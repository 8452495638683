import { RestClass, RestService } from '../../../services/rest/rest.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ICategory {
  Id: string;
  Name: string;
  isShowMoreOptions?: boolean;
  isEditMode?: boolean;
  isChosenForSorting?: boolean;
  amountOfObjects?: number;
}

export interface IDataSourceCategory {
  Id: string;
  Name: string;
  Count: number;
}

@Injectable()
@RestClass('/companies')
export class CategoriesService extends RestService<any> {
  isLastItemDeletedFromSortingCategory$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(protected http: HttpClient) {
    super(http);
  }

  createCategory(companySlug: string, categoryName: string): Observable<ICategory> {
    return this.http.post<ICategory>(`${this.baseUrl}/companies/${companySlug}/categories/${categoryName}`, {});
  }

  renameCategoryName(companySlug: string, categoryId: string, categoryName: string) {
    return this.http.put(`${this.baseUrl}/companies/${companySlug}/categories/${categoryId}`, categoryName);
  }

  updateCategoryForMultipleDevices(companySlug: string, categoryId: string, devicesIds: string[]): Observable<null> {
    return this.http.put<null>(`${this.baseUrl}/companies/${companySlug}/categories/${categoryId}/devices`, devicesIds);
  }

  updateCategoryForMultipleSources(companySlug: string, categoryId: string, sourcesIds: string[]): Observable<null> {
    return this.http.put<null>(`${this.baseUrl}/companies/${companySlug}/categories/${categoryId}/data-sources`, sourcesIds);
  }

  updateCategoryForLayer(companySlug: string, categoryId: string, layersIds: string[]): Observable<null> {
    return this.http.put<null>(`${this.baseUrl}/companies/${companySlug}/categories/${categoryId}/scene-layers`, layersIds);
  }

  deleteCategory(companySlug: string, categoryId: string) {
    return this.http.delete(`${this.baseUrl}/companies/${companySlug}/categories/${categoryId}`);
  }

  getDataSourcesCategories(companySlug: string, teamSlug?: string): Observable<ICategory[]> {
    let url = `${this.baseUrl}/companies/${companySlug}/sources/categories`;

    if (teamSlug) {
      url = `${this.baseUrl}/companies/${companySlug}/team/${teamSlug}/sources/categories`
    }

    return this.http.get<ICategory[]>(url);
  }

  filterCategories(categories: ICategory[], value: string): ICategory[] {
    if (typeof value !== 'string') {
      return;
    }

    if (!value.length || !/\S/.test(value)) {
      return categories;
    }

    const filteredValue = value.trim().toLowerCase();

    const newCategory = { Id: 'create', Name: `Create item "${value}"` };

    const filteredCategories = categories.filter(category => category.Name.trim().toLowerCase().includes(filteredValue));

    const isNewCategoryObjectExist = filteredCategories.some(c => c.Id === 'create');

    const isNeedToCreateCategory = filteredCategories.every(c => c.Name !== value);

    if (value.length && isNeedToCreateCategory && !isNewCategoryObjectExist) {
      filteredCategories.push(newCategory);
    }

    if (filteredCategories.length === 2 && isNewCategoryObjectExist) {
      filteredCategories.pop();
    }

    return filteredCategories;
  }

  removeDublicatesFromCategories(keyFn, categories: ICategory[]) {
    const mySet = new Set();

    if (categories) {
      return categories.filter((category: ICategory) => {
        const key = keyFn(category),
          isNew = !mySet.has(key);
        if (isNew) {
          mySet.add(key);
          return isNew;
        }
      });
    }
  }

  setAmountOfObjectsForCategories(categories: ICategory[], list: any[], isDataSourcesCategories?: boolean) {
    if (isDataSourcesCategories) {
      categories.forEach((c: IDataSourceCategory) => {
        c['amountOfObjects'] = c.Count;
      });

      return;
    }

    const elementsWithCategory = [];
    const elementsWithoutCategory = [];

    list.forEach(i => {
      if (i.Category && !i.IsDeleted && !i.IsArchived) {
        elementsWithCategory.push(i);
      }

      if (!i.Category && !i.IsDeleted && !i.IsArchived) {
        elementsWithoutCategory.push(i);
      }
    });

    categories.forEach(c => {
      c.amountOfObjects = elementsWithCategory.filter(i => i.Category.Name === c.Name).length;
    });
  }

  isLastItemDeletedFromSortingCategories(items: any[], itemsCategories: ICategory[]) {
    /*For case when user delete last item in category that was choose for sorting*/

    const categoryThatLostItem = [];

    items.forEach(t => {
      if (t.Category && t.Category.isChosenForSorting) {
        categoryThatLostItem.push(t.Category);
      }
    });

    if (categoryThatLostItem.length === 0) {
      this.isLastItemDeletedFromSortingCategory$.next(false);
      return;
    }

    const isLastItemInCategory = itemsCategories.every(c => c.Id !== categoryThatLostItem[0].Id);

    this.isLastItemDeletedFromSortingCategory$.next(categoryThatLostItem.length === 1 && isLastItemInCategory);
  }
}
