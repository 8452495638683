import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProcessService } from '../process-list/process.service';
import { Report, ReportLayer } from '../../models/report.interface';
import { DateFormatterService } from '../../../../services/date-formatter.service';
import { DataSourcesAdapterService } from '../source-configuration-list/components/configuration-tab/data-sources-adapter.service';
import { tap } from 'rxjs/operators';
import { PROCESSING_HISTORY_CALLBACK, PROCESSING_HISTORY_DATA } from '../../models/processing-history-data';
import { ProcessingDetailsModalData } from '../../models/processing-details-modal-data';

@Component({
  selector: 'mee-process-details',
  templateUrl: './process-details-modal.component.html',
  styleUrls: ['./process-details-modal.component.scss'],
})
export class ProcessDetailsModalComponent implements OnInit, OnDestroy {
  currentProcess: Report;
  subs: Subscription[] = [];
  isCurrentProcessInProgress: boolean;
  processLayers: Array<ReportLayer>;
  params: Params;

  constructor(
    private route: ActivatedRoute,
    private processService: ProcessService,
    private dataSourcesAdapterService: DataSourcesAdapterService,
    private dateFormatterService: DateFormatterService,
    @Inject(PROCESSING_HISTORY_CALLBACK) public closeDetails: () => void,
    @Inject(PROCESSING_HISTORY_DATA) public data: ProcessingDetailsModalData,
  ) {}

  ngOnInit() {
    this.onGetRouteParams();
    this.getProcessReport();
  }

  onGetRouteParams() {
    this.subs.push(this.route.params.subscribe((params: Params) => (this.params = params)));
  }

  getProgress() {
    if (this.currentProcess.PercentageComplete !== 100) {
      return this.currentProcess.PercentageComplete.toFixed(1) + ' %';
    } else {
      return this.currentProcess.PercentageComplete + ' %';
    }
  }

  getSourceConfigLink(): Array<string> {
    return [`/sources/${this.currentProcess.CompanySlug}/${this.currentProcess.DataSourceSlug}/config`];
  }

  getDataSourceType(): string {
    return this.dataSourcesAdapterService.getSourceTitle(this.currentProcess.DataSourceType);
  }

  getProcessReport() {
    this.subs.push(
      this.processService
        .getProcessReport(this.data.id, this.data.companySlug, this.data.sourceSlug)
        .pipe(
          tap((res: Report) => {
            const processReport = res;
            this.processLayers = processReport.Layers;
            this.currentProcess = processReport;
            this.isCurrentProcessInProgress = this.currentProcess.Status === 'InProgress';
          }),
        )
        .subscribe(),
    );
  }

  getDuration() {
    return this.dateFormatterService.getDurationBetweenTwoDates(this.currentProcess.StartDateUtc, this.currentProcess.EndDateUtc, 'HMS');
  }

  onCancelProcessInProgress() {
    if (this.isCurrentProcessInProgress) {
      this.subs.push(
        this.processService.cancelProcess(this.currentProcess.DataSourceId, this.currentProcess.ProcessId).subscribe(() => {
          this.getProcessReport();
        }),
      );
    }
  }

  onUpdateProcess() {
    this.getProcessReport();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
  }
}
