import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IdentityService } from '../../../services/identity.service';
import { CategoriesService, ICategory } from '../../../modules/company/services/categories.service';
import { ICompany } from '../../../modules/companies/shared/interfaces/company.interface';
import { CompaniesService } from '../../../modules/companies/companies.service';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CreateCategoryData } from '../../../shared/interfaces/create-category-data';

@Component({
  selector: 'mee-category-autocomplete',
  templateUrl: './category-autocomplete.component.html',
  styleUrls: ['./category-autocomplete.component.scss'],
})
export class CategoryAutocompleteComponent implements OnInit, OnDestroy {
  @Input() editModeCategoryName: string;
  @Output() categoryNameChanged: EventEmitter<string> = new EventEmitter<string>();
  // For device update category
  @Output() categoryCreateDataChanged: EventEmitter<CreateCategoryData> = new EventEmitter();
  categories: ICategory[];
  filteredCategories: ICategory[] = [];
  finalCategoryName = '';
  isCategorySelected = false;
  private readonly destroy$ = new Subject<void>();

  constructor(
    public identityService: IdentityService,
    private companiesService: CompaniesService,
    private categoriesService: CategoriesService,
  ) {}

  ngOnInit() {
    if (this.identityService.isCompanyAdmin()) {
      this.companiesService
        .getMyCompany()
        .pipe(
          takeUntil(this.destroy$),
          tap((company: ICompany) => {
            this.categories = company.Categories;
          }),
        )
        .subscribe();
    }

    this.finalCategoryName = this.editModeCategoryName ? this.editModeCategoryName : '';
  }

  onChangeCategoryName(event) {
    let value = event.target.value;
    this.filteredCategories = this.categoriesService.filterCategories(this.categories, value);

    if (this.isCategorySelected) {
      return;
    }

    if (!/\S/.test(value)) {
      this.categoryCreateDataChanged.emit({ categoryThatChosen: null, isCreateCategory: false });
      value = '';
    } else {
      this.categoryCreateDataChanged.emit({ categoryThatChosen: value, isCreateCategory: this.isCreateCategory(value) });
    }

    this.categoryNameChanged.emit(value);
  }

  onChooseCategoryFromAutocomplete(event) {
    let value: ICategory = event.option.value;
    this.isCategorySelected = true;

    if (this.isCreateCategory(value)) {
      this.finalCategoryName = value.Name.replace(/Create item "(.+)"/g, '$1');
    } else {
      this.finalCategoryName = value.Name;
    }

    value = { ...value, Name: this.finalCategoryName };

    const data = { categoryThatChosen: value, isCreateCategory: this.isCreateCategory(value) };

    this.categoryCreateDataChanged.emit(data);
    this.categoryNameChanged.emit(this.finalCategoryName);
  }

  isCreateCategory(currentValue: string | ICategory): boolean {
    return this.categories.every(c => {
      const categoryName = c.Name.trim().toLowerCase();
      let changedCategoryName;
      if (typeof currentValue === 'string') {
        changedCategoryName = currentValue.trim().toLowerCase();
      } else {
        changedCategoryName = currentValue.Name.trim().toLowerCase();
      }

      return categoryName !== changedCategoryName;
    });
  }

  onDeleteCategory() {
    this.finalCategoryName = '';
    this.categoryNameChanged.emit(this.finalCategoryName);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
