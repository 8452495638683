import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'mee-set-template-modal',
  templateUrl: './set-template.component.html',
  styleUrls: ['./set-template.component.scss'],
})
export class SetTemplateComponent {
  templateNameControl = new FormControl<string>('', Validators.required);
  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<string>();

  onSave(): void {
    this.save.emit(this.templateNameControl.value);
  }
}
