import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CategoriesService, ICategory, IDataSourceCategory} from '../../modules/company/services/categories.service';
import {IdentityService} from '../../services/identity.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {snackBarConfig} from '../core.module';
import {TranslationHandleService} from '../translate/translation-handle.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {TableActionConfirmComponent} from '../table/table-action-confirm/table-action-confirm.component';
import {CompaniesService} from '../../modules/companies/companies.service';
import {TranslateService} from '@ngx-translate/core';
import {PaginationService} from '../../services/pagination.service';

const PHRASES_FOR_TRANSLATE = [
  {
    categoryMergedWith: 'categories.categoryMergedWith',
  },
  {
    categoryMergeFailed: 'categories.categoryMergeFailed',
  },
  {
    categoryWasRenamed: 'categories.categoryWasRenamed',
  },
  {
    categoryRenameFailed: 'categories.categoryRenameFailed',
  },
  {
    categoryWasDeleted: 'categories.categoryWasDeleted',
  },
  {
    categoryDeletedFailed: 'categories.categoryDeletedFailed',
  },
];

export interface ICategoryBase {
  categoryName: string;
  categoryId: string;
}

export const UNCATEGORIZED_CATEGORY_ID = 'uncategorized';
export const UNCATEGORIZED_CATEGORY_NAME = '*null';

export const FIRST_SYMBOL_FOR_CATEGORY_SEARCH = '*';

@Component({
  selector: 'mee-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit, OnDestroy {
  initialDataList: any; // ILayer[] | ISource[] | IDevice[] | TeamInterface[]

  subs: Subscription[] = [];

  translationMap: Map<string, string>;

  companySlug: string;

  isOpenAddMenu = true;

  private dialogConfirm: MatDialogRef<TableActionConfirmComponent>;

  private _categories: ICategory[];

  @Output() selectedItemsByCategoryChanged: EventEmitter<ICategoryBase> = new EventEmitter<ICategoryBase>();

  @Output() refreshListAndCategoriesData: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() list: Array<any>; // ILayer[] | ISource[] | IDevice[] | TeamInterface[]

  @Input() set categories(categories: ICategory[]) {
    this._categories = categories;

    /*Check on data sources categories*/

    if (this._categories && this._categories.length > 0 && this._categories[0]['Count']) {

      /*Shift 'Other' item in the end of the array*/

      this._categories.push(this._categories.shift());

      /*From the server category that holds items without category calls none and nave null id*/

      this._categories = this._categories.map(cat => {
        if (cat.Id === null) {
          return {...cat, Name: 'Other'};
        }

        return cat;
      });

      let searchedCategoryId = this.paginationService.paginationOptions.categoryId;

      if (!searchedCategoryId) {
        return;
      }

      if (searchedCategoryId === 'none') {
        searchedCategoryId = null;
      }

      this._categories.find(category => category.Id === searchedCategoryId).isChosenForSorting = true;

      return;
    }

    this._categories = this.categoriesService.removeDublicatesFromCategories(category => category.Name, categories);

    if (this._categories && this._categories.length > 0) {
      const lastCategory = this._categories[this._categories.length - 1];

      if (!this.isCategoryContainsUncategorized(lastCategory)) {
        this._categories.push({Id: UNCATEGORIZED_CATEGORY_ID, Name: 'Other', amountOfObjects: this.getAmountOfObjectWithoutCategory()});
      }
    }
  }

  get categories() {
    return this._categories;
  }

  constructor(
    public identityService: IdentityService,
    private categoriesService: CategoriesService,
    private translateService: TranslateService,
    private translationHandleService: TranslationHandleService,
    private companiesService: CompaniesService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private readonly paginationService: PaginationService
  ) {
    this.translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);

    this.getTranslationMap();
  }

  ngOnInit() {
    this.companySlug = this.identityService.companySlug;

    this.categories = this.categoriesService.removeDublicatesFromCategories(category => category.Id, this.categories);

    this.initialDataList = this.list;

    this.categoriesService.isLastItemDeletedFromSortingCategory$.subscribe(result => {
      if (result) {
        this.selectedItemsByCategoryChanged.emit({categoryName: '', categoryId: null});
      }
    });
  }

  getTranslationMap() {
    this.subs.push(
      this.translationHandleService.getTranslationMap().subscribe(translationMap => {
        this.translationMap = translationMap;
      }),
    );
  }

  getAmountOfObjectWithoutCategory(): number {
    const amountOfObjectsWithoutCategory = this.list.filter(el => !el.IsDeleted && !el.IsArchived && !el.Category);

    return amountOfObjectsWithoutCategory.length;
  }

  onChooseItemsByCategory(category: ICategory) {
    if (category.isEditMode) {
      return;
    }

    if (!category.isChosenForSorting) {
      /*For Name property because after merge until request for separate item id of merged category would be different*/

      const alreadyChosenCategory = this.categories.find(cat => cat.isChosenForSorting);

      if (alreadyChosenCategory !== undefined) {
        alreadyChosenCategory.isChosenForSorting = false;
      }

      // TODO return new array with map but with save order (problem without default sorting from backend)

      category.isChosenForSorting = true;

      if (this.isCategoryContainsUncategorized(category)) {
        this.selectedItemsByCategoryChanged.emit({categoryName: '*' + 'null', categoryId: category.Id});

        return;
      }

      this.selectedItemsByCategoryChanged.emit({categoryName: '*' + category.Name.trim().toLowerCase(), categoryId: category.Id});
    } else {
      // TODO return new array with map but with save order (problem without default sorting from backend)

      category.isChosenForSorting = false;

      this.selectedItemsByCategoryChanged.emit({categoryName: '', categoryId: category.Id});
    }
  }

  onClickMoreOptionsMenu(event) {
    this.isOpenAddMenu = false;

    // for cancel click on parent component that response for sorting items

    event.stopImmediatePropagation();
  }

  onOpenMoreOptions(event, category: ICategory) {
    category.isShowMoreOptions = true;
  }

  onCloseMoreOptions(category: ICategory) {
    category.isShowMoreOptions = false;

    this.isOpenAddMenu = true;
  }

  onRenameCategory(category: ICategory) {
    category.isEditMode = true;
  }

  onChangeNameOfCategory(event, currentCategory: ICategory) {
    const newCategoryName = event.target.value;

    const oldCategoryName = currentCategory.Name;

    if (!/\S/.test(newCategoryName)) {
      currentCategory.isEditMode = false;
      currentCategory.Name = oldCategoryName;
      return;
    }

    if (this.categories.some((category: ICategory) => category.Name === newCategoryName)) {
      this.dialogConfirm = this.dialog.open(TableActionConfirmComponent, {
        width: '350px',
        data: {
          categoryInfo: {
            customText: this.translationMap.get('categoryMergedWith'),
            categoryName: newCategoryName,
          },
        },
      });

      this.dialogConfirm.afterClosed().subscribe(confirm => {
        if (confirm) {
          this.renameCategory(currentCategory, newCategoryName, true);
        } else {
          currentCategory.isEditMode = false;
          currentCategory.Name = oldCategoryName;
        }
      });
    } else {
      this.renameCategory(currentCategory, newCategoryName);
    }
  }

  renameCategory(currentCategory: ICategory, newCategoryName: string, isCategoriesMerged?: boolean) {
    this.subs.push(
      /*TODO refactor on flatMap and forkJoin :) */

      this.categoriesService.renameCategoryName(this.companySlug, currentCategory.Id, newCategoryName).subscribe(
        (newCategory: ICategory) => {
          currentCategory.Name = newCategoryName;
          currentCategory.isEditMode = false;

          this.refreshListAndCategoriesData.emit(true);

          this.translateService.get('categories.categoryWasMerged', {name: currentCategory.Name}).subscribe(result => {
            this.snackBar.open('Category was successfully merged!', 'OK', snackBarConfig);
          });

          this.snackBar.open(this.translationMap.get('categoryWasRenamed'), 'OK', snackBarConfig);
        },
        () => {
          currentCategory.isEditMode = false;

          if (isCategoriesMerged) {
            this.snackBar.open(this.translationMap.get('categoryMergeFailed'), 'OK', snackBarConfig);
            return;
          }

          this.snackBar.open(this.translationMap.get('categoryRenameFailed'), 'OK', snackBarConfig);
        },
      ),
    );

    this.isOpenAddMenu = true;
  }

  onDeleteCategory(deletedCategory: ICategory) {
    this.subs.push(
      this.categoriesService.deleteCategory(this.companySlug, deletedCategory.Id).subscribe(
        () => {
          this.refreshListAndCategoriesData.emit(true);
          this.snackBar.open(this.translationMap.get('categoryWasDeleted'), 'OK', snackBarConfig);
        },
        () => this.snackBar.open(this.translationMap.get('categoryDeletedFailed'), 'OK', snackBarConfig),
      ),
    );
  }

  isDisplayAmountOfObjects(category: ICategory): boolean {
    return category.amountOfObjects && !category.isEditMode && this.isOpenAddMenu;
  }

  isCategoryContainsUncategorized(category: ICategory) {
    return category.Id === 'uncategorized' || category.Id === null;
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub && sub.unsubscribe());
    this.translationHandleService.cleanTranslationArray();
  }
}
