import { DataSourceType } from '../../../../../data-source-type.enum';
import { ConfigurationFields } from '../enums/configuration-fields.enum';
import { FeatureLayerType } from '../interfaces/feature-layer-type.enum';

export const CONFIGURATION_FIELDS_DISPLAY_SOURCE_TYPES = {
  MainColor: {
    Root: [
      DataSourceType.ArcGisFeatureService,
      DataSourceType.ArcGisSceneService,
      DataSourceType.Bim360,
      DataSourceType.NativeBim,
      DataSourceType.NativeGis,
      DataSourceType.Wfs,
      DataSourceType.Kml,
      DataSourceType.IModelGis,
      DataSourceType.Shapefile,
      DataSourceType.Pix4d,
      DataSourceType.RealityCapture,
      DataSourceType.LandXml,
      DataSourceType.Simple3dModel,
    ],
  },
  CoordinateSystem: {
    Root: [DataSourceType.NativeGis],
    Model3d: [DataSourceType.NativeBim, DataSourceType.NativeGis, DataSourceType.Bim360, DataSourceType.LandXml],
  },
  MeasurementSystem: {
    Root: [DataSourceType.NativeGis],
    Model3d: [DataSourceType.NativeBim, DataSourceType.NativeGis, DataSourceType.LandXml],
  },
  IsLocalCoordinateSystem: {
    Root: [DataSourceType.NativeBim, DataSourceType.Bim360],
    Model3d: [DataSourceType.NativeBim, DataSourceType.Bim360],
  },
  ManualCoordinates: {
    Model3d: [
      DataSourceType.ArcGisFeatureService,
      DataSourceType.Bim360,
      DataSourceType.NativeBim,
      DataSourceType.NativeGis,
      DataSourceType.LandXml,
      DataSourceType.Wfs,
      DataSourceType.Pix4d,
      DataSourceType.Simple3dModel,
    ],
  },
  IsMeshInteractionEnabled: {
    Root: [DataSourceType.ArcGisSceneService],
    Model3d: [
      DataSourceType.ArcGisSceneService,
      DataSourceType.Kml,
      DataSourceType.Bim360,
      DataSourceType.Pix4d,
      DataSourceType.NativeBim,
      DataSourceType.LandXml,
      DataSourceType.IModel,
    ],
  },
  FullModel: {
    Root: [DataSourceType.ArcGisSceneService],
    Model3d: [
      DataSourceType.ArcGisSceneService,
      DataSourceType.Kml,
      DataSourceType.Bim360,
      DataSourceType.Pix4d,
      DataSourceType.NativeBim,
      DataSourceType.LandXml,
      DataSourceType.IModel,
    ],
  },
  DefaultDepth: {
    Root: [
      DataSourceType.ArcGisFeatureService,
      DataSourceType.NativeGis,
      DataSourceType.Wfs,
      DataSourceType.Shapefile,
      DataSourceType.Pix4d,
      DataSourceType.IModelGis,
    ],
    Folder: [
      DataSourceType.ArcGisFeatureService,
      DataSourceType.NativeGis,
      DataSourceType.Wfs,
      DataSourceType.Shapefile,
      DataSourceType.Pix4d,
    ],
  },
  VerticalDatum: {
    Root: [
      DataSourceType.ArcGisFeatureService,
      DataSourceType.ArcGisSceneService,
      DataSourceType.Bim360,
      DataSourceType.NativeGis,
      DataSourceType.Wfs,
      DataSourceType.Shapefile,
      DataSourceType.Pix4d,
      DataSourceType.Kml,
      DataSourceType.IModel,
      DataSourceType.IModelGis,
      DataSourceType.LandXml,
      DataSourceType.RealityCapture
    ],
    Folder: [],
    Model3d: [
      DataSourceType.ArcGisFeatureService,
      DataSourceType.ArcGisSceneService,
      DataSourceType.Bim360,
      DataSourceType.NativeBim,
      DataSourceType.NativeGis,
      DataSourceType.Wfs,
      DataSourceType.LandXml,
    ],
  },
  AltitudeMode: {
    Root: [
      DataSourceType.Kml,
      DataSourceType.ArcGisFeatureService,
      DataSourceType.ArcGisSceneService,
      DataSourceType.Bim360,
      DataSourceType.IModel,
      DataSourceType.IModelGis,
      DataSourceType.LandXml,
      DataSourceType.NativeGis,
      DataSourceType.Wfs,
      DataSourceType.Pix4d,
    ],
    Model3d: [
      DataSourceType.Kml,
      DataSourceType.ArcGisFeatureService,
      DataSourceType.Bim360,
      DataSourceType.NativeBim,
      DataSourceType.NativeGis,
      DataSourceType.Wfs,
    ],
    Folder: [
      DataSourceType.Kml,
      DataSourceType.ArcGisFeatureService,
      DataSourceType.Bim360,
      DataSourceType.NativeBim,
      DataSourceType.NativeGis,
      DataSourceType.Wfs,
    ],
  },
  IsExcludedFromProcessing: {
    Folder: [
      DataSourceType.Kml,
      DataSourceType.ArcGisFeatureService,
      DataSourceType.ArcGisSceneService,
      DataSourceType.Bim360,
      DataSourceType.IModel,
      DataSourceType.IModelGis,
      DataSourceType.NativeBim,
      DataSourceType.LandXml,
      DataSourceType.NativeGis,
      DataSourceType.Wfs,
    ],
    Point: [
      DataSourceType.Kml,
      DataSourceType.ArcGisFeatureService,
      DataSourceType.ArcGisSceneService,
      DataSourceType.Bim360,
      DataSourceType.IModel,
      DataSourceType.IModelGis,
      DataSourceType.NativeBim,
      DataSourceType.LandXml,
      DataSourceType.NativeGis,
      DataSourceType.Wfs,
    ],
    Line: [
      DataSourceType.Kml,
      DataSourceType.ArcGisFeatureService,
      DataSourceType.ArcGisSceneService,
      DataSourceType.Bim360,
      DataSourceType.IModel,
      DataSourceType.IModelGis,
      DataSourceType.NativeBim,
      DataSourceType.LandXml,
      DataSourceType.NativeGis,
      DataSourceType.Wfs,
    ],
    Area: [
      DataSourceType.Kml,
      DataSourceType.ArcGisFeatureService,
      DataSourceType.ArcGisSceneService,
      DataSourceType.Bim360,
      DataSourceType.IModel,
      DataSourceType.IModelGis,
      DataSourceType.NativeBim,
      DataSourceType.NativeGis,
      DataSourceType.LandXml,
      DataSourceType.Wfs,
    ],
    Model3d: [
      DataSourceType.Kml,
      DataSourceType.ArcGisFeatureService,
      DataSourceType.ArcGisSceneService,
      DataSourceType.Bim360,
      DataSourceType.IModel,
      DataSourceType.NativeBim,
      DataSourceType.NativeGis,
      DataSourceType.LandXml,
      DataSourceType.Wfs,
    ],
    Unknown: [
      DataSourceType.Kml,
      DataSourceType.ArcGisFeatureService,
      DataSourceType.ArcGisSceneService,
      DataSourceType.Bim360,
      DataSourceType.IModel,
      DataSourceType.IModelGis,
      DataSourceType.NativeBim,
      DataSourceType.NativeGis,
      DataSourceType.LandXml,
      DataSourceType.Wfs,
    ],
  },
  UseVGisSymbols: {
    Point: [DataSourceType.ArcGisFeatureService],
    Line: [DataSourceType.ArcGisFeatureService],
  },
  IfcCategorizationStrategy: {
    Model3d: [DataSourceType.NativeBim, DataSourceType.Bim360],
  },
  EnableDoubleSideInteraction: {
    Model3d: [DataSourceType.NativeBim, DataSourceType.Bim360, DataSourceType.LandXml],
  },
  FloorElevation: {
    Model3d: [DataSourceType.NativeBim, DataSourceType.Bim360],
  },
  WorkPacks: {
    Model3d: [DataSourceType.NativeBim, DataSourceType.Bim360],
  }
} as Record<ConfigurationFields, Partial<Record<FeatureLayerType, DataSourceType[]>>>;
