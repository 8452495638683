import { UntypedFormGroup } from '@angular/forms';

export enum TableFormMode {
  CREATE,
  UPDATE
}

export interface ITableForm {
  mode: TableFormMode;

  form: UntypedFormGroup;

  onSubmit(form: UntypedFormGroup): void;

  create(data: UntypedFormGroup): void;

  update(data: UntypedFormGroup): void;
}
