import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  DAY_FILTER_OPTION_USUAL,
  INTERVAL_FILTER_OPTIONS,
  ITimeSelectOption,
  TIME_FILTER_OPTIONS,
} from './source-form-scheduling-options.schema';
import { ISchedule, ISource } from '../../../models/source.interface';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { SourcesFormChangesService } from '../../../services/sources-form-changes.service';
import { filter, tap } from 'rxjs/operators';

interface ITimeFilters {
  key: string;
  formControl: UntypedFormControl;
  placeholder: string;
  options: Array<ITimeSelectOption>;
  isDisplay: boolean;
  timeLabel?: boolean;
}

interface ITimeFiltersValue {
  interval: string;
  day: string | number;
  time: string;
}

@Component({
  selector: 'mee-source-form-scheduling',
  templateUrl: './source-form-scheduling.component.html',
  styleUrls: ['./source-form-scheduling.component.scss']
})
export class SourceFormSchedulingComponent implements OnInit, OnDestroy {

  @Input() currentSource: ISource;
  @Input() isShowScheduling = true;

  @Output() onChangeScheduleConfiguration = new EventEmitter<ISchedule>();

  scheduleControl: UntypedFormControl;

  isShowFilters = true;

  selectSchema: Array<ITimeFilters>;

  dayFilterCases = ['Weekly', 'Monthly'];
  timeFilterCases = ['Weekly', 'Monthly', 'Daily'];
  selectsFormGroup: UntypedFormGroup;

  intervalFilterOptions: Array<ITimeSelectOption> = INTERVAL_FILTER_OPTIONS;

  dayFilterOptionUsual: Array<ITimeSelectOption> = DAY_FILTER_OPTION_USUAL;

  timeFilterOptions: Array<ITimeSelectOption> = TIME_FILTER_OPTIONS;

  dayFilterOptionOnceAMonth: Array<ITimeSelectOption> = [];

  filtersValueDefault: any;

  subs: Subscription[] = [];

  companyLocalTime: any;

  scheduleSettings: ISchedule;

  companyTimeLabel = 'sources.scheduling.companyLocalTime';

  static giveProcessStatusUI(status): string {
    let columnContent;

    switch (status) {
      case 'InProgress' :
        columnContent = `<img src="../../../../../../assets/img/icons/ellipse.png" alt="">
                  <span class="blue-color">${status.replace('n', 'n ')}</span>`;
        break;
      case 'Finished' :
        columnContent = `<i class="material-icons green-color">done</i> <span class="green-color">${status}</span>`;
        break;
      case 'TimedOut' :
        columnContent = `<i class="material-icons red-color">warning</i> <span class="red-color">${status.replace('d', 'd ')}</span>`;
        break;
      case 'Idle':
      case 'Canceled':
        columnContent = `<i class="material-icons red-color">warning</i> <span class="red-color">${status}</span>`;
    }

    return columnContent;
  }

  constructor(private sourcesFormChangesService: SourcesFormChangesService) {
  }


  ngOnInit(): void {
    this.scheduleControl = new UntypedFormControl();

    if (this.currentSource.Schedule === undefined) {
      return;
    }

    this.defineDefaultFiltersValue();
    this.defineCompanyLocalTime();
    this.onScheduleControlChange();
    this.defineDayFilterMonthlyOptions();
    this.initSelectFormGroup();
    this.initCancelChangesHandler();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub && sub.unsubscribe());
  }

  defineCompanyLocalTime(): void {
    const dateNow = moment(new Date()).utc();

    this.companyLocalTime = dateNow.add(this.currentSource.Schedule.UtcOffsetHours, 'hours').format('HH:mm A');
  }

  onScheduleControlChange(): void {
    this.subs.push(
      this.scheduleControl.valueChanges.subscribe((value) => {
        this.isShowFilters = value;
        this.scheduleSettings.IsEnabled = value;
      })
    );
  }

  defineDefaultFiltersValue(): void {
    this.scheduleControl.setValue(this.currentSource.Schedule.IsEnabled, { emitEvent: false });
    this.isShowFilters = this.currentSource.Schedule.IsEnabled;

    this.filtersValueDefault = {
      interval: this.currentSource.Schedule.Type,
      time: this.currentSource.Schedule.DayTime
    };

    if (this.currentSource.Schedule.Type === 'Monthly') {
      this.filtersValueDefault.day = this.currentSource.Schedule.DayOfMonth;
    } else if (this.currentSource.Schedule.Type === 'Weekly') {
      this.filtersValueDefault.day = this.currentSource.Schedule.DayOfWeek;
    }
  }

  defineDayFilterMonthlyOptions(): void {
    for (let i = 0; i < 30; i++) {
      this.dayFilterOptionOnceAMonth.push({
        userDisplay: i + 1 + 'th',
        value: i + 1
      });
    }
  }

  initSelectFormGroup(): void {

    this.selectsFormGroup = new UntypedFormGroup({});

    // init select schema

    if (!this.selectSchema) {
      this.onChangeSelectSchema(this.filtersValueDefault);
      this.defineScheduleSettings(this.filtersValueDefault);
    }

    this.selectSchema.forEach((schema: ITimeFilters) => {
      this.selectsFormGroup.addControl(schema.key, schema.formControl);
    });

    this.subs.push(
      this.selectsFormGroup.controls.interval.valueChanges.subscribe((intervalFilterValue: string) => {
        if (intervalFilterValue === 'Monthly') {
          this.selectsFormGroup.controls.day.setValue(1);
        } else {
          this.selectsFormGroup.controls.day.setValue('Sunday');
        }
      })
    );

    this.subs.push(this.scheduleControl.valueChanges.subscribe(() => {
      this.defineScheduleSettings(this.selectsFormGroup.getRawValue());
    }))

    this.subs.push(
      this.selectsFormGroup.valueChanges.subscribe((filtersValues) => {
        this.onChangeSelectSchema(filtersValues);
        this.defineScheduleSettings(filtersValues);
      })
    );
  }

  onChangeSelectSchema(filtersValues: ITimeFiltersValue): void {
    this.selectSchema = [
      {
        key: 'interval',
        formControl: new UntypedFormControl(filtersValues.interval),
        placeholder: 'sources.scheduling.intervalFilters.title',
        options: this.intervalFilterOptions,
        isDisplay: true
      },
      {
        key: 'day',
        formControl: new UntypedFormControl(filtersValues.day),
        placeholder: filtersValues.interval === 'Monthly' ?
          'sources.scheduling.dayFilters.onceAMonthTitle' : 'sources.scheduling.dayFilters.title',
        options: filtersValues.interval === 'Monthly' ? this.dayFilterOptionOnceAMonth : this.dayFilterOptionUsual,
        isDisplay: this.dayFilterCases.includes(filtersValues.interval)
      },
      {
        key: 'time',
        formControl: new UntypedFormControl(filtersValues.time),
        placeholder: 'sources.scheduling.timeFilters.title',
        options: this.timeFilterOptions,
        isDisplay: this.timeFilterCases.includes(filtersValues.interval),
        timeLabel: true
      },
    ];
  }

  defineScheduleSettings(filtersValues: ITimeFiltersValue): void {
    this.scheduleSettings = {
      Type: filtersValues.interval,
      IsEnabled: this.isShowFilters,
      DayTime: filtersValues.time,
      UtcOffsetHours: this.currentSource.Schedule.UtcOffsetHours
    };

    if (filtersValues.interval === 'Monthly') {
      this.scheduleSettings.DayOfMonth = filtersValues.day;
    } else if (filtersValues.interval === 'Weekly') {
      this.scheduleSettings.DayOfWeek = filtersValues.day;
    } else if (filtersValues.interval === 'Daily') {
      delete this.scheduleSettings.DayOfWeek;
      delete this.scheduleSettings.DayOfMonth;
    } else {
      this.scheduleSettings.Type = filtersValues.interval;
      delete this.scheduleSettings.DayTime;
    }

    this.onChangeScheduleConfiguration.emit(this.scheduleSettings);
  }

  onChangeValue(): void {
    this.initSelectFormGroup();
  }

  private initCancelChangesHandler(): void {
    this.subs.push(
      this.sourcesFormChangesService.isNeedToCancelChanges$.pipe(
        filter(isNeedCancel => !!isNeedCancel),
        tap(() => {
          this.defineDefaultFiltersValue();
          this.onChangeSelectSchema(this.filtersValueDefault);
        })
      ).subscribe()
    )
  }
}
