import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {NodeType} from '../../../enums/node-type.enum';
import {Bim360ModelsTreeDialogData} from './bim360-models-tree-dialog-data';
import {Bim360ModelsNode} from '../../../models/bim360-model-node';
import {DataSourcesService} from '../../../services/data-sources.service';
import {debounceTime, tap} from 'rxjs/operators';
import { ITreeOptions, TreeComponent, TreeModel, TreeNode } from '@ali-hm/angular-tree-component';

@Component({
  selector: 'mee-bim360-models-tree',
  templateUrl: './bim360-models-tree.component.html',
  styleUrls: ['./bim360-models-tree.component.scss']
})
export class Bim360ModelsTreeComponent implements AfterViewInit {

  selectedNodes: Bim360ModelsNode[] = [];

  options: ITreeOptions = {
    idField: 'Id',
    displayField: 'DisplayName',
    childrenField: 'ChildNodes',
    hasChildrenField: 'HasChild',
    getChildren: this.getChildrenNode.bind(this),
    useCheckbox: true,
    useTriState: false,
    nodeClass: (node: TreeNode) => {
      if (node.data.NodeType === NodeType.Folder) {
        return 'folder';
      } else {
        return 'item';
      }
    },
  };

  @ViewChild('tree') treeComponent: TreeComponent;

  get isShowModelsTree() {
    return this.data.modelsTree && this.data.modelsTree.length > 0;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: Bim360ModelsTreeDialogData,
              private matDialogRef: MatDialogRef<Bim360ModelsTreeComponent, Bim360ModelsNode[]>,
              private dataSourcesService: DataSourcesService) {
    this.data.modelsTree = this.getAdaptedTreeModel();
  }

  ngAfterViewInit() {
    if (this.data.alreadySelectedNodes.length > 0) {
      this.initSelectedNodes();
      this.selectedNodes = this.data.alreadySelectedNodes;
    }
  }

  initSelectedNodes() {

    const treeModel: TreeModel = this.treeComponent.treeModel;

    const selectedNodeIds = this.data.alreadySelectedNodes.map(node => node.Id);

    const selectedNodesDomElements = [];

    selectedNodeIds.forEach(selectedNodeId => {
      if (treeModel.getNodeById(selectedNodeId) !== undefined) {
        selectedNodesDomElements.push(treeModel.getNodeById(selectedNodeId));
      }
    });

    if (selectedNodesDomElements.length > 0) {
      selectedNodesDomElements.forEach(node => {
        node.setIsSelected(true);
      });
    }
  }

  onSelectModels(event) {

    event.node.setIsActive(true, true);

    const isElementAlreadyExist = !!(this.selectedNodes.find(node => event.node.data.Id === node.Id));

    if (isElementAlreadyExist) {
      return;
    }

    this.selectedNodes.push(event.node.data);
  }

  onDeselectModels(event) {

    event.node.setIsActive(false, true);

    this.selectedNodes = this.selectedNodes.filter(node => node.Id !== event.node.data.Id);
  }

  onSaveModels() {
    this.matDialogRef.close(this.selectedNodes);
  }

  onCloseDialog() {
    this.matDialogRef.close();
  }

  private getAdaptedTreeModel() {
    return this.data.modelsTree.map(tree => {

      delete tree.ChildNodes;

      return { ...tree, HasChild: true };
    });
  }

  private getChildrenNode(node) {
    return this.dataSourcesService.getModelsTreeForBim360(
      this.data.companySlug, this.data.dataSourceSlug,
      this.data.hubId, this.data.project.Id,
      node.data.Href)
      .pipe(
        debounceTime(1000),
        tap(children => {

          /*TODO replace it without mutation*/

          children.forEach(child => {
            if (child.NodeType === NodeType.Folder) {

              delete child.ChildNodes;

              child.HasChild = true;
            }
          })

          // library doesn`t refresh data without timeout, update tree method works incorrect

          setTimeout(() => {
            this.initSelectedNodes();
          }, 100);
        })
      )
      .toPromise();
  }
}

