import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[meeLimitTextToMaxLength], textarea[meeLimitTextToMaxLength]',
})
export class LimitTextToMaxLengthDirective {
  constructor(private readonly element: ElementRef) {}

  @HostListener('input') onInput() {
    const value = this.element.nativeElement.value;
    const maxLength = this.element.nativeElement.maxLength;

    if (value.length > maxLength) {
      this.element.nativeElement.value = value.slice(0, maxLength);
    }
  }
}
