import { Component, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ICompany } from '../../companies/shared/interfaces/company.interface';
import { layersPresetColors } from '../../components/layers/shared/components/layers-form/preset-colors-list';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ICategory } from '../../company/services/categories.service';
import { Observable, of, Subject } from 'rxjs';
import {catchError, filter, map, startWith, takeUntil, takeWhile, tap} from 'rxjs/operators';
import { LoadingService } from '../../../core/loading-overlay/loading.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { LayersService } from '../../components/layers/shared/services/layers.service';
import { Router } from '@angular/router';
import { IdentityService } from '../../../services/identity.service';
import { CompaniesService } from '../../companies/companies.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationHandleService } from '../../../core/translate/translation-handle.service';
import { ColorHelperService } from '../../../shared/services/color-helper.service';
import { Scope } from '../../components/models/scope.enum';
import {identifierMaxLength} from '../../../core/utils/validators';
import {AutofillFieldService} from '../../../services/autofill-field.service';

@Component({
  selector: 'mee-add-layer',
  templateUrl: './add-layer.component.html',
  styleUrls: ['./add-layer.component.scss'],
})
export class AddLayerComponent implements OnDestroy {
  form: UntypedFormGroup;
  companies: ICompany[];
  presetColors = layersPresetColors;
  translationMap: Map<string, string>;
  categories: ICategory[] = [];
  editModeCategoryName: string;
  companiesFiltered: Observable<ICompany[]>;
  scopes = Scope;
  destroy$ = new Subject<void>();
  readonly identifierMaxLength = identifierMaxLength;
  get companySlugControl(): AbstractControl {
    return this.form.get('CompanySlug');
  }

  constructor(
    protected formBuilder: UntypedFormBuilder,
    public loadingService: LoadingService,
    private snackBar: MatSnackBar,
    private layersService: LayersService,
    private router: Router,
    public identityService: IdentityService,
    private companiesService: CompaniesService,
    private dialog: MatDialog,
    private colorHelperService: ColorHelperService,
    private translateService: TranslateService,
    private translationHandleService: TranslationHandleService,
    public dialogRef: MatDialogRef<AddLayerComponent>,
    private readonly autofillFieldService: AutofillFieldService,
  ) {
    this.translationHandleService.setPhrasesForTranslate([
      {
        serverError: 'system.serverError',
      },
      {
        layerWasCreated: 'layers.form.snackBarActions.update',
      },
    ]);
    this.translationHandleService.getTranslationMap().subscribe(translationMap => {
      this.translationMap = translationMap;
    });

    this.createForm();
    this.setAutoFillValue();
    this.companySlugControl.setValue(this.identityService.companySlug);
  }

  ngOnDestroy(): void {
    this.translationHandleService.cleanTranslationArray();
    this.destroy$.next();
    this.destroy$.complete();
  }

  filterCompaniesValues(): void {
    this.companiesFiltered = this.companySlugControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(''),
      map((value: string) => this.companiesFilter(value)),
    );
  }

  displayCompanyProperty(companySlug) {
    if (!companySlug || !this.companies) {
      return '';
    }

    return this.companies.find(company => company.Slug === companySlug).Name;
  }

  setCompanyValue(event: any): void {
    if (!event.option.value) {
      return;
    }

    this.companySlugControl.setErrors(null);
  }

  isRequiredError(formControlName: string): boolean {
    const control = this.form.get(formControlName);

    return control.errors && control.errors.required && (control.dirty || control.touched);
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      CompanySlug: ['', [Validators.required]],
      Name: ['', [Validators.required]],
      CategoryName: [''],
      Slug: [{value: '', disabled: true}, [Validators.required, Validators.pattern(/^[0-9a-z-]+$/)]],
      Scope: [Scope.CompanyScope],
      LayerColor: [''],
    });

    this.form.get('Slug').statusChanges.subscribe(value => {
      if (value === 'INVALID' && this.form.get('Slug').value) {
        this.form.get('Slug').setValue(this.form.get('Slug').value.slice(0, this.form.get('Slug').value.length - 1));
      }
    });
  }

  onCategoryNameChanged(categoryName: string) {
    this.form.get('CategoryName').setValue(categoryName);
  }

  getTextColor(color: string): string {
    return this.colorHelperService.getTextColor(color);
  }

  cancel() {
    this.dialogRef.close();
  }

  onSubmit({ value, valid }: UntypedFormGroup) {
    if (!valid) {
      return;
    }

    this.layersService
      .create(value.CompanySlug, value)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.snackBar.open(this.translationMap.get('layerWasCreated'), 'OK', { duration: 5000 });
          this.dialogRef.close(true);
        }),
        catchError(error => {
          this.snackBar.open(error.statusText, 'OK', { duration: 5000 });

          return of(error);
        }),
      )
      .subscribe();
  }

  private setAutoFillValue(): void {
    const nameControl = this.form.get('Name');
    const identifierControl = this.form.get('Slug');

    this.autofillFieldService.setAutoFillValue$(nameControl, identifierControl).pipe(takeUntil(this.destroy$)).subscribe();
  }

  private companiesFilter(value: string): ICompany[] {
    if (typeof value !== 'string') {
      return;
    }

    const filterValue = value.toLowerCase();
    this.companySlugControl.setErrors({ required: true });

    return this.companies.filter(company => company.Name.toLowerCase().includes(filterValue));
  }
}
