import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Pix4dProjectsDialogData } from './pix4d-projects-dialog-data';
import { ITreeOptions, TreeComponent, TreeModel } from '@ali-hm/angular-tree-component';

@Component({
  selector: 'mee-pix4d-select-projects',
  templateUrl: './pix4d-select-projects.component.html',
  styleUrls: ['./pix4d-select-projects.component.scss']
})
export class Pix4dSelectProjectsComponent implements AfterViewInit {

  selectedNodes = [];

  options: ITreeOptions = {
    idField: 'Id',
    displayField: 'DisplayName',
    useCheckbox: true,
    useTriState: false,
    nodeClass: () => {
      return 'item';
    }
  };

  @ViewChild('tree') treeComponent: TreeComponent;

  get isShowModelsTree() {
    return this.data.projects && this.data.projects.length > 0;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: Pix4dProjectsDialogData,
              private matDialogRef: MatDialogRef<Pix4dSelectProjectsComponent>) {
  }

  ngAfterViewInit() {
    if (this.data.alreadySelectedProjects.length > 0) {
      this.initSelectedNodes();
    }
  }

  initSelectedNodes() {

    const treeModel: TreeModel = this.treeComponent.treeModel;

    const selectedNodeIds = this.data.alreadySelectedProjects.map(node => node.Id);

    const selectedNodesDomElements = [];

    selectedNodeIds.forEach(selectedNodeId => selectedNodesDomElements.push(treeModel.getNodeById(selectedNodeId)))

    selectedNodesDomElements.forEach(node => node.toggleSelected(true));
  }

  onSelectModels(event) {

    event.node.setIsActive(true, true);

    this.selectedNodes.push(event.node.data);
  }

  onDeselectModels(event) {

    event.node.setIsActive(false, true);

    this.selectedNodes = this.selectedNodes.filter(node => node.Id !== event.node.data.Id);
  }

  onSaveModels() {
    this.matDialogRef.close(this.selectedNodes);
  }

  onCloseDialog() {
    this.matDialogRef.close();
  }

}
