import { Component, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ImageCropComponent } from '../image-crop/image-crop.component';
import { environment } from '../../../../environments/environment';
import { InfocardImage } from '../infocard-image';
import { snackBarConfig } from '../../core.module';
import { HttpClient } from '@angular/common/http';
import { TranslationHandleService } from '../../translate/translation-handle.service';
import { Subscription } from 'rxjs';

const PHRASES_FOR_TRANSLATE = [
  {
    updatePhoto: 'system.updatePhoto',
  },
];

@Component({
  selector: 'mee-infocard-images',
  templateUrl: './infocard-images.component.html',
  styleUrls: ['./infocard-images.component.scss'],
})
export class InfocardImagesComponent implements OnDestroy {

  imgUrl: string;

  images: InfocardImage [];

  selectedImage: InfocardImage;

  searchWord = '';

  localImages: InfocardImage [];

  subs: Subscription[] = [];

  translationMap: Map<string, string>;

  constructor(private dialog: MatDialog,
              private http: HttpClient,
              public dialogRef: MatDialogRef<InfocardImagesComponent>,
              private snackBar: MatSnackBar,
              private translationHandleService: TranslationHandleService) {

    this.translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);

    this.subs.push(
      this.translationHandleService.getTranslationMap().subscribe(translationMap => {
        this.translationMap = translationMap;
      }),
    );
  }

  openDialog(data) {
    const file = this.getFirstFile(data);
    if (!file) {
      return;
    }
    if (file && !this.isImageFile(file)) {
      this.snackBar.open(this.translationMap.get('updatePhoto'), 'OK', snackBarConfig);
      return;
    }

    this.subs.push(
      this.dialog.open(ImageCropComponent, {
        data: file,
      }).afterClosed().subscribe((confirm: any) => {
        if (!confirm) {
          return;
        }

        const formData = new FormData();
        formData.append('file', confirm, 'index.png');

        this.http.post(`${environment.api}/media/PlacemarkImagePreview`, formData)
          .subscribe((res: string) => {
            this.imgUrl = res;
            this.closeDialog();
          });
      }),
    );
  }

  private isImageFile(file) {
    return ['image/gif', 'image/jpeg', 'image/png'].some(type => type === file.type);
  }

  private getFirstFile(data) {
    return (data.target.files as FileList).item(0);
  }

  closeDialog() {
    this.dialogRef.close(this.imgUrl);
  }

  clearFilter() {
    this.searchWord = '';
    this.filterLocalImages('');
  }

  filterImages(event) {
    this.searchWord = event.target.value;
    this.filterLocalImages(this.searchWord);
  }

  private filterLocalImages(word: string) {
    if (!this.images) {
      return;
    }
    this.localImages = this.images.filter(image => {
      return [image.Name, image.Tag]
        .map(item => item.toLowerCase())
        .some(value => value.includes(word.toLowerCase()));
    });
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub && sub.unsubscribe());
    this.translationHandleService.cleanTranslationArray();
  }

}
