import { CrudOperations } from '../enums/crud-operations.enum';

export const RELATIVE_PERMISSIONS: Record<CrudOperations, { allowRelative: CrudOperations[], disallowRelative: CrudOperations[] }> = {
    Read: {
      allowRelative: [],
      disallowRelative: [CrudOperations.Update, CrudOperations.Create, CrudOperations.Delete]
    },
    Update: {
      allowRelative: [CrudOperations.Read],
      disallowRelative: [CrudOperations.Create, CrudOperations.Delete]
    },
    Create: {
      allowRelative: [CrudOperations.Read, CrudOperations.Update],
      disallowRelative: [CrudOperations.Delete]
    },
    Delete: {
      allowRelative: [CrudOperations.Read, CrudOperations.Update, CrudOperations.Create],
      disallowRelative: []
    },
  };
