import { NgModule } from '@angular/core';
import { IconSidenavDirective } from './sidenav/icon-sidenav.directive';
import { SidenavItemComponent } from './sidenav/sidenav-item/sidenav-item.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { AdminComponent } from './admin/admin.component';
import { BreadcrumbsComponent } from './breadcrumb/breadcrumb.component';
import { ToolbarUserButtonComponent } from './toolbar/toolbar-user-button/toolbar-user-button.component';
import { ToolbarNotificationsComponent } from './toolbar/toolbar-notifications/toolbar-notifications.component';
import { SearchComponent } from './toolbar/search/search.component';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { QuickpanelComponent } from './quickpanel/quickpanel.component';
import { BreadcrumbService } from './breadcrumb/breadcrumb.service';
import { SidenavService } from './sidenav/sidenav.service';
import { MediaReplayService } from './sidenav/mediareplay/media-replay.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatCommonModule } from './mat-common.module';
import { MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { DateFormatterService } from '../services/date-formatter.service';
import { TranslateModule } from '@ngx-translate/core';
import { CategoriesService } from '../modules/company/services/categories.service';
import { RouterPrevService } from '../services/router-prev.service';
import { SchedulingService } from '../modules/sources/components/source-form/source-form-scheduling/services/scheduling.service';
import { MisclickDirective } from './misclick.directive';
import { TeamProjectSelectorComponent } from './team-project-selector/team-project-selector.component';
import { OverlayModule } from '@angular/cdk/overlay';

export const snackBarConfig: MatSnackBarConfig = {
  duration: 5000,
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatCommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    OverlayModule,
  ],
  declarations: [
    TeamProjectSelectorComponent,
    SidenavComponent,
    SidenavItemComponent,
    IconSidenavDirective,
    SearchComponent,
    ToolbarNotificationsComponent,
    ToolbarUserButtonComponent,
    BreadcrumbsComponent,
    AdminComponent,
    QuickpanelComponent,
    ToolbarComponent,
    NotFoundComponent,
    MisclickDirective,
  ],
  providers: [
    MediaReplayService,
    BreadcrumbService,
    SidenavService,
    DateFormatterService,
    CategoriesService,
    SchedulingService,
    RouterPrevService,
  ],
  exports: [],
})
export class CoreModule {
}
