import { Injectable } from '@angular/core';
import { ColorPickerService } from 'ngx-color-picker';

@Injectable({
  providedIn: 'root',
})
export class ColorHelperService {

  bla: string;

  constructor(private colorPickerService: ColorPickerService) {}

  // set the color of text on select color blocks depends on selected color

  getTextColor(color: string): string {
    const defaultColor = '#000';

    if (!color) {
      return defaultColor;
    }
    let textColor = defaultColor;

    const hsva = this.colorPickerService.stringToHsva(color, true);

    if (hsva) {
      const rgba = this.colorPickerService.denormalizeRGBA(this.colorPickerService.hsvaToRgba(hsva));
      if ((rgba.r < 70 || rgba.g < 70) && rgba.a > 0.4) {
        textColor = 'white';
      }
    } else {
      textColor = defaultColor;
    }

    return textColor;
  }
}
