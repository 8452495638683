// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  padding: 0 8px;
}

.header {
  display: flex;
  justify-content: space-between;
}
.header h3 {
  font-weight: bold;
}
.header .close-icon {
  cursor: pointer;
}

.form .date-range-picker {
  width: 240px;
  margin-bottom: 23px;
}
.form .ranges {
  margin-bottom: 48px;
}
.form .ranges mat-chip {
  cursor: pointer;
}
.form .include-archived {
  margin-bottom: 48px;
}
.form .export-button button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/export-data/export-data-modal/export-data-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,eAAA;AAAJ;;AAKE;EACE,YAAA;EACA,mBAAA;AAFJ;AAKE;EACE,mBAAA;AAHJ;AAII;EACE,eAAA;AAFN;AAME;EACE,mBAAA;AAJJ;AAQI;EACE,WAAA;AANN","sourcesContent":[":host {\n  display: block;\n  padding: 0 8px;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n\n  h3 {\n    font-weight: bold;\n  }\n\n  .close-icon {\n    cursor: pointer;\n  }\n}\n\n.form {\n  .date-range-picker {\n    width: 240px;\n    margin-bottom: 23px;\n  }\n\n  .ranges {\n    margin-bottom: 48px;\n    mat-chip {\n      cursor: pointer;\n    }\n  }\n\n  .include-archived {\n    margin-bottom: 48px;\n  }\n\n  .export-button {\n    button {\n      width: 100%;\n    }\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
