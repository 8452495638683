import { Component, OnInit } from '@angular/core';
import { TableBuilder } from '../../../../core/table/table-builder';
import { ISource } from '../../models/source.interface';
import { Process } from '../../models/process.interface';
import { ITableColumn } from '../../../../core/table/interfaces/table-column.interface';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessService } from './process.service';
import { ICompany } from '../../../companies/shared/interfaces/company.interface';
import { ITableAction } from '../../../../core/table/interfaces/table-action.interface';

@Component({
  selector: 'mee-process-list',
  templateUrl: './process-list.component.html',
  styleUrls: ['./process-list.component.scss']
})
export class ProcessListComponent implements OnInit {

  public tableBuilder: TableBuilder<Process[]>;

  constructor(
    protected processService: ProcessService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    const columns: ITableColumn[] = [
      {
        key: 'ProcessId',
        title: 'ProcessId'
      },
      {
        key: 'DataSourceId',
        title: 'DataSourceId'
      },
      {
        key: 'InitiatorId',
        title: 'InitiatorId'
      },
      {
        key: 'Status',
        title: 'Status'
      },
      {
        key: 'StartDateUtc',
        title: 'StartDateUtc'
      }
    ];

    const rowActions: ITableAction[] = [
      {
        tooltip: 'Reports',
        icon: 'list_alt',
        action: (item: Process) => {
          this.router.navigate([`${item.CompanySlug}/${item.DataSourceSlug}/${item.ProcessId}/report`], { relativeTo: this.route });
        }
      }
    ];

    const headActions: ITableAction[] = [
      {
        tooltip: 'Update',
        icon: 'autorenew',
        action: item => {
          this.updateList();
        }
      }
    ];

    this.tableBuilder = new TableBuilder<Process[]>({
      list: this.route.snapshot.data.processes,
      service: processService
    }, {
      columns: columns,
      rowActions: rowActions,
      headActions: headActions
    });
  }

  ngOnInit() {
  }

  updateList() {
    this.processService.getProcessList().subscribe((data: Process[][]) => {
      this.tableBuilder.dataChange.next(data);
    });
  }

}
