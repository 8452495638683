// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs {
  margin: 0;
  padding: 0 16px;
  list-style: none;
  font-weight: 400;
}
.breadcrumbs li {
  line-height: 64px;
}
.breadcrumbs li a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.breadcrumbs li a:hover {
  text-decoration: underline;
}
.breadcrumbs li span {
  font-weight: 600;
}
.breadcrumbs li mat-icon {
  margin: 0 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/core/breadcrumb/breadcrumb.component.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACF;AACE;EACE,iBAAA;AACJ;AACI;EACE,qBAAA;EACA,cAAA;EACA,eAAA;AACN;AACM;EACE,0BAAA;AACR;AAGI;EACE,gBAAA;AADN;AAII;EACE,aAAA;AAFN","sourcesContent":[".breadcrumbs {\n  margin: 0;\n  padding: 0 16px;\n  list-style: none;\n  font-weight: 400;\n\n  li {\n    line-height: 64px;\n\n    a {\n      text-decoration: none;\n      color: inherit;\n      cursor: pointer;\n\n      &:hover {\n        text-decoration: underline;\n      }\n    }\n\n    span {\n      font-weight: 600;\n    }\n\n    mat-icon {\n      margin: 0 8px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
