import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SourcesFormChangesService {
  private localIsNeedToCancelChanges$ = new BehaviorSubject<boolean>(false);
  isNeedToCancelChanges$ = this.localIsNeedToCancelChanges$.asObservable();

  isNeedToCancelChanges(): void {
    this.localIsNeedToCancelChanges$.next(true);
  }
}
