import { merge } from 'rxjs';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { BehaviorSubject } from 'rxjs';
import { TableBuilder } from './table-builder';
import { map } from 'rxjs/operators';
import filter from 'lodash-es/filter';
import toString from 'lodash-es/toString';
import { DataSourceType } from '../../modules/sources/data-source-type.enum';

export class TableDataSource<T> extends MatTableDataSource<T> {
  // Filtering control
  _filterChange = new BehaviorSubject('');

  get filter(): string {
    if (typeof this._filterChange !== 'undefined') {
      return this._filterChange.value;
    }
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  // Controls of archived items viewing
  _archivedShowChange = new BehaviorSubject<any>({ isArchiveToggle: true, show: false });

  get archivedShow(): boolean {
    return this._archivedShowChange.value.show;
  }

  set archivedShow(show: boolean) {
    this._archivedShowChange.next({ isArchiveToggle: true, show: show });
  }

  filterableFields: Array<string>;
  sortableFields: Array<string>;
  enableDelFiltration = false;
  intermediateDataLength: number;

  constructor(private builder: TableBuilder<T>, private options?) {
    super();

    if (options && options.filterableFields) {
      this.filterableFields = options.filterableFields;
    }

    if (options && options.paginator) {
      this.paginator = options.paginator;
    }

    if (options && options.sort) {
      this.sort = options.sort;
      this.sortableFields = options.sortableFields;
    }

    if (options && options.enableDelFiltration) {
      this.enableDelFiltration = options.enableDelFiltration;
    }

    if (options && options.sortingDataAccessor) {
      this.sortingDataAccessor = options.sortingDataAccessor;
    }
  }

  connect(): BehaviorSubject<T[]> {
    const displayDataChanges: Array<any> = [this.builder.dataChange];

    if (this.hasArchivedDataControl()) {
      displayDataChanges.push(this._archivedShowChange);
    }
    if (this.hasFiltering()) {
      displayDataChanges.push(this._filterChange);
    }
    if (this.hasSorting()) {
      displayDataChanges.push(this.sort.sortChange);
    }
    if (this.hasPagination()) {
      displayDataChanges.push(this.paginator.page);
    }

    const tableData = merge(...displayDataChanges).pipe(
      map(event => {
        let data = this.builder.list.slice();

        if (this.hasArchivedDataControl()) {
          data = this.sortDeleted(data);
        }

        if (this.hasFiltering()) {
          if (this.filter === '*null') {
            /*For search items without categories (Other)*/

            data = data.filter(i => !i['Category']);
          } else if (this.filter[0] === '*') {
            /*For search items by category name*/

            const searchCategoryName = this.filter.replace('*', '');
            data = data.filter(i => i['Category'] && i['Category'].Name.trim().toLowerCase() === searchCategoryName.trim().toLowerCase());
          } else {
            /*For search items by other parameters*/

            data = data.filter(item => {
              const searchStr = this.getSearchString(item).toLowerCase();
              return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
            });
          }
        }

        this.intermediateDataLength = data.length;

        if (this.hasSorting()) {
          data = this.getSortedData(data);
        }

        if (this.hasPagination()) {
          if (event['isArchiveToggle']) {
            this.resetPagination();
          }

          data = this.getPaginatedData(data);
        }
        return data;
      }),
    );

    const subject = new BehaviorSubject([]);

    tableData.subscribe(data => subject.next(data));

    return subject;
  }

  disconnect(): void {
  }

  /** Returns a sorted copy of the database data. */
  getSortedData(data: T[]): T[] {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    if (this.sortingDataAccessor) {
      data.forEach(item => {
        if (item['MapParsingRules']) {
          item['MapParsingRules'] = this.sortingDataAccessor(item, 'MapParsingRules');
        } else if (item['Duration']) {
          item['Duration'] = this.sortingDataAccessor(item, 'Duration');
        }
      });
    }

    return data.sort((a, b) => {
      const _a = toString(a[this.sort.active]);
      const _b = toString(b[this.sort.active]);

      let propertyA: number | string = '';
      let propertyB: number | string = '';

      if (this.sortableFields.indexOf(this.sort.active) >= 0) {
        [propertyA, propertyB] = [_a.toLowerCase(), _b.toLowerCase()];
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this.sort.direction === 'asc' ? 1 : -1);
    });
  }

  /**  Grab the page's slice of data. */
  getPaginatedData(data: T[]): T[] {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  sortDeleted(data: T[]): T[] {

    if (this.archivedShow) {
      return data.filter(item => {
        return item['IsArchived'] || item['IsCascadeArchived'];
      });
    }

    return data.filter(item => {
      return !item['IsArchived'] && !item['IsCascadeArchived'];
    });
  }

  getSearchString(item): string {
    return this.filterableFields.map(f => item[f] + ' ').join(' ');
  }

  resetPagination() {
    if (!this.hasPagination()) {
      return;
    }
    this.paginator.pageIndex = 0;
  }

  hasPagination(): boolean {
    return this.paginator != null;
  }

  hasSorting(): boolean {
    return this.sort && this.sortableFields.length > 0;
  }

  hasFiltering(): boolean {
    return this.filterableFields.length > 0;
  }

  hasArchivedDataControl(): boolean {
    return this.enableDelFiltration;
  }
}
