import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CopyConfiguration } from '../models/copy-configuration';
import { CopyConfigurationColumns } from '../models/copy-configuration-columns';
import { MatTableDataSource } from '@angular/material/table';
import { DataSourcesAdapterService } from '../../configuration-tab/data-sources-adapter.service';
import { DataSourceType } from '../../../../../data-source-type.enum';
import { debounceTime, distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'mee-configuration-copy-list',
  templateUrl: './configuration-copy-list.component.html',
  styleUrls: ['./configuration-copy-list.component.scss']
})
export class ConfigurationCopyListComponent implements OnInit, OnDestroy {
  searchControl = new FormControl<string>('');
  copyConfigurationColumns = CopyConfigurationColumns;
  displayedColumns = [
    CopyConfigurationColumns.Description,
    CopyConfigurationColumns.Slug,
    CopyConfigurationColumns.Type,
    CopyConfigurationColumns.Favorite
  ];
  dataSource = new MatTableDataSource<CopyConfiguration>();

  private dataSourcesAdapterService = inject(DataSourcesAdapterService);
  private readonly destroy$ = new Subject<void>();

  @Output() onSelectConfiguration = new EventEmitter<CopyConfiguration>()

  @Input() set copyConfigurationList(list: CopyConfiguration[]) {
    this.dataSource.data = list;
  }

  get isSearchValue(): boolean {
    const searchValue = this.searchControl.value;

    return searchValue && searchValue.length > 0;
  }

  get isConfigurationListEmpty(): boolean {
    return this.dataSource.data.length === 0;
  }

  ngOnInit(): void {
    this.initSearchFieldObserver();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getSourceDescription(description: string): string {
    return description && description.length > 0 ? description : '-';
  }

  getSourceTypeTitle(type: DataSourceType): string {
    return this.dataSourcesAdapterService.getSourceTitle(type);
  }

  clearSearch(): void {
    this.searchControl.reset('');
  }

  selectConfiguration(configuration: CopyConfiguration): void {
    this.onSelectConfiguration.emit(configuration);
  }

  private initSearchFieldObserver(): void {
    this.searchControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      map((value: string) => (value ? value.toLowerCase().trim() : '')),
      tap((value: string) => this.dataSource.filter = value),
      takeUntil(this.destroy$)
    ).subscribe();
  }
}
