import { ChangeDetectionStrategy, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { TableBuilder } from '../../../../../../core/table/table-builder';
import { Process } from '../../../../models/process.interface';
import * as moment from 'moment';
import { DateFormatterService } from '../../../../../../services/date-formatter.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ProcessStatus } from '../../../../../../shared/interfaces/process-status.enum';
import { DataSourcesAdapterService } from '../../../source-configuration-list/components/configuration-tab/data-sources-adapter.service';
import { Subject } from 'rxjs';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { ProcessingDetailsModalData } from '../../../../models/processing-details-modal-data';
import { PROCESSING_HISTORY_CALLBACK, PROCESSING_HISTORY_DATA } from '../../../../models/processing-history-data';

@Component({
  selector: 'mee-processing-history-table',
  templateUrl: './processing-history-table.component.html',
  styleUrls: ['./processing-history-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessingHistoryTableComponent implements OnDestroy {
  tableBuilder: TableBuilder<Process>;

  processingHistoryProcesses: Process[];

  isDataLoading = true;

  displayedColumns = ['StartDateUtc', 'EndDateUtc', 'Duration', 'Status', 'Progress', 'InitiatorName', 'DataSourceType'];

  dataSource = new MatTableDataSource<Process>();

  private destroy$ = new Subject<void>();

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(
    private dateFormatterService: DateFormatterService,
    private dataSourcesAdapterService: DataSourcesAdapterService,
    @Inject(PROCESSING_HISTORY_DATA) private processingList: Process[],
    @Inject(PROCESSING_HISTORY_CALLBACK) private openDetails: (id: ProcessingDetailsModalData) => void,
  ) {
    this.processingHistoryProcesses = processingList;
    this.dataSource.data = this.processingHistoryProcesses;
  }

  viewProcessDetails(process: Process): void {
    const detailsData: ProcessingDetailsModalData = {
      id: process.ProcessId,
      companySlug: process.CompanySlug,
      sourceSlug: process.DataSourceSlug,
    };

    this.openDetails(detailsData);
  }

  getProcessDuration(process: Process): string {
    if (process.Status === ProcessStatus.InProgress) {
      return '';
    }

    const startDateUtc = moment(process.StartDateUtc);

    const endDateUtc = moment(process.EndDateUtc);

    return this.dateFormatterService.getDurationBetweenTwoDates(startDateUtc, endDateUtc, 'HMS');
  }

  getProcessProgress(process: Process): string {
    if (process.Progress !== 100) {
      return process.Progress.toFixed(1) + '%';
    } else {
      return process.Progress + ' %';
    }
  }

  getIsShowProgress(process: Process): boolean {
    return process.Status === ProcessStatus.InProgress || process.Status === ProcessStatus.Finished;
  }

  getSourceType(process: Process): string {
    return this.dataSourcesAdapterService.getSourceTitle(process.DataSourceType);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
