// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found {
  text-align: center;
}
.not-found h1 {
  font-size: 100px;
  margin-bottom: 0;
}
.not-found a {
  color: #2B303B;
}`, "",{"version":3,"sources":["webpack://./src/app/core/errors/not-found/not-found.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,gBAAA;EACA,gBAAA;AACJ;AAEE;EACE,cAAA;AAAJ","sourcesContent":[".not-found {\n  text-align: center;\n\n  h1 {\n    font-size: 100px;\n    margin-bottom: 0;\n  }\n\n  a {\n    color: #2B303B;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
