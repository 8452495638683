import { environment } from '../../../environments/environment';
import { IRestService } from './rest-service.interface';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

export function RestClass(route: string, api?: string): ClassDecorator {
  return function (target: Function) {
    const lastRoute = target.prototype.route;
    if (lastRoute === undefined) {
      const apiUrl = api ? api : environment.adminApi;
      target.prototype.route = `${apiUrl}${route}`;
      return;
    }

    target.prototype.route = lastRoute;
  };
}

export interface IOptions {
  params: { [key: string]: string }
}

/**
 * Base service for REST services
 */
export abstract class RestService<T> implements IRestService<T> {

  /**
   * ID key for getting item
   */
  idKey = 'Id';

  /**
   * Base route to request
   */
  private _route: string;

  public baseUrl = environment.adminApi;
  identityBaseUrl = environment.identityApi;

  public params: HttpParams = new HttpParams();

  /**
   * Base class for REST services
   * @param http
   */
  constructor(protected http: HttpClient) {
  }

  /**
   * Get base route
   * @returns {string}
   */
  get route(): string {
    return this._route;
  }

  /**
   * Set base route
   * @param route
   */
  set route(route: string) {
    this._route = route;
  }

  protected getHttpParamsForSelectedScope(teamSlug?: string, companySlug?: string): HttpParams {
    let params = new HttpParams();

    if (teamSlug) {
      params = params.set('teamScopeSlug', teamSlug)
    }

    if (companySlug) {
      params = params.set('companyScopeSlug', companySlug)
    }

    return params;
  }

  /**
   * Get route with params
   * @param id
   * @returns {string}
   */
  public getRoute(id?: any): string {
    return this.route + (id ? '/' + id : '');
  }

  /**
   * Request to list
   * @param options
   * @returns {Observable<any>}
   */
  public list(options?: any): Observable<any> {
    return this.http.get(this.route, {params: this.createHttpParams(options)});
  }

  /**
   * Request to one object
   * @param id
   * @param options
   * @returns {Observable<any>}
   */
  public get(id: any, options?: any): Observable<any> {
    return this.http.get(this.getRoute(id), {params: this.createHttpParams(options)});
  }

  /**
   * Request to create object
   * @param data
   * @param options
   * @returns {Observable<any>}
   */
  public create(data: T | {}, options?: any): Observable<T> {
    return this.http.post<T>(this.route, data, {params: this.createHttpParams(options)});
  }

  /**
   * Request to update object
   * @param id
   * @param data
   * @param options
   * @returns {Observable<any>}
   */
  public update(id: any, data: T, options?: any): Observable<T> {

    if (typeof id === 'object') {
      id = id[this.idKey];
    }

    return this.http.put<T>(this.getRoute(id), data, {params: this.createHttpParams(options)});
  }

  /**
   * Request to remove object
   * @param id
   * @param options
   * @returns {Observable<any>}
   */
  public remove(id: any, options?: any): Observable<any> {

    if (typeof id === 'object') {
      id = id[this.idKey];
    }

    return this.http.delete(options && options.url ? options.url : this.getRoute(id));
  }

  /*Return total amount of objects for dashboard page*/

  public getTotalCount(companySlug?: string): Observable<number> {

    let params;

    if (companySlug) {
      params = new HttpParams()
        .set('companySlug', companySlug);
    }

    return this.http.get<number>(this.getRoute('total-count'), {params: params})
  }

  /**
   * Create httpParams
   * @param options {IOptions}
   * @returns {HttpParams}
   */

  createHttpParams(options: IOptions): HttpParams {

    let params;
    let httpParams: HttpParams = new HttpParams();

    if (options) {
      params = options.params;

      Object.keys(params).forEach(param => {
        if (typeof params[param] === 'string') {
          httpParams = httpParams.set(param, params[param]);
        }
      });
    }

    return httpParams;
  }
}
