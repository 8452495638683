import { EventEmitter, Injectable } from '@angular/core';
import { SidenavItem, SidenavItemsTypes } from './sidenav-item/sidenav-item.model';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import find from 'lodash-es/find';
import each from 'lodash-es/each';
import { MatDrawerMode } from '@angular/material/sidenav';

@Injectable()
export class SidenavService {
  private _itemsSubject: BehaviorSubject<SidenavItem[]> = new BehaviorSubject<SidenavItem[]>([]);
  private _items: SidenavItem[] = [];
  items$: Observable<SidenavItem[]> = this._itemsSubject.asObservable();

  private _currentlyOpenSubject: BehaviorSubject<SidenavItem[]> = new BehaviorSubject<SidenavItem[]>([]);
  private _currentlyOpen: SidenavItem[] = [];
  currentlyOpen$: Observable<SidenavItem[]> = this._currentlyOpenSubject.asObservable();

  public mode: BehaviorSubject<MatDrawerMode> = new BehaviorSubject<MatDrawerMode>('side');

  onOpen: EventEmitter<boolean> = new EventEmitter();
  onClose: EventEmitter<boolean> = new EventEmitter();

  private isMobile = false;

  isIconSidenav: boolean;

  public onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  public open() {
    this.onOpen.emit(true);
  }

  public close() {
    this.onClose.emit(true);
  }

  show() {
    this.setVisible(false);
  }

  hide() {
    this.setVisible(true);
  }

  toggle() {
    this.isIconSidenav = !this.isIconSidenav;
    this.onToggle.emit(this.isIconSidenav);
  }

  setMobileMode(on: boolean) {
    this.isMobile = on;
    if (this.isMobile) {
      this.mode.next('over');
    } else {
      this.mode.next('side');
    }
  }

  toggleMobileMode() {
    this.setMobileMode(!this.isMobile);
  }

  get opened() {
    return this.isIconSidenav;
  }

  set opened(opened) {
    this.onToggle.emit(opened);
    this.setVisible(opened);
  }

  setVisible(visible) {
    this.isIconSidenav = visible;
    this.onToggle.emit(this.isIconSidenav);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  addItem(
    name: string,
    icon: string,
    route: any,
    position: number,
    svgIcon?: string,
    type = SidenavItemsTypes.Item,
    link?: string,
    badge?: string,
    badgeColor?: string,
    customClass?: string,
  ) {
    const item = new SidenavItem({
      name: name,
      icon: icon,
      route: route,
      subItems: [],
      position: position || 99,
      svgIcon: svgIcon,
      badge: badge || null,
      badgeColor: badgeColor || null,
      customClass: customClass || null,
      type: type,
      link: link
    });

    this._items.push(item);
    this._itemsSubject.next(this._items);

    return item;
  }

  addSeparator(position: number) {
    const item = new SidenavItem({
      name: '',
      icon: '',
      route: '',
      subItems: [],
      position: position || 99,
      badge: null,
      badgeColor: null,
      customClass: null,
      type: SidenavItemsTypes.Separator,
    });

    this._items.push(item);
    this._itemsSubject.next(this._items);

    return item;
  }

  addSubItem(parent: SidenavItem, name: string, route: any, position: number) {
    const item = new SidenavItem({
      name: name,
      route: route,
      parent: parent,
      subItems: [],
      position: position || 99,
    });

    parent.subItems.push(item);
    this._itemsSubject.next(this._items);

    return item;
  }

  removeItem(item: SidenavItem) {
    const index = this._items.indexOf(item);
    if (index > -1) {
      this._items.splice(index, 1);
    }

    this._itemsSubject.next(this._items);
  }

  isOpen(item: SidenavItem) {
    return this._currentlyOpen && this._currentlyOpen.indexOf(item) !== -1;
  }

  toggleCurrentlyOpen(item: SidenavItem) {
    let currentlyOpen = this._currentlyOpen;

    if (this.isOpen(item)) {
      if (currentlyOpen.length > 1) {
        currentlyOpen.length = this._currentlyOpen.indexOf(item);
      } else {
        currentlyOpen = [];
      }
    } else {
      currentlyOpen = this.getAllParents(item);
    }

    this._currentlyOpen = currentlyOpen;
    this._currentlyOpenSubject.next(currentlyOpen);
  }

  getAllParents(item: SidenavItem, currentlyOpen: SidenavItem[] = []) {
    currentlyOpen.unshift(item);

    if (item.hasParent()) {
      return this.getAllParents(item.parent, currentlyOpen);
    } else {
      return currentlyOpen;
    }
  }

  nextCurrentlyOpen(currentlyOpen: SidenavItem[]) {
    this._currentlyOpen = currentlyOpen;
    this._currentlyOpenSubject.next(currentlyOpen);
  }

  nextCurrentlyOpenByRoute(route: string) {
    let currentlyOpen = [];

    const item: any = this.findByRouteRecursive(route, this._items);

    if (item && item.hasParent()) {
      currentlyOpen = this.getAllParents(item);
    } else if (item) {
      currentlyOpen = [item];
    }

    this.nextCurrentlyOpen(currentlyOpen);
  }

  findByRouteRecursive(route: string, collection: SidenavItem[]) {
    let result = find(collection, { route: route });

    if (!result) {
      each(collection, item => {
        if (item.hasSubItems()) {
          const found = this.findByRouteRecursive(route, item.subItems);

          if (found) {
            result = found;
            return false;
          }
        }
      });
    }

    return result;
  }

  get currentlyOpen() {
    return this._currentlyOpen;
  }

  getSidenavItemByRoute(route) {
    return this.findByRouteRecursive(route, this._items);
  }

  clear() {
    this._items = [];
    this._itemsSubject.next(this._items);
  }
}
