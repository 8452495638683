import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { CopyConfiguration } from '../components/copy-config-modal/models/copy-configuration';
import { CopyConfigurationSourceType } from '../components/copy-config-modal/models/copy-configuration-source-type';
import { CopyConfigOptionsSendData } from '../components/copy-config-modal/models/copy-config-options-send-data';
import { RestService } from '../../../../../services/rest/rest.service';
import { IdentityService } from '../../../../../services/identity.service';
import { CopyConfigResponse } from '../components/copy-config-modal/models/copy-config-response';

@Injectable({
  providedIn: 'root',
})
export class CopyConfigService extends RestService<unknown> {
  constructor(private identityService: IdentityService, protected http: HttpClient) {
    super(http);
  }

  getCopyConfigurationsList(
    destinationSourceSlug: string,
    companySlug: string,
    type: CopyConfigurationSourceType,
  ): Observable<CopyConfiguration[]> {
    let params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    params = params.append('type', type);
    params = params.append('offset', 0);
    params = params.append('limit', 100);

    return this.http.get<CopyConfiguration[]>(`${environment.adminApi}/companies/${companySlug}/sources/copy-config/${destinationSourceSlug}`,
      { params },
    );
  }

  copyConfig(companySlug: string, data: CopyConfigOptionsSendData): Observable<CopyConfigResponse> {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.post<CopyConfigResponse>(`${environment.adminApi}/companies/${companySlug}/sources/copy-config`, data, { params });
  }

  addToFavorites(companySlug: string, sourceSlug: string): Observable<unknown> {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.post<unknown>(`${environment.adminApi}/companies/${companySlug}/sources/${sourceSlug}/favorites`, {}, { params });
  }

  removeFromFavorites(companySlug: string, sourceSlug: string): Observable<unknown> {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.delete<unknown>(`${environment.adminApi}/companies/${companySlug}/sources/${sourceSlug}/favorites`, { params });
  }
}
