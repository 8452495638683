// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datepicker-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.datepicker-wrap mat-form-field {
  width: 144px;
}
.datepicker-wrap label {
  margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/date-picker/date-picker.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,kBAAA;AAAJ","sourcesContent":[".datepicker-wrap {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  mat-form-field {\n    width: 144px;\n  }\n\n  label {\n    margin-right: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
