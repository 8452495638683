import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TeamQrCodeModalData } from '../models/team-qr-code-modal-data';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ICompany } from '../../../companies/shared/interfaces/company.interface';
import { TeamQrCodesService } from '../services/team-qr-codes.service';
import { TeamQrCode } from '../models/team-qr-code';
import { LoadingService } from '../../../../core/loading-overlay/loading.service';
import { TeamQrCodeRequestData } from '../models/team-qr-code-request-data';
import { TeamQrCodePreviewComponent } from '../team-qr-code-preview/team-qr-code-preview.component';
import { TeamQrCodeDataService } from '../services/team-qr-code-data.service';
import * as printJS from 'print-js';

@Component({
  selector: 'mee-team-qr-code-form',
  templateUrl: './team-qr-code-form.component.html',
  styleUrls: ['./team-qr-code-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamQrCodeFormComponent implements OnDestroy {
  qrCodeForm: UntypedFormGroup;
  private selectedCompany: ICompany;
  private destroy$ = new Subject<void>();
  private localQrCode: TeamQrCode;
  readonly defaultQrCodeData = 'https://www.vgis.io/';

  get qrCode(): TeamQrCode {
    return this.localQrCode;
  }

  get isQrCode(): boolean {
    return Boolean(this.localQrCode);
  }

  get isUpdate(): boolean {
    return this.qrCodeModalData.isUpdate;
  }

  get isGenerateDisable(): boolean {
    return !(this.qrCodeForm.dirty && this.qrCodeForm.touched) || this.qrCodeForm.invalid;
  }

  get isLocalCrs(): boolean {
    return this.qrCodeForm.get('IsLocalCrs').value;
  }

  get companySlug(): string {
    if (!this.selectedCompany) {
      return;
    }

    return this.selectedCompany.Slug;
  }

  constructor(
    public loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) private qrCodeModalData: TeamQrCodeModalData,
    private fb: UntypedFormBuilder,
    private matDialogRef: MatDialogRef<TeamQrCodeFormComponent>,
    private qrCodeService: TeamQrCodesService,
    private matDialog: MatDialog,
    private teamQrCodeDataService: TeamQrCodeDataService,
    private teamQrCodesService: TeamQrCodesService
  ) {
    this.initForm();
    this.onCompanySelect();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  save(): void {
    const formValue = this.qrCodeForm.getRawValue();
    const companySlug = formValue.CompanySlug.Slug;
    const teamSlug = formValue.TeamSlug.Slug;

    const qrCodeRequestData: TeamQrCodeRequestData = {
      Note: formValue.Note,
      Latitude: formValue.Latitude,
      Longitude: formValue.Longitude,
      Altitude: formValue.Altitude ?? 0,
      IsLocalCrs: formValue.IsLocalCrs,
    }

    if (this.isUpdate) {
      const qrCodeUpdateData = { ...qrCodeRequestData, TeamSlug: teamSlug };

      this.qrCodeService.updateQrCode(companySlug, teamSlug, this.localQrCode.Id, qrCodeUpdateData).pipe(
        tap(qrCode => this.matDialogRef.close(qrCode)),
        takeUntil(this.destroy$),
      ).subscribe()

      return;
    }
    // Unsubscribe not needed for display loading progress on qr code image refs #54417
    this.qrCodeService.createQrCode(companySlug, teamSlug, qrCodeRequestData).pipe(
      tap(qrCode => {
        this.teamQrCodeDataService.setQrCodeDataForPreview(qrCode);
        this.teamQrCodeDataService.setQrCodeRefreshState(true);
      }),
    ).subscribe()

    // The create form is not closed after the request is completed because preview is imposed on it

    this.matDialogRef.close();

    this.matDialog.open<TeamQrCodePreviewComponent, null, null>(TeamQrCodePreviewComponent, {
      data: null,
      width: '392px',
      autoFocus: false
    })
  }

  download(): void {
    this.teamQrCodesService.exportQrCode(this.qrCode.CompanySlug, this.qrCode.TeamSlug, this.qrCode.Id).pipe(
      tap(blob => this.teamQrCodeDataService.downloadQrCode(blob, 'vGIS QR Code.pdf')),
      takeUntil(this.destroy$)
    ).subscribe()
  }

  print(): void {
    this.teamQrCodesService.exportQrCode(this.qrCode.CompanySlug, this.qrCode.TeamSlug, this.qrCode.Id).pipe(
      tap(blob => {
        const url = window.URL.createObjectURL(blob);
        printJS(url);
      }),
      takeUntil(this.destroy$)
    ).subscribe()
  }

  private initForm(): void {
    this.qrCodeForm = this.fb.group({
      Note: [],
      CompanySlug: ['', Validators.required],
      TeamSlug: ['', Validators.required],
      IsLocalCrs: [false],
      Longitude: ['', Validators.required],
      Latitude: ['', Validators.required],
      Altitude: [],
    });

    if (this.isUpdate) {
      this.localQrCode = this.qrCodeModalData.qrCode;

      this.qrCodeForm.patchValue(this.localQrCode);
      this.qrCodeForm.get('CompanySlug').disable();
    }
  }

  private onCompanySelect(): void {
    this.qrCodeForm.get('CompanySlug').valueChanges.pipe(
      filter(selectedCompany => typeof selectedCompany === 'object'),
      tap(company => this.selectedCompany = company),
      takeUntil(this.destroy$)
    ).subscribe()
  }
}
