import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestClass, RestService } from '../../../services/rest/rest.service';
import { Observable, Subject } from 'rxjs';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ICompany } from '../../companies/shared/interfaces/company.interface';
import { BehaviorSubject } from 'rxjs';
import { CustomMapsList } from './interfaces/custom-maps-list.type';
import { CustomMap } from './interfaces/custom-map.type';
import { ArcGisMapProvider } from './interfaces/arcgis-map-provider';
import { WmtsMapProvider } from './interfaces/wmts-map-provider';
import { WmsMapProvider } from './interfaces/wms-map-provider';
import { CustomMapType } from './custom-map-type.enum';
import { WmsFieldsRequestData } from './interfaces/wms-fields-request-data';
import { LayerDependsWmsFieldsData } from './interfaces/layer-depends-wms-fields-data';
import { WmtsFieldsRequestData } from './interfaces/wmts-fields-request-data';
import { LayerDependsWmtsFieldsData } from './interfaces/layer-depends-wmts-fields-data';
import { ExcludedGlobalErrorsLogicUrlsService } from '../../../shared/excluded-global-errors-logic-urls.service';
import { IdentityService } from '../../../services/identity.service';

export interface IAddEditDialogInfo {
  isCreateMode: boolean;
  selectedCompany?: ICompany;
  selectedCustomMap?: CustomMap;
}

@Injectable({
  providedIn: 'root',
})
@RestClass('/companies')
export class CustomMapsService extends RestService<any> {
  newMapProviderChanged$: Subject<CustomMap> = new Subject();

  editMapProviderChanged$: Subject<CustomMap> = new Subject();

  customMapProviderListChanged$: BehaviorSubject<CustomMapsList> = new BehaviorSubject([]);

  constructor(
    protected http: HttpClient,
    private excludedGlobalErrorsLogicUrlsService: ExcludedGlobalErrorsLogicUrlsService,
    private identityService: IdentityService
  ) {
    super(http);
  }

  getAll(): Observable<CustomMapsList> {
    const url = `${this.baseUrl}/maps`;

    return this.http.get<CustomMapsList>(url);
  }

  getByCompany(companySlug: string): Observable<CustomMap[]> {
    const url = `${this.getRoute()}/${companySlug}/maps`;

    return this.http.get<CustomMap[]>(url);
  }

  getByTeam(companySlug: string, teamSlug: string): Observable<CustomMap[]> {
    const url = `${this.getRoute()}/${companySlug}/teams/${teamSlug}/maps`;

    return this.http.get<CustomMap[]>(url);
  }

  createNewMapProvider(data: ArcGisMapProvider | WmtsMapProvider | WmsMapProvider, companySlug: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.post(this.getRoute() + `/${companySlug}/maptileproviders`, data, { params });
  }

  setNewMapProvider(newMapProvider) {
    this.newMapProviderChanged$.next(newMapProvider);
  }

  deleteMapProvider(mapTileProviderId: string, companySlug: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.delete(this.getRoute() + `/${companySlug}/maptileproviders/${mapTileProviderId}`, { params });
  }

  editMapProvider(mapTileProviderId: string, mapTilesProvider: ArcGisMapProvider | WmtsMapProvider | WmsMapProvider, companySlug: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.put(this.getRoute() + `/${companySlug}/maptileproviders/${mapTileProviderId}`, mapTilesProvider, { params });
  }

  clearCache(companySlug: string, mapTileProviderSlug: string) {
    const url = `${this.getRoute()}/${companySlug}/maptileproviders/${mapTileProviderSlug}/cleanup`;

    return this.http.delete(url);
  }

  getWmsFieldsValueWhileCreateMap(data: WmsFieldsRequestData): Observable<LayerDependsWmsFieldsData> {
    const url = this.getRoute() + `/maptileproviders/wms/layers`;

    this.excludedGlobalErrorsLogicUrlsService.setUrls(url);

    return this.http.post<LayerDependsWmsFieldsData>(url, data);
  }

  getWmtsFieldsValueWhileCreateMap(data: WmtsFieldsRequestData): Observable<LayerDependsWmtsFieldsData> {
    const url = this.getRoute() + `/maptileproviders/wmts/layers`;

    this.excludedGlobalErrorsLogicUrlsService.setUrls(url);

    return this.http.post<LayerDependsWmtsFieldsData>(url, data);
  }

  getWmsFieldsValueWhileEditMap(
    companySlug: string,
    mapTileProviderSlug: string,
    data: WmsFieldsRequestData,
  ): Observable<LayerDependsWmsFieldsData> {
    const url = this.getRoute() + `/${companySlug}/maptileproviders/${mapTileProviderSlug}/wms/layers`;
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    this.excludedGlobalErrorsLogicUrlsService.setUrls(url);

    return this.http.post<LayerDependsWmsFieldsData>(url, data, { params });
  }

  getWmtsFieldsValueWhileEditMap(
    companySlug: string,
    mapTileProviderSlug: string,
    data: WmtsFieldsRequestData,
  ): Observable<LayerDependsWmtsFieldsData> {
    const url = this.getRoute() + `/${companySlug}/maptileproviders/${mapTileProviderSlug}/wmts/layers`;
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    this.excludedGlobalErrorsLogicUrlsService.setUrls(url);

    return this.http.post<LayerDependsWmtsFieldsData>(url, data, { params });
  }

  getTilePreviewKey(companySlug: string, customMap: Partial<CustomMap>): Observable<{ Data: string }> {
    const url = this.getRoute() + `/${companySlug}/maptileproviders/preview`;
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.post<{ Data: string }>(url, customMap, { params });
  }

  getCustomTileRequestUrl(companySlug: string, tileId: string, previewKey: string): string {
    return this.getRoute() + `/${companySlug}/maptileproviders/preview/${previewKey}/{z}/{x}/{y}`;
  }

  setEditMapProvider(editMapProvider: ArcGisMapProvider | WmtsMapProvider | WmsMapProvider) {
    this.editMapProviderChanged$.next(editMapProvider);
  }

  setCustomMapProvidersList(customMapProvidersList: CustomMapsList) {
    this.customMapProviderListChanged$.next(customMapProvidersList);
  }

  onCheckUniqueMapProviderName(customMapNames: string[]): ValidatorFn {
    return (control: AbstractControl) => {
      const isNewCustomMapNameUnique = !customMapNames.includes(control.value.toLowerCase().trim());

      return isNewCustomMapNameUnique ? null : { notUnique: true };
    };
  }

  onCheckUniqueMapServerUrl(customMapsServerUrl: string[]): ValidatorFn {
    return (control: AbstractControl) => {
      const isNewMapServerUrlUnique = !customMapsServerUrl.includes(control.value.toLowerCase().trim());

      return isNewMapServerUrlUnique ? null : { notUnique: true };
    };
  }

  isArcGisMapService(map: CustomMapType) {
    return map === CustomMapType.ArcGis;
  }

  isWMTSMapService(map: CustomMapType) {
    return map === CustomMapType.Wmts;
  }

  isWMSMapService(map: CustomMapType) {
    return map === CustomMapType.Wms;
  }
}
