import {NgxZendeskWebwidgetConfig} from '@nitsanzo/ngx-zendesk-webwidget';
import {ZendeskCommands} from './enums/zendesk-commands';
import {environment} from '../../environments/environment';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  override lazyLoad = true;
  accountUrl = `${environment.zendeskAccountUrl}.zendesk.com`;

  callback(zE) {
    zE(ZendeskCommands.WidgetType, ZendeskCommands.SetLocale, 'en');
  }
}
