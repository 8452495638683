import { Injectable } from '@angular/core';
import { identifierMaxLength, spaceMatch, specChartersMatch } from '../core/utils/validators';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, takeWhile, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AutofillFieldService {

  setAutoFillValue$(sourceControl: AbstractControl, fillControl: AbstractControl): Observable<any> {
    return sourceControl.valueChanges.pipe(
      takeWhile(() => !fillControl.dirty),
      filter(value => value.length <= identifierMaxLength),
      tap(value => {
        const fillValue = this.getTransformedValueForFill(value);
        fillControl.setValue(fillValue);

        if (fillControl.disabled) {
          fillControl.enable();
        }
      }),
    )
  }

  private getTransformedValueForFill(value: string): string {
    const withoutSpecCharters = value.replace(specChartersMatch, '');

    return withoutSpecCharters.replace(spaceMatch, '-').toLowerCase();
  }
}
