import { ITableConfig } from './interfaces/table-config.interface';
import { Directive, EventEmitter, Output } from '@angular/core';
import { IRestService } from '../../services/rest/rest-service.interface';
import { HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ITableBuilder } from './interfaces/table-builder.interface';
import { ITableServer } from './interfaces/table-server.interface';
import { ITableColumn } from './interfaces/table-column.interface';

export interface ITableSource<T> {
  service?: IRestService<T>;
  list?: T[];
}

@Directive()
export class TableBuilder<T> implements ITableBuilder<T>, ITableServer<T> {
  /**
   * Table config
   */
  config: ITableConfig;

  /**
   *
   * Loading indicator
   */
  private _loading: boolean;


  /**
   * 'DataChanged' event
   * @type {BehaviorSubject}
   */
  dataChange: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

  get list(): T[] {
    return this.dataChange.value;
  }

  /**
   * 'Created' event
   * @type {EventEmitter}
   */
  @Output() created: EventEmitter<T> = new EventEmitter();

  /**
   * 'Updated' event
   * @type {EventEmitter}
   */
  @Output() updated: EventEmitter<T> = new EventEmitter();

  /**
   * 'Removed' event
   * @type {EventEmitter}
   */
  @Output() removed: EventEmitter<T> = new EventEmitter();

  @Output() restored: EventEmitter<T> = new EventEmitter();

  /**
   * 'Filtered' event
   * @type {EventEmitter}
   */
  @Output() filtered: EventEmitter<any> = new EventEmitter();

  /**
   * 'Sorted' event
   * @type {EventEmitter}
   */
  @Output() sorted: EventEmitter<ITableColumn> = new EventEmitter();

  /**
   * 'Start loading' event
   * @type {EventEmitter}
   */
  @Output() startLoading: EventEmitter<any> = new EventEmitter();

  /**
   * 'Stop loading' event
   * @type {EventEmitter}
   */
  @Output() endLoading: EventEmitter<any> = new EventEmitter();

  /*On data sources categories changed (only data sources because of server side pagination there)*/

  @Output() categoriesChanged: EventEmitter<boolean> = new EventEmitter();

  /**
   * Service for server loading data
   */
  service: IRestService<T>;

  constructor(source: ITableSource<T>, config?: ITableConfig) {
    this.config = config;

    if (source.service) {
      this.service = source.service;
    }

    if (source.list) {
      this.dataChange.next(source.list);
    }
  }

  /**
   * Delete item
   */
  remove(item: T, removeFromList = true, options?: HttpRequest<unknown>) {

    if (removeFromList) {
      this.removeFromList(item);
    }

    const obs = this.service.remove(item, options);
    obs.subscribe(() => this.removed.emit(item));
    return obs;
  }

  removeFromList(item: T) {
    const index: number = this.list.indexOf(item);
    if (index !== -1) {
      this.list.splice(index, 1);
      this.dataChange.next(this.list);
    }
  }

  fetch(indicator = true) {
    if (!this.service) {
      return null;
    }

    if (indicator) {
      this.loading = true;
    }

    return this.service.list().subscribe(data => {
      this.dataChange.next(data);
      this.loading = false;
    });
  }

  create(data: T): void {
    this.service.create(data).subscribe(res => {
      this.created.emit(data);
      this.fetch();
    });
  }

  update(row: T): Observable<Response> {
    const obs = this.service.update(row, row);
    obs.subscribe(() => this.updated.emit(row));
    return obs;
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(loading) {
    if (loading) {
      this.startLoading.emit();
    } else {
      this.endLoading.emit();
    }

    this._loading = loading;
  }

  search(keywords: string): Observable<Response> {
    this.service.params.set('keywords', keywords);
    const obs = this.service.list();
    obs.subscribe(data => this.filtered.emit(data));
    return obs;
  }

}
