import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { IdentityService } from '../services/identity.service';

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  identityService = inject(IdentityService),
  router = inject(Router)
) => {
  if (identityService.isLoggedIn) {
    return true;
  }

  identityService.redirectUrl = state.url;

  return router.navigate(['/sign-in']);
}
