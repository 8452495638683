import { Component } from '@angular/core';

@Component({
  selector: 'mee-export-data-button',
  templateUrl: './export-data-button.component.html',
  styleUrls: ['./export-data-button.component.scss']
})
export class ExportDataButtonComponent {

  constructor() { }
}
