import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'positiveNumber',
})
export class PositiveNumberPipe implements PipeTransform {
  transform(offset: number): string {
    if (offset > 0) {
      return '+' + offset;
    }

    return offset.toString();
  }
}
