import {Component, EventEmitter, Inject} from '@angular/core';
import * as moment from 'moment';
import {FilePreviewOverlayRef} from '../file-preview-overlay-ref';
import {FILE_PREVIEW_DIALOG_DATA} from '../file-preview-overlay.tokens';
import {Event} from '@angular/router';
import {animate, AnimationEvent, state, style, transition, trigger} from '@angular/animations';
import {filter, map} from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { IssueReport } from '../../field-reports/interfaces/issue-report.interface';

const ANIMATION_TIMINGS = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';

@Component({
  selector: 'mee-file-preview-overlay',
  templateUrl: './file-preview-overlay.component.html',
  styleUrls: ['./file-preview-overlay.component.scss'],
  animations: [
    trigger('fade', [
      state('fadeOut', style({opacity: 0})),
      state('fadeIn', style({opacity: 1})),
      transition('* => fadeIn', animate(ANIMATION_TIMINGS))
    ]),
    trigger('slideContent', [
      state('void', style({transform: 'scale(0.9)', opacity: 0})),
      state('enter', style({transform: 'none', opacity: 1})),
      state('leave', style({transform: 'scale(0.9)', opacity: 0})),
      transition('* => *', animate(ANIMATION_TIMINGS)),
    ])
  ]
})
export class FilePreviewOverlayComponent {

  file: IssueReport;

  animationStateChanged = new EventEmitter<AnimationEvent>();

  loading = true;
  animationState: 'void' | 'enter' | 'leave' = 'enter';

  constructor(
    public dialogRef: FilePreviewOverlayRef,
    @Inject(FILE_PREVIEW_DIALOG_DATA) public data: any) {
    this.setEscHandler();
    this.setFile(data);
  }

  private setEscHandler() {
    fromEvent(document, 'keydown').pipe(
      map(event => (event as KeyboardEvent).key),
      filter(keyCode => keyCode === '27')
    ).subscribe(() => this.dialogRef.close());
  }

  private setFile(data: IssueReport) {
    this.file = data;
  }

  onLoad(event: Event) {
    this.loading = false;
  }

  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  startExitAnimation() {
    this.animationState = 'leave';
  }

  getTime(date) {
    return moment(date).format('MMM d, YYYY, h:mm a');
  }
}
