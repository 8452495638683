import { ITableForm, TableFormMode } from './interfaces/form.interface';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

export abstract class TableDialogForm implements ITableForm {

  /**
   * Mode of form
   */
  mode: TableFormMode;

  /**
   * Form group
   */
  form: UntypedFormGroup;

  constructor(protected formBuilder: UntypedFormBuilder) {
    this.createForm();
  }

  abstract createForm(): void;

  onSubmit(form: UntypedFormGroup): void {
    if (this.mode === TableFormMode.UPDATE) {
      this.update(form);
    } else {
      this.create(form);
    }
  }

  abstract create(data: UntypedFormGroup): void;

  abstract update(data: UntypedFormGroup): void;

  /**
   * Check when create mode on
   * @returns {boolean}
   */
  isCreateMode(): boolean {
    return this.mode === TableFormMode.CREATE;
  }

  /**
   * Check when update mode on
   * @returns {boolean}
   */
  isUpdateMode() {
    return this.mode === TableFormMode.UPDATE;
  }
}
