import { Component, Input, OnInit } from '@angular/core';
import map from 'lodash-es/map';
import { LayerStatistic } from '../../interfaces/layer-statistic';

@Component({
  selector: 'mee-statistic-tab',
  templateUrl: './statistic-tab.component.html',
  styleUrls: ['./statistic-tab.component.scss'],
})
export class StatisticTabComponent implements OnInit {
  @Input() statistic: LayerStatistic;

  constructor() {}

  ngOnInit() {}

  normalizeStatisticValue(object: any) {
    if (typeof object === 'object') {
      return map(object, (value, key) => ({ key, value }));
    }
    return [{ key: '', value: object }];
  }
}
