import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IdentityService} from '../services/identity.service';
import {Router} from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {BehaviorSubject} from 'rxjs';
import {LoadingService} from '../core/loading-overlay/loading.service';
import {snackBarConfig} from '../core/core.module';
import {Identity} from '../interfaces/identity';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Injectable()

/*TODO loading servive and snack bar*/

export class AuthInterceptor implements HttpInterceptor {

  TOKEN_EXPIRED_ERROR_CODE = 'token_not_provided';

  private identityService: IdentityService;

  private isRefreshTokenInProgress = false;
  private refreshToken$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private injector: Injector,
              private loadingService: LoadingService,
              private router: Router, private snackBar: MatSnackBar) {
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({setHeaders: {Authorization: `Bearer ${token}`}});
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.identityService = this.injector.get(IdentityService);

    this.loadingService.run();

    return next.handle(this.addToken(req, this.identityService.token))
      .pipe(
        finalize(() => {
          this.loadingService.stop();
        }),

        catchError((error: HttpErrorResponse) => {
          /*if (error.status === 401 || (error.status === 400 && error.message === this.TOKEN_EXPIRED_ERROR_CODE)) {
            return this.handleExpiredTokenError(req, next);
          }*/
          if (error && error.status === 401) {
            this.handleExpiredTokenError(req, next);
          } else {
            return throwError(error);
          }
        })
      );
  }


  private handleExpiredTokenError(req: HttpRequest<any>, next: HttpHandler) {
    if (this.isRefreshTokenInProgress) {
      return this.refreshToken$.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(() => next.handle(this.addToken(req, this.identityService.token)))
      );
    } else {
      this.isRefreshTokenInProgress = true;

      this.refreshToken$.next(null);

      return this.refreshAccessToken().pipe(
        switchMap((res: Identity) => {
          this.identityService.identity = res;
          this.refreshToken$.next(res);
          return next.handle(this.addToken(req, this.identityService.token));
        }),

        finalize(() => this.isRefreshTokenInProgress = false),

        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.identityService.logout();
          }
          this.snackBar.open(error.statusText, null, snackBarConfig);
          return throwError(error);
        })
      );
    }
  }


  private refreshAccessToken(): Observable<any> {
    return this.identityService.refreshAccessToken();
  }
}
