import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InfocardImagesService } from '../../../../../../../core/infocard-images/infocard-images.service';
import { tap } from 'rxjs/operators';
import { InfocardImage } from '../../../../../../../core/infocard-images/infocard-image';
import { PlacemarkImage } from '../interfaces/placemark-image';

@Component({
  selector: 'mee-info-card-image',
  templateUrl: './info-card-image.component.html',
  styleUrls: ['./info-card-image.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InfoCardImageComponent),
      multi: true,
    },
  ],
})
export class InfoCardImageComponent implements ControlValueAccessor {

  infoCardImageUrl: string | null;

  placemarkImage: PlacemarkImage | null;

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  constructor(private infoCardImagesService: InfocardImagesService) {
    this.getInfoCardImageUrl();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(data: InfocardImage | PlacemarkImage): void {
    this.placemarkImage = data;
  }

  selectInfoCardImages() {
    this.infoCardImagesService.select()
      .pipe(
        tap(image => {

          if (!image) {
            this.infoCardImageUrl = null;
            return;
          }

          let placemarkImageData;

          if (typeof image === 'string') {
            placemarkImageData = {
              Url: image,
            };

            this.infoCardImageUrl = image;
          } else if (image.Id) {

            placemarkImageData = {
              Id: image.Id,
            };

            this.infoCardImageUrl = image.Url;
          }

          this.placemarkImage = { ...placemarkImageData };

          this.onChange(this.placemarkImage);
        }),
      )
      .subscribe();
  }

  resetInfoCardImage() {
    this.infoCardImageUrl = null;
    this.placemarkImage = null;
    this.onChange(this.placemarkImage);
  }

  private getInfoCardImageUrl() {
    if (!this.placemarkImage) {
      this.infoCardImageUrl = null;
      return;
    }

    if (this.placemarkImage.Url && this.placemarkImage.Url.length) {
      this.infoCardImageUrl = this.placemarkImage.Url;
      return;
    }

    this.infoCardImagesService.all()
      .pipe(
        tap(images => {
          if (this.placemarkImage) {
            const neededImage = images.find(image => image.Id === this.placemarkImage.Id);
            this.infoCardImageUrl = neededImage ? neededImage.Url : '';
          }
        }),
      )
      .subscribe();
  }
}
