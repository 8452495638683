import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import { SingleForm } from '../interfaces/single-form';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ALTITUDE_MODES_MODEL } from '../models/altitude-modes.model';
import { ConfigurationFields } from '../enums/configuration-fields.enum';
import { SourceConfiguration } from '../interfaces/source-configuration';
import { Subject } from 'rxjs';
import { ILayer } from '../../../../../../components/layers/shared/models/layer.interface';
import { DataSourceType } from '../../../../../data-source-type.enum';
import { ConfigurationFieldsAdapterService } from '../configuration-fields-adapter.service';
import isEqual from 'lodash-es/isEqual';
import { isDisplayForCurrentSourceType } from '../is-display-for-current-source-type.function';
import { FeatureLayerType } from '../interfaces/feature-layer-type.enum';
import { ColorPickerPosition } from '../enums/color-picker-position';

@Component({
  selector: 'mee-folder-configuration',
  templateUrl: './folder-configuration.component.html',
  styleUrls: ['./folder-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderConfigurationComponent implements SingleForm, OnDestroy {

  form: UntypedFormGroup;

  altitudeModesModel = ALTITUDE_MODES_MODEL;

  verticalDatumOptions = this.configurationFieldsAdapterService.getVerticalDatumOptions();

  measurementSystemOptions = this.configurationFieldsAdapterService.getMeasurementSystemOptions();

  configurationFields = ConfigurationFields;

  localLayers: ILayer[];

  private localNode: SourceConfiguration;

  private destroy$ = new Subject<void>();

  readonly ColorPickerPosition = ColorPickerPosition;

  @Input() sourceType: DataSourceType;
  @Output() updateLayerGroup = new EventEmitter();

  @Input() set layers(layers: ILayer[]) {

    this.localLayers = layers;

    if (layers.length === 0) {
      this.form.get('Layer').disable();
    }
  };

  get layers() {
    return this.localLayers;
  }

  @Input() set node(node: SourceConfiguration) {

    if (node === undefined) {
      return;
    }

    if (isEqual(this.node, node)) {
      return;
    }

    this.localNode = node;

    this.form.patchValue({
      ...node,
      ObjectTypeConfiguration: node.ObjectTypeConfiguration ? node.ObjectTypeConfiguration : {}
    }, { emitEvent: false });
  }

  get node() {
    return this.localNode;
  }

  constructor(private formBuilder: UntypedFormBuilder,
              private configurationFieldsAdapterService: ConfigurationFieldsAdapterService) {
    this.initForm();
  }

  updateLayers(): void {
    this.updateLayerGroup.emit();
  }

  isDisplayForCurrentSourceType(fieldName: ConfigurationFields) {
    return isDisplayForCurrentSourceType(fieldName, FeatureLayerType.Folder, this.sourceType);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      Id: [],
      Layer: [],
      MainColor: [],
      AltitudeMode: [],
      VerticalDatum: [],
      VerticalMeasurementSystem: [],
      DefaultDepth: [],
      IsExcludedFromProcessing: [],
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
