import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { SourceTeam } from '../../../../../teams/models/source-team.interface';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { sortBy } from 'lodash';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { debounceTime, distinctUntilChanged, startWith, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'mee-source-teams',
  templateUrl: './source-teams.component.html',
  styleUrls: ['./source-teams.component.scss'],
})
export class SourceTeamsComponent implements AfterViewInit, OnDestroy {

  dataSource = new MatTableDataSource<SourceTeam>();

  displayedColumns = ['IsEnabled', 'LogoUrl', 'Name', 'Slug', 'CreatedAtUtc'];
  searchControl = new UntypedFormControl();

  private destroy$ = new Subject<void>();

  @ViewChild('search') searchInput: ElementRef<MatInput>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Output() changeSourceTeams = new EventEmitter<string[]>();

  @Input() set sourceTeams(sourceTeams: Array<SourceTeam>) {
    this.dataSource.data = sourceTeams;

    this.dataSource.data = this.getSortedData();
  }

  get isSearchValue(): boolean {
    return this.searchControl.value !== null && this.searchControl.value.length > 0;
  }

  constructor(private formBuilder: UntypedFormBuilder) {
    this.onChangeSearchValue();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  onChangeSearchValue() {
    this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      distinctUntilChanged(),
      tap(value => {
        this.dataSource.filter = value.trim().toLowerCase();

        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
      }),
    ).subscribe();
  }

  changeEnableState(team: SourceTeam) {
    this.dataSource.data = this.dataSource.data.map(sourceTeam => {
      if (team.Id === sourceTeam.Id) {
        return { ...sourceTeam, IsEnabled: !sourceTeam.IsEnabled };
      }
      return sourceTeam;
    });

    const enabledTeamsIds = this.dataSource.data
      .filter(sourceTeam => sourceTeam.IsEnabled)
      .map(sourceTeam => sourceTeam.Id);

    this.changeSourceTeams.emit(enabledTeamsIds);
  }

  clearFilter() {
    this.searchControl.setValue('');
  }

  getSortedData(): SourceTeam[] {
    const sortedByCreated = sortBy(this.dataSource.data, ['CreatedAtUtc']);

    return sortBy(sortedByCreated, ['IsEnabled']).reverse();
  }

  getTeamEditLink(team: SourceTeam): string {
    return `/teams/edit/${team.CompanySlug}/${team.Slug}`;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
