import { Injectable } from '@angular/core';
import { RestClass, RestService } from '../../services/rest/rest.service';
import { Observable } from 'rxjs';
import { IdentityService } from '../../services/identity.service';
import { ICompany } from './shared/interfaces/company.interface';
import { LoadingService } from '../../core/loading-overlay/loading.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CompanyComponentsInfo } from '../company/interfaces/company-components-info';
import { UnusedData } from '../../interfaces/unused-data';

@Injectable({
  providedIn: 'root',
})
@RestClass('/companies')
export class CompaniesService extends RestService<ICompany> {
  idKey = 'Slug';

  /**
   * Companies service
   * @param http
   * @param identityService
   * @param loadingService
   */
  constructor(protected http: HttpClient, private identityService: IdentityService, protected loadingService: LoadingService) {
    super(http);
  }

  getUnusedData(companySlug: string = null): Observable<UnusedData> {
    return this.http.post<UnusedData>(`${this.getRoute()}/${companySlug}/unused-data-details`, {});
  }

  getComponentsInfo(companySlug: string): Observable<CompanyComponentsInfo> {
    return this.http.get<CompanyComponentsInfo>(`${this.getRoute()}/${companySlug}/components-info`);
  }

  /**
   * Get company by companySlug from local identity
   * @returns {any}
   */
  getMyCompany(): Observable<ICompany | null> {
    return new Observable(observer => {
      if (!this.identityService.isCompanyAdmin() || !this.identityService.identity) {
        observer.next(null);
        return observer.complete();
      }

      const slug = this.identityService.companySlug;
      if (slug) {
        return this.get(slug).subscribe(
          data => {
            observer.next(data);
            observer.complete();
          },
          error => observer.error(error.statusText),
        );
      }

      observer.next(null);
      observer.complete();
    });
  }

  getCompanies(isArchived: boolean): Observable<ICompany[]> {
    const url = this.getRoute();

    let params = new HttpParams();

    if (isArchived) {
      params = params.append('archived', isArchived);
    }

    return this.http.get<ICompany[]>(url, { params: params });
  }

  getCompanyBySlug(companySlug: string): Observable<ICompany> {

    const url = this.getRoute(`${companySlug}`);

    return this.http.get<ICompany>(url);
  }

  /**
   * Restore company
   * @param id
   * @returns {Observable<Response>}
   */
  restore(id: any) {
    return this.http.put(this.getRoute(`restore/${id}`), {});
  }

  archive(slug: string): Observable<ICompany> {
    return this.http.delete<ICompany>(this.getRoute(`${slug}`));
  }

  /*get list of admin emails*/

  getAdminEmails(companySlug: string) {
    return this.http.get(`${this.getRoute()}/${companySlug}/admin-emails`, {});
  }

  generateExternalToken(companySlug: string) {
    const httpParams = new HttpParams();

    httpParams.append('companySlug', companySlug);

    const url = `${this.getRoute()}/${companySlug}/external-access-token`;

    return this.http.post(url, { params: httpParams }, { responseType: 'text' });
  }

  generateScanAccessToken(companySlug: string) {
    const httpParams = new HttpParams();

    httpParams.append('companySlug', companySlug);

    const url = `${this.getRoute()}/${companySlug}/vgis-scan-access-token`;

    return this.http.post(url, { params: httpParams }, { responseType: 'text' });
  }

  getSortedCompaniesList(list: ICompany[]): ICompany[] {
    const companiesTrimmed = list.map(company => ({ ...company, Name: company.Name.trim() }));
    const existingCompanies = companiesTrimmed.filter(company => !company.IsArchived);

    return existingCompanies.sort((a, b) => (a.Name < b.Name ? -1 : 1));
  }
}
