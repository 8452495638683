import {Component, HostBinding, OnDestroy} from '@angular/core';
import {routeAnimation} from '../../animations/route.animation';
import {PaginationService} from '../../services/pagination.service';

@Component({
  selector: 'mee-sources',
  templateUrl: './sources.component.html',
  styleUrls: ['./sources.component.scss'],
  animations: [routeAnimation]
})
export class SourcesComponent implements OnDestroy {

  @HostBinding('@routeAnimation') routeAnimation = '';

  constructor(private readonly paginationService: PaginationService) {
  }

  ngOnDestroy(): void {
    this.paginationService.resetAllOptions();
  }
}
