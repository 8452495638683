import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import Swiper, { Navigation } from 'swiper';
import { ImageVideoDialogData } from '../../../modules/field-reports/interfaces/image-video-dialog-data';

@Component({
  selector: 'mee-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.scss']
})
export class ImagePopupComponent implements OnInit {
  @ViewChild('swiper', { static: true }) swiper: ElementRef<HTMLDivElement>;
  @ViewChild('nextBtn', { static: true }) nextBtn: ElementRef<HTMLDivElement>;
  @ViewChild('prevBtn', { static: true }) prevBtn: ElementRef<HTMLDivElement>;

  constructor(@Inject(MAT_DIALOG_DATA) public imageVideoDialogData: ImageVideoDialogData) {
  }

  ngOnInit(): void {
    const swiper = new Swiper(this.swiper.nativeElement, {
      slidesPerView: 1,
      spaceBetween: 40,
      navigation: {
        nextEl: this.nextBtn.nativeElement,
        prevEl: this.prevBtn.nativeElement
      },
      modules: [Navigation]
    })
  }
}
