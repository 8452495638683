import { Injectable } from '@angular/core';
import { RestClass, RestService } from '../../../services/rest/rest.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SourceTeam } from '../models/source-team.interface';
import { TeamInterface } from '../models/team.interface';
import { SourceTeamSendModel } from '../models/source-team-send-model';
import { TeamComponentsInfo } from '../interfaces/team-components-info';
import { IdentityService } from '../../../services/identity.service';

@Injectable({
  providedIn: 'root',
})
@RestClass('/teams?fields=Teammates')
export class TeamsService extends RestService<any> {
  /**
   * Sources service
   * @param http
   */
  constructor(protected http: HttpClient, private identityService: IdentityService) {
    super(http);
  }

  getBaseTeamsUrl(companySlug: string): string {
    return `${this.baseUrl}/companies/${companySlug}/teams`;
  }

  getTeams(companySlug: string): Observable<TeamInterface[]> {
    return this.http.get<TeamInterface[]>(this.getBaseTeamsUrl(companySlug) + '?fields=Teammates');
  }

  getTeam(companySlug: string, teamSlug: string): Observable<TeamInterface> {
    return this.http.get<TeamInterface>(`${this.getBaseTeamsUrl(companySlug)}/${teamSlug}?fields=Teammates,Floors,Workpacks`);
  }

  getTeamComponentsInfo(companySlug: string, teamSlug: string): Observable<TeamComponentsInfo> {
    return this.http.get<TeamComponentsInfo>(`${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/components-info`);
  }

  create(companySlug: string, data: any): Observable<TeamInterface> {
    return this.http.post<TeamInterface>(this.getBaseTeamsUrl(companySlug), data);
  }

  update(companySlug: string, teamSlug: string, data: any): Observable<TeamInterface> {
    return this.http.put<TeamInterface>(`${this.getBaseTeamsUrl(companySlug)}/${teamSlug}`, data);
  }

  removeTeams(slugs: string[]): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}/companies/${this.identityService.companySlug}/teams`, { body: slugs })
  }

  updateSourceTeams(companySlug: string, data: SourceTeamSendModel) {
    const url = `${this.baseUrl}/companies/${companySlug}/teams`;

    return this.http.put(url, data);
  }

  /*Get teams that have access for specific data source*/

  getTeamsForDataSource(companySlug: string, dataSourceId: string): Observable<Array<SourceTeam>> {
    return this.http.get<Array<SourceTeam>>(`${this.baseUrl}/companies/${companySlug}/sources/${dataSourceId}/teams`);
  }

  generateExternalToken(companySlug: string, teamSlug: string) {
    const httpParams = new HttpParams();

    httpParams.append('companySlug', companySlug);
    httpParams.append('teamSlug', teamSlug);

    const url = `${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/external-access-token`;

    return this.http.post(url, { params: httpParams }, { responseType: 'text' });
  }

  generateScanAccessToken(companySlug: string, teamSlug: string) {
    const httpParams = new HttpParams();

    httpParams.append('companySlug', companySlug);
    httpParams.append('teamSlug', teamSlug);

    const url = `${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/vgis-scan-access-token`;

    return this.http.post(url, { params: httpParams }, { responseType: 'text' });
  }

  getSortedTeamsList(list: TeamInterface[]): TeamInterface[] {
    const teamsTrimmed = list.map(team => ({ ...team, Name: team.Name.trim() }));
    const existingTeam = teamsTrimmed.filter(team => !team.IsArchived);

    return existingTeam.sort((a, b) => (a.Name < b.Name ? -1 : 1));
  }
}
