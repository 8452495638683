import { AfterViewInit, Component, Input, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MultiselectHeaderService } from '../../services/multiselect-header.service';

@Component({
  selector: 'mee-multiselect-header',
  templateUrl: './multiselect-header.component.html',
  styleUrls: ['./multiselect-header.component.scss'],
})
export class MultiselectHeaderComponent implements AfterViewInit, OnDestroy {
  @ViewChild('headerContainer', { read: ViewContainerRef, static: false }) containerRef: ViewContainerRef;
  @Input() title: string;
  private destroy$ = new Subject<void>();
  isTemplateEmpty = false;

  constructor(private readonly multiselectHeaderService: MultiselectHeaderService) {}

  ngAfterViewInit(): void {
    this.multiselectHeaderService.template$
      .pipe(
        tap(template => {
          if (template === null) {
            this.isTemplateEmpty = true;
            this.containerRef.clear();

            return;
          }

          this.isTemplateEmpty = false;
          this.containerRef.createEmbeddedView(template);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.containerRef.clear();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
