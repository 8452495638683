import { Injectable } from '@angular/core';
import { RestClass, RestService } from '../../../services/rest/rest.service';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BimNode } from '../components/source-configuration-list/models/bim-node';
import { Bim360ModelsTree } from '../models/bim360-models-tree';
import { ISource } from '../models/source.interface';
import { Pix4dProject } from '../components/source-form/pix4d-select-projects/pix4d-project';
import { TeamDataSource } from '../../teams/interfaces/team-data-source';
import { ConfigurationHint } from '../components/source-configuration-list/models/configuration-hint';
import { ServerPaginationParams } from '../../../interfaces/server-pagination-params';
import { PaginationService } from '../../../services/pagination.service';
import { IdentityService } from '../../../services/identity.service';
import { map } from 'rxjs/operators';
import { ServerPaginatedData } from '../../../interfaces/server-paginated-data';
import { SourceConfiguration } from '../components/source-configuration-list/components/configuration-tab/interfaces/source-configuration';
import {
  ConfigurationStructure,
} from '../components/source-configuration-list/components/configuration-tab/interfaces/configuration-structure';
import { FeatureLayerPlacemark } from '../components/source-configuration-list/interfaces/feature-layer-placemark';
import {
  ObjectTypeConfiguration,
} from '../components/source-configuration-list/components/configuration-tab/interfaces/object-type-configuration';
import { Floor } from '../../teams/models/floor';
import { DataSourceStatus } from '../data-source-status.enum';
import { LayerCoordinates } from '../../components/custom-maps/interfaces/layer-coordinates';
import { SynchronizationProgressInfo } from '../models/synchronization-progress-info';
import { TemplateCreateData } from '../models/template-create-data';
import { SourceTemplate } from '../../components/source-templates/source-template';

@Injectable({ providedIn: 'root' })
@RestClass('/sources')
export class DataSourcesService extends RestService<ISource> {
  /*TODO remove extends from RestService*/

  constructor(protected http: HttpClient, private identityService: IdentityService, private paginationService: PaginationService) {
    super(http);
  }

  getTotalCount(): Observable<number> {
    const companySlug = this.identityService.companySlug;

    let url = `${this.baseUrl}/companies/${companySlug}/sources/total-count`;

    if (this.identityService.isTeamAdmin()) {
      const teamSlug = this.identityService.teamSlug;

      url = `${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/sources/total-count`;
    }

    return this.http.get<number>(url);
  }

  checkLocation(companySlug: string, sourceSlug: string, layerId: string, source: SourceConfiguration): Observable<LayerCoordinates> {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.post<LayerCoordinates>(`${this.baseUrl}/companies/${companySlug}/sources/${sourceSlug}/check-location/${layerId}`, source, { params });
  }

  getTemplates$(optionsForServer: ServerPaginationParams): Observable<SourceTemplate[]> {
    const companySlug = this.identityService.companySlug;
    const params = this.paginationService.getRequestParams(optionsForServer);

    let httpParams = new HttpParams();

    params.forEach((value, key) => {
      httpParams = httpParams.set(key, value);
    });

    return this.http.get<SourceTemplate[]>(`${this.baseUrl}/companies/${companySlug}/sources/templates`, { params: httpParams });
  }

  getTemplatesByType(type: string): Observable<SourceTemplate[]> {
    const companySlug = this.identityService.companySlug;

    return this.http.get<SourceTemplate[]>(`${this.baseUrl}/companies/${companySlug}/sources/${type}/templates`);
  }

  removeTemplate(companySlug: string, id: string): Observable<unknown> {
    return this.http.delete(this.getRouteByCompany(companySlug, 'templates', id));
  }

  getFloors(companySlug: string): Observable<Floor[]> {
    return this.http.get<Floor[]>(`${this.baseUrl}/companies/${companySlug}/floors`);
  }

  getRouteByCompany(companySlug: string, ...params) {
    return this.baseUrl + `/companies/${companySlug}/sources/` + params.join('/');
  }

  getWorkPacks(companySlug: string, sourceSlug: string, modelConfigurationId: string): Observable<any> {
    return this.http.get<any[]>(this.getRouteByCompany(companySlug, sourceSlug, modelConfigurationId, 'workpack-attributes'));
  }

  getStatistic(companySlug: string, sourceSlug: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.get(this.getRouteByCompany(companySlug, sourceSlug, 'statistic'), { params });
  }

  getByCompany(companySlug: string, sourceSlug: string): Observable<ISource> {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.get<ISource>(this.getRouteByCompany(companySlug, sourceSlug), { params });
  }

  getPlacemarks(companySlug: string, sourceSlug: string, placemarkId: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.get(this.getRouteByCompany(companySlug, sourceSlug, 'placemarks', placemarkId), { params });
  }

  updateByCompany(companySlug: string, sourceSlug: string, data: any) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.put(this.getRouteByCompany(companySlug, sourceSlug), data, { params });
  }

  createSource(companySlug: string, data: any): Observable<ISource> {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.post<ISource>(this.getRouteByCompany(companySlug), data, { params: params });
  }

  configuration(companySlug: string, sourceSlug: string): Observable<ConfigurationStructure> {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.get<ConfigurationStructure>(this.getRouteByCompany(companySlug, sourceSlug, 'configuration'), { params });
  }

  updateConfiguration(companySlug: string, sourceSlug: string, configuration: SourceConfiguration | ConfigurationStructure) {

    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.put(this.getRouteByCompany(companySlug, sourceSlug, 'configuration'), configuration, { params });
  }

  importData(companySlug: string, sourceSlug: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.post(this.getRouteByCompany(companySlug, sourceSlug, 'importdata'), {}, { params });
  }

  importLayerData(companySlug: string, sourceSlug: string, layerId: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.post(this.getRouteByCompany(companySlug, sourceSlug, 'importdatalayer', layerId), {}, { params });
  }

  setAsTemplate(companySlug: string, data: TemplateCreateData): Observable<unknown> {
    return this.http.post(this.getRouteByCompany(companySlug, 'templates'), data);
  }

  importMultipleLayersData(companySlug: string, sourceSlug: string, layersIds: string[]) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.post(this.getRouteByCompany(companySlug, sourceSlug, 'importdatalayers'), layersIds, { params });
  }

  syncStructure(companySlug: string, sourceSlug: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.post(this.getRouteByCompany(companySlug, sourceSlug, 'synchronizestructure'), {}, { params });
  }

  getSyncStatus(companySlug: string, sourceSlug: string): Observable<SynchronizationProgressInfo> {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);
    const url = this.getRouteByCompany(companySlug, sourceSlug, 'synchronization', 'inprogress');

    return this.http.get<SynchronizationProgressInfo>(url, { params });
  }

  getFeatureLayersPlacemarks(companySlug: string, sourceSlug: string, featureLayerId: string): Observable<FeatureLayerPlacemark[]> {
    const placemarksRoute = this.getRouteByCompany(companySlug, sourceSlug, 'featurelayers', featureLayerId, 'placemarks');
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.get<FeatureLayerPlacemark[]>(placemarksRoute, { params });
  }

  getFeatureLayersPlacemarksFields(companySlug: string, sourceSlug: string, featureLayerId: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.get(this.getRouteByCompany(companySlug, sourceSlug, 'featurelayers', featureLayerId, 'placemarksfields'), { params });
  }

  getSubtypeConfigurations(companySlug: string, sourceSlug: string, featureLayerId: string, fieldName: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.get(
      this.getRouteByCompany(companySlug, sourceSlug, 'featurelayers', featureLayerId, 'subtypeconfigurations', fieldName),
      { params },
    );
  }

  getDefaultObjectTypeConfiguration(
    objectType: string,
    isObjectTypePoint?: boolean,
    isObjectTypeLine?: boolean,
    objectSubtype?: string,
  ): Observable<ObjectTypeConfiguration> {
    let params = new HttpParams();

    if (objectType) {
      params = params.set('type', objectType);
    }

    if (isObjectTypePoint) {
      params = params.set('pointSubtype', objectSubtype);
    }

    if (isObjectTypeLine) {
      params = params.set('lineSubtype', objectSubtype);
    }

    return this.http.get<ObjectTypeConfiguration>(this.route + '/object-type-configuration/default', { params: params });
  }

  getBimNodes(companySlug: string, sourceSlug: string, featureLayerId: string): Observable<BimNode[]> {
    const bimNodesRoute = this.getRouteByCompany(companySlug, sourceSlug, 'featurelayers', featureLayerId, 'bimnodes');
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.get<BimNode[]>(bimNodesRoute, { params });
  }

  // For BIM type data source

  getDefaultClientId(companySlug: string) {
    return this.http.get(`${this.baseUrl}/companies/${companySlug}/bim360-client-id`);
  }

  signOutBim360(companySlug: string, sourceSlug: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);
    const url = `${this.baseUrl}/companies/${companySlug}/sources/${sourceSlug}/bim360/signout`;

    return this.http.post(url, {}, { params });
  }

  getModelsTreeForBim360(
    companySlug: string,
    dataSourceSlug: string,
    hubId: string,
    projectId: string,
    href?: string,
  ): Observable<Bim360ModelsTree[]> {
    let params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    if (href !== undefined) {
      params = params.append('href', href);
    }

    const url = `${this.baseUrl}/companies/${companySlug}/sources/${dataSourceSlug}/bim360/hubs/${hubId}/projects/${projectId}/folder`;

    return this.http.get<Bim360ModelsTree[]>(url, { params });
  }

  getPix4dProjects(companySlug: string, dataSourceSlug: string): Observable<Pix4dProject[]> {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);
    const url = `${this.baseUrl}/companies/${companySlug}/sources/${dataSourceSlug}/pix4d/projects`;

    return this.http.get<Pix4dProject[]>(url, { params });
  }

  getScheduleForToday() {
    return this.http.get(this.route + '/schedule');
  }

  getProcessingStatus(companySlug: string, sourceSlug: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);
    const url = `${this.baseUrl}/companies/${companySlug}/sources/${sourceSlug}/processes/inprogress`;

    return this.http.get(url, { params });
  }

  getPreparationStatus(companySlug: string, sourceSlug: string): Observable<Record<string, DataSourceStatus>> {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);
    const url = `${this.baseUrl}/companies/${companySlug}/sources/${sourceSlug}/preparation-status`;

    return this.http.get<Record<string, DataSourceStatus>>(url, { params });
  }

  // url for getting server side pagination data for company or team admin

  getBaseUrlForCompanyOrTeamAdmin(companySlug: string) {
    return `${this.baseUrl}/companies/${companySlug}/sources`;
  }

  /**
   * Mark as removed
   * @param {string} companySlug
   * @param {string} sourceSlug
   * @returns {Observable<Response>}
   */
  remove(companySlug: string, sourceSlug: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.delete(`${this.baseUrl}/companies/${companySlug}/sources/${sourceSlug}`, { params });
  }

  removeMultiple(sourceIds: string[]) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);
    const companySlug = this.identityService.companySlug;

    return this.http.delete(`${this.baseUrl}/companies/${companySlug}/sources/`, { body: sourceIds, params });
  }

  /**
   * Restore
   * @param {string} companySlug
   * @param {string} sourceSlug
   * @returns {Observable<Response>}
   */
  restore(companySlug: string, sourceSlug: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.put(`${this.baseUrl}/companies/${companySlug}/sources/${sourceSlug}/restore`, {}, { params });
  }

  /**
   * Get list sources by company slug
   * @param {string} companySlug
   * @returns {Observable<Response>}
   */
  allByCompany(companySlug: string): Observable<ISource[]> {
    return this.http.get<ISource[]>(`${this.baseUrl}/companies/${companySlug}/sources`);
  }

  getDataSourcesByTeam(companySlug: string, teamSlug: string): Observable<TeamDataSource[]> {
    const url = `${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/sources`;

    return this.http.get<TeamDataSource[]>(url);
  }

  getConfigurationHints(companySlug: string, dataSourceSlug: string): Observable<ConfigurationHint[]> {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);
    const url = `${this.baseUrl}/companies/${companySlug}/sources/${dataSourceSlug}/hints`;

    return this.http.get<ConfigurationHint[]>(url, { params });
  }

  getServerPaginatedList$(optionsForServer: ServerPaginationParams, companySlug: string | null): Observable<ServerPaginatedData> {
    const params = this.paginationService.getRequestParams(optionsForServer);

    const pageIndex = this.paginationService.pageIndex;

    let httpParams = new HttpParams();

    params.forEach((value, key) => {
      httpParams = httpParams.set(key, value);
    });

    let url = `${this.baseUrl}/companies/${companySlug}/sources`;

    if (this.identityService.isTeamAdmin()) {
      const teamSlug = this.identityService.teamSlug;

      url = `${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/sources`;
    }

    return this.http
      .get<ServerPaginatedData>(url, { params: httpParams, observe: 'response' })
      .pipe(
        map((data: HttpResponse<any>) => {
          return { totalCount: data.headers.get('TotalCount'), body: data.body, pageIndex };
        }),
      );
  }
}
