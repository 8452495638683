import { DataSourceType } from '../../modules/sources/data-source-type.enum';

export const DATA_SOURCE_TYPES_CREATE_FORM = [
  {
    type: DataSourceType.Bim360,
    title: 'Autodesk Build (BIM360)',
  },
  {
    type: DataSourceType.IModel,
    title: 'Bentley iTwin (3D Objects)',
  },
  {
    type: DataSourceType.IModelGis,
    title: 'Bentley iTwin (Vector Lines)',
  },
  {
    type: DataSourceType.NativeGis,
    title: 'DWG-DXF (Vector Lines)',
  },
  {
    type: DataSourceType.NativeBim,
    title: 'DWG-DXF-IFC-RVT (3D Objects)',
  },
  {
    type: DataSourceType.ArcGisFeatureService,
    title: 'Esri ArcGIS Feature/Map Services',
  },
  {
    type: DataSourceType.ArcGisSceneService,
    title: 'Esri ArcGIS Scene Services',
  },
  {
    type: DataSourceType.Kml,
    title: 'KML',
  },
  {
    type: DataSourceType.Wfs,
    title: 'WFS',
  },
  {
    type: DataSourceType.Shapefile,
    title: 'Shapefiles',
  },
  {
    type: DataSourceType.Pix4d,
    title: 'Pix4D Cloud',
  },
  {
    type: DataSourceType.LandXml,
    title: 'LandXML',
  },
  {
    type: DataSourceType.Simple3dModel,
    title: 'Simple 3D Model',
  },
];
