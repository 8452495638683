import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ConfigurationSnapshotData } from '../interfaces/configuration-snapshot-data';
import { ActivatedRoute } from '@angular/router';
import { ILayer } from '../../../../../../components/layers/shared/models/layer.interface';
import { SourceConfiguration } from '../interfaces/source-configuration';
import { PlacemarksFields } from '../../../models/placemarks-fields';
import { DataSourceType } from '../../../../../data-source-type.enum';
import { SourceConfigurationService } from '../../../services/source-configuration.service';
import { SingleForm } from '../interfaces/single-form';
import { takeUntil, tap } from 'rxjs/operators';
import { SubtypeConfiguration } from '../interfaces/subtype-configuration';
import { Subject } from 'rxjs';
import { ObjectTypeConfiguration } from '../interfaces/object-type-configuration';
import { ObjectType } from '../enums/object-types.enum';

@Component({
  selector: 'mee-subtype-configuration-new',
  templateUrl: './subtype-configuration.component.html',
  styleUrls: ['./subtype-configuration.component.scss'],
})
export class SubtypeConfigurationComponent implements OnDestroy {

  localPlaceMarksFieldsFields: PlacemarksFields;

  layers: ILayer[];

  private destroy$ = new Subject<void>();

  private localSubtypeParentObjectTypeConfig: ObjectTypeConfiguration;

  @Input() node: SourceConfiguration;

  @Input() sourceType: DataSourceType;

  @Input() placeMarksFields: PlacemarksFields | null;

  @Output() onSubtypeConfigChanged = new EventEmitter<SubtypeConfiguration>();

  @Input() set subtypeParentObjectTypeConfig(subtypeParentObjectTypeConfig: ObjectTypeConfiguration) {
    this.localSubtypeParentObjectTypeConfig = subtypeParentObjectTypeConfig;
  }

  get subtypeParentObjectTypeConfig() {
    return this.localSubtypeParentObjectTypeConfig;
  }

  @ViewChild('pointConfiguration', { static: false }) set subtypePointConfiguration(subtypePointConfiguration: SingleForm) {
    if (subtypePointConfiguration) {
      subtypePointConfiguration.form.valueChanges
        .pipe(
          tap(() => {

            this.node.changed = true;

            const updatedSubtypeConfiguration: SubtypeConfiguration = subtypePointConfiguration.form.getRawValue();

            this.onSubtypeConfigChanged.emit(updatedSubtypeConfiguration);
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  @ViewChild('lineConfiguration', { static: false }) set subtypeLineConfiguration(subtypeLineConfiguration: SingleForm) {
    if (subtypeLineConfiguration) {
      subtypeLineConfiguration.form.valueChanges
        .pipe(
          tap(() => {

            this.node.changed = true;

            const updatedSubtypeConfiguration: SubtypeConfiguration = subtypeLineConfiguration.form.getRawValue();

            this.onSubtypeConfigChanged.emit(updatedSubtypeConfiguration);
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  @ViewChild('areaConfiguration', { static: false }) set subtypeAreaConfiguration(subtypeAreaConfiguration: SingleForm) {
    if (subtypeAreaConfiguration) {
      subtypeAreaConfiguration.form.valueChanges
        .pipe(
          tap(() => {

            this.node.changed = true;

            const updatedSubtypeConfiguration: SubtypeConfiguration = subtypeAreaConfiguration.form.getRawValue();

            this.onSubtypeConfigChanged.emit(updatedSubtypeConfiguration);
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  constructor(private activatedRoute: ActivatedRoute,
              private sourceConfigService: SourceConfigurationService) {
    this.getLayers();
  }

  getIsParentObjectTypePoint(): boolean {

    // not selected object type on feature layer

    if (!this.subtypeParentObjectTypeConfig) {
      return;
    }
    return this.sourceConfigService.objectTypeIs(this.subtypeParentObjectTypeConfig.ObjectType, ObjectType.POINT);
  }

  getIsParentObjectTypeLine(): boolean {

    // not selected object type on feature layer

    if (!this.subtypeParentObjectTypeConfig) {
      return;
    }
    return this.sourceConfigService.objectTypeIs(this.subtypeParentObjectTypeConfig.ObjectType, ObjectType.LINE);
  }

  getIsParentObjectTypeArea(): boolean {
    // not selected object type on feature layer

    if (!this.subtypeParentObjectTypeConfig) {
      return;
    }
    return this.sourceConfigService.objectTypeIs(this.subtypeParentObjectTypeConfig.ObjectType, ObjectType.AREA);
  }

  private getLayers() {
    const data = this.activatedRoute.snapshot.data as ConfigurationSnapshotData;

    this.layers = data.layers.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
