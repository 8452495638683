// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/*for correct work of scroll for process-layers-table component*/
:host ::ng-deep mee-process-details {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/sources/sources.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AACF;;AAEA,gEAAA;AAGE;EACE,YAAA;AADJ","sourcesContent":[":host {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n}\n\n/*for correct work of scroll for process-layers-table component*/\n\n:host ::ng-deep {\n  mee-process-details {\n    height: 100%;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
