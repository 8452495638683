import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RestClass, RestService } from '../../../../../../services/rest/rest.service';
import { IdentityService } from '../../../../../../services/identity.service';


@Injectable()
@RestClass('/companies')
export class TemplatesForCopyService extends RestService<any> {
  constructor(protected http: HttpClient, private identityService: IdentityService) {
    super(http);
  }

  // DS - data source
  // FL - feature layer

  setTemplate(companySlug: string, DSSlug: string, FLConfigurationId: string) {
    // POST /api/companies/{companySlug}/sources/{dataSourceId}/favorites/{featureLayerConfigurationId}
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.post(`${this.baseUrl}/companies/${companySlug}/sources/${DSSlug}/favorites/${FLConfigurationId}`, {}, { params });
  }

  getTemplatesForDataSource(companySlug: string, destinationDSId: string) {
    // GET /api/companies/{companySlug}/sources/favorites/{destinationDataSourceId}
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.get(`${this.baseUrl}/companies/${companySlug}/sources/favorites/${destinationDSId}`, { params });
  }

  getTemplatesForFeatureLayers(companySlug: string, destinationDSSlug: string, destinationFLConfigurationId: string) {
    // GET /api/companies/{companySlug}/sources/favorites/{destinationDataSourceId}/{destinationFeatureLayerConfigurationId}
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.get(`${this.baseUrl}/companies/${companySlug}/sources/favorites/${destinationDSSlug}/${destinationFLConfigurationId}`,
      { params }
    );
  }

  removeTemplate(companySlug: string, DSSlug: string, FLConfigurationId: string) {
    // DELETE /api/companies/{companySlug}/sources/{dataSourceId}/favorites/{featureLayerConfigurationId}
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.delete(`${this.baseUrl}/companies/${companySlug}/sources/${DSSlug}/favorites/${FLConfigurationId}`, { params });
  }
}
