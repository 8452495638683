import { Component, Input } from '@angular/core';
import { ConfigurationHint } from '../../models/configuration-hint';
import { DataSourcesAdapterService } from '../configuration-tab/data-sources-adapter.service';
import { ConfigurationHintData } from '../../models/configuration-hint-data';

@Component({
  selector: 'mee-configuration-hints',
  templateUrl: './configuration-hints.component.html',
  styleUrls: ['./configuration-hints.component.scss'],
})
export class ConfigurationHintsComponent {

  @Input() verticalDatumValue: string;

  adaptedConfigurationHints: ConfigurationHintData[];
  isPanelOpened = false;

  @Input() set configurationHints(configurationHints: ConfigurationHint[]) {
    if (!configurationHints) {
      return;
    }

    this.adaptedConfigurationHints =
      this.dataSourcesAdapterService.getAdaptedConfigurationHints(configurationHints);
  }

  get isAllRecomendationDone(): boolean {
    const hints = this.adaptedConfigurationHints;

    return hints && !hints.some(hint => !hint.Passed);
  }

  constructor(private dataSourcesAdapterService: DataSourcesAdapterService) {
  }

  onPanelOpen(): void {
    this.isPanelOpened = true;
  }

  onPanelClosed(): void {
    this.isPanelOpened = false;
  }
}
