// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 12px;
}
.modal-wrapper .title {
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-size: 38px;
  line-height: 34px;
  letter-spacing: -0.02em;
  margin: 0;
  font-weight: 600;
}
.modal-wrapper .form-actions {
  margin: 0;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.modal-wrapper .form-actions button {
  width: 120px;
  height: 48px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
}
.modal-wrapper .form-actions .add-button {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  color: white;
}
.modal-wrapper .form-actions .add-button:not(:disabled) {
  background: #0077EE;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/users/find-user-modal/find-user-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;AACF;AACE;EACE,2CAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,SAAA;EACA,gBAAA;AACJ;AAGE;EACE,SAAA;EACA,aAAA;EACA,yBAAA;EACA,SAAA;AADJ;AAGI;EACE,YAAA;EACA,YAAA;EACA,2CAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AADN;AAII;EAKE,sEAAA;EACA,kBAAA;EACA,YAAA;AANN;AAAM;EACE,mBAAA;AAER","sourcesContent":[".modal-wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  padding: 12px;\n\n  .title {\n    font-family: 'Roboto Condensed', sans-serif;\n    font-style: normal;\n    font-size: 38px;\n    line-height: 34px;\n    letter-spacing: -0.02em;\n    margin: 0;\n    font-weight: 600;\n\n  }\n\n  .form-actions {\n    margin: 0;\n    display: flex;\n    justify-content: flex-end;\n    gap: 16px;\n\n    button {\n      width: 120px;\n      height: 48px;\n      font-family: 'Roboto Condensed', sans-serif;\n      font-weight: 700;\n      font-size: 16px;\n      line-height: 16px;\n    }\n\n    .add-button {\n      &:not(:disabled) {\n        background: #0077EE;\n      }\n\n      box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);\n      border-radius: 4px;\n      color: white;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
