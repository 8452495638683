import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ISource } from '../../../modules/sources/models/source.interface';
import { IdentityService } from '../../../services/identity.service';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { Observable, Subject } from 'rxjs';
import { DataSourcesService } from '../../../modules/sources/services/data-sources.service';

@Component({
  selector: 'mee-sources-autocomplete',
  templateUrl: './sources-autocomplete.component.html',
  styleUrls: ['./sources-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SourcesAutocompleteComponent),
    },
  ],
})
export class SourcesAutocompleteComponent implements OnDestroy, ControlValueAccessor {
  sources: ISource[];

  filteredSourcesData$: Observable<ISource[]>;

  form = new UntypedFormGroup({
    selectedSource: new UntypedFormControl({ value: null, disabled: true }),
  });

  private localCompanySlug: string;

  private destroy$ = new Subject<void>();

  @Input() selectedSource: ISource;

  @Input() set companySlug(companySlug: string) {

    // default value

    if (companySlug === null) {
      return;
    }

    // value after remove selected company

    if (companySlug === undefined) {
      this.form.get('selectedSource').reset();
      this.form.get('selectedSource').disable();

      return;
    }

    this.localCompanySlug = companySlug;
    this.getSourcesByCompany(companySlug);
    this.form.get('selectedSource').enable();
  }

  get companySlug() {
    return this.localCompanySlug;
  }

  onChange: any = () => {};

  get isSuperAdmin() {
    return this.identityService.isSuperAdmin();
  }

  get isCompanyAdmin() {
    return this.identityService.isCompanyAdmin();
  }

  get isTeamAdmin() {
    return this.identityService.isTeamAdmin();
  }

  constructor(private identityService: IdentityService, private sourcesService: DataSourcesService) {
    this.form.get('selectedSource').valueChanges.subscribe(value => {
      this.selectedSource = value;
      this.onChange(value);
    });
  }

  getSourcesByCompany(companySlug: string) {
    this.sourcesService
      .allByCompany(companySlug || this.identityService.companySlug)
      .pipe(
        tap(sources => {
          this.setExistingSortedSources(sources);

          this.filterSourcesAutocompleteValues();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  displaySourceProperty(source: ISource) {
    if (source) {
      return source.Slug;
    }
  }

  onSourceChange(event: MatAutocompleteSelectedEvent) {
    this.selectedSource = event.option.value;
  }

  filterSourcesAutocompleteValues(): void {
    this.filteredSourcesData$ = this.form.get('selectedSource').valueChanges.pipe(
      startWith(''),
      map(value => this.filterSources(value)),
    );
  }

  private filterSources(value: string): Array<ISource> {
    if (typeof value !== 'string') {
      return;
    }

    const filterValue = value.toLowerCase();
    this.form.get('selectedSource').setErrors({ required: true });

    return this.sources.filter(option => option.Slug.toLowerCase().includes(filterValue));
  }

  private setExistingSortedSources(sources: ISource[]) {
    const sourcesTrimmed = sources.map(source => {
      return { ...source, Name: source.Slug.trim() };
    });

    const existingSources = sourcesTrimmed.filter(source => !source.IsArchived);
    this.sources = existingSources.sort((a, b) => (a.Slug < b.Slug ? -1 : 1));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(source: ISource): void {
    this.form.get('selectedSource').setValue(source);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
