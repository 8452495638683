import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConnectBim360ProjectData } from './connect-bim360-project-data';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { snackBarConfig } from '../../../../../../core/core.module';

@Component({
  selector: 'mee-connect-bim360-project',
  templateUrl: './connect-bim360-project.component.html',
  styleUrls: ['./connect-bim360-project.component.scss']
})
export class ConnectBim360ProjectComponent implements AfterViewInit {

  // https://github.com/angular/components/issues/13870#issuecomment-502071712

  disableAnimation = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConnectBim360ProjectData,
              private clipboard: Clipboard,
              private matSnackBar: MatSnackBar) {}

  onCopyClientId() {
    this.matSnackBar.open('Text copied to clipboard', 'OK', snackBarConfig);
  }

  onCopyAppName() {
    this.matSnackBar.open('Text copied to clipboard', 'OK', snackBarConfig);
  }

  ngAfterViewInit() {

    // timeout required to avoid 'ExpressionChangedAfterItHasBeenCheckedError'

    setTimeout(() => this.disableAnimation = false, 0);
  }
}
