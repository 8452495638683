import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { SidenavItem, SidenavItemsTypes } from './sidenav-item.model';
import { SidenavService } from '../sidenav.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'mee-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidenavItemComponent {
  sidenavItemsTypes = SidenavItemsTypes;

  @Input() item: SidenavItem;

  @HostBinding('class.sidenav-item')
  sidenavItemClass = true;

  @HostBinding('class.open')
  get isOpen() {
    return this.sidenavService.isOpen(this.item);
  }

  constructor(
    private sidenavService: SidenavService,
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
  ) {
    this.iconRegistry.addSvgIcon('grid-item-add', sanitizer.bypassSecurityTrustResourceUrl('/assets/img/icons/grid-item-add.svg'));
    this.iconRegistry.addSvgIcon('reality-capture-icon', sanitizer.bypassSecurityTrustResourceUrl('/assets/img/icons/reality-capture.svg'))
  }

  toggleDropdown(): void {
    if (this.item.hasSubItems()) {
      this.sidenavService.toggleCurrentlyOpen(this.item);
    }
  }

  // Receives the count of Sub Items and multiplies it with 48 (height of one SidenavItem) to set the height for animation.
  getSubItemsHeight(): string {
    return this.getOpenSubItemsCount(this.item) * 48 + 'px';
  }

  // Counts the amount of Sub Items there is and returns the count.
  getOpenSubItemsCount(item: SidenavItem): number {
    let count = 0;

    if (item.hasSubItems() && this.sidenavService.isOpen(item)) {
      count += item.subItems.length;

      item.subItems.forEach(subItem => {
        count += this.getOpenSubItemsCount(subItem);
      });
    }

    return count;
  }
}
