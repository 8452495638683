export const layersPresetColors = [
  'rgba(255, 241, 0, 1)',
  'rgba(220, 207, 3, 1)',
  'rgba(251, 177, 21, 1)',
  'rgba(194, 133, 4, 1)',
  'rgba(252, 115, 18, 1)',
  'rgba(183, 87, 18, 1)',

  'rgba(255, 0, 0, 1)',
  'rgba(201, 0, 0, 1)',
  'rgba(255, 100, 100, 1)',
  'rgba(175, 68, 68, 1)',
  'rgba(255, 48, 0, 1)',
  'rgba(196, 43, 7, 1)',

  'rgba(0, 121, 213, 1)',
  'rgba(0, 95, 167, 1)',
  'rgba(61, 201, 238, 1)',
  'rgba(33, 136, 163, 1)',
  'rgba(0, 214, 204, 1)',
  'rgba(0, 145, 137, 1)',

  'rgba(1, 176, 22, 1)',
  'rgba(2, 124, 16, 1)',
  'rgba(128, 216, 11, 1)',
  'rgba(93, 157, 8, 1)',
  'rgba(255, 255, 255, 1)',
  'rgba(198, 198, 198, 1)',

  'rgba(243, 39, 160, 1)',
  'rgba(184, 30, 121, 1)',
  'rgba(183, 69, 245, 1)',
  'rgba(135, 42, 187, 1)',
  'rgba(138, 133, 255, 1)',
  'rgba(76, 73, 165, 1)'
];
