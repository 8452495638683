import { AltitudeMode } from '../enums/altitude-mode.enum';

export const ALTITUDE_MODES_MODEL = [
  {
    title: 'configurationTab.altitudeMode.seaLevel',
    value: AltitudeMode.Absolute,
  },
  {
    title: 'configurationTab.altitudeMode.surface',
    value: AltitudeMode.RelativeToGround,
  },
  {
    title: 'configurationTab.altitudeMode.ingoreZ',
    value: AltitudeMode.ClampToGround,
  },
];
