// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-overlay {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.loading-overlay.show {
  opacity: 1;
  visibility: visible;
}
.loading-overlay.full {
  height: auto;
  background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/core/loading-overlay/loading-overlay.component.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,iCAAA;EACA,UAAA;EACA,kBAAA;AACF;AACE;EACE,UAAA;EACA,mBAAA;AACJ;AAEE;EACE,YAAA;EACA,uBAAA;AAAJ","sourcesContent":[".loading-overlay {\n  background-color: rgba(255,255,255, 0.7);\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 10;\n  transition: all .25s ease-in-out;\n  opacity: 0;\n  visibility: hidden;\n\n  &.show {\n    opacity: 1;\n    visibility: visible;\n  }\n\n  &.full {\n    height: auto;\n    background: transparent;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
