import { Injectable } from '@angular/core';
import { RestService } from './rest/rest.service';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class InspectionFormService extends RestService<any> {

  path = '/companies/:companySlug/forms';

  companySlug: string;

  get route(): string {
    return this.baseUrl + this.path.replace(':companySlug', this.companySlug);
  }

  constructor(protected http: HttpClient) {
    super(http);
  }

  list(companySlug: string, options?: HttpRequest<unknown>): Observable<Response> {
    this.companySlug = companySlug;
    return super.list(options);
  }
}
