import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CopyConfigService } from '../../../services/copy-config.service';
import { MatLegacyCheckboxChange } from '@angular/material/legacy-checkbox';
import { MappingFieldsForCopyModel } from '../models/mapping-fields-for-copy-model';
import { CopyConfigurationSourceType } from '../models/copy-configuration-source-type';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CopyConfigFromService } from '../../copy-config-from/copy-config-from.service';
import { CopyConfigMappingFields } from '../models/copy-config-mapping-fields';
import { CopyConfigOptionsSendData } from '../models/copy-config-options-send-data';
import { tap } from 'rxjs/operators';
import { SourceConfiguration } from '../../configuration-tab/interfaces/source-configuration';
import { CopyConfiguration } from '../models/copy-configuration';
import { ISource } from '../../../../../models/source.interface';

@Component({
  selector: 'mee-copy-config-options-window',
  templateUrl: './copy-config-options-window.component.html',
  styleUrls: ['./copy-config-options-window.component.scss'],
})
export class CopyConfigOptionsWindowComponent implements OnInit {
  allSettingsCheckbox = {
    title: 'sources.configuration.CopyConfigOptions.AllSettings',
    name: 'CopyAllSettings',
    state: true,
  };

  checkboxes = [
    {
      title: 'sources.configuration.CopyConfigOptions.CopyColors',
      name: 'CopyColors',
      state: true,
    },
    {
      title: 'sources.configuration.CopyConfigOptions.CopyLayerGroups',
      name: 'CopyLayerGroups',
      state: true,
    },
    {
      title: 'sources.configuration.CopyConfigOptions.CopyLayerConfigs',
      name: 'CopyLayerConfigs',
      state: true,
    },
  ];

  destinationModels: MappingFieldsForCopyModel[] = [];
  copiedModels: MappingFieldsForCopyModel[] = [];
  copyConfigMappingFields = CopyConfigMappingFields;

  private copyConfigService = inject(CopyConfigService);
  private copyConfigFromService = inject(CopyConfigFromService);
  private formBuilder = inject(FormBuilder);

  @Input() copiedSourceConfiguration: CopyConfiguration;
  @Input() destinationConfiguration: SourceConfiguration;
  @Input() destinationSource: ISource;
  @Input() copyConfigurationType: CopyConfigurationSourceType;
  @Output() back = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  modelsForm = this.formBuilder.group({
    ModelToModelMapping: this.formBuilder.array([]),
  });

  get isAllFieldExist(): boolean {
    return this.destinationModels.length === this.getExistModelsByField(CopyConfigMappingFields.FromModelId).length;
  }

  get modelToModelMapping(): FormArray {
    return this.modelsForm.get('ModelToModelMapping') as FormArray;
  }

  get isBim(): boolean {
    return this.copyConfigurationType === CopyConfigurationSourceType.Bim;
  }

  get isAllCheckboxesTurnOn(): boolean {
    return this.checkboxes.every(checkbox => checkbox.state);
  }

  get isSomeCompleted(): boolean {
    return this.checkboxes.some(checkbox => checkbox.state) && !this.isAllCheckboxesTurnOn;
  }

  ngOnInit(): void {
    this.initModelsForCopy();
  }

  isFieldDisable(id: string, field: CopyConfigMappingFields): boolean {
    return this.getExistModelsByField(field).includes(id);
  }

  selectAll({ checked }: MatLegacyCheckboxChange): void {
    this.checkboxes.forEach(checkbox => checkbox.state = checked);
  }

  copy(): void {
    let data: CopyConfigOptionsSendData = null;

    this.checkboxes.forEach(checkbox => {
      data = { ...data, [checkbox.name]: checkbox.state };
    });

    data = { ...data, [this.allSettingsCheckbox.name]: this.isAllCheckboxesTurnOn };

    data = {
      ...data,
      SourceId: this.copiedSourceConfiguration.Id,
      DestinationId: this.destinationSource.Id,
      ModelToModelMapping: this.isBim ? this.getModelToModelMappingFieldsList() : null,
    };

    this.copyConfigService.copyConfig(this.destinationSource.CompanySlug, data).pipe(
      tap(copyData => {
        this.copyConfigFromService.setNewDSAfterCopy(copyData.DataSource);
        this.close.emit();
      }),
    ).subscribe();
  }

  removePair(index: number): void {
    this.modelToModelMapping.removeAt(index);
  }

  addPair(): void {
    const pairForm = this.formBuilder.group({
        FromModelId: ['', Validators.required], ToModelId: ['', Validators.required],
      },
    );
    this.modelToModelMapping.push(pairForm);
  }

  goBack(): void {
    this.back.emit();
  }

  private initModelsForCopy(): void {
    if (!this.isBim) {
      return;
    }

    const mapLayers = ({ LayerName, Id }) => ({ LayerName, Id });

    this.destinationModels = this.destinationConfiguration.ChildLayers.map(mapLayers);
    this.copiedModels = this.copiedSourceConfiguration.RootFeatureLayerConfiguration.ChildLayers.map(mapLayers);
    this.addPair();
  }

  private getModelToModelMappingFieldsList(): any {
    return this.modelToModelMapping.controls.map((control: FormGroup) => control.value);
  }

  private getExistModelsByField(field: CopyConfigMappingFields): string[] {
    return this.modelToModelMapping.controls.map(control => {
      const form = control as FormGroup;

      if (form) {
        return form.value[field];
      }
    });
  }
}
