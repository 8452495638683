import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ISource} from '../models/source.interface';

@Injectable({
  providedIn: 'root'
})

export class SourcesRelatedDataService {

  private enabledSourcesIds$ = new BehaviorSubject<string[]>([]);

  private newlyAddedDataSourceForTeamAdmin$ = new BehaviorSubject<ISource>(null);

  get enabledDataSourcesIds$() {
    return this.enabledSourcesIds$.asObservable();
  }

  setNewlyAddedSourceForTeamAdmin(dataSource: ISource) {
    this.newlyAddedDataSourceForTeamAdmin$.next(dataSource);
  }

  getNewlyAddedSourceForTeamAdmin(): ISource {
    return this.newlyAddedDataSourceForTeamAdmin$.value;
  }

  getNewlyAddedSourceForTeamAdmin$(): Observable<ISource> {
    return this.newlyAddedDataSourceForTeamAdmin$ as Observable<ISource>;
  }

  setEnabledDataSourcesIdsForSyncLayers(enabledSourcesIds: string[]) {
    this.enabledSourcesIds$.next(enabledSourcesIds);
  }
}
