import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import * as Cropper from 'cropperjs/dist/cropper';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mee-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss']
})
export class ImageCropComponent implements OnInit {

  cropper;
  imageSrc = '';

  @ViewChild('image', { static: true }) image: ElementRef;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ImageCropComponent>) {
  }

  ngOnInit() {
    const file = this.data;
    const reader = new FileReader();

    reader.addEventListener('load', function () {
      this.imageSrc = reader.result;
      setTimeout(this.createCropper.bind(this), 0);
    }.bind(this), false);

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  createCropper() {
    this.cropper = new Cropper(this.image.nativeElement, {
      aspectRatio: 1,
      viewMode: 1
    });
  }

  getCroppedImage() {
    this.getCanvasFromCropper(blob => {
      this.dialogRef.close(blob);
    });
  }

  getDataFromCropper() {
    return this.cropper.getData();
  }

  getCanvasFromCropper(callback: Function) {
    return this.cropper.getCroppedCanvas({width: 800, height: 800}).toBlob(callback);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

}


