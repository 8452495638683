import {Component, forwardRef, Input} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ColorPickerService } from 'ngx-color-picker';
import { UPDATED_PRESETS_COLORS } from '../presets-color-list.conts';
import { ColorPickerPosition } from '../enums/color-picker-position';

const DEFAULT_COLOR_TEXT = 'rgba(0, 0, 0, .87)';
const DEFAULT_POSITION = ColorPickerPosition.Right;

@Component({
  selector: 'mee-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
  ],
})
export class ColorPickerComponent implements ControlValueAccessor {
  @Input() position: string;

  color: string | null;

  backgroundColor: string;

  textColor = DEFAULT_COLOR_TEXT;

  presetColors = UPDATED_PRESETS_COLORS;

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  get cpPosition(): string {
    return this.position ? this.position : DEFAULT_POSITION;
  }

  constructor(private colorPickerService: ColorPickerService) {
  }

  set value(value: string | null) {
    this.color = value;
    this.backgroundColor = value;
  }

  // this method sets the value programmatically
  writeValue(value: any) {
    this.value = value;
  }

// upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

// upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  onResetColor() {
    this.color = null;
    this.backgroundColor = '#ffffff';
    this.onChange(null);
  }

  getRGBAColorFromHex(): string {

    this.textColor = DEFAULT_COLOR_TEXT;

    if (!this.color) {
      return;
    }

    const hsva = this.colorPickerService.stringToHsva(this.color, true);

    const rgba = this.colorPickerService.denormalizeRGBA(this.colorPickerService.hsvaToRgba(hsva));

    if ((rgba.r < 70 || rgba.g < 70) && rgba.a > 0.4) {
      this.textColor = '#ffffff';
    }

    return this.colorPickerService
      .outputFormat(hsva, 'rgba', 'hex8');
  }
}
