import { Component, Input } from '@angular/core';

@Component({
  selector: 'mee-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  @Input('quickpanel') quickpanel: any;
  @Input('sidenav') sidenav: any;
  isFullscreen = false;

  showBreadcrumbs = true;

  constructor() { }
}
