import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ILayer } from '../../../../../../components/layers/shared/models/layer.interface';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SourceConfiguration } from '../interfaces/source-configuration';
import { ALTITUDE_MODES_MODEL } from '../models/altitude-modes.model';
import { ConfigurationFieldsAdapterService } from '../configuration-fields-adapter.service';
import { ConfigurationDataSource } from '../../../models/configuration-data-source';
import { DataSourceType } from '../../../../../data-source-type.enum';
import { ConfigurationFields } from '../enums/configuration-fields.enum';
import { Subject } from 'rxjs';
import isEqual from 'lodash-es/isEqual';
import { isDisplayForCurrentSourceType } from '../is-display-for-current-source-type.function';
import { FeatureLayerType } from '../interfaces/feature-layer-type.enum';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { FloorConfiguration } from '../floors-configuration/interfaces/floor-configuration';
import { Floor } from '../../../../../../components/floors/interfaces/floor.interface';
import { DataSourcesService } from '../../../../../services/data-sources.service';
import { IdentityService } from '../../../../../../../services/identity.service';
import { FloorIfcConfiguration } from '../floors-configuration/interfaces/floor-ifc-configuration';
import { isDisableForCurrentSourceType } from '../is-disable-for-current-source-type.function';
import { EpsgCodeTipsComponent } from '../../epsg-code-tips/epsg-code-tips.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ColorPickerPosition } from '../enums/color-picker-position';

@Component({
  selector: 'mee-root-configuration',
  templateUrl: './root-configuration.component.html',
  styleUrls: ['./root-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootConfigurationComponent implements OnInit {
  form: UntypedFormGroup;

  localLayers: ILayer[];

  altitudeModesModel = ALTITUDE_MODES_MODEL;

  verticalDatumOptions = this.configurationFieldsAdapterService.getVerticalDatumOptions();

  measurementSystemOptions = this.configurationFieldsAdapterService.getMeasurementSystemOptions();

  configurationFields = ConfigurationFields;

  private localNode: SourceConfiguration;

  private localDataSourceSettings: ConfigurationDataSource;

  private destroy$ = new Subject<void>();

  readonly ColorPickerPosition = ColorPickerPosition;

  @Input() companySlug: string;

  @Input() sourceType: DataSourceType;

  @Input() isSourceRealTime: boolean;
  @Output() updateLayerGroup = new EventEmitter();

  @Input() set layers(layers: ILayer[]) {
    this.localLayers = layers;

    if (layers.length === 0) {
      this.form.get('Layer').disable();
    }
  }

  get layers() {
    return this.localLayers;
  }

  @Input() set node(node: SourceConfiguration) {
    if (node === undefined) {
      return;
    }

    if (isEqual(this.node, node)) {
      return;
    }

    this.localNode = node;
  }

  get node() {
    return this.localNode;
  }

  @Input() set dataSourceSettings(dataSourceSettings: ConfigurationDataSource) {
    if (dataSourceSettings === undefined) {
      return;
    }

    if (isEqual(this.dataSourceSettings, dataSourceSettings)) {
      return;
    }

    this.localDataSourceSettings = dataSourceSettings;

    this.patchFormValues();
  }

  get dataSourceSettings() {
    return this.localDataSourceSettings;
  }

  get isSuperAdmin() {
    return this.identityService.isSuperAdmin();
  }

  get isShowCoordinateSystem() {
    return (
      this.isDisplayForCurrentSourceType(this.configurationFields.CoordinateSystem) ||
      this.isDisplayForCurrentSourceType(this.configurationFields.MeasurementSystem)
    );
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private configurationFieldsAdapterService: ConfigurationFieldsAdapterService,
    private dataSourcesService: DataSourcesService,
    private cdr: ChangeDetectorRef,
    private identityService: IdentityService,
    private matDialog: MatDialog
  ) {
    this.initForm();
    this.onChangeIsUseLocalCoordinateSystem();
  }

  ngOnInit() {
    this.disableFields();
  }

  updateLayers(): void {
    this.updateLayerGroup.emit();
  }

  openEpsgHintDialog(event) {
    event.stopPropagation();

    this.matDialog.open(EpsgCodeTipsComponent, {
      autoFocus: false
    });
  }

  isDisplayForCurrentSourceType(fieldName: ConfigurationFields) {
    return isDisplayForCurrentSourceType(fieldName, FeatureLayerType.Root, this.sourceType);
  }

  isDisableForCurrentSourceType(fieldName: ConfigurationFields) {
    return isDisableForCurrentSourceType(fieldName, FeatureLayerType.Root, this.sourceType);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      Id: [],
      Layer: [],
      MainColor: [],
      AltitudeMode: [],
      VerticalDatum: [],
      VerticalMeasurementSystem: [],
      CoordinateSystem: [],
      MeasurementSystem: [],
      IsLocalCrs: [],
      DefaultDepth: [],
      EnableDataCollection: [],
      Permissions: [],
      IsMeshInteractionEnabled: [],
      FullModel: [],
      IsFloorSplittingEnabled: [],
      FloorConfiguration: [],
    });
  }

  private disableFields() {
    Object.keys(this.form.controls).forEach((controlName: ConfigurationFields) => {
      if (this.isDisableForCurrentSourceType(controlName)) {
        this.form.get(controlName).disable();
      }
    });
  }

  private onChangeIsUseLocalCoordinateSystem() {
    this.form
      .get('IsLocalCrs')
      .valueChanges.pipe(
        tap(isLocalCrs => (isLocalCrs ? this.disableLocalCoordinatesRelatedFields() : this.enableLocalCoordinatesRelatedFields())),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private enableLocalCoordinatesRelatedFields() {
    this.form.get('CoordinateSystem').enable({ emitEvent: false });
    this.form.get('VerticalDatum').enable({ emitEvent: false });
    this.form.get('VerticalMeasurementSystem').enable({ emitEvent: false });
    this.form.get('AltitudeMode').enable({ emitEvent: false });
  }

  private disableLocalCoordinatesRelatedFields() {
    this.form.get('CoordinateSystem').disable({ emitEvent: false });
    this.form.get('VerticalDatum').disable({ emitEvent: false });
    this.form.get('VerticalMeasurementSystem').disable({ emitEvent: false });
    this.form.get('AltitudeMode').disable({ emitEvent: false });
  }

  private patchFormValues() {
    if (this.node === undefined || this.dataSourceSettings === undefined) {
      return;
    }

    this.form.patchValue(this.node, { emitEvent: false });

    const dataSourceSettings = this.dataSourceSettings;
    const objectTypeConfiguration = this.node.ObjectTypeConfiguration;

    // Native gis source type

    if (dataSourceSettings.hasOwnProperty('CoordinateSystem')) {
      this.form.get('CoordinateSystem').setValue(dataSourceSettings.CoordinateSystem, { emitEvent: false });
    }

    if (dataSourceSettings.hasOwnProperty('MeasurementSystem')) {
      this.form.get('MeasurementSystem').setValue(dataSourceSettings.MeasurementSystem, { emitEvent: false });
    }

    // Native bim, bim360 source types

    if (objectTypeConfiguration && objectTypeConfiguration.hasOwnProperty('CoordinateSystem')) {
      this.form.get('CoordinateSystem').setValue(objectTypeConfiguration.CoordinateSystem, { emitEvent: false });
    }

    if (objectTypeConfiguration && objectTypeConfiguration.hasOwnProperty('MeasurementSystem')) {
      this.form.get('MeasurementSystem').setValue(objectTypeConfiguration.MeasurementSystem, { emitEvent: false });
    }

    if (objectTypeConfiguration && objectTypeConfiguration.hasOwnProperty('IsMeshInteractionEnabled')) {
      this.form.get('IsMeshInteractionEnabled').setValue(objectTypeConfiguration.IsMeshInteractionEnabled, { emitEvent: false });
    }

    if (objectTypeConfiguration && objectTypeConfiguration.hasOwnProperty('FullModel')) {
      this.form.get('FullModel').setValue(objectTypeConfiguration.FullModel, { emitEvent: false });
    }

    if (objectTypeConfiguration && objectTypeConfiguration.hasOwnProperty('IsLocalCrs')) {
      this.form.get('IsLocalCrs').setValue(objectTypeConfiguration.IsLocalCrs, { emitEvent: false });

      if (objectTypeConfiguration.IsLocalCrs) {
        this.disableLocalCoordinatesRelatedFields();
      }
    }
  }
}
