import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { IdentityService } from '../../../services/identity.service';
import { PaginationService } from '../../../services/pagination.service';
import { DataSourcesService } from '../services/data-sources.service';
import { ServerPaginatedData } from '../../../interfaces/server-paginated-data';

export const SourcesResolver: ResolveFn<ServerPaginatedData> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  dataSourcesService = inject(DataSourcesService),
  paginationService = inject(PaginationService),
  identityService = inject(IdentityService),
  router = inject(Router)
): Observable<ServerPaginatedData> => {
  let options = paginationService.paginationOptions;
  const routerState = router.getCurrentNavigation()?.extras.state;

  if (routerState !== undefined && routerState.hasOwnProperty('searchParam')) {
    options = { ...options, filter: routerState.searchParam };
  }

  return dataSourcesService.getServerPaginatedList$(options, identityService.companySlug);
}
