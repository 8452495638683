// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  display: flex;
  flex-direction: column;
}
form .form-row {
  margin-bottom: 8px;
}
form .form-row::ng-deep .mat-form-field .mat-form-field-wrapper {
  padding-bottom: 20px;
}
form .form-row::ng-deep .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  padding: 8px 0;
  border-top: 12px solid transparent;
}
form .form-row::ng-deep .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element, form .form-row::ng-deep .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select {
  display: flex;
  max-height: 16px;
  margin: 0;
}
form .form-row::ng-deep .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
  top: -12px;
  padding-top: 12px;
}
form .help-tooltip {
  position: absolute;
  right: 25px;
  bottom: 5px;
  color: rgba(13, 22, 33, 0.6);
  z-index: 1;
}
form .warning-about-set-public {
  width: 345px;
  height: 64px;
  padding: 16px;
  background: rgba(255, 34, 51, 0.1);
  border-radius: 4px;
  display: flex;
  margin: 10px 0 20px 0;
}
form .warning-about-set-public .icon {
  color: #EE2233;
  margin-right: 10px;
  font-size: 17px;
}
form .warning-about-set-public .text {
  font-family: Roboto Condensed, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #EE2233;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/permissions-fields/permissions-fields-sa-ca/permissions-fields-sa-ca.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAGQ;EACE,oBAAA;AADV;AAIY;EACE,cAAA;EACA,kCAAA;AAFd;AAIc;EACE,aAAA;EACA,gBAAA;EACA,SAAA;AAFhB;AAKc;EACE,UAAA;EACA,iBAAA;AAHhB;AAYE;EACE,kBAAA;EACA,WAAA;EACA,WAAA;EACA,4BAAA;EACA,UAAA;AAVJ;AAaE;EACE,YAAA;EACA,YAAA;EACA,aAAA;EACA,kCAAA;EACA,kBAAA;EACA,aAAA;EACA,qBAAA;AAXJ;AAaI;EACE,cAAA;EACA,kBAAA;EACA,eAAA;AAXN;AAcI;EACE,yCAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAZN","sourcesContent":["form {\n  display: flex;\n  flex-direction: column;\n\n  .form-row {\n    margin-bottom: 8px;\n\n    &::ng-deep {\n      .mat-form-field {\n        .mat-form-field-wrapper {\n          padding-bottom: 20px;\n\n          .mat-form-field-flex {\n            .mat-form-field-infix {\n              padding: 8px 0;\n              border-top: 12px solid transparent;\n\n              .mat-input-element, .mat-select {\n                display: flex;\n                max-height: 16px;\n                margin: 0;\n              }\n\n              .mat-form-field-label-wrapper {\n                top: -12px;\n                padding-top: 12px;\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n\n  .help-tooltip {\n    position: absolute;\n    right: 25px;\n    bottom: 5px;\n    color: rgba(13, 22, 33, 0.6);\n    z-index: 1;\n  }\n\n  .warning-about-set-public {\n    width: 345px;\n    height: 64px;\n    padding: 16px;\n    background: rgba(255, 34, 51, 0.1);\n    border-radius: 4px;\n    display: flex;\n    margin: 10px 0 20px 0;\n\n    .icon {\n      color: #EE2233;\n      margin-right: 10px;\n      font-size: 17px;\n    }\n\n    .text {\n      font-family: Roboto Condensed, sans-serif;\n      font-size: 14px;\n      line-height: 16px;\n      color: #EE2233;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
