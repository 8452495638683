import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { VerticalDatums } from '../../../sources/vertical-datums.enum';
import { Subject } from 'rxjs';
import { ArcGisCustomElevationFormData } from '../../../components/models/arc-gis-custom-elevation-form-data';
import { TranslationHandleService } from '../../../../core/translate/translation-handle.service';
import { CustomValidatorsService } from '../../../../shared/services/custom-validators.service';
import { takeUntil, tap } from 'rxjs/operators';
import { ArcGisSecurityTypes } from '../../../sources/arcgis-security-types.enum';

@Component({
  selector: 'mee-arcgis-custom-dtm-form',
  templateUrl: './arcgis-custom-dtm-form.component.html',
  styleUrls: ['./arcgis-custom-dtm-form.component.scss'],
})
export class ArcgisCustomDtmFormComponent implements OnInit, OnDestroy {
  arcGisCustomElevationForm: UntypedFormGroup;
  securityTypes: { name: string; value: number }[];
  translationMap: Map<string, string>;
  verticalDatum = VerticalDatums;
  private readonly destroy$ = new Subject<void>();
  @Output() arcGisDataChanged = new EventEmitter<ArcGisCustomElevationFormData>(); // <IArcGisMapProviderForm>

  constructor(
    private translationHandleService: TranslationHandleService,
    private customValidatorsService: CustomValidatorsService,
    private readonly formBuilder: UntypedFormBuilder,
  ) {
    translationHandleService.setPhrasesForTranslate([
      {
        public: 'sources.details.Public',
      },
      {
        password: 'sources.details.Password',
      },
    ]);

    translationHandleService
      .getTranslationMap()
      .pipe(takeUntil(this.destroy$))
      .subscribe(translationMap => {
        this.translationMap = translationMap;
      });
  }

  ngOnInit() {
    this.initArcGisCustomMapForm();
    this.initSecurityTypes();
    this.onChangeFormValue();

    /*TODO implement custom control value accessor*/

    this.arcGisDataChanged.next({data: null, isFormInvalid: true});
  }

  getControlError(controlName: string): boolean {
    const control = this.arcGisCustomElevationForm.get(controlName);

    if (control === null) {
      return false;
    }

    return control.errors && control.errors.required && (control.dirty || control.touched);
  }

  initArcGisCustomMapForm() {
    this.arcGisCustomElevationForm = this.formBuilder.group({
      SecurityType: [ArcGisSecurityTypes.Public],
      ElevationServerUrl: ['', Validators.required],
      AuthenticationUrl: [''],
      ArcGisUsername: [''],
      Password: [''],
      VerticalDatum: [null],
      IsArcGisOnline: [false],
    });
  }

  initSecurityTypes() {
    this.securityTypes = [
      {
        name: this.translationMap.get('public'),
        value: ArcGisSecurityTypes.Public,
      },
      {
        name: this.translationMap.get('password'),
        value: ArcGisSecurityTypes.Password,
      },
    ];
  }

  getVerticalDatum() {
    return Object.keys(VerticalDatums)
      .filter(datum => isNaN(Number(datum)))
      .sort((a, b) => a.localeCompare(b));
  }

  onChangeFormValue() {
    this.arcGisCustomElevationForm.valueChanges
      .pipe(
        tap(value => {
          let arcGisDataForCustomMap;
          if (this.isArcGisPassword()) {
            this.setArcGisSecurityValidators();
            arcGisDataForCustomMap = {
              data: {
                ElevationServerUrl: value.ElevationServerUrl,
                AuthenticationUrl: value.AuthenticationUrl,
                ArcGisUsername: value.ArcGisUsername,
                ArcGisPassword: value.Password,
                IsArcGisOnline: value.IsArcGisOnline,
                VerticalDatum: value.VerticalDatum,
              },
            };
          } else {
            this.clearArcGisSecurityValidators();
            arcGisDataForCustomMap = {
              data: {
                ElevationServerUrl: value.ElevationServerUrl,
                IsArcGisOnline: value.IsArcGisOnline,
                VerticalDatum: value.VerticalDatum,
              },
            };
          }

          arcGisDataForCustomMap.isFormInvalid = this.arcGisCustomElevationForm.invalid;
          this.arcGisDataChanged.next(arcGisDataForCustomMap);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  isArcGisPassword() {
    return this.arcGisCustomElevationForm.get('SecurityType').value === ArcGisSecurityTypes.Password;
  }

  setArcGisSecurityValidators() {
    this.arcGisCustomElevationForm.get('AuthenticationUrl').setValidators([Validators.required]);
    this.arcGisCustomElevationForm.get('ArcGisUsername').setValidators([Validators.required]);
    this.arcGisCustomElevationForm.get('Password').setValidators([Validators.required]);
  }

  clearArcGisSecurityValidators() {
    this.arcGisCustomElevationForm.get('AuthenticationUrl').clearValidators();
    this.arcGisCustomElevationForm.get('ArcGisUsername').clearValidators();
    this.arcGisCustomElevationForm.get('Password').clearValidators();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.translationHandleService.cleanTranslationArray();
  }
}
