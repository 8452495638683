import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Bim360AccountDisconnectedData } from './bim360-account-disconnected-data';

@Component({
  selector: 'mee-bim360-account-disconnected',
  templateUrl: './bim360-account-disconnected.component.html',
  styleUrls: ['./bim360-account-disconnected.component.scss'],
})
export class Bim360AccountDisconnectedComponent {

  constructor(@Inject(MAT_DIALOG_DATA) private data: Bim360AccountDisconnectedData) {
  }

  get routerLinkOnEditSource() {
    return ['sources', this.data.companySlug, this.data.sourceSlug, 'edit']
  }
}
