import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CoordinateSystem } from './coordinate-system';
import { CoordinateSystemsService } from './coordinate-systems.service';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';


export const CoordinateSystemsResolver: ResolveFn<CoordinateSystem[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  coordinateSystemsService = inject(CoordinateSystemsService
  )
): Observable<CoordinateSystem[]> => coordinateSystemsService.get();
