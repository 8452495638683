import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserRole } from '../../../interfaces/user-role';
import { merge, Subject } from 'rxjs';
import { IdentityService } from '../../../services/identity.service';
import { UsersService } from '../services/users.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UserModalData } from '../models/user-modal-data';
import { IdentityRole } from '../../../interfaces/identity-role';
import { LoadingService } from '../../../core/loading-overlay/loading.service';
import { TeamRoleFields } from '../../../interfaces/team-role-fields';
import { TeamProjectService } from '../../../services/team-project.service';
import { CompanyRoleFields } from '../../../interfaces/company-role-fields';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ICompany } from '../../companies/shared/interfaces/company.interface';
import { UserModalRoles } from '../models/user-modal-roles';

@Component({
  selector: 'mee-create-user-modal',
  templateUrl: './create-user-modal.component.html',
  styleUrls: ['./create-user-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateUserModalComponent implements OnDestroy {
  form: UntypedFormGroup;
  selectedCompanySlug: string;
  private userModalRolesCollection = new Map<string, UserModalRoles>();
  private readonly destroy$ = new Subject<void>();

  get isSuperAdmin(): boolean {
    return this.identity.isSuperAdmin();
  }

  get userRole(): typeof UserRole {
    return UserRole;
  }

  get adminRoles(): UntypedFormArray {
    return this.form.get('AdminRoles') as UntypedFormArray;
  }

  get workerRoles(): UntypedFormArray {
    return this.form.get('WorkerRoles') as UntypedFormArray;
  }

  get isDisplayAdminAccess(): boolean {
    return this.adminRoles.controls.length > 0;
  }

  get isUserHasSuperAdminRole(): boolean {
    return this.adminRoles.controls.some(role => role.get('Name').value === UserRole.SUPER_ADMINISTRATOR);
  }

  get isCanAddAdminRole(): boolean {
    const isSuperOrCompanyAdmin = this.adminRoles.controls.some(role => {
      const roleName = role.get('Name').value;

      return roleName === UserRole.SUPER_ADMINISTRATOR || roleName === UserRole.COMPANY_ADMINISTRATOR;
    })

    return !isSuperOrCompanyAdmin && !this.identity.isTeamAdmin();
  }

  get isDisplayCompanyControl(): boolean {
    return this.userModalData.isAllTeamProject && this.isSuperAdmin && !this.isUserHasSuperAdminRole;
  }

  get isAddAdminRoleDisabled(): boolean {
    return this.isDisplayCompanyControl && !this.selectedCompanySlug;
  }

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly teamProjectService: TeamProjectService,
    private readonly identity: IdentityService,
    private readonly usersService: UsersService,
    public readonly loadingService: LoadingService,
    private readonly matDialog: MatDialogRef<CreateUserModalComponent>,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) readonly userModalData: UserModalData
  ) {
    this.initForm();
    this.form.patchValue(this.userModalData.user);

    if (this.userModalData.user.Roles && this.userModalData.user.Roles.length > 0) {
      this.setUserRolesCollection(this.userModalData.user.Roles);
      this.selectedCompanySlug = this.userModalData.user.Roles[0].CompanySlug;
      this.form.get('CompanyControl').setValue(this.selectedCompanySlug, { emitEvent: false });
      this.setDisplayedRoles(this.userModalRolesCollection.get(this.selectedCompanySlug));

      if (this.isUserHasSuperAdminRole) {
        this.workerRoles.disable();
      }
    }

    this.onRolesChange();
    this.onCompanyChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getRoleCompanySlug(): string {
    return this.isDisplayCompanyControl ? this.selectedCompanySlug : this.identity.companySlug;
  }

  removeRole(index: number): void {
    this.adminRoles.removeAt(index);
  }

  getFormGroup(role: AbstractControl): UntypedFormGroup {
    return role as UntypedFormGroup;
  }

  isRoleTeamAdmin(role: AbstractControl): boolean {
    return role.get('Name').value === UserRole.TEAM_ADMINISTRATOR;
  }

  isUserRoleCanDelete(roleGroup: AbstractControl) {
    const userRoleName = roleGroup.get('Name').value;
    const userEmail = this.form.get('Email').value;

    if (this.identity.isCompanyAdmin() && userEmail === this.identity.user.Email) {
      return userRoleName !== UserRole.COMPANY_ADMINISTRATOR;
    }

    if (this.identity.isTeamAdmin()) {
      return userRoleName !== UserRole.TEAM_ADMINISTRATOR;
    }

    return true;
  }

  onRoleChange(role: AbstractControl): void {
    const roleName = role.get('Name').value;

    if (roleName === UserRole.SUPER_ADMINISTRATOR) {
      this.workerRoles.reset([]);
      this.workerRoles.disable();
    } else {
      this.workerRoles.enable();
    }

    // for case super admin role change on company role

    if (this.isDisplayCompanyControl && !this.selectedCompanySlug) {
      this.adminRoles.clear();

      return;
    }

    if (roleName === UserRole.TEAM_ADMINISTRATOR) {
      return;
    }

    role.reset();
    role.get('Name').setValue(roleName);

    this.adminRoles.controls = this.adminRoles.controls.filter(filterRole => {
      const roleData = filterRole.value;

      return roleData.Name === roleName;
    });
  }

  save(): void {
    const formValue = this.form.getRawValue();
    const Roles = this.getRolesForCreateUpdate();
    delete formValue.WorkerRoles;
    delete formValue.AdminRoles;

    const createEditUserData = { ...formValue, Roles };

    if (!this.userModalData.isNewUser) {
      this.usersService.updateUser(createEditUserData, this.userModalData.isAllTeamProject, this.userModalData.user.Id).pipe(
        tap(() => this.matDialog.close(true)),
        takeUntil(this.destroy$)
      ).subscribe()

      return;
    }

    this.usersService.createUser(createEditUserData, this.userModalData.isAllTeamProject).pipe(
      tap(() => this.matDialog.close(true)),
      takeUntil(this.destroy$)
    ).subscribe()
  }

  addAdminRole(): void {
    const role = this.fb.group({
      Name: [UserRole.TEAM_ADMINISTRATOR, [Validators.required]],
      CompanyName: '',
      CompanyId: '',
      CompanySlug: '',
      TeamName: [],
      TeamId: [],
      TeamSlug: []
    });

    this.adminRoles.push(role);
  }

  private initForm(): void {
    this.form = this.fb.group({
      Email: [{ value: '', disabled: true }, [Validators.required]],
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      CompanyControl: [''],
      WorkerRoles: [],
      AdminRoles: this.fb.array([])
    });
  }

  private createRole(role: IdentityRole): UntypedFormGroup {
    const roleFormGroup = this.fb.group({
      Name: [role.Name, [Validators.required]],
      CompanyName: [role.CompanyName],
      CompanyId: [role.CompanyId],
      CompanySlug: [role.CompanySlug],
      TeamName: [role.TeamName],
      TeamId: [role.TeamId],
      TeamSlug: [role.TeamSlug]
    });

    if (this.identity.isTeamAdmin()) {
      roleFormGroup.disable();
    }

    return roleFormGroup;
  }

  private onCompanyChange(): void {
    this.form.get('CompanyControl').valueChanges.pipe(
      filter(company => company && typeof company === 'object'),
      tap((company: ICompany) => {
        this.workerRoles.setValue([], { emitEvent: false });
        this.adminRoles.clear({ emitEvent: false });
        this.selectedCompanySlug = company.Slug;
        this.setDisplayedRoles(this.userModalRolesCollection.get(this.selectedCompanySlug));
        this.cdr.markForCheck();
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  private onRolesChange(): void {
    const workerRoles$ = this.workerRoles.valueChanges;
    const adminsRoles$ = this.adminRoles.valueChanges;

    merge(workerRoles$, adminsRoles$).pipe(
      filter(() => Boolean(this.selectedCompanySlug)),
      tap(() => {
        const userRoles: UserModalRoles = {
          workerRoles: this.getWorkersRoles(this.workerRoles.value),
          adminRoles: this.getAdminsRoles(this.adminRoles.value)
        }

        this.userModalRolesCollection.set(this.selectedCompanySlug, userRoles);
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  private getRolesForCreateUpdate(): IdentityRole[] {
    let roles = [];

    if (this.isDisplayCompanyControl) {
      const userModalRoles = [...this.userModalRolesCollection.values()];

      userModalRoles.forEach(role => {
        const filteredAdminRoles = role.adminRoles.filter(adminRole => adminRole.Name !== UserRole.SUPER_ADMINISTRATOR);

        roles = [...roles, ...role.workerRoles, ...filteredAdminRoles];
      })

      return roles;
    }

    const adminsRoles = this.getAdminsRoles(this.adminRoles.value);
    const workerRoles = this.getWorkersRoles(this.workerRoles.value);

    roles = [...workerRoles, ...adminsRoles];

    return roles;
  }

  private getAdminsRoles(adminRoles): IdentityRole[] {
    if (!adminRoles || adminRoles.length === 0) {
      return [];
    }

    return adminRoles.map(role => {
      if (role.Name === UserRole.TEAM_ADMINISTRATOR || role.Name === UserRole.COMPANY_ADMINISTRATOR) {
        const selectedCompanyFields = this.getSelectedCompanyFields();

        role = { ...role, ...selectedCompanyFields };

        if (role.TeamSlug) {
          role = { ...role, TeamId: role.TeamSlug.Id, TeamSlug: role.TeamSlug.Slug, TeamName: role.TeamSlug.Name };
        }
      }

      return role;
    })
  }

  private getWorkersRoles(workerRolesField: IdentityRole[]): IdentityRole[] {
    if (!workerRolesField || workerRolesField.length === 0) {
      return [];
    }

    const selectedCompanyFields = this.getSelectedCompanyFields();

    return workerRolesField.map(workerField => {
      return { ...selectedCompanyFields, Name: UserRole.WORKER, ...workerField } as IdentityRole;
    })
  }

  private getSelectedCompanyFields(): CompanyRoleFields {
    if (this.isDisplayCompanyControl) {
      const company = this.form.get('CompanyControl').value;

      return { CompanyId: company.Id, CompanySlug: company.Slug, CompanyName: company.Name };
    }

    const teamProject = this.teamProjectService.teamProject

    return { CompanyId: teamProject.CompanyId, CompanySlug: teamProject.CompanySlug, CompanyName: teamProject.CompanyName };
  }

  private setDisplayedRoles(roles: UserModalRoles): void {
    if (!roles) {
      return;
    }

    const workersRoles = roles.workerRoles.map(role => {
      return { TeamSlug: role.TeamSlug, TeamName: role.TeamName, TeamId: role.TeamId } as TeamRoleFields
    })

    this.workerRoles.setValue(workersRoles, { emitEvent: false });
    roles.adminRoles.forEach(role => this.adminRoles.push(this.createRole(role)));
  }

  private setUserRolesCollection(userRoles: IdentityRole[]): void {
    this.userModalRolesCollection = new Map<string, UserModalRoles>(userRoles.map(item => {
      const rolesByCompanySlug = userRoles.filter(role => role.CompanySlug === item.CompanySlug);
      const workerRoles = rolesByCompanySlug.filter(role => role.Name === UserRole.WORKER);
      const adminRoles = rolesByCompanySlug.filter(role => role.Name !== UserRole.WORKER);
      const userModalRoles: UserModalRoles = { workerRoles, adminRoles };

      return [item.CompanySlug, userModalRoles];
    }));
  }
}
