import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ISource } from '../models/source.interface';
import { DataSourcesService } from '../services/data-sources.service';
import { IdentityService } from '../../../services/identity.service';
import { TeamProjectService } from '../../../services/team-project.service';

export const DataSourceResolver: ResolveFn<ISource> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  dataSourceService = inject(DataSourcesService),
  identityService = inject(IdentityService),
  teamProjectService = inject(TeamProjectService)
): Observable<ISource> => {
  const companySlug = route.paramMap.get('companySlug');
  const sourceSlug = route.paramMap.get('sourceSlug');

  teamProjectService.companySlugFromRoute = companySlug;

  return dataSourceService.getByCompany(companySlug, sourceSlug);
}
