import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CopyConfigFromService, IDialogDataForCopyConfigItem } from '../copy-config-from.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ISource } from '../../../../../models/source.interface';
import { TranslationHandleService } from '../../../../../../../core/translate/translation-handle.service';
import isEmpty from 'lodash-es/isEmpty';
import find from 'lodash-es/find';
import each from 'lodash-es/each';
import cloneDeep from 'lodash-es/cloneDeep';
import { SourceConfiguration } from '../../configuration-tab/interfaces/source-configuration';
import { ObjectType } from '../../configuration-tab/enums/object-types.enum';
import { PointSubtype } from '../../configuration-tab/interfaces/point-subtype';
import { LineSubtype } from '../../configuration-tab/enums/line-subtypes.enum';
import { SubtypeConfiguration } from '../../configuration-tab/interfaces/subtype-configuration';

const PHRASES_FOR_TRANSLATE = [
  {
    copyWasSuccess: 'sources.configuration.copyWasSuccess',
  },
  {
    copyWasFailed: 'sources.configuration.copyWasFailed',
  },
];

@Component({
  selector: 'mee-copy-config-from',
  templateUrl: './copy-config-from-item.component.html',
  styleUrls: ['./copy-config-from-item.component.scss'],
})
export class CopyConfigFromItemComponent implements OnInit {

  public fixedRows = [
    {
      title: 'configurations-fields.columns.layerName',
      key: 'LayerName',
    },
  ];

  activatedNode: SourceConfiguration;

  public BoundingBoxShape = [
    { id: 0, name: 'Cylinder' },
    { id: 1, name: 'Cube' },
    { id: 2, name: 'Sphere' },
  ];
  public Shape = [
    { id: 0, name: 'Cylinder' },
    { id: 1, name: 'Box' },
  ];

  orderableList: any[];

  expandedNodes = [];

  hoveredItemId: number;

  ObjectType = ObjectType;

  isActivateNode = false;

  options: Array<{ name: string, objectType: string }> = [];

  isCopyDSToDS: boolean;

  originalData: any;

  changeFeatureLayers = new Map();

  isCopyButonDisabled = false;

  translationMap: Map<string, string>;

  constructor(private matDialog: MatDialog,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) public dialogData: IDialogDataForCopyConfigItem,
              private copyConfigFromService: CopyConfigFromService,
              private translateService: TranslateService,
              private translationHandleService: TranslationHandleService,
              private snackBar: MatSnackBar) {

    translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);

    translationHandleService.getTranslationMap().subscribe((translationMap: Map<string, string>) => {
      this.translationMap = translationMap;
    });
  }

  ngOnInit() {

    this.isCopyDSToDS = typeof this.dialogData.DSWithPossibleCopyFLConfig !== 'undefined';

    this.originalData = cloneDeep(this.dialogData.DSWithPossibleCopyFLConfig);

    if (this.dialogData.DSWithPossibleCopyFLConfig) {

      /*Copy data source config to data source config*/

      const isNotAvailableLayersForConfig = isEmpty(this.dialogData.DSWithPossibleCopyFLConfig.DirectMatch)
        && isEmpty(this.dialogData.DSWithPossibleCopyFLConfig.PossibleMatch);

      this.isCopyButonDisabled = isNotAvailableLayersForConfig;

      this.expandedNodes.push(this.dialogData.DSWithPossibleCopyFLConfig.RootFeatureLayerConfiguration.Id);

      this.modifyingTree(this.dialogData.DSWithPossibleCopyFLConfig.RootFeatureLayerConfiguration);

      this.orderableList = this.treeToList(this.dialogData.DSWithPossibleCopyFLConfig.RootFeatureLayerConfiguration);

    } else {

      /*Copy feature layer config to feature layer config*/

      this.expandedNodes.push(this.dialogData.sourceDS.RootFeatureLayerConfiguration.Id);

      this.modifyingTree(this.dialogData.sourceDS.RootFeatureLayerConfiguration);

      this.orderableList = this.treeToList(this.dialogData.sourceDS.RootFeatureLayerConfiguration);
    }
  }

  showNode(node): boolean {
    return node.isRoot || this.expandedNodes.indexOf(node.parentId) !== -1;
  }


  onChangeAlternativeLayer(chosenLayer, layerToReplace) {

    layerToReplace.node.firstAlternativeLayer = chosenLayer.value;

    // chosenLayer.value.Id === null when user chose not selected option

    if (chosenLayer.value.Id !== null) {
      this.changeFeatureLayers.set(layerToReplace.Id, chosenLayer.value);
    } else {
      this.changeFeatureLayers.delete(layerToReplace.Id);
    }

    this.originalData.DirectMatch = Object.fromEntries(this.changeFeatureLayers.entries());
  }


  /**
   * Methods used for Collapsing / Expanding of table branches
   * @param {Event} event
   * @param {Configuration} node
   */
  collapseNode(event, node) {
    event.stopPropagation();
    this.collapseChildren(node.node);
  }

  expandNode(event, node) {
    event.stopPropagation();
    node.expanded = true;
    this.expandedNodes.push(node.Id);
  }


  collapseChildren(node: SourceConfiguration) {
    find(this.orderableList, { Id: node.Id as any }).expanded = node.expanded = false;

    each(node.ChildLayers, (layer) => {
      this.collapseChildren(layer);
    });

    const index = this.expandedNodes.indexOf(node.Id);
    if (index > -1) {
      this.expandedNodes.splice(index, 1);
    }

    // this.syncScrollWidth();
  }


  treeToList(data: any) {

    const result = [];

    const lvl = 1;

    data.isRoot = true;

    /**
     * For Adding new row into the array
     * @param node
     * @param {number} level
     * @param {Array<any>} res
     */
    const addLine = (node, level: number, res: Array<any>) => {
      node.hasChildren = (node.ChildLayers && node.ChildLayers.length)
        || (node.SubtypesConfigurations && node.SubtypesConfigurations.length);

      const line = this.makeLine(node, level);

      res.push(line);

      each(node.ChildLayers, (child: SourceConfiguration) => {
        child.parentId = node.Id;
        addLine(child, level + 1, result);
      });

      each(node.SubtypesConfigurations, (subtype: SubtypeConfiguration) => {
        subtype.parentId = node.Id;
        subtype.isSubtype = true;
        addLine(subtype, level + 1, result);
      });
    };

    addLine(data, lvl, result);

    return result;
  }

  /**
   * Actualizing the inputted tree, set expanded branches and other previous decorations
   * Used after canceling of all tree updates
   * @param {Configuration} node
   */
  modifyingTree(node) {
    if (this.expandedNodes.length && this.expandedNodes.indexOf(node.Id) !== -1) {
      node.expanded = true;
    }

    // Array for all node layers for possible config (direct and possible matches if any)

    const allMatches = [];

    if (this.isCopyDSToDS && node.LayerId !== 'ROOT') {

      // Direct matches for feature layer

      if (!isEmpty(this.dialogData.DSWithPossibleCopyFLConfig.DirectMatch)) {

        if (node.ChildLayers && node.ChildLayers.length) {

          node.ChildLayers.forEach(layer => {

            this.modifyingTree(layer);

            return;
          });
        }

        // Layers that match some of our layers by object type and name

        const directMatches = this.dialogData.DSWithPossibleCopyFLConfig.DirectMatch;

        // Check if direct matches includes current layer

        if (directMatches[node.Id]) {

          // Compare layer in direct matches and current layer by names

          node.isDirectMatch = node.LayerName === directMatches[node.Id].LayerName;

          if (node.LayerName === directMatches[node.Id].LayerName) {
            allMatches.push(this.dialogData.DSWithPossibleCopyFLConfig.DirectMatch[node.Id]);
          }
        }

      } else {
        node.isDirectMatch = false;
      }

      // Possible matches for feature layer

      if (!isEmpty(this.dialogData.DSWithPossibleCopyFLConfig.PossibleMatch)) {

        if (node.ChildLayers && node.ChildLayers.length) {

          node.ChildLayers.forEach(layer => {

            this.modifyingTree(layer);

            return;
          });
        }

        // Layers that match some of our layers only by object type

        const possibleMatches = this.dialogData.DSWithPossibleCopyFLConfig.PossibleMatch[this.getObjectTypeName(node.ObjectType)];

        // Check for excluding direct match layer from possible layers

        let filteredPossibleMatches;

        if (allMatches.length) {
          filteredPossibleMatches = possibleMatches.filter(item => !allMatches.find(a => a.Id === item.Id));
        } else {
          filteredPossibleMatches = possibleMatches;
        }

        if (filteredPossibleMatches) {
          allMatches.push(...filteredPossibleMatches);

          node.possibleLayersForConfig = allMatches;

          /*
          * If current layer dont have direct matches
          * user should have possibility to "not select" option if he dont want to copy some layer from source data source
          * and keep layer config from destination data source
          * */

          if (!node.isDirectMatch && node.possibleLayersForConfig[0].Id !== null) {
            node.possibleLayersForConfig.unshift({
              ChildLayers: [],
              Id: null,
              IsFavorite: false,
              LayerId: null,
              LayerName: 'not selected',
              ObjectSubType: null,
              ObjectType: null,
            });
          }

          node.firstAlternativeLayer = node.possibleLayersForConfig[0];

        }
      }

    }

    node.isLeaf = false;
    node.isBranch = true;

    if (node.ChildLayers && !node.ChildLayers.length) {

      node.isLeaf = true;
      node.isBranch = false;

      return;
    }

    each(node.ChildLayers, (layer) => {
      this.modifyingTree(layer);
    });

    return;
  }


  /**
   * For Creating needed row from the tree node
   * @param data
   * @param level
   */
  makeLine(data, level) {
    const node = {
      Id: data.Id,
      parentId: data.parentId || null,
      LayerName: data.isSubtype ? data.SubtypeValue : data.LayerName,
      DisplayName: data.DisplayName,
      node: data,
      level: level,
      isRoot: data.isRoot || false,
      isSubtype: data.isSubtype || false,
      isBranch: data.isBranch || false,
      isExcludedFromProcessing: data.IsExcludedFromProcessing || false,
      isLeaf: data.isLeaf || false,
      hasChildren: data.hasChildren || false,
      changed: data.changed,
      expanded: data.expanded,
    };

    return node;
  }

  getObjectTypeName(type) {
    switch (type) {
      case ObjectType.UNKNOWN:
        return 'Unknown';
      case ObjectType.POINT:
        return 'Point';
      case ObjectType.LINE:
        return 'Line';
      case ObjectType.AREA:
        return 'Area';
      case ObjectType.MODEL3D:
        return 'Model 3D';
    }
  }

  getObjectTypeImage(type) {
    switch (type) {
      case ObjectType.UNKNOWN:
        return `<img src="/assets/img/icons/unknown.svg"/>`;
      case ObjectType.POINT:
        return `<img src="/assets/img/icons/point.svg"/>`;
      case ObjectType.LINE:
        return `<img src="/assets/img/icons/line.svg"/>`;
      case ObjectType.AREA:
        return `<img src="/assets/img/icons/area.svg"/>`;
      case ObjectType.MODEL3D:
        return `<img src="/assets/img/icons/3d.svg"/>`;
      default:
        return '';
    }
  }


  getObjectSubtypeName(node, key) {

    if (!key) {
      return '';
    }

    if (node.node['ObjectType'] === ObjectType.LINE) {
      switch (key) {
        case LineSubtype.PIPE:
          return 'Pipe';
        case LineSubtype.LINE:
          return 'Line';
      }
    }

    if (node.node['ObjectType'] === ObjectType.POINT) {
      switch (key) {
        case PointSubtype.UNKNOWN:
          return 'Unknown';
        case PointSubtype.OBJECT:
          return 'Object';
        case PointSubtype.MANHOLE:
          return 'Manhole/Inlet';
        case PointSubtype.CUSTOM:
          return 'Custom';
      }
    }
  }

  activateNode(node: SourceConfiguration) {
    if (this.isCopyDSToDS || node.isBranch || node.isSubtype || node.isRoot) {
      return;
    }

    this.isActivateNode = true;

    this.activatedNode = node;


  }

  onCloseModal() {
    this.matDialog.closeAll();
  }

  onBackToChooseDs() {
    this.copyConfigFromService.onBackToList(true);
    this.matDialog.closeAll();
  }

  isCopyButtonDisabled() {
    if (this.isCopyDSToDS) {
      return this.isCopyButonDisabled;
    }
    return !this.isActivateNode || this.activatedNode && this.activatedNode.isRoot;
  }


  onCopyConfig() {

    if (this.isCopyDSToDS) {
      this.copyConfigFromService.getCopyOfDSFromDS(this.dialogData.destinationDS.CompanySlug, this.originalData)
        .subscribe((response: ISource) => {
          this.matDialog.closeAll();
          this.snackBar.open(this.translationMap.get('copyWasSuccess'), 'OK', { duration: 5000 });
          this.copyConfigFromService.setNewDSAfterCopy(response);
        }, () => {
          this.matDialog.closeAll();
          this.snackBar.open(this.translationMap.get('copyWasFailed'), 'OK', { duration: 5000 });
        });

      return;
    }

    const sourceFeatureLayerId =
      this.dialogData.sourceDS.RootFeatureLayerConfiguration['ChildLayers'].find(layer => this.activatedNode.Id === layer.Id).Id;

    const dataForNewLayerAfterCopyConfig = {
      companySlug: this.dialogData.destinationDS.CompanySlug,
      sourceDSId: this.dialogData.sourceDS.Id,
      sourceFeatureLayerConfigId: sourceFeatureLayerId,
      destinationDSId: this.dialogData.destinationDS.Id,
      destinationFeatureLayerConfigId: this.dialogData.destinationLayer.Id,
    };

    this.copyConfigFromService.getNewLayerAfterCopyConfig(dataForNewLayerAfterCopyConfig)
      .subscribe((response: SourceConfiguration) => {
        this.matDialog.closeAll();
        this.snackBar.open(this.translationMap.get('copyWasSuccess'), 'OK', { duration: 5000 });
        this.copyConfigFromService.setNewLayerAfterCopyConfig(response);
      }, () => {
        this.matDialog.closeAll();
        this.snackBar.open(this.translationMap.get('copyWasFailed'), 'OK', { duration: 5000 });
      });
  }

}
