import { Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslationHandleService } from '../../../core/translate/translation-handle.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from '../../../interfaces/confirmation-dialog-data';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const PHRASES_FOR_TRANSLATE = [
  {
    tokenCopiedToClipboard: 'companies.form.ExternalToken.TokenCopiedToClipboard',
  },
  {
    identifierCopiedToClipboard: 'companies.form.ExternalToken.IdentifierCopiedToClipboard',
  },
];

@Component({
  selector: 'mee-vgis-scan-access-token',
  templateUrl: './vgis-scan-access-token.component.html',
  styleUrls: ['./vgis-scan-access-token.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => VgisScanAccessTokenComponent),
    multi: true,
  }],
})
export class VgisScanAccessTokenComponent implements ControlValueAccessor, OnDestroy {
  isShowToken: boolean;

  private translationMap: Map<string, string>;

  private localIdentifier: string;

  private destroy$ = new Subject<void>();

  @Input() tokenCreationDate: Date;

  @Output() onGenerateToken = new EventEmitter<void>();
  @ViewChild('token', { static: true }) tokenInput: ElementRef<HTMLInputElement>;

  @Input() set identifier(identifier: string) {
    this.localIdentifier = identifier;
  }

  get identifier() {
    return this.localIdentifier;
  }

  get isTokenExist() {
    return this.tokenInput.nativeElement.value.length !== 0;
  }

  get isTokenCreatedNow() {
    return this.isTokenExist && this.tokenCreationDate === null;
  }

  onChange: any = () => {};

  onTouch: any = () => {};

  constructor(
    private clipboard: Clipboard,
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    private translationHandleService: TranslationHandleService,
  ) {
    translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);

    translationHandleService.getTranslationMap().subscribe((translationMap: Map<string, string>) => {
      this.translationMap = translationMap;
    });
  }

  showToken() {
    this.isShowToken = !this.isShowToken;
  }

  copyToken() {
    this.clipboard.copy(this.tokenInput.nativeElement.value);
    this.matSnackBar.open(this.translationMap.get('tokenCopiedToClipboard'), 'OK', {
      duration: 2000,
    });
  }

  copyIdentifier() {
    this.clipboard.copy(this.identifier);
    this.matSnackBar.open(this.translationMap.get('identifierCopiedToClipboard'), 'OK', {
      duration: 2000,
    });
  }

  registerOnChange(fn: any): void {
    this.onChange();
  }

  registerOnTouched(fn: any): void {
    this.onTouch();
  }

  writeValue(token: string): void {
    this.setTokenInputValue(token);
  }

  setTokenInputValue(token: string) {
    this.tokenInput.nativeElement.value = token;
  }

  generateToken() {
    this.matDialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(ConfirmationDialogComponent, {
        width: '400px',
        height: '188px',
        data: { subtitleKey: 'companies.form.tokenGenerateWarning' },
        panelClass: 'confirmation-dialog',
        autoFocus: false
      })
      .afterClosed()
      .pipe(
        filter(isConfirmAction => !!isConfirmAction),
        tap(() => this.onGenerateToken.emit()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
