import { Injectable } from '@angular/core';
import { RestClass, RestService } from '../../../../services/rest/rest.service';
import { IDevice } from '../models/device.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
@RestClass('/devices')
export class DevicesService extends RestService<IDevice> {
  /**
   * Device service
   * @param http
   */
  constructor(protected http: HttpClient) {
    super(http);
  }

  /**
   *
   * @param {string} companySlug
   * @param params
   * @returns {string}
   */
  getRouteByCompany(companySlug: string, ...params) {
    return this.baseUrl + `/companies/${companySlug}/` + params.join('/');
  }

  getDevicesByCompany(companySlug: string) {
    return this.http.get(this.getRouteByCompany(companySlug, 'devices'));
  }

  remove(id, options?: { companySlug: string }): Observable<any> {
    /*TODO refactor with http params*/

    /*For company admin*/

    if (options && options.companySlug) {
      return this.http.delete(this.getRoute(id) + `/?companySlug=${options.companySlug}`);
    }

    /*For super admin*/

    return this.http.delete(this.getRoute(id));
  }

  removeMultiple(devicesIds: string[], companySlug?: string): Observable<null> {
    let params: HttpParams = new HttpParams();

    if (companySlug !== undefined) {
      params = params.append('companySlug', companySlug);
    }

    return this.http.delete<null>(`${this.baseUrl}/devices`, { body: devicesIds, params: params });
  }

  deleteFromTeamOnly(deviceId: string, teamSlug: string, companySlug: string) {
    let params: HttpParams = new HttpParams();

    params = params.append('companySlug', companySlug);

    return this.http.delete(`${this.baseUrl}/devices/${deviceId}/teams/${teamSlug}`, { params: params });
  }
}
