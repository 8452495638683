import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IdentityService} from '../../../../services/identity.service';
import {PermissionFields} from '../../../companies/shared/interfaces/permission-fields';
import {CustomMapsService} from '../custom-maps.service';
import {catchError, filter, takeUntil, takeWhile, tap} from 'rxjs/operators';
import {EMPTY, Subject} from 'rxjs';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {TranslationHandleService} from '../../../../core/translate/translation-handle.service';
import {ArcGisMapProviderForm} from '../interfaces/arcgis-map-provider-form';
import {WmtsMapProviderForm} from '../interfaces/wmts-map-provider-form';
import {WmsMapProviderForm} from '../interfaces/wms-map-provider-form';
import {CustomMapType} from '../custom-map-type.enum';
import {identifierMaxLength, slugPattern} from '../../../../core/utils/validators';
import {ArcGisMapProvider} from '../interfaces/arcgis-map-provider';
import {WmtsMapProvider} from '../interfaces/wmts-map-provider';
import {WmsMapProvider} from '../interfaces/wms-map-provider';
import {LayerFieldOptionWms} from '../interfaces/layer-field-option-wms';
import {LayerFieldOptionWmts} from '../interfaces/layer-field-options-wmts';
import {CustomMap} from '../interfaces/custom-map.type';
import {AutofillFieldService} from '../../../../services/autofill-field.service';

const PHRASES_FOR_TRANSLATE = [
  {
    createCustomMap: 'company.baseMaps.customMapWasCreated',
  },
  {
    checkData: 'company.baseMaps.checkData',
  },
  {
    errorOccurred: 'errors.error_occurred',
  },
];

@Component({
  selector: 'mee-add-custom-map',
  templateUrl: './add-custom-map.component.html',
  styleUrls: ['./add-custom-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCustomMapComponent implements OnDestroy {
  customMapForm: UntypedFormGroup;

  permissionsData: PermissionFields;

  arcGisFormValue: { data: ArcGisMapProviderForm; isFormValid: boolean };
  wmtsFormValue: { data: WmtsMapProviderForm; isFormValid: boolean };
  wmsFormValue: { data: WmsMapProviderForm; isFormValid: boolean };

  mapServerUrlsOfCustomMapProviders: string[];

  maps = [
    {
      name: 'ArcGIS MapServer',
      value: CustomMapType.ArcGis,
    },
    {
      name: 'WMTS',
      value: CustomMapType.Wmts,
    },
    {
      name: 'WMS',
      value: CustomMapType.Wms,
    },
  ];

  addedCustomMap: Partial<CustomMap>;

  isPreviewExpanded = true;

  selectedLayer: LayerFieldOptionWms | LayerFieldOptionWmts;

  readonly identifierMaxLength = identifierMaxLength;
  private namesOfCustomMapProviders: Array<string>;

  private translationMap: Map<string, string>;

  private destroy$ = new Subject<void>();

  get isSuperAdmin() {
    return this.identityService.isSuperAdmin();
  }

  get isCompanyAdmin() {
    return this.identityService.isCompanyAdmin();
  }

  constructor(
    private matDialogRef: MatDialogRef<AddCustomMapComponent>,
    private snackBar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private customMapsService: CustomMapsService,
    private identityService: IdentityService,
    private cdr: ChangeDetectorRef,
    private translationHandleService: TranslationHandleService,
    private readonly autofillFieldService: AutofillFieldService,
  ) {
    translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);

    translationHandleService
      .getTranslationMap()
      .pipe(
        tap((translationMap: Map<string, string>) => (this.translationMap = translationMap)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.customMapsService.customMapProviderListChanged$
      .pipe(
        tap(customMapProvidersList => {
          this.namesOfCustomMapProviders = [];
          this.getCustomMapProvidersNames(customMapProvidersList);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.customMapForm = this.formBuilder.group({
      Slug: [{value: null, disabled: true}, [Validators.required, Validators.pattern(slugPattern)]],
      Name: [null, Validators.required],
      Type: [null, Validators.required],
    });

    this.customMapForm.statusChanges.subscribe(status => {
      if (this.selectedLayer !== undefined && status === 'VALID') {
        this.addedCustomMap = this.getRequestData();
      }
    });

    this.setAutoFillValue();
  }

  getCustomMapProvidersNames(customMapProviders) {
    this.namesOfCustomMapProviders = customMapProviders.map(i => i.Name.toLowerCase().trim());

    this.mapServerUrlsOfCustomMapProviders = customMapProviders.map(item => {
      if (item.Type === 'ArcGis') {
        return item.MapServerUrl.toLowerCase().trim();
      }
    });
  }

  onChangePermissionsData(permissionsData: PermissionFields) {
    this.permissionsData = permissionsData;
  }

  isArcGisMapService() {
    const mapType = this.customMapForm.get('Type').value;

    return this.customMapsService.isArcGisMapService(mapType);
  }

  isWMTSMapService() {
    const mapType = this.customMapForm.get('Type').value;

    return this.customMapsService.isWMTSMapService(mapType);
  }

  isWMSMapService() {
    const mapType = this.customMapForm.get('Type').value;

    return this.customMapsService.isWMSMapService(mapType);
  }

  arcGisDataChanged(arcgisValue) {
    this.arcGisFormValue = arcgisValue;
  }

  isArcGisFormInvalid() {
    return (this.isArcGisMapService() && !this.customMapForm.valid) || (this.arcGisFormValue && !this.arcGisFormValue.isFormValid);
  }

  onWMTSDataChanged(wmtsValue) {
    this.wmtsFormValue = wmtsValue;

    this.addedCustomMap = this.getRequestData() as WmsMapProvider;
  }

  onWmtsSelectedLayerChanged(selectedLayer: LayerFieldOptionWmts) {
    this.isPreviewExpanded = true;
    this.selectedLayer = selectedLayer;
  }

  isWMTSFormInvalid() {
    return (this.isWMTSMapService() && !this.customMapForm.valid) || (this.wmtsFormValue && !this.wmtsFormValue.isFormValid);
  }

  onWmsSelectedLayerChanged(selectedLayer: LayerFieldOptionWms) {
    this.isPreviewExpanded = true;
    this.selectedLayer = selectedLayer;
  }

  onWMSDataChanged(wmsValue) {
    this.wmsFormValue = wmsValue;

    this.addedCustomMap = this.getRequestData() as WmtsMapProvider;
  }

  isWMSFormInvalid() {
    return (this.isWMSMapService() && !this.customMapForm.valid) || (this.wmsFormValue && !this.wmsFormValue.isFormValid);
  }

  isProviderFormValid() {
    if (this.isArcGisMapService()) {
      return !this.isArcGisFormInvalid();
    } else if (this.isWMTSMapService()) {
      return !this.isWMTSFormInvalid();
    } else if (this.isWMSMapService()) {
      return !this.isWMSFormInvalid();
    } else {
      return true;
    }
  }

  isShowMapPreview() {
    return this.selectedLayer && this.customMapForm.valid && this.isProviderFormValid();
  }

  onAddMap() {
    const requestData = this.getRequestData();

    this.customMapsService
      .createNewMapProvider(requestData, requestData.CompanySlug)
      .pipe(
        tap(response => {
          this.customMapsService.setNewMapProvider(response);

          this.snackBar.open(this.translationMap.get('createCustomMap'), 'OK', {duration: 5000});

          this.matDialogRef.close();
        }),
        catchError(error => {
          if (error.status === 400) {
            this.snackBar.open(this.translationMap.get('checkData'), 'OK', {duration: 5000});
          } else {
            this.matDialogRef.close();
            this.snackBar.open(this.translationMap.get('errorOccurred'), 'OK', {duration: 5000});
          }

          return EMPTY;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private setAutoFillValue(): void {
    const nameControl = this.customMapForm.get('Name');
    const identifierControl = this.customMapForm.get('Slug');

    this.autofillFieldService.setAutoFillValue$(nameControl, identifierControl).pipe(takeUntil(this.destroy$)).subscribe();
  }

  private getRequestData(): ArcGisMapProvider | WmtsMapProvider | WmsMapProvider {
    const formValue = this.customMapForm.getRawValue();

    let dataForCreateMapProvider;

    if (this.isArcGisMapService() && this.arcGisFormValue) {
      dataForCreateMapProvider = this.arcGisFormValue.data;
    } else if (this.isWMTSMapService() && this.wmtsFormValue) {
      dataForCreateMapProvider = this.wmtsFormValue.data;
    } else if (this.isWMSMapService() && this.wmsFormValue) {
      dataForCreateMapProvider = this.wmsFormValue.data;
    }

    return {...formValue, ...dataForCreateMapProvider, ...this.permissionsData};
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
