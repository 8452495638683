import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limit',
})
export class LimitPipe implements PipeTransform {
  transform(value: number, limit: number): number {
    const coefficient = 10 ** limit;
    return Math.round(value * coefficient) / coefficient;
  }
}
