import { Injectable } from '@angular/core';
import { ILayer } from '../../../../components/layers/shared/models/layer.interface';
import { Point3DShape } from '../../../point3dshape.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import isNull from 'lodash-es/isNull';
import each from 'lodash-es/each';
import find from 'lodash-es/find';
import isNumber from 'lodash-es/isNumber';
import get from 'lodash-es/get';
import { MeasurementSystem } from '../components/configuration-tab/interfaces/measurement-system.enum';
import { ObjectType } from '../components/configuration-tab/enums/object-types.enum';
import { SourceConfiguration } from '../components/configuration-tab/interfaces/source-configuration';
import { PointSubtype } from '../components/configuration-tab/interfaces/point-subtype';
import { LineSubtype } from '../components/configuration-tab/enums/line-subtypes.enum';
import { ConfigurationTableColumn } from '../components/configuration-tab/interfaces/configuration-table-column';
import { AltitudeMode } from '../components/configuration-tab/enums/altitude-mode.enum';
import { SubtypeConfiguration } from '../components/configuration-tab/interfaces/subtype-configuration';
import { SourceWorkpack } from '../../../models/source-workpack';

@Injectable()
export class SourceConfigurationService {
  /**
   * Measurement systems list
   */
  measurementSystem = [
    {
      id: MeasurementSystem.Millimeters,
      name: 'mm',
    },
    {
      id: MeasurementSystem.Centimeters,
      name: 'cm',
    },
    {
      id: MeasurementSystem.Meters,
      name: 'm',
    },
    {
      id: MeasurementSystem.Inches,
      name: 'in',
    },
    {
      id: MeasurementSystem.Feet,
      name: 'ft',
    },
  ];

  private localIsCheckLocation = new BehaviorSubject<boolean>(false);

  private _pointSubtypes: Array<{ id: number; name: string; systemName: string }> = [
    {
      id: PointSubtype.OBJECT,
      name: 'configurationTab.pointSubtypes.object',
      systemName: 'Object',
    },
    {
      id: PointSubtype.MANHOLE,
      name: 'configurationTab.pointSubtypes.manhole',
      systemName: 'Manhole',
    },
    {
      id: PointSubtype.CUSTOM,
      name: 'configurationTab.pointSubtypes.custom',
      systemName: 'Custom',
    },
  ];

  private _lineSubtypes: Array<{ id: number; name: string; systemName: string }> = [
    {
      id: LineSubtype.PIPE,
      name: 'configurationTab.lineSubtypes.pipe',
      systemName: 'Pipe',
    },
    {
      id: LineSubtype.LINE,
      name: 'configurationTab.lineSubtypes.line',
      systemName: 'Pipe',
    },
  ];

  private _altitudeMode = [
    {
      id: AltitudeMode.RelativeToGround,
      name: 'configurationTab.altitudeMode.surface',
    },
    {
      id: AltitudeMode.ClampToGround,
      name: 'configurationTab.altitudeMode.ingoreZ',
    },
    {
      id: AltitudeMode.Absolute,
      name: 'configurationTab.altitudeMode.seaLevel',
    },
  ];

  pointSubtype = PointSubtype;

  lineSubtype = LineSubtype;

  point3DShape = Point3DShape;

  objectType = ObjectType;

  FLObjectTypeChanged$ = new BehaviorSubject<boolean>(false);

  private _workPacks$ = new BehaviorSubject<SourceWorkpack[]>([]);

  private _currentStateOfRootFLConfig: SourceConfiguration;

  private _newlyCreatedSubtypes: Array<SubtypeConfiguration>;

  private _boundingBoxShape = [
    { id: 0, name: 'Cylinder' },
    { id: 1, name: 'Cube' },
    { id: 2, name: 'Sphere' },
  ];

  private _shape = [
    { id: 0, name: 'Cylinder' },
    { id: 1, name: 'Box' },
  ];

  get workPacks$(): Observable<SourceWorkpack[]> {
    return this._workPacks$.asObservable();
  }

  constructor() {
  }

  setRootFLConfiguration(rootFLConfiguration: SourceConfiguration) {
    this._currentStateOfRootFLConfig = rootFLConfiguration;
  }

  setNewlyCreatedSubtypes(newlyCreatedSubtypes: Array<SubtypeConfiguration>) {
    this._newlyCreatedSubtypes = newlyCreatedSubtypes;
  }

  getNewlyCreatedSubtypes() {
    return this._newlyCreatedSubtypes;
  }

  getObjectTypeName(type: number) {
    switch (type) {
      case ObjectType.UNKNOWN:
        return 'Unknown';
      case ObjectType.POINT:
        return 'Point';
      case ObjectType.LINE:
        return 'Line';
      case ObjectType.AREA:
        return 'Area';
      case ObjectType.MODEL3D:
        return 'Model 3D';
    }
  }

  getObjectSubtypeName(node, key, subtypeParentObjectType?: ObjectType) {
    if (!node.ObjectTypeConfiguration) {
      return '';
    }

    // why not node['ObjectType'] = subtypeParentObjectType
    // because after we defined subtype name we must delete Object Type property
    // from subtype and that is a lot of unnecessary code too because we have
    // switch and a lot of return

    if (subtypeParentObjectType === undefined && node.ObjectType === undefined) {
      return;
    }

    if (subtypeParentObjectType === ObjectType.LINE || node.ObjectType === ObjectType.LINE) {
      switch (key) {
        case LineSubtype.PIPE:
          return 'Pipe';
        case LineSubtype.LINE:
          return 'Line';
      }
    }

    if (subtypeParentObjectType === ObjectType.POINT || node.ObjectType === ObjectType.POINT) {
      switch (key) {
        case PointSubtype.UNKNOWN:
          return 'Unknown';
        case PointSubtype.OBJECT:
          return 'Object';
        case PointSubtype.MANHOLE:
          return 'Manhole/Inlet';
        case PointSubtype.CUSTOM:
          return 'Custom';
      }
    }
  }

  getAltitudeMode(mode) {
    switch (mode) {
      case AltitudeMode.RelativeToGround:
        return 'Surface offset';
      case AltitudeMode.ClampToGround:
        return 'Ignore z-value';
      case AltitudeMode.Absolute:
        return 'Default';
    }
  }

  getCheckLocationState$(): Observable<boolean> {
    return this.localIsCheckLocation.asObservable();
  }

  setCheckLocationState(isCheckLocation: boolean): void {
    this.localIsCheckLocation.next(isCheckLocation);
  }

  objectTypeIs(parentObjectType, objectType): boolean {
    return parentObjectType === objectType;
  }

  objectSubtypeIs(node, objectSubtype): boolean {
    return node.ObjectTypeConfiguration && node.ObjectTypeConfiguration.Subtype === objectSubtype;
  }

  getMeasurementSystemName(id: number) {
    if (!isNumber(id)) {
      return '-';
    }

    const mSystem = this.measurementSystem.find(value => {
      return value.id === id;
    });

    return mSystem ? mSystem.name : '';
  }

  compare(a, b, isAsc) {
    if (a === '-' || a === 'N/A') {
      return -1 * (isAsc ? 1 : -1);
    }
    if (b === '-' || b === 'N/A') {
      return isAsc ? 1 : -1;
    }

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /*General find layer function that recursively goes for their child layers*/

  findLayer(items: Array<SourceConfiguration>, nodeId: string) {
    if (!items) {
      return;
    }

    for (const item of items) {
      // Test current object

      if (!item) {
        return;
      }

      if (item.Id === nodeId) {
        return item;
      }

      // Test children recursively

      const child = this.findLayer(item.ChildLayers, nodeId);
      if (child) {
        return child;
      }
    }
  }

  makeLine(data: SourceConfiguration, level: number, tableRows, inspectionForms, layers: ILayer[]) {
    const node = {
      Id: data.Id,
      parentId: data.parentId || null,
      LayerName: data.isSubtype ? data.SubtypeValue : data.LayerName,
      DisplayName: data.DisplayName,
      node: data,
      level: level,
      isRoot: data.isRoot || false,
      isSubtype: data.isSubtype || false,
      isBranch: data.isBranch || false,
      isExcludedFromProcessing: data.IsExcludedFromProcessing || false,
      isLeaf: data.isLeaf || false,
      hasChildren: data.hasChildren || false,
      changed: data.changed,
      expanded: data.expanded,
    };

    each(tableRows, (col: ConfigurationTableColumn) => {
      const isUoM = col.key.split('.').indexOf('UoM') !== -1;

      let def: any = '-';

      if (col.type && col.type === 'boolean') {
        def = false;
      }

      if (col.type && col.type === 'statistic') {
        def = 'N/A';
      }

      let value = get(data, col.field, def);

      if (col.key === 'TotalCount' || col.key === 'ProcessedCount') {
        value = isNull(value) ? 'N/A' : value;
      }

      if (col.key === 'ObjectType' && isNumber(value)) {
        value = this.getObjectTypeName(value);
      }

      if (col.key === 'Subtype' && isNumber(value)) {
        // define object subtype value for sublayer (subtype)

        if (data.isSubtype) {
          const parentFeatureLayer = this.findLayer(this._currentStateOfRootFLConfig.ChildLayers, data.parentId);
          value = this.getObjectSubtypeName(data, value, parentFeatureLayer.ObjectType);
          node[col.key] = value;
          return;
        }

        value = this.getObjectSubtypeName(data, value);
      }

      if (col.key === 'AltitudeMode' && isNumber(value)) {
        value = this.getAltitudeMode(value);
      }

      if (isUoM && isNumber(value)) {
        value = this.getMeasurementSystemName(value);
      }

      if (col.key === 'ProcessedPercent') {
        const tooltip = [];

        const results = get(data, 'statistic.ProcessingResultsNumbers', null);

        each(results, (val, key) => {
          tooltip.push({
            key: key,
            value: val,
          });
        });

        node[col.key + '-tooltip'] = tooltip;
      }

      if (
        col.key === 'POINT_MANHOLE_ObjectMesh' &&
        !(this.objectTypeIs(data, ObjectType.POINT) && this.objectSubtypeIs(data, PointSubtype.MANHOLE))
      ) {
        value = '-';
      }

      if (
        col.key === 'POINT_OBJECT_ObjectMesh' &&
        !(this.objectTypeIs(data, ObjectType.POINT) && this.objectSubtypeIs(data, PointSubtype.OBJECT))
      ) {
        value = '-';
      }

      if (col.key === 'InspectionForms') {
        const tooltip = [];
        each(value, formId => {
          const form = find(inspectionForms, { Id: formId });

          if (form) {
            tooltip.push(form.Name);
          }
        });

        value = value && value.length > 0 ? value.length + ' forms' : '-';

        node[col.key + '-tooltip'] = tooltip;
      }

      if (col.key === 'Layer' && layers.length) {
        const layer = find(layers, { Id: value });
        value = layer ? layer.Name : value;
      }

      if (col.key === 'POINT_OBJECT_ObjectTypeConfiguration.BoundingBoxShape') {
        const shape: any = find(this._boundingBoxShape, { id: value });
        value = shape ? shape.name : value;
      }

      if (col.key === 'POINT_MANHOLE_Shape') {
        const shape: any = find(this._shape, { id: value });
        value = shape ? shape.name : value;
      }

      if (node.isSubtype && col.key === 'SubtypesConfigurations.Title') {
        value = node.node.TitleField;
        node[col.key] = value;
      }

      node[col.key] = isNull(value) ? '-' : value;
    });

    return node;
  }

  setWorkPacks(workPacks: SourceWorkpack[]): void {
    this._workPacks$.next(workPacks);
  }

  // is feature layer object type changed

  isFLObjectTypeChanged(isChanged) {
    this.FLObjectTypeChanged$.next(isChanged);
  }

  isShowLabelType(nodeObjectType: number): boolean {
    return (
      this.objectTypeIs(nodeObjectType, this.objectType.AREA) ||
      this.objectTypeIs(nodeObjectType, this.objectType.POINT) ||
      this.objectTypeIs(nodeObjectType, this.objectType.LINE)
    );
  }

  isObjectTypeLine(objectType: number) {
    return objectType === this.objectType.LINE;
  }

  isObjectSubtypePipe(objectConfigSubtype: number) {
    return objectConfigSubtype === this.lineSubtype.PIPE;
  }

  isObjectSubtypeLine(objectConfigSubtype: number) {
    return objectConfigSubtype === this.lineSubtype.LINE;
  }

  isObjectTypeArea(objectConfigSubtype: number) {
    return objectConfigSubtype === this.objectType.AREA;
  }

  isObjectTypePoint(objectConfigSubtype: number) {
    return objectConfigSubtype === this.objectType.POINT;
  }

  isObjectSubtypeCustom(objectConfigSubtype: number) {
    return objectConfigSubtype === this.pointSubtype.CUSTOM;
  }

  isObjectSubtypeManhole(objectConfigSubtype: number) {
    return objectConfigSubtype === this.pointSubtype.MANHOLE;
  }

  isObjectSubtypeObject(objectConfigSubtype: number) {
    return objectConfigSubtype === this.pointSubtype.OBJECT;
  }

  getPoint3DShapesArray() {
    const keys = Object.keys(Point3DShape);
    return keys.filter(item => isNaN(parseInt(item, 10)));
  }

  getPointSubtypes() {
    return this._pointSubtypes;
  }

  getLineSubtypes() {
    return this._lineSubtypes;
  }

  getAltitudeModeData() {
    return this._altitudeMode;
  }
}
