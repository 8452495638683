import { Injectable } from '@angular/core';
import { EMPTY, fromEvent, Observable, Subject } from 'rxjs';
import { WorkerMessage } from '../../../worker/app-workers/shared/worker-message.model';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class WorkerService {

  public readonly workerPath = 'assets/workers/main.js';

  workerUpdate$: Observable<WorkerMessage>;

  private worker: Worker;

  private workerSubject: Subject<WorkerMessage>;

  constructor() {
    this.workerInit();
  }

  doWork(workerMessage: WorkerMessage) {
    if (this.worker) {
      this.worker.postMessage(workerMessage);
    }
  }

  workerInit(): void {
    if (this.worker === undefined) {
      this.worker = new Worker(this.workerPath);
      this.workerSubject = new Subject<WorkerMessage>();
      this.workerUpdate$ = this.workerSubject.asObservable();

      fromEvent(this.worker, 'message')
        .pipe(
          tap((response: MessageEvent) => {
            this.workerSubject.next(WorkerMessage.getInstance(response.data));
          }),
          catchError(error => {
            console.log(error);
            return EMPTY;
          }),
        ).subscribe();
    }
  }
}
