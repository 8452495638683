import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { LabelConfiguration } from '../../models/label-configuration';
import { LabelMarker } from '../../models/label-marker.enum';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { map } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mee-label-configuration',
  templateUrl: './label-configuration.component.html',
  styleUrls: ['./label-configuration.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LabelConfigurationComponent),
      multi: true,
    },
  ],
})

export class LabelConfigurationComponent implements ControlValueAccessor, OnInit {

  @ViewChild('container', { static: true }) container;

  isOpen = false;
  isDoubleClick = false;
  labelMarker = LabelMarker;
  @Output() outLabelConfig = new EventEmitter<LabelConfiguration>();
  @Input() inLabelConfig: LabelConfiguration;

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  getDefaultConfig() {
    return {
      LabelMarker: LabelMarker.None,
      ShowRiser: false,
      ShowText: false,
    };
  }

  ngOnInit() {
    this.inLabelConfig = this.inLabelConfig || this.getDefaultConfig();
    fromEvent<MouseEvent>(document, 'click').pipe(
      map(event => event.target),
      map(target => {
        if (this.isOpen) {
          this.isDoubleClick = true;
          return this.container.nativeElement.contains(target);
        }
        if (!this.isOpen && this.isDoubleClick) {
          this.isDoubleClick = false;
        }
        return false;
      }),
    ).subscribe(isClickedOutside => this.isOpen = isClickedOutside);
  }

  changeResult({ checked }: MatCheckboxChange, text: string) {
    this.inLabelConfig[`Show${text}`] = checked;

    this.onChange(this.inLabelConfig);
  }

  changeRadioButtonsGroupValue({ value }: MatRadioChange) {
    this.inLabelConfig.LabelMarker = value as number;

    this.onChange(this.inLabelConfig);
  }

  getResultText() {
    if (!this.inLabelConfig) {
      return '';
    }
    return Object.keys(this.inLabelConfig)
      .map(item => {
        if (item === 'ShowText' && this.inLabelConfig[item]) {
          return 'Text';
        } else if (item === 'ShowRiser' && this.inLabelConfig[item]) {
          return 'Riser';
        } else if (item === 'LabelMarker') {
          return this.labelMarker[this.inLabelConfig[item]];
        }
      })
      .filter(item => item)
      .join('+');
  }

  onCloseLabelConfig() {

    /*meeClickOutside directive triggers first time when object is not even open once*/

    if (this.isOpen) {
      this.outLabelConfig.emit(this.inLabelConfig);
      this.isOpen = false;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(data: LabelConfiguration): void {
    this.inLabelConfig = data || this.getDefaultConfig();
  }
}
