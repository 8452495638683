// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-picker-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
}
.color-picker-wrapper .color-picker {
  width: 240px;
  height: 40px;
  border-radius: 7px;
  display: flex;
  cursor: pointer;
  position: relative;
}
.color-picker-wrapper .color-picker .text-color {
  align-self: center;
  margin-left: 16px;
  font-family: Roboto Condensed, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
}
.color-picker-wrapper .select-color {
  border: 1px solid grey !important;
}
.color-picker-wrapper mat-icon {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/sources/components/source-configuration-list/components/configuration-tab/color-picker/color-picker.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,QAAA;AACF;AACE;EACE,YAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,eAAA;EACA,kBAAA;AACJ;AACI;EACE,kBAAA;EACA,iBAAA;EACA,yCAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;AACN;AAGE;EACE,iCAAA;AADJ;AAIE;EACE,eAAA;AAFJ","sourcesContent":[".color-picker-wrapper {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-items: center;\n  gap: 8px;\n\n  .color-picker {\n    width: 240px;\n    height: 40px;\n    border-radius: 7px;\n    display: flex;\n    cursor: pointer;\n    position: relative;\n\n    .text-color {\n      align-self: center;\n      margin-left: 16px;\n      font-family: Roboto Condensed, sans-serif;\n      font-weight: bold;\n      font-size: 16px;\n      line-height: 18px;\n    }\n  }\n\n  .select-color {\n    border: 1px solid grey !important;\n  }\n\n  mat-icon {\n    cursor: pointer;\n  }\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
