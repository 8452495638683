import { ConfigurationTableTitleGroups } from '../components/configuration-tab/enums/configuration-table-title-groups.enum';
import { ConfigurationTableColumn } from '../components/configuration-tab/interfaces/configuration-table-column';

export const SOURCE_CONFIG_TABLE_ROWS: ConfigurationTableColumn[] = [
  // {
  //   title: '',
  //   key: 'IsFavorite',
  //   field: 'IsFavorite',
  //   group: ConfigurationTableTitleGroups.GLOBAL,
  // },
  {
    title: 'configurations-fields.columns.layers',
    key: 'Layer',
    field: 'Layer',
    group: ConfigurationTableTitleGroups.GLOBAL,
  },
  {
    title: 'configurations-fields.columns.primaryColor',
    key: 'MainColor',
    field: 'MainColor',
    group: ConfigurationTableTitleGroups.GLOBAL,
  },
  {
    title: 'configurations-fields.columns.header',
    key: 'Title',
    field: 'Title',
    group: ConfigurationTableTitleGroups.GLOBAL,
  },
  {
    title: 'configurations-fields.columns.elevationType',
    key: 'AltitudeMode',
    field: 'AltitudeMode',
    group: ConfigurationTableTitleGroups.GLOBAL,
  },
  {
    title: 'configurations-fields.columns.type',
    key: 'ObjectType',
    field: 'ObjectType',
    group: ConfigurationTableTitleGroups.GLOBAL,
  },
  {
    title: 'configurations-fields.columns.exclude',
    key: 'IsExcludedFromProcessing',
    field: 'IsExcludedFromProcessing',
    group: ConfigurationTableTitleGroups.GLOBAL,
    type: 'boolean',
  },
  {
    title: '%',
    key: 'ProcessedPercent',
    field: 'statistic.ProcessedPercent',
    group: ConfigurationTableTitleGroups.GLOBAL,
    type: 'statistic',
  },
  {
    title: 'configurations-fields.columns.process',
    key: 'ProcessedCount',
    field: 'statistic.ProcessedPlacemarksNumber',
    group: ConfigurationTableTitleGroups.GLOBAL,
    type: 'statistic',
  },
  {
    title: 'configurations-fields.columns.total',
    key: 'TotalCount',
    field: 'Statistics.TotalCount',
    group: ConfigurationTableTitleGroups.GLOBAL,
    type: 'statistic',
  },
  // {
  //   title: 'configurations-fields.columns.subHeader',
  //   key: 'SubtypesConfigurations.Title',
  //   field: 'SubtypesConfigurations.Title',
  //   group: ConfigurationTableTitleGroups.GLOBAL,
  // },
  // {
  //   title: 'configurations-fields.columns.subtypeField',
  //   key: 'SubtypeField',
  //   field: 'SubtypeField',
  //   group: ConfigurationTableTitleGroups.GLOBAL,
  // },
  // {
  //   title: 'configurations-fields.columns.externalId',
  //   key: 'ExternalIdField',
  //   field: 'ExternalIdField',
  //   group: ConfigurationTableTitleGroups.GLOBAL,
  // },
  // {
  //   title: 'configurations-fields.columns.calibrationScreen',
  //   key: 'ObjectTypeConfiguration.DisplayAtCalibration',
  //   field: 'ObjectTypeConfiguration.DisplayAtCalibration',
  //   group: ConfigurationTableTitleGroups.GLOBAL,
  //   type: 'boolean',
  // },
  // {
  //   title: 'configurations-fields.columns.defaultDepth',
  //   key: 'DefaultDepth.Value',
  //   field: 'DefaultDepth.Value',
  //   group: ConfigurationTableTitleGroups.GLOBAL,
  //   className: 'taRight',
  // },
  // {
  //   title: 'configurations-fields.columns.uom',
  //   key: 'DefaultDepth.UoM',
  //   field: 'DefaultDepth.Type',
  //   group: ConfigurationTableTitleGroups.GLOBAL,
  //   className: 'taLeft',
  // },
  // {
  //   title: 'configurations-fields.columns.depthField',
  //   key: 'DepthField.FieldName',
  //   field: 'DepthField.FieldName',
  //   group: ConfigurationTableTitleGroups.GLOBAL,
  //   className: 'taRight',
  // },
  // {
  //   title: 'configurations-fields.columns.uom',
  //   key: 'DepthField.UoM',
  //   field: 'DepthField.Type',
  //   group: ConfigurationTableTitleGroups.GLOBAL,
  //   className: 'taLeft',
  // },
  // {
  //   title: 'configurations-fields.columns.invert',
  //   key: 'DepthField.InvertValue',
  //   field: 'DepthField.InvertValue',
  //   group: ConfigurationTableTitleGroups.GLOBAL,
  //   type: 'boolean',
  // },


  // {
  //   title: 'configurations-fields.columns.defaultDiameter',
  //   key: 'LINE_PIPE_DefaultDiameter.Value',
  //   field: 'DefaultDiameter.Value',
  //   group: ConfigurationTableTitleGroups.LINE_PIPE,
  //   className: 'taRight',
  // },
  // {
  //   title: 'configurations-fields.columns.uom',
  //   key: 'LINE_PIPE_DefaultDiameter.UoM',
  //   field: 'DefaultDiameter.Type',
  //   group: ConfigurationTableTitleGroups.LINE_PIPE,
  //   className: 'taLeft',
  // },
  // {
  //   title: 'configurations-fields.columns.diameterField',
  //   key: 'LINE_PIPE_DiameterField.Value',
  //   field: 'DiameterField.Value',
  //   group: ConfigurationTableTitleGroups.LINE_PIPE,
  //   className: 'taRight',
  // },
  // {
  //   title: 'configurations-fields.columns.uom',
  //   key: 'LINE_PIPE_DiameterField.UoM',
  //   field: 'DiameterField.Type',
  //   group: ConfigurationTableTitleGroups.LINE_PIPE,
  //   className: 'taLeft',
  // },
  // {
  //   title: 'configurations-fields.columns.invert',
  //   key: 'LINE_PIPE_DiameterField.InvertValue',
  //   field: 'DiameterField.InvertValue',
  //   group: ConfigurationTableTitleGroups.LINE_PIPE,
  //   type: 'boolean',
  // },
  //
  //
  // {
  //   title: 'configurations-fields.columns.defaultHeight',
  //   key: 'LINE_LINE_DefaultHeight.Value',
  //   field: 'ObjectTypeConfiguration.DefaultHeight.Value',
  //   group: ConfigurationTableTitleGroups.LINE_LINE,
  //   className: 'taRight',
  // },
  // {
  //   title: 'configurations-fields.columns.uom',
  //   key: 'LINE_LINE_DefaultHeight.UoM',
  //   field: 'ObjectTypeConfiguration.DefaultHeight.Type',
  //   group: ConfigurationTableTitleGroups.LINE_LINE,
  //   className: 'taLeft',
  // },
  //
  // {
  //   title: 'configurations-fields.columns.heightField',
  //   key: 'LINE_LINE_HeightField.Value',
  //   field: 'ObjectTypeConfiguration.HeightField.Value',
  //   group: ConfigurationTableTitleGroups.LINE_LINE,
  //   className: 'taRight',
  // },
  // {
  //   title: 'configurations-fields.columns.uom',
  //   key: 'LINE_LINE_HeightField.UoM',
  //   field: 'ObjectTypeConfiguration.HeightField.Type',
  //   group: ConfigurationTableTitleGroups.LINE_LINE,
  //   className: 'taLeft',
  // },
  // {
  //   title: 'configurations-fields.columns.invert',
  //   key: 'LINE_LINE_HeightField.InvertValue',
  //   field: 'ObjectTypeConfiguration.HeightField.InvertValue',
  //   group: ConfigurationTableTitleGroups.LINE_LINE,
  //   type: 'boolean',
  // },
  //
  // {
  //   title: 'configurations-fields.columns.defaultWidth',
  //   key: 'LINE_LINE_DefaultWidth.Value',
  //   field: 'ObjectTypeConfiguration.DefaultWidth.Value',
  //   group: ConfigurationTableTitleGroups.LINE_LINE,
  //   className: 'taRight',
  // },
  // {
  //   title: 'configurations-fields.columns.uom',
  //   key: 'LINE_LINE_DefaultWidth.UoM',
  //   field: 'ObjectTypeConfiguration.DefaultWidth.Type',
  //   group: ConfigurationTableTitleGroups.LINE_LINE,
  //   className: 'taLeft',
  // },
  //
  // {
  //   title: 'configurations-fields.columns.widthField',
  //   key: 'LINE_LINE_WidthField.Value',
  //   field: 'ObjectTypeConfiguration.WidthField.Value',
  //   group: ConfigurationTableTitleGroups.LINE_LINE,
  //   className: 'taRight',
  // },
  // {
  //   title: 'configurations-fields.columns.uom',
  //   key: 'LINE_LINE_WidthField.UoM',
  //   field: 'ObjectTypeConfiguration.WidthField.Value',
  //   group: ConfigurationTableTitleGroups.LINE_LINE,
  //   className: 'taLeft',
  // },
  // {
  //   title: 'configurations-fields.columns.invert',
  //   key: 'LINE_LINE_WidthField.InvertValue',
  //   field: 'ObjectTypeConfiguration.WidthField.InvertValue',
  //   group: ConfigurationTableTitleGroups.LINE_LINE,
  //   type: 'boolean',
  // },
  //
  // {
  //   title: 'configurations-fields.columns.shape',
  //   key: 'POINT_MANHOLE_Shape',
  //   field: 'ObjectTypeConfiguration.Shape',
  //   group: ConfigurationTableTitleGroups.POINT_MANHOLE,
  // },
  // {
  //   title: 'configurations-fields.columns.defaultDiameter',
  //   key: 'POINT_MANHOLE_DefaultDiameter.Value',
  //   field: 'DefaultDiameter.Value',
  //   group: ConfigurationTableTitleGroups.POINT_MANHOLE,
  //   className: 'taRight',
  // },
  // {
  //   title: 'configurations-fields.columns.uom',
  //   key: 'POINT_MANHOLE_DefaultDiameter.UoM',
  //   field: 'DefaultDiameter.Type',
  //   group: ConfigurationTableTitleGroups.POINT_MANHOLE,
  //   className: 'taLeft',
  // },
  // {
  //   title: 'configurations-fields.columns.diameterField',
  //   key: 'POINT_MANHOLE_DiameterField.Value',
  //   field: 'DiameterField.Value',
  //   group: ConfigurationTableTitleGroups.POINT_MANHOLE,
  //   className: 'taRight',
  // },
  // {
  //   title: 'configurations-fields.columns.uom',
  //   key: 'POINT_MANHOLE_DiameterField.UoM',
  //   field: 'DiameterField.Type',
  //   group: ConfigurationTableTitleGroups.POINT_MANHOLE,
  //   className: 'taLeft',
  // },
  // {
  //   title: 'configurations-fields.columns.invert',
  //   key: 'POINT_MANHOLE_DiameterField.InvertValue',
  //   field: 'DiameterField.InvertValue',
  //   group: ConfigurationTableTitleGroups.POINT_MANHOLE,
  //   type: 'boolean',
  // },
  // {
  //   title: 'configurations-fields.columns.disableTexture',
  //   key: 'POINT_MANHOLE_DisableMaterial',
  //   field: 'ObjectTypeConfiguration.DisableMaterial',
  //   group: ConfigurationTableTitleGroups.POINT_MANHOLE,
  //   type: 'boolean',
  // },
  // {
  //   title: 'configurations-fields.columns.objectMesh',
  //   key: 'POINT_MANHOLE_ObjectMesh',
  //   field: 'ObjectTypeConfiguration.ObjectMesh',
  //   group: ConfigurationTableTitleGroups.POINT_MANHOLE,
  // },
  //
  //
  // {
  //   title: 'configurations-fields.columns.shape',
  //   key: 'POINT_OBJECT_Shape',
  //   field: 'ObjectTypeConfiguration.BoundingBoxShape',
  //   group: ConfigurationTableTitleGroups.POINT_OBJECT,
  // },
  // {
  //   title: 'configurations-fields.columns.boundingBoxHeight',
  //   key: 'POINT_OBJECT_ObjectTypeConfiguration.BoundingBoxHeight.Value',
  //   field: 'ObjectTypeConfiguration.BoundingBoxHeight.Value',
  //   group: ConfigurationTableTitleGroups.POINT_OBJECT,
  //   className: 'taRight',
  // },
  // {
  //   title: 'configurations-fields.columns.uom',
  //   key: 'POINT_OBJECT_ObjectTypeConfiguration.BoundingBoxHeight.Type',
  //   field: 'ObjectTypeConfiguration.BoundingBoxHeight.Type',
  //   group: ConfigurationTableTitleGroups.POINT_OBJECT,
  //   className: 'taLeft',
  // },
  // {
  //   title: 'configurations-fields.columns.boundingBoxHeightField',
  //   key: 'POINT_OBJECT_ObjectTypeConfiguration.BoundingBoxHeightField.Value',
  //   field: 'ObjectTypeConfiguration.BoundingBoxHeightField.FieldName',
  //   group: ConfigurationTableTitleGroups.POINT_OBJECT,
  //   className: 'taRight',
  // },
  // {
  //   title: 'configurations-fields.columns.uom',
  //   key: 'POINT_OBJECT_ObjectTypeConfiguration.BoundingBoxHeightField.Type',
  //   field: 'ObjectTypeConfiguration.BoundingBoxHeightField.Type',
  //   group: ConfigurationTableTitleGroups.POINT_OBJECT,
  //   className: 'taLeft',
  // },
  // {
  //   title: 'configurations-fields.columns.boundingBoxWidth',
  //   key: 'POINT_OBJECT_ObjectTypeConfiguration.BoundingBoxWidth.Value',
  //   field: 'ObjectTypeConfiguration.BoundingBoxWidth.Value',
  //   group: ConfigurationTableTitleGroups.POINT_OBJECT,
  //   className: 'taRight',
  // },
  // {
  //   title: 'configurations-fields.columns.uom',
  //   key: 'POINT_OBJECT_ObjectTypeConfiguration.BoundingBoxWidth.Type',
  //   field: 'ObjectTypeConfiguration.BoundingBoxWidth.Type',
  //   group: ConfigurationTableTitleGroups.POINT_OBJECT,
  //   className: 'taLeft',
  // },
  // {
  //   title: 'configurations-fields.columns.boundingBoxWidthField',
  //   key: 'POINT_OBJECT_ObjectTypeConfiguration.BoundingBoxWidthField.Value',
  //   field: 'ObjectTypeConfiguration.BoundingBoxWidthField.FieldName',
  //   group: ConfigurationTableTitleGroups.POINT_OBJECT,
  //   className: 'taRight',
  // },
  // {
  //   title: 'configurations-fields.columns.uom',
  //   key: 'POINT_OBJECT_ObjectTypeConfiguration.BoundingBoxWidthField.Type',
  //   field: 'ObjectTypeConfiguration.BoundingBoxWidthField.Type',
  //   group: ConfigurationTableTitleGroups.POINT_OBJECT,
  //   className: 'taLeft',
  // },
  // {
  //   title: 'configurations-fields.columns.undergroundConnector',
  //   key: 'POINT_OBJECT_ObjectTypeConfiguration.UndergroundConnectorDepth.Value',
  //   field: 'ObjectTypeConfiguration.UndergroundConnectorDepth.Value',
  //   group: ConfigurationTableTitleGroups.POINT_OBJECT,
  //   className: 'taRight',
  // },
  // {
  //   title: 'configurations-fields.columns.showBoundingBox',
  //   key: 'POINT_OBJECT_ObjectTypeConfiguration.ShowBoundingBox',
  //   field: 'ObjectTypeConfiguration.ShowBoundingBox',
  //   group: ConfigurationTableTitleGroups.POINT_OBJECT,
  //   type: 'boolean',
  // },
];
