import { Injectable } from '@angular/core';
import { DataSourceType } from '../../../../data-source-type.enum';
import { BasicSecurityTypes } from './enums/basic-security-types.enum';
import { SecurityType } from './interfaces/security-type';
import { ArcGisSecurityTypes } from '../../../../arcgis-security-types.enum';
import { ConfigurationHint } from '../../models/configuration-hint';
import { CONFIGURATION_HINTS_DATA_MODEL } from '../../models/configuration-hints-data.model';
import { ConfigurationHintData } from '../../models/configuration-hint-data';
import { CopyConfigurationSourceType } from '../copy-config-modal/models/copy-configuration-source-type';

@Injectable({
  providedIn: 'root',
})
export class DataSourcesAdapterService {
  private readonly listOfExistsDataSourcesTypesForCopy: DataSourceType[] = [
    DataSourceType.Kml,
    DataSourceType.ArcGisFeatureService,
    DataSourceType.Virginia811,
    DataSourceType.Wfs,
    DataSourceType.NativeGis,
    DataSourceType.Shapefile,
    DataSourceType.IModelGis,
    DataSourceType.Bim360,
    DataSourceType.NativeBim,
  ];


  getSourceTitle(sourceType: DataSourceType): string {
    let sourceTitle: string;

    switch (sourceType) {
      case DataSourceType.Kml:
        sourceTitle = 'KML';
        break;
      case DataSourceType.ArcGisFeatureService:
        sourceTitle = 'ArcGIS Feature Service';
        break;
      case DataSourceType.ArcGisSceneService:
        sourceTitle = 'ArcGIS Scene Service';
        break;
      case DataSourceType.IModel:
        sourceTitle = 'Bentley iTwin (3D Objects)';
        break;
      case DataSourceType.IModelGis:
        sourceTitle = 'Bentley iTwin (Vector Lines)';
        break;
      case DataSourceType.Wfs:
        sourceTitle = 'WFS';
        break;
      case DataSourceType.Bim360:
        sourceTitle = 'Autodesk Build (BIM360)';
        break;
      case DataSourceType.NativeBim:
        sourceTitle = 'DWG-DXF-IFC-RVT (3D Objects)';
        break;
      case DataSourceType.NativeGis:
        sourceTitle = 'DWG-DXF (Vector Lines)';
        break;
      case DataSourceType.Shapefile:
        sourceTitle = 'Shapefile';
        break;
      case DataSourceType.Pix4d:
        sourceTitle = 'Pix4D Cloud';
        break;
      case DataSourceType.RealityCapture:
        sourceTitle = 'Reality Capture';
        break;
      case DataSourceType.LandXml:
        sourceTitle = 'LandXML';
        break;
      case DataSourceType.Simple3dModel:
        sourceTitle = 'Simple  Model';
        break;
    }

    return sourceTitle;
  }

  isDataSourceExistForCopy(type: DataSourceType): boolean {
    return this.listOfExistsDataSourcesTypesForCopy.includes(type);
  }

  getBasicSecurityTypes(): SecurityType<BasicSecurityTypes>[] {
    return [
      {
        id: BasicSecurityTypes.Public,
        translationKey: 'sources.details.Public',
      },
      {
        id: BasicSecurityTypes.Password,
        translationKey: 'sources.details.Password',
      },
    ];
  }

  getArcgisSecurityTypes(): SecurityType<ArcGisSecurityTypes>[] {
    return [
      {
        id: ArcGisSecurityTypes.Public,
        translationKey: 'sources.details.Public',
      },
      {
        id: ArcGisSecurityTypes.Password,
        translationKey: 'sources.details.Password',
      },
      {
        id: ArcGisSecurityTypes.Token,
        translationKey: 'sources.details.Token',
      },
      {
        id: ArcGisSecurityTypes.Ntlm,
        translationKey: 'sources.details.Ntlm',
      },
      {
        id: ArcGisSecurityTypes.VGisAccount,
        translationKey: 'sources.details.VGisAccount',
      },
    ];
  }

  getAdaptedConfigurationHints(configurationHints: ConfigurationHint[]): ConfigurationHintData[] {
    return CONFIGURATION_HINTS_DATA_MODEL.reduce((filteredData, hintData) => {
      const configurationServerDataHint = configurationHints.find(serverDataHint => {
        return serverDataHint.Id === hintData.Id;
      });

      if (configurationServerDataHint !== undefined) {
        const configurationData = {
          ...hintData,
          Passed: configurationServerDataHint.Passed,
          LabelKey: configurationServerDataHint.Passed ? hintData.LabelKeyPositive : hintData.LabelKeyNegative,
        };

        filteredData.push({ ...configurationData });
      }

      return filteredData;
    }, []);
  }

  getCopyConfigDataSourceType(sourceType: DataSourceType): CopyConfigurationSourceType {
    if (!this.isDataSourceExistForCopy(sourceType)) {
      return;
    }

    if (this.isBim360Type(sourceType) || this.isNativeBimType(sourceType)) {
      return CopyConfigurationSourceType.Bim;
    }

    return CopyConfigurationSourceType.Gis;
  }

  isKmlType(sourceType: string) {
    return sourceType === DataSourceType.Kml;
  }

  isArcGisFeatureType(sourceType: string) {
    return sourceType === DataSourceType.ArcGisFeatureService;
  }

  isIModelType(sourceType: string) {
    return sourceType === DataSourceType.IModel;
  }

  isIModelGisDataType(sourceType: DataSourceType) {
    return sourceType === DataSourceType.IModelGis;
  }

  isWFSType(sourceType: DataSourceType) {
    return sourceType === DataSourceType.Wfs;
  }

  isArcGisSceneType(sourceType: DataSourceType) {
    return sourceType === DataSourceType.ArcGisSceneService;
  }

  isBim360Type(sourceType: DataSourceType) {
    return sourceType === DataSourceType.Bim360;
  }

  isPix4dType(sourceType: DataSourceType) {
    return sourceType === DataSourceType.Pix4d;
  }

  isNativeBimType(sourceType: DataSourceType) {
    return sourceType === DataSourceType.NativeBim;
  }

  isLandXmlType(sourceType: DataSourceType) {
    return sourceType === DataSourceType.LandXml;
  }

  isNativeGisType(sourceType: DataSourceType) {
    return sourceType === DataSourceType.NativeGis;
  }

  isShapefileType(sourceType: DataSourceType) {
    return sourceType === DataSourceType.Shapefile;
  }

  isSimpleModelType(sourceType: DataSourceType) {
    return sourceType === DataSourceType.Simple3dModel;
  }
}
