// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .mat-dialog-actions {
  justify-content: flex-end;
}
:host .title {
  font-weight: bold !important;
  font-size: 20px;
  line-height: 32px;
}
:host .subtitle {
  font-size: 12px;
  line-height: 16px;
  color: rgba(13, 22, 33, 0.6);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/confirmation-dialog/confirmation-dialog.component.scss"],"names":[],"mappings":"AAEI;EACE,yBAAA;AADN;AAKE;EACE,4BAAA;EACA,eAAA;EACA,iBAAA;AAHJ;AAME;EACE,eAAA;EACA,iBAAA;EACA,4BAAA;AAJJ","sourcesContent":[":host {\n  ::ng-deep {\n    .mat-dialog-actions {\n      justify-content: flex-end;\n    }\n  }\n\n  .title {\n    font-weight: bold !important;\n    font-size: 20px;\n    line-height: 32px;\n  }\n\n  .subtitle {\n    font-size: 12px;\n    line-height: 16px;\n    color: rgba(13, 22, 33, 0.6);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
