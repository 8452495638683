import { AfterViewInit, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TeamsService } from '../../../modules/teams/services/teams.service';
import { IdentityService } from '../../../services/identity.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TeamInterface } from '../../../modules/teams/models/team.interface';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { TeamRoleFields } from '../../../interfaces/team-role-fields';

@Component({
  selector: 'mee-teams-multiple-selector',
  templateUrl: './teams-multiple-selector.component.html',
  styleUrls: ['./teams-multiple-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TeamsMultipleSelectorComponent),
    },
  ],
})
export class TeamsMultipleSelectorComponent implements ControlValueAccessor, AfterViewInit {
  teamsControl = new UntypedFormControl();
  teams$: Observable<TeamInterface[]>;

  @Input() isAllTeamProject: boolean;

  @Input() set companySlug(slug: string) {
    if (!this.isAllTeamProject || !this.identityService.isSuperAdmin()) {
      return;
    }

    this.getTeamsByCompany(slug);
  }

  onChange: any = () => {
  };

  compareFn = (compareRoleFields: TeamRoleFields, userRoleFields: TeamRoleFields): boolean => {
    return compareRoleFields && userRoleFields && compareRoleFields.TeamSlug === userRoleFields.TeamSlug;
  }

  constructor(private teamService: TeamsService, private identityService: IdentityService) {
    if (this.identityService.isTeamAdmin()) {
      this.setTeamForTeamAdmin();
      this.teamsControl.disable();
    }
  }

  ngAfterViewInit(): void {
    if (!this.isAllTeamProject && !this.identityService.isTeamAdmin()) {
      this.getTeamsByCompany(this.identityService.companySlug);
    }
  }

  onTeamSelect(matSelectChange: MatSelectChange): void {
    const teams = matSelectChange.value as TeamRoleFields[];
    this.onChange(teams);
  }

  transformTeam(team: TeamInterface): TeamRoleFields {
    return { TeamSlug: team.Slug, TeamName: team.Name, TeamId: team.Id }
  }


  private setTeamForTeamAdmin(): void {
    this.teams$ = this.teamService.getTeam(this.identityService.companySlug, this.identityService.teamSlug).pipe(
      map(team => {
        this.teamsControl.setValue([this.transformTeam(team)]);
        this.onChange([this.transformTeam(team)]);

        return [team];
      })
    );
  }

  private getTeamsByCompany(companySlug: string): void {
    if (companySlug) {
      this.teams$ = this.teamService.getTeams(companySlug).pipe(
        map(teams => {
          const teamsTrimmed = teams.map(team => {
            return { ...team, Name: team.Name.trim() };
          });

          const existingTeams = teamsTrimmed.filter(team => !team.IsArchived);

          return existingTeams.sort((a, b) => (a.Name < b.Name ? -1 : 1));
        })
      );
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.teamsControl.disable();

      return;
    }

    this.teamsControl.enable();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(teams: TeamRoleFields[]): void {
    this.teamsControl.setValue(teams);
  }
}
