import { Injectable } from '@angular/core';
import { RestClass, RestService } from '../../../services/rest/rest.service';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { CreateEditUserData } from '../models/create-edit-user-data';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { ServerPaginationParams } from '../../../interfaces/server-pagination-params';
import { map } from 'rxjs/operators';
import { PaginationService } from '../../../services/pagination.service';
import { IdentityService } from '../../../services/identity.service';

@Injectable({
  providedIn: 'root'
})
@RestClass('/identities')
export class UsersService extends RestService<User> {

  readonly defaultPaginationOptions: ServerPaginationParams = {
    offset: 0,
    limit: 50,
    orderBy: 'CreatedAtUtc',
    desc: true,
  };

  constructor(
    protected http: HttpClient,
    private readonly paginationService: PaginationService,
    private readonly identityService: IdentityService,
  ) {
    super(http);
  }

  get(id: string): Observable<User> {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug, this.identityService.companySlug);

    return this.http.get<User>(`${this.baseUrl}/identities/${id}`, { params })
  }

  getMeData(): Observable<User> {
    return this.http.get<User>(`${this.route}/me`)
  }

  findUserByEmail(email: string): Observable<User> {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug, this.identityService.companySlug);

    return this.http.get<User>(`${this.baseUrl}/identities/byemail/${email}`, { params });
  }

  deleteUserFromTeam(id: string, companySlug: string, teamSlug: string): Observable<User> {
    return this.http.delete<User>(`${this.identityBaseUrl}/companies/${companySlug}/teams/${teamSlug}/identities/${id}`);
  }

  createUser(user: CreateEditUserData, isAllTeamProjects: boolean): Observable<User> {
    return this.http.post<User>(this.getRequestUrl(isAllTeamProjects), user);
  }

  deleteUsers(usersId: string[], isAllTeamProjects: boolean): Observable<User[]> {
    return this.http.delete<User[]>(this.getRequestUrl(isAllTeamProjects), { body: usersId });
  };

  updateUser(user: CreateEditUserData, isAllTeamProjects: boolean, id: string): Observable<User> {
    const companySlug = this.identityService.companySlug;
    let url = `${this.identityBaseUrl}/companies/${companySlug}/identities/${id}`;

    if (isAllTeamProjects) {
      url = `${this.identityBaseUrl}/identities/${id}`
    }

    if (this.identityService.isTeamAdmin()) {
      const teamSlug = this.identityService.teamSlug;

      url = `${this.identityBaseUrl}/companies/${companySlug}/teams/${teamSlug}/identities/${id}`;
    }

    return this.http.put<User>(url, user);
  }

  deleteUser(userId: string[], isAllTeamProjects: boolean): Observable<User> {
    const companySlug = this.identityService.companySlug;
    let url = `${this.identityBaseUrl}/companies/${companySlug}/identities/${userId}`;

    if (isAllTeamProjects) {
      url = `${this.identityBaseUrl}/identities/${userId}`;
    }

    if (this.identityService.isTeamAdmin()) {
      const teamSlug = this.identityService.teamSlug;

      url = `${this.identityBaseUrl}/companies/${companySlug}/teams/${teamSlug}/identities/${userId}`;
    }

    return this.http.delete<User>(url);
  }

  getUsersForTeam(companySlug: string, teamSlug: string): Observable<User[]> {
    return this.http.get<User[]>(`${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/identities`);
  }

  getServerSidePaginatedList(options: ServerPaginationParams, isAll = false) {
    const params = this.paginationService.getRequestParams(options);
    let url = `${this.baseUrl}/companies/${this.identityService.companySlug}/identities`;

    let httpParams = new HttpParams();

    params.forEach((value, key) => {
      httpParams = httpParams.set(key, value);
    });

    if (this.identityService.isTeamAdmin()) {
      const companySlug = this.identityService.companySlug;
      const teamSlug = this.identityService.teamSlug;

      url = `${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/identities`;
    }

    if (isAll) {
      url = `${this.baseUrl}/identities`
    }

    return this.http.get<User[]>(url, { params: httpParams, observe: 'response' }).pipe(
      map((data: HttpResponse<any>) => {
        return { totalCount: data.headers.get('TotalCount'), body: data.body };
      }),
    );
  }

  private getRequestUrl(isAllTeamProjects: boolean): string {
    const companySlug = this.identityService.companySlug;
    let url = `${this.identityBaseUrl}/companies/${companySlug}/identities`;

    if (isAllTeamProjects) {
      url = `${this.identityBaseUrl}/identities`;
    }

    if (this.identityService.isTeamAdmin()) {
      const teamSlug = this.identityService.teamSlug;

      url = `${this.identityBaseUrl}/companies/${companySlug}/teams/${teamSlug}/identities`;
    }

    return url;
  }
}
