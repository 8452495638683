// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host::ng-deep .mat-icon-button {
  height: 24px;
  width: 24px;
  line-height: 24px;
}
:host a {
  color: inherit;
  text-decoration: none;
}
:host .small-btn {
  min-width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/core/table/table-action/table-action.component.scss"],"names":[],"mappings":"AAEI;EACE,YAAA;EACA,WAAA;EACA,iBAAA;AADN;AAKE;EACE,cAAA;EACA,qBAAA;AAHJ;AAME;EACE,eAAA;AAJJ","sourcesContent":[":host{\n  &::ng-deep {\n    .mat-icon-button {\n      height:24px;\n      width:24px;\n      line-height: 24px;\n    }\n  }\n\n  a {\n    color: inherit;\n    text-decoration: none;\n  }\n\n  .small-btn {\n    min-width: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
