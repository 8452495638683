import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { TemplatesForCopyService } from '../../templates-for-copy.service';
import { IDialogDataForCopyConfigList } from '../../copy-config-from.service';
import { TableBuilder } from '../../../../../../../../core/table/table-builder';
import { ISourceLight } from '../../company-sources-light.model';
import { ITableColumn } from '../../../../../../../../core/table/interfaces/table-column.interface';
import { ITableAction } from '../../../../../../../../core/table/interfaces/table-action.interface';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Component({
  selector: 'mee-copy-from-templates',
  templateUrl: './copy-from-templates.component.html',
  styleUrls: ['./copy-from-templates.component.scss'],
})
export class CopyFromTemplatesComponent implements OnInit {

  @Input() isCopyDSConfig: boolean;

  @Input() dialogData: IDialogDataForCopyConfigList;

  @Output() onChooseSourceCopyItem: EventEmitter<ISourceLight> = new EventEmitter();

  // if templates is empty open second tab by default

  @Output() selectedTabChanged: EventEmitter<number> = new EventEmitter();

  isDataLoaded = false;

  isTemplatesForCopy = false;

  tableBuilder: TableBuilder<ISourceLight>; // ISource

  availableForCopyTemplates: ISourceLight[];

  subs: Subscription[] = [];

  constructor(private templatesForCopyService: TemplatesForCopyService) {
  }

  ngOnInit() {
    this.initTemplatesList();
  }


  initTemplatesList() {
    if (this.isCopyDSConfig) {
      this.subs.push(
        this.templatesForCopyService.getTemplatesForDataSource(
          this.dialogData.destinationDS.CompanySlug, this.dialogData.destinationDS.Id
        ).pipe(
          finalize(() => {
            this.isDataLoaded = true;
          }),
        ).subscribe((DSTemplates: ISourceLight[]) => {
          this.availableForCopyTemplates = DSTemplates;
          this.initTableBuilder(DSTemplates);
        }, () => {
          this.isTemplatesForCopy = false;
        }),
      );
    } else {
      this.subs.push(
        this.templatesForCopyService.getTemplatesForFeatureLayers(
          this.dialogData.destinationDS.CompanySlug,
          this.dialogData.destinationDS.Slug,
          this.dialogData.destinationLayer.Id).pipe(
          finalize(() => {
            this.isDataLoaded = true;
          }),
        ).subscribe((FLTemplates: ISourceLight[]) => {
            this.availableForCopyTemplates = FLTemplates;
            this.initTableBuilder(FLTemplates);
          }, () => {
            this.isTemplatesForCopy = false;
          },
        ),
      );
    }
  }


  initTableBuilder(availableForCopyTemplates) {

    if (this.availableForCopyTemplates.length) {
      this.isTemplatesForCopy = true;
      this.selectedTabChanged.emit(0);
    } else {
      this.isTemplatesForCopy = false;
      this.selectedTabChanged.emit(1);
    }

    const columns: ITableColumn[] = [
      {
        key: 'Slug',
        title: 'sources.listTable.columns.identifier',
      },
      {
        key: 'Type',
        title: 'sources.listTable.columns.type',
        contentFn: (content) => {
          return new Observable((observer) => {
            if (content === 'ArcGisFeatureService') {
              observer.next('ArcGISFeatureService');
            } else if (content === 'Kml') {
              observer.next('KML');
            }
            observer.complete();
          });
        },
      },
    ];

    this.tableBuilder = new TableBuilder<any>({
      list: availableForCopyTemplates,
    }, {
      columns: columns,
      rowClickAction: this.onChooseDSForCopy.bind(this),
      pagination: {
        hide: availableForCopyTemplates.length <= 7,
      },
    });


    /*Future feature with remove template from list of templates*/

    const rowActions: ITableAction[] = [
      {
        tooltip: 'Reports',
        icon: 'list_alt',
        action: (template) => this.removeTemplate(template),
      },
    ];

  }

  removeTemplate(template: ISourceLight) {
    this.templatesForCopyService.removeTemplate(this.dialogData.destinationDS.CompanySlug,
      this.dialogData.destinationDS.Id, template.Id).subscribe(() => {
        const index = this.availableForCopyTemplates.indexOf(template);
        this.availableForCopyTemplates.splice(index, 1);
        if (this.availableForCopyTemplates.length) {
          this.isTemplatesForCopy = true;
        }
      },
    );
  }


  onChooseDSForCopy(sourceForCopyItem) {
    this.onChooseSourceCopyItem.emit(sourceForCopyItem);
  }

}
