import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {PlacemarksFields} from '../components/source-configuration-list/models/placemarks-fields';

@Injectable()
export class PlacemarksFieldsService {

  private readonly _placemarkFields = new Subject<PlacemarksFields>();
  placemarkFields = this._placemarkFields.asObservable();

  constructor() {
  }

  setPlacemarkFields(value: PlacemarksFields) {
    this._placemarkFields.next(value);
  }

}
