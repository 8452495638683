import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MultiselectHeaderService {
  private templateRefSubject = new BehaviorSubject<TemplateRef<any>>(null);

  template$ = this.templateRefSubject.asObservable();

  setTemplate(template: TemplateRef<any>) {
    this.templateRefSubject.next(template);
  }
}
