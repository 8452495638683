import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CopyConfigService } from '../../services/copy-config.service';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { takeUntil, tap } from 'rxjs/operators';
import { CopyConfiguration } from './models/copy-configuration';
import { DataSourcesAdapterService } from '../configuration-tab/data-sources-adapter.service';
import { CopyConfigData } from './models/copy-config-data';
import { Subject } from 'rxjs';

@Component({
  selector: 'mee-copy-config-modal',
  templateUrl: './copy-config-modal.component.html',
  styleUrls: ['./copy-config-modal.component.scss'],
})
export class CopyConfigModalComponent implements OnInit, OnDestroy {
  selectedCopiedConfiguration: CopyConfiguration | null = null;
  copyConfigurationsList: CopyConfiguration[] = [];

  public readonly copyConfigData: CopyConfigData = inject(MAT_LEGACY_DIALOG_DATA);
  private readonly copyConfigService = inject(CopyConfigService);
  private readonly destroy$ = new Subject<void>();
  private readonly dataSourcesAdapterService = inject(DataSourcesAdapterService);
  private readonly dialogRef = inject(MatLegacyDialogRef<CopyConfigModalComponent>);
  public readonly copyConfigurationType = this.dataSourcesAdapterService.getCopyConfigDataSourceType(
    this.copyConfigData.destinationDataSource.Type,
  );

  ngOnInit(): void {
    this.getCopyConfigurationList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClose(): void {
    this.dialogRef.close();
  };

  onBack(): void {
    this.selectedCopiedConfiguration = null;
  }

  getSelectedConfigurationName(): string {
    if (!this.selectedCopiedConfiguration) {
      return '';
    }

    return this.selectedCopiedConfiguration.Description || this.selectedCopiedConfiguration.Slug;
  }

  onSelectConfiguration(copiedConfiguration: CopyConfiguration): void {
    this.selectedCopiedConfiguration = copiedConfiguration;
  };

  private getCopyConfigurationList(): void {
    const destinationSlug = this.copyConfigData.destinationDataSource.Slug;
    const companySlug = this.copyConfigData.destinationDataSource.CompanySlug;

    this.copyConfigService.getCopyConfigurationsList(destinationSlug, companySlug, this.copyConfigurationType).pipe(
      tap(configurationsList => this.copyConfigurationsList = configurationsList),
      takeUntil(this.destroy$),
    ).subscribe();
  };
}
