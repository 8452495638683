import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { ProcessService } from '../process-list/process.service';
import { TeamProjectService } from '../../../../services/team-project.service';

export const ProcessReportResolver: ResolveFn<unknown> = (
  route: ActivatedRouteSnapshot,
  next: RouterStateSnapshot,
  processService = inject(ProcessService),
  teamProjectService = inject(TeamProjectService)
) => {
  const processId = route.paramMap.get('processId');
  const companySlug = route.paramMap.get('companySlug');
  const sourceSlug = route.paramMap.get('sourceSlug');

  teamProjectService.companySlugFromRoute = companySlug;

  return processService.getProcessReport(processId, companySlug, sourceSlug);
}
