import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ILayer } from '../../../../../../components/layers/shared/models/layer.interface';
import { ConfigurationFields } from '../enums/configuration-fields.enum';
import { DataSourceType } from '../../../../../data-source-type.enum';
import { SourceConfiguration } from '../interfaces/source-configuration';
import { isEqual } from 'lodash';
import { ColorPickerPosition } from '../enums/color-picker-position';
import { FeatureLayerType } from '../interfaces/feature-layer-type.enum';
import { ObjectType } from '../enums/object-types.enum';
import { isDisplayForCurrentSourceType } from '../is-display-for-current-source-type.function';

@Component({
  selector: 'mee-multiselect-configuration',
  templateUrl: './multiselect-configuration.component.html',
  styleUrls: ['./multiselect-configuration.component.scss'],
})
export class MultiselectConfigurationComponent {

  form: UntypedFormGroup;

  localLayers: ILayer[];

  configurationFields = ConfigurationFields;

  private localNode: SourceConfiguration;

  readonly ColorPickerPosition = ColorPickerPosition;

  @Output() updateLayerGroup = new EventEmitter();

  @Input() companySlug: string;

  @Input() sourceType: DataSourceType;

  @Input() set node(node: SourceConfiguration) {
    if (node === undefined) {
      return;
    }

    if (isEqual(this.node, node)) {
      return;
    }

    this.localNode = node;

    this.form.patchValue(this.node, { emitEvent: false });
  }

  get node() {
    return this.localNode;
  }

  @Input() set layers(layers: ILayer[]) {
    this.localLayers = layers;

    if (layers.length === 0) {
      this.form.get('Layer').disable();
    }
  }

  get layers() {
    return this.localLayers;
  }

  get colorPickerPosition(): ColorPickerPosition {
    return this.isDisplayExcludeFromProcessing() ? ColorPickerPosition.Right : ColorPickerPosition.Left;
  }

  constructor(private formBuilder: UntypedFormBuilder) {
    this.initForm();
  }

  updateLayers(): void {
    this.updateLayerGroup.emit();
  }

  isDisplayExcludeFromProcessing() {
    return isDisplayForCurrentSourceType(this.configurationFields.IsExcludedFromProcessing, this.getActivatedNodeType(), this.sourceType);
  }

  private getActivatedNodeType(): FeatureLayerType {
    if (this.node.isRoot) {
      return FeatureLayerType.Root;
    }

    switch (this.node.ObjectType) {
      case ObjectType.UNKNOWN:
        return FeatureLayerType.Unknown
      case ObjectType.POINT:
        return FeatureLayerType.Point
      case ObjectType.LINE:
        return FeatureLayerType.Line
      case ObjectType.AREA:
        return FeatureLayerType.Area
      case ObjectType.MODEL3D:
        return FeatureLayerType.Model3d
      default:
        return FeatureLayerType.Unknown
    }
  }

  private initForm() {
    this.form = this.formBuilder.group({
      Id: [],
      Layer: [],
      MainColor: [],
      IsExcludedFromProcessing: []
    });
  }
}
