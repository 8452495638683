import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[meeRemoveAutofill]',
})
export class RemoveAutofillDirective {
  @HostBinding('attr.readonly') isReadonly: string | null = 'readonly';
  @HostListener('focus') onFocus(): void {
    this.isReadonly = null;
  }
}
