export enum UploadOutputType {
  Start = 'start',
  Done = 'done',
  AllAddedToQueue = 'allAddedToQueue',
  AddedToQueue = 'addedToQueue',
  Removed = 'removed',
  RemovedAll = 'removedAll',
  Cancelled = 'cancelled',
  DragOver = 'dragOver',
  DragOut = 'dragOut',
  Drop = 'drop',
  Uploading = 'uploading',
  Rejected = 'rejected'
}
