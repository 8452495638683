import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSourcesService } from '../../services/data-sources.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Sort } from '@angular/material/sort';
import { snackBarConfig } from '../../../../core/core.module';
import { EMPTY, Observable, Observer, Subject, Subscription, throwError } from 'rxjs';
import { ESCAPE } from '@angular/cdk/keycodes';
import { PlacemarksFieldsService } from '../../services/placemarks-fields.service';
import { PlacemarksFields } from './models/placemarks-fields';
import { TranslationHandleService } from '../../../../core/translate/translation-handle.service';
import { ISource } from '../../models/source.interface';
import { CopyConfigFromListComponent } from './components/copy-config-from/copy-config-from-list/copy-config-from-list.component';
import { CopyConfigFromService } from './components/copy-config-from/copy-config-from.service';
import { TemplatesForCopyService } from './components/copy-config-from/templates-for-copy.service';
import { CompaniesService } from '../../../companies/companies.service';
import { IdentityService } from '../../../../services/identity.service';
import { ILayer } from '../../../components/layers/shared/models/layer.interface';
import { catchError, delay, filter, finalize, mergeMap, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SourceConfigurationService } from './services/source-configuration.service';
import { SOURCE_CONFIG_TABLE_ROWS } from './models/configuration-table-rows';
import { TeamsService } from '../../../teams/services/teams.service';
import { SourceTeam } from '../../../teams/models/source-team.interface';
import { ColorPickerService } from 'ngx-color-picker';
import { ColorHelperService } from '../../../../shared/services/color-helper.service';
import every from 'lodash-es/every';
import isArray from 'lodash-es/isArray';
import each from 'lodash-es/each';
import keys from 'lodash-es/keys';
import has from 'lodash-es/has';
import _map from 'lodash-es/map';
import cloneDeep from 'lodash-es/cloneDeep';
import clone from 'lodash-es/clone';
import get from 'lodash-es/get';
import isNumber from 'lodash-es/isNumber';
import maxBy from 'lodash-es/maxBy';
import find from 'lodash-es/find';
import remove from 'lodash-es/remove';
import orderBy from 'lodash-es/orderBy';
import { DataSourceType } from '../../data-source-type.enum';
import { DataSourceStatus } from '../../data-source-status.enum';
import { BimNode } from './models/bim-node';
import { ProcessingResult } from './models/processing-result.enum';
import { AutodeskAuthNotificationCode } from '../../enums/autodesk-auth-notification-code.enum';
import { Bim360AccountDisconnectedComponent } from './components/bim360-account-disconnected/bim360-account-disconnected.component';
import { Bim360AccountDisconnectedData } from './components/bim360-account-disconnected/bim360-account-disconnected-data';
import { IProcessingLogRecords } from '../../models/data-source.interface';
import { SourceConfiguration3dModelMarker } from './models/source-configuration-3d-model-marker.enum';
import { ChangedNodeResults } from './components/configuration-tab/interfaces/changed-node-results';
import { SourceConfiguration } from './components/configuration-tab/interfaces/source-configuration';
import { ChangedNodeFormContent } from './components/configuration-tab/interfaces/changed-node-form-content';
import { ConfigurationFieldsAdapterService } from './components/configuration-tab/configuration-fields-adapter.service';
import { WorkerService } from '../../../../shared/services/worker.service';
import { WorkerMessage } from '../../../../../worker/app-workers/shared/worker-message.model';
import { WorkerTopic } from '../../../../../worker/app-workers/shared/worker-topic.enum';
import { SubtypeConfiguration } from './components/configuration-tab/interfaces/subtype-configuration';
import { PermissionType } from '../../../../shared/enums/permission-type.enum';
import { Scope } from '../../../components/models/scope.enum';
import { ConfigurationHint } from './models/configuration-hint';
import { LayerStatistic } from './interfaces/layer-statistic';
import { FeatureLayerPlacemark } from './interfaces/feature-layer-placemark';
import { ConfigurationStructure } from './components/configuration-tab/interfaces/configuration-structure';
import { ObjectType } from './components/configuration-tab/enums/object-types.enum';
import { ObjectTypeConfiguration } from './components/configuration-tab/interfaces/object-type-configuration';
import { ConfigurationTableColumn } from './components/configuration-tab/interfaces/configuration-table-column';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from '../../../../interfaces/confirmation-dialog-data';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LayerCoordinates } from '../../../components/custom-maps/interfaces/layer-coordinates';
import { CheckLocationMapComponent } from '../check-location-map/check-location-map.component';
import { LocationMapData } from '../../models/location-map-data';
import { ProjectionType } from './components/configuration-tab/enums/projection-type.enum';
import { SynchronizationResult } from '../../enums/synchronization-result';
import { PlacemarksTableTabComponent } from './components/placemarks-table-tab/placemarks-table-tab.component';
import { BimnodesTableTabComponent } from './components/bimnodes-table-tab/bimnodes-table-tab.component';
import { ConfigurationHeaderTemplateService } from './services/configuration-header-template.service';
import { MatLegacyTabChangeEvent as MatTabChangeEvent, MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { SourcesFormChangesService } from '../../services/sources-form-changes.service';
import { ConfigurationTab } from './models/configuration-tab';
import { CoordinateSystem } from '../../../components/coordinate-system/coordinate-system';
import { CopyConfigModalComponent } from './components/copy-config-modal/copy-config-modal.component';
import { DataSourcesAdapterService } from './components/configuration-tab/data-sources-adapter.service';
import { CopyConfigService } from './services/copy-config.service';
import { SetTemplateComponent } from './components/set-template-modal/set-template.component';
import { TemplateCreateData } from '../../models/template-create-data';

export const defaultStatistic: LayerStatistic = {
  ProcessedPlacemarksNumber: null,
  ProcessingLogRecords: [],
  ProcessingResultsNumbers: {
    'Success/0': 0,
    'Warning/1': 0,
    'Error/2': 0,
    'Ignore/3': 0,
  },
};

const DEFAULT_NODE_PADDING = 32;

const PHRASES_FOR_TRANSLATE = [
  {
    unsavedChanges: 'sources.configuration.unsavedChanges',
  },
  {
    synchronizeCompleted: 'sources.configuration.synchronizeCompleted',
  },
  {
    configurationUpdated: 'sources.configuration.configurationUpdated',
  },
  {
    doYouWantToSaveChanges: 'sources.configuration.doYouWantToSaveChanges',
  },
  {
    processingStarted: 'sources.configuration.processingStarted',
  },
  {
    processingFinished: 'sources.configuration.processingFinished',
  },
  {
    changesWillBeLost: 'sources.configuration.changesWillBeLost',
  },
  {
    layerChangesWillBeLost: 'sources.configuration.layerChangesWillBeLost',
  },
  {
    tooltipRightClick: 'sources.configuration.tooltipForRightClick',
  },
  {
    checkLocationError: 'sources.configuration.checkLocationError',
  },
];

@Component({
  selector: 'mee-source-configuration-list',
  templateUrl: './source-configuration-list.component.html',
  styleUrls: ['./source-configuration-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceConfigurationListComponent implements OnInit, AfterViewInit, OnDestroy {
  inspectionForms: any[];

  orderableList: any[];

  placemarks: FeatureLayerPlacemark[] = [];

  layers: ILayer[];

  companySlug: string;

  sourceTeams: Array<SourceTeam>;

  initialSourceTeams: Array<SourceTeam>;

  sourceSlug: string;

  typeOfSource = '';
  isRealTime = false;

  configuration: ConfigurationStructure;

  rootFeatureLayerConfiguration: SourceConfiguration;

  coordinateSystems: CoordinateSystem[] = this.route.snapshot.data.coordinateSystems;

  hoveredItemId: number;

  scrollLeft: number;

  ObjectType = ObjectType;

  changedNodes = [];

  activatedNode: SourceConfiguration;
  activatedNodePanel = false;
  expandedNodes = [];
  dataSourceStatuses = DataSourceStatus;
  dataSourceStatus: DataSourceStatus;
  isCurrentDSInProcessing: boolean;
  isUpdateStatisticAfterProcessing: boolean;
  isModelLevel = false;

  placemarksFields: PlacemarksFields;

  sortMode = false;

  // node - layer with added fields for model by client side (for example: isLeaf)

  newNodeAfterCopyConfig: SourceConfiguration;

  contextMenuPosition = { x: '0px', y: '0px' };

  scrollHolderStyles = {
    parentWidth: '0px',
    childWidth: '0px',
  };

  fixedRows = [
    {
      title: 'configurations-fields.columns.layerName',
      key: 'LayerName',
    },
  ];

  tableRows: ConfigurationTableColumn[] = SOURCE_CONFIG_TABLE_ROWS;

  isActivatedNodeSubtype: boolean;

  subtypeParentObjectTypeConfig: ObjectTypeConfiguration;

  bimNodes: BimNode[] = [];

  processingResult = ProcessingResult;

  isProcessingStarted = false;

  isConfigurationUpdateInProgress: boolean;

  rootFeatureLayerStatistic: LayerStatistic;

  configurationHints: ConfigurationHint[];

  selectedNodesIds = [];

  selectedTabIndex = 0;

  isGeneralHeaderTemplate = false;
  isDisplayHints = true;

  private configurationTabs = [
    {
      index: 0,
      label: ConfigurationTab.Mapping,
    },
    {
      index: 1,
      label: ConfigurationTab.General,
    },
    {
      index: 2,
      label: ConfigurationTab.Teams,
    },
    {
      index: 3,
      label: ConfigurationTab.Script,
    },
  ];

  private isGeneralFormChanged = false;

  private previousTabIndex = 0;

  private modelProjectionType: ProjectionType;

  private isSyncInProgress = true;

  private _subtypeParent: ChangedNodeFormContent;

  private _scriptChanged = false;

  private _destinationLayer: SourceConfiguration;

  private _translationMap: Map<string, string>;

  public _currentDataSource: ISource;

  private _adminEmails: Array<string>;

  private _newLayerAfterCopyConfig: SourceConfiguration;

  private _newDSAfterCopy: ISource;

  private _statisticData: LayerStatistic[];

  private _originalData: ConfigurationStructure;

  private _unsubscribe$ = new Subject<void>();

  private enabledSourceTeamsIds: string[] = [];

  private isSourceTeamChanged = false;

  private changedNodeId: string;

  private isNeedToDisableUpdateButton = false;

  @ViewChild('scrollingTableOrigin', { static: true }) scrollingTableOrigin: ElementRef;
  @ViewChild('headerTemplate', { read: ViewContainerRef, static: false }) headerTemplate: ViewContainerRef;
  @ViewChild('scrollingTableDupl', { static: true }) scrollingTableDupl: ElementRef;
  @ViewChild('tableHolder', { static: true }) tableHolder: ElementRef;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  @ViewChild(MatMenuTrigger, { static: true }) contextMenu: MatMenuTrigger;
  @ViewChildren('layerNames') layerNames: QueryList<ElementRef>;

  private selectedNodesToUpdateIdsQueue = [];

  @HostListener('document:keyup', ['$event'])
  handleKeyUp(event) {
    if (event.code === ESCAPE) {
      this.deactivateNode();
    }
  }

  get isMultiselectModeActive() {
    return this.selectedNodesIds.length > 0;
  }

  get isAllNodesSelected(): boolean {
    if (!this.selectedNodesIds || !this.orderableList) {
      return;
    }

    return this.selectedNodesIds.length === this.orderableList.length;
  }

  get isSelectedNodes(): boolean {
    return this.selectedNodesIds && this.selectedNodesIds.length !== 0;
  }

  get isBim360SourceType() {
    if (this._currentDataSource === undefined) {
      return;
    }

    return this._currentDataSource.Type === DataSourceType.Bim360;
  }

  get isActivatedNode(): boolean {
    return !!this.activatedNode;
  }

  get isSetTemplateAvailable(): boolean {
    return !this.isBim360SourceType && this._currentDataSource.Type !== DataSourceType.Simple3dModel;
  }

  get statistic(): LayerStatistic {
    return this.activatedNode && this.activatedNode.statistic ? this.activatedNode.statistic : ({} as LayerStatistic);
  }

  get processingLogs(): IProcessingLogRecords[] {
    if (this.isRealTime) {
      return this.placemarks.map(placemark => {
        return {
          PlacemarkId: placemark.Id,
          PlacemarkProcessingResult: placemark.ProcessingResult,
          ProcessingLogMessage: placemark.ProcessingLogs[0],
        };
      });
    }

    if (this.bimNodes && this.bimNodes.length === 0) {
      const isRootHasStatistic = this.rootFeatureLayerConfiguration && this.rootFeatureLayerConfiguration.statistic;

      return isRootHasStatistic ? this.rootFeatureLayerConfiguration.statistic.ProcessingLogRecords : [];
    }

    return this.bimNodes
      .filter(bimNode => bimNode.ProcessingResult !== this.processingResult.Success)
      .map(bimNode => {
        return {
          PlacemarkId: bimNode.CompositeId.split('|').pop(),
          PlacemarkProcessingResult: bimNode.ProcessingResult,
          ProcessingLogMessage: bimNode.ProcessingLogs[0],
        };
      });
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private sourceConfigurationService: SourceConfigurationService,
    private colorPickerService: ColorPickerService,
    private placemarksFieldsService: PlacemarksFieldsService,
    private identityService: IdentityService,
    private companiesService: CompaniesService,
    private dataSourceService: DataSourcesService,
    private copyConfigFromService: CopyConfigFromService,
    private translationHandleService: TranslationHandleService,
    private templatesForCopyService: TemplatesForCopyService,
    private teamsService: TeamsService,
    private workerService: WorkerService,
    private colorHelperService: ColorHelperService,
    private configurationFieldsAdapterService: ConfigurationFieldsAdapterService,
    private configurationHeaderTemplateService: ConfigurationHeaderTemplateService,
    private sourcesFormChanges: SourcesFormChangesService,
    private copyConfigService: CopyConfigService,
    private dataSourcesAdapterService: DataSourcesAdapterService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
  ) {
    this.handleOpenWithQueryParams();
    matIconRegistry.addSvgIcon('check-location', domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/icons/check-location-icon.svg'));
    matIconRegistry.addSvgIcon('template-outline', domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/icons/template-outline.svg'));
    this.translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);
    this.translationHandleService
      .getTranslationMap()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((translationMap: Map<string, string>) => {
        this._translationMap = translationMap;
      });

    const routeData = this.route.snapshot.data;

    this._originalData = routeData.configuration;

    this.copyConfigFromService.setOldConfigurationStructure(cloneDeep(this.route.snapshot.data.configuration));
    this._currentDataSource = this.route.snapshot.data.source;

    this._originalData.RootFeatureLayerConfiguration.expanded = true;
    this.expandedNodes.push(this._originalData.RootFeatureLayerConfiguration.Id);
    this.modifyingTree(this._originalData.RootFeatureLayerConfiguration);

    this.configuration = cloneDeep(this._originalData);

    this.rootFeatureLayerConfiguration = this.configuration.RootFeatureLayerConfiguration;

    this.sourceConfigurationService.setRootFLConfiguration(this.rootFeatureLayerConfiguration);

    this.inspectionForms = orderBy(routeData.forms, ['Name']);

    this.layers = orderBy(routeData.layers, ['Name']);

    this.companySlug = this.route.snapshot.paramMap.get('companySlug');

    this.sourceSlug = this.route.snapshot.paramMap.get('sourceSlug');

    this.checkSynchronizationStatus();

    if (!this.isTeamAdmin()) {
      this.getTeamsForCurrentDataSource(this._currentDataSource.CompanySlug, this._currentDataSource.Id);
    }

    this.getProcessingStatusBySource(this._currentDataSource);

    this.getConfigurationHints(this.companySlug, this.sourceSlug);

    this.onFormDataToList();
  }

  onFormDataToList() {
    this.isUpdateStatisticAfterProcessing = true;

    this.loadStatistic()
      .pipe(
        finalize(() => {
          this.isUpdateStatisticAfterProcessing = false;
          this.rootFeatureLayerStatistic = this.rootFeatureLayerConfiguration.statistic;
        }),
        takeUntil(this._unsubscribe$),
      )
      .subscribe(
        () => {
          this.dataToList();
        },
        () => {
          this.dataToList();
        },
      );
  }

  /**
   * On init
   */
  ngOnInit() {
    // New data source after copy config

    this.copyConfigFromService.newDSAfterCopyConfig$.pipe(takeUntil(this._unsubscribe$)).subscribe((newDataSource: ISource) => {
      if (!newDataSource) {
        return;
      }

      this._newDSAfterCopy = newDataSource;

      this.configuration = cloneDeep(this._originalData);

      this.configuration.RootFeatureLayerConfiguration = cloneDeep(this._newDSAfterCopy.RootFeatureLayerConfiguration);

      this.configuration.DataSource.MapParsingRules = cloneDeep(this._newDSAfterCopy.MapParsingRules);

      this.rootFeatureLayerConfiguration = this.configuration.RootFeatureLayerConfiguration;

      this.modifyingTree(this.rootFeatureLayerConfiguration);

      this.onFormDataToList();

      this.onChangeModels({ changed: true });
    });
    // New feature layer after copy config

    this.copyConfigFromService.newLayerAfterCopyConfig$.pipe(takeUntil(this._unsubscribe$)).subscribe((newLayer: SourceConfiguration) => {
      if (!newLayer) {
        return;
      }

      let layerForReplace;

      this._newLayerAfterCopyConfig = newLayer;

      layerForReplace = this.findLayerForReplace(
        this._originalData.RootFeatureLayerConfiguration.ChildLayers,
        this._newLayerAfterCopyConfig.Id,
      );

      const index = this._originalData.RootFeatureLayerConfiguration.ChildLayers.indexOf(layerForReplace);

      if (index !== -1) {
        this._originalData.RootFeatureLayerConfiguration.ChildLayers[index] = cloneDeep(newLayer);

        this.newNodeAfterCopyConfig = this._originalData.RootFeatureLayerConfiguration.ChildLayers[index];

        this.onChangeModels({ changed: true, id: newLayer.Id });
      } else {
        this._originalData.RootFeatureLayerConfiguration.ChildLayers.forEach(item => {
          if (item.ChildLayers) {
            item.ChildLayers.forEach(layerInsideFolder => {
              if (layerForReplace && layerInsideFolder.Id === layerForReplace.Id) {
                const indexOfLayerInsideFolder = item.ChildLayers.indexOf(layerForReplace);

                item.ChildLayers[indexOfLayerInsideFolder] = cloneDeep(newLayer);

                this.newNodeAfterCopyConfig = item.ChildLayers[indexOfLayerInsideFolder];
              }
            });
          }
        });

        this.onChangeModels({ changed: true, id: newLayer.Id });
      }

      this.modifyingTree(this._originalData.RootFeatureLayerConfiguration);

      this.configuration = cloneDeep(this._originalData);

      this.rootFeatureLayerConfiguration = this.configuration.RootFeatureLayerConfiguration;

      this.onFormDataToList();
    });

    this.copyConfigFromService.onBackToListChanged$.pipe(takeUntil(this._unsubscribe$)).subscribe(isBackToCopyConfigFromList => {
      if (isBackToCopyConfigFromList) {
        setTimeout(() => {
          this.matDialog.open(CopyConfigFromListComponent, {
            width: '400px',
            height: '640px',
            panelClass: 'copy-config-from-list-dialog',
            data: {
              destinationDS: this._currentDataSource,
              destinationLayer: this._destinationLayer,
            },
            disableClose: true,
            closeOnNavigation: true,
          });
        }, 0);
      }
    });

    if (this.identityService.isTeamAdmin() && this.companySlug) {
      this.companiesService
        .getAdminEmails(this.companySlug)
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe((adminEmails: Array<string>) => {
          this._adminEmails = adminEmails;
        });
    }
    this.onCheckLocation();

    this.listenForUpdateNodeWorkerResponse();
  }

  ngAfterViewInit(): void {
    this.syncScrollWidth();
    this.onHeaderTemplateChange();
  }

  getMultiSelectLayersToolTipText(): string {
    return this.getMultiSelectLayersName().join('; ');
  }

  getMultiSelectLayersName(): string[] {
    const selectedLayersName = [];

    this.selectedNodesIds.forEach(id => {
      const foundLayerName = this.orderableList.find(layer => layer.Id === id).LayerName;
      selectedLayersName.push(foundLayerName);
    });

    return selectedLayersName;
  }

  onDataSourceFormChange(state: boolean): void {
    this.isGeneralFormChanged = state;
  }

  /*Function that triggered on click on every layer*/

  activateNode(node: SourceConfiguration) {
    this.activatedNode = node;
    this.activatedNodePanel = true;

    if (!this.isNodeSelected(node)) {
      // this.resetSelectedNodes();
    }

    /*For display layer configuration (configuration tab) or sublayer configuration (subtype-configuration)*/

    this.isActivatedNodeSubtype = node.isSubtype;

    if (this.isActivatedNodeSubtype) {
      /*Find subtype parent and collect data from it that need in subtype component*/

      this._subtypeParent = this.findLayerForReplace(this.rootFeatureLayerConfiguration.ChildLayers, node.parentId);

      this.subtypeParentObjectTypeConfig = this._subtypeParent.ObjectTypeConfiguration;
    }

    this.placemarksFields = null;
    this.placemarks = [];
    this.bimNodes = [];
    this.isModelLevel = this.activatedNode.parentId === this.rootFeatureLayerConfiguration.Id;

    this.getWorkPacks();

    if (node.isBranch) {
      return;
    }

    /*Sublayer must take placemarks field from it parent*/

    const nodeId = this.isActivatedNodeSubtype ? this._subtypeParent.Id : node.Id;
    let isBimNodeType = false;
    this.dataSourceService
      .getFeatureLayersPlacemarksFields(this.companySlug, this.sourceSlug, nodeId)
      .pipe(
        mergeMap((placemarkFields: PlacemarksFields) => {
          this.placemarksFields = each(placemarkFields, (field: any) => field.sort());

          // TODO need to be change on backend

          placemarkFields.PrimaryFields.push('Geometry');

          this.placemarksFields.TableKeys = placemarkFields.PrimaryFields.slice();
          this.placemarksFieldsService.setPlacemarkFields(this.placemarksFields);

          if (this.activatedNode.ObjectType === this.ObjectType.MODEL3D) {
            isBimNodeType = true;

            return this.dataSourceService.getBimNodes(this.companySlug, this.sourceSlug, nodeId);
          }

          return this.dataSourceService.getFeatureLayersPlacemarks(this.companySlug, this.sourceSlug, nodeId);
        }),
        takeUntil(this._unsubscribe$),
      )
      .subscribe(data => {
        if (isBimNodeType) {
          this.bimNodes = data as BimNode[];
        } else {
          this.placemarks = data.sort() as FeatureLayerPlacemark[];
        }
        this.cdr.markForCheck();
      });
  }

  getWorkPacks(): void {
    if (!this.isBim360SourceType && this._currentDataSource.Type !== DataSourceType.NativeBim) {
      return;
    }

    if (this.activatedNode.ObjectType === ObjectType.MODEL3D && this.isModelLevel) {
      this.dataSourceService.getWorkPacks(this.companySlug, this.sourceSlug, this.activatedNode.Id).pipe(
        tap(workPacks => this.sourceConfigurationService.setWorkPacks(workPacks)),
        takeUntil(this._unsubscribe$),
      ).subscribe();
    }
  }

  onTabChange(event: MatTabChangeEvent | null): void {
    const index = event ? event.index : 0;

    const tab = this.configurationTabs.find(searchTab => searchTab.index === index);

    if (tab === undefined || index === this.selectedTabIndex) {
      return;
    }

    if (this.isGeneralFormChanged) {
      this.tabGroup.selectedIndex = this.selectedTabIndex;
      this.openGeneralFormConfirmationDialog(index);

      return;
    }

    this.isDisplayHints = tab.label === ConfigurationTab.Mapping;
    this.router.navigate([], { relativeTo: this.route, queryParams: { tab: tab.label } });
    this.selectedTabIndex = index;
  }

  deactivateNode() {
    this.activatedNodePanel = false;
  }

  /*TODO shifted all general functions into service*/

  /*General find layer function that recursively goes for their child layers*/

  findLayerForReplace(items: Array<SourceConfiguration>, nodeId: string) {
    if (!items) {
      return;
    }

    for (const item of items) {
      // Test current object

      if (!item) {
        return;
      }

      if (item.Id === nodeId) {
        return item;
      }

      // Test children recursively

      const child = this.findLayerForReplace(item.ChildLayers, nodeId);
      if (child) {
        return child;
      }
    }
  }

  onContextMenu(event: any, node) {
    event.preventDefault();

    this.contextMenuPosition.x = event.clientX - 200 + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { node: node };
    this.contextMenu.openMenu();
  }

  onChangeSourceTeams(enabledSourceTeams: string[]) {
    this.isSourceTeamChanged = true;
    this.enabledSourceTeamsIds = enabledSourceTeams;
  }

  openCopyConfigWindow(): void {
    if (!this.isUpdateAllowedForTeamAdmin()) {
      return;
    }

    this.matDialog.open(CopyConfigModalComponent, {
      data: {
        destinationConfiguration: this.rootFeatureLayerConfiguration,
        destinationDataSource: this._currentDataSource,
      },
      autoFocus: false,
      panelClass: 'copy-config-modal',
    });
  }

  setAsTemplate(): void {
    const dialogRef = this.matDialog.open<SetTemplateComponent, null, null>(SetTemplateComponent, {
      autoFocus: false,
      panelClass: 'set-template-modal',
      width: '400px',
    });

    dialogRef.componentInstance.cancel.pipe(
      tap(() => dialogRef.close()),
      takeUntil(this._unsubscribe$),
    ).subscribe();

    dialogRef.componentInstance.save.pipe(
      switchMap(Name => {
        const templateCreateData: TemplateCreateData = { SourceSlug: this._currentDataSource.Slug, Name };

        return this.dataSourceService.setAsTemplate(this.companySlug, templateCreateData);
      }),
      tap(() => dialogRef.close()),
      takeUntil(this._unsubscribe$),
    ).subscribe();
  }

  addToFavorites(): void {
    this.copyConfigService.addToFavorites(this.companySlug, this._currentDataSource.Slug).pipe(
      tap(() => this.rootFeatureLayerConfiguration.IsFavorite = true),
      takeUntil(this._unsubscribe$),
    ).subscribe();
  }

  removeFromFavorites(): void {
    this.copyConfigService.removeFromFavorites(this.companySlug, this._currentDataSource.Slug).pipe(
      tap(() => this.rootFeatureLayerConfiguration.IsFavorite = false),
      takeUntil(this._unsubscribe$),
    ).subscribe();
  }

  getCurrentDataSource() {
    if (this.companySlug && this.sourceSlug) {
      this.dataSourceService
        .getByCompany(this.companySlug, this.sourceSlug)
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe((dataSource: ISource) => {
          this._currentDataSource = dataSource;

          if (!this.isTeamAdmin()) {
            this.getTeamsForCurrentDataSource(dataSource.CompanySlug, dataSource.Id);
          }

          this.getProcessingStatusBySource(dataSource);
        });
    }
  }

  getTeamsForCurrentDataSource(dsCompanySlug: string, currentDataSourceId: string) {
    this.teamsService
      .getTeamsForDataSource(dsCompanySlug, currentDataSourceId)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(teams => {
        if (this.sourceTeams === undefined) {
          this.initialSourceTeams = teams;
          this.enabledSourceTeamsIds = teams.filter(sourceTeam => sourceTeam.IsEnabled).map(sourceTeam => sourceTeam.Id);
        }
        this.sourceTeams = teams;
      });
  }

  getConfigurationHints(companySlug: string, dataSourceSlug: string) {
    this.dataSourceService
      .getConfigurationHints(companySlug, dataSourceSlug)
      .pipe(
        tap(hints => (this.configurationHints = hints)),
        takeUntil(this._unsubscribe$),
      )
      .subscribe();
  }

  get isVirginiaDataSource() {
    return this.typeOfSource === 'Virginia811';
  }

  syncScrollWidth() {
    setTimeout(() => {
      this.scrollHolderStyles.parentWidth = this.scrollingTableOrigin.nativeElement.offsetWidth + 2;
      this.scrollHolderStyles.childWidth = this.scrollingTableOrigin.nativeElement.querySelector('.table-scrollable').offsetWidth;
    }, 500);
  }

  onScroll(event) {
    this.scrollLeft = event.target.scrollLeft;
    this.scrollingTableOrigin.nativeElement.scrollLeft = this.scrollLeft;
    this.scrollingTableDupl.nativeElement.scrollLeft = this.scrollLeft;
  }

  getCellTooltipText(key: string, node, index: number) {
    const layerName = node.DisplayName ? `${node.LayerName} (${node.DisplayName})` : node.LayerName;
    const maxLayerNameWidth = 217;
    const isBiggerThenMaxWidth = this.layerNames.toArray()[index]?.nativeElement.scrollWidth > maxLayerNameWidth;
    return key === 'LayerName' && isBiggerThenMaxWidth ? layerName : '';
  }

  /**
   * On click for sync structure
   * @returns {Subscription}
   */
  onSyncStructure() {
    this.syncStructure();
  }

  /**
   * Sync structure request
   */
  syncStructure() {
    this.isSyncInProgress = true;

    this.dataSourceService
      .syncStructure(this.companySlug, this.sourceSlug)
      .pipe(
        delay(5000),
        catchError(err => {
          this.isSyncInProgress = false;

          return throwError(err);
        }),
        tap(() => this.startSyncPolling()),
        takeUntil(this._unsubscribe$),
      )
      .subscribe();
  }

  /**
   * Loading statistic for source
   */
  loadStatistic(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.dataSourceService
        .getStatistic(this.companySlug, this.sourceSlug)
        .pipe(
          finalize(() => (this.isConfigurationUpdateInProgress = false)),
          takeUntil(this._unsubscribe$),
        )
        .subscribe(
          (data: LayerStatistic[]) => {
            this._statisticData = data;
            this.processingStatistic();
            observer.next(true);
            observer.complete();
          },
          error => observer.error(error.statusText),
        );
    });
  }

  /**
   * Processing data source statistic with configuration
   */
  processingStatistic() {
    const RootLayer = this.rootFeatureLayerConfiguration;

    const processingNode = (layerId: string, layer, path: string, parentLayer?) => {
      const statistic = this.findStatisticByLayerPath(path);

      layer.statistic = statistic ? statistic : clone(defaultStatistic);

      // Adding ProcessedPercent
      if (layer.Statistics) {
        const total = get(layer, 'Statistics.TotalCount', '');
        layer.statistic.ProcessedPercent = this.getProcessedPercent(total, layer.statistic.ProcessedPlacemarksNumber);
      }

      /**
       * Append child logs to parents
       */

      each(layer.ChildLayers, (childLayer: SourceConfiguration) => {
        return processingNode(childLayer.Id, childLayer, `${path}/${childLayer.LayerId}`, layer);
      });

      if (parentLayer) {
        if (!layer.statistic.ProcessingLogRecords) {
          layer.statistic.ProcessingLogRecords = [];
        }

        if (!parentLayer.statistic.ProcessingLogRecords) {
          parentLayer.statistic.ProcessingLogRecords = [];
        }

        parentLayer.statistic.ProcessingLogRecords = [
          ...parentLayer.statistic.ProcessingLogRecords,
          ...layer.statistic.ProcessingLogRecords,
        ];
      }

      return;
    };

    processingNode(RootLayer.Id, RootLayer, RootLayer.LayerId);
  }

  getProcessedPercent(total, processed) {
    if (!isNumber(total) || !isNumber(processed)) {
      return null;
    }

    const proc = processed / (total * 0.01);

    return isNaN(proc) ? 0 : Math.round(proc * 100) / 100;
  }

  getProcessedIconClass(statistic) {
    if (!statistic) {
      return '';
    }

    const max: any = maxBy(
      statistic.filter(data => data.key !== 'Success/0'),
      'value',
    );

    if (!max.value) {
      return '';
    }

    switch (max.key) {
      case 'Warning/1':
        return 'warning';
      case 'Error/2':
        return 'error';
      case 'Ignore/3':
        return 'ignore';
      default:
        return '';
    }
  }

  getProcessedClass(percent) {
    if (!isNumber(percent)) {
      return '';
    }

    let cl = 'good';

    if (percent < 100) {
      cl = 'normal';
    }

    if (percent < 25) {
      cl = 'bed';
    }

    return `processedPercent ${cl}`;
  }

  openSampleDataModal(): void {
    this.matDialog.open<PlacemarksTableTabComponent>(PlacemarksTableTabComponent, {
      data: {
        placemarks: this.placemarks,
        fields: this.placemarksFields,
      },
      autoFocus: false,
      height: '540px',
    });
  }

  openBimNodesData() {
    this.matDialog.open<BimnodesTableTabComponent>(BimnodesTableTabComponent, {
      data: {
        bimNodes: this.bimNodes,
      },
      autoFocus: false,
      height: '540px',
    });
  }

  findStatisticByLayerPath(path: string): any {
    return find(this._statisticData, (item, index: string) => {
      return index.toLowerCase() === path.toLowerCase();
    });
  }

  onChangeSelectedLayersFieldsValue(changedFields: any) {
    let oldLayers = [];
    let rootLayer = null;

    this.selectedNodesIds.forEach(selectedNodeId => {
      const foundLayer = this.getLayerById(this.rootFeatureLayerConfiguration, selectedNodeId);

      if (foundLayer.isRoot) {
        rootLayer = foundLayer;
      }

      const isLayerHasChildren = foundLayer.ChildLayers && foundLayer.ChildLayers.length > 0;

      oldLayers = isLayerHasChildren ? [foundLayer, ...oldLayers] : [...oldLayers, foundLayer];
    });

    if (rootLayer) {
      oldLayers = [rootLayer, ...oldLayers.filter(layer => !layer.isRoot)];
    }

    const interval = 1000;

    oldLayers.forEach((oldLayer, index) => {
      this.isNeedToDisableUpdateButton = true;

      const IsExcludedFromProcessing = oldLayer.isRoot ? false : changedFields.IsExcludedFromProcessing;

      const newLayer = {
        ...oldLayer,
        MainColor: changedFields.MainColor,
        Layer: changedFields.Layer,
        IsExcludedFromProcessing,
      };

      if (index === 0) {
        this.onChangeModels({ changed: true, id: oldLayer.Id, changedNode: newLayer });
      } else {
        // we used setTimeout here for update all nodes consecutively one after the other in forEach, can be replaced with some queue

        setTimeout(() => {
          this.onChangeModels({ changed: true, id: oldLayer.Id, changedNode: newLayer });

          if (index === oldLayers.length - 1) {
            this.isNeedToDisableUpdateButton = false;
          }
        }, index * interval);
      }
    });
  }

  /**
   * Has changing the 'changed' status of node
   * @param result
   */
  onChangeModels(result: ChangedNodeResults) {
    if (result.changed && this.changedNodes.indexOf(result.id) === -1) {
      this.changedNodes.push(result.id);
    }

    if (!result.changed && this.changedNodes.indexOf(result.id) !== -1) {
      this.changedNodes.splice(this.changedNodes.indexOf(result.id), 1);
    }

    if (result.subtypeConfiguration !== undefined) {
      this.changedNodeId = result.subtypeConfiguration.Id;

      this.updateConfigurationObject(result.changedNode, result.subtypeConfiguration);

      return;
    }

    if (result.changedNode !== undefined) {
      this.changedNodeId = result.changedNode.Id;

      this.updateConfigurationObject(result.changedNode);
    }
  }

  getPaddingForNode(node): string {
    if (!node.isRoot) {
      const isNodeHasChildren = node.node.ChildLayers && node.node.ChildLayers.length > 0;
      const arrowWidth = isNodeHasChildren ? 16 : 32;
      const iterationPadding = (node.level - 1) * DEFAULT_NODE_PADDING + arrowWidth;

      return !this.sortMode ? `${iterationPadding}px` : null;
    }

    return `${DEFAULT_NODE_PADDING}px`;
  }

  onChangeNodeAfterCopyConfig(changedNode: SourceConfiguration) {
    this.activatedNode = { ...this.activatedNode, ...changedNode };

    this.configuration.RootFeatureLayerConfiguration.ChildLayers = this.configuration.RootFeatureLayerConfiguration.ChildLayers.map(
      node => {
        return this.updateNode(changedNode, node);
      },
    );
  }

  updateTableRow(nodeId: string) {
    if (!nodeId) {
      return;
    }

    const newNodesForOrderableList = [];

    const changedRootLayer = this.configuration.RootFeatureLayerConfiguration;

    // updated child layer

    const changedNode = this.getLayerById(changedRootLayer, nodeId);

    this.orderableList = this.orderableList.map(element => {
      if (element.Id === nodeId) {
        return { ...element, node: { ...element.node, ...changedNode } };
      }

      return element;
    });

    const changedNodeInOrderableList = this.orderableList.find(element => element.Id === nodeId);

    let newNode = this.makeLine(changedNodeInOrderableList.node, changedNodeInOrderableList.level);

    let newSublayers = [];

    const isChangedNode3dModelCategory = changedNodeInOrderableList.node.Marker === SourceConfiguration3dModelMarker.Category;

    if (!isChangedNode3dModelCategory && changedNodeInOrderableList.SubtypeField !== changedNodeInOrderableList.node.SubtypeField) {
      remove(this.orderableList, { parentId: nodeId as any, isSubtype: true as any });
      newSublayers = this.getNewSubtypeRows(changedNodeInOrderableList);
    }

    if (newSublayers.length > 0) {
      newNode = { ...newNode, hasChildren: true, node: { ...newNode.node, hasChildren: true } };

      newNodesForOrderableList.unshift(...newSublayers);
    }

    newNodesForOrderableList.unshift(newNode);

    const nodeIndex = this.orderableList.indexOf(changedNodeInOrderableList);

    this.orderableList.splice(nodeIndex, 1, ...newNodesForOrderableList);

    if (!changedNodeInOrderableList.node.isRoot) {
      this.collapseChildren(newNode.node);
    }

    this.cdr.markForCheck();
  }

  getNewSubtypeRows(node: any) {
    return _map(node.node.SubtypesConfigurations, (subtype: SubtypeConfiguration) => {
      subtype.Id = `${node.node.Id}_${subtype.SubtypeValue}`;
      subtype.isSubtype = true;
      subtype.parentId = node.node.Id;

      return this.makeLine(subtype, node.level + 1);
    });
  }

  onScriptChanged(event) {
    this._scriptChanged = event;
  }

  subtypeConfigChanged(subtypeConfig: SubtypeConfiguration) {
    const changedModel: ChangedNodeResults = {
      changed: true,
      id: subtypeConfig.Id,
      changedNode: this._subtypeParent,
      subtypeConfiguration: subtypeConfig,
    };

    this.onChangeModels({ ...changedModel });

    // this.onChangeModels({ changed: true, id: subtypeConfig.Id });
  }

  /**
   * Update configuration request
   */
  onUpdate() {
    const isNativeGis = this.typeOfSource === DataSourceType.NativeGis;
    const isLandXmlModel = this.typeOfSource === DataSourceType.LandXml;

    let coordinateSystem;

    if (isNativeGis) {
      coordinateSystem = this.configuration.DataSource.CoordinateSystem;
    } else if (isLandXmlModel) {
      coordinateSystem = this.configuration.RootFeatureLayerConfiguration.ChildLayers[0].ObjectTypeConfiguration.CoordinateSystem;
    }

    const isCoordinationFieldInvalid = coordinateSystem === null || coordinateSystem <= 0;

    if ((isNativeGis || isLandXmlModel) && isCoordinationFieldInvalid && this.modelProjectionType !== ProjectionType.Manual) {
      this.snackBar.open('Please specify the projection system (ESPG code)', 'OK', snackBarConfig);
      return;
    }

    this.isConfigurationUpdateInProgress = true;

    const sourceTeamsSendModel = {
      DataSourceId: this._currentDataSource.Id,
      TeamsIds: this.enabledSourceTeamsIds,
    };

    if (this.changedNodes.length === 0 && !this._scriptChanged && this.isSourceTeamChanged) {
      this.teamsService
        .updateSourceTeams(this.companySlug, sourceTeamsSendModel)
        .pipe(
          filter(() => !this.isTeamAdmin()),
          tap(() => {
            this.isSourceTeamChanged = false;
            this.isConfigurationUpdateInProgress = false;
            this.snackBar.open(this._translationMap.get('configurationUpdated'), 'OK', snackBarConfig);
            this.cdr.markForCheck();
          }),
          takeUntil(this._unsubscribe$),
        )
        .subscribe();

      return;
    }

    this.updateConfiguration()
      .pipe(
        filter(() => !this.isTeamAdmin()),
        switchMap(() => {
          this.resetSelectedNodes();
          return this.teamsService.updateSourceTeams(this.companySlug, sourceTeamsSendModel);
        }),
        tap(() => {
          this.isSourceTeamChanged = false;
          this.cdr.markForCheck();
        }),
        takeUntil(this._unsubscribe$),
      )
      .subscribe(() => {
        this.snackBar.open(this._translationMap.get('configurationUpdated'), 'OK', snackBarConfig);
      });
  }

  updateConfiguration(): Observable<any> {
    return this.dataSourceService.updateConfiguration(this.companySlug, this.sourceSlug, this.configuration).pipe(
      tap(() => {
        this.refreshConfiguration();
      }),
      catchError(error => {
        this.isConfigurationUpdateInProgress = false;
        this.snackBar.open(error.statusText, 'OK', snackBarConfig);
        return EMPTY;
      }),
      switchMap(() => {
        return this.dataSourceService.getConfigurationHints(this.companySlug, this.sourceSlug);
      }),
      tap(hints => (this.configurationHints = hints)),
    );
  }

  /**
   * Makes updating of original and working trees data and make decoration according previous tree viewing
   */
  refreshConfiguration() {
    this.dataSourceService
      .configuration(this.companySlug, this.sourceSlug)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((data: ConfigurationStructure) => {
        // const activatedNodeId = this.activatedNode.Id;
        this._originalData = data;
        this._originalData.RootFeatureLayerConfiguration.expanded = true;
        this.expandedNodes.push(this._originalData.RootFeatureLayerConfiguration.Id);
        this.modifyingTree(this._originalData.RootFeatureLayerConfiguration);

        this.configuration = cloneDeep(this._originalData);
        this.rootFeatureLayerConfiguration = this.configuration.RootFeatureLayerConfiguration;
        // this.modifyingTree(this.configurationLayers);

        if (this.activatedNode && this.activatedNode.Id) {
          this.activatedNode = this.getLayerById(this.rootFeatureLayerConfiguration, this.activatedNode.Id);
        }

        this.changedNodes = [];
        this._scriptChanged = false;

        this.dataToList();

        this.isConfigurationUpdateInProgress = false;

        this.onFormDataToList();

        this.cdr.markForCheck();
      });
  }

  /**
   * Process methods
   */
  onProcessConfiguration() {
    const coordinateSystem = this.configuration.DataSource.CoordinateSystem;

    const isNativeGis = this.typeOfSource === DataSourceType.NativeGis;
    const isCoordinationFieldInvalid = coordinateSystem === null || coordinateSystem <= 0;
    if (isNativeGis && isCoordinationFieldInvalid) {
      this.snackBar.open('Please specify the projection system (ESPG code)', 'OK', snackBarConfig);
      return;
    }
    this.updateConfiguration()
      .pipe(
        tap(() => {
          if (this.isMultiselectModeActive) {
            this.processMultiselectLayers();

            return;
          }

          this.processConfiguration();
        }),
        takeUntil(this._unsubscribe$),
      )
      .subscribe();
  }

  processMultiselectLayers() {
    /*TODO make request for process multiselect layers*/

    this.isCurrentDSInProcessing = true;

    this.isProcessingStarted = true;

    this.dataSourceService
      .importMultipleLayersData(this.companySlug, this.sourceSlug, this.selectedNodesIds)
      .pipe(
        tap(() => {
          // setTimeout for backend managed refresh data about certain data source

          setTimeout(() => {
            this.getCurrentDataSource();
            this.getProcessingStatus();
          }, 1000);
        }),
        catchError(error => {
          if (this.isBim360SourceType && error.error.Message === AutodeskAuthNotificationCode.B360) {
            this.openBim360AuthNotificationDialog();
          } else if (error.status === 400) {
            this.snackBar.open(error.error.Message, 'OK', snackBarConfig);
          }

          return EMPTY;
        }),
        finalize(() => this.snackBar.open(this._translationMap.get('processingStarted'), 'OK', snackBarConfig)),
      )
      .subscribe();
  }

  onProcessLayerConfiguration(layerId) {
    this.updateConfiguration()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(() => {
        this.processLayerConfiguration(layerId);
      });
  }

  /**
   * Import data request
   */
  processConfiguration() {
    this.isCurrentDSInProcessing = true;

    this.isProcessingStarted = true;

    this.dataSourceService
      .importData(this.companySlug, this.sourceSlug)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(
        data => data,
        error => {
          if (this.isBim360SourceType && error.error.Message === AutodeskAuthNotificationCode.B360) {
            this.openBim360AuthNotificationDialog();
          } else if (error.status === 400) {
            this.snackBar.open(error.error.Message, 'OK', snackBarConfig);
          }
        },
        () => {
          this.snackBar.open(this._translationMap.get('processingStarted'), 'OK', snackBarConfig);

          // setTimeout for backend manage to refresh data about certain data source

          setTimeout(() => {
            this.getCurrentDataSource();
            this.getProcessingStatus();
          }, 1000);
        },
      );
  }

  getProcessingStatus() {
    const interval = setInterval(
      () =>
        this.dataSourceService
          .getProcessingStatus(this.companySlug, this.sourceSlug)
          .pipe(
            tap((value: Record<string, boolean>) => {
              if (!value.Data) {
                clearInterval(interval);

                this.isCurrentDSInProcessing = false;

                this.isProcessingStarted = false;

                this.snackBar.open(this._translationMap.get('processingFinished'), 'OK', snackBarConfig);

                this.resetSelectedNodes();

                this.refreshConfiguration();

                this.cdr.markForCheck();
              }
            }),
            takeUntil(this._unsubscribe$),
          )
          .subscribe(),
      5000,
    );
  }

  getPreparationStatus() {
    if (this._currentDataSource.Type !== DataSourceType.Shapefile) {
      return;
    }

    const interval = setInterval(
      () =>
        this.dataSourceService
          .getPreparationStatus(this.companySlug, this.sourceSlug)
          .pipe(
            tap(value => {
              this.dataSourceStatus = value.Data;

              if (
                this.dataSourceStatus === DataSourceStatus.Completed ||
                this.dataSourceStatus === DataSourceStatus.Failed ||
                this.dataSourceStatus === DataSourceStatus.Cancelled
              ) {
                clearInterval(interval);

                this.snackBar.open(this._translationMap.get('processingFinished'), 'OK', snackBarConfig);

                this.resetSelectedNodes();

                this.refreshConfiguration();

                this.cdr.markForCheck();
              }
            }),
            takeUntil(this._unsubscribe$),
          )
          .subscribe(),
      5000,
    );
  }

  /**
   * Import layer data request.
   */
  processLayerConfiguration(layerId) {
    this.dataSourceService
      .importLayerData(this.companySlug, this.sourceSlug, layerId)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(
        data => data,
        error => {
          if (this.isBim360SourceType && error.error.Message === AutodeskAuthNotificationCode.B360) {
            this.openBim360AuthNotificationDialog();
          } else if (error.status === 400) {
            this.snackBar.open(error.error.Message, 'OK', snackBarConfig);
          }
        },
        () => {
          this.snackBar.open(this._translationMap.get('processingStarted'), 'OK', snackBarConfig);

          // setTimeout for backend managed refresh data about certain data source

          setTimeout(() => {
            this.getCurrentDataSource();
            this.getProcessingStatus();
            this.getPreparationStatus();
          }, 1000);
        },
      );
  }

  /**
   * Has canceling all configuration tree changes
   */
  cancelConfiguration() {
    this.matDialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(ConfirmationDialogComponent, {
        width: '400px',
        height: '188px',
        data: { subtitleKey: 'sources.configuration.changesWillBeLost' },
      })
      .afterClosed()
      .pipe(
        filter(isConfirmAction => !!isConfirmAction),
        tap(() => (this.sourceTeams = [...this.initialSourceTeams])),
        takeUntil(this._unsubscribe$),
      )
      .subscribe(() => {
        // Take old data until copy config for feature layer

        this.configuration = cloneDeep(this.copyConfigFromService.getOldConfigurationStructure());

        this.rootFeatureLayerConfiguration = this.configuration.RootFeatureLayerConfiguration;

        this.modifyingTree(this.rootFeatureLayerConfiguration);

        if (this.activatedNode && this.activatedNode.Id) {
          this.activatedNode = this.getLayerById(this.rootFeatureLayerConfiguration, this.activatedNode.Id);
        }

        this.changedNodes = [];
        this._scriptChanged = false;

        this.isSourceTeamChanged = false;

        this.onFormDataToList();
      });
  }

  /**
   * Canceling changes of activated layer
   * @param {string} Id
   */
  cancelLayer(Id: string) {
    const excludedFields = ['ChildLayers'];

    this.matDialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(ConfirmationDialogComponent, {
        width: '400px',
        height: '188px',
        data: { subtitleKey: 'sources.configuration.layerChangesWillBeLost' },
      })
      .afterClosed()
      .pipe(
        filter(isConfirmAction => !!isConfirmAction),
        takeUntil(this._unsubscribe$),
      )
      .subscribe(() => {
        let originLayer;

        originLayer = this.getLayerById(this._originalData.RootFeatureLayerConfiguration, Id);

        const initialStateOfSubtypes = this.sourceConfigurationService.getNewlyCreatedSubtypes();

        if (!originLayer && initialStateOfSubtypes.length === 0) {
          return;
        }

        if (!originLayer) {
          // in case if we cancel changes in newly created subtype that not have been saved yet

          originLayer = initialStateOfSubtypes.find(subtype => subtype.Id === this.activatedNode.Id);
        }

        const activeLayer = this.getLayerById(this.rootFeatureLayerConfiguration, this.activatedNode.Id);

        this.activatedNode = null;

        each(originLayer, (value, key: string) => {
          if (excludedFields.indexOf(key) !== -1) {
            return;
          }

          activeLayer[key] = cloneDeep(value);
        });

        this.onChangeModels({ changed: false, id: activeLayer.Id });

        activeLayer.changed = false;

        setTimeout(() => {
          this.activatedNode = activeLayer;
        }, 0);
      });
  }

  /**
   * Methods used for Collapsing / Expanding of table branches
   */
  collapseNode(event: any, node) {
    event.stopPropagation();
    this.collapseChildren(node.node);
  }

  collapseChildren(node: SourceConfiguration) {
    find(this.orderableList, { Id: node.Id as any }).expanded = node.expanded = false;

    each(node.ChildLayers, layer => {
      this.collapseChildren(layer);
    });

    const index = this.expandedNodes.indexOf(node.Id);
    if (index > -1) {
      this.expandedNodes.splice(index, 1);
    }

    this.syncScrollWidth();
  }

  expandNode(event: any, node: SourceConfiguration) {
    event.stopPropagation();
    node.expanded = true;
    this.expandedNodes.push(node.Id);

    this.syncScrollWidth();
  }

  /**
   * Actualizing the inputted tree, set expanded branches and other previous decorations
   * Used after canceling of all tree updates
   * @param {Configuration} node
   */
  modifyingTree(node: any) {
    if (this.expandedNodes.length && this.expandedNodes.indexOf(node.Id) !== -1) {
      node.expanded = true;
    }

    node.SubtypesConfigurations = node.SubtypesConfigurations === null ? [] : node.SubtypesConfigurations;

    node.SubtypesConfigurations = node.SubtypesConfigurations.map((subtype: SubtypeConfiguration) => {
      subtype.isSubtype = true;
      subtype.parentId = node.Id;
      subtype.Id = `${node.Id}_${subtype.SubtypeValue}`;
      return subtype;
    });

    node.isLeaf = false;
    node.isBranch = true;

    if (node.ChildLayers && !node.ChildLayers.length) {
      node.isLeaf = true;
      node.isBranch = false;

      return;
    }

    each(node.ChildLayers, layer => {
      this.modifyingTree(layer);
    });

    this.cdr.markForCheck();

    return;
  }

  /**
   * Methods for getting Layers by specific criterias
   */
  getLayerById(source: SourceConfiguration, id: string) {
    const list = this.findLayer(source, { Id: id });
    return list.length ? list[0] : null;
  }

  /**
   * Method for getting leafs or branches of configuration tree by 'fields' of searching criterias,
   * returns array of 'Configurations (Layers)' or empty array
   * @param {Configuration} node
   * @param {Object} fields
   * @param {Configuration[]} resultArr
   * @returns {any[]}
   */
  findLayer(node, fields: object, resultArr?: SourceConfiguration[] | null): any[] {
    if (!isArray(resultArr)) {
      resultArr = [];
    }

    const results = keys(fields).map(key => {
      return { r: has(node, key) && node[key] === fields[key] };
    });

    if (!results.length) {
      return resultArr;
    }

    if (every(results, { r: true })) {
      resultArr.push(node);
    }

    each(node.SubtypesConfigurations, subtype => {
      this.findLayer(subtype, fields, resultArr);
    });

    if (!node.ChildLayers || !node.ChildLayers.length) {
      return resultArr;
    }

    each(node.ChildLayers, layer => {
      this.findLayer(layer, fields, resultArr);
    });

    return resultArr;
  }

  /**
   * Detects any changes of tree on the global level
   * @returns {boolean}
   */
  get isChanged() {
    return !!this.changedNodes.length || this._scriptChanged || this.isSourceTeamChanged;
  }

  showNode(node): boolean {
    return node.isRoot || this.expandedNodes.indexOf(node.parentId) !== -1 || this.sortMode;
  }

  sortData(sort: Sort) {
    if (!sort.active || sort.direction === '') {
      this.sortMode = false;
      this.dataToList();
      return;
    }

    this.sortMode = true;

    this.orderableList.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      return this.compare(a[sort.active], b[sort.active], isAsc);
    });
  }

  compare(a, b, isAsc): number {
    return this.sourceConfigurationService.compare(a, b, isAsc);
  }

  /**
   * Converting tree to the array
   * @param {Configuration} data
   * @returns {Array}
   * @private
   */
  /**
   * List for ordering
   */
  dataToList() {
    this.orderableList = this.treeToList(this.rootFeatureLayerConfiguration);
  }

  treeToList(data: SourceConfiguration) {
    const result = [];

    const lvl = 1;

    data.isRoot = true;

    /**
     * For Adding new row into the array
     * @param node
     * @param {number} level
     * @param {Array<any>} res
     */
    const addLine = (node, level: number, res: Array<any>) => {
      node.hasChildren =
        (node.ChildLayers && node.ChildLayers.length) || (node.SubtypesConfigurations && node.SubtypesConfigurations.length);

      const line = this.makeLine(node, level);

      res.push(line);

      each(node.ChildLayers, (child: SourceConfiguration) => {
        child.parentId = node.Id;
        addLine(child, level + 1, result);
      });

      each(node.SubtypesConfigurations, (subtype: SubtypeConfiguration) => {
        subtype.parentId = node.Id;
        subtype.isSubtype = true;
        addLine(subtype, level + 1, result);
      });
    };

    addLine(data, lvl, result);

    this.cdr.markForCheck();

    return result;
  }

  /**
   * For Creating needed row from the tree node
   * @param data
   * @param level
   */
  makeLine(data, level) {
    return this.sourceConfigurationService.makeLine(data, level, this.tableRows, this.inspectionForms, this.layers);
  }

  onRequestPermission() {
    let mailTo = 'mailto:';

    // concat all receivers to one string for mailto

    this._adminEmails.forEach(email => {
      mailTo += email + ',';
    });

    window.location.href = mailTo;
  }

  onModelProjectionTypeChanged(projectionType: ProjectionType) {
    this.modelProjectionType = projectionType;
  }

  getObjectTypeForSubtype(node: SubtypeConfiguration) {
    if (node.ObjectTypeConfiguration) {
      return node.ObjectTypeConfiguration.ObjectType;
    } else {
      const subtypeParent = this.findLayerForReplace(this.rootFeatureLayerConfiguration.ChildLayers, node.parentId);

      return subtypeParent.ObjectType;
    }
  }

  goToSearchPage() {
    this.router.navigate(['sources']);
  }

  /**
   * Convert hex to rgba string
   * @param {string} color
   * @returns {string}
   */
  getTextColor(color: string): string {
    return this.colorHelperService.getTextColor(color);
  }

  isSuperAdmin() {
    return this.identityService.isSuperAdmin();
  }

  isTeamAdmin() {
    return this.identityService.isTeamAdmin();
  }

  isShowFolderIcon(node: SourceConfiguration) {
    return node.Marker === SourceConfiguration3dModelMarker.None;
  }

  isShowObjectTypeIcon(node: SourceConfiguration) {
    return node.Marker !== SourceConfiguration3dModelMarker.Category;
  }

  selectAllNodes(): void {
    if (this.isAllNodesSelected) {
      this.selectedNodesIds = [];

      return;
    }

    this.selectedNodesIds = this.orderableList.map(node => node.node.Id);
  }

  isShowProcessLayerData() {
    return this.activatedNode && !this.activatedNode.isSubtype && !this.activatedNode.changed && !this.activatedNode.isRoot;
  }

  isSynchronizeButtonDisabled(): boolean {
    const dataSource = this._currentDataSource || {};

    if ('PreparationStatus' in dataSource) {
      const isDSCompleted = this.dataSourceStatus === this.dataSourceStatuses.Completed;

      return this.isVirginiaDataSource || this.isCurrentDSInProcessing || !isDSCompleted;
    }

    return this.isVirginiaDataSource || this.isCurrentDSInProcessing || this.isSyncInProgress;
  }

  isProcessingButtonDisabled(): boolean {
    const dataSource = this._currentDataSource || {};

    if ('PreparationStatus' in dataSource) {
      const isDSCompleted = this.dataSourceStatus === this.dataSourceStatuses.Completed;

      return this.isRealTime || this.isVirginiaDataSource || this.isCurrentDSInProcessing || !isDSCompleted;
    }

    const isUpdateAllowedForTeamAdmin = this.isUpdateAllowedForTeamAdmin();

    return (
      this.isSyncInProgress ||
      !isUpdateAllowedForTeamAdmin ||
      this.isRealTime ||
      this.isVirginiaDataSource ||
      this.isCurrentDSInProcessing ||
      this.isProcessingStarted ||
      (this.isBim360SourceType && !this.isMultiselectModeActive)
    );
  }

  isProcessingFeatureLayerButtonDisabled(): boolean {
    const isUpdateAllowedForTeamAdmin = this.isUpdateAllowedForTeamAdmin();

    return (
      this.isSyncInProgress || !isUpdateAllowedForTeamAdmin || this.isRealTime || this.isVirginiaDataSource || this.isCurrentDSInProcessing
    );
  }

  isCancelButtonDisabled(): boolean {
    return !this.isUpdateAllowedForTeamAdmin();
  }

  isUpdateButtonDisabled(): boolean {
    return !this.isUpdateAllowedForTeamAdmin() || this.isNeedToDisableUpdateButton;
  }

  isCopyConfigOptionDisabled(): boolean {
    return !this.isUpdateAllowedForTeamAdmin();
  }

  isNodeSelected(node) {
    return this.selectedNodesIds.includes(node.Id);
  }

  onSelectNode(event, node) {
    if (node.isBranch) {
      if (node.isRoot) {
        node.ChildLayers.forEach(layer => {
          if (layer.isBranch) {
            layer.ChildLayers.forEach(childLayer => {
              this.onSelectNode(event, childLayer);
            });
          }
          this.onSelectNode(event, layer);
        });
      } else {
        node.ChildLayers.forEach(layer => {
          this.onSelectNode(event, layer);
        });
      }
    }

    if (event.checked) {
      this.selectedNodesIds = [...this.selectedNodesIds, node.Id];
    } else {
      this.selectedNodesIds = this.selectedNodesIds.filter(id => id !== node.Id);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
    this.sourceConfigurationService.setCheckLocationState(false);
    this.configurationHeaderTemplateService.setTemplate(null);
    this.copyConfigFromService.setNewDSAfterCopy(null);
    this.translationHandleService.cleanTranslationArray();
  }

  private openGeneralFormConfirmationDialog(index: number): void {
    this.matDialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(ConfirmationDialogComponent, {
        width: '400px',
        height: '188px',
        data: { subtitleKey: 'sources.configuration.changesWillBeLost' },
      })
      .afterClosed()
      .pipe(
        filter(isConfirmAction => !!isConfirmAction),
        tap(() => {
          this.sourcesFormChanges.isNeedToCancelChanges();
          this.selectedTabIndex = index;
        }),
        takeUntil(this._unsubscribe$),
      )
      .subscribe();
  }

  private getProcessingStatusBySource(dataSource: ISource): void {
    this.isCurrentDSInProcessing = dataSource && dataSource.IsProcessing;

    if (this.isCurrentDSInProcessing && !this.isProcessingStarted) {
      this.getProcessingStatus();
    }

    this.dataSourceStatus = dataSource && dataSource.PreparationStatus;

    if (this.dataSourceStatus === DataSourceStatus.Idle || this.dataSourceStatus === DataSourceStatus.InProgress) {
      this.getPreparationStatus();
    }

    this.cdr.markForCheck();
    this.typeOfSource = dataSource.Type;
    if (dataSource.Type === 'ArcGisFeatureService' || dataSource.Type === 'Wfs') {
      this.isRealTime = dataSource.IsRealTime;
    }
  }

  private onHeaderTemplateChange(): void {
    this.configurationHeaderTemplateService.template$
      .pipe(
        skip(1),
        tap(template => {
          this.isGeneralHeaderTemplate = template !== null;

          if (template === null) {
            this.headerTemplate.clear();
            this.cdr.markForCheck();

            return;
          }

          this.headerTemplate.createEmbeddedView(template);
        }),
        takeUntil(this._unsubscribe$),
      )
      .subscribe();
  }

  private startSyncPolling(): void {
    const syncInterval = setInterval(() => {
      this.dataSourceService
        .getSyncStatus(this.companySlug, this.sourceSlug)
        .pipe(
          switchMap(syncProgressInfo => {
            this.isSyncInProgress = syncProgressInfo.IsSynchronizationInProgress;

            if (syncProgressInfo.SynchronizationResult === SynchronizationResult.Success) {
              clearInterval(syncInterval);

              return this.dataSourceService.configuration(this.companySlug, this.sourceSlug);
            } else {
              clearInterval(syncInterval);
              this.snackBar.open(syncProgressInfo.ErrorMessage, 'OK', snackBarConfig);
            }

            return EMPTY;
          }),
          catchError(err => {
            clearInterval(syncInterval);

            return throwError(err);
          }),
          tap((response: ConfigurationStructure) => {
            this._originalData.RootFeatureLayerConfiguration = cloneDeep(response.RootFeatureLayerConfiguration);
            this._originalData.RootFeatureLayerConfiguration.expanded = true;
            this.expandedNodes.push(this._originalData.RootFeatureLayerConfiguration.Id);
            this.modifyingTree(this._originalData.RootFeatureLayerConfiguration);

            this.configuration.RootFeatureLayerConfiguration = cloneDeep(this._originalData.RootFeatureLayerConfiguration);
            this.rootFeatureLayerConfiguration = this.configuration.RootFeatureLayerConfiguration;

            this.snackBar.open(this._translationMap.get('synchronizeCompleted'), 'OK', snackBarConfig);

            this.onFormDataToList();
          }),
        )
        .subscribe();
    }, 3000);
  }

  private resetSelectedNodes() {
    this.selectedNodesIds = [];
  }

  private updateConfigurationObject(changedNode: ChangedNodeFormContent, subtypeConfiguration?: SubtypeConfiguration) {
    if (this.configuration.RootFeatureLayerConfiguration.Id === changedNode.Id) {
      this.configuration = this.configurationFieldsAdapterService.getDataForRootFeatureLayerConfiguration(
        this.configuration,
        changedNode,
        this._currentDataSource.Type,
      );

      this.updateTableRow(this.changedNodeId);
    } else {
      const workerMessage = new WorkerMessage(WorkerTopic.UpdateNode, {
        configuration: this.configuration,
        changedNode: changedNode,
        subtypeConfiguration,
      });

      this.workerService.doWork(workerMessage);
    }
  }

  private updateNode(changedNode, currentNode) {
    if (currentNode.Id === changedNode.Id) {
      return { ...currentNode, ...changedNode, changed: true };
    }

    if (currentNode.ChildLayers.length > 0) {
      for (let i = 0; i < currentNode.ChildLayers.length; i++) {
        currentNode.ChildLayers = currentNode.ChildLayers.map(childLayer => {
          return this.updateNode(changedNode, childLayer);
        });
      }
    }

    return currentNode;
  }

  private listenForUpdateNodeWorkerResponse() {
    this.workerService.workerUpdate$
      .pipe(
        tap(value => {
          const changedChildLayers = value.data;

          this.configuration.RootFeatureLayerConfiguration.ChildLayers = [...changedChildLayers];

          this.updateTableRow(this.changedNodeId);

          if (this.selectedNodesToUpdateIdsQueue.length > 0) {
            const updatedNode = changedChildLayers.find(layer => layer.Id === this.selectedNodesToUpdateIdsQueue[0]);

            this.selectedNodesToUpdateIdsQueue.splice(updatedNode.Id, 1);
          } else {
            this.selectedNodesToUpdateIdsQueue = [];
          }
        }),
      )
      .subscribe();
  }

  private openBim360AuthNotificationDialog() {
    this.matDialog.open<Bim360AccountDisconnectedComponent, Bim360AccountDisconnectedData, void>(Bim360AccountDisconnectedComponent, {
      width: '400px',
      data: { companySlug: this._currentDataSource.CompanySlug, sourceSlug: this._currentDataSource.Slug },
    });
  }

  private isUpdateAllowedForTeamAdmin(): boolean {
    if (this.isTeamAdmin()) {
      const teamPermissions = this.identityService.teamPermissions;

      const updatePermission = teamPermissions.find(permission => permission.Type === PermissionType.Update);

      return this._currentDataSource?.Scope === Scope.TeamPrivateScope && updatePermission.IsAllowed;
    }

    return true;
  }

  private onCheckLocation(): void {
    let espg;

    this.sourceConfigurationService
      .getCheckLocationState$()
      .pipe(
        filter(Boolean),
        switchMap(() => {
          const changedNode = this.rootFeatureLayerConfiguration.ChildLayers.find(layer => layer.Id === this.activatedNode.Id);
          espg = changedNode.ObjectTypeConfiguration.CoordinateSystem ? changedNode.ObjectTypeConfiguration.CoordinateSystem : null;

          return this.dataSourceService
            .checkLocation(this.companySlug, this.sourceSlug, this.activatedNode.Id, this.rootFeatureLayerConfiguration)
            .pipe(
              catchError(() => {
                this.snackBar.open(this._translationMap.get('checkLocationError'), 'OK', snackBarConfig);

                return EMPTY;
              }),
            );
        }),
        tap(location => this.openLocationMapModal(espg, location)),
        takeUntil(this._unsubscribe$),
      )
      .subscribe();
  }

  private checkSynchronizationStatus(): void {
    this.dataSourceService
      .getSyncStatus(this.companySlug, this.sourceSlug)
      .pipe(
        tap(syncStatus => {
          this.isSyncInProgress = syncStatus.IsSynchronizationInProgress;

          if (this.isSyncInProgress) {
            this.startSyncPolling();
          }
        }),
        takeUntil(this._unsubscribe$),
      )
      .subscribe();
  }

  private handleOpenWithQueryParams(): void {
    const tabLabel = this.route.snapshot.queryParamMap.get('tab');

    if (tabLabel === null) {
      this.onTabChange(null);

      return;
    }

    this.isDisplayHints = tabLabel === ConfigurationTab.Mapping;
    this.selectedTabIndex = this.configurationTabs.find(tab => tab.label === tabLabel).index;
  }

  private openLocationMapModal(espg: number, location: LayerCoordinates) {
    if (location) {
      this.matDialog.open<CheckLocationMapComponent, LocationMapData, undefined>(CheckLocationMapComponent, {
        width: '784px',
        height: '712px',
        panelClass: 'location-map-dialog',
        data: {
          espg,
          location,
        },
      });
    }
  }
}
