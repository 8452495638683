import { Component, Input } from '@angular/core';

@Component({
  selector: 'mee-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent {

  @Input() text: string;

  constructor() { }
}
