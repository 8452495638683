import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Duration } from './duration.enum';
import { IdentityService } from '../../services/identity.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GraphData } from '../../interfaces/graph-data';
import { Observable } from 'rxjs';
import { TelemetryEvent } from '../../shared/interfaces/telemetry-event';

@Injectable()
export class TelemetryService {
  constructor(protected http: HttpClient, private identityService: IdentityService) {
  }

  getTelemetry(period: Duration = Duration.Week) {
    let url = '';
    const companySLug = this.identityService.companySlug;

    url = `${environment.adminApi}/telemetry/companies/${companySLug}/activitystatistic`;

    if (this.identityService.isTeamAdmin()) {
      const teamSlug = this.identityService.teamSlug;

      url = `${environment.adminApi}/telemetry/companies/${companySLug}/teams/${teamSlug}/activitystatistic`
    }

    const params: HttpParams = new HttpParams().append('period', period.toString());

    return this.http.get<GraphData[]>(url, { params: params });
  }

  sendTelemetryData(data: TelemetryEvent): Observable<TelemetryEvent> {
    return this.http.post<TelemetryEvent>(`${environment.adminApi}/telemetry`, data);
  }
}
