import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CopyConfigFromService, IDialogDataForCopyConfigList } from '../../copy-config-from.service';
import { ISourceLight } from '../../company-sources-light.model';
import { TableBuilder } from '../../../../../../../../core/table/table-builder';
import { ITableColumn } from '../../../../../../../../core/table/interfaces/table-column.interface';
import { Subscription } from 'rxjs/internal/Subscription';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'mee-copy-from-sources',
  templateUrl: './copy-from-sources.component.html',
  styleUrls: ['./copy-from-sources.component.scss'],
})
export class CopyFromSourcesComponent implements OnInit {

  @Input() isCopyDSConfig: boolean;

  @Input() dialogData: IDialogDataForCopyConfigList;

  @Output() onChooseSourceCopyItem: EventEmitter<ISourceLight> = new EventEmitter();

  isDataLoaded = false;

  isDSForCopy = false;

  tableBuilder: TableBuilder<ISourceLight>;

  subs: Subscription[] = [];

  constructor(private copyConfigFromService: CopyConfigFromService) {
  }

  ngOnInit() {
    this.initDSList();
  }

  initDSList() {

    if (this.isCopyDSConfig) {

      this.copyConfigFromService.getCompanyDSLightMinimizedVersion(
        this.dialogData.destinationDS.CompanySlug, this.dialogData.destinationDS.Id)
        .pipe(
          finalize(() => {
            this.isDataLoaded = true;
          }),
        ).subscribe((availableForCopyDSList: ISourceLight[]) => this.initTableBuilder(availableForCopyDSList),
        () => this.isDSForCopy = false);
    } else {
      this.copyConfigFromService.getCompanyDSLightVersion(
        this.dialogData.destinationDS.CompanySlug, this.dialogData.destinationDS.Slug, this.dialogData.destinationLayer.Id)
        .pipe(
          finalize(() => {
            this.isDataLoaded = true;
          }),
        ).subscribe((availableForCopyDSList: ISourceLight[]) => this.initTableBuilder(availableForCopyDSList),
        () => this.isDSForCopy = false);
    }
  }


  initTableBuilder(availableForCopyDSList) {

    if (availableForCopyDSList.length) {
      this.isDSForCopy = true;
    }

    const columns: ITableColumn[] = [
      {
        key: 'Slug',
        title: 'sources.listTable.columns.identifier',
      },
      {
        key: 'Type',
        title: 'sources.listTable.columns.type',
      },
    ];

    this.tableBuilder = new TableBuilder<any>({
      list: availableForCopyDSList,
    }, {
      columns: columns,
      rowClickAction: this.onChooseDSForCopy.bind(this),
      pagination: {
        hide: availableForCopyDSList.length <= 7,
      },
    });
  }

  onChooseDSForCopy(sourceForCopy) {
    this.onChooseSourceCopyItem.emit(sourceForCopy);
  }
}
