import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { snackBarConfig } from '../core/core.module';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, Injector } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ExcludedGlobalErrorsLogicUrlsService } from '../shared/excluded-global-errors-logic-urls.service';

@Injectable()
export class RequestErrorsInterceptor implements HttpInterceptor {
  private translateService: TranslateService;

  urlsForExclude = this.injector.get(ExcludedGlobalErrorsLogicUrlsService).getUrls();

  constructor(private snackBar: MatSnackBar, private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<HttpErrorResponse> | any> {
    this.translateService = this.injector.get(TranslateService);

    if (this.urlsForExclude.includes(req.url)) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400 || error.status === 404 || error.status === 403) {
          if (error.error?.Message) {
            this.snackBar.open(error.error?.Message, 'OK', snackBarConfig);
            return EMPTY;
          }
        } else {
          this.translateService.get('errors.error_occurred').subscribe(text => this.snackBar.open(text, 'OK', snackBarConfig));
        }

        return throwError(error);
      }),
    );
  }
}
