// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  background: linear-gradient(180deg, rgb(0, 0, 0) 0, transparent);
  z-index: 1;
  min-height: 120px;
}

.toolbar-wrapper {
  display: flex;
  align-items: center;
  padding: 1em;
}
.toolbar-wrapper ::ng-deep mat-icon {
  margin-right: 30px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/video-dialog/file-preview-overlay-toolbar/file-preview-overlay-toolbar.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;EACA,gEAAA;EACA,UAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EACE,kBAAA;EACA,eAAA;AACJ","sourcesContent":[":host {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  color: #fff;\n  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0, transparent);\n  z-index: 1;\n  min-height: 120px;\n}\n\n.toolbar-wrapper {\n  display: flex;\n  align-items: center;\n  padding: 1em;\n\n  ::ng-deep mat-icon {\n    margin-right: 30px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
