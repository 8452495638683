import { Injectable } from '@angular/core';
import { VerticalDatums } from '../../../../vertical-datums.enum';
import { SelectOption } from './interfaces/select-option';
import { MeasurementSystem } from './interfaces/measurement-system.enum';
import { ObjectType } from './enums/object-types.enum';
import { Point3DShape } from '../../../../point3dshape.enum';
import { Subtype } from './interfaces/subtype';
import { LineSubtype } from './enums/line-subtypes.enum';
import { ChangedNodeFormContent } from './interfaces/changed-node-form-content';
import { DataSourcesAdapterService } from './data-sources-adapter.service';
import { DataCollectionPermissions } from './enums/data-collection-permissions.enum';
import { SourceConfiguration } from './interfaces/source-configuration';
import { ConfigurationFieldsAdaptedDefaultValue } from './interfaces/configuration-fields-adapted-default-value';
import { DataSourceType } from '../../../../data-source-type.enum';
import { IfcCategorizationStrategy } from './enums/ifc-categorization-strategy.enum';
import { AltitudeMode } from './enums/altitude-mode.enum';
import { ConfigurationStructure } from './interfaces/configuration-structure';
import { PointSubtype } from './interfaces/point-subtype';

@Injectable()
export class ConfigurationFieldsAdapterService {
  constructor(private dataSourcesAdapterService: DataSourcesAdapterService) {}

  getVerticalDatumOptions(): SelectOption[] {
    const verticalDatumModel = [];

    const verticalDatumKeys = Object.keys(VerticalDatums).filter(key => {
      return isNaN(Number(key));
    });

    verticalDatumKeys.forEach(key => {
      verticalDatumModel.push({
        title: key,
        value: VerticalDatums[key],
      });
    });

    return verticalDatumModel.sort((a, b) => a.title.localeCompare(b.title));
  }

  getPermissionsOptions(): SelectOption[] {
    return [
      {
        title: 'Add',
        value: DataCollectionPermissions.NewPlacemark,
      },
      {
        title: 'Delete',
        value: DataCollectionPermissions.DeletePlacemark,
      },
      {
        title: 'Update',
        value: DataCollectionPermissions.UpdatePlacemark,
      },
    ];
  }

  getPermissionLabels() {
    return ['Position adjustment', 'Attribute editing', 'New placemarks'];
  }

  getMeasurementSystemOptions(): SelectOption[] {
    return [
      {
        title: 'mm',
        value: MeasurementSystem.Millimeters,
      },
      {
        title: 'cm',
        value: MeasurementSystem.Centimeters,
      },
      {
        title: 'm',
        value: MeasurementSystem.Meters,
      },
      {
        title: 'in',
        value: MeasurementSystem.Inches,
      },
      {
        title: 'ft',
        value: MeasurementSystem.Feet,
      },
      {
        title: 'sft',
        value: MeasurementSystem.FeetUs,
      },
    ];
  }

  getObjectTypesOptions(): SelectOption[] {
    return [
      {
        title: 'configurationTab.objectTypes.unknown',
        value: ObjectType.UNKNOWN,
      },
      {
        title: 'configurationTab.objectTypes.point',
        value: ObjectType.POINT,
      },
      {
        title: 'configurationTab.objectTypes.line',
        value: ObjectType.LINE,
      },
      {
        title: 'configurationTab.objectTypes.area',
        value: ObjectType.AREA,
      },
      {
        title: 'configurationTab.objectTypes.3DModel',
        value: ObjectType.MODEL3D,
      },
    ];
  }

  getPoint3DShapes(): Array<string> {
    let keys = Object.keys(Point3DShape);
    keys = keys.filter(item => isNaN(parseInt(item, 10)));
    keys = keys.sort((a, b) => a.localeCompare(b));
    return keys;
  }

  getPointSubtypes(): Subtype[] {
    return [
      {
        id: PointSubtype.OBJECT,
        title: 'configurationTab.pointSubtypes.object',
        systemName: 'Object',
      },
      {
        id: PointSubtype.MANHOLE,
        title: 'configurationTab.pointSubtypes.manhole',
        systemName: 'Manhole',
      },
      {
        id: PointSubtype.CUSTOM,
        title: 'configurationTab.pointSubtypes.custom',
        systemName: 'Custom',
      },
    ];
  }

  getLineSubtypes(): Subtype[] {
    return [
      {
        id: LineSubtype.PIPE,
        title: 'configurationTab.lineSubtypes.pipe',
        systemName: 'Pipe',
      },
      {
        id: LineSubtype.LINE,
        title: 'configurationTab.lineSubtypes.line',
        systemName: 'Pipe',
      },
    ];
  }

  getAltitudeModesOptions(): SelectOption[] {
    return [
      {
        title: 'configurationTab.default',
        value: AltitudeMode.InheritFromParent,
      },
      {
        title: 'configurationTab.altitudeMode.seaLevel',
        value: AltitudeMode.Absolute,
      },
      {
        title: 'configurationTab.altitudeMode.surface',
        value: AltitudeMode.RelativeToGround,
      },
      {
        title: 'configurationTab.altitudeMode.ingoreZ',
        value: AltitudeMode.ClampToGround,
      },
    ];
  }

  getIfcCategorizationStrategyOptions(): SelectOption[] {
    return [
      {
        title: 'configurationTab.IFcCategorizationStrategy.Presentation',
        value: IfcCategorizationStrategy.Presentation,
      },
      {
        title: 'configurationTab.IFcCategorizationStrategy.BuildingName',
        value: IfcCategorizationStrategy['Building Name'],
      },
      {
        title: 'configurationTab.IFcCategorizationStrategy.ElementName',
        value: IfcCategorizationStrategy['Element Name'],
      },
    ];
  }

  getDataForRootFeatureLayerConfiguration(
    existingConfiguration: ConfigurationStructure,
    changedNode: ChangedNodeFormContent,
    sourceType: DataSourceType,
  ) {
    let newConfiguration;

    const localChangedNode = { ...changedNode };

    const updatedSourceConfig = {
      ...localChangedNode,
    };

    delete localChangedNode.CoordinateSystem;
    delete localChangedNode.MeasurementSystem;
    delete localChangedNode.IsMeshInteractionEnabled;
    delete localChangedNode.FullModel;

    const existingObjectTypeConfiguration = existingConfiguration.RootFeatureLayerConfiguration.ObjectTypeConfiguration;

    const rootFeatureLayerConfiguration = existingConfiguration.RootFeatureLayerConfiguration;

    let objectType = existingObjectTypeConfiguration ? existingObjectTypeConfiguration.ObjectType : null;

    if (objectType === null) {
      objectType = rootFeatureLayerConfiguration.ObjectType;
    }

    let objectTypeConfiguration = null;

    let dataSourceConfiguration = {
      ...existingConfiguration.DataSource,
    };

    if (this.dataSourcesAdapterService.isNativeGisType(sourceType)) {
      dataSourceConfiguration = {
        ...dataSourceConfiguration,
        CoordinateSystem: updatedSourceConfig.CoordinateSystem,
        MeasurementSystem: updatedSourceConfig.MeasurementSystem,
      };

      objectTypeConfiguration = null;
    } else if (
      this.dataSourcesAdapterService.isNativeBimType(sourceType) ||
      this.dataSourcesAdapterService.isBim360Type(sourceType) ||
      this.dataSourcesAdapterService.isPix4dType(sourceType)
    ) {
      objectTypeConfiguration = {
        ...rootFeatureLayerConfiguration.ObjectTypeConfiguration,
        ObjectType: objectType,
        CoordinateSystem: updatedSourceConfig.CoordinateSystem,
        MeasurementSystem: updatedSourceConfig.MeasurementSystem,
        FullModel: updatedSourceConfig.FullModel,
        IsMeshInteractionEnabled: updatedSourceConfig.IsMeshInteractionEnabled,
        IsLocalCrs: updatedSourceConfig.IsLocalCrs,
      };
    } else if (this.dataSourcesAdapterService.isArcGisSceneType(sourceType) || this.dataSourcesAdapterService.isIModelType(sourceType)) {
      objectTypeConfiguration = {
        ...rootFeatureLayerConfiguration.ObjectTypeConfiguration,
        ObjectType: objectType,
        FullModel: updatedSourceConfig.FullModel,
        IsMeshInteractionEnabled: updatedSourceConfig.IsMeshInteractionEnabled,
      };
    } else {
      dataSourceConfiguration = {
        ...dataSourceConfiguration,
      };

      objectTypeConfiguration = null;
    }

    newConfiguration = {
      RootFeatureLayerConfiguration: {
        ...rootFeatureLayerConfiguration,
        ...localChangedNode,
        ObjectTypeConfiguration: objectTypeConfiguration,
      },
      DataSource: dataSourceConfiguration,
    };

    return newConfiguration;
  }

  getAdaptedDefaultValues(node: SourceConfiguration): ConfigurationFieldsAdaptedDefaultValue {
    let adaptedDefaultValues;

    if (node.AltitudeMode === null) {
      adaptedDefaultValues = {
        AltitudeMode: AltitudeMode.InheritFromParent,
      };
    }

    return adaptedDefaultValues;
  }
}
