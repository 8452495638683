import {Component, Input, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SourceConfiguration} from '../../interfaces/source-configuration';
import {ILayer} from '../../../../../../../components/layers/shared/models/layer.interface';
import {ConfigurationFields} from '../../enums/configuration-fields.enum';
import {Subject} from 'rxjs';
import {DataSourceType} from '../../../../../../data-source-type.enum';
import {PlacemarksFields} from '../../../../models/placemarks-fields';
import {ConfigurationFieldsAdapterService} from '../../configuration-fields-adapter.service';
import {FeatureLayerType} from '../../interfaces/feature-layer-type.enum';
import isEqual from 'lodash-es/isEqual';
import {isDisplayForCurrentSourceType} from '../../is-display-for-current-source-type.function';

@Component({
  selector: 'mee-area-subtype-configuration',
  templateUrl: './area-subtype-configuration.component.html',
  styleUrls: ['./area-subtype-configuration.component.scss']
})
export class AreaSubtypeConfigurationComponent implements OnDestroy {

  form: UntypedFormGroup;

  localNode: SourceConfiguration;

  localLayers: ILayer[];

  configurationFields = ConfigurationFields;

  tooltipTextForSubtypeField: string;

  objectTypesOptions = this.configurationFieldsAdapterService.getObjectTypesOptions();

  altitudeModesOptions = this.configurationFieldsAdapterService.getAltitudeModesOptions();

  private destroy$ = new Subject<void>();

  @Input() sourceType: DataSourceType;

  @Input() placeMarksFields: PlacemarksFields;

  @Input() set layers(layers: ILayer[]) {

    this.localLayers = layers;

    if (layers.length === 0) {
      this.form.get('Layer').disable();
    }
  };

  get layers() {
    return this.localLayers;
  }

  @Input() set node(node: SourceConfiguration) {

    if (node === undefined) {
      return;
    }

    if (isEqual(this.node, node)) {
      return;
    }

    this.localNode = node;

    this.form.patchValue(this.node, { emitEvent: false });

    this.setDefaultAdaptedValues(node);
  }

  get node() {
    return this.localNode;
  }

  get isTitleNotEmpty() {
    return this.form.get('Title').value !== null;
  }

  get isNodeHaveSublayer() {
    return this.node.SubtypesConfigurations.length > 0;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private configurationFieldsAdapterService: ConfigurationFieldsAdapterService) {
    this.initForm();
  }

  isDisplayForCurrentSourceType(fieldName: ConfigurationFields) {
    return isDisplayForCurrentSourceType(fieldName, FeatureLayerType.Area, this.sourceType);
  }

  clearTitle() {
    this.form.get('Title').reset();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      Id: [],
      Layer: [],
      Title: [],
      TitleField: [],
      MainColor: [],
      DefaultDepth: [],
      DepthField: [],
      ObjectTypeConfiguration: this.formBuilder.group({
        ObjectType: [{ value: null, disabled: true }],
        DefaultHeight: [],
        HeightField: [],
      }),
      LabelConfiguration: [],
      PlacemarkImage: []
    });
  }

  private setDefaultAdaptedValues(node: SourceConfiguration) {
    const adaptedDefaultValues = this.configurationFieldsAdapterService.getAdaptedDefaultValues(node);

    if (node.AltitudeMode === null) {
      this.form.get('AltitudeMode').setValue(adaptedDefaultValues.AltitudeMode, {emitEvent: false});
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
