// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cropper-js-container img {
  max-height: 70vh;
  max-width: 100%;
  min-height: 40vh;
  min-width: 40vw;
  height: auto;
  width: auto;
}
.cropper-js-container .buttons {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.cropper-js-container .buttons button {
  height: 36px;
  margin: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/core/infocard-images/image-crop/image-crop.component.scss"],"names":[],"mappings":"AAEE;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;AADJ;AAIE;EACE,iBAAA;EACA,WAAA;EACA,aAAA;EACA,yBAAA;AAFJ;AAII;EACE,YAAA;EACA,WAAA;AAFN","sourcesContent":[".cropper-js-container {\n\n  img {\n    max-height: 70vh;\n    max-width: 100%;\n    min-height: 40vh;\n    min-width: 40vw;\n    height: auto;\n    width: auto;\n  }\n\n  .buttons {\n    padding-top: 20px;\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n\n    button {\n      height: 36px;\n      margin: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
