import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { Scope } from '../../../modules/components/models/scope.enum';
import { forkJoin, Observable, Subject } from 'rxjs';
import { ICompany } from '../../../modules/companies/shared/interfaces/company.interface';
import { TeamInterface } from '../../../modules/teams/models/team.interface';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IdentityService } from '../../../services/identity.service';
import { CompaniesService } from '../../../modules/companies/companies.service';
import { TeamsService } from '../../../modules/teams/services/teams.service';
import { TranslationHandleService } from '../../../core/translate/translation-handle.service';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SourcesFormChangesService } from '../../../modules/sources/services/sources-form-changes.service';

const PHRASES_FOR_TRANSLATE = [
  {
    errorOccurred: 'errors.error_occurred',
  },
  {
    companyScopeTooltip: 'permissions.companyScopeTooltip',
  },
  {
    teamPublicScopeTooltip: 'permissions.teamPublicScopeTooltip',
  },
  {
    teamPrivateScopeTooltip: 'permissions.teamPrivateScopeTooltip',
  },
];

@Component({
  selector: 'mee-permissions-fields-ta',
  templateUrl: './permissions-fields-ta.component.html',
  styleUrls: ['./permissions-fields-ta.component.scss'],
})
export class PermissionsFieldsTaComponent implements OnInit, OnDestroy {
  @Input() selectedItem: any;

  @Output() changePermissionsData = new EventEmitter<any>();

  @Input() slugFieldTemplateRef: TemplateRef<any>;
  @Input() nameFieldTemplateRef: TemplateRef<any>;
  @Input() descriptionFieldTemplateRef: TemplateRef<any>

  scopes = Scope;

  filteredCompaniesData$: Observable<ICompany[]>;
  filteredTeamsData$: Observable<TeamInterface[]>;

  isPrivateVisibilityDisabled: boolean;

  form: UntypedFormGroup;

  isShowWarningAboutSetPublic: boolean;

  isShowTeamField: boolean;

  destroy$ = new Subject<void>();

  private localIsUpdateMode: boolean;

  private selectedCompany: ICompany | { Slug: string; Name: string };

  private selectedTeam: TeamInterface | { Id: string; Name?: string };

  private translationMap: Map<string, string>;

  @Input() set isUpdateMode(isUpdateMode: boolean) {
    this.localIsUpdateMode = isUpdateMode;
  }

  get isUpdateMode() {
    return this.localIsUpdateMode;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private identityService: IdentityService,
    private companiesService: CompaniesService,
    private teamsService: TeamsService,
    private translationHandleService: TranslationHandleService,
    private sourcesFormChangesService: SourcesFormChangesService
  ) {
    translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);

    translationHandleService.getTranslationMap().subscribe((translationMap: Map<string, string>) => {
      this.translationMap = translationMap;
    });
  }

  get isShowScopeTooltip(): boolean {
    return this.form.get('Scope').value !== null;
  }

  ngOnInit(): void {
    this.initForm();

    this.getCompanyAndTeamForTeamAdmin();
    this.sourcesFormChangesService.isNeedToCancelChanges$.pipe(
      filter(isNeedToCancel => isNeedToCancel),
      tap(() => this.patchValuesWhileUpdate()),
      takeUntil(this.destroy$)
    ).subscribe()
  }

  displayTeamProperty(team: TeamInterface) {
    if (team) {
      return team.Name;
    }
  }

  getScopeTooltipText(): string {
    const currentScope = this.form.get('Scope').value;

    if (currentScope === Scope.CompanyScope) {
      return this.translationMap.get('companyScopeTooltip');
    } else if (currentScope === Scope.TeamPublicScope) {
      return this.translationMap.get('teamPublicScopeTooltip');
    } else {
      return this.translationMap.get('teamPrivateScopeTooltip');
    }
  }

  displayCompanyProperty(company: ICompany) {
    if (company) {
      return company.Name;
    }
  }

  private initForm() {
    this.form = this.formBuilder.group({
      CompanySlug: [{ value: null, disabled: true }, [Validators.required]],
      OwnerTeamId: [{ value: null, disabled: true }, [Validators.required]],
      Scope: [{value: null, disabled: true}],
    });
  }

  private patchValuesWhileCreate() {
    this.form.get('CompanySlug').setValue(this.selectedCompany);
    this.form.get('Scope').setValue(Scope.TeamPrivateScope);
    this.onChangePermissionsData();
  }

  private patchValuesWhileUpdate() {
    this.form.get('CompanySlug').disable({ emitEvent: false });

    if (this.selectedItem.OwnerTeamId !== null) {
      this.setOwnerTeamId();
    }

    this.form.get('Scope').setValue(this.selectedItem.Scope, { emitEvent: false });

    this.isShowTeamField = this.selectedItem.Scope !== Scope.CompanyScope;

    this.isPrivateVisibilityDisabled = this.selectedItem.Scope !== Scope.TeamPrivateScope;
  }

  private getCompanyAndTeamForTeamAdmin() {
    const companySlug = this.identityService.companySlug;
    const teamSlug = this.identityService.teamSlug;

    forkJoin({
      company: this.companiesService.get(companySlug),
      team: this.teamsService.getTeam(companySlug, teamSlug),
    })
      .pipe(
        tap(value => {
          this.selectedCompany = { Slug: value.company.Slug, Name: value.company.Name };
          this.selectedTeam = { Id: value.team.Id, Slug: value.team.Slug, Name: value.team.Name };

          this.form.get('CompanySlug').setValue(this.selectedCompany);

          this.form.get('OwnerTeamId').setValue(this.selectedTeam);

          if (this.isUpdateMode) {
            this.patchValuesWhileUpdate();
          } else {
            this.patchValuesWhileCreate();
          }
        }),
        switchMap(() => this.form.valueChanges),
        tap(() => this.onChangePermissionsData()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private onChangePermissionsData() {
    const formValue = this.form.getRawValue();

    const formCompany = this.form.get('CompanySlug').value;

    const formOwnerTeam = this.form.get('OwnerTeamId').value;

    const permissionsData = { ...formValue, CompanySlug: formCompany.Slug, OwnerTeamId: formOwnerTeam.Id };

    this.changePermissionsData.emit(permissionsData);
  }

  private setOwnerTeamId() {
    if (this.selectedItem.OwnerTeamId && this.selectedItem.OwnerTeamName) {
      this.selectedTeam = { Id: this.selectedItem.OwnerTeamId, Name: this.selectedItem.OwnerTeamName };
    }

    this.form.get('OwnerTeamId').setValue(this.selectedTeam, { emitEvent: false });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
