import { Component, Inject, Input, OnInit } from '@angular/core';
import { TableBuilder } from '../../../../../../core/table/table-builder';
import { ITableColumn } from '../../../../../../core/table/interfaces/table-column.interface';
import each from 'lodash-es/each';
import map from 'lodash-es/map';
import { PlacemarksFields } from '../../models/placemarks-fields';
import { GeometryType } from '../../../../../data-collection/attributes/enums/geometry-type.enum';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mee-placemarks-table-tab',
  templateUrl: './placemarks-table-tab.component.html',
  styleUrls: ['./placemarks-table-tab.component.scss'],
})
export class PlacemarksTableTabComponent implements OnInit {
  public tableBuilder: TableBuilder<any>;
  list: any[] = [];
  columns: ITableColumn[];

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
    console.log('g');
  }

  ngOnInit() {
    this.columns = this.getColumns(this.data.fields);
    this.list = this.createList(this.data.fields?.TableKeys, this.data.placemarks);

    this.tableBuilder = new TableBuilder<any>(
      {
        list: this.list,
      },
      {
        columns: this.columns,
        pagination: {
          hide: true,
        },
      },
    );
  }

  getColumns(columns: PlacemarksFields): ITableColumn[] {
    const headerCols = [
      {
        key: 'Id',
        title: 'ID',
        sortable: true,
      },
    ];

    if (!columns) {
      return;
    }

    columns.PrimaryFields.forEach((item, index) => {
      headerCols.push({
        key: columns.TableKeys[index],
        title: item,
        sortable: true,
      });
    });

    return headerCols;
  }

  createList(columns: string[], data: any[]): any[] {
    return map(data, obj => {
      const row = {
        Id: obj.Id,
      };

      each(columns, col => {
        const sourceProperties = obj.DynamicPropertiesLabels || obj.DynamicProperties;
        row[col] = sourceProperties[col] || '';

        if (col === 'Geometry') {
          row[col] = this.getGeometryData(obj.Geometry);
        }
      });

      return row;
    });
  }

  getGeometryData(geometryData): string {
    if (!geometryData) {
      return;
    }

    const geometryTypes = Object.keys(GeometryType).filter(type => isNaN(Number(type)));

    let orderedGeometryDataObjects = [];
    let orderedGeometryDataObject = {};

    if (geometryData.hasOwnProperty('Points')) {
      geometryData.Points.forEach((point: any, index) => {
        // First three points string it`s 156 symbols

        if (index <= 2) {
          orderedGeometryDataObject = {
            Latitude: point.Latitude,
            Longitude: point.Longitude,
            Altitude: point.Altitude,
            GeometryType: geometryTypes[point.GeometryType],
          };

          orderedGeometryDataObjects.push(orderedGeometryDataObject);
        }
      });

      orderedGeometryDataObjects = orderedGeometryDataObjects.map(obj => {
        return Object.values(obj).join(', ');
      });

      return orderedGeometryDataObjects.join('<br>');
    } else {
      orderedGeometryDataObject = {
        Latitude: geometryData.Latitude,
        Longitude: geometryData.Longitude,
        Altitude: geometryData.Altitude,
        GeometryType: geometryTypes[geometryData.GeometryType],
      };

      return Object.values(orderedGeometryDataObject).join(', ');
    }
  }
}
