import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { IdentityService } from '../../services/identity.service';
import { TeamProjectService } from '../../services/team-project.service';
import { CompaniesService } from '../../modules/companies/companies.service';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TeamProject } from '../../interfaces/team-project';
import { UntypedFormControl } from '@angular/forms';
import { ICompany } from '../../modules/companies/shared/interfaces/company.interface';

@Component({
  selector: 'mee-team-project-selector',
  templateUrl: './team-project-selector.component.html',
  styleUrls: ['./team-project-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamProjectSelectorComponent implements OnDestroy {
  isDropdownOpen = false;
  overlayPosition = { x: 178, y: -48 }
  availableCompanies: TeamProject[] = [];
  availableTeams: TeamProject[] = [];
  searchControl = new UntypedFormControl();

  private destroy$ = new Subject<void>();
  private teamProjects: TeamProject[];

  get isCompanies(): boolean {
    return this.availableCompanies.length !== 0;
  }

  get isTeams(): boolean {
    return this.availableTeams.length !== 0;
  }

  get isSearchProjectsEmpty(): boolean {
    return !this.isCompanies && !this.isTeams && this.searchControl.value.length > 0;
  }

  constructor(
    private readonly identityService: IdentityService,
    readonly teamProjectService: TeamProjectService,
    private readonly companiesService: CompaniesService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.initTeamProjects();
    this.initSearch();

    this.teamProjectService.isNeedToUpdateTeamProjectSelector$.pipe(
      filter(Boolean),
      tap(() => this.initTeamProjects()),
      takeUntil(this.destroy$),
    ).subscribe()
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectTeamProject(teamProject: TeamProject) {
    this.teamProjectService.setTeamProject(teamProject);
    this.toggleDropdown();
  }

  toggleDropdown(): void {
    if (!this.teamProjects || this.teamProjects.length === 0) {
      return;
    }

    this.isDropdownOpen = !this.isDropdownOpen;
  }

  private initSearch(): void {
    this.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap((searchValue: string) => {
        const filteredTeamProjects = this.teamProjects.filter(teamProject => {
          const searchText = searchValue.toLowerCase();

          return teamProject.CompanyName.toLowerCase().includes(searchText) || teamProject.TeamName?.includes(searchText);
        });

        this.setCompanyTeamsProjects(filteredTeamProjects);
        this.cdr.markForCheck();
      }),
      takeUntil(this.destroy$)
    ).subscribe()
  }

  private initTeamProjects(): void {
    if (this.identityService.isSuperAdmin()) {
      this.companiesService.list().pipe(
        tap((companies: ICompany[]) => {
          const sortedCompanies = this.companiesService.getSortedCompaniesList(companies);

          this.teamProjects = sortedCompanies.map(company => {
            return { CompanySlug: company.Slug, CompanyName: company.Name, CompanyId: company.Id } as TeamProject;
          });

          this.setCompanyTeamsProjects(this.teamProjects);
          const companySlugFromRoute = this.teamProjectService.companySlugFromRoute;

          if (companySlugFromRoute) {
            const companyFromRoute = this.availableCompanies.find(company => company.CompanySlug === companySlugFromRoute);
            this.teamProjectService.setTeamProject(companyFromRoute);
          }
        }),
        takeUntil(this.destroy$)
      ).subscribe();

      return;
    }

    this.teamProjects = this.identityService.userAdminsRoles.map(role => this.teamProjectService.transformRole(role));
    this.setCompanyTeamsProjects(this.teamProjects);
  }

  private setCompanyTeamsProjects(teamProjects: TeamProject[]): void {
    this.availableCompanies = teamProjects.filter(teamProject => !teamProject.TeamSlug);
    this.availableTeams = teamProjects.filter(teamProject => teamProject.TeamSlug);
  }
}
