// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[0]!../../../../../node_modules/@angular-devkit/build-angular/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[6].rules[0].oneOf[1].use[1]!../../../../../node_modules/swiper/swiper-bundle.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .field-report-images .mat-dialog-container {
  padding: 0;
}

.modal-wrapper {
  overflow: hidden;
  position: relative;
  padding: 44px 32px 32px;
  min-width: 300px;
  min-height: 300px;
}
.modal-wrapper .close {
  right: 4px;
  top: 4px;
  position: absolute;
}
.modal-wrapper .swiper-place .swiper-button-prev, .modal-wrapper .swiper-place .swiper-button-next {
  position: absolute;
  top: 50%;
}
.modal-wrapper .swiper-place .swiper-button-next {
  right: 8px;
}
.modal-wrapper .swiper-place .swiper-button-prev {
  left: 8px;
}
.modal-wrapper .swiper-place .swiper .swiper-wrapper .swiper-slide {
  display: flex;
  justify-content: center;
}
.modal-wrapper .swiper-place .swiper .swiper-wrapper .swiper-slide img, .modal-wrapper .swiper-place .swiper .swiper-wrapper .swiper-slide video {
  max-height: 80vh;
  max-width: 80vw;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/image-popup/image-popup.component.scss"],"names":[],"mappings":"AAII;EACE,UAAA;AAFN;;AAOA;EACE,gBAAA;EACA,kBAAA;EACA,uBAAA;EACA,gBAAA;EACA,iBAAA;AAJF;AAME;EACE,UAAA;EACA,QAAA;EACA,kBAAA;AAJJ;AAQI;EACE,kBAAA;EACA,QAAA;AANN;AASI;EACE,UAAA;AAPN;AAUI;EACE,SAAA;AARN;AAaQ;EACE,aAAA;EACA,uBAAA;AAXV;AAaU;EACE,gBAAA;EACA,eAAA;AAXZ","sourcesContent":["@import \"swiper/swiper-bundle.css\";\n\n::ng-deep {\n  .field-report-images {\n    .mat-dialog-container {\n      padding: 0;\n    }\n  }\n}\n\n.modal-wrapper {\n  overflow: hidden;\n  position: relative;\n  padding: 44px 32px 32px;\n  min-width: 300px;\n  min-height: 300px;\n\n  .close {\n    right: 4px;\n    top: 4px;\n    position: absolute;\n  }\n\n  .swiper-place {\n    .swiper-button-prev, .swiper-button-next {\n      position: absolute;\n      top: 50%;\n    }\n\n    .swiper-button-next {\n      right: 8px;\n    }\n\n    .swiper-button-prev {\n      left: 8px;\n    }\n\n    .swiper {\n      .swiper-wrapper {\n        .swiper-slide {\n          display: flex;\n          justify-content: center;\n\n          img, video {\n            max-height: 80vh;\n            max-width: 80vw;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
