import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class LoadingService {
  private loadingStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  run() {
    this.loadingStatus$.next(true);
  }

  stop() {
    this.loadingStatus$.next(false);
  }

  isLoading$(): Observable<boolean> {
    return this.loadingStatus$.asObservable();
  }

  getLoadingStatusValue(): boolean {
    return this.loadingStatus$.value;
  }
}
