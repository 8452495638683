// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cell-image {
  max-width: 90%;
  max-height: 39px;
  padding: 4px 8px 1px;
}

.cell-color-icon {
  margin: 2px 2px 2px 8px;
  width: 36px;
  height: 36px;
  border: 1px solid transparent;
  overflow: hidden;
  border-radius: 18px;
}

:host {
  position: relative;
  word-break: break-all;
  vertical-align: top;
  display: inline-block;
}
:host .cell-text {
  padding: 5px 8px;
}
:host::ng-deep .cell-text:hover + .mee-table-tooltip {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s, visibility 0.5s;
}
:host::ng-deep .mee-table-tooltip {
  z-index: 1;
  position: absolute;
  left: 0;
  top: calc(100% + 5px);
  border: 1px solid #2B303B;
  border-radius: 5px;
  background: #2B303B;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s;
}
:host::ng-deep .mee-table-tooltip ul {
  font-size: 0.7rem;
  padding: 5px;
  margin: 0;
  list-style-type: none;
  color: #fff;
}
:host::ng-deep .mee-table-tooltip ul li {
  margin: 2px 0;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/core/table/table-cell-content/table-cell-content.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,gBAAA;EACA,oBAAA;AACF;;AAEA;EACE,uBAAA;EACA,WAAA;EACA,YAAA;EACA,6BAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAGA;EACE,kBAAA;EACA,qBAAA;EACA,mBAAA;EACA,qBAAA;AAAF;AAEE;EACE,gBAAA;AAAJ;AAII;EACE,mBAAA;EACA,UAAA;EACA,yCAAA;AAFN;AAKI;EACE,UAAA;EACA,kBAAA;EACA,OAAA;EACA,qBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,UAAA;EACA,yCAAA;AAHN;AAKM;EACE,iBAAA;EACA,YAAA;EACA,SAAA;EACA,qBAAA;EACA,WAAA;AAHR;AAKQ;EACE,aAAA;EACA,mBAAA;AAHV","sourcesContent":[".cell-image {\n  max-width: 90%;\n  max-height: 39px;\n  padding: 4px 8px 1px;\n}\n\n.cell-color-icon {\n  margin: 2px 2px 2px 8px;\n  width: 36px;\n  height: 36px;\n  border: 1px solid transparent;\n  overflow: hidden;\n  border-radius: 18px;\n}\n\n\n:host {\n  position: relative;\n  word-break: break-all;\n  vertical-align: top;\n  display: inline-block;\n\n  .cell-text {\n    padding: 5px 8px;\n  }\n\n  &::ng-deep {\n    .cell-text:hover + .mee-table-tooltip {\n      visibility: visible;\n      opacity: 1;\n      transition: opacity 0.5s, visibility 0.5s;\n    }\n\n    .mee-table-tooltip {\n      z-index: 1;\n      position: absolute;\n      left: 0;\n      top: calc(100% + 5px);\n      border: 1px solid #2B303B;\n      border-radius: 5px;\n      background: #2B303B;\n      visibility: hidden;\n      opacity: 0;\n      transition: opacity 0.5s, visibility 0.5s;\n\n      ul {\n        font-size: 0.7rem;\n        padding: 5px;\n        margin: 0;\n        list-style-type: none;\n        color: #fff;\n\n        li {\n          margin: 2px 0;\n          white-space: nowrap;\n        }\n      }\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
