import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ExportDataService } from '../export-data.service';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ExportData } from '../export-data';
import { IdentityService } from '../../../../services/identity.service';
import { DateRange } from '../../../interfaces/date-range';

@Component({
  selector: 'mee-export-data',
  templateUrl: './export-data-modal.component.html',
  styleUrls: ['./export-data-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportDataModalComponent implements OnInit, OnDestroy {
  exportDataFromGroup: UntypedFormGroup;

  rangeOptions: DateRange[] = [
    {
      title: 'exportDataModal.dateRanges.last7Days',
      count: -7,
      interval: 'days',
      selected: false,
    },
    {
      title: 'exportDataModal.dateRanges.last15Days',
      count: -15,
      interval: 'days',
      selected: false,
    },
    {
      title: 'exportDataModal.dateRanges.last30Days',
      count: -30,
      interval: 'days',
      selected: false,
    },
    {
      title: 'exportDataModal.dateRanges.last3Month',
      count: -3,
      interval: 'month',
      selected: false,
    },
    {
      title: 'exportDataModal.dateRanges.last12Month',
      count: -12,
      interval: 'month',
      selected: false,
    },
  ];

  destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { title: string; typeOfData: ExportData['typeOfData'] },
    private dialogRef: MatDialogRef<ExportDataModalComponent>,
    private exportDataService: ExportDataService,
    private identityService: IdentityService,
  ) {}

  ngOnInit(): void {
    this.initExportDataFromGroup();
  }

  initExportDataFromGroup() {
    this.exportDataFromGroup = new UntypedFormGroup({
      startDate: new UntypedFormControl(null, [Validators.required]),
      endDate: new UntypedFormControl(null, [Validators.required]),
      includeArchived: new UntypedFormControl(false),
    });
  }

  onSelectDateRange(range: DateRange) {
    this.rangeOptions.forEach(option => (option.selected = false));

    range.selected = true;

    this.setDateRange(range);
  }

  setDateRange(selectedRange: DateRange) {
    const momentRange = extendMoment(moment);

    const selectedRangeDates = momentRange.rangeFromInterval(selectedRange.interval, selectedRange.count, moment(Date.now()));

    const startDate = selectedRangeDates.toDate()[0];
    const endDate = selectedRangeDates.toDate()[1];

    this.exportDataFromGroup.controls.startDate.setValue(startDate);
    this.exportDataFromGroup.controls.endDate.setValue(endDate);
  }

  onExportData() {
    const typeOfData = this.dialogData.typeOfData;
    const from = moment(this.exportDataFromGroup.controls.startDate.value).format('YYYY-MM-DD') + 'T00:00:00Z';
    const to = moment(this.exportDataFromGroup.controls.endDate.value).format('YYYY-MM-DD') + 'T23:59:00Z';
    const includeArchived = this.exportDataFromGroup.controls.includeArchived.value;

    const data = { typeOfData, from, to, includeArchived };

    this.exportDataService
      .exportData(data)
      .pipe(tap((CSVString: string) => this.downloadCSVFile(CSVString)))
      .subscribe();
  }

  closeModalWindow() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private downloadCSVFile(CSVString: string) {
    const a = document.createElement('a');

    a.href = 'data:attachment/csv,' + encodeURIComponent(CSVString);
    a.target = '_blank';
    a.download = 'export.csv';

    document.body.appendChild(a);
    a.click();
  }
}
