import { ConfigurationFields } from './enums/configuration-fields.enum';
import { DataSourceType } from '../../../../data-source-type.enum';
import { CONFIGURATION_FIELDS_DISABLE_SOURCE_TYPES } from './models/configuration-fields-disable-source-types.model';
import { FeatureLayerType } from './interfaces/feature-layer-type.enum';

export function isDisableForCurrentSourceType(
  fieldName: ConfigurationFields,
  featureLayerType: FeatureLayerType,
  currentSourceType: DataSourceType,
) {
  let isDisable = false;

  // check for fields that are exist in form group but don`t exist as configuration field (Id for example)

  if (CONFIGURATION_FIELDS_DISABLE_SOURCE_TYPES[fieldName] === undefined) {
    return;
  }

  const sourceTypesForDisplay = CONFIGURATION_FIELDS_DISABLE_SOURCE_TYPES[fieldName][featureLayerType];

  if (sourceTypesForDisplay === undefined) {
    return;
  }

  sourceTypesForDisplay.forEach(sourceTypeForDisplay => {
    if (sourceTypeForDisplay === currentSourceType) {
      isDisable = true;
    }
  });

  return isDisable;
}
