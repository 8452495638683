import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  private readonly isDropdownOpened = new BehaviorSubject(false);

  get isOpened$(): Observable<boolean> {
    return this.isDropdownOpened.asObservable();
  }

  open(): void {
    this.isDropdownOpened.next(true);
  }

  close(): void {
    this.isDropdownOpened.next(false);
  }
}
