import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TeamQrCode } from '../models/team-qr-code';
import { TeamQrCodeDataService } from '../services/team-qr-code-data.service';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TeamQrCodesService } from '../services/team-qr-codes.service';
import * as printJS from 'print-js';
import { LoadingService } from '../../../../core/loading-overlay/loading.service';

@Component({
  selector: 'mee-team-qr-code-preview',
  templateUrl: './team-qr-code-preview.component.html',
  styleUrls: ['./team-qr-code-preview.component.scss']
})
export class TeamQrCodePreviewComponent implements OnDestroy {
  qrCode: TeamQrCode;
  isImageLoad = false;
  private destroy$ = new Subject<void>();
  readonly defaultQrCodeData = 'https://www.vgis.io/';

  get isPreviewNotAvailable(): boolean {
    return !Boolean(this.qrCode) || !this.isImageLoad;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private qrCodeData: TeamQrCode,
    private teamQrCodeDataService: TeamQrCodeDataService,
    private teamQrCodesService: TeamQrCodesService,
    public loadingService: LoadingService
  ) {
    if (qrCodeData === null) {
      this.teamQrCodeDataService.getQrCodeDataForPreview$().pipe(
        filter(qrCode => Boolean(qrCode)),
        tap(qrCode => this.qrCode = qrCode),
        takeUntil(this.destroy$)
      ).subscribe()
    } else {
      this.qrCode = qrCodeData;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onImageLoad(): void {
    this.isImageLoad = true
  }

  download(): void {
    this.teamQrCodesService.exportQrCode(this.qrCode.CompanySlug, this.qrCode.TeamSlug, this.qrCode.Id).pipe(
      tap(blob => this.teamQrCodeDataService.downloadQrCode(blob, 'vGIS QR Code.pdf')),
      takeUntil(this.destroy$)
    ).subscribe()
  }

  print(): void {
    this.teamQrCodesService.exportQrCode(this.qrCode.CompanySlug, this.qrCode.TeamSlug, this.qrCode.Id).pipe(
      tap(blob => {
        const url = window.URL.createObjectURL(blob);
        printJS(url);
      }),
      takeUntil(this.destroy$)
    ).subscribe()
  }
}
