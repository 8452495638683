// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configuration-compound-select-wrapper {
  display: flex;
  flex-wrap: nowrap;
}
.configuration-compound-select-wrapper mat-checkbox {
  align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/sources/components/source-configuration-list/components/configuration-tab/configuration-compound-select/configuration-compound-select.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;AACF;AACE;EACE,kBAAA;AACJ","sourcesContent":[".configuration-compound-select-wrapper {\n  display: flex;\n  flex-wrap: nowrap;\n\n  mat-checkbox {\n    align-self: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
