import { Injectable } from '@angular/core';
import { RestClass, RestService } from '../../../../services/rest/rest.service';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TeamQrCode } from '../models/team-qr-code';
import { ServerPaginationParams } from '../../../../interfaces/server-pagination-params';
import { PaginationService } from '../../../../services/pagination.service';
import { map } from 'rxjs/operators';
import { IdentityService } from '../../../../services/identity.service';
import { TeamQrCodeRequestData } from '../models/team-qr-code-request-data';

@Injectable({
  providedIn: 'root'
})
@RestClass('/qr-codes')
export class TeamQrCodesService extends RestService<any> {

  readonly defaultPaginationOptions: ServerPaginationParams = {
    offset: 0,
    limit: 50,
    orderBy: 'CreatedAtUtc',
    desc: true,
  };

  constructor(
    protected http: HttpClient,
    private paginationService: PaginationService,
    private identityService: IdentityService,
  ) {
    super(http);
  }

  getServerSidePaginatedList(options: ServerPaginationParams) {
    const params = this.paginationService.getRequestParams(options);
    let httpParams = new HttpParams();

    params.forEach((value, key) => {
      httpParams = httpParams.set(key, value);
    });

    return this.http.get<TeamQrCode[]>(this.getQrCodeUrl(), { params: httpParams, observe: 'response' }).pipe(
      map((data: HttpResponse<any>) => {
        return { totalCount: data.headers.get('TotalCount'), body: data.body };
      }),
    );
  }

  getQrCodesForTeam(companySlug: string, teamSlug: string): Observable<TeamQrCode[]> {
    return this.http.get<TeamQrCode[]>(`${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/qr-codes`);
  }

  restoreQrCode(qrCodeId: string, teamSlug: string, companySlug: string): Observable<boolean> {
    return this.http.put<boolean>(`${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/qr-codes/${qrCodeId}/restore`, {});
  }

  multiRestoreQrCodes(QrCodesId: Array<string>): Observable<boolean> {
    return this.http.put<boolean>(this.getQrCodeUrl('restore'), QrCodesId);
  }

  multiDeleteQrCodes(QrCodesId: Array<string>): Observable<boolean> {
    return this.http.delete<boolean>(this.getQrCodeUrl(), { body: QrCodesId });
  }

  deleteQrCode(companySlug: string, teamSlug: string, QrCodeId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/qr-codes/${QrCodeId}`);
  }

  createQrCode(companySlug: string, teamSlug: string, data: TeamQrCodeRequestData): Observable<TeamQrCode> {
    return this.http.post<TeamQrCode>(`${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/qr-codes`, data);
  }

  updateQrCode(companySlug: string, teamSlug: string, id: string, data: TeamQrCodeRequestData): Observable<TeamQrCode> {
    return this.http.put<TeamQrCode>(`${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/qr-codes/${id}`, data);
  }

  exportQrCode(companySlug: string, teamSlug: string, id: string): Observable<Blob> {
    return this.http.post(`${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/qr-codes/${id}/export`, {}, { responseType: 'blob' });
  }

  private getQrCodeUrl(endpoint = ''): string {
    const companySlug = this.identityService.companySlug;

    let url = `${this.baseUrl}/companies/${companySlug}/qr-codes`;

    if (this.identityService.isTeamAdmin()) {
      const teamSlug = this.identityService.teamSlug;

      url = `${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/qr-codes`;
    }

    return endpoint.length > 0 ? url + `/${endpoint}` : url;
  }
}
