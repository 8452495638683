import {Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {TableBuilder} from '../table-builder';
import {TableDialog} from '../table-dialog.component';
import {ITableModel} from '../interfaces/table-model.interface';
import {TranslationHandleService} from '../../translate/translation-handle.service';
import {Subscription} from 'rxjs';

export enum DetailsMode {
  ADD, EDIT
}

const snackBarConfig: MatSnackBarConfig = {
  duration: 3000
};

const PHRASES_FOR_TRANSLATE = [
  {
    serverError: 'system.serverError'
  },
  {
    modelWasUpdated: 'system.serverError'
  },
  {
    modelWasCreated: 'system.serverError'
  }
];

@Component({
  selector: 'mee-table-item-form',
  templateUrl: './table-item-form.component.html',
  styleUrls: ['./table-item-form.component.scss']
})
export class TableItemFormComponent extends TableDialog<ITableModel> implements OnInit, OnDestroy {

  public model: any;

  public mode: DetailsMode = DetailsMode.EDIT;

  public form: UntypedFormGroup;

  public detailsMode = DetailsMode;

  public builder: TableBuilder<any>;

  private translationMap: Map<string, string>;
  private subs: Subscription[] = [];

  @ViewChild('auto') auto: ElementRef;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
    public dialogRef: MatDialogRef<TableItemFormComponent>,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private translationHandleService: TranslationHandleService
  ) {
    super();

    this.translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);

    this.subs.push(
      this.translationHandleService.getTranslationMap().subscribe(translationMap => {
        this.translationMap = translationMap;
      })
    );

    this.mode = this.dialogData.mode;
    this.model = this.dialogData.model;
    this.builder = this.dialogData.builder;
  }

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    const group = {};

    for (const column of this.builder.config.columns) {
      if (column.editable) {
        group[column.key] = ['', column.validators];
      }
    }

    this.form = this.formBuilder.group(group);

    if (this.model) {
      this.form.setValue(this.model);
    }
  }

  update(data: any) {
    this.subs.push(
      this.builder.service.update(this.model, data).subscribe(res => {
        this.snackBar.open(this.translationMap.get('modelWasUpdated'), 'Close');
      }, error => this.snackBar.open(error.statusText || this.translationMap.get('serverError'), 'Close', snackBarConfig))
    );
  }

  create(user: any) {
    this.subs.push(
      this.builder.service.create(user).subscribe(res => {
        this.snackBar.open(this.translationMap.get('modelWasCreated'), 'Close');
      }, error => this.snackBar.open(error.statusText || this.translationMap.get('serverError'), 'Close', snackBarConfig))
    );
  }

  onSubmit({value, valid}: UntypedFormGroup) {
    if (this.mode === DetailsMode.EDIT) {
      this.update(value);
    } else {
      this.create(value);
    }

    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub && sub.unsubscribe());
    this.translationHandleService.cleanTranslationArray();
  }
}
