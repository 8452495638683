export interface ITimeSelectOption {
  userDisplay: string;
  value: string | number;
}

export const INTERVAL_FILTER_OPTIONS: Array<ITimeSelectOption> = [
  {
    userDisplay: 'sources.scheduling.intervalFilters.options.every4Hours',
    value: 'Hourly4'
  },
  {
    userDisplay: 'sources.scheduling.intervalFilters.options.every8Hours',
    value: 'Hourly8'
  },
  {
    userDisplay: 'sources.scheduling.intervalFilters.options.every12Hours',
    value: 'Hourly12'
  },
  {
    userDisplay: 'sources.scheduling.intervalFilters.options.everyDay',
    value: 'Daily'
  },
  {
    userDisplay: 'sources.scheduling.intervalFilters.options.onceAWeek',
    value: 'Weekly'
  },
  {
    userDisplay: 'sources.scheduling.intervalFilters.options.onceAMonth',
    value: 'Monthly'
  }
];


export const DAY_FILTER_OPTION_USUAL: Array<ITimeSelectOption> = [
  {
    userDisplay: 'sources.scheduling.dayFilters.options.monday',
    value: 'Monday'
  },
  {
    userDisplay: 'sources.scheduling.dayFilters.options.tuesday',
    value: 'Tuesday'
  },
  {
    userDisplay: 'sources.scheduling.dayFilters.options.wednesday',
    value: 'Wednesday'
  },
  {
    userDisplay: 'sources.scheduling.dayFilters.options.thursday',
    value: 'Thursday'
  },
  {
    userDisplay: 'sources.scheduling.dayFilters.options.friday',
    value: 'Friday'
  },
  {
    userDisplay: 'sources.scheduling.dayFilters.options.saturday',
    value: 'Saturday'
  },
  {
    userDisplay: 'sources.scheduling.dayFilters.options.sunday',
    value: 'Sunday'
  }
];

export const TIME_FILTER_OPTIONS: Array<ITimeSelectOption> = [
  {
    userDisplay: 'sources.scheduling.timeFilters.options.morning',
    value: 'Morning'
  },
  {
    userDisplay: 'sources.scheduling.timeFilters.options.day',
    value: 'Day'
  },
  {
    userDisplay: 'sources.scheduling.timeFilters.options.evening',
    value: 'Evening'
  },
  {
    userDisplay: 'sources.scheduling.timeFilters.options.night',
    value: 'Night'
  },
];
