import { Injectable } from '@angular/core';
import { ArcGisCustomGeneralElevation } from '../models/arc-gis-custom-general-elevation';
import { ArcGisCustomElevation } from '../models/arc-gis-custom-elevation';
import { CustomElevationType } from '../models/custom-elevation-type.enum';
import { ArcGisCustomElevationCreateUpdate } from '../models/arc-gis-custom-elevation-create-update';

@Injectable({
  providedIn: 'root',
})
export class DtmAdapterService {
  adaptGeneralDtm(dtm: ArcGisCustomGeneralElevation): ArcGisCustomElevation {
    let adaptedDtm = {
      ArcGisUsername: dtm.ArcGisUsername,
      AuthenticationUrl: dtm.AuthenticationUrl,
      CreatedAtUtc: dtm.CreatedAtUtc,
      Id: dtm.Id,
      Slug: dtm.Slug,
      IsArcGisOnline: dtm.IsArcGisOnline,
      IsArchived: dtm.IsArchived,
      IsCascadeArchived: dtm.IsCascadeArchived,
      LastUpdatedAtUtc: dtm.LastUpdatedAtUtc,
      ElevationServerUrl: dtm.ElevationServerUrl,
      Name: dtm.Name,
      Type: CustomElevationType.ELEVATION_PROVIDER_TYPE_ARC_GIS,
      SouthwestBoundLongitude: dtm.SouthwestBoundLongitude,
      SouthwestBoundLatitude: dtm.SouthwestBoundLatitude,
      NortheastBoundLatitude: dtm.NortheastBoundLatitude,
      NortheastBoundLongitude: dtm.NortheastBoundLongitude,
      CompanyName: dtm.CompanyName,
      CompanySlug: dtm.CompanySlug,
      CompanyId: dtm.CompanyId,
      VerticalDatum: dtm.VerticalDatum,
      Scope: dtm.Scope,
      AccessReason: dtm.AccessReason,
      OwnerTeamId: dtm.OwnerTeamId,
      OwnerTeamName: dtm.OwnerTeamName,
    }

    if (dtm.hasOwnProperty('ArcGisPassword')) {
      adaptedDtm = {...adaptedDtm, ArcGisPassword: dtm.ArcGisPassword.trim() } as ArcGisCustomElevation;
    }

    return adaptedDtm;
  }


  adaptDtmForUpdate(dtm: ArcGisCustomElevation): ArcGisCustomElevationCreateUpdate {

    let dtmForUpdate = {
      ArcGisUsername: dtm.ArcGisUsername,
      AuthenticationUrl: dtm.AuthenticationUrl,
      CreatedAtUtc: dtm.CreatedAtUtc,
      Id: dtm.Id,
      Slug: dtm.Slug,
      IsArcGisOnline: dtm.IsArcGisOnline,
      LastUpdatedAtUtc: dtm.LastUpdatedAtUtc,
      ElevationServerUrl: dtm.ElevationServerUrl,
      Name: dtm.Name,
      Type: dtm.Type,
      CompanyName: dtm.CompanyName,
      CompanySlug: dtm.CompanySlug,
      CompanyId: dtm.CompanyId,
      VerticalDatum: dtm.VerticalDatum,
      Scope: dtm.Scope,
      OwnerTeamId: dtm.OwnerTeamId,
    };

    if (dtm.hasOwnProperty('ArcGisPassword')) {
      dtmForUpdate = { ...dtmForUpdate, ArcGisPassword: dtm.ArcGisPassword } as ArcGisCustomElevationCreateUpdate;
    }

    return dtmForUpdate;
  }
}
