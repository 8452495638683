import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SidenavItem } from './sidenav-item/sidenav-item.model';
import { SidenavService } from './sidenav.service';
import { NavigationEnd, Router } from '@angular/router';
import keys from 'lodash-es/keys';
import isArray from 'lodash-es/isArray';
import sortBy from 'lodash-es/sortBy';
import { IdentityService } from '../../services/identity.service';
import { DropdownService } from '../../services/dropdown.service';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'mee-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements OnInit, OnDestroy {
  items: SidenavItem[];
  isDropdownOpened = false;

  private destroy$ = new Subject<void>();

  get isTeamAdmin(): boolean {
    return this.identityService.isTeamAdmin();
  }

  constructor(
    private sidenavService: SidenavService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private readonly identityService: IdentityService,
    private readonly dropdownService: DropdownService,
  ) {
    this.dropdownService.isOpened$
      .pipe(
        tap(isOpened => {
          this.isDropdownOpened = isOpened;
          this.cdr.markForCheck();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnInit() {
    this.sidenavService.items$
      .pipe(
        takeUntil(this.destroy$),
        tap((items: SidenavItem[]) => {
          this.items = this.sortRecursive(items, 'position');
        }),
      )
      .subscribe();

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenavService.nextCurrentlyOpenByRoute(event.url);
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 400);
      }
    });
  }

  toggleIconSidenav() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
    this.sidenavService.toggle();
  }

  sortRecursive(array: SidenavItem[], propertyName: string) {
    const that = this;

    array.forEach(function (item) {
      const objectKeys = keys(item);
      objectKeys.forEach(function (key) {
        if (isArray(item[key])) {
          item[key] = that.sortRecursive(item[key], propertyName);
        }
      });
    });

    this.cdr.markForCheck();

    return sortBy(array, propertyName);
  }

  toggleState() {
    this.isDropdownOpened ? this.dropdownService.close() : this.dropdownService.open();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
