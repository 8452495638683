import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SourcesComponent } from './sources.component';
import { SourcesResolver } from './resolvers/sources.resolver';
import { SourcesListComponent } from './components/sources-list/sources-list.component';
import { SourceFormComponent } from './components/source-form/source-form.component';
import { DataSourceResolver } from './resolvers/data-source.resolver';
import { SourceViewComponent } from './components/source-view/source-view.component';
import { SourceConfigurationResolver } from './components/source-configuration-list/resolvers/source-configuration.resolver';
import { SaveFormsGuard } from './guards/save-forms.guard';
import { SourcesLayersResolver } from './resolvers/sources-layers.resolver';
import { SourceConfigurationListComponent } from './components/source-configuration-list/source-configuration-list.component';
import { ProcessListComponent } from './components/process-list/process-list.component';
import { ProcessReportComponent } from './components/process-report/process-report.component';
import { ProcessListResolver } from './components/process-list/process-list.resolver';
import { ProcessReportResolver } from './components/process-report/process-report.resolver';
import { ScheduleForTodayComponent } from './components/source-form/schedule-for-today/schedule-for-today.component';
import { CoordinateSystemsResolver } from '../components/coordinate-system/coordinate-systems-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: SourcesComponent,
    children: [
      {
        path: '',
        component: SourcesListComponent,
        resolve: {
          sources: SourcesResolver,
        },
      },
      {
        path: 'schedule-for-today',
        component: ScheduleForTodayComponent,
      },
      {
        path: 'process',
        component: ProcessListComponent,
        resolve: {
          processes: ProcessListResolver,
        },
      },
      {
        path: 'process/:companySlug/sourceSlug/:processId/report',
        component: ProcessReportComponent,
        resolve: {
          reports: ProcessReportResolver,
        },
      },
      {
        path: 'add',
        component: SourceFormComponent,
      },
      {
        path: ':companySlug/:sourceSlug/config',
        component: SourceConfigurationListComponent,
        resolve: {
          configuration: SourceConfigurationResolver,
          layers: SourcesLayersResolver,
          source: DataSourceResolver,
          coordinateSystems: CoordinateSystemsResolver
        },
        canDeactivate: [SaveFormsGuard],
      },
      {
        path: ':companySlug/:sourceSlug',
        component: SourceViewComponent,
        resolve: {
          source: DataSourceResolver,
        },
      },
      {
        path: ':companySlug',
        component: SourcesListComponent,
        resolve: {
          sources: SourcesResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SourcesRoutingModule {
}
