import { NgModule } from '@angular/core';
import { TableComponent } from './table.component';
import { SharedModule } from '../../shared/shared.module';
import { MatCommonModule } from '../mat-common.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ReactiveFormsModule } from '@angular/forms';
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TableItemViewComponent } from './table-item-view/table-item-view.component';
import { TableActionComponent } from './table-action/table-action.component';
import { TableItemFormComponent } from './table-item-form/table-item-form.component';
import { TableActionConfirmComponent } from './table-action-confirm/table-action-confirm.component';
import { TableCellContentComponent } from './table-cell-content/table-cell-content.component';
import { TableTooltipDirective } from './tooltip/tooltip.directive';
import { RouterModule } from '@angular/router';
import { InputAutofocusDirective } from '../utils/input-autofocus.directive';
import { CategoriesComponent } from '../categories/categories.component';

const components = [
  TableComponent,
  TableCellContentComponent,
  TableActionComponent,
  TableItemFormComponent,
  TableItemViewComponent,
  TableActionConfirmComponent,
  CategoriesComponent,
  TableTooltipDirective,
  InputAutofocusDirective,
];

@NgModule({
    imports: [
        SharedModule,
        MatCommonModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        // PerfectScrollbarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        RouterModule,
    ],
    declarations: components,
    exports: components
})

export class TableModule {

}
