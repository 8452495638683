import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MediaUploader } from '../media-uploader';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SourceBaseFile } from '../../../modules/sources/components/source-form/simple-model-file-loader/source-base-file';
import { UploaderType } from '../uploader-type';
import { UploadStatus } from 'ngx-uploader';
import { UploadFile } from '../upload-file';
import { UploadOutputType } from '../upload-output-type';

@Component({
  selector: 'mee-file-upload-button',
  templateUrl: './file-upload-button.component.html',
  styleUrls: ['./file-upload-button.component.scss'],
})
export class FileUploadButtonComponent extends MediaUploader implements OnInit, OnDestroy {
  type = UploaderType.FILE;

  @Input() acceptTypes = '';

  @Input() file: SourceBaseFile;

  @Input() isSingleFileRequired = true;

  @Input() existingFileUrl: string;

  @Output() removeRequiredFile = new EventEmitter<void>();

  uploadStatus = UploadStatus;

  private destroy$ = new Subject<void>();

  constructor(dialog: MatDialog) {
    super(dialog);
  }

  ngOnInit() {
    this.uploaded
      .pipe(
        tap(() => {
          if (this.isSingleFileRequired && this.files.length > 1) {
            this.replaceFile();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  onExistFileTitleChange(event): void {
    const changedValue = event.target.value;
    this.file = { ...this.file, Title: changedValue };

    this.uploaded.emit({ sourceFile: this.file });
  }

  onTitleChangeUploadFile(event, currentFile: UploadFile) {
    const changedValue = event.target.value;

    this.files = this.files.map(file => {
      if (file.id === currentFile.id) {
        return { ...file, customTitle: changedValue };
      }

      return file;
    });

    const newFile = this.getAdaptedUploadFile();

    this.uploaded.emit({ sourceFile: newFile });
  }

  removeFile(): void {
    // this.uploadInput.emit is not working for remove

    this.onUploadOutput({ type: UploadOutputType.Removed, file: this.files[0] });

    if (this.isSingleFileRequired) {
      this.removeRequiredFile.emit();
    }
  }

  private replaceFile() {
    this.onUploadOutput({ type: UploadOutputType.Removed, file: this.files[0] });
  }

  private getAdaptedUploadFile(): SourceBaseFile {
    const file = this.files[0];

    return { FileName: file.name, FileUrl: file.response, Title: file.customTitle ? file.customTitle : file.name };
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
