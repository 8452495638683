import { Component } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { default as lodashFilter } from 'lodash-es/filter';
import orderBy from 'lodash-es/orderBy';
import { Team } from '../../teams/models/team';
import { ICompany } from '../../companies/shared/interfaces/company.interface';
import { LoadingService } from '../../../core/loading-overlay/loading.service';
import { IdentityService } from '../../../services/identity.service';
import { TranslateService } from '@ngx-translate/core';
import { CompaniesService } from '../../companies/companies.service';
import { DevicesService } from '../../devices/shared/services/devices.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TeamsService } from '../../teams/services/teams.service';
import { slugPattern } from '../../../core/utils/validators';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

const snackBarConfig = {
  duration: 5000,
};

@Component({
  templateUrl: './create-device.component.html',
  styleUrls: ['./create-device.component.scss'],
})
export class CreateDeviceComponent {
  form = this.formBuilder.group({
    email: ['', [Validators.email, Validators.required]],
    companySlug: ['', [Validators.pattern(slugPattern), Validators.required]],
    superDeviceControl: [false],
    teamSlugControl: [false],
  });
  teams: Team[];
  companies: Array<ICompany>;
  companiesFiltered: Observable<ICompany[]>;
  teamsFiltered: Observable<any[]>;

  get emailControl(): AbstractControl {
    return this.form.get('email');
  }

  get companySlugControl(): AbstractControl {
    return this.form.get('companySlug');
  }

  get teamSlugControl(): AbstractControl {
    return this.form.get('teamSlugControl');
  }

  get superDeviceControl(): AbstractControl {
    return this.form.get('superDeviceControl');
  }

  get isSuperAdmin(): boolean {
    return this.identityService.isSuperAdmin();
  }

  get isTeamAdmin(): boolean {
    return this.identityService.isTeamAdmin();
  }

  get isCompanyAdmin(): boolean {
    return this.identityService.isCompanyAdmin();
  }

  get shouldShowEmailError(): boolean {
    return this.emailControl.errors && this.emailControl.errors.required && (this.emailControl.dirty || this.emailControl.touched);
  }

  get shouldShowCompanySlugError(): boolean {
    return this.companySlugControl.invalid && (this.companySlugControl.dirty || this.companySlugControl.touched);
  }

  constructor(
    public loadingService: LoadingService,
    public identityService: IdentityService,
    private translateService: TranslateService,
    private companiesService: CompaniesService,
    private devicesServices: DevicesService,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private teamsService: TeamsService,
    public dialogRef: MatDialogRef<CreateDeviceComponent>,
  ) {
    this.superDeviceControl.valueChanges
      .pipe(
        tap(value => {
          this.companySlugControl.setValue(value ? false : null);
          this.teamSlugControl.setValue(value ? false : null);
        }),
      )
      .subscribe();

    if (this.identityService.isSuperAdmin()) {
      this.companiesService
        .list()
        .pipe(
          tap(companies => {
            const companiesTrimmed = companies.map(company => {
              return { ...company, Name: company.Name.trim() };
            });

            this.companies = orderBy(
              lodashFilter(companiesTrimmed, (company: ICompany) => {
                return !company.IsArchived;
              }),
              ['Name'],
            );
            this.filterCompaniesValues();
          }),
        )
        .subscribe();
    } else if (this.identityService.isCompanyAdmin()) {
      this.companiesService
        .getMyCompany()
        .pipe(
          filter(Boolean),
          tap<ICompany>(company => {
            this.companies = [company];
            this.companySlugControl.setValue(company.Slug);
            this.companySlugControl.disable();
            this.setTeamList(company.Slug);
          }),
        )
        .subscribe();
    } else {
      this.companiesService
        .get(this.identityService.companySlug)
        .pipe(
          filter(Boolean),
          tap<ICompany>(company => {
            this.companies = [company];
            this.companySlugControl.setValue(company.Slug);
            this.companySlugControl.disable();
          }),
        )
        .subscribe();

      this.teamsService
        .getTeam(this.identityService.companySlug, this.identityService.teamSlug)
        .pipe(
          filter(Boolean),
          tap<Team>(team => {
            this.teams = [team];
            this.teamSlugControl.setValue(team.Slug);
            this.teamSlugControl.disable();
          }),
        )
        .subscribe();
    }
  }

  filterCompaniesValues(): void {
    this.companiesFiltered = this.companySlugControl.valueChanges.pipe(
      startWith(''),
      map((value: string) => {
        if (typeof value !== 'string') {
          return;
        }

        const filterValue = value.toLowerCase();
        this.companySlugControl.setErrors({ required: true });

        return this.companies.filter(company => company.Name.toLowerCase().includes(filterValue));
      }),
    );
  }

  filterTeamsValues(): void {
    this.teamsFiltered = this.teamSlugControl.valueChanges.pipe(
      startWith(''),
      map((value: string) => {
        if (typeof value !== 'string') {
          return [];
        }

        const filterValue = value.toLowerCase();

        return this.teams.filter(team => team.Name.toLowerCase().includes(filterValue));
      }),
    );
  }

  getTeamDisplayValue(team: Team): string {
    return `${team.Name} (${team.Slug})`;
  }

  displayCompanyProperty(companySlug: string): string {
    if (!companySlug || !this.companies) {
      return '';
    }

    return this.companies.find(company => company.Slug === companySlug).Name;
  }

  displayTeamProperty(teamSlug: string): string {
    if (!teamSlug || !this.teams) {
      return;
    }

    const foundTeam = this.teams.find(team => team.Slug === teamSlug);

    return `${foundTeam.Name} (${teamSlug})`;
  }

  setCompanyValue(event: any): void {
    if (!event.option.value) {
      return;
    }

    if (this.identityService.isTeamAdmin()) {
      this.companySlugControl.setErrors(null);

      return;
    }

    this.setTeamList(event.option.value);
  }

  setTeamList(companySlug: string) {
    this.teamsService
      .getTeams(companySlug)
      .pipe(
        tap<any[]>(list => {
          this.teamSlugControl.setValue('default');
          const filteredList = list.filter(item => !item.IsArchived);
          this.teams = filteredList.sort((a, b) => (a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 1));
          this.companySlugControl.setErrors(null);
          this.filterTeamsValues();
        }),
      )
      .subscribe();
  }

  onSubmit({ value, valid }) {
    // use value from formControl, because value in form is empty
    const companySlug = this.companySlugControl.value;
    const teamSlug = this.teamSlugControl.value ? this.teamSlugControl.value : 'default';
    if (!valid) {
      return;
    }

    let params: {} = {
      teamSlug,
    };

    if (companySlug) {
      params = { ...params, companySlug };
    }

    this.devicesServices
      .create(
        {
          Data: value.email,
        },
        {
          params,
        },
      )
      .pipe(
        tap(() => this.dialogRef.close()),
        switchMap(() => this.translateService.get('devices.creating-form.creating_success_msg')),
        tap(text => this.snackBar.open(text, 'OK', snackBarConfig)),
      )
      .subscribe();
  }

  cancel() {
    this.dialogRef.close();
  }
}
