import {Component, OnInit} from '@angular/core';
import {TableBuilder} from '../../../../../core/table/table-builder';
import {ITableColumn} from '../../../../../core/table/interfaces/table-column.interface';
import {DataSourcesService} from '../../../services/data-sources.service';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {ITableAction} from '../../../../../core/table/interfaces/table-action.interface';
import {DateFormatterService} from '../../../../../services/date-formatter.service';
import {ISchedule} from '../../../models/source.interface';

@Component({
  selector: 'mee-schedule-for-today',
  templateUrl: './schedule-for-today.component.html',
  styleUrls: ['./schedule-for-today.component.scss']
})
export class ScheduleForTodayComponent implements OnInit {

  tableBuilder: TableBuilder<any>;

  startDateUtc: any;

  constructor(private dataSourceService: DataSourcesService, private dateFormatterService: DateFormatterService) {
  }

  ngOnInit() {

    this.defineTableBuilder();
  }

  defineTableBuilder() {

    const columns: ITableColumn[] = [
      {
        key: 'DataSourceId',
        title: 'DataSourceId'
      },
      {
        key: 'DataSourceSlug',
        title: 'DataSourceSlug'
      },
      {
        key: 'StartUtc',
        title: 'StartUtc',
        contentFn: (content) => {
          return new Observable((observer) => {
            this.startDateUtc = moment(content);
            observer.next(this.dateFormatterService.getFormattedToLocalTime(content));
            observer.complete();
          });
        }
      },
      {
        key: 'UtcOffsetHours',
        title: 'UtcOffsetHours',
        contentFn: (content) => {
          return new Observable((observer) => {
            observer.next(this.dateFormatterService.getTimeWithUtcOffset(this.startDateUtc, content, 'hours'));
            observer.complete();
          });
        }
      },
    ];


    const headActions: ITableAction[] = [
      {
        name: 'system.refresh',
        color: 'primary',
        action: this.getScheduleForToday.bind(this)
      }];

    this.tableBuilder = new TableBuilder<any>({
      list: this.getScheduleForToday(),
    }, {
      columns: columns,
      headActions: headActions
    });

  }

  getScheduleForToday() {
    let list;

    this.dataSourceService.getScheduleForToday().subscribe((schedule: ISchedule[]) => {
      list = schedule;
      this.tableBuilder.dataChange.next(schedule);
    });

    return list;
  }

}
