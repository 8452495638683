import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ArcGisCustomElevationFormData } from '../../components/models/arc-gis-custom-elevation-form-data';
import { ICompany } from '../../companies/shared/interfaces/company.interface';
import { CustomElevationType } from '../../components/models/custom-elevation-type.enum';
import { EMPTY, forkJoin, Observable, Subject } from 'rxjs';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AddEditDialogData } from '../../components/models/add-edit-dialog-data';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslationHandleService } from '../../../core/translate/translation-handle.service';
import { CustomElevationsService } from '../../components/custom-elevations.service';
import { IdentityService } from '../../../services/identity.service';
import { CompaniesService } from '../../companies/companies.service';
import {catchError, filter, map, startWith, switchMap, takeUntil, takeWhile, tap} from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { Scope } from '../../components/models/scope.enum';
import { snackBarConfig } from '../../../core/core.module';
import { ArcGisCustomGeneralElevation } from '../../components/models/arc-gis-custom-general-elevation';
import { TeamInterface } from '../../teams/models/team.interface';
import { CustomValidatorsService } from '../../../shared/services/custom-validators.service';
import { TeamsService } from '../../teams/services/teams.service';
import { DtmAdapterService } from '../../components/add-edit-custom-elevation/dtm-adapter.service';
import { ArcGisCustomElevation } from '../../components/models/arc-gis-custom-elevation';
import {identifierMaxLength, slugPattern} from '../../../core/utils/validators';
import {AutofillFieldService} from '../../../services/autofill-field.service';

@Component({
  selector: 'mee-add-dtm',
  templateUrl: './add-dtm.component.html',
  styleUrls: ['./add-dtm.component.scss'],
})
export class AddDtmComponent implements OnInit, OnDestroy {
  addDTMForm: UntypedFormGroup;
  arcGisFormValue: ArcGisCustomElevationFormData;
  selectedCompany: ICompany | { Slug: string; Name: string };
  landscapes = [
    {
      name: 'ArcGIS ImageServer',
      value: CustomElevationType.ELEVATION_PROVIDER_TYPE_ARC_GIS,
    },
  ];
  translationMap: Map<string, string>;
  filteredData: Observable<ICompany[]>;
  companies: ICompany[];
  selectedTeam: TeamInterface | { Id: string, Slug: string; Name: string };
  selectedDtm: ArcGisCustomGeneralElevation;
  scopes = Scope;

  isPrivateVisibilityDisabled: boolean;

  privateVisibilityDisabledTooltipText = 'permissions.privateDtmTooltipTex';
  filteredCompaniesData$: Observable<ICompany[]>;
  filteredTeamsData$: Observable<TeamInterface[]>;
  teams: TeamInterface[];
  isShowTeamField = false;

  elevationServerUrls: string[] = [];
  namesOfCustomLandscapeProviders: string[] = [];

  isShowWarningAboutSetPublic: boolean;
  readonly identifierMaxLength = identifierMaxLength;
  private readonly destroy$ = new Subject<void>();

  get isSuperAdmin() {
    return this.identityService.isSuperAdmin();
  }

  get isCompanyAdmin() {
    return this.identityService.isCompanyAdmin();
  }

  get isTeamAdmin() {
    return this.identityService.isTeamAdmin();
  }

  get isShowScopeTooltip(): boolean {
    return this.addDTMForm.get('scope').value !== null;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: AddEditDialogData,
    private dialogRef: MatDialogRef<AddDtmComponent>,
    private snackBar: MatSnackBar,
    private translationHandleService: TranslationHandleService,
    private customValidatorsService: CustomValidatorsService,
    private customLandscapesService: CustomElevationsService,
    private readonly identityService: IdentityService,
    private readonly companiesService: CompaniesService,
    private readonly teamsService: TeamsService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly dtmAdapterService: DtmAdapterService,
    private readonly autofillFieldService: AutofillFieldService,
  ) {
    translationHandleService.setPhrasesForTranslate([
      {
        createCustomElevation: 'company.customElevations.customElevationWasCreated',
      },
      {
        checkData: 'company.baseMaps.checkData',
      },
      {
        errorOccurred: 'errors.error_occurred',
      },
      {
        companyScopeTooltip: 'permissions.companyScopeTooltip',
      },
      {
        teamPublicScopeTooltip: 'permissions.teamPublicScopeTooltip',
      },
      {
        teamPrivateScopeTooltip: 'permissions.teamPrivateScopeTooltip',
      },
    ]);

    translationHandleService.getTranslationMap().subscribe((translationMap: Map<string, string>) => {
      this.translationMap = translationMap;
    });

    if (this.isCompanyAdmin) {
      this.companiesService
        .getMyCompany()
        .pipe(
          tap(company => {
            this.selectedCompany = company;
            this.addDTMForm.get('company').setValue(company);
            this.addDTMForm.get('company').disable();
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
      return;
    }

    if (this.isSuperAdmin) {
      this.companiesService
        .list()
        .pipe(
          tap(companies => {
            this.companies = companies.sort((a, b) => (a.Name < b.Name ? -1 : 1)).filter(company => !company.IsArchived);
            this.filterCompaniesAutocompleteValues();
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  ngOnInit(): void {
    this.customLandscapesService.customLandscapeProviderListChanged$
      .pipe(
        tap((customLandscapeProvidersList: ArcGisCustomElevation[]) => {
          this.namesOfCustomLandscapeProviders = customLandscapeProvidersList.map(i => i.Name.toLowerCase().trim());
          this.elevationServerUrls = customLandscapeProvidersList.map(item => {
            if ('ElevationServerUrl' in item) {
              return item.ElevationServerUrl.toLowerCase().trim();
            }
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.initAddDTMForm();

    if (this.isSuperAdmin || this.isCompanyAdmin) {
      this.addDTMForm.get('scope').setValue(Scope.CompanyScope);
    } else {
      this.getCompanyAndTeamForTeamAdmin();

      this.addDTMForm.get('scope').setValue(Scope.TeamPrivateScope);
    }

    if (this.selectedCompany && !this.isTeamAdmin) {
      this.getSortedTeams$(this.selectedCompany.Slug)
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    }

    const initialScopeValue = this.addDTMForm.get('scope').value;

    this.addDTMForm.get('scope').valueChanges
      .pipe(
        tap(scope => {

          this.isShowTeamField = (scope === Scope.TeamPublicScope) || (scope === Scope.TeamPrivateScope);

          this.isShowWarningAboutSetPublic = initialScopeValue === Scope.TeamPrivateScope && scope !== Scope.TeamPrivateScope;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.addDTMForm.get('company').valueChanges
      .pipe(
        filter(value => value.hasOwnProperty('Id')),
        switchMap(({ Slug }) => this.getSortedTeams$(Slug)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.setAutoFillValue();
  }

  initAddDTMForm() {
    this.addDTMForm = this.formBuilder.group({
      slug: [{value: '', disabled: true}, [Validators.required, Validators.pattern(slugPattern)]],
      name: ['', [Validators.required]],
      elevationProviderType: [CustomElevationType.ELEVATION_PROVIDER_TYPE_ARC_GIS, [Validators.required]],
      company: ['', Validators.required],
      scope: [],
      ownerTeamId: [],
    });
  }

  arcGisDataChanged(arcgisValue) {
    this.arcGisFormValue = arcgisValue;
  }

  isArcGisFormInvalid() {
    return this.addDTMForm.invalid || this.arcGisFormValue.isFormInvalid;
  }

  onAddLandscape() {
    const formValue = this.addDTMForm.getRawValue();

    const dataForCreateMapProvider = { ...this.arcGisFormValue.data };
    dataForCreateMapProvider.Slug = formValue.slug;
    dataForCreateMapProvider.Name = formValue.name;
    dataForCreateMapProvider.Type = formValue.elevationProviderType;
    dataForCreateMapProvider.Scope = formValue.scope;

    if (formValue.scope === Scope.CompanyScope) {
      dataForCreateMapProvider.OwnerTeamId = null;
    } else {
      dataForCreateMapProvider.OwnerTeamId = this.selectedTeam.Id;
    }

    const dtmUpdateModel = this.dtmAdapterService.adaptDtmForUpdate(dataForCreateMapProvider);

    this.customLandscapesService
      .createNewLandscapeProvider(dtmUpdateModel, this.selectedCompany.Slug)
      .pipe(
        tap(generalDtm => {
          const dtm = this.dtmAdapterService.adaptGeneralDtm(generalDtm);
          this.customLandscapesService.setNewLandscapeProvider(dtm);
          this.snackBar.open(this.translationMap.get('createCustomElevation'), 'OK', snackBarConfig);
          this.dialogRef.close();
        }),
        catchError(error => {
          this.handleError(error);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  filterCompaniesAutocompleteValues(): void {
    this.filteredCompaniesData$ = this.addDTMForm.get('company').valueChanges.pipe(
      startWith(''),
      map(value => this.filterCompanies(value)),
    );
  }

  filterTeamsAutocompleteValues() {
    this.filteredTeamsData$ = this.addDTMForm.get('ownerTeamId').valueChanges.pipe(
      startWith(''),
      map(value => this.filterTeams(value)),
    );
  }

  getScopeTooltipText(): string {
    const currentScope = this.addDTMForm.get('scope').value;

    if (currentScope === Scope.CompanyScope) {
      return this.translationMap.get('companyScopeTooltip');
    } else if (currentScope === Scope.TeamPublicScope) {
      return this.translationMap.get('teamPublicScopeTooltip');
    } else {
      return this.translationMap.get('teamPrivateScopeTooltip');
    }
  }

  onCompanyChange(event: MatAutocompleteSelectedEvent) {
    this.selectedCompany = event.option.value;
  }

  onTeamChange(event: MatAutocompleteSelectedEvent) {
    this.selectedTeam = event.option.value;

    this.addDTMForm.get('ownerTeamId').setValue(this.selectedTeam);
  }

  displayCompanyProperty(company: ICompany) {
    if (company) {
      return company.Name;
    }
  }

  displayTeamProperty(team: TeamInterface) {
    if (team) {
      return team.Name;
    }
  }

  handleError(error) {
    if (error.status === 400) {
      this.snackBar.open(this.translationMap.get('checkData'), 'OK', { duration: 5000 });
    } else {
      this.dialogRef.close();
      this.snackBar.open(this.translationMap.get('errorOccurred'), 'OK', { duration: 5000 });
    }
  }

  isRequiredError(formControlName: string): boolean {
    const control = this.addDTMForm.get(formControlName);

    return control.errors && control.errors.required && (control.dirty || control.touched);
  }

  private setAutoFillValue(): void {
    const nameControl = this.addDTMForm.get('name');
    const identifierControl = this.addDTMForm.get('slug');

    this.autofillFieldService.setAutoFillValue$(nameControl, identifierControl).pipe(takeUntil(this.destroy$)).subscribe();
  }

  private getSortedTeams$(companySlug: string): Observable<TeamInterface[]> {
    return this.teamsService.getTeams(companySlug)
      .pipe(
        tap((teams: TeamInterface[]) => {
          const teamsTrimmed = teams.map(team => {
            return { ...team, Name: team.Name.trim() };
          });

          this.teams = teamsTrimmed.sort((a, b) => (a.Name < b.Name ? -1 : 1));
          this.filterTeamsAutocompleteValues();
        }),
      ) as Observable<TeamInterface[]>;
  }

  private filterCompanies(value: string): Array<ICompany> {
    if (typeof value !== 'string') {
      return;
    }

    const filterValue = value.toLowerCase();
    this.addDTMForm.get('company').setErrors({ required: true });
    return this.companies.filter(option => option.Name.toLowerCase().includes(filterValue));
  }

  private filterTeams(value: string): Array<TeamInterface> {
    if (typeof value !== 'string') {
      return;
    }

    const filterValue = value.toLowerCase();
    return this.teams.filter(option => option.Name.toLowerCase().includes(filterValue));
  }

  private getCompanyAndTeamForTeamAdmin() {
    const companySlug = this.identityService.companySlug;
    const teamSlug = this.identityService.teamSlug;

    forkJoin({
        company: this.companiesService.get(companySlug),
        team: this.teamsService.getTeam(companySlug, teamSlug),
      },
    ).pipe(
      tap(value => {
        this.selectedCompany = { Slug: value.company.Slug, Name: value.company.Slug };
        this.selectedTeam = { Id: value.team.Id, Slug: value.team.Slug, Name: value.team.Slug };

        this.addDTMForm.get('company').setValue(this.selectedCompany);
        this.addDTMForm.get('ownerTeamId').setValue(this.selectedTeam);
      }),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
