// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floors-elevation-wrapper .enable-state-icon {
  cursor: pointer;
}
.floors-elevation-wrapper .enable-state-icon.green-color {
  color: #4CAF50;
}
.floors-elevation-wrapper .enable-state-icon.red-color {
  color: #EE2233;
}
.floors-elevation-wrapper .enable-state-icon.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.floors-elevation-wrapper .elevation-input {
  border-bottom: 1px solid #e6e6e6;
  margin-top: 5px;
}
.floors-elevation-wrapper .hidden-checkbox {
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/sources/components/source-configuration-list/components/configuration-tab/floors-configuration/floors-elevation/floors-elevation.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AAEI;EACE,cAAA;AAAN;AAGI;EACE,cAAA;AADN;AAII;EACE,mBAAA;EACA,YAAA;AAFN;AAME;EACE,gCAAA;EACA,eAAA;AAJJ;AAOE;EACE,kBAAA;AALJ","sourcesContent":[".floors-elevation-wrapper {\n  .enable-state-icon {\n    cursor: pointer;\n\n    &.green-color {\n      color: #4CAF50;\n    }\n\n    &.red-color {\n      color: #EE2233;\n    }\n\n    &.disabled {\n      cursor: not-allowed;\n      opacity: 0.4;\n    }\n  }\n\n  .elevation-input {\n    border-bottom: 1px solid #e6e6e6;\n    margin-top: 5px;\n  }\n\n  .hidden-checkbox {\n    visibility: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
