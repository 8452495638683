import {ConfigurationFields} from '../enums/configuration-fields.enum';
import {DataSourceType} from '../../../../../data-source-type.enum';
import {FeatureLayerType} from '../interfaces/feature-layer-type.enum';

export const CONFIGURATION_FIELDS_DISABLE_SOURCE_TYPES = {
  VerticalDatum: {
    Root: [
      DataSourceType.Kml,
      DataSourceType.IModel,
      DataSourceType.IModelGis
    ]
  },
  ObjectType: {
    Model3d: [
      DataSourceType.ArcGisFeatureService,
      DataSourceType.ArcGisSceneService,
      DataSourceType.Bim360,
      DataSourceType.NativeBim,
      DataSourceType.NativeGis,
      DataSourceType.Wfs,
      DataSourceType.Kml,
      DataSourceType.IModel,
      DataSourceType.IModelGis,
      DataSourceType.Shapefile,
      DataSourceType.Pix4d,
      DataSourceType.RealityCapture,
      DataSourceType.LandXml,
      DataSourceType.Simple3dModel,
    ],
    Area: [
      DataSourceType.ArcGisFeatureService,
      DataSourceType.ArcGisSceneService,
      DataSourceType.Bim360,
      DataSourceType.NativeBim,
      DataSourceType.NativeGis,
      DataSourceType.Wfs,
      DataSourceType.Kml,
      DataSourceType.IModel,
      DataSourceType.IModelGis,
      DataSourceType.Shapefile,
      DataSourceType.Pix4d,
      DataSourceType.RealityCapture,
      DataSourceType.LandXml,
      DataSourceType.Simple3dModel,
    ],
    Line: [
      DataSourceType.ArcGisFeatureService,
      DataSourceType.ArcGisSceneService,
      DataSourceType.Bim360,
      DataSourceType.NativeBim,
      DataSourceType.NativeGis,
      DataSourceType.Wfs,
      DataSourceType.Kml,
      DataSourceType.IModel,
      DataSourceType.IModelGis,
      DataSourceType.Shapefile,
      DataSourceType.Pix4d,
      DataSourceType.RealityCapture,
      DataSourceType.LandXml,
      DataSourceType.Simple3dModel,
    ],
    Point: [
      DataSourceType.ArcGisFeatureService,
      DataSourceType.ArcGisSceneService,
      DataSourceType.Bim360,
      DataSourceType.NativeBim,
      DataSourceType.NativeGis,
      DataSourceType.Wfs,
      DataSourceType.Kml,
      DataSourceType.IModel,
      DataSourceType.IModelGis,
      DataSourceType.IModel,
      DataSourceType.Shapefile,
      DataSourceType.Pix4d,
      DataSourceType.RealityCapture,
      DataSourceType.LandXml,
      DataSourceType.Simple3dModel,
    ]
  }
} as Record<ConfigurationFields, Partial<Record<FeatureLayerType, DataSourceType[]>>>;
