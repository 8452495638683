import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'mee-test-crop-upload',
  templateUrl: './test-crop-upload.component.html',
  styleUrls: ['./test-crop-upload.component.scss']
})
export class TestCropUploadComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(protected formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.formBuilder.group({
      LogoUrl: [''],
    });
  }
}
