export const DEFAULT_MAP_OPTIONS: google.maps.MapOptions = {
  center: {
    lat: 43.650298697765884,
    lng: -79.3772292137146
  },
  zoom: 2,
  minZoom: 2,
  mapTypeId: 'hybrid',
  styles: [
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      stylers: [{ visibility: 'off' }],
    },
  ],
}
