import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ExcludedGlobalErrorsLogicUrlsService {
  private urlsListToExcludeFromGlobalErrorLogic: string[] = [];

  setUrls(newUrl: string) {
    this.urlsListToExcludeFromGlobalErrorLogic.push(newUrl);
  }

  getUrls() {
    return this.urlsListToExcludeFromGlobalErrorLogic;
  }
}
