import { ConfigurationFields } from './enums/configuration-fields.enum';
import { DataSourceType } from '../../../../data-source-type.enum';
import { CONFIGURATION_FIELDS_DISPLAY_SOURCE_TYPES } from './models/configuration-fields-display-source-types.model';
import { FeatureLayerType } from './interfaces/feature-layer-type.enum';

export function isDisplayForCurrentSourceType(fieldName: ConfigurationFields,
                                              featureLayerType: FeatureLayerType,
                                              currentSourceType: DataSourceType) {
  let isDisplay = false;

  const sourceTypesForDisplay = CONFIGURATION_FIELDS_DISPLAY_SOURCE_TYPES[fieldName][featureLayerType];

  if (sourceTypesForDisplay === undefined) {
    return false;
  }

  sourceTypesForDisplay.forEach(sourceTypeForDisplay => {
    if (sourceTypeForDisplay === currentSourceType) {
      isDisplay = true;
    }
  });

  return isDisplay;
}
