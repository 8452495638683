// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.process-status {
  display: flex;
  align-items: center;
}
.process-status span {
  margin-left: 10px;
}
.process-status i {
  width: 20px;
  height: 20px;
  margin-top: -4px;
}
.process-status img {
  animation: spin 2s linear infinite;
  vertical-align: sub;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.process-status .blue-color {
  color: #0077ee;
}
.process-status .red-color {
  color: red;
}
.process-status .green-color {
  color: #4caf50;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/process-status/process-status.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AAAJ;AAGE;EAGE,kCAAA;EACA,mBAAA;AADJ;AAcE;EACE;IAEE,yBAAA;EAFJ;AACF;AAKE;EACE,cAAA;AAHJ;AAME;EACE,UAAA;AAJJ;AAOE;EACE,cAAA;AALJ","sourcesContent":[".process-status {\n  display: flex;\n  align-items: center;\n\n  span {\n    margin-left: 10px;\n  }\n\n  i {\n    width: 20px;\n    height: 20px;\n    margin-top: -4px;\n  }\n\n  img {\n    -webkit-animation: spin 2s linear infinite;\n    -moz-animation: spin 2s linear infinite;\n    animation: spin 2s linear infinite;\n    vertical-align: sub;\n  }\n\n  @-moz-keyframes spin {\n    100% {\n      -moz-transform: rotate(360deg);\n    }\n  }\n  @-webkit-keyframes spin {\n    100% {\n      -webkit-transform: rotate(360deg);\n    }\n  }\n  @keyframes spin {\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n  }\n\n  .blue-color {\n    color: #0077ee;\n  }\n\n  .red-color {\n    color: red;\n  }\n\n  .green-color {\n    color: #4caf50;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
