import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import { ILayer } from '../../../../../../components/layers/shared/models/layer.interface';
import { DataSourceType } from '../../../../../data-source-type.enum';
import { PlacemarksFields } from '../../../models/placemarks-fields';
import { SourceConfiguration } from '../interfaces/source-configuration';
import { SingleForm } from '../interfaces/single-form';
import isEqual from 'lodash-es/isEqual';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ConfigurationFieldsAdapterService } from '../configuration-fields-adapter.service';
import { tap } from 'rxjs/operators';
import { ConfigurationFields } from '../enums/configuration-fields.enum';
import { isDisplayForCurrentSourceType } from '../is-display-for-current-source-type.function';
import { FeatureLayerType } from '../interfaces/feature-layer-type.enum';
import { ObjectType } from '../enums/object-types.enum';

@Component({
  selector: 'mee-unknown-configuration',
  templateUrl: './unknown-configuration.component.html',
  styleUrls: ['./unknown-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnknownConfigurationComponent implements SingleForm {

  form: UntypedFormGroup;

  localNode: SourceConfiguration;

  localLayers: ILayer[];

  configurationFields = ConfigurationFields;

  objectTypesOptions = this.configurationFieldsAdapterService.getObjectTypesOptions();

  altitudeModesOptions = this.configurationFieldsAdapterService.getAltitudeModesOptions();

  tooltipTextForSubtypeField: string;

  @Input() sourceType: DataSourceType;

  @Input() placeMarksFields: PlacemarksFields;
  @Output() updateLayerGroup = new EventEmitter();

  @Input() set layers(layers: ILayer[]) {

    this.localLayers = layers;

    if (layers.length === 0) {
      this.form.get('Layer').disable();
    }
  };

  get layers() {
    return this.localLayers;
  }

  @Input() set node(node: SourceConfiguration) {

    if (node === undefined) {
      return;
    }

    if (isEqual(this.node, node)) {
      return;
    }

    this.localNode = node;

    this.form.patchValue({
      Id: this.node.Id,
      Layer: this.node.Layer,
      ObjectType: this.node.ObjectType,
      Title: this.node.Title,
      TitleField: this.node.TitleField,
      MainColor: this.node.MainColor,
      ObjectTypeConfiguration: this.formBuilder.group({
        ObjectType: this.node.ObjectType,
      }),
      SubtypeField: this.node.SubtypeField,
      ExternalIdField: this.node.ExternalIdField,
      LabelConfiguration: this.node.LabelConfiguration,
      PlacemarkImage: this.node.PlacemarkImage,
      AltitudeMode: this.node.AltitudeMode,
      IsExcludedFromProcessing: this.node.IsExcludedFromProcessing,
    }, { emitEvent: false });

    this.setDefaultAdaptedValues(node);
  }

  get node() {
    return this.localNode;
  }

  get isTitleNotEmpty() {
    return this.form.get('Title').value !== null;
  }

  get isNodeHaveSublayer() {
    return this.node.SubtypesConfigurations.length > 0;
  }

  constructor(private formBuilder: UntypedFormBuilder,
              private configurationFieldsAdapterService: ConfigurationFieldsAdapterService) {
    this.initForm();
    this.onChangeObjectType();
  }

  updateLayers(): void {
    this.updateLayerGroup.emit();
  }

  isDisplayForCurrentSourceType(fieldName: ConfigurationFields) {
    return isDisplayForCurrentSourceType(fieldName, FeatureLayerType.Unknown, this.sourceType);
  }

  clearTitle() {
    this.form.get('Title').reset();
  }

  private onChangeObjectType() {
    this.form.get('ObjectType').valueChanges
      .pipe(
        tap((objectType: ObjectType) => {
          this.form.get('ObjectTypeConfiguration').get('ObjectType').setValue(objectType);
        }),
      ).subscribe();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      Id: [],
      Layer: [],
      ObjectType: [],
      Title: [],
      TitleField: [],
      MainColor: [],
      ObjectTypeConfiguration: this.formBuilder.group({
        ObjectType: [],
      }),
      SubtypeField: [],
      ExternalIdField: [],
      LabelConfiguration: [],
      PlacemarkImage: [],
      AltitudeMode: [],
      IsExcludedFromProcessing: [],
    });
  }

  private setDefaultAdaptedValues(node: SourceConfiguration) {
    const adaptedDefaultValues = this.configurationFieldsAdapterService.getAdaptedDefaultValues(node);

    if (node.AltitudeMode === null) {
      this.form.get('AltitudeMode').setValue(adaptedDefaultValues.AltitudeMode, {emitEvent: false});
    }
  }
}
