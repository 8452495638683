import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { SingleForm } from '../interfaces/single-form';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ILayer } from '../../../../../../components/layers/shared/models/layer.interface';
import { DataSourceType } from '../../../../../data-source-type.enum';
import { SourceConfiguration } from '../interfaces/source-configuration';
import isEqual from 'lodash-es/isEqual';
import { ConfigurationFieldsAdapterService } from '../configuration-fields-adapter.service';
import { PlacemarksFields } from '../../../models/placemarks-fields';
import { ConfigurationFields } from '../enums/configuration-fields.enum';
import { isDisplayForCurrentSourceType } from '../is-display-for-current-source-type.function';
import { Symbology } from '../enums/symbology.enum';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Point3DShape } from '../../../../../point3dshape.enum';
import { takeUntil, tap } from 'rxjs/operators';
import { ObjectType } from '../enums/object-types.enum';
import { Subject } from 'rxjs';
import { FeatureLayerType } from '../interfaces/feature-layer-type.enum';
import { isDisableForCurrentSourceType } from '../is-disable-for-current-source-type.function';

@Component({
  selector: 'mee-point-configuration',
  templateUrl: './point-configuration.component.html',
  styleUrls: ['./point-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointConfigurationComponent implements SingleForm, OnInit, OnDestroy {
  form: UntypedFormGroup;

  localNode: SourceConfiguration;

  localLayers: ILayer[];

  configurationFields = ConfigurationFields;

  symbology = Symbology;

  point3DShape = Point3DShape;

  tooltipTextForSubtypeField: string;

  altitudeModesOptions = this.configurationFieldsAdapterService.getAltitudeModesOptions();

  objectTypesOptions = this.configurationFieldsAdapterService.getObjectTypesOptions();

  point3DShapesOptions = this.configurationFieldsAdapterService.getPoint3DShapes();

  measurementSystemOptions = this.configurationFieldsAdapterService.getMeasurementSystemOptions();

  private destroy$ = new Subject<void>();

  @Input() placeMarksFields: PlacemarksFields;

  @Input() sourceType: DataSourceType;
  @Output() updateLayerGroup = new EventEmitter();

  @Input() set layers(layers: ILayer[]) {
    this.localLayers = layers;

    if (layers.length === 0) {
      this.form.get('Layer').disable();
    }
  }

  get layers() {
    return this.localLayers;
  }

  @Input() set node(node: SourceConfiguration) {
    if (node === undefined) {
      return;
    }

    if (isEqual(this.node, node)) {
      return;
    }

    // for settle initial settings from backend for point object type

    this.reInitFormAfterChangeObjectType(node);

    this.localNode = node;

    this.form.patchValue(this.node, { emitEvent: false });

    this.setDefaultAdaptedValues(node);
  }

  get node() {
    return this.localNode;
  }

  get isTitleNotEmpty() {
    return this.form.get('Title').value !== null;
  }

  get mainColor() {
    return this.form.get('MainColor').value;
  }

  constructor(private formBuilder: UntypedFormBuilder,
              private configurationFieldsAdapterService: ConfigurationFieldsAdapterService) {
    this.initForm();
  }

  ngOnInit() {

    this.onChangeObjectType();

    this.disableFields();
  }

  updateLayers(): void {
    this.updateLayerGroup.emit();
  }

  onChangeSymbology(event: MatButtonToggleChange) {
    this.form.get('UseVGisSymbols').setValue(event.value === Symbology.vGIS);
  }

  isDisplayForCurrentSourceType(fieldName: ConfigurationFields) {
    return isDisplayForCurrentSourceType(fieldName, FeatureLayerType.Point, this.sourceType);
  }

  isDisableForCurrentSourceType(fieldName: ConfigurationFields) {
    return isDisableForCurrentSourceType(fieldName, FeatureLayerType.Model3d, this.sourceType);
  }

  isNodeHaveSublayer() {
    return this.node.SubtypesConfigurations.length > 0;
  }

  clearTitle() {
    this.form.get('Title').reset();
  }

  private reInitFormAfterChangeObjectType(node: SourceConfiguration) {
    const objectTypeConfiguration = this.form.get('ObjectTypeConfiguration') as UntypedFormGroup;

    const nodeObjectTypeConfiguration = node.ObjectTypeConfiguration;

    if (Object.keys(nodeObjectTypeConfiguration).length > Object.keys(objectTypeConfiguration.controls).length) {
      this.initForm();
    }
  }

  private initForm() {
    this.form = this.formBuilder.group({
      Id: [],
      Layer: [],
      ObjectType: [],
      Title: [],
      TitleField: [],
      MainColor: [],
      UseVGisSymbols: [],
      DefaultDepth: [],
      DepthField: [],
      ObjectTypeConfiguration: this.formBuilder.group({
        ObjectType: [],
        Subtype: [],
        BoundingBoxShape: [],
        UndergroundConnectorDepth: [],
        BoundingBoxHeight: [],
        BoundingBoxHeightField: [],
        BoundingBoxWidth: [],
        BoundingBoxWidthField: [],
      }),
      SubtypeField: [],
      ExternalIdField: [],
      LabelConfiguration: [],
      PlacemarkImage: [],
      AltitudeMode: [],
      IsExcludedFromProcessing: [],
    });
  }

  private disableFields() {
    Object.keys(this.form.controls).forEach((controlName: ConfigurationFields) => {
      if (this.isDisableForCurrentSourceType(controlName)) {
        this.form.get(controlName).disable();
      }
    });
  }

  private onChangeObjectType() {
    this.form
      .get('ObjectType')
      .valueChanges.pipe(
        tap((newObjectType: ObjectType) => {
          this.form.get('ObjectTypeConfiguration').get('ObjectType').setValue(newObjectType);

          if (this.isNodeHaveSublayer()) {
            this.tooltipTextForSubtypeField = 'configurations-fields.tooltips.DisabledSubtypeField';
            this.form.get('SubtypeField').disable();

            return;
          }

          this.tooltipTextForSubtypeField = 'configurations-fields.tooltips.SubtypeField';
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private setDefaultAdaptedValues(node: SourceConfiguration) {
    const adaptedDefaultValues = this.configurationFieldsAdapterService.getAdaptedDefaultValues(node);

    if (node.AltitudeMode === null) {
      this.form.get('AltitudeMode').setValue(adaptedDefaultValues.AltitudeMode, { emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
