import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class DateFormatterService {
  constructor() {}

  getInMomentFormat(initialDate) {
    return moment(initialDate);
  }

  getFormattedToLocalTime(initialDate) {
    return moment(initialDate).format('MMM D, YYYY hh:mm a');
  }

  getFormattedToLocalDate(initialDate: string) {
    return moment(initialDate).format('YYYY-MM-DD');
  }

  getDurationBetweenTwoDates(startDate, endDate, format) {
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);

    let duration;
    let durationHours;
    let durationMinutes;
    let durationSeconds;

    const date = moment(new Date().toISOString());

    if (endDate) {
      duration = moment.duration(momentEndDate.diff(momentStartDate));
    } else {
      duration = moment.duration(date.diff(momentStartDate));
    }

    durationHours = duration.hours();
    durationMinutes = duration.minutes();
    durationSeconds = duration.seconds();

    if (format === 'HMS') {
      return durationHours + 'h ' + durationMinutes + 'm ' + durationSeconds + 's';
    } else if (format === 'mS') {
      return duration.asMilliseconds();
    }
  }

  getTimeWithUtcOffset(initialDate, utcOffset: number, timeFormat: string) {
    return initialDate.add(utcOffset, timeFormat).format('HH:mm A');
  }
}
