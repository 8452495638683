import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromptComponent } from './prompt.component';
import { PromptService } from './prompt.service';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import {TranslateModule} from '@ngx-translate/core';

const components = [
  PromptComponent
];

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        TranslateModule,
    ],
    declarations: components,
    providers: [
        PromptService
    ],
    exports: components
})
export class PromptModule { }
