import { Component, Inject, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { IDataSource, IProcessingLogRecords } from '../../../../models/data-source.interface';
import { DataSourcesService } from '../../../../services/data-sources.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import forEach from 'lodash-es/forEach';
import merge from 'lodash-es/merge';
import { ProcessingResult } from '../../models/processing-result.enum';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProcessingLogsData } from '../../../../models/processing-logs-data';

@Component({
  selector: 'mee-source-statistic',
  templateUrl: './source-statistic.component.html',
  styleUrls: ['./source-statistic.component.scss'],
  providers: [],
})
export class SourceStatisticComponent implements OnChanges {
  private companySlug: string;

  private sourceSlug: string;

  logs: IProcessingLogRecords[] = [];

  placemarkContent: any;
  processingResult = ProcessingResult;

  @ViewChild('tree') tree;

  collapsedTree = true;

  constructor(private dataSourcesService: DataSourcesService, private snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) private data: ProcessingLogsData) {
    this.companySlug = data.companySlug;
    this.sourceSlug = data.sourceSlug;
    this.logs = data.logs;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.placemarkContent = null;
  }

  mergeChildLogs(parent: IDataSource, nextChildren: { [key: string]: IDataSource }) {
    forEach(nextChildren, child => {
      merge(parent.Statistic.ProcessingLogRecords, child.Statistic.ProcessingLogRecords);
      this.mergeChildLogs(parent, child.Children);
    });
  }

  /*TODO find what placemark content variable are*/

  loadPlacemark(placemarkId: string) {
    this.placemarkContent = null;
    this.dataSourcesService
      .getPlacemarks(this.companySlug, this.sourceSlug, placemarkId)
      .pipe(
        tap(res => (this.placemarkContent = res)),
        catchError(error => {
          this.snackBar.open(error.statusText, 'OK');
          return of(error);
        }),
      )
      .subscribe();
  }
}
