import { Component, OnInit } from '@angular/core';
import { IdentityService } from '../../../services/identity.service';
import { Identity } from '../../../interfaces/identity';

@Component({
  selector: 'mee-toolbar-user-button',
  templateUrl: './toolbar-user-button.component.html',
  styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit {

  isOpen: boolean;

  private identity: Identity;

  constructor(private identityService: IdentityService) {
    this.identity = identityService.identity;

    identityService.onUpdated.subscribe(identity => this.identity = identity);
  }

  get name(): string {
    return `${this.identity.FirstName} ${this.identity.LastName}`;
  }

  ngOnInit() {
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  logout() {
    this.identityService.logout();
  }
}
