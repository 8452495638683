// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.export {
  display: flex;
  width: 80px;
  cursor: pointer;
}
.export .export-title {
  margin: 3px 0 0 7px !important;
  color: #0077EE;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/export-data/export-data-button/export-data-button.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,eAAA;AACF;AACE;EACE,8BAAA;EACA,cAAA;AACJ","sourcesContent":[".export {\n  display: flex;\n  width: 80px;\n  cursor: pointer;\n\n  .export-title {\n    margin: 3px 0 0 7px !important;\n    color: #0077EE;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
