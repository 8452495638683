import { MeasurementSystem } from '../components/configuration-tab/interfaces/measurement-system.enum';

export const MEASUREMENT_SYSTEM_MODEL = [
  {
    value: MeasurementSystem.Meters,
    label: 'm'
  },
  {
    value: MeasurementSystem.Feet,
    label: 'ft'
  },
  {
    value: MeasurementSystem.Inches,
    label: 'in'
  },
  {
    value: MeasurementSystem.Centimeters,
    label: 'cm'
  },
  {
    value: MeasurementSystem.Millimeters,
    label: 'mm'
  },
];
