// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}
:host .table-place {
  height: 100%;
  margin: 16px;
  padding: 6px 16px 0;
  overflow-y: auto;
}
:host .table-place .mat-card-content {
  overflow-x: auto;
}
:host .table-place .mat-input-element:disabled {
  color: inherit;
}
:host .table-place .preview-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  text-decoration: underline;
  color: #3f51b5;
}
:host .table-place hr {
  border: 0;
  border-bottom: 1px dashed #ccc;
  background: #999;
}
:host .table-place .source-view-table {
  width: 100%;
}
:host .table-place .source-view-table th {
  padding: 10px;
}
:host .table-place .source-view-table th:first-child {
  padding-left: 0;
}
:host .table-place .source-view-table tr td {
  border-bottom: 1px solid #e0e0e0;
  vertical-align: top;
  padding: 10px;
}
:host .table-place .source-view-table tr td:first-child {
  padding-left: 0;
  font-weight: bold;
  width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/sources/components/process-report/process-report.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACF;AACE;EACE,YAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AACI;EACE,gBAAA;AACN;AAEI;EACE,cAAA;AAAN;AAGI;EACE,iDAAA;EACA,0BAAA;EACA,cAAA;AADN;AAII;EACE,SAAA;EACA,8BAAA;EACA,gBAAA;AAFN;AAKI;EACE,WAAA;AAHN;AAKM;EACE,aAAA;AAHR;AAIQ;EACE,eAAA;AAFV;AAOQ;EACE,gCAAA;EACA,mBAAA;EACA,aAAA;AALV;AAOU;EACE,eAAA;EACA,iBAAA;EACA,YAAA;AALZ","sourcesContent":[":host {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n\n  .table-place {\n    height: 100%;\n    margin: 16px;\n    padding: 6px 16px 0;\n    overflow-y: auto;\n\n    .mat-card-content {\n      overflow-x: auto;\n    }\n\n    .mat-input-element:disabled {\n      color: inherit;\n    }\n\n    .preview-link {\n      font-family: Roboto, \"Helvetica Neue\", sans-serif;\n      text-decoration: underline;\n      color: #3f51b5;\n    }\n\n    hr {\n      border: 0;\n      border-bottom: 1px dashed #ccc;\n      background: #999;\n    }\n\n    .source-view-table {\n      width: 100%;\n\n      th {\n        padding: 10px;\n        &:first-child {\n          padding-left: 0;\n        }\n      }\n\n      tr {\n        td {\n          border-bottom: 1px solid #e0e0e0;\n          vertical-align: top;\n          padding: 10px;\n\n          &:first-child {\n            padding-left: 0;\n            font-weight: bold;\n            width: 300px;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
