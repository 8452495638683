// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}

.team-admin .material-icons {
  opacity: 0.4;
}
.team-admin mat-cell.permission {
  cursor: default;
}

mat-table {
  width: 100%;
}
mat-table mat-header-cell {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding-top: 24px;
  padding-bottom: 19px;
  color: rgba(13, 22, 33, 0.6);
  box-sizing: border-box;
  color: black;
}
mat-table mat-header-cell:first-child {
  padding-left: 0;
}
mat-table mat-cell:first-child {
  padding-left: 0;
}
mat-table mat-cell.permission {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  flex: 0.4;
}
mat-table mat-cell .red-color {
  color: #EE2233;
}
mat-table mat-cell .green-color {
  color: #77CC33;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/permissions-crud/permissions-crud.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAGE;EACE,YAAA;AAAJ;AAII;EACE,eAAA;AAFN;;AAOA;EACE,WAAA;AAJF;AAME;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,oBAAA;EACA,4BAAA;EACA,sBAAA;EACA,YAAA;AAJJ;AAMI;EACE,eAAA;AAJN;AAUI;EACE,eAAA;AARN;AAWI;EACE,eAAA;EACA,yBAAA;UAAA,iBAAA;EACA,SAAA;AATN;AAYI;EACE,cAAA;AAVN;AAaI;EACE,cAAA;AAXN","sourcesContent":[":host {\n  width: 100%;\n}\n\n.team-admin {\n  .material-icons {\n    opacity: 0.4;\n  }\n\n  mat-cell {\n    &.permission {\n      cursor: default;\n    }\n  }\n}\n\nmat-table {\n  width: 100%;\n\n  mat-header-cell {\n    font-style: normal;\n    font-weight: 500;\n    font-size: 12px;\n    line-height: 14px;\n    padding-top: 24px;\n    padding-bottom: 19px;\n    color: rgba(13, 22, 33, 0.6);\n    box-sizing: border-box;\n    color: black;\n\n    &:first-child {\n      padding-left: 0;\n    }\n  }\n\n  mat-cell {\n\n    &:first-child {\n      padding-left: 0;\n    }\n\n    &.permission {\n      cursor: pointer;\n      user-select: none;\n      flex: 0.4;\n    }\n\n    .red-color {\n      color: #EE2233;\n    }\n\n    .green-color {\n      color: #77CC33;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
