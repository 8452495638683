import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateDeviceComponent } from './create-device/create-device.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ColorPickerModule } from 'ngx-color-picker';
import { SharedModule } from '../../shared/shared.module';
import { AddLayerComponent } from './add-layer/add-layer.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { AddDataSourceComponent } from './add-data-source/add-data-source.component';
import { UploaderModule } from '../../core/uploader/uploader.module';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AddDtmComponent } from './add-dtm/add-dtm.component';
import { ArcgisCustomDtmFormComponent } from './add-dtm/arcgis-custom-dtm-form/arcgis-custom-dtm-form.component';
import { SourcesModule } from '../sources/sources.module';

@NgModule({
    declarations: [
        CreateDeviceComponent,
        AddLayerComponent,
        AddDataSourceComponent,
        AddDtmComponent,
        ArcgisCustomDtmFormComponent,
    ],
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        TranslateModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSlideToggleModule,
        MatCardModule,
        MatRippleModule,
        MatIconModule,
        MatButtonModule,
        MatSelectModule,
        ColorPickerModule,
        SharedModule,
        MatDialogModule,
        UploaderModule,
        MatListModule,
        SharedModule,
        MatExpansionModule,
        ClipboardModule,
        FormsModule,
        SourcesModule,
    ],
    exports: [
        CreateDeviceComponent,
        AddLayerComponent,
        AddDataSourceComponent,
        AddDtmComponent,
    ]
})
export class GlobalAddModule {}
