// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-place {
  height: 100%;
  margin: 0 16px 16px;
  padding: 16px 16px 0;
  overflow-y: auto;
}

::ng-deep .table-wrapper {
  height: 85vh;
}
::ng-deep mat-card {
  overflow: hidden !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/sources/components/process-list/process-list.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;AACF;;AAGE;EACE,YAAA;AAAJ;AAEE;EACE,2BAAA;AAAJ","sourcesContent":[".form-place {\n  height: 100%;\n  margin: 0 16px 16px;\n  padding: 16px 16px 0;\n  overflow-y: auto;\n}\n\n::ng-deep {\n  .table-wrapper {\n    height: 85vh;\n  }\n  mat-card {\n    overflow: hidden !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
