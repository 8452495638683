// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-row {
  margin-bottom: 8px;
}
.form-row::ng-deep .mat-form-field .mat-form-field-wrapper {
  padding-bottom: 20px;
}
.form-row::ng-deep .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  padding: 8px 0;
  border-top: 12px solid transparent;
}
.form-row::ng-deep .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element, .form-row::ng-deep .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select {
  display: flex;
  max-height: 16px;
  margin: 0;
}
.form-row::ng-deep .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
  top: -12px;
  padding-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/core/categories/category-autocomplete/category-autocomplete.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAGM;EACE,oBAAA;AADR;AAIU;EACE,cAAA;EACA,kCAAA;AAFZ;AAIY;EACE,aAAA;EACA,gBAAA;EACA,SAAA;AAFd;AAKY;EACE,UAAA;EACA,iBAAA;AAHd","sourcesContent":[".form-row {\n  margin-bottom: 8px;\n\n  &::ng-deep {\n    .mat-form-field {\n      .mat-form-field-wrapper {\n        padding-bottom: 20px;\n\n        .mat-form-field-flex {\n          .mat-form-field-infix {\n            padding: 8px 0;\n            border-top: 12px solid transparent;\n\n            .mat-input-element, .mat-select {\n              display: flex;\n              max-height: 16px;\n              margin: 0;\n            }\n\n            .mat-form-field-label-wrapper {\n              top: -12px;\n              padding-top: 12px;\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
