import { Injectable } from '@angular/core';
import { RestClass, RestService } from '../../../../../services/rest/rest.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ILayer } from '../models/layer.interface';
import { Observable } from 'rxjs';
import { RemoveLayerData } from '../models/remove-layer-data';
import { LayerUpdateData } from '../models/layer-update-data';
import { IdentityService } from '../../../../../services/identity.service';

@Injectable({
  providedIn: 'root',
})
@RestClass('/layers')
export class LayersService extends RestService<any> {
  constructor(protected http: HttpClient, private identityService: IdentityService) {
    super(http);
  }

  getRouteByCompany(companySlug: string, ...params): string {
    return `${this.baseUrl}/companies/${companySlug}/` + params.join('/');
  }

  getLayersByCompany(companySlug: string): Observable<ILayer[]> {
    return this.http.get<ILayer[]>(this.getRouteByCompany(companySlug, 'layers'));
  }

  create(companySlug: string, data: any) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.post(this.getRouteByCompany(companySlug, 'layers'), data, { params });
  }

  get(companySlug: string, layerId: string): Observable<ILayer> {
    return this.http.get<ILayer>(this.getRouteByCompany(companySlug, 'layers', layerId));
  }

  update(companySlug: string, layerId: string, data: LayerUpdateData) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.put(this.getRouteByCompany(companySlug, 'layers', layerId), data, { params });
  }

  remove(companySlug: string, layerId: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.delete(this.getRouteByCompany(companySlug, 'layers', layerId), { params });
  }

  multiRemove(data: RemoveLayerData[]): Observable<null> {
    return this.http.delete<null>(`${this.baseUrl}/layers`, { body: data });
  }

  sync(companySlug: string, enabledDataSourcesIds: Array<string>): Observable<ILayer[]> {
    return this.http.post<ILayer[]>(this.getRouteByCompany(companySlug, 'sync-layers'), enabledDataSourcesIds);
  }
}
