// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.script-editor-wrapper {
  width: 100%;
  height: 100%;
}
.script-editor-wrapper .script-editor {
  width: 100%;
}
.script-editor-wrapper .script-editor .mat-form-field {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/sources/components/source-configuration-list/components/script-editor-tab/script-editor-tab.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;AACJ;AACI;EACE,WAAA;AACN","sourcesContent":[".script-editor-wrapper {\n  width: 100%;\n  height: 100%;\n\n  .script-editor {\n    width: 100%;\n\n    .mat-form-field {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
