// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overflow-container {
  height: 100%;
  overflow-x: hidden;
  position: absolute;
  width: 100%;
}

.text-padding {
  padding: 24px;
}

p {
  color: #444;
}

.h1 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 0;
}

h4 {
  color: #212121;
}`, "",{"version":3,"sources":["webpack://./src/app/core/quickpanel/quickpanel.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".overflow-container {\n  height: 100%;\n  overflow-x: hidden;\n  position: absolute;\n  width: 100%;\n}\n\n.text-padding {\n  padding: 24px;\n}\n\np {\n  color: #444;\n}\n\n.h1 {\n  font-size: 32px;\n  font-weight: 500;\n  margin-bottom: 0;\n}\n\nh4 {\n  color: #212121;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
