import { AfterViewInit, Component, Inject, Injector } from '@angular/core';
import { ComponentPortal, Portal } from '@angular/cdk/portal';
import { ProcessingHistoryTableComponent } from '../source-form-scheduling/processing-history-table/processing-history-table.component';
import { ProcessDetailsModalComponent } from '../../process-details/process-details-modal.component';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProcessingDetailsModalData } from '../../../models/processing-details-modal-data';
import { PROCESSING_HISTORY_CALLBACK, PROCESSING_HISTORY_DATA } from '../../../models/processing-history-data';
import { Process } from '../../../models/process.interface';

@Component({
  selector: 'mee-processing-history',
  templateUrl: './processing-history.component.html',
  styleUrls: ['./processing-history.component.scss'],
})
export class ProcessingHistoryComponent implements AfterViewInit {
  selectedPortal: Portal<any>;
  private processingDetailsFactory = () => (data: ProcessingDetailsModalData): void => {
    this.initProcessingDetails(data);
  };

  private closeDetailsFactory = () => (): void => {
    this.initProcessingList();
  };

  constructor(@Inject(MAT_DIALOG_DATA) private processesList: Process[]) {}

  ngAfterViewInit(): void {
    this.initProcessingList();
  }

  private initProcessingList(): void {
    this.selectedPortal = new ComponentPortal(
      ProcessingHistoryTableComponent,
      null,
      Injector.create({
        providers: [
          {
            provide: PROCESSING_HISTORY_DATA,
            useValue: this.processesList,
          },
          {
            provide: PROCESSING_HISTORY_CALLBACK,
            useFactory: this.processingDetailsFactory,
          },
        ],
      }),
    );
  }

  private initProcessingDetails(data: ProcessingDetailsModalData): void {
    this.selectedPortal = new ComponentPortal(
      ProcessDetailsModalComponent,
      null,
      Injector.create({
        providers: [
          {
            provide: PROCESSING_HISTORY_DATA,
            useValue: data,
          },
          {
            provide: PROCESSING_HISTORY_CALLBACK,
            useFactory: this.closeDetailsFactory,
          },
        ],
      }),
    );
  }
}
