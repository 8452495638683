// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
}

.search {
  width: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s cubic-bezier(0.35, 0, 0.25, 1);
  margin-top: 7px;
}
.search.search-open {
  width: 250px;
  visibility: visible;
  opacity: 1;
  margin-top: 11px;
}

.search-button {
  border-radius: 0;
  padding: 0;
  min-width: 50px;
  max-width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/core/toolbar/search/search.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,QAAA;EACA,gBAAA;EACA,UAAA;EACA,kBAAA;EACA,mDAAA;EACA,eAAA;AACF;AACE;EACE,YAAA;EACA,mBAAA;EACA,UAAA;EACA,gBAAA;AACJ;;AAGA;EACE,gBAAA;EACA,UAAA;EACA,eAAA;EACA,eAAA;AAAF","sourcesContent":[":host {\r\n  height: 100%;\r\n}\r\n\r\n.search {\r\n  width: 0;\r\n  overflow: hidden;\r\n  opacity: 0;\r\n  visibility: hidden;\r\n  transition: all .4s cubic-bezier(.35,0,.25,1);\r\n  margin-top: 7px;\r\n\r\n  &.search-open {\r\n    width: 250px;\r\n    visibility: visible;\r\n    opacity: 1;\r\n    margin-top: 11px;\r\n  }\r\n}\r\n\r\n.search-button {\r\n  border-radius: 0;\r\n  padding: 0;\r\n  min-width: 50px;\r\n  max-width: 50px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
