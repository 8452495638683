import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PermissionType } from '../../enums/permission-type.enum';
import { CrudOperations } from '../../enums/crud-operations.enum';
import { TablePermission } from '../../interfaces/table-permission';
import { PermissionsAdapterService } from '../../services/permissions-adapter.service';
import { Permission } from '../../interfaces/permission';
import { IdentityService } from '../../../services/identity.service';

@Component({
  selector: 'mee-permissions-crud',
  templateUrl: './permissions-crud.component.html',
  styleUrls: ['./permissions-crud.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PermissionsCrudComponent {

  permissionType = PermissionType;

  crudOperations = CrudOperations;

  permissionsData: Record<CrudOperations, TablePermission>[];

  displayedColumns = [
    'commonPhrases.CRUD.read',
    'commonPhrases.CRUD.update',
    'commonPhrases.CRUD.create',
    'commonPhrases.CRUD.delete',
  ];

  columnsList = [CrudOperations.Read, CrudOperations.Update, CrudOperations.Create, CrudOperations.Delete];

  @Output() onChangePermissions = new EventEmitter<Record<CrudOperations, TablePermission>>();

  @Input() set permissions(permissions: Permission[]) {
    this.permissionsData = this.permissionsAdapterService.getTablePermissions(permissions);
  };

  get isTeamAdmin() {
    return this.identityService.isTeamAdmin();
  }

  constructor(private permissionsAdapterService: PermissionsAdapterService,
              private identityService: IdentityService ) {}

  changePermission(operation: CrudOperations) {

    if (this.isTeamAdmin) {
      return;
    }

    this.permissionsData = this.permissionsData.map(data => {

      if (data[operation]) {
        data[operation].isAllowed = !data[operation].isAllowed;
      }

      return data;
    });

    const permission = this.permissionsData[0][operation];

    this.setRelativePermissions(permission);
  }

  private setRelativePermissions(permission: TablePermission) {

    const isPermissionAllowed = permission.isAllowed;

    if (isPermissionAllowed) {
      this.allowRelativePermissions(permission.allowRelative);
    } else {
      this.disallowRelativePermissions(permission.disallowRelative);
    }
  }

  private setNewPermissions() {
    this.onChangePermissions.emit(this.permissionsData[0]);
  }

  private allowRelativePermissions(crudOperations: Array<CrudOperations>) {
    this.permissionsData = this.permissionsData.map(data => {
      crudOperations.forEach(operation => {
        data[operation].isAllowed = true;
      });

      return data;
    });

    this.setNewPermissions();
  }

  private disallowRelativePermissions(crudOperations: Array<CrudOperations>) {

    this.permissionsData = this.permissionsData.map(data => {
      crudOperations.forEach(operation => {
        data[operation].isAllowed = false;
      });

      return data;
    });

    this.setNewPermissions();
  }
}
