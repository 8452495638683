import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationHeaderTemplateService {
  private templateRefSubject = new BehaviorSubject<TemplateRef<any>>(null);

  template$ = this.templateRefSubject.asObservable();

  get isTemplateEmpty(): boolean {
    return this.templateRefSubject.value === null;
  }

  setTemplate(template: TemplateRef<any>) {
    this.templateRefSubject.next(template);
  }
}
