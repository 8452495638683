// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.epsg-code-tips-wrapper .title_close {
  display: flex;
  justify-content: space-between;
}
.epsg-code-tips-wrapper .content {
  margin-top: 5px;
}
.epsg-code-tips-wrapper .content ol {
  list-style: none;
  counter-reset: item;
  margin-top: 0;
}
.epsg-code-tips-wrapper .content ol li {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  counter-increment: item;
}
.epsg-code-tips-wrapper .content ol li:before {
  content: counter(item);
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  margin-right: 5px;
  margin-left: -10px;
}
.epsg-code-tips-wrapper .content ol li span {
  display: inline-block;
  margin-bottom: 4px;
}
.epsg-code-tips-wrapper .content ol li img {
  margin-bottom: 16px;
  display: block;
}
.epsg-code-tips-wrapper .content .epsg-io-image {
  width: 464px;
  height: 233px;
}
.epsg-code-tips-wrapper .content .epsg-io-list-image {
  width: 464px;
  height: 242px;
}
.epsg-code-tips-wrapper .content .epsg-io-code-image {
  width: 464px;
  height: 153px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/sources/components/source-configuration-list/components/epsg-code-tips/epsg-code-tips.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,8BAAA;AAAJ;AAGE;EACE,eAAA;AADJ;AAGI;EACE,gBAAA;EACA,mBAAA;EACA,aAAA;AADN;AAGM;EACE,iCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;AADR;AAGQ;EACE,sBAAA;EACA,2CAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;EACA,iBAAA;EACA,kBAAA;AADV;AAIQ;EACE,qBAAA;EACA,kBAAA;AAFV;AAKQ;EACE,mBAAA;EACA,cAAA;AAHV;AAQI;EACE,YAAA;EACA,aAAA;AANN;AASI;EACE,YAAA;EACA,aAAA;AAPN;AAUI;EACE,YAAA;EACA,aAAA;AARN","sourcesContent":[".epsg-code-tips-wrapper {\n  .title_close {\n    display: flex;\n    justify-content: space-between;\n  }\n\n  .content {\n    margin-top: 5px;\n\n    ol {\n      list-style: none;\n      counter-reset: item;\n      margin-top: 0;\n\n      li {\n        font-family: 'Roboto', sans-serif;\n        font-size: 12px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 18px;\n        counter-increment: item;\n\n        &:before {\n          content: counter(item);\n          font-family: 'Roboto Condensed', sans-serif;\n          font-size: 14px;\n          font-style: normal;\n          font-weight: 700;\n          line-height: 16px;\n          text-transform: uppercase;\n          margin-right: 5px;\n          margin-left: -10px;\n        }\n\n        span {\n          display: inline-block;\n          margin-bottom: 4px;\n        }\n\n        img {\n          margin-bottom: 16px;\n          display: block;\n        }\n      }\n    }\n\n    .epsg-io-image {\n      width: 464px;\n      height: 233px;\n    }\n\n    .epsg-io-list-image {\n      width: 464px;\n      height: 242px;\n    }\n\n    .epsg-io-code-image {\n      width: 464px;\n      height: 153px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
