// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  height: 100%;
}
:host .stub-wrapper {
  margin: auto;
}
:host .stub-wrapper mat-icon {
  display: flex;
  margin: 0 auto 40px auto;
  width: 112px;
  height: 112px;
  font-size: 7em;
}
:host .stub-wrapper h1 {
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  text-transform: uppercase;
  text-align: center;
}
:host .stub-wrapper .in-case-of-new-request .system-reports {
  display: flex;
}
:host .stub-wrapper .in-case-of-new-request .system-reports p {
  align-self: center;
  margin: 10px 15px 0 80px;
}
:host .stub-wrapper .in-case-of-new-request .system-reports mee-date-picker {
  width: 25%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/stub/stub.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;AACF;AACE;EACE,YAAA;AACJ;AACI;EACE,aAAA;EACA,wBAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;AACN;AAEI;EACE,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,kBAAA;AAAN;AAIM;EACE,aAAA;AAFR;AAGQ;EACE,kBAAA;EACA,wBAAA;AADV;AAIQ;EACE,UAAA;AAFV","sourcesContent":[":host {\n  display: flex;\n  height: 100%;\n\n  .stub-wrapper {\n    margin: auto;\n\n    mat-icon {\n      display: flex;\n      margin: 0 auto 40px auto;\n      width: 112px;\n      height: 112px;\n      font-size: 7em;\n    }\n\n    h1 {\n      font-weight: bold;\n      font-size: 48px;\n      line-height: 56px;\n      text-transform: uppercase;\n      text-align: center;\n    }\n\n    .in-case-of-new-request {\n      .system-reports {\n        display: flex;\n        p {\n          align-self: center;\n          margin: 10px 15px 0 80px;\n        }\n\n        mee-date-picker {\n          width: 25%;\n        }\n      }\n    }\n  }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
