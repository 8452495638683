import { Injectable } from '@angular/core';
import { EMPTY, fromEvent, interval } from 'rxjs';
import { TelemetryTimings } from '../shared/models/telemetry-timings';
import { IdentityService } from './identity.service';
import { switchMap } from 'rxjs/operators';
import { TelemetryService } from '../modules/dashboard/telemetry.service';
import { TelemetryEvent } from '../shared/interfaces/telemetry-event';
import { TeamProjectService } from './team-project.service';
import { TelemetryEventType } from '../shared/interfaces/telemetry-event-type';
import { environment } from '../../environments/environment';

const LAST_TELEMETRY_REQUEST_TIME_PREFIX = 'last_telemetry_request_time';
const SESSION_ID_PREFIX = 'telemetry_session_id';
const TELEMETRY_PROJECT_NAME = 'Admin';

@Injectable({
  providedIn: 'root'
})
export class TelemetryEventService {
  private isPageInactive = document.hidden;

  get isExpiredSessionId(): boolean {
    const lastRequestTime = JSON.parse(localStorage.getItem(LAST_TELEMETRY_REQUEST_TIME_PREFIX));

    return !lastRequestTime || Date.now() - lastRequestTime > TelemetryTimings.ExpiredSessionIdTime;
  }

  constructor(
    private readonly identityService: IdentityService,
    private readonly telemetryService: TelemetryService,
    private readonly teamProjectService: TeamProjectService,
  ) {
  }

  runActiveTabWatcher(): void {
    fromEvent(document, 'visibilitychange').subscribe(() => this.isPageInactive = document.hidden);
  }

  runTelemetrySending(): void {
    interval(TelemetryTimings.RequestFrequency).pipe(
      switchMap(() => {
        if (!this.identityService.logged() || this.isPageInactive) {
          return EMPTY;
        }

        const requestTime = Date.now();
        let sessionId = localStorage.getItem(SESSION_ID_PREFIX) ?? this.generateUUID();

        if (this.isExpiredSessionId) {
          sessionId = this.generateUUID();
        }

        localStorage.setItem(SESSION_ID_PREFIX, sessionId);
        localStorage.setItem(LAST_TELEMETRY_REQUEST_TIME_PREFIX, JSON.stringify(requestTime));

        return this.telemetryService.sendTelemetryData(this.getTelemetryData(sessionId));
      })
    ).subscribe()
  }

  private generateUUID(): string {
    return crypto.randomUUID();
  }

  private getTelemetryData(sessionId: string): TelemetryEvent {
    const teamProject = this.teamProjectService.teamProject;
    const telemetryData: TelemetryEvent = {
      Id: this.generateUUID(),
      ApiVersion: environment.apiVersion,
      SessionId: sessionId,
      CompanySlug: teamProject.CompanySlug,
      CompanyId: teamProject.CompanyId,
      Project: TELEMETRY_PROJECT_NAME,
      EventType: TelemetryEventType.HeartBeat,
      CreationDateUtc: new Date().toISOString(),
      TeamSlug: teamProject.TeamSlug,
      TeamId: teamProject.TeamId
    }

    if (!this.identityService.isTeamAdmin()) {
      delete telemetryData.TeamId;
      delete telemetryData.TeamSlug;
    }

    return telemetryData;
  }
}
