import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './core/admin/admin.component';
import { AuthGuard } from './guards/auth.guard';
import { NotFoundComponent } from './core/errors/not-found/not-found.component';
import { CheckTokenResolver } from './core/check-token.resolver';
import { TeamResolver } from './modules/teams/resolvers/team.resolver';
import { TeamProjectsResolver } from './core/team-project-selector/team-projects.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'qr-code',
    loadChildren: () => import('./modules/qr-code/qr-code.module').then(m => m.QrCodeModule),
  },
  {
    path: '',
    loadChildren: () => import('./modules/identity/identity.module').then(m => m.IdentityModule),
  },
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: [CheckTokenResolver, TeamResolver, TeamProjectsResolver],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'companies',
        loadChildren: () => import('./modules/companies/companies.module').then(m => m.CompaniesModule),
      },
      {
        path: 'sources',
        loadChildren: () => import('./modules/sources/sources.module').then(m => m.SourcesModule),
      },
      {
        path: 'company',
        loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'issue-reports',
        loadChildren: () => import('./modules/field-reports/issue-reports.module').then(m => m.IssueReportsModule),
      },
      {
        path: 'data-collection',
        loadChildren: () =>
          import('./modules/data-collection/data-collection.module').then(m => m.DataCollectionModule),
      },
      {
        path: 'site-sketches',
        loadChildren: () => import('./modules/data-collection/site-sketches/site-sketches.module').then(m => m.SiteSketchesModule),
      },
      {
        path: 'teams',
        loadChildren: () => import('./modules/teams/teams.module').then(m => m.TeamsModule),
      },
      {
        path: 'team',
        loadChildren: () => import('./modules/team/team.module').then(m => m.TeamModule),
      },
      {
        path: 'system-reports',
        loadChildren: () => import('./modules/system-reports/system-reports.module').then(m => m.SystemReportsModule),
      },
      {
        path: 'reality-capture',
        loadChildren: () => import('./modules/reality-capture/reality-capture.module').then(m => m.RealityCaptureModule),
      },
      {
        path: 'help',
        loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule),
      },
      {
        path: 'components',
        loadChildren: () => import('./modules/components/components.module').then(m => m.ComponentsModule),
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules
    }),
  ],
  exports: [RouterModule],
})
export class RoutingModule {
}
