// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiselect-header {
  height: 58px;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(13, 22, 33, 0.2);
}
.multiselect-header .title-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 32px;
}
.multiselect-header .title-wrapper .title-place {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0d1621;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/multiselect-header/multiselect-header.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,uBAAA;EACA,2CAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AACJ;AACI;EACE,+BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACN","sourcesContent":[".multiselect-header {\n  height: 58px;\n  width: 100%;\n  background-color: white;\n  box-shadow: 0 2px 4px rgba(13, 22, 33, 0.2);\n\n  .title-wrapper {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    padding-left: 32px;\n\n    .title-place {\n      font-family: Roboto, sans-serif;\n      font-style: normal;\n      font-weight: 500;\n      font-size: 18px;\n      line-height: 22px;\n      color: #0d1621;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
