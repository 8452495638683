// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
form .coordinate-system-form-row ::ng-deep .coordinate-system-epsg::-webkit-inner-spin-button, form .coordinate-system-form-row ::ng-deep .coordinate-system-epsg ::-webkit-outer-spin-button {
  -moz-appearance: textfield;
  -webkit-appearance: none;
}
form .coordinate-system-form-row.manual-coordinates {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
form .coordinate-system-form-row.manual-coordinates .first-row, form .coordinate-system-form-row.manual-coordinates .second-row {
  display: flex;
  justify-content: space-between;
}
form .coordinate-system-form-row.manual-coordinates .first-row mat-form-field, form .coordinate-system-form-row.manual-coordinates .second-row mat-form-field {
  max-width: 128px;
  width: 100%;
  min-width: auto;
}
form .coordinate-system-form-row.uom-row {
  display: flex;
  gap: 16px;
}
form .coordinate-system-form-row.uom-row mat-form-field {
  min-width: auto;
}
form .coordinate-system-form-row.uom-row mat-form-field:first-child {
  max-width: 320px;
  width: 100%;
}
form .coordinate-system-form-row.uom-row mat-form-field:last-child {
  width: 72px;
}
form .coordinate-system-form-row.uom-row mat-form-field:last-child ::ng-deep .mat-form-field-wrapper .mat-form-field-infix .mat-select-trigger .mat-select-value {
  max-width: none;
}
form .coordinate-system-form-row.uom-row mat-form-field:last-child ::ng-deep .mat-form-field-wrapper .mat-form-field-infix .mat-select-trigger .mat-select-arrow-wrapper {
  display: none;
}
form .coordinate-system-form-row.uom-row mat-form-field mat-icon {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/default-coordinate-system/default-coordinate-system.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;AAIQ;EACE,0BAAA;EACA,wBAAA;AAFV;AAOI;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AALN;AAOM;EACE,aAAA;EACA,8BAAA;AALR;AAOQ;EACE,gBAAA;EACA,WAAA;EACA,eAAA;AALV;AAUI;EACE,aAAA;EACA,SAAA;AARN;AAUM;EACE,eAAA;AARR;AAUQ;EACE,gBAAA;EACA,WAAA;AARV;AAWQ;EACE,WAAA;AATV;AAekB;EACE,eAAA;AAbpB;AAgBkB;EACE,aAAA;AAdpB;AAsBQ;EACE,eAAA;AApBV","sourcesContent":["form {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n\n  .coordinate-system-form-row {\n    ::ng-deep {\n      .coordinate-system-epsg {\n        &::-webkit-inner-spin-button, ::-webkit-outer-spin-button {\n          -moz-appearance: textfield;\n          -webkit-appearance: none;\n        }\n      }\n    }\n\n    &.manual-coordinates {\n      display: flex;\n      flex-direction: column;\n      gap: 8px;\n\n      .first-row, .second-row {\n        display: flex;\n        justify-content: space-between;\n\n        mat-form-field {\n          max-width: 128px;\n          width: 100%;\n          min-width: auto;\n        }\n      }\n    }\n\n    &.uom-row {\n      display: flex;\n      gap: 16px;\n\n      mat-form-field {\n        min-width: auto;\n\n        &:first-child {\n          max-width: 320px;\n          width: 100%;\n        }\n\n        &:last-child {\n          width: 72px;\n\n          ::ng-deep {\n            .mat-form-field-wrapper {\n              .mat-form-field-infix {\n                .mat-select-trigger {\n                  .mat-select-value {\n                    max-width: none;\n                  }\n\n                  .mat-select-arrow-wrapper {\n                    display: none;\n                  }\n                }\n              }\n            }\n          }\n        }\n\n        mat-icon {\n          cursor: pointer;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
