import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ProcessStatus } from '../../../../../shared/interfaces/process-status.enum';
import { ReportLayer } from '../../../models/report.interface';
import { MatSort } from '@angular/material/sort';
import { Process } from '../../../models/process.interface';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';

@Component({
  selector: 'mee-process-layers-table',
  templateUrl: './process-layers-table.component.html',
  styleUrls: ['./process-layers-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessLayersTableComponent {
  displayedColumns = [
    'LayerId',
    'LayerExternalId',
    'LayerName',
    'Status',
    'PercentageComplete',
    'TotalCount',
    'IndexedCount',
    'IndexationFailedCount',
    'ReadAndStoredCount',
    'FailedToReadAndStoreCount',
  ];

  dataSource = new MatTableDataSource<ReportLayer>();

  private localProcessLayers: Array<ReportLayer>;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @Input() set processLayers(processLayers: Array<ReportLayer>) {
    if (!processLayers) {
      return;
    }

    this.localProcessLayers = processLayers;

    this.dataSource.data = processLayers;
  }

  get processLayers() {
    return this.localProcessLayers;
  }

  getIsShowProgress(processLayer: ReportLayer): boolean {
    return processLayer.Status === ProcessStatus.InProgress || processLayer.Status === ProcessStatus.Finished;
  }
}
