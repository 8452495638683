import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IdentityService } from '../../../services/identity.service';
import { TeamInterface } from '../../../modules/teams/models/team.interface';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { TeamsService } from '../../../modules/teams/services/teams.service';
import { Team } from '../../../modules/teams/models/team';

@Component({
  selector: 'mee-teams-autocomplete',
  templateUrl: './teams-autocomplete.component.html',
  styleUrls: ['./teams-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TeamsAutocompleteComponent),
    },
  ],
})
export class TeamsAutocompleteComponent implements ControlValueAccessor, OnDestroy {
  selectedTeamControl = new UntypedFormControl();

  teams: TeamInterface[];

  filteredTeamsData$: Observable<TeamInterface[]>;

  private localCompanySlug: string;

  private destroy$ = new Subject<void>();
  private selectedTeamSlug: string;
  @Input() isDisplayTeamIdentifier = false;
  @Input() set companySlug(companySlug: string | null) {
    // default value

    if (!companySlug) {
      return;
    }

    // value after remove selected company

    this.localCompanySlug = companySlug;

    if (this.isTeamAdmin) {
      this.setTeamForTeamAdmin();
      this.selectedTeamControl.disable();

      return;
    }

    this.getTeamsByCompany(companySlug);
  }

  get companySlug() {
    return this.localCompanySlug;
  }

  onChange: any = () => {};

  get isSuperAdmin() {
    return this.identityService.isSuperAdmin();
  }

  get isCompanyAdmin() {
    return this.identityService.isCompanyAdmin();
  }

  get isTeamAdmin() {
    return this.identityService.isTeamAdmin();
  }

  constructor(private identityService: IdentityService, private teamService: TeamsService) {
    this.selectedTeamControl.valueChanges
      .pipe(
        tap(value => this.onChange(value)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  getTeamsByCompany(companySlug: string) {
    this.teamService
      .getTeams(companySlug)
      .pipe(
        tap(teams => {
          this.setExistingSortedTeams(teams);

          this.filterTeamsAutocompleteValues();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  setTeamForTeamAdmin() {
    this.teamService
      .getTeam(this.identityService.companySlug, this.identityService.teamSlug)
      .pipe(
        tap(selectedTeam => {
          this.selectedTeamControl.setValue(selectedTeam);
          this.onChange(selectedTeam);
        }),
      )
      .subscribe();
  }

  displayTeamProperty(team: TeamInterface) {
    if (team) {
      return this.getTeamDisplayValue(team);
    }
  }

  getTeamDisplayValue(team: Team): string {
    return this.isDisplayTeamIdentifier ? `${team.Name} (${team.Slug})` : team.Name;
  }

  filterTeamsAutocompleteValues(): void {
    this.filteredTeamsData$ = this.selectedTeamControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filterTeams(value)),
    );
  }

  private filterTeams(value: string): Array<TeamInterface> {
    if (typeof value !== 'string') {
      return;
    }

    const filterValue = value.toLowerCase();

    return this.teams.filter(option => option.Slug.toLowerCase().includes(filterValue));
  }

  private setExistingSortedTeams(teams: TeamInterface[]) {
    const teamsTrimmed = teams.map(source => {
      return { ...source, Name: source.Name.trim() };
    });

    const existingTeams = teamsTrimmed.filter(source => !source.IsArchived);
    this.teams = existingTeams.sort((a, b) => (a.Slug < b.Slug ? -1 : 1));

    if (this.selectedTeamSlug) {
      const findTeam = this.teams.find(team => team.Slug === this.selectedTeamSlug);

      this.writeValue(findTeam);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(team: TeamInterface): void {
    if (typeof team === 'string') {
      this.selectedTeamSlug = team;

      return;
    }

    this.selectedTeamControl.setValue(team);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
