import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { Floor } from '../../../../../../../components/floors/interfaces/floor.interface';
import { AbstractControl, ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IdentityService } from '../../../../../../../../services/identity.service';
import { FloorIfcMapping } from '../interfaces/floor-ifc-mapping';
import { FloorIfcConfiguration } from '../interfaces/floor-ifc-configuration';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FloorElevationSource } from '../enums/floor-elevation-source.enum';

@Component({
  selector: 'mee-floors-ifc-mapping',
  templateUrl: './floors-ifc-mapping.component.html',
  styleUrls: ['./floors-ifc-mapping.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FloorsIfcMappingComponent),
      multi: true,
    },
  ],
})
export class FloorsIfcMappingComponent implements OnInit, ControlValueAccessor {
  dataSource = new BehaviorSubject<AbstractControl[]>([]);

  displayedColumns = ['Name', 'Floor'];

  form: UntypedFormGroup;

  floorsIfcMapping: FloorIfcMapping[];

  private localFloors: Floor[];

  @Output() floorsIfcMappingChanged = new EventEmitter();

  @Input() set companyFloors(floors: Floor[]) {
    if (!floors) {
      return;
    }

    this.localFloors = floors;
  }

  get floorsFormArray() {
    return this.form.get('Floors') as UntypedFormArray;
  }

  get companyFloors() {
    return this.localFloors;
  }

  get isTeamAdmin() {
    return this.identityService.isTeamAdmin();
  }

  onChange: any = () => {};

  constructor(private formBuilder: UntypedFormBuilder, private identityService: IdentityService) {
    const floor = this.buildFloor();

    this.form = this.formBuilder.group({
      Floors: this.formBuilder.array([floor]),
    });
  }

  buildFloor() {
    return this.formBuilder.group({
      GlobalId: [],
      Name: [],
      Floor: [],
    });
  }

  ngOnInit() {
    this.floorsFormArray.valueChanges
      .pipe(
        tap(value => {
          const adaptedValue = {
            Type: FloorElevationSource.Ifc,
            Mapping: value,
          };

          this.onChange(adaptedValue);
        }),
      )
      .subscribe();
  }

  writeValue(floorsIfcConfiguration: FloorIfcConfiguration): void {
    this.floorsIfcMapping = (floorsIfcConfiguration as FloorIfcConfiguration).Mapping;

    this.initFormArray();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  initFormArray() {
    this.floorsIfcMapping.forEach((tableFloor, index) => {
      this.floorsFormArray.setControl(
        index,
        this.formBuilder.group({
          GlobalId: tableFloor.GlobalId,
          Name: tableFloor.Name,
          Floor: tableFloor.Floor,
        }),
      );
    });

    this.dataSource.next(this.floorsFormArray.controls);
  }

  getFormValueByKey(key: string, index: number): any {
    return this.floorsFormArray.at(index)?.get(key)?.value;
  }
}
