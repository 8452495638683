import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TeamsService } from '../services/teams.service';
import { TeamInterface } from '../models/team.interface';
import { IdentityService } from '../../../services/identity.service';
import { tap } from 'rxjs/operators';
import { TeamProjectService } from '../../../services/team-project.service';

export const TeamResolver: ResolveFn<TeamInterface> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  teamsService = inject(TeamsService),
  identityService = inject(IdentityService),
  teamProjectService = inject(TeamProjectService)
): Observable<TeamInterface> => {
  if (identityService.isTeamAdmin()) {
    return teamsService.getTeam(identityService.companySlug, identityService.teamSlug)
      .pipe(
        tap(team => identityService.teamPermissions = team.Permissions)
      );
  }

  if (route.params.companySlug && route.params.teamSlug) {
    teamProjectService.companySlugFromRoute = route.params.companySlug;

    return teamsService.getTeam(route.params.companySlug, route.params.teamSlug);
  }
}
