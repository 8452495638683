import { Injectable } from '@angular/core';
import { RestClass, RestService } from '../../../../services/rest/rest.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Process } from '../../models/process.interface';
import { IdentityService } from '../../../../services/identity.service';

@Injectable()
@RestClass('/sources/processes')
export class ProcessService extends RestService<any> {

  /**
   * @param {HttpClient} http
   * @param identityService
   */
  constructor(protected http: HttpClient, private identityService: IdentityService) {
    super(http);
  }

  /**
   * Get process list
   * @returns {Observable<Object>}
   */
  public getProcessList() {
    return this.http.get(this.route);
  }

  /**
   * Get process reports
   * @param {string} processId
   * @param companySlug
   * @param sourceSlug
   * @returns {Observable<Object>}
   */
  public getProcessReport(processId: string, companySlug: string, sourceSlug: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);
    const url = `${this.baseUrl}/companies/${companySlug}/sources/${sourceSlug}/processes/${processId}/report/`

    return this.http.get(url, { params });
  }

  /**
   * Cancel process
   */
  public cancelProcess(dataSourceId: string, processId: string) {
    const companySlug = this.identityService.companySlug;
    const url = `${this.baseUrl}/companies/${companySlug}/sources/${dataSourceId}/processes/${processId}/cancel`

    return this.http.post(url, {});
  }

  /**
   * Get processing history list for all data source
   * @param {string} endDateString
   * @returns {Observable<Process[]>}
   * */

  public getProcessingHistoryList(endDateString: string): Observable<Process[]> {
    const companySlug = this.identityService.companySlug;
    let url = `${this.baseUrl}/sources/companies/${companySlug}/processes/daterange/${endDateString}`;

    if (this.identityService.isTeamAdmin()) {
      const teamSlug = this.identityService.teamSlug;

      url = `${this.baseUrl}/sources/companies/${companySlug}/teams/${teamSlug}/processes/daterange/${endDateString}`
    }

    return this.http.get<Process[]>(url);
  }

  /**
   * Get processing history list for certain data source
   * @param {string} companySlug
   * @param {string} sourceSlug
   * @param {string} endDateString
   * @returns {Observable<Array<Process>>}
   * */

  public getDataSourceProcessingHistory(companySlug: string, sourceSlug: string, endDateString: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);
    const url = `${this.baseUrl}/companies/${companySlug}/sources/${sourceSlug}/processes/daterange/${endDateString}`

    return this.http.get(url, { params });
  }
}
