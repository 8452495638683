export enum ConfigurationTableTitleGroups {
  GLOBAL = 1,
  POINT_UNKNOWN,
  POINT_OBJECT,
  POINT_LABEL,
  POINT_MANHOLE,
  POINT_CUSTOM,
  LINE_PIPE,
  LINE_LINE,
}
