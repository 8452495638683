import { Component, Inject, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmationDialogData } from '../../../interfaces/confirmation-dialog-data';

@Component({
  selector: 'mee-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {}

  get isDataCustomTitle() {
    return this.data && this.data.titleKey;
  }

  get isDataSubtitle() {
    return this.data && this.data.subtitleKey;
  }
}
