import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TableDataSource } from './datasource';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MatLegacyPaginator as MatPaginator, MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { ITableColumn } from './interfaces/table-column.interface';
import { TableBuilder } from './table-builder';
import { ITableAction } from './interfaces/table-action.interface';
import { TableActionConfirmComponent } from './table-action-confirm/table-action-confirm.component';
import { TranslationHandleService } from '../translate/translation-handle.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pluck, tap } from 'rxjs/operators';
import { DateFormatterService } from '../../services/date-formatter.service';
import { ISource } from '../../modules/sources/models/source.interface';
import { Router } from '@angular/router';
import { IdentityService } from '../../services/identity.service';
import { SchedulingService } from '../../modules/sources/components/source-form/source-form-scheduling/services/scheduling.service';
import { ICategoryBase } from '../categories/categories.component';
import { PermissionType } from '../../shared/enums/permission-type.enum';
import { cloneDeep } from 'lodash';

const PHRASES_FOR_TRANSLATE = [
  {
    itemsPerPage: 'pagination.itemsPerPage',
  },
  {
    previousPage: 'pagination.previousPage',
  },
  {
    nextPage: 'pagination.nextPage',
  },
];

@Component({
  selector: 'mee-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent extends MatPaginatorIntl implements OnInit, OnDestroy {
  @Input() builder: TableBuilder<any>;
  @Input() minWidth: number;
  @Input() enableDelFiltration: boolean;
  @Input() title: string;
  @Input() preFilter = '';
  @Input() matSortActive: string;
  @Input() isDataLoading?: boolean;

  @Output() onShowArchivedChanged = new EventEmitter<boolean>();

  @Output() onChangeDate = new EventEmitter<string | Date>();

  @Output() isRefreshDataAfterMergedCategoriesChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  private dialogConfirm: MatDialogRef<TableActionConfirmComponent>;

  paginatorOptions = {
    hide: false,
    pageSize: 50,
    pageSizeOptions: [50, 100, 150],
  };
  filterableFields: Array<string> = [];
  sortableFields: Array<string> = [];
  displayedColumns: Array<string> = [];
  showArchived = false;

  dataSource: any;
  searchValue = '';

  subs: Subscription[] = [];

  @ViewChild('filter', { static: true }) filter: ElementRef;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private translationHandleService: TranslationHandleService,
    protected identityService: IdentityService,
    private dateFormatterService: DateFormatterService,
    private schedulingService: SchedulingService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,
  ) {
    super();
    this.translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);

    this.getTranslationMap();
  }

  ngOnInit() {
    this.analyzeColumns(this.builder.config.columns);
    if (this.hasActions()) {
      this.displayedColumns.push('actions');
    }

    this.paginatorOptions = this.builder.config.pagination
      ? Object.assign(this.paginatorOptions, this.builder.config.pagination)
      : this.paginatorOptions;
    if (!this.hasPagination()) {
      this.paginatorOptions.pageSize = 1000000;
      this.paginatorOptions.pageSizeOptions = [];
    }

    this.subs.push(
      fromEvent(this.filter.nativeElement, 'keyup')
        .pipe(
          pluck('target', 'value'),
          map((value: string) => (value ? value.toLowerCase().trim() : '')),
          debounceTime(150),
          distinctUntilChanged(),
          filter(value => this.dataSource && this.searchValue !== value),
          tap((value: string) => {
            this.searchValue = value;
            this.dataSource.filter = value;
            this.cdr.markForCheck();
          }),
        )
        .subscribe(),
    );
    /**
     *  Create instance of datasource
     *  */
    const dataSourceOptions = {
      filterableFields: this.filterableFields,
      paginator: this.paginator,
      sort: this.sort,
      sortableFields: this.sortableFields,
      enableDelFiltration: this.enableDelFiltration,
      sortingDataAccessor: (item, property) => {
        if (property === 'MapParsingRules') {
          return !!item.MapParsingRules.length ? 2 : 1;
        } else if (property === 'Duration') {
          return this.dateFormatterService.getDurationBetweenTwoDates(item.StartDateUtc, item.EndDateUtc, 'mS');
        }
      },
    };

    this.dataSource = new TableDataSource(this.builder, dataSourceOptions);

    if (this.preFilter) {
      this.dataSource.filter = this.preFilter;
    }

    this.subs.push(
      this.builder.removed.subscribe(() => this.cdr.markForCheck()),
      this.builder.restored.subscribe(() => this.cdr.markForCheck()),
      this.builder.categoriesChanged.subscribe(() => this.cdr.markForCheck()),
    );
  }

  selectedItemsByCategoryChanged(categoryBase: ICategoryBase) {
    this.dataSource.filter = categoryBase.categoryName;
  }

  onRefreshListAndCategoriesData(isRefreshListData: boolean) {
    this.isRefreshDataAfterMergedCategoriesChanged.emit(isRefreshListData);
  }

  navigateScheduling(event, source: ISource) {
    if (event.target.className.toString() === 'alarm-clock' || event.target.className.toString() === 'spinner') {
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.router.navigate([`sources/${source.CompanySlug}/${source.Slug}/edit`], { queryParams: { defaultTab: 2 } });
    }
  }

  getScheduleTooltipText(dataItem: any, columnKey: string) {
    if (columnKey === 'Schedule' && dataItem[columnKey] && dataItem[columnKey]['IsEnabled']) {
      return this.schedulingService.getScheduleTooltipText(dataItem[columnKey]);
    } else if (columnKey === 'IsProcessing' && dataItem[columnKey]) {
      return this.schedulingService.getScheduleTooltipText(dataItem['Schedule']);
    } else {
      return '';
    }
  }

  getTooltipText(dataItem: any, columnKey: string) {
    const isMoreThanOne = dataItem[columnKey]?.length > 1;
    if ((columnKey === 'CompaniesSlugs' || columnKey === 'TeamsSlugs') && isMoreThanOne) {
      return dataItem[columnKey].join('\n');
    }
    if (columnKey === 'Permissions') {
      const permissions = cloneDeep(dataItem.Permissions);

      // To implement order as on design
      const readPermission = permissions.find(permission => permission.Type === PermissionType.Read);
      const updatePermission = permissions.find(permission => permission.Type === PermissionType.Update);
      const createPermission = permissions.find(permission => permission.Type === PermissionType.Create);

      permissions[1] = readPermission;
      permissions[2] = updatePermission;
      permissions[3] = createPermission;

      return permissions
        .filter(permission => permission.IsAllowed)
        .map(permission => {
          let newName = permission.Name;

          switch (permission.Type) {
            case PermissionType.Create:
              newName = 'Add';
              break;
            case PermissionType.Update:
              newName = 'Edit';
              break;

            case PermissionType.PublicDataAccess:
              newName = 'Company Asset Access';
              break;
          }

          return { ...permission, Name: newName };
        })
        .map(permission => permission.Name)
        .join(' / ');
    }
  }

  getTooltipPosition(columnKey: string) {
    return columnKey === 'Permissions' ? 'below' : 'left';
  }

  getTranslationMap() {
    this.subs.push(
      this.translationHandleService.getTranslationMap().subscribe(translationMap => {
        this.itemsPerPageLabel = translationMap.get('itemsPerPage');
        this.nextPageLabel = translationMap.get('nextPage');
        this.previousPageLabel = translationMap.get('previousPage');
      }),
    );
  }

  analyzeColumns(columns: ITableColumn[]) {
    columns.forEach(col => {
      if (!col.hidden) {
        this.displayedColumns.push(col.key);
      }

      if (col.filterable) {
        this.filterableFields.push(col.key);
      }

      if (col.sortable) {
        this.sortableFields.push(col.key);
      }
    });
  }

  hasPagination() {
    return !this.paginatorOptions.hide;
  }

  hasSorting() {
    return this.sortableFields.length > 0;
  }

  hasFiltering(): boolean {
    return this.filterableFields.length > 0;
  }

  hasActions() {
    return this.builder.config.headActions || this.builder.config.rowActions || this.builder.config.rowGroupActions;
  }

  getHeadActionVisible(action: ITableAction) {
    if (typeof action.visible === 'function') {
      return action.visible();
    }
    return action.visible !== false;
  }

  getRowActionVisible(action: ITableAction, item?: any): boolean {
    const visible = action.visible;
    if (visible === undefined) {
      return true;
    }
    return typeof visible === 'function' ? visible(item) : visible;
  }

  getTooltip(tooltip: Function | string, item?: any) {
    if (tooltip === undefined) {
      return '';
    }

    return typeof tooltip === 'function' ? tooltip(item) : tooltip;
  }

  confirm(event, action: ITableAction, item: any, actionFunc: Function) {
    event.stopPropagation();

    if (action.confirmable) {
      this.dialogConfirm = this.dialog.open(TableActionConfirmComponent, {
        width: '350px',
      });
      this.subs.push(
        this.dialogConfirm.afterClosed().subscribe(confirm => {
          if (confirm) {
            actionFunc(item);
          }
        }),
      );
    } else {
      actionFunc(item);
    }
  }

  onShowArchived({ checked }) {
    this.showArchived = checked;

    this.onShowArchivedChanged.emit(checked);

    this.dataSource.archivedShow = checked;
  }

  onRowClick(row) {
    if (this.builder.config.rowClickAction) {
      this.builder.config.rowClickAction(row);
    }
  }

  openRowMenuClick(event) {
    event.stopPropagation(event);
  }

  onChangeSelectedDate(selectedDate: Date) {
    this.onChangeDate.emit(selectedDate);
  }

  clearFilter() {
    this.preFilter = '';
    this.dataSource.filter = '';
    this.filter.nativeElement.value = '';
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    this.translationHandleService.cleanTranslationArray();
  }
}
