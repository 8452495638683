import { Injectable } from '@angular/core';
import { ISchedule } from '../../../../models/source.interface';

@Injectable()

export class SchedulingService {

  getScheduleTooltipText (content: ISchedule): string {
    if (content) {
      switch (content.Type) {
        case 'Monthly':
          return `ONCE A MONTH / ${content.DayOfMonth.toString().toUpperCase()} / ${content.DayTime.toString().toUpperCase()}`;
        case 'Weekly':
          return `ONCE A WEEK / ${content.DayOfWeek.toString().toUpperCase()}S / ${content.DayTime.toString().toUpperCase()}`;
        case 'Daily':
          return `ONCE A DAY / ${content.DayTime.toString().toUpperCase()}`;
        case 'Hourly4':
        case 'Hourly8':
          return `EVERY ${content.Type.toString().slice(-1)} HOURS`;
        case 'Hourly12':
          return 'EVERY 12 HOURS';
        default:
          return '';
      }
    } else {
      return '';
    }
  }

}
