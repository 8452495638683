import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[meeTableTooltip]'
})

export class TableTooltipDirective implements AfterViewInit {
  @Input() tooltipText: string;
  @Input() tooltipDisabled: boolean;

  constructor(private element: ElementRef) {
  }

  ngAfterViewInit() {

    const tooltipHtml = `<span class="mee-table-tooltip">${this.tooltipText}</span>`;

    if (!this.tooltipDisabled) {
      this.element.nativeElement.insertAdjacentHTML('afterend', tooltipHtml);
    }
  }
}
