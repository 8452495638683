export enum ZendeskCommands {
  WidgetGet = 'webWidget:get',
  WidgetType = 'webWidget',
  Hide = 'hide',
  SetLocale = 'setLocale',
  Show = 'show',
  Reset = 'reset',
  Prefill = 'prefill',
  Display = 'display'
}
