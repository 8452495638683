import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { InfocardImagesService } from './infocard-images.service';
import { InfocardImagesComponent } from './infocard-images/infocard-images.component';
import { SharedModule } from '../../shared/shared.module';
import { ImageCropComponent } from './image-crop/image-crop.component';

@NgModule({
    imports: [CommonModule, SharedModule, MatButtonModule, MatDialogModule],
    declarations: [InfocardImagesComponent],
    providers: [InfocardImagesService],
    exports: [InfocardImagesComponent]
})
export class InfocardImagesModule {}
