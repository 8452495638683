import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingOverlayModule } from '../core/loading-overlay/loading-overlay.module';
import { NgPipesModule } from 'ngx-pipes';
import { MatCommonModule } from '../core/mat-common.module';
import { DatexPipe } from '../core/datex.pipe';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { TestCropUploadComponent } from './components/test-crop-upload/test-crop-upload.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { ImagePopupComponent } from './components/image-popup/image-popup.component';
import { FilePreviewOverlayComponent } from '../modules/video-dialog/file-preview-overlay/file-preview-overlay.component';
import { FilePreviewOverlayToolbarComponent } from '../modules/video-dialog/file-preview-overlay-toolbar/file-preview-overlay-toolbar.component';
import { CategoryAutocompleteComponent } from '../core/categories/category-autocomplete/category-autocomplete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StubComponent } from './components/stub/stub.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { ClickOutsideDirective } from '../core/utils/click-outside.directive';
import { ExportDataModalComponent } from './components/export-data/export-data-modal/export-data-modal.component';
import { ExportDataButtonComponent } from './components/export-data/export-data-button/export-data-button.component';
import { ImageCropComponent } from '../core/infocard-images/image-crop/image-crop.component';
import { LimitTextToMaxLengthDirective } from './limit-text-to-max-length.directive';
import { RemoveAutofillDirective } from './remove-autofill.directive';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { PermissionsCrudComponent } from './components/permissions-crud/permissions-crud.component';
import { CompanyPermissionsComponent } from './components/company-permissions/company-permissions.component';
import { ExternalTokenComponent } from './components/external-token/external-token.component';
import { PermissionsFieldsSaCaComponent } from './permissions-fields/permissions-fields-sa-ca/permissions-fields-sa-ca.component';
import { ProcessStatusComponent } from './components/process-status/process-status.component';
import { CategorySetDialogComponent } from './components/category-set-dialog/category-set-dialog.component';
import { LimitPipe } from './limit.pipe';
import { CompaniesAutocompleteComponent } from './components/companies-autocomplete/companies-autocomplete.component';
import { SourcesAutocompleteComponent } from './components/sources-autocomplete/sources-autocomplete.component';
import { MultiselectHeaderComponent } from './components/multiselect-header/multiselect-header.component';
import { ColorPickerComponent } from '../modules/sources/components/source-configuration-list/components/configuration-tab/color-picker/color-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { PermissionsFieldsTaComponent } from './permissions-fields/permissions-fields-ta/permissions-fields-ta.component';
import { TeamsAutocompleteComponent } from './components/teams-autocomplete/teams-autocomplete.component';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { VgisScanAccessTokenComponent } from './components/vgis-scan-access-token/vgis-scan-access-token.component';
import { TeamsMultipleSelectorComponent } from './components/teams-multiple-selector/teams-multiple-selector.component';
import { SwiperDirective } from './swiper.directive';
import { ByteSizeTemplatePipe } from './byte-size.pipe';
import { PositiveNumberPipe } from './positiveNumber.pipe';
import { EnumToArrayPipe } from './enum-to-array.pipe';
import { ProcessLayersTableComponent } from '../modules/sources/components/process-details/process-layers-table/process-layers-table.component';
import { DefaultCoordinateSystemComponent } from './components/default-coordinate-system/default-coordinate-system.component';

@NgModule({
    declarations: [
        DatexPipe,
        TruncatePipe,
        TestCropUploadComponent,
        AudioPlayerComponent,
        ImagePopupComponent,
        FilePreviewOverlayComponent,
        ProcessLayersTableComponent,
        FilePreviewOverlayToolbarComponent,
        CategoryAutocompleteComponent,
        StubComponent,
        DatePickerComponent,
        ExportDataModalComponent,
        ExportDataButtonComponent,
        ClickOutsideDirective,
        ImageCropComponent,
        LimitTextToMaxLengthDirective,
        RemoveAutofillDirective,
        ConfirmationDialogComponent,
        PermissionsCrudComponent,
        CompanyPermissionsComponent,
        ExternalTokenComponent,
        PermissionsFieldsSaCaComponent,
        ProcessStatusComponent,
        CategorySetDialogComponent,
        LimitPipe,
        CompaniesAutocompleteComponent,
        SourcesAutocompleteComponent,
        MultiselectHeaderComponent,
        ColorPickerComponent,
        PermissionsFieldsTaComponent,
        TeamsAutocompleteComponent,
        VgisScanAccessTokenComponent,
        TeamsMultipleSelectorComponent,
        SwiperDirective,
        ByteSizeTemplatePipe,
        PositiveNumberPipe,
        EnumToArrayPipe,
        DefaultCoordinateSystemComponent,
    ],
  exports: [
    CommonModule,
    NgPipesModule,
    MatCommonModule,
    MatDialogModule,
    TranslateModule,
    LoadingOverlayModule,
    DatexPipe,
    TruncatePipe,
    AudioPlayerComponent,
    CategoryAutocompleteComponent,
    StubComponent,
    DatePickerComponent,
    ExportDataModalComponent,
    ExportDataButtonComponent,
    ClickOutsideDirective,
    ImageCropComponent,
    LimitTextToMaxLengthDirective,
    RemoveAutofillDirective,
    PermissionsCrudComponent,
    CompanyPermissionsComponent,
    ExternalTokenComponent,
    PermissionsFieldsSaCaComponent,
    PermissionsFieldsTaComponent,
    ProcessStatusComponent,
    CategorySetDialogComponent,
    LimitPipe,
    CompaniesAutocompleteComponent,
    SourcesAutocompleteComponent,
    MultiselectHeaderComponent,
    ColorPickerComponent,
    TeamsAutocompleteComponent,
    ContextMenuModule,
    VgisScanAccessTokenComponent,
    TeamsMultipleSelectorComponent,
    SwiperDirective,
    ByteSizeTemplatePipe,
    PositiveNumberPipe,
    EnumToArrayPipe,
    ProcessLayersTableComponent,
    DefaultCoordinateSystemComponent,
  ],
    imports: [
        CommonModule,
        MatCommonModule,
        MatDialogModule,
        MatAutocompleteModule,
        TranslateModule,
        FormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        ColorPickerModule,
        ContextMenuModule,
    ]
})
export class SharedModule {}
