import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Check value for another input
 * @param target
 * @param source
 * @returns {(control:AbstractControl)=>{[p: string]: any}}
 */
export function requiredWhenValidator(target: string, source?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value;

    if (!source) {
      source = value;
    }

    if (!control.parent) {
      return !value ? { required: true } : null;
    }

    return (control.parent.controls[target].value === source && !value) ? { required: true } : null;
  };
}

export function regexValidator(regExp: RegExp, error: ValidationErrors): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    regExp.lastIndex = 0;

    const isInvalid = regExp.test(control.value);

    return isInvalid ? null : error;
  };
}


/**
 * Validator of matching two input controls
 * @param passwordKey
 * @param passwordConfirmationKey
 * @returns {(group:FormGroup)=>(void|void)}
 */
export function checkIfMatching(passwordKey: string, passwordConfirmationKey: string) {
  return (group: UntypedFormGroup) => {
    const passwordInput = group.controls[passwordKey],
      passwordConfirmationInput = group.controls[passwordConfirmationKey];
    if (passwordInput.value !== passwordConfirmationInput.value) {
      return passwordConfirmationInput.setErrors({ notEquivalent: true });
    } else {
      return passwordConfirmationInput.setErrors(null);
    }
  };
}

/**
 * Validator of matching two input controls
 * @returns {(group:FormGroup)=>(void|void)}
 * @param validateControlName
 * @param controlName
 * @param value
 */
export function requiredIf(validateControlName: string, controlName: string, value: any) {
  return (group: UntypedFormGroup) => {
    const validateControl = group.controls[validateControlName],
      control = group.controls[controlName];
    if (control.value === value && !validateControl.value) {
      return validateControl.setErrors({ required: true });
    } else {
      return validateControl.setErrors(null);
    }
  };
}


export const slugPattern = /^[0-9a-z-]+$/;

export const specChartersMatch = /[^a-zA-Z0-9\s|-]/g;

export const spaceMatch = / /g;

export const floatMatch = /^[+-]?([0-9]*[.])?[0-9]+$/;

export const identifierMaxLength = 30;

/**
 * Block when control is invalid
 * @param {AbstractControl} control
 */
export const lockInvalidControl = (control: AbstractControl) => {
  control.statusChanges.subscribe(value => {
    if (value === 'INVALID' && control.value) {
      control.setValue(control.value.slice(0, control.value.length - 1));
    }
  });
};
