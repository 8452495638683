import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

export const MAP_LOADER = new InjectionToken<Observable<boolean>>('Google Maps API Loaded');

export function loadGoggleMap(): Observable<boolean> {
  const loader = new Loader({
    apiKey: environment.apiKey,
    version: 'weekly',
    language: 'en',
    libraries: ['places'],
  });

  return fromPromise(loader.load()).pipe(map(() => true));
}
