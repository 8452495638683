import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {
  transform(enumType: any): Array<{ key: string, value: any }> {
    const enumKeys = Object.keys(enumType);

    const enumStringKeys = enumKeys.filter(key => {
      const enumValue = Number(enumType[key]);

      return !isNaN(enumValue)
    });

    return enumStringKeys.map(key => ({ key, value: enumType[key] }));
  }

}
