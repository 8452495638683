import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SourceConfiguration } from '../interfaces/source-configuration';
import { ILayer } from '../../../../../../components/layers/shared/models/layer.interface';
import { DataSourceType } from '../../../../../data-source-type.enum';
import { ConfigurationFieldsAdapterService } from '../configuration-fields-adapter.service';
import { SingleForm } from '../interfaces/single-form';
import isEqual from 'lodash-es/isEqual';
import { PlacemarksFields } from '../../../models/placemarks-fields';
import { ConfigurationFields } from '../enums/configuration-fields.enum';
import { isDisplayForCurrentSourceType } from '../is-display-for-current-source-type.function';
import { Symbology } from '../enums/symbology.enum';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LineSubtype } from '../enums/line-subtypes.enum';
import { FeatureLayerType } from '../interfaces/feature-layer-type.enum';
import { ObjectType } from '../enums/object-types.enum';
import { isDisableForCurrentSourceType } from '../is-disable-for-current-source-type.function';

@Component({
  selector: 'mee-line-configuration',
  templateUrl: './line-configuration.component.html',
  styleUrls: ['./line-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineConfigurationComponent implements SingleForm, OnInit, OnDestroy {
  form: UntypedFormGroup;

  localNode: SourceConfiguration;

  localLayers: ILayer[];

  configurationFields = ConfigurationFields;

  symbology = Symbology;

  tooltipTextForSubtypeField: string;

  objectTypesOptions = this.configurationFieldsAdapterService.getObjectTypesOptions();

  lineSubtypesOptions = this.configurationFieldsAdapterService.getLineSubtypes();

  altitudeModesOptions = this.configurationFieldsAdapterService.getAltitudeModesOptions();

  private destroy$ = new Subject<void>();

  @Input() sourceType: DataSourceType;

  @Input() placeMarksFields: PlacemarksFields;
  @Output() updateLayerGroup = new EventEmitter();

  @Input() set layers(layers: ILayer[]) {
    this.localLayers = layers;

    if (layers.length === 0) {
      this.form.get('Layer').disable();
    }
  }

  get layers() {
    return this.localLayers;
  }

  @Input() set node(node: SourceConfiguration) {
    if (node === undefined) {
      return;
    }

    if (isEqual(this.node, node)) {
      return;
    }

    this.localNode = node;

    this.form.patchValue(node, { emitEvent: false });

    this.setDefaultAdaptedValues(node);
  }

  get node() {
    return this.localNode;
  }

  get isTitleNotEmpty() {
    return this.form.get('Title').value !== null;
  }

  get mainColor() {
    return this.form.get('MainColor').value;
  }

  get isSubtypePipe() {
    return this.form.get('ObjectTypeConfiguration').get('Subtype').value === LineSubtype.PIPE;
  }

  get isSubtypeLine() {
    return this.form.get('ObjectTypeConfiguration').get('Subtype').value === LineSubtype.LINE;
  }

  get isNodeHaveSublayer() {
    return this.node.SubtypesConfigurations.length > 0;
  }

  constructor(private formBuilder: UntypedFormBuilder,
              private configurationFieldsAdapterService: ConfigurationFieldsAdapterService) {
    this.initForm();
  }

  ngOnInit() {

    this.disableFields();

    this.onChangeObjectType();

    this.onChangeSubtypeFormValue();
  }

  updateLayers(): void {
    this.updateLayerGroup.emit();
  }

  onChangeSymbology(event: MatButtonToggleChange) {
    this.form.get('UseVGisSymbols').setValue(event.value === Symbology.vGIS);
  }

  isDisplayForCurrentSourceType(fieldName: ConfigurationFields) {
    return isDisplayForCurrentSourceType(fieldName, FeatureLayerType.Line, this.sourceType);
  }

  isDisableForCurrentSourceType(fieldName: ConfigurationFields) {
    return isDisableForCurrentSourceType(fieldName, FeatureLayerType.Model3d, this.sourceType);
  }

  clearTitle() {
    this.form.get('Title').reset();
  }

  private onChangeSubtypeFormValue() {
    this.form
      .get('ObjectTypeConfiguration')
      .get('Subtype')
      .valueChanges.pipe(tap(() => this.onChangeSubtype()))
      .subscribe();
  }

  private onChangeSubtype() {
    const objectTypeConfigurationFormGroup = this.form.get('ObjectTypeConfiguration');

    if (this.isSubtypePipe) {
      objectTypeConfigurationFormGroup.get('DefaultDiameter').reset();
      objectTypeConfigurationFormGroup.get('DiameterField').reset();
    } else if (this.isSubtypeLine) {
      objectTypeConfigurationFormGroup.get('DefaultWidth').reset();
      objectTypeConfigurationFormGroup.get('WidthField').reset();
      objectTypeConfigurationFormGroup.get('DefaultHeight').reset();
      objectTypeConfigurationFormGroup.get('HeightField').reset();
    }
  }

  private onChangeObjectType() {
    this.form
      .get('ObjectType')
      .valueChanges.pipe(
        tap((newObjectType: ObjectType) => {
          this.form.get('ObjectTypeConfiguration').get('ObjectType').setValue(newObjectType);

          if (this.isNodeHaveSublayer) {
            this.tooltipTextForSubtypeField = 'configurations-fields.tooltips.DisabledSubtypeField';
            this.form.get('SubtypeField').disable();

            return;
          }

          this.tooltipTextForSubtypeField = 'configurations-fields.tooltips.SubtypeField';
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      Id: [],
      Layer: [],
      ObjectType: [],
      Title: [],
      TitleField: [],
      MainColor: [],
      UseVGisSymbols: [],
      DefaultDepth: [],
      DepthField: [],
      ObjectTypeConfiguration: this.formBuilder.group({
        ObjectType: [],
        Subtype: [],
        DefaultHeight: [],
        HeightField: [],
        DefaultWidth: [],
        WidthField: [],
        DefaultDiameter: [],
        DiameterField: [],
      }),
      SubtypeField: [],
      ExternalIdField: [],
      LabelConfiguration: [],
      PlacemarkImage: [],
      AltitudeMode: [],
      IsExcludedFromProcessing: [],
    });
  }

  private disableFields() {
    Object.keys(this.form.controls).forEach((controlName: ConfigurationFields) => {
      if (this.isDisableForCurrentSourceType(controlName)) {
        this.form.get(controlName).disable();
      }
    });
  }

  private setDefaultAdaptedValues(node: SourceConfiguration) {
    const adaptedDefaultValues = this.configurationFieldsAdapterService.getAdaptedDefaultValues(node);

    if (node.AltitudeMode === null) {
      this.form.get('AltitudeMode').setValue(adaptedDefaultValues.AltitudeMode, { emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
