import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable()

export class TranslationHandleService {

  private translationMapChanged$ = new BehaviorSubject<Map<string, string>>(new Map());
  private translationMap = new Map();
  private phrasesForTranslate: Record<string, string>[];

  /**
   * TranslationHandle service used for translate only static content in ts files
   * @param translate: TranslateService
   */

  constructor(private translate: TranslateService) {
  }

  /**
   * Set phrases for translate
   * @param phrasesForTranslate
   */

  setPhrasesForTranslate(phrasesForTranslate): void {
    this.phrasesForTranslate = phrasesForTranslate;
    this.phrasesForTranslate.forEach(item => {
      this.translate.get(Object.values(item)).subscribe((res) => {
          this.translationMap.set(Object.keys(item)[0], Object.values(res)[0]);
          this.translationMapChanged$.next(this.translationMap);
        }
      );
    });
  }

  /**
   * Get translation map
   * @return {BehaviorSubject<Map <string, string>>}
   */

  getTranslationMap(): BehaviorSubject<Map<string, string>> {
    return this.translationMapChanged$;
  }

  cleanTranslationArray() {
    this.phrasesForTranslate.forEach((item) => {
      this.translationMap.delete(Object.keys(item)[0]);
    });
  }
}
