export enum DataSourceType {
  Virginia811 = 'Virginia811',
  ArcGisFeatureService = 'ArcGisFeatureService',
  ArcGisSceneService = 'ArcGisSceneService',
  IModel = 'IModel',
  IModelGis = 'IModelGis',
  Bim360 = 'Bim360',
  Kml = 'Kml',
  NativeBim = 'NativeBim',
  Wfs = 'Wfs',
  NativeGis = 'NativeGis',
  Shapefile = 'Shapefile',
  Pix4d = 'Pix4dCloud',
  RealityCapture = 'RealityCapture',
  LandXml = 'LandXml',
  Simple3dModel = 'Simple3dModel',
}
