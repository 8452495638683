import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mee-table-action-confirm',
  templateUrl: './table-action-confirm.component.html',
  styleUrls: ['./table-action-confirm.component.scss'],
})
export class TableActionConfirmComponent implements OnInit {

  categoryMergedText: string;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: { categoryInfo?: { customText: string, categoryName: string }, text?: string }) {
  }

  ngOnInit() {

    this.isDialogData();

    /*For case when user merge categories*/

    if (this.dialogData && this.dialogData.categoryInfo && this.dialogData.categoryInfo.customText && this.dialogData.categoryInfo.categoryName) {
      this.categoryMergedText = this.dialogData.categoryInfo.customText + ' ' + this.dialogData.categoryInfo.categoryName + ' ?';
    }
  }

  isDialogData() {
    return !!this.dialogData;
  }

}
