export enum VerticalDatums {
  Egm2008 = 3855,
  Wgs84 = 4326,
  GDA94 = 4938,
  Rh2000 = 5613,
  Osgm15 = 5701,
  Navd88 = 5703,
  Nap = 5709,
  Cgvd28 = 5713,
  Ln02 = 5728,
  Lhn95 = 5729,
  Auk46 = 5759,
  Egm96 = 5773,
  Gha = 5778,
  Egm08Rednap = 5782,
  Dvr90 = 5799,
  Nn2000 = 5941,
  Cgvd2013 = 6647,
  Jgd2011 = 6695,
  Nzvd2016 = 7839,
  Gda2020 = 7842,
  Egm08RednapCanarias = 9397,
  Eh2000 = 9663,
  RAF09 = 10009,
  RAF18 = 10018,
  HBg18 = 31370,
  Gsd95 = 90000,
  Sgeoid09 = 100001
}
