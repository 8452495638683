import { NgModule } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { SourcesComponent } from './sources.component';
import { SharedModule } from '../../shared/shared.module';
import { SourcesRoutingModule } from './sources-routing.module';
import { SourcesListComponent } from './components/sources-list/sources-list.component';
import { SourceStatisticComponent } from './components/source-configuration-list/components/source-statistic/source-statistic.component';
import { DataSourcesService } from './services/data-sources.service';
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CompaniesService } from '../companies/companies.service';
import { SourceFormComponent } from './components/source-form/source-form.component';
import { TableModule } from '../../core/table/table.module';
import { SourceViewComponent } from './components/source-view/source-view.component';
import { UploaderModule } from '../../core/uploader/uploader.module';
import { PromptModule } from '../../core/prompt/prompt.module';
import { ScriptEditorTabComponent } from './components/source-configuration-list/components/script-editor-tab/script-editor-tab.component';
import {
  PlacemarksTableTabComponent
} from './components/source-configuration-list/components/placemarks-table-tab/placemarks-table-tab.component';
import { InspectionFormService } from '../../services/inspection-form.service';
import { LayersService } from '../components/layers/shared/services/layers.service';
import { SourceConfigurationListComponent } from './components/source-configuration-list/source-configuration-list.component';
import { StatisticTabComponent } from './components/source-configuration-list/components/statistic-tab/statistic-tab.component';
import { ProcessListComponent } from './components/process-list/process-list.component';
import { ProcessReportComponent } from './components/process-report/process-report.component';
import { ProcessService } from './components/process-list/process.service';
import {
  LabelConfigurationComponent
} from './components/source-configuration-list/components/label-configuration/label-configuration.component';
import { PlacemarksFieldsService } from './services/placemarks-fields.service';
import { InfocardImagesModule } from '../../core/infocard-images/infocard-images.module';
import { AuthInterceptor } from '../../interceptors/auth.interceptor';
import { SourceFormSchedulingComponent } from './components/source-form/source-form-scheduling/source-form-scheduling.component';
import { ProcessDetailsModalComponent } from './components/process-details/process-details-modal.component';
import {
  ProcessingHistoryTableComponent
} from './components/source-form/source-form-scheduling/processing-history-table/processing-history-table.component';
import { ScheduleForTodayComponent } from './components/source-form/schedule-for-today/schedule-for-today.component';
import {
  CopyConfigFromItemComponent
} from './components/source-configuration-list/components/copy-config-from/copy-config-from-item/copy-config-from-item.component';
import {
  CopyConfigFromListComponent
} from './components/source-configuration-list/components/copy-config-from/copy-config-from-list/copy-config-from-list.component';
import { CopyConfigFromService } from './components/source-configuration-list/components/copy-config-from/copy-config-from.service';
import { TemplatesForCopyService } from './components/source-configuration-list/components/copy-config-from/templates-for-copy.service';
import {
  CopyFromTemplatesComponent
} from './components/source-configuration-list/components/copy-config-from/copy-config-from-list/copy-from-templates/copy-from-templates.component';
import {
  CopyFromSourcesComponent
} from './components/source-configuration-list/components/copy-config-from/copy-config-from-list/copy-from-sources/copy-from-sources.component';
import { TeamsService } from '../teams/services/teams.service';
import { SourceConfigurationService } from './components/source-configuration-list/services/source-configuration.service';
import { SourceTeamsComponent } from './components/source-configuration-list/components/source-teams/source-teams.component';
import { ColorHelperService } from '../../shared/services/color-helper.service';
import {
  BimnodesTableTabComponent
} from './components/source-configuration-list/components/bimnodes-table-tab/bimnodes-table-tab.component';
import { AutodeskAccountComponent } from './components/source-form/autodesk-account/autodesk-account.component';
import {
  ConnectBim360ProjectComponent
} from './components/source-form/autodesk-account/connect-bim360-project/connect-bim360-project.component';
import {
  Bim360AccountDisconnectedComponent
} from './components/source-configuration-list/components/bim360-account-disconnected/bim360-account-disconnected.component';
import { Bim360ModelsTreeComponent } from './components/source-form/bim360-models-tree/bim360-models-tree.component';
import { ConfigurationTabComponent } from './components/source-configuration-list/components/configuration-tab/configuration-tab.component';
import {
  PointConfigurationComponent
} from './components/source-configuration-list/components/configuration-tab/point-configuration/point-configuration.component';
import {
  LineConfigurationComponent
} from './components/source-configuration-list/components/configuration-tab/line-configuration/line-configuration.component';
import {
  Model3dConfigurationComponent
} from './components/source-configuration-list/components/configuration-tab/model3d-configuration/model3d-configuration.component';
import {
  AreaConfigurationComponent
} from './components/source-configuration-list/components/configuration-tab/area-configuration/area-configuration.component';
import {
  UnknownConfigurationComponent
} from './components/source-configuration-list/components/configuration-tab/unknown-configuration/unknown-configuration.component';
import {
  RootConfigurationComponent
} from './components/source-configuration-list/components/configuration-tab/root-configuration/root-configuration.component';
import {
  FolderConfigurationComponent
} from './components/source-configuration-list/components/configuration-tab/folder-configuration/folder-configuration.component';
import {
  ConfigurationFieldsAdapterService
} from './components/source-configuration-list/components/configuration-tab/configuration-fields-adapter.service';
import {
  ConfigurationCompoundInputComponent
} from './components/source-configuration-list/components/configuration-tab/configuration-compound-input/configuration-compound-input.component';
import {
  ConfigurationCompoundSelectComponent
} from './components/source-configuration-list/components/configuration-tab/configuration-compound-select/configuration-compound-select.component';
import {
  InfoCardImageComponent
} from './components/source-configuration-list/components/configuration-tab/info-card-image/info-card-image.component';
import {
  SubtypeConfigurationComponent
} from './components/source-configuration-list/components/configuration-tab/subtype-configuration/subtype-configuration.component';
import {
  SubtypePointConfigurationComponent
} from './components/source-configuration-list/components/configuration-tab/subtype-configuration/subtype-point-configuration/subtype-point-configuration.component';
import {
  SubtypeLineConfigurationComponent
} from './components/source-configuration-list/components/configuration-tab/subtype-configuration/subtype-line-configuration/subtype-line-configuration.component';
import {
  AreaSubtypeConfigurationComponent
} from './components/source-configuration-list/components/configuration-tab/subtype-configuration/area-subtype-configuration/area-subtype-configuration.component';
import { Pix4dSelectProjectsComponent } from './components/source-form/pix4d-select-projects/pix4d-select-projects.component';
import {
  ConfigurationHintsComponent
} from './components/source-configuration-list/components/configuration-hints/configuration-hints.component';
import {
  FloorsElevationComponent
} from './components/source-configuration-list/components/configuration-tab/floors-configuration/floors-elevation/floors-elevation.component';
import {
  FloorsIfcMappingComponent
} from './components/source-configuration-list/components/configuration-tab/floors-configuration/floors-ifc-mapping/floors-ifc-mapping.component';
import { SimpleModelFileLoaderComponent } from './components/source-form/simple-model-file-loader/simple-model-file-loader.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { CheckLocationMapComponent } from './components/check-location-map/check-location-map.component';
import { EpsgCodeTipsComponent } from './components/source-configuration-list/components/epsg-code-tips/epsg-code-tips.component';
import {
  MultiselectConfigurationComponent
} from './components/source-configuration-list/components/configuration-tab/multiselect-configuration/multiselect-configuration.component';
import { ProcessingStatisticComponent } from './components/source-form/processing-statistic/processing-statistic.component';
import { ProcessingHistoryComponent } from './components/source-form/processing-history/processing-history.component';
import { PortalModule } from '@angular/cdk/portal';
import { loadGoggleMap, MAP_LOADER } from '../../shared/google-map-load';
import { GoogleMapsModule } from '@angular/google-maps';
import { TreeModule } from '@ali-hm/angular-tree-component';
import { CopyConfigModalComponent } from './components/source-configuration-list/components/copy-config-modal/copy-config-modal.component';
import { ConfigurationCopyListComponent } from './components/source-configuration-list/components/copy-config-modal/configuration-copy-list/configuration-copy-list.component';
import { CopyConfigOptionsWindowComponent } from './components/source-configuration-list/components/copy-config-modal/copy-config-options-window/copy-config-options-window.component';
import { SetTemplateComponent } from './components/source-configuration-list/components/set-template-modal/set-template.component';

@NgModule({
  imports: [
    SharedModule,
    TableModule,
    TreeModule,
    FormsModule,
    ReactiveFormsModule,
    PromptModule,
    // PerfectScrollbarModule,
    SourcesRoutingModule,
    UploaderModule,
    MatTableModule,
    MatExpansionModule,
    MatRadioModule,
    InfocardImagesModule,
    MatAutocompleteModule,
    ClipboardModule,
    NgxUploaderModule,
    PortalModule,
    GoogleMapsModule,
  ],
  declarations: [
    SourcesComponent,
    SourcesListComponent,
    SourceConfigurationListComponent,
    SourceStatisticComponent,
    SourceFormComponent,
    SourceFormSchedulingComponent,
    SourceViewComponent,
    ScriptEditorTabComponent,
    PlacemarksTableTabComponent,
    StatisticTabComponent,
    ProcessListComponent,
    ProcessReportComponent,
    LabelConfigurationComponent,
    ProcessingHistoryTableComponent,
    ScheduleForTodayComponent,
    CopyConfigFromItemComponent,
    CopyConfigFromListComponent,
    CopyFromTemplatesComponent,
    CopyFromSourcesComponent,
    SourceTeamsComponent,
    BimnodesTableTabComponent,
    AutodeskAccountComponent,
    ConnectBim360ProjectComponent,
    Bim360AccountDisconnectedComponent,
    Bim360ModelsTreeComponent,
    ConfigurationTabComponent,
    PointConfigurationComponent,
    LineConfigurationComponent,
    Model3dConfigurationComponent,
    AreaConfigurationComponent,
    UnknownConfigurationComponent,
    RootConfigurationComponent,
    FolderConfigurationComponent,
    ConfigurationCompoundInputComponent,
    ConfigurationCompoundSelectComponent,
    InfoCardImageComponent,
    SubtypeConfigurationComponent,
    SubtypePointConfigurationComponent,
    SubtypeLineConfigurationComponent,
    AreaSubtypeConfigurationComponent,
    Pix4dSelectProjectsComponent,
    ConfigurationHintsComponent,
    FloorsElevationComponent,
    FloorsIfcMappingComponent,
    SimpleModelFileLoaderComponent,
    CheckLocationMapComponent,
    EpsgCodeTipsComponent,
    MultiselectConfigurationComponent,
    ProcessingStatisticComponent,
    ProcessingHistoryComponent,
    ProcessDetailsModalComponent,
    CopyConfigModalComponent,
    ConfigurationCopyListComponent,
    CopyConfigOptionsWindowComponent,
    SetTemplateComponent,
  ],
  providers: [
    LayersService,
    CompaniesService,
    DataSourcesService,
    ProcessService,
    InspectionFormService,
    PlacemarksFieldsService,
    CopyConfigFromService,
    TeamsService,
    TemplatesForCopyService,
    SourceConfigurationService,
    ColorHelperService,
    ConfigurationFieldsAdapterService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    {
      provide: MAP_LOADER,
      useFactory: loadGoggleMap,
    }
  ],
  exports: [SimpleModelFileLoaderComponent]
})
export class SourcesModule {
}
