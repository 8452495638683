import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TeamQrCode } from '../models/team-qr-code';

@Injectable({
  providedIn: 'root'
})
export class TeamQrCodeDataService {
  private qrCodeData = new BehaviorSubject<TeamQrCode | null>(null);
  private needToRefreshQrCodeAfterCreateQrCode = new BehaviorSubject<boolean>(false);

  get isNeedToRefreshAfterCreateQrCode$ (): Observable<boolean> {
    return this.needToRefreshQrCodeAfterCreateQrCode.asObservable();
  }

  setQrCodeRefreshState(state: boolean): void {
    this.needToRefreshQrCodeAfterCreateQrCode.next(state);
  }
  setQrCodeDataForPreview(qrCode: TeamQrCode | null): void {
    this.qrCodeData.next(qrCode);
  }

  getQrCodeDataForPreview$(): Observable<TeamQrCode | null> {
    return this.qrCodeData.asObservable();
  }

  downloadQrCode(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
  }
}
