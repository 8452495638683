import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IdentityService } from './services/identity.service';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SidenavService } from './core/sidenav/sidenav.service';
import { LoadingService } from './core/loading-overlay/loading.service';
import { snackBarConfig } from './core/core.module';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslationHandleService } from './core/translate/translation-handle.service';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { WorkerTopic } from '../worker/app-workers/shared/worker-topic.enum';
import { ZendeskService } from './services/zendesk.service';
import { Locales } from './shared/enums/locales';
import { User } from './modules/users/models/user';
import { UsersService } from './modules/users/services/users.service';
import { TeamProjectService } from './services/team-project.service';
import { Identity } from './interfaces/identity';
import { UserRole } from './interfaces/user-role';
import { SidenavItemsTypes } from './core/sidenav/sidenav-item/sidenav-item.model';
import { TelemetryEventService } from './services/telemetry-event.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

const PHRASES_FOR_TRANSLATE = [
  {
    welcome: 'commonPhrases.welcome',
  },
  {
    dashboard: 'sidebar.dashboard',
  },
  {
    userReports: 'sidebar.userReports',
  },
  {
    issueReports: 'sidebar.fieldReports',
  },
  {
    dataCollection: 'sidebar.dataCollection',
  },
  {
    realityCapture: 'sidebar.realityCapture',
  },
  {
    companies: 'sidebar.companies',
  },
  {
    company: 'sidebar.company',
  },
  {
    teams: 'sidebar.teams',
  },
  {
    team: 'sidebar.team',
  },
  {
    devices: 'sidebar.devices',
  },
  {
    dataSources: 'sidebar.dataSources',
  },
  {
    reports: 'sidebar.reports',
  },
  {
    help: 'sidebar.help',
  },
  {
    myProfile: 'sidebar.myProfile',
  },
  {
    components: 'sidebar.components'
  },
  {
    sessionClosed: 'system.sessionClosed',
  },
  {
    users: 'sidebar.users',
  },
  {
    logout: 'sidebar.logout',
  },
];

@Component({
  selector: 'mee-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  translationArray: Map<string, string>;
  isLoading = false;

  workerTopic = WorkerTopic.UpdateNode;

  constructor(
    public loadingService: LoadingService,
    private router: Router,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private sidenavService: SidenavService,
    private identityService: IdentityService,
    private translationService: TranslationHandleService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly zendeskService: ZendeskService,
    private readonly usersService: UsersService,
    private readonly teamProjectService: TeamProjectService,
    private readonly telemetryEventService: TelemetryEventService
  ) {
    const languages = Object.values(Locales);
    translate.addLangs(languages);
    translate.setDefaultLang(Locales.English);
    this.initTeamProject();

    this.translate.onLangChange.pipe(
      tap((langChangeEvent: LangChangeEvent) => {
        this.zendeskService.setWidgetLanguage(langChangeEvent.lang);
        this.translationService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);
        this.initSidenav();
      })
    ).subscribe();

    this.initLanguage().pipe(
      switchMap(() => this.initContactUsForm()),
      filter(isInit => !!isInit),
      tap(() => this.setContactUsFormData(this.identityService.user))
    ).subscribe();

    this.translationService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);
    this.translationService.getTranslationMap().subscribe(res => this.translationArray = res);
    this.identityService.runTokenWatcher();
    this.telemetryEventService.runActiveTabWatcher();
    this.telemetryEventService.runTelemetrySending();
  }

  ngOnInit() {
    this.loadingService
      .isLoading$()
      .pipe(
        debounceTime(100),
        tap(isLoading => {
          this.isLoading = isLoading;
          this.changeDetectorRef.detectChanges();
        }),
      )
      .subscribe();

    this.identityService.onSignIn.pipe(
      tap((identity: Identity) => {

        // The concept is tied on team/project selector, after login we don't have full user roles, it needs to set role from identity

        const adminRole = identity.Roles.filter(role => role.Name !== UserRole.WORKER)[0];
        const teamProject = this.teamProjectService.transformRole(adminRole);
        this.teamProjectService.setTeamProject(teamProject);
      }),
      switchMap(() => this.usersService.getMeData()),
      switchMap((userInformation: User) => {
        this.identityService.user = userInformation;
        this.initTeamProject();
        this.zendeskService.initWidget().then(() => {
          this.zendeskService.setContactFormData(userInformation);
          this.zendeskService.showWidget();
        });

        return this.translate.use(userInformation.Locale ?? Locales.English);
      }),
      tap(() => {
        this.translationService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);
        this.initSidenav();
        this.router.navigate(['/dashboard']).then(() => {
          this.snackBar.open(this.translationArray.get('welcome'), null, snackBarConfig);
        });
      }),
      catchError(err => {
        this.snackBar.open(err.statusText, 'OK', snackBarConfig);
        return throwError(err);
      })
    ).subscribe();

    this.identityService.onLogout.pipe(
      switchMap(() => this.translate.use(Locales.English)),
      tap(() => {
        this.translationService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);
        this.snackBar.open(this.translationArray.get('sessionClosed'), null, {
          duration: 5000,
        });

        this.zendeskService.setContactFormData(null);
        this.zendeskService.hideWidget();
        this.router.navigate(['sign-in']);
      })
    ).subscribe();
  }


  initSidenav() {
    this.sidenavService.clear();

    if (!this.identityService.isTeamAdmin()) {
      this.sidenavService.addItem(this.translationArray.get('company'), 'domain', '/company', 6);
    }

    /**
     * Common pages
     */
    this.sidenavService.addItem(this.translationArray.get('dashboard'), 'dashboard', '/dashboard', 1);
    // TODO: Temporarily hidden
    // this.sidenavService.addItem('Forms', 'message', '/forms', 2);
    // TODO: Not Implemented yet
    // this.sidenavService.addItem('Sessions', 'timelapse', '', 3);
    // this.sidenavService.addItem(this.translationArray.get('userReports'), 'photo_camera', '/reports', 4);

    this.sidenavService.addItem(this.translationArray.get('issueReports'), 'photo_camera', '/issue-reports', 2);

    this.sidenavService.addItem(this.translationArray.get('dataCollection'), 'place', '/data-collection', 3);

    this.sidenavService.addItem(this.translationArray.get('realityCapture'), '', '/reality-capture', 4, 'reality-capture-icon');

    this.sidenavService.addItem(this.translationArray.get('reports'), 'assignment_turned_in', '/system-reports', 5);
    // TODO: Not Implemented yet
    // this.sidenavService.addItem('System Audit', '', '', 5);

    this.sidenavService.addSeparator(5);

    if (this.identityService.isTeamAdmin()) {
      this.sidenavService.addItem(this.translationArray.get('team'), 'group', '/team', 6);
    } else {
      this.sidenavService.addItem(this.translationArray.get('teams'), 'group', '/teams', 6);
    }

    this.sidenavService.addItem(this.translationArray.get('users'), 'person', '/users', 7);
    this.sidenavService.addItem(this.translationArray.get('components'), '', '/components', 8, 'grid-item-add');

    this.sidenavService.addItem(this.translationArray.get('dataSources'), 'settings_ethernet', '/sources', 9);

    this.sidenavService.addSeparator(9);
    this.sidenavService.addItem(this.translationArray.get('help'), 'help', '/help', 10, '', SidenavItemsTypes.Link, 'https://support.vgis.io/');
    this.sidenavService.addItem(this.translationArray.get('myProfile'), 'account_circle', '/profile', 11);
  }

  logout() {
    this.identityService.logout();
  }

  private initLanguage(): Observable<any> {
    const user = this.identityService.user;
    let locale = Locales.English;

    if (!this.identityService.identity) {
      return EMPTY;
    }

    if (user) {
      locale = user.Locale ?? Locales.English;

      return this.translate.use(locale);
    }

    return this.usersService.getMeData().pipe(
      switchMap(userInfo => {
        this.identityService.user = userInfo;
        locale = userInfo.Locale ?? Locales.English;

        return this.translate.use(locale);
      })
    )
  }

  private initTeamProject(): void {
    if (!this.identityService.identity) {
      return;
    }

    const userAdminsRoles = this.identityService.userAdminsRoles;
    const userRole = userAdminsRoles.length !== 0 ? userAdminsRoles[0] : null;
    const teamProjectFromLocalStorage = this.teamProjectService.teamProjectFromLocalStorage;
    let teamProject = this.teamProjectService.transformRole(userRole);

    if (teamProjectFromLocalStorage && teamProjectFromLocalStorage.CompanyName) {
      teamProject = teamProjectFromLocalStorage;
    }

    this.teamProjectService.setTeamProject(teamProject);
  }

  private initContactUsForm(): Observable<boolean> {
    return fromPromise(this.zendeskService.initWidget());
  }

  private setContactUsFormData(userInformation: User): void {
    this.zendeskService.setContactFormData(userInformation);
    this.zendeskService.setWidgetLanguage(userInformation.Locale);
  }
}
