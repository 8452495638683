import {Component, EventEmitter, Input, Output} from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {UntypedFormControl} from '@angular/forms';
import * as moment from 'moment';

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
  },
  display: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
    monthYearLabel: {year: 'numeric'}
  }
};

@Component({
  selector: 'mee-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }]
})
export class DatePickerComponent {

  @Input() dateReturnFormat: string;

  @Input() isWithoutLabel?: boolean;

  @Output() onChooseNewDate = new EventEmitter<string | Date>();

  date = new UntypedFormControl();

  onChangeSelectedDate({ value: selectedDate }) {

    switch (this.dateReturnFormat) {
      case 'YYYY-MM-DD' :
        this.onChooseNewDate.emit(this.getYYYYMMDDFormat(selectedDate));
        break;
      default :
        this.onChangeSelectedDate(selectedDate);
    }
  }

  getYYYYMMDDFormat(selectedDate: Date): string {
    return moment(selectedDate).format('YYYY-MM-DD');
  }
}
