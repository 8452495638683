import { Component, Inject, OnInit } from '@angular/core';
import { TableBuilder } from '../../../../../../core/table/table-builder';
import { ITableColumn } from '../../../../../../core/table/interfaces/table-column.interface';
import { BimNode } from '../../models/bim-node';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mee-bimnodes-table-tab',
  templateUrl: './bimnodes-table-tab.component.html',
  styleUrls: ['./bimnodes-table-tab.component.scss'],
})
export class BimnodesTableTabComponent implements OnInit {
  public tableBuilder: TableBuilder<any>;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
  }

  ngOnInit() {
    this.tableBuilder = new TableBuilder<any>(
      {
        list: this.modifyList(this.data.bimNodes),
      },
      {
        columns: this.getColumns(),
        pagination: {
          hide: true
        }
      },
    );
  }

  getColumns(): ITableColumn[] {
    return [
      {
        key: 'Id',
        title: 'Id',
        sortable: true,
      },
      {
        key: 'Latitude',
        title: 'configurations-fields.Latitude',
        sortable: true,
      },
      {
        key: 'Longitude',
        title: 'configurations-fields.Longitude',
        sortable: true,
      },
      {
        key: 'Altitude',
        title: 'sources.sample-data.ellipsoidalHeight',
        sortable: true,
      },
    ];
  }

  modifyList(data: BimNode[]): { Id: string; Altitude: number; Longitude: number; Latitude: number }[] {
    return data.map(node => {
      return {
        Id: node.CompositeId.split('|').pop(),
        Altitude: node.Origin.Altitude,
        Longitude: node.Origin.Longitude,
        Latitude: node.Origin.Latitude,
      };
    });
  }
}
