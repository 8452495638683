export enum ConfigurationFields {
  MainColor = 'MainColor',
  CoordinateSystem = 'CoordinateSystem',
  MeasurementSystem = 'MeasurementSystem',
  IsMeshInteractionEnabled = 'IsMeshInteractionEnabled',
  FullModel = 'FullModel',
  IsExcludedFromProcessing = 'IsExcludedFromProcessing',
  UseVGisSymbols = 'UseVGisSymbols',
  ObjectType = 'ObjectType',
  DefaultDepth = 'DefaultDepth',
  VerticalDatum = 'VerticalDatum',
  AltitudeMode = 'AltitudeMode',
  ManualCoordinates = 'ManualCoordinates',
  IfcCategorizationStrategy = 'IfcCategorizationStrategy',
  EnableDoubleSideInteraction = 'EnableDoubleSideInteraction',
  IsLocalCoordinateSystem = 'IsLocalCoordinateSystem',
  FloorElevation = 'FloorElevation',
  WorkPacks = 'WorkPacks',
}
