import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MediaUploader } from '../../../../../core/uploader/media-uploader';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { SimpleModelEditFile } from './simple-model-edit-file';
import { SourceBaseFile } from './source-base-file';
import { UploadOutputType } from '../../../../../core/uploader/upload-output-type';
import { UploaderType } from '../../../../../core/uploader/uploader-type';
import { UploadStatus } from 'ngx-uploader';
import { UploadFile } from '../../../../../core/uploader/upload-file';

@Component({
  selector: 'mee-simple-model-file-loader',
  templateUrl: './simple-model-file-loader.component.html',
  styleUrls: ['./simple-model-file-loader.component.scss'],
})
export class SimpleModelFileLoaderComponent extends MediaUploader implements OnInit, OnDestroy {
  multiple = true;

  type = UploaderType.FILE;

  uploadStatus = UploadStatus;

  mediaType = 'datasourcefile';

  @Input() existingFiles: SimpleModelEditFile[];

  @Output() existingFilesChanged = new EventEmitter<SimpleModelEditFile[]>();

  @Output() newFilesAdded = new EventEmitter<SourceBaseFile[]>();

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>;

  private destroy$ = new Subject<void>();

  constructor(dialog: MatDialog) {
    super(dialog);
  }

  ngOnInit() {
    this.uploaded
      .pipe(
        tap(() => {
          if (this.isReplaceExistingFile) {
            this.replaceExistingFile();

            return;
          }

          this.onChangeNewFiles();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  removeFile(file: any): void {

    // for possibility to upload same file twice

    this.fileInput.nativeElement.value = '';

    // this.uploadInput.emit is not working for remove

    this.onUploadOutput({ type: UploadOutputType.Removed, file: file });

    this.onChangeNewFiles();
  }

  cancelUpload(id: string): void {

    // for possibility to upload same file twice

    this.fileInput.nativeElement.value = '';

    super.cancelUpload(id);
  }

  onChangeNewFileTitle(event, newFile: UploadFile) {
    const changedValue = event.target.value;

    this.files = this.files.map(file => {
      if (file.id === newFile.id) {
        return { ...file, customTitle: changedValue };
      }

      return file;
    });

    this.onChangeNewFiles();
  }

  removeExistingFile(file: SimpleModelEditFile) {

    this.existingFiles = this.existingFiles.filter(exFile => exFile.Id !== file.Id);

    this.onChangeExistingFiles();
  }

  onChangeExistingFileTitle(event, file: SimpleModelEditFile) {
    const changedValue = event.target.value;

    this.existingFiles = this.existingFiles.map(exFile => {
      if (exFile.Id === file.Id) {
        return { ...exFile, Title: changedValue };
      }

      return exFile;
    });
    this.onChangeExistingFiles();
  }

  updateFile(replacedFile) {
    this.isReplaceExistingFile = true;

    this.replacedFileId = replacedFile.Id;

    this.fileInput.nativeElement.click();
  }

  private replaceExistingFile() {
    this.existingFiles = this.existingFiles.map(existingFile => {
      const newFile = this.files.find(file => {
        return file.hasOwnProperty('replacedFileId') && file.replacedFileId === existingFile.Id;
      });

      if (newFile) {
        this.onUploadOutput({ type: UploadOutputType.Removed, file: newFile });

        return {
          Id: existingFile.Id,
          FileName: newFile.name,
          FileUrl: newFile.response,
          Title: existingFile.Title ? existingFile.Title : newFile.name,
        };
      }

      return existingFile;
    });

    this.onChangeExistingFiles();

    this.isReplaceExistingFile = false;
  }

  private onChangeNewFiles() {
    const adaptedFiles = this.getNewFileModelFromUploadFile();

    this.newFilesAdded.emit(adaptedFiles);
  }

  private onChangeExistingFiles() {
    this.existingFilesChanged.emit(this.existingFiles);
  }

  private getNewFileModelFromUploadFile(): SourceBaseFile[] {
    return this.files.map(file => {
      return { FileName: file.name, FileUrl: file.response, Title: file.customTitle ? file.customTitle : file.name };
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
