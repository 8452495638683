import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CoordinateSystemType } from '../../enums/coordinate-system-type';
import { DefaultCoordinateSystem } from '../../interfaces/default-coordinate-system.interface';
import {
  ConfigurationFieldsAdapterService,
} from '../../../modules/sources/components/source-configuration-list/components/configuration-tab/configuration-fields-adapter.service';
import {
  SelectOption,
} from '../../../modules/sources/components/source-configuration-list/components/configuration-tab/interfaces/select-option';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import {
  EpsgCodeTipsComponent,
} from '../../../modules/sources/components/source-configuration-list/components/epsg-code-tips/epsg-code-tips.component';
import { CoordinateSystemsService } from '../../../modules/components/coordinate-system/coordinate-systems.service';
import { CoordinateSystem } from '../../../modules/components/coordinate-system/coordinate-system';
import { skip, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatLegacySelectChange } from '@angular/material/legacy-select';
import { TeamProjectService } from '../../../services/team-project.service';
import { VerticalDatums } from '../../../modules/sources/vertical-datums.enum';

@Component({
  selector: 'mee-default-coordinate-system',
  templateUrl: './default-coordinate-system.component.html',
  styleUrls: ['./default-coordinate-system.component.scss'],
})
export class DefaultCoordinateSystemComponent implements OnInit, OnDestroy {
  private formBuilder = inject(FormBuilder);
  private configurationFieldsAdapterService = inject(ConfigurationFieldsAdapterService);
  private matDialog = inject(MatLegacyDialog);
  private coordinateSystemsService = inject(CoordinateSystemsService);
  private teamProjectService = inject(TeamProjectService);

  private destroy$ = new Subject<void>();

  gnssControl = new FormControl<VerticalDatums | null>(null);
  customCoordinateSystems: CoordinateSystem[] = [];
  coordinateSystemTypes: CoordinateSystemType[] = [
    CoordinateSystemType.NotSelected, CoordinateSystemType.Global, CoordinateSystemType.Manual, CoordinateSystemType.SiteCoordinates,
  ];
  verticalDatumList: SelectOption[] = this.configurationFieldsAdapterService.getVerticalDatumOptions();
  measurementSystemList: SelectOption[] = this.configurationFieldsAdapterService.getMeasurementSystemOptions();
  isCustomCoordinateSystem = false;

  @Output() onCoordinateSystemChange = new EventEmitter<DefaultCoordinateSystem>();
  @Output() gnssDatumChange = new EventEmitter<VerticalDatums>();

  coordinateSystemForm = this.formBuilder.group({
    Type: new FormControl<CoordinateSystemType | CoordinateSystem>(CoordinateSystemType.NotSelected),
    CoordinateSystemEpsg: new FormControl<number>(null),
    MeasurementSystem: null,
    VerticalDatum: null,
    VerticalMeasurementSystem: null,
    Latitude: [0, Validators.required],
    Longitude: [0, Validators.required],
    Altitude: [0, Validators.required],
    Yaw: [0, Validators.required],
    Roll: [0, Validators.required],
    Pitch: [0, Validators.required],
    CustomCrsId: null,
    CustomCrsName: null,
  });

  private _coordinateSystem: DefaultCoordinateSystem;

  @Input() set coordinateSystem(coordinateSystem: DefaultCoordinateSystem) {
    this._coordinateSystem = coordinateSystem;
    this.initCoordinateSystemForm();
  }

  @Input() set gnssDatum(datum: VerticalDatums) {
    this.gnssControl.setValue(datum, { emitEvent: false })
  }

  get isValid(): boolean {
    return this.coordinateSystemForm.valid;
  }

  get isCoordinateSystemEpsgDisplay(): boolean {
    return this.coordinateSystemForm.get('Type').value === CoordinateSystemType.Global;
  }

  get isManualCoordinatesDisplay(): boolean {
    return this.coordinateSystemForm.get('Type').value === CoordinateSystemType.Manual;
  }

  get isVerticalDatumDisplay(): boolean {
    return this.coordinateSystemForm.get('Type').value === CoordinateSystemType.Manual
      || this.coordinateSystemForm.get('Type').value === CoordinateSystemType.Global
      && !this.isCustomCoordinateSystem;
  }

  ngOnInit(): void {
    this.onCoordinateSystemFormChange();
    this.getCustomCoordinateSystems();
    this.onTeamProjectChange();
    this.initHandlerGnssDatumChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openEpsgHintDialog(event: Event): void {
    event.stopPropagation();

    this.matDialog.open(EpsgCodeTipsComponent, {
      autoFocus: false,
    });
  }

  getCoordinateSystemLabel(coordinateSystemType: CoordinateSystemType): string {
    switch (coordinateSystemType) {
      case CoordinateSystemType.SiteCoordinates:
        return 'Site coordinates (xyz)';
      case CoordinateSystemType.NotSelected:
        return 'Not Selected';
      default:
        return CoordinateSystemType[coordinateSystemType];
    }
  }

  onCoordinateSystemTypeChange(event: MatLegacySelectChange): void {
    this.isCustomCoordinateSystem = typeof event.value !== 'number';
    const isManualSystemCoordinates = event.value === CoordinateSystemType.Manual;
    const isSiteCoordinateSystem = event.value === CoordinateSystemType.SiteCoordinates;
    const isGlobalCoordinateSystem = event.value === CoordinateSystemType.Global;

    if (isGlobalCoordinateSystem) {
      this.clearCustomCrsFields();
      this.clearManualCoordinatesFields();

      this.coordinateSystemForm.get('CoordinateSystemEpsg').setValidators(Validators.required);
    } else {
      this.coordinateSystemForm.get('CoordinateSystemEpsg').removeValidators(Validators.required);
      this.coordinateSystemForm.get('CoordinateSystemEpsg').setErrors(null);
    }

    if (isManualSystemCoordinates) {
      this.clearEpsg();
    }

    if (isSiteCoordinateSystem || this.isCustomCoordinateSystem) {
      this.clearEpsg();
      this.clearVerticalDatum();
      this.clearManualCoordinatesFields();
    }

    if (this.isCustomCoordinateSystem) {
      const customCoordinateSystem: CoordinateSystem = event.value;

      this.coordinateSystemForm.get('CustomCrsId').setValue(customCoordinateSystem.Id);
      this.coordinateSystemForm.get('CustomCrsName').setValue(customCoordinateSystem.Name);

      return;
    }
  }

  private clearCustomCrsFields(): void {
    this.coordinateSystemForm.get('CustomCrsId').setValue(null);
    this.coordinateSystemForm.get('CustomCrsName').setValue(null);
  }

  private clearVerticalDatum(): void {
    this.coordinateSystemForm.get('VerticalDatum').setValue(null);
    this.coordinateSystemForm.get('VerticalMeasurementSystem').setValue(null);
  }

  private clearEpsg(): void {
    this.coordinateSystemForm.get('CoordinateSystemEpsg').setValue(null);
    this.coordinateSystemForm.get('MeasurementSystem').setValue(null);
  }

  private clearManualCoordinatesFields(): void {
    this.coordinateSystemForm.get('Altitude').setValue(0);
    this.coordinateSystemForm.get('Latitude').setValue(0);
    this.coordinateSystemForm.get('Longitude').setValue(0);
    this.coordinateSystemForm.get('Pitch').setValue(0);
    this.coordinateSystemForm.get('Roll').setValue(0);
    this.coordinateSystemForm.get('Yaw').setValue(0);
  }

  private initHandlerGnssDatumChange(): void {
    this.gnssControl.valueChanges.pipe(
      tap(datum => this.gnssDatumChange.emit(datum)),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  private onCoordinateSystemFormChange(): void {
    this.coordinateSystemForm.valueChanges.pipe(
      tap(formValue => {
        let data = { ...formValue } as DefaultCoordinateSystem;

        if (this.isCustomCoordinateSystem) {
          data = { ...data, Type: CoordinateSystemType.Custom };
        }

        if (this.coordinateSystemForm.get('Type').value === CoordinateSystemType.NotSelected) {
          data = null;
        }

        this.onCoordinateSystemChange.emit(data);
      }),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  private initCoordinateSystemForm(): void {
    if (!this._coordinateSystem) {
      this.coordinateSystemForm.get('Type').setValue(CoordinateSystemType.NotSelected, { emitEvent: false });

      return;
    }

    this.isCustomCoordinateSystem = this._coordinateSystem.Type === CoordinateSystemType.Custom;

    if (!this.isCustomCoordinateSystem) {
      this.coordinateSystemForm.patchValue(this._coordinateSystem, { emitEvent: false });
    }

    if (this._coordinateSystem.Type === CoordinateSystemType.Global) {
      this.coordinateSystemForm.get('CoordinateSystemEpsg').setValidators(Validators.required);
    } else {
      this.coordinateSystemForm.get('CoordinateSystemEpsg').removeValidators(Validators.required);
    }
  }

  private onTeamProjectChange(): void {
    this.teamProjectService.teamProject$.pipe(
      skip(1),
      tap(() => this.getCustomCoordinateSystems()),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  private getCustomCoordinateSystems(): void {
    this.coordinateSystemsService.get().pipe(
      tap(list => {
        this.customCoordinateSystems = list;

        if (this.isCustomCoordinateSystem) {
          const customCrsSystem = list.find(crs => crs.Id === this._coordinateSystem.CustomCrsId);

          this.coordinateSystemForm.get('Type').setValue(customCrsSystem, { emitEvent: false });
        }
      }),
      takeUntil(this.destroy$),
    ).subscribe();
  }
}
