import { Component, Input, OnInit } from '@angular/core';
import { ITableColumn } from '../interfaces/table-column.interface';
import { TableContentTypes } from '../table-column-types.enum';
import each from 'lodash-es/each';

@Component({
  selector: 'mee-table-cell-content',
  templateUrl: './table-cell-content.component.html',
  styleUrls: ['./table-cell-content.component.scss'],
})
export class TableCellContentComponent implements OnInit {
  @Input() column: ITableColumn;
  @Input() content = '';
  @Input() param = null;
  @Input() isEventBubblingDisabled?;

  contentTypes = TableContentTypes;
  displayingContent = '';

  constructor() {}

  ngOnInit() {
    if (this.column.contentFn) {
      if (this.param !== null) {
        this.column.contentFn(this.content, this.param).subscribe(answer => (this.displayingContent = answer));
        return;
      }

      this.column.contentFn(this.content).subscribe(answer => (this.displayingContent = answer));
    } else {
      this.displayingContent = this.content;
    }
  }

  showTooltip(item) {
    return Array.isArray(item) && item.length > 1 && typeof item !== 'object';
  }

  getTooltip(item) {
    let tooltip = '';
    if (this.showTooltip(item)) {
      each(item, row => (tooltip += `<li>${row}</li>`));
      tooltip = `<ul>${tooltip}</ul>`;
    }

    return tooltip;
  }

  checkBubbling($event: MouseEvent) {
    if (this.isEventBubblingDisabled) {
      $event.stopPropagation();
    }
  }
}
