import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CopyConfigFromItemComponent } from '../copy-config-from-item/copy-config-from-item.component';
import { CopyConfigFromService, IDialogDataForCopyConfigList } from '../copy-config-from.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'mee-copy-config-from-list',
  templateUrl: './copy-config-from-list.component.html',
  styleUrls: ['./copy-config-from-list.component.scss'],
})
export class CopyConfigFromListComponent implements OnInit {

  isCopyDSConfig: boolean;

  selectedTab = 0;

  subs: Subscription[] = [];

  constructor(private matDialog: MatDialog,
              private copyConfigFromService: CopyConfigFromService,
              @Inject(MAT_DIALOG_DATA) public dialogData: IDialogDataForCopyConfigList) {
  }

  ngOnInit() {
    this.isCopyDSConfig = this.dialogData.destinationLayer.isRoot;
  }

  selectedTabChanged(selectedTabIndex) {
    this.selectedTab = selectedTabIndex;
  }

  onCloseModal() {
    this.matDialog.closeAll();
  }

  onChooseDSForCopy(sourceForCopy) {

    if (this.isCopyDSConfig) {

      const dataForCopyDSConfig = {
        companySlug: this.dialogData.destinationDS.CompanySlug,
        sourceDSId: sourceForCopy.Id,
        destinationDSId: this.dialogData.destinationDS.Id,
      };

      this.subs.push(
        this.copyConfigFromService.getDSPossibleCopyingConfig(dataForCopyDSConfig).subscribe(response => {
          this.matDialog.open(CopyConfigFromItemComponent, {
            width: '400px',
            height: '640px',
            panelClass: 'copy-config-from-item-dialog',
            data: {
              sourceDS: sourceForCopy,
              destinationDS: this.dialogData.destinationDS,
              destinationLayer: this.dialogData.destinationLayer,
              DSWithPossibleCopyFLConfig: response,
            },
            disableClose: true,
            closeOnNavigation: true,
          });
        }),
      );

    } else {
      this.matDialog.open(CopyConfigFromItemComponent, {
        width: '400px',
        height: '640px',
        panelClass: 'copy-config-from-item-dialog',
        data: {
          sourceDS: sourceForCopy,
          destinationDS: this.dialogData.destinationDS,
          destinationLayer: this.dialogData.destinationLayer,
        },
        disableClose: true,
        closeOnNavigation: true,
      });
    }
  }

}
