import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SidenavService } from '../sidenav/sidenav.service';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DropdownService } from '../../services/dropdown.service';
import { CreateDeviceComponent } from '../../modules/global-add/create-device/create-device.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IdentityService } from '../../services/identity.service';
import { AddLayerComponent } from '../../modules/global-add/add-layer/add-layer.component';
import { AddDataSourceComponent } from '../../modules/global-add/add-data-source/add-data-source.component';
import { AddDtmComponent } from '../../modules/global-add/add-dtm/add-dtm.component';
import { TeamsService } from '../../modules/teams/services/teams.service';
import { PermissionType } from '../../shared/enums/permission-type.enum';
import { TeamInterface } from '../../modules/teams/models/team.interface';
import { AddCustomMapComponent } from '../../modules/components/custom-maps/add-custom-map/add-custom-map.component';
import { TeamQrCodeFormComponent } from '../../modules/components/team-qr-codes/team-qr-code-form/team-qr-code-form.component';
import { TeamQrCodeModalData } from '../../modules/components/team-qr-codes/models/team-qr-code-modal-data';
import { CreateUserModalComponent } from '../../modules/users/create-user-modal/create-user-modal.component';
import { UserModalData } from '../../modules/users/models/user-modal-data';
import { FindUserModalComponent } from '../../modules/users/find-user-modal/find-user-modal.component';
import { User } from '../../modules/users/models/user';
import { MatDrawerMode } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'mee-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', { static: true }) sidenav;

  @ViewChild('scrollContainer', { static: true }) scrollContainer;
  isDropdownOpened = false;
  destroy$ = new Subject<void>();
  sidenavOpen = true;
  sidenavMode: MatDrawerMode = 'side';
  isMobile = false;
  outletState = 'hidden';
  dropdownTrigger = '#dropdown-trigger';

  private isCreatePermissionsAllowed: boolean;

  get isSuperAdmin(): boolean {
    return this.identityService.isSuperAdmin();
  }

  get isCompanyAdmin(): boolean {
    return this.identityService.isCompanyAdmin();
  }

  get isTeamAdmin(): boolean {
    return this.identityService.isTeamAdmin();
  }

  get isShowDtmCreateButton() {
    if (this.isSuperAdmin || this.isCompanyAdmin) {
      return true;
    }

    return this.isCreatePermissionsAllowed;
  }

  get isShowMapCreateButton() {
    if (this.isSuperAdmin || this.isCompanyAdmin) {
      return true;
    }

    return this.isCreatePermissionsAllowed;
  }

  get isShowLayerCreateButton() {
    if (this.isSuperAdmin || this.isCompanyAdmin) {
      return true;
    }

    return this.isCreatePermissionsAllowed;
  }

  get isShowDataSourceCreateButton() {
    if (this.isSuperAdmin || this.isCompanyAdmin) {
      return true;
    }

    return this.isCreatePermissionsAllowed;
  }

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private sidenavService: SidenavService,
    private teamsService: TeamsService,
    private activatedRoute: ActivatedRoute,
    private readonly dropdownService: DropdownService,
    private readonly matDialog: MatDialog,
    private readonly identityService: IdentityService,
  ) {
    const self = this;

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        tap(event => {
          if (event instanceof NavigationStart) {
            if (event.url !== router.url) {
              self.outletState = 'hidden';
            }
          }
        }),
      )
      .subscribe();

    this.sidenavService.onOpen
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.sidenav.open()),
      )
      .subscribe();

    this.sidenavService.onClose
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.sidenav.close()),
      )
      .subscribe();

    this.sidenavService.onToggle
      .pipe(
        takeUntil(this.destroy$),
        tap((opened: boolean) => {
          this.sidenavOpen = opened;
          if (this.sidenavOpen) {
            this.sidenav.open();
          } else {
            this.sidenav.close();
          }
        }),
      )
      .subscribe();

    this.sidenavService.mode.subscribe(mode => (this.sidenavMode = mode));
    this.dropdownService.isOpened$
      .pipe(
        tap(isOpened => (this.isDropdownOpened = isOpened)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 960px)']).pipe(
      tap(result => {
        this.isMobile = result.matches;
        this.sidenavMode = this.isMobile ? 'over' : 'side';
        this.sidenavOpen = !this.isMobile;
      }),
      takeUntil(this.destroy$),
    ).subscribe();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
        tap(() => {
          this.scrollContainer.nativeElement.scrollTop = 0;
          if (this.isMobile) {
            this.sidenav.close();
          }
        }),
      )
      .subscribe();

    fromEvent(window, 'resize')
      .pipe(
        tap(() => {
          if (this.sidenavOpen && !this.sidenav.opened) {
            this.sidenav.open();
          }
        }),
      )
      .subscribe();

    if (this.isTeamAdmin) {
      this.setTeamPermissions();
    }
  }

  onActivate(event: any, scrollContainer: any): void {
    scrollContainer.scrollTop = 0;
    this.outletState = 'shown';
  }

  createDevice(event: any) {
    event.preventDefault();
    this.close(event);
    this.matDialog.open(CreateDeviceComponent, {
      width: '392px',
      panelClass: 'create-device',
    });
  }

  createUser(event: any) {
    event.preventDefault();
    this.close(event);

    this.matDialog.open<FindUserModalComponent, null, UserModalData>(FindUserModalComponent, {
      width: '392px',
      height: '272px',
    }).afterClosed().pipe(
      filter(value => Boolean(value)),
      switchMap(data => {
        return this.matDialog.open<CreateUserModalComponent, UserModalData, User>(CreateUserModalComponent, {
          width: '392px',
          panelClass: 'create-user',
          data,
        }).afterClosed();
      }),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  createLayer(event: any) {
    event.preventDefault();
    this.close(event);
    this.matDialog.open(AddLayerComponent, {
      width: '400px',
      panelClass: 'create-layer',
    });
  }

  createBasemap(event: any) {
    event.preventDefault();
    this.close(event);
    this.matDialog.open(AddCustomMapComponent, {
      width: '792px',
      panelClass: 'custom-map-dialog',
      data: {},
    });
  }

  createDataSource(event: MouseEvent) {
    event.preventDefault();
    this.close(event);
    this.matDialog.open(AddDataSourceComponent, {
      width: '392px',
      panelClass: 'add-data-source',
    });
  }

  createQrCode(event: MouseEvent) {
    event.preventDefault();
    this.close(event);
    this.matDialog.open<TeamQrCodeFormComponent, TeamQrCodeModalData, null>(TeamQrCodeFormComponent, {
      autoFocus: false,
      width: '392px',
      data: {
        isUpdate: false,
        qrCode: null,
      },
    });
  }

  createDTM(event: MouseEvent) {
    event.preventDefault();
    this.close(event);
    this.matDialog.open(AddDtmComponent, {
      width: '392px',
      panelClass: 'elevation-form',
    });
  }

  close(event: any) {
    event.stopPropagation();
    this.dropdownService.close();
  }

  closeOnMisclick(shouldNotBeClosed: boolean) {
    if (!shouldNotBeClosed) {
      this.dropdownService.close();
    }
  }

  private setTeamPermissions() {
    /*TODO deal with null static injector error in app-routing module for remove [1]*/

    const currentTeam = this.activatedRoute.snapshot.data[1] as TeamInterface;

    const createPermission = currentTeam.Permissions.find(permission => permission.Type === PermissionType.Create);

    this.isCreatePermissionsAllowed = createPermission.IsAllowed;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
