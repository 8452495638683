import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadButtonComponent } from './file-upload-button/file-upload-button.component';
import { ImageUploadButtonComponent } from './image-upload-button/image-upload-button.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgxUploaderModule
  ],
  declarations: [
    FileUploadButtonComponent,
    ImageUploadButtonComponent
  ],
  exports: [
    FileUploadButtonComponent,
    ImageUploadButtonComponent
  ]
})
export class UploaderModule { }
