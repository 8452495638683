import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormControl } from '@angular/forms';
import { ICategory } from '../../../modules/company/services/categories.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CategorySetData } from '../../interfaces/category-set-data';
import { ICompany } from '../../../modules/companies/shared/interfaces/company.interface';
import { IdentityService } from '../../../services/identity.service';
import { CompaniesService } from '../../../modules/companies/companies.service';
import { CategorySetActions } from '../../enums/category-set-actions.enum';

@Component({
  selector: 'mee-category-set-dialog',
  templateUrl: './category-set-dialog.component.html',
  styleUrls: ['./category-set-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategorySetDialogComponent implements OnInit, OnDestroy {
  categoryName = new UntypedFormControl();
  filteredCategories: ICategory[];
  categories: ICategory[];
  isCreateNewCategory = false;

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { itemsCount: number },
    private identityService: IdentityService,
    private companiesService: CompaniesService,
    private dialogRef: MatDialogRef<CategorySetDialogComponent>,
    private cdr: ChangeDetectorRef,
  ) {
    this.setCategories();
  }

  ngOnInit() {
    this.categoryName.valueChanges
      .pipe(
        tap(value => {
          this.filteredCategories = this.getFilteredCategories(value);
          this.isCreateNewCategory = this.filteredCategories.length === 0;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  setCategories() {
    if (this.identityService.isCompanyAdmin()) {
      this.companiesService
        .getMyCompany()
        .pipe(
          tap((company: ICompany) => {
            this.categories = company.Categories;
            this.filteredCategories = company.Categories;
            this.cdr.markForCheck();
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  createNewCategory() {
    const newCategoryName = this.categoryName.value;

    const data: CategorySetData = {
      action: CategorySetActions.Create,
      categoryName: newCategoryName,
    };

    this.dialogRef.close(data);
  }

  setCategory(category: ICategory) {
    const data: CategorySetData = {
      action: CategorySetActions.Set,
      category: category,
    };

    this.dialogRef.close(data);
  }

  private getFilteredCategories(name: string): ICategory[] {
    const searchName = name.trim().toLowerCase();

    return this.categories.filter(category => category.Name.toLowerCase().includes(searchName));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
