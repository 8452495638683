import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { TableDialog } from '../../table/table-dialog.component';
import { ITableModel } from '../../table/interfaces/table-model.interface';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TableBuilder } from '../table-builder';

@Component({
  selector: 'mee-table-item-view',
  templateUrl: './table-item-view.component.html',
  styleUrls: ['./table-item-view.component.scss']
})
export class TableItemViewComponent extends TableDialog<ITableModel> implements OnInit {

  public builder: TableBuilder<any>;

  @ViewChild('auto') auto: ElementRef;

  model: any;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
    public dialogRef: MatDialogRef<TableItemViewComponent>
  ) {
    super();

    this.model = this.dialogData.model;
    this.builder = this.dialogData.builder;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  parse(item) {
    if (Array.isArray(item)) {
      return item.join(', ').trim();
    }
    return item;
  }
}
