import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ILayer } from '../../../../../../../components/layers/shared/models/layer.interface';
import { SingleForm } from '../../interfaces/single-form';
import { PlacemarksFields } from '../../../../models/placemarks-fields';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Symbology } from '../../enums/symbology.enum';
import { ConfigurationFieldsAdapterService } from '../../configuration-fields-adapter.service';
import { Point3DShape } from '../../../../../../point3dshape.enum';
import { SubtypeConfiguration } from '../../interfaces/subtype-configuration';
import { ConfigurationFields } from '../../enums/configuration-fields.enum';
import { FeatureLayerType } from '../../interfaces/feature-layer-type.enum';
import { isDisplayForCurrentSourceType } from '../../is-display-for-current-source-type.function';
import { DataSourceType } from '../../../../../../data-source-type.enum';
import { ObjectType } from '../../enums/object-types.enum';

@Component({
  selector: 'mee-subtype-point-configuration',
  templateUrl: './subtype-point-configuration.component.html',
  styleUrls: ['./subtype-point-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubtypePointConfigurationComponent implements SingleForm {

  form: UntypedFormGroup;

  symbology = Symbology;

  point3DShape = Point3DShape;

  configurationFields = ConfigurationFields;

  point3DShapesOptions = this.configurationFieldsAdapterService.getPoint3DShapes();

  objectTypes = ObjectType;

  private localLayers: ILayer[];

  private localNode: SubtypeConfiguration;

  @Input() placeMarksFields: PlacemarksFields;

  @Input() sourceType: DataSourceType;

  @Input() set node(node: SubtypeConfiguration) {

    if (node === undefined) {
      return;
    }

    this.localNode = node;

    if (node.ObjectTypeConfiguration === null) {
      node = {
        ...node, ObjectTypeConfiguration: {
          ObjectType: ObjectType.POINT,
        },
      } as SubtypeConfiguration;
    }

    this.form.patchValue(node, { emitEvent: false });
  }

  get node() {
    return this.localNode;
  }

  @Input() set layers(layers: ILayer[]) {

    this.localLayers = layers;

    if (layers.length === 0) {
      this.form.get('Layer').disable();
    }
  };

  get layers() {
    return this.localLayers;
  }

  get mainColor() {
    return this.form.get('MainColor').value;
  }

  get isTitleNotEmpty() {
    return this.form.get('Title').value !== null;
  }

  constructor(private formBuilder: UntypedFormBuilder,
              private configurationFieldsAdapterService: ConfigurationFieldsAdapterService) {
    this.initForm();
  }

  clearTitle() {
    this.form.get('Title').reset();
  }

  onChangeSymbology(event: MatButtonToggleChange) {
    this.form.get('UseVGisSymbols').setValue(event.value === Symbology.vGIS);
  }

  isDisplayForCurrentSourceType(fieldName: ConfigurationFields) {
    return isDisplayForCurrentSourceType(fieldName, FeatureLayerType.Line, this.sourceType);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      Id: [],
      Layer: [],
      Title: [],
      TitleField: [],
      MainColor: [],
      UseVGisSymbols: [],
      DefaultDepth: [],
      DepthField: [],
      ObjectTypeConfiguration: this.formBuilder.group({
        ObjectType: [{ value: null, disabled: true }],
        BoundingBoxShape: [],
        UndergroundConnectorDepth: [],
        BoundingBoxHeight: [],
        BoundingBoxHeightField: [],
        BoundingBoxWidth: [],
        BoundingBoxWidthField: []
      }),
      LabelConfiguration: [],
      PlacemarkImage: [],
    });
  }

}
