import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ISchedule, ISource } from '../../models/source.interface';
import { ICompany } from '../../../companies/shared/interfaces/company.interface';
import { IdentityService } from '../../../../services/identity.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DataSourcesService } from '../../services/data-sources.service';
import { CompaniesService } from '../../../companies/companies.service';
import { ActivatedRoute, Router } from '@angular/router';
import { identifierMaxLength, lockInvalidControl, slugPattern } from '../../../../core/utils/validators';
import { snackBarConfig } from '../../../../core/core.module';
import { TableDialogForm } from '../../../../core/table/table-dialog-form';
import { TableFormMode } from '../../../../core/table/interfaces/form.interface';
import * as enums from '../../arcgis-security-types.enum';
import { ArcGisSecurityTypes } from '../../arcgis-security-types.enum';
import { TranslationHandleService } from '../../../../core/translate/translation-handle.service';
import { EMPTY, Observable, Subject, Subscription, throwError } from 'rxjs';
import { DataSourceType } from '../../data-source-type.enum';
import { catchError, filter, finalize, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { DOCUMENT } from '@angular/common';
import { MEASUREMENT_SYSTEM_MODEL } from '../source-configuration-list/models/measurement-system.model';
import { DATA_SOURCE_TYPES_UPDATE_FORM } from '../../../../shared/models/data-source-types.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Bim360ModelsTreeComponent } from './bim360-models-tree/bim360-models-tree.component';
import { Bim360ModelsTreeDialogData } from './bim360-models-tree/bim360-models-tree-dialog-data';
import { Bim360ModelsNode } from '../../models/bim360-model-node';
import { EntityBaseInfo } from '../../../../shared/interfaces/entity-base-info';
import { BasicSecurityTypes } from '../source-configuration-list/components/configuration-tab/enums/basic-security-types.enum';
import { DataSourcesAdapterService } from '../source-configuration-list/components/configuration-tab/data-sources-adapter.service';
import { SecurityType } from '../source-configuration-list/components/configuration-tab/interfaces/security-type';
import { PermissionFields } from '../../../companies/shared/interfaces/permission-fields';
import { Pix4dSelectProjectsComponent } from './pix4d-select-projects/pix4d-select-projects.component';
import { Pix4dProject } from './pix4d-select-projects/pix4d-project';
import { Pix4dProjectsDialogData } from './pix4d-select-projects/pix4d-projects-dialog-data';
import { SourcesRelatedDataService } from '../../services/sources-related-data.service';
import { CategoriesService } from '../../../company/services/categories.service';
import { CreateCategoryData } from '../../../../shared/interfaces/create-category-data';
import { MeasurementSystem } from '../source-configuration-list/components/configuration-tab/interfaces/measurement-system.enum';
import { SimpleModelEditFile } from './simple-model-file-loader/simple-model-edit-file';
import { SourceBaseFile } from './simple-model-file-loader/source-base-file';
import { IMediaFile } from '../../../../core/uploader/media-file.interface';
import { AutofillFieldService } from '../../../../services/autofill-field.service';
import { UploadOutputType } from '../../../../core/uploader/upload-output-type';
import { LayerStatistic } from '../source-configuration-list/interfaces/layer-statistic';
import { SourceStatisticComponent } from '../source-configuration-list/components/source-statistic/source-statistic.component';
import { IProcessingLogRecords } from '../../models/data-source.interface';
import { ProcessingLogsData } from '../../models/processing-logs-data';
import { ConfigurationHeaderTemplateService } from '../source-configuration-list/services/configuration-header-template.service';
import { SourcesFormChangesService } from '../../services/sources-form-changes.service';
import { ProcessingHistoryComponent } from './processing-history/processing-history.component';
import { Process } from '../../models/process.interface';
import { ProcessService } from '../process-list/process.service';

const PHRASES_FOR_TRANSLATE = [
  {
    sourceWasUpdated: 'sources.update',
  },
  {
    sourceWasCreated: 'sources.create',
  },
  {
    mapFileLink: 'sources.mapFileLink',
  },
  {
    public: 'sources.details.Public',
  },
  {
    password: 'sources.details.Password',
  },
  {
    token: 'sources.details.Token',
  },
  {
    ntlm: 'sources.details.Ntlm',
  },
  {
    fullIntegrationType: 'sources.details.fullIntegrationType',
  },
  {
    infrastructureIntegrationType: 'sources.details.infrastructureIntegrationType',
  },
  {
    dMeshIntegrationType: 'sources.details.3dMeshIntegrationType',
  },
];

@Component({
  selector: 'mee-source-form',
  templateUrl: './source-form.component.html',
  styleUrls: ['./source-form.component.scss'],
})
export class SourceFormComponent extends TableDialogForm implements OnInit, OnDestroy, AfterViewInit {
  form: UntypedFormGroup;

  source: ISource;

  isShowScheduling: boolean;

  scheduleSettings: ISchedule;

  processingStatistic: LayerStatistic;

  isRealTimeFormControl = new UntypedFormControl(false);

  /**
   * changed when we back from process details back on update data source page
   */

  defaultTabToOpen = 0;

  arcGisSecurityTypes: SecurityType<ArcGisSecurityTypes>[];

  wfsSecurityTypes: SecurityType<BasicSecurityTypes>[];

  private destroy$ = new Subject<void>();
  private autoFillDestroy$ = new Subject<void>();

  types = DATA_SOURCE_TYPES_UPDATE_FORM;

  integrationTypes = [];

  /**
   * List of companies
   */
  companies: Array<ICompany>;

  subs: Subscription[] = [];

  translationMap: Map<string, string>;

  previewLinkName: string;
  editModeCategoryName: string;

  typesFiltered: Observable<any[]>;

  measurementSystemUnits: Array<{ label: string; value: MeasurementSystem }> = MEASUREMENT_SYSTEM_MODEL;

  bim360TreeDialogRef: MatDialogRef<Bim360ModelsTreeComponent, Bim360ModelsNode[]>;

  pix4dTreeDialogRef: MatDialogRef<any>;
  selectedPix4dProjectsAmount: number;

  isBim360TreeLoading = false;

  isPix4dProjectsLoading = false;

  isSelectedPix4dNodesChanged = false;

  availableProjects: Array<EntityBaseInfo>;
  isCurrentDataSourceInProcessing = false;
  isProcessingHistoryLoading = false;

  companyName: string;

  get simpleModelExistingFiles(): any[] {
    if (!this.isSimpleModelType) {
      return [];
    }

    const isSourceFiles = this.form.get('Files') && this.form.get('Files').value.length !== 0;

    return isSourceFiles ? this.form.get('Files').value : [];
  }
  readonly identifierMaxLength = identifierMaxLength;
  private permissionsData: PermissionFields;
  private isSelectedBim360NodesChanged = false;
  private isCreateCategory: boolean;
  private createCategoryData: CreateCategoryData;
  private uploadStatus = UploadOutputType.Done;
  private localProcessingLogs: IProcessingLogRecords[] = [];

  @ViewChild('generalHeaderTemplate') generalHeaderTemplate: TemplateRef<any>;

  @Output() formChange = new EventEmitter<boolean>();

  get isSuperAdmin() {
    return this.identityService.isSuperAdmin();
  }

  get isCompanyAdmin() {
    return this.identityService.isCompanyAdmin();
  }

  get selectedModelsAmount(): number {
    const selectedModels = this.form.get('SelectedNodes');

    if (selectedModels.value && selectedModels.value.length > 0) {
      return selectedModels.value.length;
    }

    return this.source.SelectedNodes && this.source.SelectedNodes.length > 0 ? this.source.SelectedNodes.length : 0;
  }

  get isUserAuthorizedInAutodesk() {
    if (this.isCreateMode()) {
      return false;
    }

    return this.source.IntegrationStatus.IsAuthorized && this.source.IntegrationStatus.IsAppIntegrationEnabled;
  }

  get isShowBim360SelectedModels() {
    return (
      (this.isUpdateMode() && this.form.get('SelectedNodes').value !== null && this.form.get('ProjectId').value !== null) ||
      (this.isUpdateMode() &&
        this.form.get('ProjectId').value === this.source.ProjectId &&
        this.source.SelectedNodes !== null &&
        this.source.SelectedNodes.length > 0)
    );
  }

  get isShowPix4dSelectedProjects() {
    const selectedProjects = this.source.SelectedProjects;
    const formSelectedProjects = this.form.get('SelectedProjects').value;

    return (selectedProjects !== null && selectedProjects.length > 0) || (this.isUpdateMode() && formSelectedProjects !== null);
  }

  get isDisplaySelectBim360Files() {
    return this.isUserAuthorizedInAutodesk && this.form.get('ProjectId').value !== null;
  }

  get file(): SourceBaseFile {
    return this.form.get('File').value;
  }

  get mediaType(): string {
    return this.isKmlType() ? 'maps' : 'datasourcefile';
  }

  get isShowFileLoader(): boolean {
    return this.isKmlType() || this.isNativeBimType() || this.isLandXmlType() || this.isShapefileType() || this.isNativeGisType();
  }

  get isFormInvalid(): boolean {
    return this.form.invalid || this.isCurrentDataSourceInProcessing || !this.isIdentifier;
  }

  get isUploadFileInvalid(): boolean {
    return (this.isShowFileLoader || this.isSimpleModelType()) && this.uploadStatus === UploadOutputType.Uploading;
  }

  get isProcessingLogs(): boolean {
    return this.localProcessingLogs.length !== 0;
  }

  get isIdentifier(): boolean {
    const identifierControl = this.form.get('Slug');

    return identifierControl && Boolean(identifierControl.value.length);
  }

  @Input() set dataSource(dataSource: ISource) {
    this.source = dataSource;
  }

  @Input() set statistic(statistic: LayerStatistic) {
    this.processingStatistic = statistic;
  }

  @Input() set processingLogs(logs: IProcessingLogRecords[]) {
    this.localProcessingLogs = logs;
  }

  @Input() set isCurrentDSInProcessing(state: boolean) {
    this.isCurrentDataSourceInProcessing = state;
  }

  constructor(
    private identityService: IdentityService,
    protected formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private dataSourcesService: DataSourcesService,
    private sourcesRelatedDataService: SourcesRelatedDataService,
    private dataSourcesAdapterService: DataSourcesAdapterService,
    private companiesService: CompaniesService,
    private categoriesService: CategoriesService,
    private translationHandleService: TranslationHandleService,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private readonly autofillFieldService: AutofillFieldService,
    private readonly configurationHeaderTemplateService: ConfigurationHeaderTemplateService,
    private readonly sourcesFormChangesService: SourcesFormChangesService,
    private readonly processService: ProcessService,
    @Inject(DOCUMENT) private document,
  ) {
    super(formBuilder);
    this.translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);

    this.subs.push(
      this.translationHandleService.getTranslationMap().subscribe(translationMap => {
        this.translationMap = translationMap;
      }),
    );

    this.integrationTypes = [
      {
        value: 0,
        title: this.translationMap.get('fullIntegrationType'),
      },
      {
        value: 1,
        title: this.translationMap.get('infrastructureIntegrationType'),
      },
      {
        value: 2,
        title: this.translationMap.get('dMeshIntegrationType'),
      },
    ];
  }

  ngOnInit() {
    this.initArcGisSecurityTypes();
    this.initWFSData();

    this.previewLinkName = this.translationMap.get('mapFileLink');

    if (this.isTeamAdmin()) {
      this.isShowScheduling = this.getIsShowSchedulingTab();
      this.setDataForUpdateMode();

      if (this.source.Category) {
        this.editModeCategoryName = this.source.Category.Name;
        this.form.get('CategoryName').setValue(this.source.Category.Name);
      }

      this.form.get('Slug').disable();

      this.autoFillDestroy$.next();
      this.autoFillDestroy$.complete();

      if (this.isBim360Type()) {
        this.initBim360Data();
      }

      if (this.isPix4dType()) {
        this.initPix4dData();
      }

      // if (this.source === undefined) {
      //   const companySlug = this.route.snapshot.paramMap.get('companySlug');
      //   const sourceSlug = this.route.snapshot.paramMap.get('sourceSlug');
      //
      //   if (this.sourcesRelatedDataService.getNewlyAddedSourceForTeamAdmin() === null && companySlug === null) {
      //     this.mode = TableFormMode.CREATE;
      //   } else if (this.sourcesRelatedDataService.getNewlyAddedSourceForTeamAdmin() === null && companySlug !== null) {
      //     // visit edit source form first time
      //   } else {
      //     // visit edit source form after create source
      //
      //     this.source = this.sourcesRelatedDataService.getNewlyAddedSourceForTeamAdmin();
      //
      //     this.setDataForUpdateMode();
      //
      //     this.form.get('Slug').disable();
      //   }
      // }
    } else {
      if (this.source) {
        this.isShowScheduling = this.getIsShowSchedulingTab();

        this.setDataForUpdateMode();

        if (this.source.Category) {
          this.editModeCategoryName = this.source.Category.Name;
          this.form.get('CategoryName').setValue(this.source.Category.Name);
        }
      }
    }

    if (this.identityService.isSuperAdmin()) {
      this.subs.push(
        this.companiesService.list().subscribe(companies => {
          const companiesTrimmed = companies.map(company => {
            return { ...company, Name: company.Name.trim() };
          });

          this.companies = companiesTrimmed.filter(company => !company.IsArchived);

          this.companies = this.companies.sort((a, b) => {
            if (a.Name < b.Name) {
              return -1;
            }
            if (a.Name > b.Name) {
              return 1;
            }
            return 0;
          });

          this.companyName = 'Vitcompany';
        }),
      );
    }

    this.sortDSTypesAlphabetically();

    this.onChangeBim360HubId();

    this.onChangeBim360ProjectId();

    if (this.isBim360Type() && this.isUpdateMode()) {
      this.initBim360Data();
    }

    if (this.isPix4dType() && this.isUpdateMode()) {
      this.initPix4dData();
    }

    if (this.isSimpleModelType()) {
      if (this.isUpdateMode()) {
        this.onExistingFilesChange();

        return;
      }

      this.form.get('NewFiles').setValidators(Validators.required);
    }

    this.form
      .get('Security')
      .valueChanges.pipe(
        tap(value => {
          if (value === ArcGisSecurityTypes.VGisAccount) {
            this.form.get('IsArcGisOnline').setValue(true);
            this.form.get('IsArcGisOnline').disable();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.filterTypesValues();
    this.setAutoFillValue();
  }

  ngAfterViewInit(): void {
    this.form.valueChanges
      .pipe(
        tap(() => {
          this.form.markAsDirty();
          this.formChange.emit(true);

          if (this.configurationHeaderTemplateService.isTemplateEmpty) {
            this.configurationHeaderTemplateService.setTemplate(this.generalHeaderTemplate);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.initCancelChangesHandler();
  }

  openProcessingLog(): void {
    this.matDialog.open<SourceStatisticComponent, ProcessingLogsData, null>(SourceStatisticComponent, {
      data: {
        companySlug: this.source.CompanySlug,
        sourceSlug: this.source.Slug,
        logs: this.localProcessingLogs,
      },
      autoFocus: false,
      panelClass: 'processing-logs-modal',
      height: '540px',
    });
  }

  getProcessedPercentColorClassName(percent: number): string {
    if (!percent) {
      return '';
    }

    if (percent < 100) {
      return 'normal';
    }

    if (percent < 25) {
      return 'red';
    }
  }

  onExistingFilesChange() {
    this.form
      .get('Files')
      .valueChanges.pipe(
        tap(files => {
          if (files.length === 0) {
            this.form.get('NewFiles').setValidators([Validators.required]);
            this.form.get('NewFiles').setErrors(Validators.required);
          }
        }),
      )
      .subscribe();
  }

  create(data: UntypedFormGroup) {}

  filterTypesValues(): void {
    this.typesFiltered = this.form.get('Type').valueChanges.pipe(
      startWith(''),
      map((value: string) => this.typesFilter(value)),
    );
  }

  private typesFilter(value: string): { type: DataSourceType; title: string }[] {
    if (typeof value !== 'string') {
      return;
    }

    const filterValue = value.toLowerCase();
    return this.types.filter(type => type.title.toLowerCase().includes(filterValue));
  }

  displayTypeProperty(type: string): string {
    if (!type || this.types.length === 0) {
      return '';
    }

    return this.types.find(foundType => foundType.type === type).title;
  }

  setTypeValue(event: MatAutocompleteSelectedEvent): void {
    if (!event.option.value) {
      return;
    }
    this.form.get('Type').setErrors(null);
  }

  initWFSData() {
    // Security type on WFS data sources will defined by auth credentials object

    if (this.isUpdateMode() && this.isWFSType()) {
      if (this.source.AuthCredentials && this.source.AuthCredentials.Username) {
        this.form.controls.Security.setValue(1);
        this.form.controls.Username.setValue(this.source.AuthCredentials.Username);
        this.form.controls.Password.setValue(this.source.AuthCredentials.Password);
      } else {
        this.form.controls.Security.setValue(0);
      }
    }

    this.wfsSecurityTypes = this.dataSourcesAdapterService.getBasicSecurityTypes();
  }

  initBim360Data() {
    if (this.source.HubId !== null) {
      const selectedHub = this.source.IntegrationStatus.AvailableHubs.find(hub => {
        return hub.Id === this.source.HubId;
      });

      this.availableProjects = selectedHub?.AvailableProjects;
    }

    this.form.get('HubId').setValue(this.source.HubId, { emitEvent: false });
    this.form.get('ProjectId').setValue(this.source.ProjectId, { emitEvent: false });
    this.form.get('SelectedNodes').setValidators(Validators.required);
  }

  initPix4dData() {
    if (this.source.SelectedProjects) {
      this.selectedPix4dProjectsAmount = this.source.SelectedProjects.length;
    }
    this.form.get('SelectedProjects').setValidators(Validators.required);
  }

  initArcGisSecurityTypes() {
    this.arcGisSecurityTypes = this.dataSourcesAdapterService.getArcgisSecurityTypes();
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      Slug: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(slugPattern)]],
      Type: ['', [Validators.required]],
      Description: [''],
      ProjectName: [''],
      ModelName: [''],
      File: this.getFileFormGroup(),
      FeatureServiceUrl: [''],
      SceneServiceUrl: [''],
      IsArcGisOnline: [false],
      IsRealTime: [false],
      Security: [''],
      AuthenticationUrl: [''],
      Username: [''],
      Password: [''],
      Token: [''],
      PortalUrl: [this.source ? this.source.PortalUrl : ''],
      PortalItemId: [this.source ? this.source.PortalItemId : ''],
      IsMeshInteractionEnabled: [this.source ? this.source.IsMeshInteractionEnabled : ''],
      BaseUrl: [''],
      CategoryName: [''],
      NewFiles: [[]],
      Files: [[]],
      MapParsingRules: [''],
      ClientId: [''],
      ClientSecret: [''],
      SelectedProjects: [],
      HubId: [''],
      ProjectId: [''],
      SelectedNodes: [''],
      CompanySlug: [''],
      OwnerTeamId: [''],
      OwnerTeamName: [''],
      Scope: [''],
    });

    /**
     * Enable lock typing for invalid chars
     */
    lockInvalidControl(this.form.controls.Slug);
  }

  getAdaptedWFSData(value) {
    let updatedData = { ...value };

    if (this.isWFSPassword()) {
      updatedData = {
        ...updatedData,
        AuthCredentials: {
          Username: value.Username,
          Password: value.Password,
        },
      };
    }

    updatedData = { ...updatedData, IsRealTime: true };

    return updatedData;
  }

  onChangePermissionsData(permissionsData: PermissionFields) {
    this.permissionsData = permissionsData;
    this.form.updateValueAndValidity();
  }

  categoryCreateDataChanged(data: CreateCategoryData) {
    this.isCreateCategory = data.isCreateCategory;
    this.createCategoryData = data;
  }

  // create(): void {
  //   const value = this.form.getRawValue();
  //
  //   let data = this.isWFSType() ? this.getAdaptedWFSData(value) : value;
  //
  //   data = { ...data, ...this.permissionsData };
  //
  //   if (this.isCreateCategory) {
  //     this.categoriesService
  //       .createCategory(this.identityService.companySlug, this.createCategoryData.categoryThatChosen.Name)
  //       .pipe(
  //         switchMap(newCategoryData => {
  //           data = { ...data, Category: newCategoryData };
  //
  //           return this.dataSourcesService.createSource(data.CompanySlug, data);
  //         }),
  //         tap(dataSource => this.handleActionsAfterCreateSource(dataSource)),
  //         catchError(error => {
  //           this.snackBar.open(error.error.Message, 'OK', snackBarConfig);
  //
  //           return EMPTY;
  //         }),
  //         takeUntil(this.destroy$),
  //       )
  //       .subscribe();
  //
  //     return;
  //   }
  //
  //   this.dataSourcesService
  //     .createSource(data.CompanySlug, data)
  //     .pipe(
  //       tap(dataSource => this.handleActionsAfterCreateSource(dataSource)),
  //       catchError(error => {
  //         this.snackBar.open(error.error.Message, 'OK', snackBarConfig);
  //
  //         return EMPTY;
  //       }),
  //       takeUntil(this.destroy$),
  //     )
  //     .subscribe();
  // }

  // handleActionsAfterCreateSource(newDataSource: ISource) {
  //   if (this.isTeamAdmin()) {
  //     this.sourcesRelatedDataService.setNewlyAddedSourceForTeamAdmin(newDataSource);
  //   }
  //
  //   this.snackBar.open(this.translationMap.get('sourceWasCreated'), 'OK', snackBarConfig);
  //
  //   if (this.isBim360Type() || this.isPix4dType()) {
  //     this.router.navigate(['/sources', newDataSource.CompanySlug, newDataSource.Slug, 'edit']);
  //
  //     return;
  //   }
  //
  //   this.router.navigate(['/sources']);
  // }

  onChangeScheduleConfiguration(scheduleSettings): void {
    this.scheduleSettings = scheduleSettings;
    this.form.updateValueAndValidity();
  }

  onCategoryNameChanged(categoryName: string) {
    this.form.get('CategoryName').setValue(categoryName);
  }

  onUpdateDataSource() {
    this.dataSourcesService
      .getByCompany(this.source.CompanySlug, this.source.Slug)
      .pipe(
        tap((source: ISource) => (this.source = source)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  update(): void {
    const value = this.form.getRawValue();

    let data = this.isWFSType() ? this.getAdaptedWFSData(value) : value;

    if (this.scheduleSettings) {
      data = { ...data, Schedule: this.scheduleSettings };
    } else {
      data = { ...data, Schedule: this.source.Schedule };
    }

    data = { ...data, ...this.permissionsData };

    if (this.isCreateCategory) {
      this.categoriesService
        .createCategory(this.identityService.companySlug, this.createCategoryData.categoryThatChosen.Name)
        .pipe(
          switchMap(newCategoryData => {
            data = { ...data, Category: newCategoryData };

            return this.dataSourcesService.updateByCompany(this.source.CompanySlug, this.source.Slug, data);
          }),
          tap(() => {
            this.snackBar.open(this.translationMap.get('sourceWasUpdated'), 'OK', snackBarConfig);
            this.router.navigate(['/sources']);
          }),
          catchError(error => {
            this.snackBar.open(error.statusText, 'OK', snackBarConfig);
            return EMPTY;
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();

      return;
    }

    this.dataSourcesService
      .updateByCompany(this.source.CompanySlug, this.source.Slug, data)
      .pipe(
        tap(() => {
          this.snackBar.open(this.translationMap.get('sourceWasUpdated'), 'OK', snackBarConfig);
          this.configurationHeaderTemplateService.setTemplate(null);
          this.formChange.emit(false);
        }),
        catchError(error => {
          this.snackBar.open(error.statusText, 'OK', snackBarConfig);
          return EMPTY;
        }),
        switchMap(() => this.dataSourcesService.getByCompany(this.source.CompanySlug, this.source.Slug)),
        tap(source => (this.source = source)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  onFileChange(file: IMediaFile): void {
    this.form.get('File').patchValue(file.sourceFile);
  }

  onRemoveRequiredFile(formControlName: string) {
    this.form.get(formControlName).reset();
    this.form.get(formControlName).setValidators([Validators.required]);
  }

  onSimpleModelExistingFilesChange(files: SimpleModelEditFile[]) {
    this.form.get('Files').setValue(files);
  }

  onSimpleModelNewFilesAdded(files: SourceBaseFile[]) {
    this.form.get('NewFiles').setValue(files);
  }

  sortDSTypesAlphabetically() {
    this.types = this.types.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
  }

  openProcessingHistory(): void {
    this.isProcessingHistoryLoading = true;
    const endDateRange = new Date().toISOString().slice(0, 10);
    this.processService
      .getDataSourceProcessingHistory(this.source.CompanySlug, this.source.Slug, endDateRange)
      .pipe(
        tap((processingHistoryProcesses: Process[]) => {
          this.matDialog.open<ProcessingHistoryComponent, Process[], null>(ProcessingHistoryComponent, {
            width: '1024px',
            height: '540px',
            panelClass: 'processing-history-modal',
            data: processingHistoryProcesses,
            autoFocus: false,
          });
        }),
        finalize(() => {
          this.isProcessingHistoryLoading = false;
          this.cdr.markForCheck();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  openBim360Tree() {
    const companySlug = this.source.CompanySlug;
    const dataSourceSlug = this.source.Slug.trim();
    const hubId = this.form.get('HubId').value;
    const projectId = this.form.get('ProjectId').value;

    this.isBim360TreeLoading = true;

    this.dataSourcesService
      .getModelsTreeForBim360(companySlug, dataSourceSlug, hubId, projectId)
      .pipe(
        tap(bim360ModelsTree => {
          const currentSelectedProject = this.availableProjects.find(project => project.Id === projectId);

          if (currentSelectedProject === undefined) {
            return;
          }

          let alreadySelectedNodes = [];

          if (this.form.get('ProjectId').value === this.source.ProjectId) {
            if (this.isSelectedBim360NodesChanged) {
              alreadySelectedNodes = this.form.get('SelectedNodes').value;
            } else {
              alreadySelectedNodes = [...this.source.SelectedNodes];
            }
          } else {
            if (this.isSelectedBim360NodesChanged) {
              alreadySelectedNodes = this.form.get('SelectedNodes').value;
            } else {
              alreadySelectedNodes = [];
            }
          }

          this.bim360TreeDialogRef = this.matDialog.open<Bim360ModelsTreeComponent, Bim360ModelsTreeDialogData, Bim360ModelsNode[]>(
            Bim360ModelsTreeComponent,
            {
              width: '544px',
              height: '460px',
              disableClose: true,
              data: {
                companySlug,
                dataSourceSlug,
                modelsTree: bim360ModelsTree,
                alreadySelectedNodes: alreadySelectedNodes,
                project: { Id: currentSelectedProject.Id, Name: currentSelectedProject.Name },
                hubId,
              },
            },
          );
        }),
        finalize(() => (this.isBim360TreeLoading = false)),
        switchMap(() => this.bim360TreeDialogRef.afterClosed()),
        tap(selectedModels => {
          if (selectedModels === undefined) {
            return;
          }

          this.form.get('SelectedNodes').setValue(selectedModels);
          this.isSelectedBim360NodesChanged = true;
          this.cdr.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  onChangeBim360HubId() {
    this.form
      .get('HubId')
      .valueChanges.pipe(
        tap(newHubId => {
          if (!this.source.IntegrationStatus.AvailableHubs) {
            return;
          }

          const selectedHub = this.source.IntegrationStatus.AvailableHubs.find(hub => {
            return hub.Id === newHubId;
          });

          this.availableProjects = selectedHub?.AvailableProjects;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  onChangeBim360ProjectId() {
    this.form
      .get('ProjectId')
      .valueChanges.pipe(
        filter((projectId: string | null) => projectId !== null),
        switchMap((projectId: string) => {
          const companySlug = this.source.CompanySlug;
          const dataSourceSlug = this.source.Slug.trim();
          const hubId = this.form.get('HubId').value;

          return this.dataSourcesService.getModelsTreeForBim360(companySlug, dataSourceSlug, hubId, projectId);
        }),
        tap(() => {
          this.form.get('SelectedNodes').reset();
          this.isSelectedBim360NodesChanged = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  openPix4dTree() {
    const companySlug = this.source.CompanySlug;
    const dataSourceSlug = this.source.Slug.trim();

    this.isPix4dProjectsLoading = true;

    this.dataSourcesService
      .getPix4dProjects(companySlug, dataSourceSlug)
      .pipe(
        tap(pix4dModelsTree => {
          const selectedProjects = this.form.get('SelectedProjects').value;

          const alreadySelectedNodes = selectedProjects ? selectedProjects : [];

          this.pix4dTreeDialogRef = this.matDialog.open<Pix4dSelectProjectsComponent, Pix4dProjectsDialogData, Pix4dProject[]>(
            Pix4dSelectProjectsComponent,
            {
              width: '544px',
              height: '460px',
              disableClose: true,
              data: {
                projects: pix4dModelsTree,
                alreadySelectedProjects: alreadySelectedNodes,
              },
            },
          );
        }),
        finalize(() => (this.isPix4dProjectsLoading = false)),
        switchMap(() => this.pix4dTreeDialogRef.afterClosed()),
        tap(selectedProjects => {
          if (selectedProjects === undefined) {
            return;
          }

          this.form.get('SelectedProjects').setValue(selectedProjects);
          this.isSelectedPix4dNodesChanged = true;
          this.selectedPix4dProjectsAmount = selectedProjects.length;
          this.cdr.detectChanges();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  isKmlType() {
    return this.dataSourcesAdapterService.isKmlType(this.form.get('Type').value);
  }

  isArcGisType() {
    return this.dataSourcesAdapterService.isArcGisFeatureType(this.form.get('Type').value);
  }

  isIModelType() {
    return this.dataSourcesAdapterService.isIModelType(this.form.get('Type').value);
  }

  isIModelGisType() {
    return this.dataSourcesAdapterService.isIModelGisDataType(this.form.get('Type').value);
  }

  isWFSType() {
    return this.dataSourcesAdapterService.isWFSType(this.form.get('Type').value);
  }

  isArcGisSceneType() {
    return this.dataSourcesAdapterService.isArcGisSceneType(this.form.get('Type').value);
  }

  isBim360Type() {
    return this.dataSourcesAdapterService.isBim360Type(this.form.get('Type').value);
  }

  isPix4dType() {
    return this.dataSourcesAdapterService.isPix4dType(this.form.get('Type').value);
  }

  isNativeBimType() {
    return this.dataSourcesAdapterService.isNativeBimType(this.form.get('Type').value);
  }

  isLandXmlType() {
    return this.dataSourcesAdapterService.isLandXmlType(this.form.get('Type').value);
  }

  isNativeGisType() {
    return this.dataSourcesAdapterService.isNativeGisType(this.form.get('Type').value);
  }

  isShapefileType() {
    return this.dataSourcesAdapterService.isShapefileType(this.form.get('Type').value);
  }

  isSimpleModelType() {
    return this.dataSourcesAdapterService.isSimpleModelType(this.form.get('Type').value);
  }

  clearSelection() {
    if (this.document.window && this.document.window.getSelection && this.document.window.getSelection().empty) {
      this.document.window.getSelection().empty();
    }
  }

  isArcGisPublic() {
    return (
      this.form.get('Type').value === DataSourceType.ArcGisFeatureService &&
      this.form.get('Security').value === enums.ArcGisSecurityTypes.Public
    );
  }

  isArcGisPassword() {
    return (
      (this.form.get('Type').value === DataSourceType.ArcGisFeatureService ||
        this.form.get('Type').value === DataSourceType.ArcGisSceneService) &&
      this.form.get('Security').value === enums.ArcGisSecurityTypes.Password
    );
  }

  isArcGisToken() {
    return (
      (this.form.get('Type').value === DataSourceType.ArcGisFeatureService ||
        this.form.get('Type').value === DataSourceType.ArcGisSceneService) &&
      this.form.get('Security').value === enums.ArcGisSecurityTypes.Token
    );
  }

  isArcGisNtlm() {
    return (
      (this.form.get('Type').value === DataSourceType.ArcGisFeatureService ||
        this.form.get('Type').value === DataSourceType.ArcGisSceneService) &&
      this.form.get('Security').value === enums.ArcGisSecurityTypes.Ntlm
    );
  }

  isShowPortalFields() {
    return this.isArcGisPassword() || this.isArcGisToken();
  }

  isShowUsernamePasswordForArcGis() {
    return (this.isArcGisPassword() && this.form.get('AuthenticationUrl').value) || this.isArcGisNtlm();
  }

  isWFSPassword() {
    return this.form.get('Type').value === DataSourceType.Wfs && this.form.get('Security').value === enums.ArcGisSecurityTypes.Password;
  }

  isTeamAdmin(): boolean {
    return this.identityService.isTeamAdmin();
  }

  onUploadingFile(status: UploadOutputType): void {
    this.uploadStatus = status;
  }

  onCancelChanges(): void {
    this.sourcesFormChangesService.isNeedToCancelChanges();
  }

  private cancelChanges(): void {
    this.form.patchValue(this.source, { emitEvent: false });
    this.isSelectedBim360NodesChanged = false;
    this.isSelectedPix4dNodesChanged = false;
    this.configurationHeaderTemplateService.setTemplate(null);
    this.formChange.emit(false);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub && sub.unsubscribe());
    this.destroy$.next();
    this.destroy$.complete();
    this.autoFillDestroy$.next();
    this.autoFillDestroy$.complete();
    this.translationHandleService.cleanTranslationArray();
  }

  private setDataForUpdateMode(): void {
    if (!this.source) {
      return;
    }

    this.form.patchValue(this.source);
    this.mode = TableFormMode.UPDATE;
    this.types = DATA_SOURCE_TYPES_UPDATE_FORM;
  }

  private getIsShowSchedulingTab(): boolean {
    return (
      (this.source.Type === DataSourceType.ArcGisFeatureService && !this.source.IsRealTime) ||
      this.source.Type === DataSourceType.IModel ||
      this.source.Type === DataSourceType.Bim360 ||
      this.source.Type === DataSourceType.Pix4d ||
      this.source.Type === DataSourceType.ArcGisSceneService ||
      this.source.Type === DataSourceType.NativeBim
    );
  }

  private getFileFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      FileUrl: [],
      FileName: [],
      Format: [],
      Title: [],
    });
  }

  private initCancelChangesHandler(): void {
    this.sourcesFormChangesService.isNeedToCancelChanges$
      .pipe(
        filter(isNeedToCancel => isNeedToCancel),
        tap(() => this.cancelChanges()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private setAutoFillValue(): void {
    if (this.isUpdateMode()) {
      return;
    }

    const descriptionControl = this.form.get('Description');
    const identifierControl = this.form.get('Slug');

    this.autofillFieldService.setAutoFillValue$(descriptionControl, identifierControl).pipe(takeUntil(this.autoFillDestroy$)).subscribe();
  }
}
