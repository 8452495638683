// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.source-form-scheduling-wrap .mat-card-title {
  font-size: 14px;
}
.source-form-scheduling-wrap .schedule-data .top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.source-form-scheduling-wrap .schedule-data .top-bar::ng-deep .toggle {
  margin-bottom: 24px;
}
.source-form-scheduling-wrap .schedule-data .time-filters {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 32px;
}
.source-form-scheduling-wrap .schedule-data .time-filters .company-time-label {
  position: absolute;
  top: 37px;
  left: 0;
  white-space: nowrap;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
}`, "",{"version":3,"sources":["webpack://./src/app/modules/sources/components/source-form/source-form-scheduling/source-form-scheduling.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AAII;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAFN;AAKQ;EACE,mBAAA;AAHV;AAQI;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;AANN;AAQM;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AANR","sourcesContent":[".source-form-scheduling-wrap {\n  .mat-card-title {\n    font-size: 14px;\n  }\n\n  .schedule-data {\n    .top-bar {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n\n      &::ng-deep {\n        .toggle {\n          margin-bottom: 24px;\n        }\n      }\n    }\n\n    .time-filters {\n      display: flex;\n      flex-direction: column;\n      position: relative;\n      margin-bottom: 32px;\n\n      .company-time-label {\n        position: absolute;\n        top: 37px;\n        left: 0;\n        white-space: nowrap;\n        font-size: 12px;\n        line-height: 20px;\n        color: rgba(0, 0, 0, 0.6);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
