import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { RoutingModule } from './app-routing.module';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
// import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoadingService } from './core/loading-overlay/loading.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { RequestErrorsInterceptor } from './interceptors/request-errors.interceptor';
import { TranslationHandleService } from './core/translate/translation-handle.service';
import { CompaniesService } from './modules/companies/companies.service';
import { GlobalAddModule } from './modules/global-add/global-add.module';
import { NgxZendeskWebwidgetModule } from '@nitsanzo/ngx-zendesk-webwidget';
import { ZendeskConfig } from './shared/zendesk-config';
import { TelemetryService } from './modules/dashboard/telemetry.service';

// const perfectScrollbarConfig: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true,
// };

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    RoutingModule,
    // PerfectScrollbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    HttpClientModule,
    GlobalAddModule,
  ],
  providers: [
    LoadingService,
    CompaniesService,
    TelemetryService,
    TranslationHandleService,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestErrorsInterceptor,
      multi: true,
    },
    // {
    //   provide: PERFECT_SCROLLBAR_CONFIG,
    //   useValue: perfectScrollbarConfig,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
