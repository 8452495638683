import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacySlideToggle as MatSlideToggle, MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { Permission } from '../../interfaces/permission';
import { PermissionType } from '../../enums/permission-type.enum';
import { CrudOperations } from '../../enums/crud-operations.enum';
import { TablePermission } from '../../interfaces/table-permission';
import { PermissionsAdapterService } from '../../services/permissions-adapter.service';

@Component({
  selector: 'mee-company-permissions',
  templateUrl: './company-permissions.component.html',
  styleUrls: ['./company-permissions.component.scss'],
})
export class CompanyPermissionsComponent {

  isPublicAccessToggleChecked: boolean;

  crudPermissions: Permission[] = [];

  private _permissions: Permission[];

  @ViewChild('publicAccessToggle') publicAccessToggle: MatSlideToggle;
  @Output() permissionChanged = new EventEmitter<Permission[]>();

  @Input() set permissions(permissions: Permission[]) {
    this._permissions = permissions;
    this._permissions.forEach(permission => {
      if (permission.Type !== PermissionType.PublicDataAccess) {
        this.crudPermissions.push(permission);
      } else {
        this.isPublicAccessToggleChecked = permission.IsAllowed;
      }
    });
  }

  get permissions() {
    return this._permissions;
  }

  constructor(private permissionsAdapterService: PermissionsAdapterService) {
  }

  togglePublicAccessPermission({ checked }: MatSlideToggleChange) {
    this.isPublicAccessToggleChecked = checked;

    this.onPermissionsChanged();
  }

  onCrudPermissionsChanged(permissions: Record<CrudOperations, TablePermission>) {

    this.crudPermissions = this.permissionsAdapterService.getServerPermissions(permissions);

    this.onPermissionsChanged();
  }

  onPermissionsChanged() {

    const permissions = [];

    permissions.push(...this.crudPermissions);

    permissions.push(
      {
        Type: PermissionType.PublicDataAccess,
        Name: PermissionType[PermissionType.PublicDataAccess],
        IsAllowed: this.isPublicAccessToggleChecked,
      },
    );

    this.permissionChanged.emit(permissions);
  }
}
