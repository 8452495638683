import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { ProcessService } from './process.service';
import { Process } from '../../models/process.interface';

export const ProcessListResolver: ResolveFn<Process[]> = (): Observable<Process[]> => {
  const processService = inject(ProcessService);

  return processService.getProcessingHistoryList(new Date().toISOString().slice(0, 10));
};
