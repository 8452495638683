// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .processing-history-modal .mat-dialog-container {
  padding: 0;
}

.processing-history-template {
  height: 100%;
  overflow: hidden;
  position: relative;
}
.processing-history-template .close {
  position: absolute;
  top: 8px;
  right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/sources/components/source-form/processing-history/processing-history.component.scss"],"names":[],"mappings":"AAEI;EACE,UAAA;AADN;;AAMA;EACE,YAAA;EACA,gBAAA;EACA,kBAAA;AAHF;AAKE;EACE,kBAAA;EACA,QAAA;EACA,UAAA;AAHJ","sourcesContent":["::ng-deep {\n  .processing-history-modal {\n    .mat-dialog-container {\n      padding: 0;\n    }\n  }\n}\n\n.processing-history-template {\n  height: 100%;\n  overflow: hidden;\n  position: relative;\n\n  .close {\n    position: absolute;\n    top: 8px;\n    right: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
