export enum Point3DShape {
  Cylinder = 0,
  Rectangle,
  Sphere,
  'Pyramid - triangular',
  'Pyramid - square',
  Cone,
  'Prism - triangular',
  'Prism - square',
  Rhombus,
  'Prism - hexagon',
  'Sphere-half',
  Cuboid,
  Dodecahedron = 14,
  Icosahedron,
  Diamond,
  'Manhole - Symmetric (Type 1)',
  'Manhole - Symmetric (Type 2)',
  'Manhole - Asymmetric (Type 1)',
  'Manhole - Asymmetric (Type 2)',
  'Inlet - Round (Type 1)',
  'Inlet - Round (Type 2)',
  'Inlet - Round (Type 3)',
  'Inlet - Square (Type 1)',
  'Inlet - Square (Type 2)',
  'Inlet - Square (Type 3)',
  'Inlet - Square (Type 4)',
  'Manhole - Cylinder (Type 1)',
  'Manhole - Cylinder (Type 2)',
  'Manhole - Box (Type 1)'
}
