import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { register } from 'swiper/element/bundle';

register();

@Directive({
  selector: '[meeSwiper]'
})
export class SwiperDirective implements AfterViewInit {
  @Input() config?: SwiperOptions;

  constructor(private element: ElementRef<HTMLElement>) {
  }

  ngAfterViewInit(): void {
    Object.assign(this.element.nativeElement, this.config);

    // @ts-ignore - We ignore this because there is no initialize method on the HTMLElement
    this.element.nativeElement.initialize();
  }
}
