import {Injectable} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {InfocardImagesComponent} from './infocard-images/infocard-images.component';
import {InfocardImage} from './infocard-image';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class InfocardImagesService {

  imagesDialog: MatDialogRef<InfocardImagesComponent>;

  constructor(private http: HttpClient, private dialog: MatDialog) {
  }

  all() {
    return this.http.get<InfocardImage[]>(environment.adminApi + '/PlacemarkImages');
  }

  select() {
    this.imagesDialog = this.dialog.open(InfocardImagesComponent);
    this.all().subscribe(meshes => {
      if (this.imagesDialog && this.imagesDialog.componentInstance) {
        meshes.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
        this.imagesDialog.componentInstance.images = meshes;
        this.imagesDialog.componentInstance.localImages = meshes;
      }
    });
    return this.imagesDialog.afterClosed() as Observable<InfocardImage | string>;
  }

}
