// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-container {
  border: 1px dashed #123;
  min-height: 150px;
  width: 100%;
  opacity: 0.5;
}
.drop-container .upload-title-drag {
  color: #2B303B;
  display: none;
}
.drop-container.is-drop-over {
  opacity: 0.9;
}
.drop-container.is-drop-over .upload-title {
  display: none;
}
.drop-container.is-drop-over .upload-title-drag {
  display: block;
}
.drop-container.preview {
  opacity: 1;
  padding: 0;
  position: relative;
}
.drop-container .update-image {
  position: absolute;
  cursor: pointer;
  opacity: 1;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  background-color: rgba(13, 22, 33, 0.6);
  height: 48px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.image-preview {
  max-width: 100%;
  max-height: 100%;
}

:host {
  position: relative;
}

.remove-button {
  line-height: inherit;
  border-radius: 3px;
  position: absolute;
  background: white;
  height: 24px;
  width: 24px;
  margin: 5px;
  z-index: 2;
  right: 0;
}

.file-info {
  transition: opacity 1s;
}
.file-info.complete {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/core/uploader/image-upload-button/image-upload-button.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,cAAA;EACA,aAAA;AACJ;AAEE;EACE,YAAA;AAAJ;AAEI;EACE,aAAA;AAAN;AAGI;EACE,cAAA;AADN;AAKE;EACE,UAAA;EACA,UAAA;EACA,kBAAA;AAHJ;AAME;EACE,kBAAA;EACA,eAAA;EACA,UAAA;EACA,WAAA;EACA,QAAA;EACA,SAAA;EACA,4CAAA;EACA,UAAA;EACA,uCAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gCAAA;AAJJ;;AASA;EACE,eAAA;EACA,gBAAA;AANF;;AASA;EACE,kBAAA;AANF;;AASA;EACE,oBAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,UAAA;EACA,QAAA;AANF;;AASA;EACE,sBAAA;AANF;AAQE;EACE,aAAA;AANJ","sourcesContent":[".drop-container {\n  border: 1px dashed #123;\n  min-height: 150px;\n  width: 100%;\n  opacity: .5;\n\n  .upload-title-drag {\n    color: #2B303B;\n    display: none;\n  }\n\n  &.is-drop-over {\n    opacity: .9;\n\n    .upload-title {\n      display: none;\n    }\n\n    .upload-title-drag {\n      display: block;\n    }\n  }\n\n  &.preview {\n    opacity: 1;\n    padding: 0;\n    position: relative;\n  }\n\n  .update-image {\n    position: absolute;\n    cursor: pointer;\n    opacity: 1;\n    color: #fff;\n    top: 50%;\n    left: 50%;\n    transform: translateX(-50%) translateY(-50%);\n    z-index: 1;\n    background-color: rgba(13, 22, 33, 0.6);\n    height: 48px;\n    width: 150px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    transition: all .3s ease-in-out;\n  }\n}\n\n\n.image-preview {\n  max-width: 100%;\n  max-height: 100%;\n}\n\n:host {\n  position: relative;\n}\n\n.remove-button {\n  line-height: inherit;\n  border-radius: 3px;\n  position: absolute;\n  background: white;\n  height: 24px;\n  width: 24px;\n  margin: 5px;\n  z-index: 2;\n  right: 0;\n}\n\n.file-info {\n  transition: opacity 1s;\n\n  &.complete {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
