import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TeamProject } from '../interfaces/team-project';
import { IdentityRole } from '../interfaces/identity-role';

const TEAM_PROJECT_PREFIX = 'teamProject';

@Injectable({
  providedIn: 'root'
})
export class TeamProjectService {
  private localTeamProject$ = new BehaviorSubject<TeamProject>(null);
  private localNeedToUpdateSelector$ = new BehaviorSubject<boolean>(false);
  private localCompanySlugFromRoute: string;

  set companySlugFromRoute(slug: string) {
    this.localCompanySlugFromRoute = slug;
  }

  get companySlugFromRoute(): string {
    return this.localCompanySlugFromRoute === this.teamProject.CompanySlug ? null : this.localCompanySlugFromRoute;
  }

  get teamProject(): TeamProject {
    return this.localTeamProject$.value;
  }

  get teamProjectFromLocalStorage(): TeamProject {
    return JSON.parse(localStorage.getItem(TEAM_PROJECT_PREFIX));
  }

  get teamProject$(): Observable<TeamProject> {
    return this.localTeamProject$.asObservable();
  }

  get isNeedToUpdateTeamProjectSelector$(): Observable<boolean> {
    return this.localNeedToUpdateSelector$.asObservable();
  }

  update(): void {
    this.localNeedToUpdateSelector$.next(true);
  }

  transformRole(role: IdentityRole): TeamProject {
    if (role === null) {
      return { CompanyId: null, CompanyName: null, CompanySlug: null, TeamSlug: null, TeamId: null }
    }

    return {
      CompanyId: role.CompanyId,
      CompanySlug: role.CompanySlug,
      CompanyName: role.CompanyName,
      TeamSlug: role.TeamSlug,
      TeamName: role.TeamName,
      TeamId: role.TeamId
    }
  }

  setTeamProject(teamProject: TeamProject): void {
    if (!teamProject) {
      return;
    }

    this.localTeamProject$.next(teamProject);
    this.saveTeamProjectToLocalStorage(teamProject);
  }

  clearTeamProject(): void {
    this.localTeamProject$.next(null);
    localStorage.removeItem(TEAM_PROJECT_PREFIX);
  }

  private saveTeamProjectToLocalStorage(teamProject: TeamProject): void {
    localStorage.setItem(TEAM_PROJECT_PREFIX, JSON.stringify(teamProject));
  }
}
