import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class CustomValidatorsService {

  onCheckUniqueString(strings: string[]): ValidatorFn {
    return (control: AbstractControl) => {
      const isNewCustomMapNameUnique = !strings.includes(control.value.toLowerCase().trim());
      return isNewCustomMapNameUnique ? null : {notUnique: true};
    };
  }
}
