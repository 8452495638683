import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ISource} from '../../models/source.interface';
import {IdentityService} from '../../../../services/identity.service';
import {ArcGisSecurityTypes} from '../../arcgis-security-types.enum';
import {TableActionConfirmComponent} from '../../../../core/table/table-action-confirm/table-action-confirm.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {snackBarConfig} from '../../../../core/core.module';
import {TranslationHandleService} from '../../../../core/translate/translation-handle.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import {DataSourcesService} from '../../services/data-sources.service';

const DATA_SOURCE_TYPE_KML = 'Kml';
const DATA_SOURCE_TYPE_ARC_GIS_FEATURE_SERVICE = 'ArcGisFeatureService';

const PHRASES_FOR_TRANSLATE = [
  {
    serverError: 'system.serverError'
  }
];

@Component({
  selector: 'mee-source-view',
  templateUrl: './source-view.component.html',
  styleUrls: ['./source-view.component.scss']
})
export class SourceViewComponent implements OnInit, OnDestroy {

  source: ISource;
  arcGisSecurityTypes = ArcGisSecurityTypes;

  translationMap: Map<string, string>;
  subs: Subscription[] = [];

  private dialogConfirm: MatDialogRef<TableActionConfirmComponent>;

  constructor(private route: ActivatedRoute,
              public identityService: IdentityService,
              private router: Router,
              private snackBar: MatSnackBar,
              private dataSourcesService: DataSourcesService,
              private dialog: MatDialog,
              private translationHandleService: TranslationHandleService,
              private translateService: TranslateService) {

    this.translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);

    this.subs.push(
      this.translationHandleService.getTranslationMap().subscribe(translationMap => {
        this.translationMap = translationMap;
      })
    );

    this.source = route.snapshot.data.source;
  }

  ngOnInit() {
  }

  openMappingForm() {
    this.router.navigate([`config`], {relativeTo: this.route});
  }

  openEditForm() {
    this.router.navigate([`edit`], {relativeTo: this.route});
  }

  actionRemove(event) {
    event.stopPropagation();

    this.dialogConfirm = this.dialog.open(TableActionConfirmComponent, {
      width: '350px'
    });

    this.subs.push(
      this.dialogConfirm.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.remove();
        }
      })
    );
  }

  remove() {

    this.subs.push(
      forkJoin([
        this.dataSourcesService.remove(this.source.CompanySlug, this.source.Slug),
        this.translateService.get('sources.snackBarMessages.archive', {slug: this.source.Slug})
      ]).subscribe((response: [ISource, string]) => {
        const source = response[0];
        if (source) {
          this.source.IsArchived = source.IsArchived;
          this.source.Slug = source.Slug;
          this.snackBar.open(response[1], 'OK', snackBarConfig);
        }
      }, error => {
        this.snackBar.open(error.statusText, 'OK', snackBarConfig);
      })
    );
  }

  actionRestore() {
    this.subs.push(
      forkJoin([
        this.dataSourcesService.restore(this.source.CompanySlug, this.source.Slug),
        this.translateService.get('sources.snackBarMessages.restore', {slug: this.source.Slug})
      ]).subscribe((response: [ISource, string]) => {
        const source = response[0];

        if (source) {
          this.source.Slug = source.Slug;
          this.source.IsArchived = source.IsArchived;
          this.snackBar.open(response[1], 'OK', snackBarConfig);
        }
      }, () => {
        this.snackBar.open(this.translationMap.get('serverError'), 'OK', snackBarConfig);
      })
    );
  }

  goBack() {
    this.router.navigate(['/sources']);
  }


  isKmlType() {
    return this.source.Type === DATA_SOURCE_TYPE_KML;
  }

  isArcGisType() {
    return this.source.Type === DATA_SOURCE_TYPE_ARC_GIS_FEATURE_SERVICE;
  }

  isArcGisPassword() {
    return this.isArcGisType() && this.source.Security === this.arcGisSecurityTypes.Password;
  }

  isArcGisNtlm() {
    return this.isArcGisType() && this.source.Security === this.arcGisSecurityTypes.Ntlm;
  }

  isArcGisToken() {
    return this.isArcGisType() && this.source.Security === this.arcGisSecurityTypes.Token;
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub && sub.unsubscribe());
    this.translationHandleService.cleanTranslationArray();
  }
}
