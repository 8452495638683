import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ServerPaginationParams } from '../interfaces/server-pagination-params';
import { OffsetLimitParams } from '../interfaces/offset-limit-params';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  DEFAULT_SORTING_PARAMS = { active: 'CreatedAtUtc', direction: 'desc' };
  readonly defaultPaginationOptions: ServerPaginationParams = {
    offset: 0,
    limit: 50,
    isDeleted: false,
    orderBy: 'CreatedAtUtc',
    desc: true,
  };

  private _itemsPerPage: number;
  private _skipItems = 0;
  private _takeItems = 50;
  private _searchString = '';
  private _orderBy = 'CreatedAtUtc';
  private _desc = true;
  private _isShowDeletedItems: boolean;
  private _categoryId: string;

  public onNeedToRefreshData: BehaviorSubject<ServerPaginationParams> = new BehaviorSubject(null);
  public onSkipTakeParamsChanged: Subject<OffsetLimitParams> = new Subject();

  get paginationOptions() {
    return this.getOptionsForServer();
  }

  setOrderByDefaultParameter(orderBy: string) {
    this._orderBy = orderBy;
  }

  get pageIndex(): number {
    return this._skipItems / this._takeItems;
  }

  private getOptionsForServer() {
    return {
      offset: this._skipItems,
      limit: this._takeItems,
      filter: this._searchString,
      orderBy: this._orderBy,
      desc: this._desc,
      isDeleted: this._isShowDeletedItems,
      categoryId: this._categoryId,
    };
  }

  public setNextPageForPagination(pageEvent, defaultSortData?: Sort) {
    this._itemsPerPage = pageEvent.pageSize;
    const pageNumber = pageEvent.pageIndex;

    this._skipItems = this._itemsPerPage * pageNumber;
    this._takeItems = this._itemsPerPage;

    if (defaultSortData) {
      this._orderBy = defaultSortData.active;
      this._desc = defaultSortData.direction === 'desc';
    }

    this.onSkipTakeParamsChanged.next({ offset: this._skipItems, limit: this._takeItems });

    this.onNeedToRefreshData.next(this.getOptionsForServer());
  }

  public setSortParameters(sortData: Sort) {
    this._orderBy = sortData.active;
    this._desc = sortData.direction === 'desc';

    this.onNeedToRefreshData.next(this.getOptionsForServer());
  }

  public setIsDeletedParameter(isArchivedDataDisplay: boolean) {
    this._isShowDeletedItems = isArchivedDataDisplay;
    this._skipItems = 0;

    this.resetOptionsWhenIsDeleted();

    if (!isArchivedDataDisplay) {
      this._categoryId = undefined;
    }
    this.onNeedToRefreshData.next(this.getOptionsForServer());
  }

  public setFilterValueParameter(searchString: string) {
    this._searchString = searchString;
    this._skipItems = 0;

    this.onNeedToRefreshData.next(this.getOptionsForServer());
  }

  public setCategoryIdForSearch(categoryId: string) {
    if (categoryId) {
      this._categoryId = categoryId;
    } else {
      this._categoryId = null;
    }

    this.onNeedToRefreshData.next(this.getOptionsForServer());
  }

  resetOptionsWhenIsDeleted() {
    this._skipItems = 0;
    this._categoryId = null;
  }

  resetAllOptions() {
    this._skipItems = 0;
    this._searchString = '';
    this._categoryId = undefined;
    this._isShowDeletedItems = false;
    this.onNeedToRefreshData.next(null);
  }

  getRequestParams(optionsForServer: ServerPaginationParams): Map<string, string> {
    let params = new Map();

    if ((optionsForServer.offset && optionsForServer.limit) || optionsForServer.offset === 0) {
      params = params.set('offset', optionsForServer.offset.toString());
      params = params.set('limit', optionsForServer.limit.toString());
    }

    if (optionsForServer.orderBy && optionsForServer.desc !== null) {
      params = params.set('orderBy', optionsForServer.orderBy);
      params = params.set('desc', optionsForServer.desc.toString());
    }

    if (optionsForServer.filter) {
      params = params.set('filter', optionsForServer.filter);
    }

    if (optionsForServer.isDeleted) {
      params = params.set('onlyDeleted', optionsForServer.isDeleted.toString());
    }

    if (optionsForServer.categoryId) {
      params = params.set('categoryId', optionsForServer.categoryId);
    }

    return params;
  }
}
