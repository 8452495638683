// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .title_close {
  display: flex;
  justify-content: space-between;
}
:host .title_close .title {
  font-weight: bold !important;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 12px;
}
:host .title_close .close {
  cursor: pointer;
}
:host .text {
  margin-bottom: 0;
}
:host .link {
  text-decoration: underline;
  color: #0077EE;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/sources/components/source-configuration-list/components/bim360-account-disconnected/bim360-account-disconnected.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,8BAAA;AAAJ;AAEI;EACE,4BAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AAAN;AAGI;EACE,eAAA;AADN;AAKE;EACE,gBAAA;AAHJ;AAME;EACE,0BAAA;EACA,cAAA;EACA,eAAA;AAJJ","sourcesContent":[":host {\n  .title_close {\n    display: flex;\n    justify-content: space-between;\n\n    .title {\n      font-weight: bold !important;\n      font-size: 20px;\n      line-height: 32px;\n      margin-bottom: 12px;\n    }\n\n    .close {\n      cursor: pointer;\n    }\n  }\n\n  .text {\n    margin-bottom: 0;\n  }\n\n  .link {\n    text-decoration: underline;\n    color: #0077EE;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
