import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { ICompany } from '../../modules/companies/shared/interfaces/company.interface';
import { CompaniesService } from '../../modules/companies/companies.service';
import { TeamProjectService } from '../../services/team-project.service';
import { tap } from 'rxjs/operators';
import { TeamProject } from '../../interfaces/team-project';
import { IdentityService } from '../../services/identity.service';

export const TeamProjectsResolver: ResolveFn<unknown> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  companiesService = inject(CompaniesService),
  teamProjectService = inject(TeamProjectService),
  identityService = inject(IdentityService)
) => {
  const teamProjectFromLocalStorage = teamProjectService.teamProjectFromLocalStorage;

  if (identityService.isSuperAdmin() && teamProjectFromLocalStorage.CompanySlug === null) {
    return companiesService.list().pipe(
      tap((companies: ICompany[]) => {
        const sortedCompanies = companiesService.getSortedCompaniesList(companies);

        const transformedCompanies = sortedCompanies.map(company => {
          return { CompanySlug: company.Slug, CompanyName: company.Name, CompanyId: company.Id } as TeamProject;
        })

        teamProjectService.setTeamProject(transformedCompanies[0]);
      }),
    )
  }
}
