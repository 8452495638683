export const CONFIGURATION_HINTS_DATA_MODEL = [
  {
    Id: 4,
    TitleKey: 'ConfigurationHints.Titles.CoordinateSystem',
    LabelKey: 'ConfigurationHints.Labels.CoordinateSystemBim',
    LabelKeyPositive: 'ConfigurationHints.Labels.CoordinateSystemBim',
    LabelKeyNegative: 'ConfigurationHints.Labels.CoordinateSystemBim',
    SuggestionKey: null,
    HelpLink: 'https://support.vgis.io/hc/en-us/articles/5697130263831',
    Passed: false,
    Tooltip: 'Tooltip text',
  },
  {
    Id: 5,
    TitleKey: 'ConfigurationHints.Titles.CoordinateSystem',
    LabelKey: 'ConfigurationHints.Labels.CoordinateSystemGis',
    LabelKeyPositive: 'ConfigurationHints.Labels.CoordinateSystemGis',
    LabelKeyNegative: 'ConfigurationHints.Labels.CoordinateSystemGis',
    SuggestionKey: null,
    HelpLink: 'https://support.vgis.io/hc/en-us/articles/5697402127127',
    Passed: false,
    Tooltip: 'Tooltip text',
  },
  {
    Id: 6,
    TitleKey: 'ConfigurationHints.Titles.CoordinateSystemUoM',
    LabelKey: 'ConfigurationHints.Labels.CoordinateSystemUoM',
    LabelKeyPositive: 'ConfigurationHints.Labels.CoordinateSystemUoM',
    LabelKeyNegative: 'ConfigurationHints.Labels.CoordinateSystemUoM',
    SuggestionKey: null,
    HelpLink: 'https://support.vgis.io/hc/en-us/articles/5698077979031',
    Passed: false,
    Tooltip: 'Tooltip text',
  },
  {
    Id: 7,
    TitleKey: 'ConfigurationHints.Titles.MandatoryFiles',
    LabelKey: 'ConfigurationHints.Labels.MandatoryFilesLabel',
    LabelKeyPositive: 'ConfigurationHints.Labels.MandatoryFilesLabel',
    LabelKeyNegative: 'ConfigurationHints.Labels.MandatoryFilesLabel',
    SuggestionKey: null,
    HelpLink: null,
    Passed: false,
    Tooltip: 'Tooltip text',
  },
  {
    Id: 1,
    TitleKey: 'ConfigurationHints.Titles.KMLDatum',
    LabelKey: 'ConfigurationHints.Labels.KMLDatum',
    LabelKeyPositive: 'ConfigurationHints.Labels.KMLDatum',
    LabelKeyNegative: 'ConfigurationHints.Labels.KMLDatum',
    SuggestionKey: null,
    HelpLink: 'https://support.vgis.io/hc/en-us/articles/5701989336215',
    Passed: false,
    Tooltip: 'Tooltip text',
  },
  {
    Id: 3,
    TitleKey: 'ConfigurationHints.Titles.VerticalDatum',
    LabelKey: 'ConfigurationHints.Labels.VerticalDatum',
    LabelKeyPositive: 'ConfigurationHints.Labels.VerticalDatum',
    LabelKeyNegative: 'ConfigurationHints.Labels.VerticalDatum',
    SuggestionKey: null,
    HelpLink: 'https://support.vgis.io/hc/en-us/articles/5702052177303',
    Passed: false,
    Tooltip: 'Tooltip text',
  },
  {
    Id: 2,
    TitleKey: 'ConfigurationHints.Titles.LayerGroups',
    LabelKey: 'ConfigurationHints.Labels.LayerGroups',
    LabelKeyPositive: 'ConfigurationHints.Labels.LayerGroups',
    LabelKeyNegative: 'ConfigurationHints.Labels.LayerGroups',
    SuggestionKey: null,
    HelpLink: 'https://support.vgis.io/hc/en-us/articles/5702113711895',
    Passed: false,
    Tooltip: 'Tooltip text',
  },
  {
    Id: 8,
    TitleKey: 'ConfigurationHints.Titles.VerticalDatum',
    LabelKey: 'ConfigurationHints.Labels.DatumDetectAutomatically',
    LabelKeyPositive: 'ConfigurationHints.Labels.DatumDetectAutomatically',
    LabelKeyNegative: 'ConfigurationHints.Labels.VerticalDatum',
    SuggestionKey: null,
    HelpLink: 'https://support.vgis.io/hc/en-us/articles/5702113711895',
    Passed: false,
    Tooltip: 'Tooltip text',
  },
  {
    Id: 500,
    TitleKey: 'ConfigurationHints.Titles.DataSourceBelongToTeam',
    LabelKey: 'ConfigurationHints.Labels.DataSourceBelongToTeam',
    LabelKeyPositive: 'ConfigurationHints.Labels.DataSourceBelongToTeam',
    LabelKeyNegative: 'ConfigurationHints.Labels.DataSourceBelongToTeam',
    SuggestionKey: null,
    HelpLink: 'https://support.vgis.io/hc/en-us/articles/5702467363863',
    Passed: false,
    Tooltip: 'Tooltip text',
  },
  {
    Id: 501,
    TitleKey: 'ConfigurationHints.Titles.LayersAvailableForTeam',
    LabelKey: 'ConfigurationHints.Labels.LayersAvailableForTeam',
    LabelKeyPositive: 'ConfigurationHints.Labels.LayersAvailableForTeam',
    LabelKeyNegative: 'ConfigurationHints.Labels.LayersAvailableForTeam',
    SuggestionKey: null,
    HelpLink: 'https://support.vgis.io/hc/en-us/articles/5702405300375',
    Passed: false,
    Tooltip: 'Tooltip text',
  },
  {
    Id: 900,
    TitleKey: 'ConfigurationHints.Titles.ProcessingAfterChange',
    LabelKey: 'ConfigurationHints.Labels.ProcessingAfterChange',
    LabelKeyPositive: 'ConfigurationHints.Labels.ProcessingAfterChange',
    LabelKeyNegative: 'ConfigurationHints.Labels.ProcessingAfterChange',
    SuggestionKey: null,
    HelpLink: 'https://support.vgis.io/hc/en-us/articles/5696888038551',
    Passed: false,
    Tooltip: 'Tooltip text',
  },
];
