// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-card-btn_img {
  background-color: rgba(0, 0, 0, 0.07);
  position: relative;
}
.info-card-btn_img:hover img {
  display: block;
}
.info-card-btn_img img {
  position: absolute;
  z-index: 1;
  display: none;
  width: 129px !important;
  height: 129px;
  right: 22px;
  top: -140px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/sources/components/source-configuration-list/components/configuration-tab/info-card-image/info-card-image.component.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;EACA,kBAAA;AACF;AACE;EACE,cAAA;AACJ;AAEE;EACE,kBAAA;EACA,UAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;EACA,WAAA;EACA,WAAA;AAAJ","sourcesContent":[".info-card-btn_img {\n  background-color: rgba(0, 0, 0, 0.07);\n  position: relative;\n\n  &:hover img {\n    display: block;\n  }\n\n  img {\n    position: absolute;\n    z-index: 1;\n    display: none;\n    width: 129px !important;\n    height: 129px;\n    right: 22px;\n    top: -140px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
