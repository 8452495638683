import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mee-script-editor-tab',
  templateUrl: './script-editor-tab.component.html',
  styleUrls: ['./script-editor-tab.component.scss']
})
export class ScriptEditorTabComponent implements OnInit {

  private _model = 0;
  @Output() modelChange = new EventEmitter();

  @Output() onChange = new EventEmitter();

  @Input()
  get model() {
    return this._model;
  }

  set model(val) {
    this._model = val;
    this.modelChange.emit(this._model);
  }

  constructor() {}

  ngOnInit() {
  }

}
