import { Directive, inject, OnDestroy, OnInit } from '@angular/core';
import { SidenavService } from './sidenav.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';

@Directive({
  selector: '[meeIconSidenav]',
})
export class IconSidenavDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private sidenavService = inject(SidenavService);
  private breakpointObserver = inject(BreakpointObserver);

  ngOnInit() {
    this.breakpointObserver.observe(['(max-width: 960px)']).pipe(
      tap(result => {
        this.sidenavService.setMobileMode(result.matches);
      }),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
