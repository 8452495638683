import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { Floor } from '../../../../../../../components/floors/interfaces/floor.interface';
import { AbstractControl, ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TableElevationFloor } from '../interfaces/table-elevation-floor';
import { FloorConfiguration } from '../interfaces/floor-configuration';
import { tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { IdentityService } from '../../../../../../../../services/identity.service';

@Component({
  selector: 'mee-floors-elevation',
  templateUrl: './floors-elevation.component.html',
  styleUrls: ['./floors-elevation.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FloorsElevationComponent),
      multi: true,
    },
  ],
})
export class FloorsElevationComponent implements OnInit, ControlValueAccessor {
  form: UntypedFormGroup;

  dataSource = new BehaviorSubject<AbstractControl[]>([]);

  displayedColumns = ['IsEnabled', 'Name', 'Elevation'];

  floorsConfiguration: any;

  floors: Floor[];

  floorsWithElevation: TableElevationFloor[];

  onChange: any = () => {};

  @Input() set companyFloors(floors: Floor[]) {
    if (!floors) {
      return;
    }

    this.floors = floors;
  }

  get floorsFormArray() {
    return this.form.get('Floors') as UntypedFormArray;
  }

  get isTeamAdmin() {
    return this.identityService.isTeamAdmin();
  }

  constructor(private formBuilder: UntypedFormBuilder, private identityService: IdentityService) {
    const floor = this.buildFloor();

    this.form = this.formBuilder.group({
      Floors: this.formBuilder.array([floor]),
    });

    if (this.isTeamAdmin) {
      this.floorsFormArray.controls.forEach(control => {
        control.get('IsEnabled').disable();
      });
    }
  }

  writeValue(floorsConfiguration: FloorConfiguration): void {
    this.floorsConfiguration = floorsConfiguration;

    this.floorsWithElevation = this.getFloorsWithElevation();

    this.initFormArray();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  ngOnInit() {
    this.floorsFormArray.valueChanges
      .pipe(
        tap(value => {
          const adaptedData = this.getAdaptedData(value);

          this.onChange(adaptedData);
        }),
      )
      .subscribe();
  }

  buildFloor() {
    return this.formBuilder.group({
      IsEnabled: [],
      Name: [],
      Elevation: [],
    });
  }

  initFormArray() {
    this.floorsWithElevation.forEach((tableFloor, index) => {
      this.floorsFormArray.setControl(
        index,
        this.formBuilder.group({
          Id: tableFloor.Id,
          IsEnabled: tableFloor.IsEnabled,
          Name: tableFloor.Name,
          Elevation: tableFloor.Elevation,
        }),
      );
    });

    this.dataSource.next(this.floorsFormArray.controls);
  }

  changeEnableState(changedFloor: UntypedFormGroup, index: number) {

    if (this.isTeamAdmin) {
      return;
    }

    const value = changedFloor.get('IsEnabled').value;

    this.floorsFormArray.at(index).get('IsEnabled').setValue(!value);
  }

  getFloorsWithElevation(): TableElevationFloor[] {
    return this.floors.map(floor => {
      return {
        Id: floor.Id,
        IsEnabled: this.getIsEnabledFloor(floor),
        Name: floor.Name,
        Elevation: this.getFloorElevation(floor),
      };
    });
  }

  getFormValueByKey(key: string, index: number): any {
    return this.floorsFormArray.at(index)?.get(key)?.value;
  }

  private getAdaptedData(tableFloors: TableElevationFloor[]): any {
    const enabledFloors = tableFloors.filter(floor => floor.IsEnabled);

    const result = {};

    for (const floor of enabledFloors) {
      result[floor.Id.toString()] = floor.Elevation;
    }

    return { Type: 0, Elevations: result };
  }

  private getIsEnabledFloor(floor: Floor): boolean {
    if (!this.floorsConfiguration) {
      return false;
    }

    return Object.keys(this.floorsConfiguration.Elevations).includes(floor.Id);
  }

  private getFloorElevation(floor: Floor): number {
    if (!this.floorsConfiguration) {
      return 0;
    }

    if (Object.keys(this.floorsConfiguration.Elevations).includes(floor.Id)) {
      return this.floorsConfiguration.Elevations[floor.Id];
    }

    return 0;
  }
}
