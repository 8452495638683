export const environment = {
  production: false,
  adminApi: 'http://localhost:52024/api',
  identityApi: 'http://localhost:54702/api',
  api: 'http://localhost:55374/api',
  apiKey: 'AIzaSyDRMnbmprvTV5NxwoAxNjhVU5Hkkh2_DAE',
  zendeskAccountUrl: 'vgishelp',
  vsiteUrl: 'http://localhost:44450',
  apiVersion: '1.0'
};
