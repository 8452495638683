import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ConfigurationFieldsAdapterService } from '../configuration-fields-adapter.service';
import { MeasurementSystem } from '../interfaces/measurement-system.enum';
import { MatLegacySelect as MatSelect, MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfigurationSelectWithMeasurement } from '../interfaces/configuration-select-with-measurement';
import { PlacemarksFields } from '../../../models/placemarks-fields';
import { MatLegacyCheckbox as MatCheckbox, MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';

@Component({
  selector: 'mee-configuration-compound-select-new',
  templateUrl: './configuration-compound-select.component.html',
  styleUrls: ['./configuration-compound-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConfigurationCompoundSelectComponent),
      multi: true,
    },
  ],
})

export class ConfigurationCompoundSelectComponent implements ControlValueAccessor {

  @Input() helpTooltip: string;

  @Input() placeholder: string;

  @Input() placeMarksFields: PlacemarksFields;

  @ViewChild('firstSelectValue', { static: true }) firstSelectValue: MatSelect;

  @ViewChild('secondSelectValue', { static: true }) secondSelectValue: MatSelect;

  @ViewChild('checkboxValue', { static: true }) checkboxValue: MatCheckbox;

  private initialData = {
    FieldName: null,
    Type: MeasurementSystem.Meters,
    InvertValue: false,
  };

  isFormTouched = false;

  data: ConfigurationSelectWithMeasurement = this.initialData;

  measurementSystemOptions = this.configurationFieldsAdapterService.getMeasurementSystemOptions();

  onChange: any = () => {
  };

  onTouch: any = () => {
  };

  set value(value: ConfigurationSelectWithMeasurement) {

    const isValueEmptyObject = Object.keys(value).length === 0;

    const isOnlyUnitsOfMeasurementChanged = this.isFormTouched && isValueEmptyObject;

    if (isOnlyUnitsOfMeasurementChanged) {
      return;
    }

    this.firstSelectValue.writeValue(value.FieldName);
    this.secondSelectValue.writeValue(value.Type || MeasurementSystem.Meters);
    this.checkboxValue.writeValue(value.InvertValue);
  }

  constructor(private configurationFieldsAdapterService: ConfigurationFieldsAdapterService) {
  }

  selectedFieldChanged(event: MatSelectChange) {
    this.data = {
      FieldName: event.value,
      Type: this.data.Type ? this.data.Type : MeasurementSystem.Meters,
      InvertValue: this.data.InvertValue,
    };

    this.onChange(this.data);
  }

  selectedUnitOfMeasurementChanged(event: MatSelectChange) {
    this.data = {
      FieldName: this.data.FieldName ? this.data.FieldName : null,
      Type: event.value,
      InvertValue: this.data.InvertValue,
    };

    if (this.data.FieldName === null) {
      this.secondSelectValue.writeValue(event.value);
      return;
    }

    this.onChange(this.data);
  }

  selectedInvertValueChanged(event: MatCheckboxChange) {

    this.data = {
      FieldName: this.data.FieldName ? this.data.FieldName : null,
      Type: this.data.Type ? this.data.Type : MeasurementSystem.Meters,
      InvertValue: event.checked,
    };

    if (this.data.FieldName === null) {
      this.checkboxValue.writeValue(event.checked);
      return;
    }

    this.onChange(this.data);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.isFormTouched = true;
    this.onTouch = fn;
  }

  writeValue(data: ConfigurationSelectWithMeasurement | null): void {

    if (data === null) {

      this.data = this.initialData;

      this.firstSelectValue.writeValue(null);

      this.secondSelectValue.writeValue(MeasurementSystem.Meters);

      this.checkboxValue.writeValue(false);

      return;
    }

    this.value = { ...data };

    this.data = { ...data };
  }

}
