import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { IdentityService } from '../../../services/identity.service';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { ICompany } from '../../../modules/companies/shared/interfaces/company.interface';
import { Observable, Subject } from 'rxjs';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompaniesService } from '../../../modules/companies/companies.service';
import { SuperAdminAllCompaniesOption } from '../../../modules/components/super-admin-all-companies-option';

@Component({
  selector: 'mee-companies-autocomplete',
  templateUrl: './companies-autocomplete.component.html',
  styleUrls: ['./companies-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CompaniesAutocompleteComponent),
    },
  ],
})
export class CompaniesAutocompleteComponent implements ControlValueAccessor, OnDestroy, OnInit {
  @Input() isAllCompaniesOption = false;
  @Input() isAllTeamProject: boolean;
  filteredCompaniesData$: Observable<ICompany[]>;

  companies: ICompany[];

  form = new UntypedFormGroup({
    selectedCompany: new UntypedFormControl(),
  });

  selectedCompany: ICompany;
  private destroy$ = new Subject<void>();
  private isAllCompaniesOptionDisableState = false;
  private selectedCompanySlug: string;

  onChange: any = () => {
  };

  get isCompanyAdmin() {
    return this.identityService.isCompanyAdmin();
  }

  get isTeamAdmin() {
    return this.identityService.isTeamAdmin();
  }

  constructor(
    private identityService: IdentityService,
    private companiesService: CompaniesService,
  ) {}

  ngOnInit(): void {
    if (!this.isAllTeamProject) {
      this.form.get('selectedCompany').disable();
    }

    this.getCompaniesByRole();
    this.form.get('selectedCompany').valueChanges.subscribe(value => {
      this.selectedCompany = value;
      this.onChange(value);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isAllCompaniesOptionDisable(companySlug: string): boolean {
    return companySlug === SuperAdminAllCompaniesOption.All && this.isAllCompaniesOptionDisableState;
  }

  onCompanyChange(event: MatAutocompleteSelectedEvent) {
    this.selectedCompany = event.option.value;
  }

  filterCompaniesAutocompleteValues(): void {
    this.filteredCompaniesData$ = this.form.get('selectedCompany').valueChanges.pipe(
      startWith(''),
      map(value => this.filterCompanies(value)),
    );
  }

  displayCompanyProperty(company: ICompany) {
    if (company) {
      return company.Name;
    }
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.form.get('selectedCompany').disable();
    } else {
      this.form.get('selectedCompany').enable();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(company: ICompany | string): void {
    if (typeof company === 'string') {
      this.selectedCompanySlug = company;

      return;
    }

    this.form.get('selectedCompany').setValue(company);
  }

  private getCompaniesListForSuperAdmin() {
    this.companiesService
      .list()
      .pipe(
        tap((companies: ICompany[]) => {
          const companiesTrimmed = companies.map(company => {
            return { ...company, Name: company.Name.trim() };
          });

          const existingCompanies = companiesTrimmed.filter(company => !company.IsArchived);
          let sortedCompanies = existingCompanies.sort((a, b) => (a.Name < b.Name ? -1 : 1));

          if (this.isAllCompaniesOption) {
            const allCompaniesOption = { Name: 'All', Slug: SuperAdminAllCompaniesOption.All } as ICompany;
            sortedCompanies = [allCompaniesOption, ...sortedCompanies]
          }

          this.companies = sortedCompanies;

          if (!this.selectedCompanySlug && !this.isAllTeamProject) {
            this.selectedCompanySlug = this.identityService.companySlug;
          }

          if (this.selectedCompanySlug) {
            const foundCompany = this.companies.find(findCompany => findCompany.Slug === this.selectedCompanySlug);

            this.writeValue(foundCompany);
          }

          this.filterCompaniesAutocompleteValues();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private getCompanyForCompanyAdmin() {
    this.companiesService
      .getMyCompany()
      .pipe(
        tap(company => this.form.get('selectedCompany').setValue(company)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private getCompanyForTeamAdmin() {
    const companySlug = this.identityService.companySlug;

    this.companiesService
      .get(companySlug)
      .pipe(
        tap(company => this.form.get('selectedCompany').setValue(company)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private filterCompanies(value: string): Array<ICompany> {
    if (typeof value !== 'string') {
      return;
    }

    const filterValue = value.toLowerCase();
    this.form.get('selectedCompany').setErrors({ required: true });

    return this.companies.filter(option => option.Name.toLowerCase().includes(filterValue));
  }

  private getCompaniesByRole() {
    if (this.identityService.isSuperAdmin()) {
      this.getCompaniesListForSuperAdmin();
    } else if (this.identityService.isCompanyAdmin()) {
      this.getCompanyForCompanyAdmin();
    } else {
      this.getCompanyForTeamAdmin();
    }
  }
}
