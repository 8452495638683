import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {SourceConfiguration} from './interfaces/source-configuration';
import {ActivatedRoute} from '@angular/router';
import {ILayer} from '../../../../../components/layers/shared/models/layer.interface';
import {ConfigurationSnapshotData} from './interfaces/configuration-snapshot-data';
import {ConfigurationDataSource} from '../../models/configuration-data-source';
import {DataSourceType} from '../../../../data-source-type.enum';
import {takeUntil, tap} from 'rxjs/operators';
import {ChangedNodeResults} from './interfaces/changed-node-results';
import {ChangedNodeFormContent} from './interfaces/changed-node-form-content';
import {SingleForm} from './interfaces/single-form';
import {Subject} from 'rxjs';
import {PlacemarksFields} from '../../models/placemarks-fields';
import cloneDeep from 'lodash-es/cloneDeep';
import {SourceConfigurationService} from '../../services/source-configuration.service';
import {DataSourcesService} from '../../../../services/data-sources.service';
import {ObjectType} from './enums/object-types.enum';
import {ConfigurationFieldsAdapterService} from './configuration-fields-adapter.service';
import {UntypedFormGroup} from '@angular/forms';
import {LineSubtype} from './enums/line-subtypes.enum';
import {BimNode} from '../../models/bim-node';
import {SourceConfiguration3dModelMarker} from '../../models/source-configuration-3d-model-marker.enum';
import isEqual from 'lodash-es/isEqual';
import {PointSubtype} from './interfaces/point-subtype';
import {SubtypeConfiguration} from './interfaces/subtype-configuration';
import {LayersService} from '../../../../../components/layers/shared/services/layers.service';
import { ProjectionType } from './enums/projection-type.enum';
import { CoordinateSystem } from '../../../../../components/coordinate-system/coordinate-system';

@Component({
  selector: 'mee-configuration-tab-new',
  templateUrl: './configuration-tab.component.html',
  styleUrls: ['./configuration-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationTabComponent implements OnDestroy {
  layers: ILayer[];

  localPlaceMarksFieldsFields: PlacemarksFields;

  currentChangedNode: ChangedNodeFormContent;

  private changedNodes: Set<SourceConfiguration>

  private localSelectedNodesIds: Array<string>;

  private localPointConfiguration: SingleForm;

  private localLineConfiguration: SingleForm;

  private localAreaConfiguration: SingleForm;

  private localModel3DConfiguration: SingleForm;

  private destroy$ = new Subject<void>();

  @Input() node: SourceConfiguration;

  @Input() dataSourceSettings: ConfigurationDataSource;

  @Input() sourceType: DataSourceType;

  @Input() isModelLevel = false;

  @Input() companySlug: string;

  @Input() sourceSlug: string;

  @Input() isSourceRealTime: boolean;

  @Input() bimNodes: BimNode[];

  @Input() coordinateSystems: CoordinateSystem[] = [];

  @Output() nodeChanged = new EventEmitter<ChangedNodeResults>();

  @Output() nodeChangedAfterCopyConfig = new EventEmitter<SourceConfiguration>();

  @Output() fieldsValueInSelectedLayersChanged = new EventEmitter<any>();

  @Output() modelProjectionTypeChanged = new EventEmitter<ProjectionType>();

  @Input() isUpdateInProgress: boolean;

  @Input() set selectedNodesIds(selectedNodesIds: string[]) {
    this.localSelectedNodesIds = selectedNodesIds;
  }

  get selectedNodesIds() {
    return this.localSelectedNodesIds;
  }

  @ViewChild('root', { static: false }) set rootConfiguration(rootConfiguration: SingleForm) {
    if (rootConfiguration) {
      rootConfiguration.form.valueChanges
        .pipe(
          tap((value: ChangedNodeFormContent) => this.emitChangedNodeResults(value)),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  @ViewChild('folder', { static: false }) set folderConfiguration(folderConfiguration: SingleForm) {
    if (folderConfiguration) {
      folderConfiguration.form.valueChanges
        .pipe(
          tap((value: ChangedNodeFormContent) => this.emitChangedNodeResults(value)),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  @ViewChild('point', { static: false }) set pointConfiguration(pointConfiguration: SingleForm) {
    if (pointConfiguration) {
      this.localPointConfiguration = pointConfiguration;
      pointConfiguration.form.valueChanges
        .pipe(
          tap(() => {
            const changedNode = pointConfiguration.form.getRawValue();

            if (isEqual(this.currentChangedNode, changedNode)) {
              return;
            }

            this.onChangeNode(changedNode);
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  get pointConfiguration() {
    return this.localPointConfiguration;
  }

  @ViewChild('line', { static: false }) set lineConfiguration(lineConfiguration: SingleForm) {
    if (lineConfiguration) {
      this.localLineConfiguration = lineConfiguration;
      lineConfiguration.form.valueChanges
        .pipe(
          tap(() => {
            const changedNode = lineConfiguration.form.getRawValue();

            if (isEqual(this.currentChangedNode, changedNode)) {
              return;
            }

            this.onChangeNode(changedNode);
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  get lineConfiguration() {
    return this.localLineConfiguration;
  }

  @ViewChild('area', { static: false }) set areaConfiguration(areaConfiguration: SingleForm) {
    if (areaConfiguration) {
      this.localAreaConfiguration = areaConfiguration;
      areaConfiguration.form.valueChanges
        .pipe(
          tap(() => {
            const changedNode = areaConfiguration.form.getRawValue();

            if (isEqual(this.currentChangedNode, changedNode)) {
              return;
            }

            this.onChangeNode(changedNode);
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  get areaConfiguration() {
    return this.localAreaConfiguration;
  }

  @ViewChild('model3D', { static: false }) set model3DConfiguration(model3DConfiguration: SingleForm) {
    if (model3DConfiguration) {
      this.localModel3DConfiguration = model3DConfiguration;
      model3DConfiguration.form.valueChanges
        .pipe(
          tap(() => {
            const changedNode = model3DConfiguration.form.getRawValue();

            if (isEqual(this.currentChangedNode, changedNode)) {
              return;
            }

            this.modelProjectionTypeChanged.emit(model3DConfiguration.projectionTypeControl.value);

            this.onChangeNode(changedNode);
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();

      model3DConfiguration.projectionTypeControl.valueChanges
        .pipe(
          tap(value => this.modelProjectionTypeChanged.emit(value)),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  get model3DConfiguration() {
    return this.localModel3DConfiguration;
  }

  @ViewChild('unknown', { static: false }) set unknownConfiguration(unknownConfiguration: SingleForm) {
    if (unknownConfiguration) {
      unknownConfiguration.form.valueChanges
        .pipe(
          tap(() => {
            const changedNode = unknownConfiguration.form.getRawValue();

            if (isEqual(this.currentChangedNode, changedNode)) {
              return;
            }

            this.onChangeNode(changedNode);
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  @ViewChild('multiselect', { static: false }) set multiselectConfiguration(multiselectConfiguration: SingleForm) {
    if (multiselectConfiguration) {
      multiselectConfiguration.form.valueChanges
        .pipe(
          tap(() => {
            const changedNode = multiselectConfiguration.form.getRawValue();

            if (isEqual(this.currentChangedNode, changedNode)) {
              return;
            }

            if (this.selectedNodesIds.length > 0) {
              this.replaceFieldsValueInSelectedLayers(changedNode);

              return;
            }

            this.onChangeNode(changedNode);
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  @Input() set placeMarksFields(placeMarksFields: PlacemarksFields | null) {
    if (placeMarksFields === null) {
      return;
    }

    this.localPlaceMarksFieldsFields = placeMarksFields;
    this.localPlaceMarksFieldsFields.TableKeys = placeMarksFields.PrimaryFields.slice();
    const primaryFields = this.localPlaceMarksFieldsFields.PrimaryFields;
    const dynamicAlias = this.node.DynamicPropertiesAliases;

    Object.keys(dynamicAlias).forEach(alias => {
      if (primaryFields.includes(alias)) {
        const index = primaryFields.indexOf(alias);
        primaryFields[index] = dynamicAlias[alias];
      }
    });
  }

  get placeMarksFields() {
    return this.localPlaceMarksFieldsFields;
  }

  @Input() set newNodeAfterCopyConfig(newNodeAfterCopyConfig: SourceConfiguration) {
    if (!newNodeAfterCopyConfig) {
      return;
    }

    this.emitNewConfigurationAfterCopy(newNodeAfterCopyConfig);
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private sourceConfigService: SourceConfigurationService,
    private configurationFieldsAdapterService: ConfigurationFieldsAdapterService,
    private dataSourceService: DataSourcesService,
    private layersService: LayersService,
    private cdr: ChangeDetectorRef
  ) {
    this.getLayers();
  }

  updateLayerGroup() {
    this.layersService.getLayersByCompany(this.companySlug).pipe(
      tap(layers => this.layers = layers.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()))),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  get isMultiselectMode() {
    return this.selectedNodesIds.length > 1 && this.selectedNodesIds.includes(this.node.Id);
  }

  isRoot() {
    return this.node.isRoot;
  }

  isFolder() {
    return this.node.parentId && this.node.isBranch && this.node.Marker !== SourceConfiguration3dModelMarker.ModelFile;
  }

  isPoint() {
    return this.node.ObjectType === ObjectType.POINT;
  }

  isLine() {
    return this.node.ObjectType === ObjectType.LINE;
  }

  isArea() {
    return this.node.ObjectType === ObjectType.AREA;
  }

  isModel3D() {
    return this.node.ObjectType === ObjectType.MODEL3D;
  }

  isUnknown() {
    return this.node.ObjectType === ObjectType.UNKNOWN || this.node.ObjectType === null;
  }

  // is feature layer defined by object type

  isByObjectType() {
    return this.isPoint() || this.isLine() || this.isArea() || this.isModel3D() || this.isUnknown();
  }

  private replaceFieldsValueInSelectedLayers(fieldsNewValue: any) {
    this.fieldsValueInSelectedLayersChanged.next(fieldsNewValue);
  }

  private onChangeNode(value: ChangedNodeFormContent) {
    this.currentChangedNode = value;

    if (this.node.ObjectType !== value.ObjectType) {
      this.node.ObjectType = value.ObjectType;

      this.objectTypeChanged(value.ObjectType);
    }

    if (value.hasOwnProperty('SubtypeField') && this.node.SubtypeField !== value.SubtypeField) {
      this.onSubtypeFieldChange(value);

      return;
    }

    // for display Parent node setting in altitude mode form field

    if (value.AltitudeMode !== undefined && value.AltitudeMode === -1) {
      value = { ...value, AltitudeMode: null };
    }

    this.emitChangedNodeResults(value);
  }

  private emitChangedNodeResults(formValue: ChangedNodeFormContent, isSubtypeChanged?: boolean) {
    let changedNodeResults: ChangedNodeResults = {
      changed: true,
      changedNode: formValue,
      id: formValue.Id,
    };

    if (isSubtypeChanged !== undefined && isSubtypeChanged) {
      changedNodeResults = {
        ...changedNodeResults,
        subtypes: this.node.SubtypesConfigurations,
      };
    }

    if (formValue.ObjectType === null) {
      changedNodeResults = {
        ...changedNodeResults,
        changedNode: { ...formValue, ObjectTypeConfiguration: null },
      };
    }

    this.nodeChanged.emit(changedNodeResults);
  }

  private emitNewConfigurationAfterCopy(newConfiguration: SourceConfiguration) {
    this.nodeChangedAfterCopyConfig.emit(newConfiguration);
  }

  private getLayers() {
    const data = this.activatedRoute.snapshot.data as ConfigurationSnapshotData;

    this.layers = data.layers.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase()));
  }

  private objectTypeChanged(objectType: ObjectType) {
    switch (objectType) {
      case null:
        this.node.ObjectTypeConfiguration = null;
        break;

      case ObjectType.POINT:
        setTimeout(() => this.onPointObjectTypeChanged(), 0);
        break;

      case ObjectType.LINE:
        setTimeout(() => this.onLineObjectTypeChanged(), 0);
        break;

      case ObjectType.AREA:
        setTimeout(() => this.onAreaObjectTypeChanged(), 0);
        break;

      case ObjectType.MODEL3D:
        setTimeout(() => this.onModel3DObjectTypeChanged(), 0);
        break;
    }
  }

  private onPointObjectTypeChanged() {
    this.pointConfiguration.form.get('PlacemarkImage').reset();

    const objectTypeConfiguration = this.pointConfiguration.form.get('ObjectTypeConfiguration') as UntypedFormGroup;

    objectTypeConfiguration.get('Subtype').setValue(PointSubtype.OBJECT);
    objectTypeConfiguration.get('ObjectType').setValue(ObjectType.POINT);

    objectTypeConfiguration.removeControl('BoundingBoxShape');
    objectTypeConfiguration.removeControl('UndergroundConnectorDepth');
    objectTypeConfiguration.removeControl('DefaultDepth');
    objectTypeConfiguration.removeControl('DepthField');
    objectTypeConfiguration.removeControl('BoundingBoxHeight');
    objectTypeConfiguration.removeControl('BoundingBoxHeightField');
    objectTypeConfiguration.removeControl('BoundingBoxWidth');
    objectTypeConfiguration.removeControl('BoundingBoxWidthField');
  }

  private onLineObjectTypeChanged() {
    this.lineConfiguration.form.get('PlacemarkImage').reset();

    const objectTypeConfiguration = this.lineConfiguration.form.get('ObjectTypeConfiguration') as UntypedFormGroup;

    objectTypeConfiguration.get('Subtype').setValue(LineSubtype.PIPE);
    objectTypeConfiguration.get('ObjectType').setValue(ObjectType.LINE);
  }

  private onAreaObjectTypeChanged() {
    this.areaConfiguration.form.get('PlacemarkImage').reset();

    const objectTypeConfiguration = this.areaConfiguration.form.get('ObjectTypeConfiguration') as UntypedFormGroup;

    objectTypeConfiguration.get('ObjectType').setValue(ObjectType.AREA);
  }

  private onModel3DObjectTypeChanged() {
    const objectTypeConfiguration = this.model3DConfiguration.form.get('ObjectTypeConfiguration') as UntypedFormGroup;

    objectTypeConfiguration.get('ObjectType').setValue(ObjectType.MODEL3D);

    objectTypeConfiguration.removeControl('CoordinateSystem');
    objectTypeConfiguration.removeControl('Altitude');
    objectTypeConfiguration.removeControl('Latitude');
    objectTypeConfiguration.removeControl('Longitude');
    objectTypeConfiguration.removeControl('Pitch');
    objectTypeConfiguration.removeControl('Roll');
    objectTypeConfiguration.removeControl('Yaw');
  }

  private onSubtypeFieldChange(formValue: ChangedNodeFormContent) {
    if (formValue.SubtypeField === null) {
      this.node.SubtypesConfigurations = [];
      this.emitChangedNodeResults(formValue, true);
      return;
    }

    this.dataSourceService
      .getSubtypeConfigurations(this.companySlug, this.sourceSlug, this.node.Id, formValue.SubtypeField)
      .subscribe((data: SubtypeConfiguration[]) => {
        // for cancel layer changes in newly created subtypes

        let newlyCreatedSubtypesWithId = data.slice();

        newlyCreatedSubtypesWithId = newlyCreatedSubtypesWithId.map(el => {
          el.Id = `${this.node.Id}_${el.SubtypeValue}`;
          return el;
        });

        this.sourceConfigService.setNewlyCreatedSubtypes(cloneDeep(newlyCreatedSubtypesWithId));

        if (!data.length) {
          return;
        }

        formValue = {
          ...formValue,
          SubtypesConfigurations: data,
        };

        this.node.SubtypesConfigurations = [...data];

        this.node.SubtypesConfigurations.forEach((subtypeConfig: SubtypeConfiguration) => {
          subtypeConfig.isSubtype = true;
          subtypeConfig.parentId = this.node.Id;
        });

        this.node.expanded = true;

        this.emitChangedNodeResults(formValue, true);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
