import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RestClass, RestService } from '../../services/rest/rest.service';
import { ArcGisCustomElevation } from './models/arc-gis-custom-elevation';
import { ArcGisCustomGeneralElevation } from './models/arc-gis-custom-general-elevation';
import { ArcGisCustomElevationCreateUpdate } from './models/arc-gis-custom-elevation-create-update';
import { IdentityService } from '../../services/identity.service';

@Injectable({ providedIn: 'root' })
@RestClass('/companies')
export class CustomElevationsService extends RestService<any> {
  newLandscapeProviderChanged$: Subject<ArcGisCustomElevation> = new Subject();
  editLandscapeProviderChanged$: Subject<ArcGisCustomElevation> = new Subject();
  customLandscapeProviderListChanged$: BehaviorSubject<ArcGisCustomElevation[]> = new BehaviorSubject([]);

  constructor(protected http: HttpClient, private identityService: IdentityService) {
    super(http);
  }

  createNewLandscapeProvider(data: ArcGisCustomElevationCreateUpdate, companySlug: string): Observable<ArcGisCustomGeneralElevation> {
    const url = this.getRoute() + `/${companySlug}/elevationtileproviders`;
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.post<ArcGisCustomGeneralElevation>(url, data, { params });
  }

  setNewLandscapeProvider(newMapProvider) {
    this.newLandscapeProviderChanged$.next(newMapProvider);
  }

  clearCache(companySlug: string, dtmTileProviderSlug: string) {
    const url = `${this.getRoute()}/${companySlug}/elevationtileproviders/${dtmTileProviderSlug}/cleanup`;
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.delete(url, { params });
  }

  deleteLandscapeProvider(dtmSlug: string, companySlug: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.delete(this.getRoute() + `/${companySlug}/elevationtileproviders/${dtmSlug}`, { params });
  }

  editLandscapeProvider(
    dtmSlug: string,
    mapTilesProvider: ArcGisCustomElevationCreateUpdate,
    companySlug: string,
  ): Observable<ArcGisCustomGeneralElevation> {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.put<ArcGisCustomGeneralElevation>(
      this.getRoute() + `/${companySlug}/elevationtileproviders/${dtmSlug}`,
      mapTilesProvider,
      { params }
    );
  }

  setEditLandscapeProvider(editMapProvider: ArcGisCustomElevation) {
    this.editLandscapeProviderChanged$.next(editMapProvider);
  }

  setCustomLandscapeProvidersList(arcGisCustomElevations: ArcGisCustomElevation[]) {
    this.customLandscapeProviderListChanged$.next(arcGisCustomElevations);
  }
}
