// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.process-layers-table-wrapper {
  height: 100%;
}
.process-layers-table-wrapper mat-table {
  min-width: 1500px;
  margin: 0;
}
.process-layers-table-wrapper mat-table .mat-header-cell,
.process-layers-table-wrapper mat-table .mat-cell {
  min-width: 120px;
}
.process-layers-table-wrapper mat-table .mat-header-cell.mat-column-LayerId,
.process-layers-table-wrapper mat-table .mat-cell.mat-column-LayerId {
  min-width: 290px;
}
.process-layers-table-wrapper mat-table .mat-header-row .mat-header-cell {
  font-weight: 500;
  color: black;
}
.process-layers-table-wrapper mat-table .mat-header-row .mat-header-cell:first-child {
  padding-left: 64px;
}
.process-layers-table-wrapper mat-table .mat-row .mat-cell:first-child {
  padding-left: 64px;
}
.process-layers-table-wrapper mat-table .mat-column-LayerId {
  flex: 2;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/sources/components/process-details/process-layers-table/process-layers-table.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,iBAAA;EACA,SAAA;AACJ;AACI;;EAEE,gBAAA;AACN;AACM;;EACE,gBAAA;AAER;AAGM;EACE,gBAAA;EACA,YAAA;AADR;AAIM;EACE,kBAAA;AAFR;AAOM;EACE,kBAAA;AALR;AASI;EACE,OAAA;AAPN","sourcesContent":[".process-layers-table-wrapper {\n  height: 100%;\n\n  mat-table {\n    min-width: 1500px;\n    margin: 0;\n\n    .mat-header-cell,\n    .mat-cell {\n      min-width: 120px;\n\n      &.mat-column-LayerId {\n        min-width: 290px;\n      }\n    }\n\n    .mat-header-row {\n      .mat-header-cell {\n        font-weight: 500;\n        color: black;\n      }\n\n      .mat-header-cell:first-child {\n        padding-left: 64px;\n      }\n    }\n\n    .mat-row {\n      .mat-cell:first-child {\n        padding-left: 64px;\n      }\n    }\n\n    .mat-column-LayerId {\n      flex: 2;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
