import { Injectable } from '@angular/core';
import { ExportData } from './export-data';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { IdentityService } from '../../../services/identity.service';

@Injectable()

export class ExportDataService {

  constructor(private http: HttpClient, private readonly identityService: IdentityService) {
  }

  exportData(data: ExportData) {
    const params: HttpParams = new HttpParams()
      .append('from', data.from)
      .append('to', data.to)
      .append('includeArchived', data.includeArchived.toString());

    return this.http.get(this.getIssueReportExportUrl(), { params: params, responseType: 'text' });
  }

  exportIssueReports(ids: string[]) {
    return this.http.post(this.getIssueReportExportUrl(), ids, { responseType: 'text' });
  }

  private getIssueReportExportUrl(): string {
    const companySlug = this.identityService.companySlug;

    let url = `${environment.adminApi}/companies/${companySlug}/field-reports/export`;

    if (this.identityService.isTeamAdmin()) {
      const teamSlug = this.identityService.teamSlug;

      url = `${environment.adminApi}/companies/${companySlug}/teams/${teamSlug}/field-reports/export`
    }

    return url;
  }
}
