// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .mat-slide-toggle-label {
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 350px;
}

.arcGisOnline {
  margin-top: 2px;
}

form .form-row {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
form .form-row::ng-deep .mat-form-field .mat-form-field-wrapper {
  padding-bottom: 20px;
}
form .form-row::ng-deep .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  padding: 8px 0;
  border-top: 12px solid transparent;
}
form .form-row::ng-deep .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element, form .form-row::ng-deep .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select {
  display: flex;
  max-height: 16px;
  margin: 0;
}
form .form-row::ng-deep .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
  top: -12px;
  padding-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/global-add/add-dtm/arcgis-custom-dtm-form/arcgis-custom-dtm-form.component.scss"],"names":[],"mappings":"AACE;EACE,8BAAA;EACA,2BAAA;EACA,YAAA;AAAJ;;AAIA;EACE,eAAA;AADF;;AAKE;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AAFJ;AAMQ;EACE,oBAAA;AAJV;AAOY;EACE,cAAA;EACA,kCAAA;AALd;AAOc;EACE,aAAA;EACA,gBAAA;EACA,SAAA;AALhB;AAQc;EACE,UAAA;EACA,iBAAA;AANhB","sourcesContent":[":host ::ng-deep {\n  .mat-slide-toggle-label {\n    justify-content: space-between;\n    flex-direction: row-reverse;\n    width: 350px;\n  }\n}\n\n.arcGisOnline {\n  margin-top: 2px;\n}\n\nform {\n  .form-row {\n    margin-bottom: 8px;\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n\n    &::ng-deep {\n      .mat-form-field {\n        .mat-form-field-wrapper {\n          padding-bottom: 20px;\n\n          .mat-form-field-flex {\n            .mat-form-field-infix {\n              padding: 8px 0;\n              border-top: 12px solid transparent;\n\n              .mat-input-element, .mat-select {\n                display: flex;\n                max-height: 16px;\n                margin: 0;\n              }\n\n              .mat-form-field-label-wrapper {\n                top: -12px;\n                padding-top: 12px;\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
