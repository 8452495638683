import { Component, OnDestroy } from '@angular/core';
import { UsersService } from '../services/users.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mee-find-user-modal',
  templateUrl: './find-user-modal.component.html',
  styleUrls: ['./find-user-modal.component.scss']
})
export class FindUserModalComponent implements OnDestroy {

  emailControl = new UntypedFormControl('', [Validators.required, Validators.pattern(/^\S+@\S+\.\S+$/)]);
  private destroy$ = new Subject<void>();
  private isNewUser = true;

  constructor(private readonly usersService: UsersService, private readonly matDialogRef: MatDialogRef<FindUserModalComponent>) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  search(): void {
    this.usersService.findUserByEmail(this.emailControl.value).pipe(
      tap(foundUser => {
        const email = foundUser.Email.length > 0 ? foundUser.Email : this.emailControl.value;
        const user = { ...foundUser, Email: email };
        this.isNewUser = false;
        this.matDialogRef.close({ isNewUser: this.isNewUser, user , isAllTeamProject: false})
      }),
      finalize(() => {
        if (this.isNewUser) {
          const user = { Email: this.emailControl.value };

          this.matDialogRef.close({ isNewUser: this.isNewUser, user, isAllTeamProject: false });
        }
      }),
      takeUntil(this.destroy$),
    ).subscribe()
  }
}
