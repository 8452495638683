import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface IStub {
  stubText: string;
  stubIcon: string;
  svgIcon?: string;
}

@Component({
  selector: 'mee-stub',
  templateUrl: './stub.component.html',
  styleUrls: ['./stub.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class StubComponent implements OnInit {

  @Input() stubData: IStub;

  @Output() onChangeDate: EventEmitter<string> = new EventEmitter<string>();

  isNeedRefreshDataForSystemReports = false;

  constructor() {
  }

  ngOnInit() {
    this.definePage(this.stubData.stubText);
  }

  definePage(stubText: string) {
    if (stubText === 'stubsText.noSystemReports') {
      this.isNeedRefreshDataForSystemReports = true;
    }
  }

  isNeedRefreshData(): boolean {
    return this.isNeedRefreshDataForSystemReports;
  }

  onChangeSelectedDate(date: string) {
    this.onChangeDate.next(date);
  }

}
