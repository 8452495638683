import { transition, style, trigger, animate, group } from '@angular/animations';

export let routeAnimation = trigger('routeAnimation', [
  transition('void => *', [
    style({
      transform: 'translate3d(0, 10px, 0)',
      opacity: 0,
    }),
    group([
      animate('400ms ease-in-out', style({
        transform: 'translate3d(0, 0, 0)',
        opacity: 1,
      })),
      animate('400ms 150ms ease-in-out', style({
        opacity: 1,
      }))
    ]),
  ]),
]);
