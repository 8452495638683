import { TablePermission } from '../interfaces/table-permission';
import { Injectable } from '@angular/core';
import { CrudOperations } from '../enums/crud-operations.enum';
import { RELATIVE_PERMISSIONS } from '../models/relative-permissions.model';
import { Permission } from '../interfaces/permission';

@Injectable({
  providedIn: 'root',
})

export class PermissionsAdapterService {

  crudOperations = CrudOperations;

  getTablePermissions(permissions: Permission[]): Record<CrudOperations, TablePermission>[] {

    const tablePermissionsKeyValue = permissions.reduce(
      (acc, currentValue) => (acc[currentValue.Name] = {
        title: currentValue.Name,
        type: currentValue.Type,
        isAllowed: currentValue.IsAllowed,
        allowRelative: RELATIVE_PERMISSIONS[currentValue.Name] ?
          RELATIVE_PERMISSIONS[currentValue.Name].allowRelative : [],
        disallowRelative: RELATIVE_PERMISSIONS[currentValue.Name] ?
          RELATIVE_PERMISSIONS[currentValue.Name].disallowRelative : [],
      }, acc), {} as Record<CrudOperations, TablePermission>);

    return [tablePermissionsKeyValue];
  }

  getServerPermissions(permissions: Record<CrudOperations, TablePermission>): Permission[] {
    return Object.keys(this.crudOperations).map(operation => {
      return {
        Type: permissions[operation].type,
        Name: permissions[operation].title,
        IsAllowed: permissions[operation].isAllowed,
      };
    });
  }
}
