import { Injectable } from '@angular/core';
import { RestClass, RestService } from '../../../../../../services/rest/rest.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ISourceLight } from './company-sources-light.model';
import { ISource } from '../../../../models/source.interface';
import { IDSPossibleFLConfig } from './ds-possible-fl-config.model';
import { SourceConfiguration } from '../configuration-tab/interfaces/source-configuration';
import { ConfigurationStructure } from '../configuration-tab/interfaces/configuration-structure';
import { IdentityService } from '../../../../../../services/identity.service';

export interface IDialogDataForCopyConfigList {
  destinationDS: ISource,
  destinationLayer: SourceConfiguration;
}

export interface IDialogDataForCopyConfigItem {
  sourceDS: ISourceLight,
  destinationDS: ISource,
  destinationLayer: SourceConfiguration;

  // Data source with possible copying of feature layer configurations

  DSWithPossibleCopyFLConfig?: IDSPossibleFLConfig;
}

@Injectable()
@RestClass('/companies')

export class CopyConfigFromService extends RestService<any> {

  onBackToListChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  newLayerAfterCopyConfig$: BehaviorSubject<SourceConfiguration> = new BehaviorSubject<SourceConfiguration>(null);

  newDSAfterCopyConfig$: BehaviorSubject<ISource> = new BehaviorSubject<ISource>(null);

  _oldConfigurationStructure: ConfigurationStructure;

  constructor(protected http: HttpClient, private identityService: IdentityService) {
    super(http);
  }

  // DS - data source

  // FL - feature layer

  /*For copy feature layer*/

  getCompanyDSLightVersion(companySlug: string, destinationDSSlug: string, destinationLayerId: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.get(`${this.baseUrl}/companies/${companySlug}/sources/${destinationDSSlug}/${destinationLayerId}`, { params });
  }

  getNewLayerAfterCopyConfig(dataForNewLayerAfterCopyConfig) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);
    const sourceDataSourceId = dataForNewLayerAfterCopyConfig.sourceDSId;
    const sourceFeatureLayerConfigurationId = dataForNewLayerAfterCopyConfig.sourceFeatureLayerConfigId;
    const destinationDataSourceId = dataForNewLayerAfterCopyConfig.destinationDSId;
    const destinationFeatureLayerConfigurationId = dataForNewLayerAfterCopyConfig.destinationFeatureLayerConfigId;

    return this.http.get(
      `${this.baseUrl}/companies/${dataForNewLayerAfterCopyConfig.companySlug}/sources/copy/from/${sourceDataSourceId}/${sourceFeatureLayerConfigurationId}/to/${destinationDataSourceId}/${destinationFeatureLayerConfigurationId}`,
      { params }
    );
  }

  setNewLayerAfterCopyConfig(newLayer: SourceConfiguration) {
    this.newLayerAfterCopyConfig$.next(newLayer);
  }

  /*For copy data source*/

  getCompanyDSLightMinimizedVersion(companySlug: string, destinationDSId: string) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.get(`${this.baseUrl}/companies/${companySlug}/sources/${destinationDSId}/base`, { params });
  }

  // Return data source with possible copying of feature layer configurations

  getDSPossibleCopyingConfig(dataForCopyDSConfig) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.get(
      `${this.baseUrl}/companies/${dataForCopyDSConfig.companySlug}/sources/match/from/${dataForCopyDSConfig.sourceDSId}/to/${dataForCopyDSConfig.destinationDSId}`,
      { params }
    );
  }

  // Returns copy of data source made from another data source

  getCopyOfDSFromDS(companySlug: string, DSWithAlternativeFL) {
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.post(`${this.baseUrl}/companies/${companySlug}/sources/copy`, DSWithAlternativeFL, { params });
  }

  setNewDSAfterCopy(newDSAfterCopy: ISource) {
    this.newDSAfterCopyConfig$.next(newDSAfterCopy);
  }

  // on cancel changes for return to old data until copy config for feature layer

  setOldConfigurationStructure(originalData: ConfigurationStructure) {
    this._oldConfigurationStructure = originalData;
  }

  getOldConfigurationStructure() {
    return this._oldConfigurationStructure;
  }

  // on back to copy config from list component from copy config from item component

  onBackToList(isBackToList: boolean) {
    this.onBackToListChanged$.next(isBackToList);
  }


}
