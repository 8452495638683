import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoordinateSystem } from './coordinate-system';
import { RestClass, RestService } from '../../../services/rest/rest.service';
import { HttpClient } from '@angular/common/http';
import { IdentityService } from '../../../services/identity.service';

@Injectable({
  providedIn: 'root'
})
@RestClass('/custom-crs')
export class CoordinateSystemsService extends RestService<any> {

  constructor(protected http: HttpClient, private identityService: IdentityService) {
    super(http);
  }

  update(coordinateSystem: CoordinateSystem): Observable<CoordinateSystem> {
    const companySlug = this.identityService.companySlug;
    const coordinateSystemSlug = coordinateSystem.Slug;
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.put<CoordinateSystem>(
      `${this.baseUrl}/companies/${companySlug}/custom-crs/${coordinateSystemSlug}`,
      coordinateSystem, { params }
    );
  }

  remove(coordinateSystemSlug: string): Observable<null> {
    const companySlug = this.identityService.companySlug;
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.delete<null>(`${this.baseUrl}/companies/${companySlug}/custom-crs/${coordinateSystemSlug}`, { params })
  }

  create(coordinateSystem: CoordinateSystem): Observable<CoordinateSystem> {
    const companySlug = this.identityService.companySlug;
    const params = this.getHttpParamsForSelectedScope(this.identityService.teamSlug);

    return this.http.post<CoordinateSystem>(`${this.baseUrl}/companies/${companySlug}/custom-crs`, coordinateSystem, { params });
  }

  get(): Observable<CoordinateSystem[]> {
    const companySlug = this.identityService.companySlug;

    let url = `${this.baseUrl}/companies/${companySlug}/custom-crs`;

    if (this.identityService.isTeamAdmin()) {
      const teamSlug = this.identityService.teamSlug;

      url = `${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/custom-crs`;
    }

    return this.http.get<CoordinateSystem[]>(url);
  }

  getByTeam(companySlug: string, teamSlug: string): Observable<CoordinateSystem[]> {
    return this.http.get<CoordinateSystem[]>(`${this.baseUrl}/companies/${companySlug}/teams/${teamSlug}/custom-crs`);
  }
}
