import { Component, Input } from '@angular/core';
import { LayerStatistic } from '../../source-configuration-list/interfaces/layer-statistic';

@Component({
  selector: 'mee-processing-statistic',
  templateUrl: './processing-statistic.component.html',
  styleUrls: ['./processing-statistic.component.scss']
})
export class ProcessingStatisticComponent {
  @Input() statistic: LayerStatistic;

  getFieldValue(fieldValue: number): number {
    return fieldValue ? fieldValue : 0;
  }
}
