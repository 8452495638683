import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { IdentityService } from '../services/identity.service';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';

export const CheckTokenResolver: ResolveFn<unknown> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  identityService = inject(IdentityService)
): Observable<unknown> => identityService.waitToken();
