import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { RestClass } from './rest/rest.service';
import { environment } from '../../environments/environment';
import { Identity } from '../interfaces/identity';
import { UserRole } from '../interfaces/user-role';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { TranslationHandleService } from '../core/translate/translation-handle.service';
import { HttpClient } from '@angular/common/http';
import { Permission } from '../shared/interfaces/permission';
import { Locales } from '../shared/enums/locales';
import { AdminRole } from '../interfaces/admin-role';
import { User } from '../modules/users/models/user';
import { UpdateSelfIdentityData } from '../modules/users/models/update-self-identity-data';
import { IdentityRole } from '../interfaces/identity-role';
import { TeamProjectService } from './team-project.service';
import { SetPasswordData } from '../interfaces/set-password-data';

const PHRASES_FOR_TRANSLATE = [
  {
    incorrectEmailPassword: 'errors.auth_incorrect',
  },
  {
    errorOccurred: 'errors.error_occurred',
  },
];


@Injectable({
  providedIn: 'root'
})
@RestClass('/identities', environment.identityApi)
export class IdentityService implements AdminRole, OnDestroy {

  /**
   * Prefix user in the storage
   * @type {string}
   */
  static STORAGE_USER_PREFIX = 'user';

  static STORAGE_TOKEN_PREFIX = 'jwt';

  static STORAGE_IDENTITY_PREFIX = 'identity';

  /**
   * Is logged in status
   */
  public isLoggedIn = false;

  /**
   * Redirect url
   */
  public redirectUrl: string;

  /**
   * Current route
   */
  private route: string;

  /**
   * On any error
   */
  public onError: EventEmitter<Identity> = new EventEmitter<Identity>();

  /**
   * On sign in
   */
  public onSignIn: EventEmitter<Identity> = new EventEmitter<Identity>();

  /**
   * On logout
   */
  public onLogout: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * On token dead
   */
  public onTokenDead: EventEmitter<any> = new EventEmitter<any>();

  /**
   * On updated token
   */
  public onTokenUpdated: EventEmitter<any> = new EventEmitter<any>();

  /**
   * On upadate identity
   */
  public onUpdated: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Token status
   */
  public tokenAlive: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * Token timer (one in 1 minute)
   */
  public tokenTimer$ = timer(0, 1000 * 60);

  /**
   * Storage controller
   */
  private _storage: any;

  private localTeamPermissions: Permission[];

  subs: Subscription[] = [];

  translationMap: Map<string, string>;

  set teamPermissions(teamPermissions: Permission[]) {
    this.localTeamPermissions = teamPermissions;
  }

  get teamPermissions() {
    return this.localTeamPermissions;
  }

  /**
   * Login service constructor
   * @param http
   * @param snackBar
   * @param translateService
   * @param teamProjectService
   * @param translationHandleService
   */
  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private teamProjectService: TeamProjectService,
    private translationHandleService: TranslationHandleService
  ) {

    this.translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);

    this.subs.push(
      this.translationHandleService.getTranslationMap().subscribe((translationMap: Map<string, string>) => {
        this.translationMap = translationMap;
      }),
    );

    this._storage = localStorage;

    this.isLoggedIn = this.check();

    /**
     * When token is dead sent refresh token request and emit updated token
     */
    this.onTokenDead.subscribe((notify = true) => {

      this.refreshAccessToken().subscribe((res: Identity) => {
        this.identity = res;

        this.onTokenUpdated.emit(this.identity);

      }, err => this.logout());
    });

    /**
     * When token is updated mark token alive to true
     */
    this.onTokenUpdated.subscribe((identity: Identity) => {
      this.tokenAlive.next(true);
    });

    // this.onLogout.subscribe(() => {
    //   /**
    //    * TODO: *hot fix
    //    * Not navigate to sign-in page after token missing with "route.navigate(['sign-in'])"
    //    * @type {string}
    //    */
    //   window.location.href = '/sign-in';
    // });
  }


  /**
   * Check auth
   * @returns {any}
   */
  check(): boolean {
    return this.token && this.token.length > 0;
  }

  updateIdentity(data: UpdateSelfIdentityData): Observable<User> {
    return this.http.put<User>(`${this.route}/self`, data);
  }

  /**
   * Auth method
   * @param login
   * @param password
   * @returns {Subscription}
   */
  signIn(login, password): Observable<Identity> {

    const response = this.http.post<Identity>(this.route + '/signin', {
      email: login,
      password: password,
    });

    this.translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);

    response.subscribe((res: Identity) => {

      this.identity = res;
      this.isLoggedIn = true;

      this.onSignIn.emit(this.identity);
    }, (error: any) => {
      if (error.status === 401 || error.status === 400) {
        this.snackBar.open(this.translationMap.get('incorrectEmailPassword'), null, { duration: 5000 });
      } else {
        this.snackBar.open(this.translationMap.get('errorOccurred'), null, { duration: 10000 });
      }

      this.onError.emit(this.identity);
    });

    return response;
  }

  /**
   * Request to refresh access token
   * @returns {Observable<Response>}
   */
  refreshAccessToken() {
    return this.http.post(`${this.route}/refreshaccesstoken`, {
      RefreshToken: this.identity.RefreshToken,
      OneTimeSessionAccessToken: ''
    });
  }

  /**
   * Reset password
   * @returns {Observable<Response>}
   */
  public reset(email: string): Observable<Object> {
    return this.http.post(this.route + '/reset', {
      Data: email,
    });
  }

  /**
   * Set password
   * @param token
   * @param newPassword
   * @returns {Observable<Response>}
   */
  public setPassword(token: string, newPassword: string): Observable<SetPasswordData> {
    return this.http.post<SetPasswordData>(this.route + '/setpassword', {
      token: token,
      password: newPassword,
    });
  }

  /**
   * Change password
   * @param oldPassword
   * @param newPassword
   * @returns {Observable<Response>}
   */
  public changePassword(oldPassword: string, newPassword: string): Observable<Object> {
    return this.http.post(this.route + '/changepassword', {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  }

  /**
   * IUser logout
   */
  logout(): void {
    this.translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);
    this.deleteUserFromStorage();
    this.teamProjectService.clearTeamProject();
    this.isLoggedIn = false;
    this.onLogout.emit();
  }

  /**
   * Get user from storage
   * @returns {string|null}
   */
  get user(): User {
    const user = this._storage.getItem(IdentityService.STORAGE_USER_PREFIX);
    return user && JSON.parse(user);
  }

  /**
   * Set user to storage
   * @param data
   */
  set user(data: User) {
    this._storage.setItem(IdentityService.STORAGE_USER_PREFIX, JSON.stringify(Object.assign(this.user || {}, data)));
  }

  get userAdminsRoles(): IdentityRole[] {
    if (!this.user) {
      return this.identity ? this.identity.Roles.filter(role => role.Name !== UserRole.WORKER) : [];
    }

    return this.user.Roles.filter(role => role.Name !== UserRole.WORKER);
  }

  /**
   * Clear user session
   */
  deleteUserFromStorage(): void {
    this._storage.removeItem(IdentityService.STORAGE_USER_PREFIX);
    this._storage.removeItem(IdentityService.STORAGE_TOKEN_PREFIX);
    this._storage.removeItem(IdentityService.STORAGE_IDENTITY_PREFIX);
  }

  /**
   * Get token from storage
   * @returns {null}
   */
  get token(): string | null {
    if (this.identity) {
      return this.identity.AccessToken;
    }
    return null;
  }

  set identity(identity) {
    this._storage.setItem(IdentityService.STORAGE_IDENTITY_PREFIX, JSON.stringify(identity));
  }

  get identity(): Identity {
    return JSON.parse(this._storage.getItem(IdentityService.STORAGE_IDENTITY_PREFIX));
  }

  get companySlug(): string {
    const teamProject = this.teamProjectService.teamProject;

    return teamProject.CompanySlug;
  }

  get teamSlug(): string {
    const teamProject = this.teamProjectService.teamProject;

    return teamProject.TeamSlug;
  }

  updateLocalIdentityName(data: UpdateSelfIdentityData): void {
    this.identity = { ...this.identity, FirstName: data.FirstName, LastName: data.LastName };
    this.onUpdated.emit(this.identity);
  }

  updateLocalLanguage(language: Locales): void {
    this.user = { ...this.user, Locale: language };
  }

  /**
   * Check logged in
   * @returns {boolean}
   */
  logged(): boolean {
    return this.token !== null;
  }

  /**
   * Todo: 'Can' functionality
   * @param what
   * @returns {boolean}
   */
  can(what: any): boolean {
    return false;
  }

  /**
   * Check role
   * @param role
   * @returns {boolean}
   */
  isRole(role: string): boolean {
    return this.identity.Roles.some(searchRole => searchRole.Name === role);
  }

  /**
   * Check is super admin
   * @returns {boolean}
   */
  isSuperAdmin(): boolean {
    return this.isRole(UserRole.SUPER_ADMINISTRATOR);
  }

  /**
   * Check is company admin
   * @returns {boolean}
   */

  isCompanyAdmin(): boolean {
    const companyAdminRole = this.userAdminsRoles.find(role => role.CompanySlug === this.companySlug);

    return companyAdminRole && companyAdminRole.Name === UserRole.COMPANY_ADMINISTRATOR;
  }

  /**
   * Check is team admin
   * @returns {boolean}
   */

  isTeamAdmin(): boolean {
    const teamAdminRole = this.userAdminsRoles.find(role => role.TeamSlug === this.teamSlug);

    return teamAdminRole && teamAdminRole.Name === UserRole.TEAM_ADMINISTRATOR;
  }

  /**
   * Check token is not expires
   * @returns {boolean}
   */
  checkTokenExpires() {
    if (!this.identity) {
      return false;
    }

    const expires = moment(this.identity.Expires).subtract(3, 'minutes');
    return moment().isBetween(this.identity.Issued, expires);
  }

  /**
   * Token watcher
   * Set a token alive when token is changed
   * @returns {Observable<boolean>}
   */
  tokenWatcher() {
    return this.tokenTimer$.pipe(
      map(() => {
        if (this.checkTokenExpires() && this.tokenAlive.getValue() === false) {
          this.tokenAlive.next(true);
        } else if (!this.checkTokenExpires() && this.tokenAlive.getValue() === true) {
          this.tokenAlive.next(false);
        }
        return this.tokenAlive.getValue();
      }),
    );
  }

  /**
   * Run token watcher and refresh token if token is died
   * @returns {Subscription}
   */
  runTokenWatcher() {
    return this.tokenWatcher().subscribe((isAlive) => {
      if (!isAlive && this.identity) {
        this.onTokenDead.emit();
      }
    });
  }

  /**
   * Check and wait new token
   * @returns {Observable<any>}
   */
  waitToken(): Observable<any> {
    return new Observable(observer => {
      if (this.checkTokenExpires()) {
        observer.next();
        observer.complete();
      } else {
        this.onTokenDead.emit();
        this.onTokenUpdated.subscribe(() => {
          observer.next();
          observer.complete();
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub && sub.unsubscribe());
    this.translationHandleService.cleanTranslationArray();
  }
}
