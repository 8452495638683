import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { IdentityService } from '../../../services/identity.service';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { ICompany } from '../../../modules/companies/shared/interfaces/company.interface';
import { Observable, Subject } from 'rxjs';
import { TeamInterface } from '../../../modules/teams/models/team.interface';
import { filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Scope } from '../../../modules/components/models/scope.enum';
import { TranslationHandleService } from '../../../core/translate/translation-handle.service';
import { CompaniesService } from '../../../modules/companies/companies.service';
import { TeamsService } from '../../../modules/teams/services/teams.service';
import { SourcesFormChangesService } from '../../../modules/sources/services/sources-form-changes.service';

const PHRASES_FOR_TRANSLATE = [
  {
    errorOccurred: 'errors.error_occurred',
  },
  {
    companyScopeTooltip: 'permissions.companyScopeTooltip',
  },
  {
    teamPublicScopeTooltip: 'permissions.teamPublicScopeTooltip',
  },
  {
    teamPrivateScopeTooltip: 'permissions.teamPrivateScopeTooltip',
  },
];

@Component({
  selector: 'mee-permissions-fields-sa-ca',
  templateUrl: './permissions-fields-sa-ca.component.html',
  styleUrls: ['./permissions-fields-sa-ca.component.scss'],
})
export class PermissionsFieldsSaCaComponent implements OnInit, OnDestroy {
  @Input() selectedItem: any;

  @Output() changePermissionsData = new EventEmitter<any>();

  @Input() slugFieldTemplateRef: TemplateRef<any>;
  @Input() nameFieldTemplateRef: TemplateRef<any>;
  @Input() descriptionFieldTemplateRef: TemplateRef<any>

  scopes = Scope;

  filteredCompaniesData$: Observable<ICompany[]>;
  filteredTeamsData$: Observable<TeamInterface[]>;

  isPrivateVisibilityDisabled: boolean;

  form: UntypedFormGroup;

  isShowWarningAboutSetPublic: boolean;

  isShowTeamField: boolean;

  destroy$ = new Subject<void>();

  private selectedCompany: ICompany | { Slug: string; Name: string };

  private selectedTeam: TeamInterface | { Id: string; Name?: string };

  private translationMap: Map<string, string>;

  private companies: ICompany[];
  private teams: TeamInterface[] = [];

  @Input() isUpdateMode: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private identityService: IdentityService,
    private companiesService: CompaniesService,
    private teamsService: TeamsService,
    private translationHandleService: TranslationHandleService,
    private sourcesFormChangesService: SourcesFormChangesService
  ) {
    translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);

    translationHandleService.getTranslationMap().subscribe((translationMap: Map<string, string>) => {
      this.translationMap = translationMap;
    });

    this.sourcesFormChangesService.isNeedToCancelChanges$.pipe(
      filter(isNeedToCancel => isNeedToCancel),
      tap(() => this.patchValuesWhileUpdate()),
      takeUntil(this.destroy$)
    ).subscribe()
  }

  get isSuperAdmin() {
    return this.identityService.isSuperAdmin();
  }

  get isCompanyAdmin() {
    return this.identityService.isCompanyAdmin();
  }

  get isShowScopeTooltip(): boolean {
    return this.form.get('Scope').value !== null;
  }

  ngOnInit(): void {
    this.initForm();

    if (this.isUpdateMode) {
      this.patchValuesWhileUpdate();
    } else {
      this.patchValuesWhileCreate();
    }

    if (this.isCompanyAdmin) {
      this.form.get('CompanySlug').disable();
    }

    this.getCompany();

    this.onChangeScope();

    // for case when we changed company while add entity

    this.form
      .get('CompanySlug')
      .valueChanges.pipe(
      filter(value => value !== null && value.hasOwnProperty('Id')),
      switchMap(({ Slug }) => this.getSortedTeams$(Slug)),
      takeUntil(this.destroy$),
    )
      .subscribe();

    this.form.valueChanges
      .pipe(
        tap(() => {
          this.onChangePermissionsData();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  onCompanyChange(event: MatAutocompleteSelectedEvent) {
    this.selectedCompany = event.option.value;
  }

  filterCompaniesAutocompleteValues(): void {
    this.filteredCompaniesData$ = this.form.get('CompanySlug').valueChanges.pipe(
      startWith(''),
      map(value => this.filterCompanies(value)),
    );
  }

  displayCompanyProperty(company: ICompany) {
    if (company) {
      return company.Name;
    }
  }

  onTeamChange(event: MatAutocompleteSelectedEvent) {
    this.selectedTeam = event.option.value;
  }

  filterTeamsAutocompleteValues() {
    this.filteredTeamsData$ = this.form.get('OwnerTeamId').valueChanges.pipe(
      startWith(''),
      map(value => this.filterTeams(value)),
    );
  }

  displayTeamProperty(team: TeamInterface) {
    if (team) {
      return team.Name;
    }
  }

  getScopeTooltipText(): string {
    const currentScope = this.form.get('Scope').value;

    if (currentScope === Scope.CompanyScope) {
      return this.translationMap.get('companyScopeTooltip');
    } else if (currentScope === Scope.TeamPublicScope) {
      return this.translationMap.get('teamPublicScopeTooltip');
    } else {
      return this.translationMap.get('teamPrivateScopeTooltip');
    }
  }

  private initForm() {
    this.form = this.formBuilder.group({
      CompanySlug: [null, [Validators.required]],
      OwnerTeamId: [],
      Scope: [],
    });
  }

  private patchValuesWhileCreate() {
    this.form.get('CompanySlug').setValue(this.selectedCompany);

    this.form.get('Scope').setValue(Scope.CompanyScope);
  }

  private patchValuesWhileUpdate() {
    if (this.selectedItem && this.selectedItem.OwnerTeamId !== null) {
      this.setOwnerTeamId();
    }

    this.form.get('Scope').setValue(this.selectedItem.Scope, { emitEvent: false });

    this.isShowTeamField = this.selectedItem.Scope !== Scope.CompanyScope;

    this.isPrivateVisibilityDisabled = this.selectedItem.Scope !== Scope.TeamPrivateScope;
  }

  private getCompaniesListForSuperAdmin() {
    this.companiesService
      .list()
      .pipe(
        tap((companies: ICompany[]) => {
          const companiesTrimmed = companies.map(company => {
            return { ...company, Name: company.Name.trim() };
          });

          const existingCompanies = companiesTrimmed.filter(company => !company.IsArchived);
          this.companies = existingCompanies.sort((a, b) => (a.Name < b.Name ? -1 : 1));

          if (this.isUpdateMode && this.selectedItem) {
            const selectedCompany = this.companies.find(company => company.Slug === this.selectedItem.CompanySlug);

            this.selectedCompany = {
              Slug: this.selectedItem.CompanySlug,
              Name: selectedCompany.Name,
            };

            this.form.get('CompanySlug').setValue(this.selectedCompany);

            this.getTeamsListForCompanyAndSuperAdmins();

            this.onChangePermissionsData();
          }

          this.filterCompaniesAutocompleteValues();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private getCompany() {
    this.form.get('CompanySlug').disable();

    this.companiesService.get(this.identityService.companySlug)
      .pipe(
        tap(company => {
          this.selectedCompany = company;

          this.form.get('CompanySlug').setValue(this.selectedCompany, { emitEvent: !this.isUpdateMode });

          this.getTeamsListForCompanyAndSuperAdmins();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private onChangeScope() {
    const initialScopeValue = this.form.get('Scope').value;

    this.form
      .get('Scope')
      .valueChanges.pipe(
      tap(scope => {
        this.isShowTeamField = scope === Scope.TeamPublicScope || scope === Scope.TeamPrivateScope;

        if (this.isShowTeamField && this.isUpdateMode) {
          this.setOwnerTeamId();
        }

        if (scope === Scope.CompanyScope) {
          this.form.get('OwnerTeamId').setValue(null);
        }

        this.isShowWarningAboutSetPublic = initialScopeValue === Scope.TeamPrivateScope && scope !== Scope.TeamPrivateScope;
      }),
      takeUntil(this.destroy$),
    )
      .subscribe();
  }

  private setOwnerTeamId() {
    if (this.selectedItem.OwnerTeamId && this.selectedItem.OwnerTeamName) {
      this.selectedTeam = { Id: this.selectedItem.OwnerTeamId, Name: this.selectedItem.OwnerTeamName };
    }

    if (this.teams.length !== 0) {
      this.selectedTeam = this.teams.find(team => team.Id === this.selectedItem.OwnerTeamId);
    }

    this.form.get('OwnerTeamId').setValue(this.selectedTeam, { emitEvent: false });
  }

  private getTeamsListForCompanyAndSuperAdmins() {
    if (this.selectedCompany) {
      this.getSortedTeams$(this.selectedCompany.Slug).pipe(takeUntil(this.destroy$)).subscribe();
    }
  }

  private getSortedTeams$(companySlug: string): Observable<TeamInterface[]> {
    return this.teamsService.getTeams(companySlug).pipe(
      tap((teams: TeamInterface[]) => {
        const teamsTrimmed = teams.map(team => {
          return { ...team, Name: team.Name.trim() };
        });

        const existingTeams = teamsTrimmed.filter(team => !team.IsArchived);
        this.teams = existingTeams.sort((a, b) => (a.Name < b.Name ? -1 : 1));
        this.filterTeamsAutocompleteValues();

        if (!this.form.get('OwnerTeamId').value) {
          this.setOwnerTeamId();
        }
      }),
    ) as Observable<TeamInterface[]>;
  }

  private filterCompanies(value: string): Array<ICompany> {
    if (typeof value !== 'string') {
      return;
    }

    const filterValue = value.toLowerCase();
    this.form.get('CompanySlug').setErrors({ required: true });

    return this.companies.filter(option => option.Name.toLowerCase().includes(filterValue));
  }

  private filterTeams(value: string): Array<TeamInterface> {
    if (typeof value !== 'string') {
      return;
    }

    const filterValue = value.toLowerCase();
    return this.teams.filter(option => option.Name.toLowerCase().includes(filterValue));
  }

  private onChangePermissionsData() {
    const formData = this.form.getRawValue();

    const formCompany = formData.CompanySlug;

    const formOwnerTeam = formData?.OwnerTeamId;

    let permissionsData = { ...formData };

    if (formCompany !== null) {
      permissionsData = { ...formData, CompanySlug: formCompany.Slug !== null ? formCompany.Slug : null };
    }

    if (formCompany !== null && formOwnerTeam) {
      permissionsData = { ...formData, CompanySlug: formCompany.Slug, OwnerTeamId: formOwnerTeam.Id };
    }

    this.changePermissionsData.emit(permissionsData);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
