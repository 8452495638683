import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { SourceConfigurationListComponent } from '../components/source-configuration-list/source-configuration-list.component';
import { PromptService } from '../../../core/prompt/prompt.service';
import { TranslateService } from '@ngx-translate/core';

export const SaveFormsGuard: CanDeactivateFn<SourceConfigurationListComponent> = (
  component: SourceConfigurationListComponent,
  currentRoute: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState: RouterStateSnapshot,
  promptService = inject(PromptService),
  translateService = inject(TranslateService)
): boolean => {
  if (component.isChanged) {
    translateService.get('sources.configuration.changesWillBeLost').subscribe(res => {
      return promptService.prompt(res);
    });
  }
  return true;
}
