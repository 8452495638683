// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy-from-templates-wrapper {
  width: 100%;
  height: 100%;
}
.copy-from-templates-wrapper .no-templates {
  height: 400px;
  display: flex;
}
.copy-from-templates-wrapper .no-templates h2 {
  margin: auto;
  font-family: Roboto Condensed, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/sources/components/source-configuration-list/components/copy-config-from/copy-config-from-list/copy-from-templates/copy-from-templates.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;EACA,aAAA;AACJ;AACI;EACE,YAAA;EACA,yCAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;AACN","sourcesContent":[".copy-from-templates-wrapper {\n  width: 100%;\n  height: 100%;\n\n  .no-templates {\n    height: 400px;\n    display: flex;\n\n    h2 {\n      margin: auto;\n      font-family: Roboto Condensed, sans-serif;\n      font-style: normal;\n      font-weight: bold;\n      font-size: 24px;\n      line-height: 28px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
