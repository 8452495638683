import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LocationMapData } from '../../models/location-map-data';
import { DEFAULT_MAP_OPTIONS } from '../../../../shared/models/default-map-options';
import { MAP_LOADER } from '../../../../shared/google-map-load';
import { Observable } from 'rxjs';
import MarkerOptions = google.maps.MarkerOptions;
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'mee-check-location-map',
  templateUrl: './check-location-map.component.html',
  styleUrls: ['./check-location-map.component.scss']
})
export class CheckLocationMapComponent {
  @ViewChild('locationInformation') locationInformation: ElementRef<HTMLDivElement>;
  mapOptions: google.maps.MapOptions;
  markerOptions: MarkerOptions = {
    position: { lat: this.data.location.Latitude, lng: this.data.location.Longitude },
    icon: {
      url: '/assets/img/icons/location-marker.svg',
      scaledSize: {
        width: 48,
        height: 48,
      } as google.maps.Size,
    },
  }
  isFullScreen = false;

  @ViewChild(GoogleMap, { static: false }) set googleMap(googleMap: GoogleMap) {
    if (googleMap) {
      this.mapReady(googleMap.googleMap);
    }
  };


  constructor(
    @Inject(MAP_LOADER) public mapLoaded$: Observable<typeof google>,
    @Inject(MAT_DIALOG_DATA) public readonly data: LocationMapData
  ) {
    this.mapOptions = {
      ...DEFAULT_MAP_OPTIONS,
      zoom: 15,
      disableDefaultUI: true,
      fullscreenControl: true,
      center: {
        lat: data.location.Latitude,
        lng: data.location.Longitude
      }
    }
  }

  onFullScreenChange(): void {
    this.isFullScreen = !this.isFullScreen;
  }

  mapReady(map: google.maps.Map): void {
    const locationInformation = this.locationInformation.nativeElement;
    locationInformation.style.display = 'flex';

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(locationInformation);
  }
}
