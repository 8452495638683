import { Component, Input } from '@angular/core';
import { ProcessStatus } from '../../interfaces/process-status.enum';

@Component({
  selector: 'mee-process-status',
  templateUrl: './process-status.component.html',
  styleUrls: ['./process-status.component.scss'],
})
export class ProcessStatusComponent {
  @Input() currentProcessStatus: ProcessStatus;

  processStatus = ProcessStatus;
}
