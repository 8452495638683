import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ISource } from '../../sources/models/source.interface';
import { EMPTY, Observable, Subject } from 'rxjs';
import { DataSourceType } from '../../sources/data-source-type.enum';
import { ICompany } from '../../companies/shared/interfaces/company.interface';
import { IdentityService } from '../../../services/identity.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSourcesService } from '../../sources/services/data-sources.service';
import { CompaniesService } from '../../companies/companies.service';
import { TranslationHandleService } from '../../../core/translate/translation-handle.service';
import { DOCUMENT } from '@angular/common';
import { catchError, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { identifierMaxLength, lockInvalidControl, slugPattern } from '../../../core/utils/validators';
import * as enums from '../../sources/arcgis-security-types.enum';
import { ArcGisSecurityTypes } from '../../sources/arcgis-security-types.enum';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  DataSourcesAdapterService
} from '../../sources/components/source-configuration-list/components/configuration-tab/data-sources-adapter.service';
import { SecurityType } from '../../sources/components/source-configuration-list/components/configuration-tab/interfaces/security-type';
import {
  BasicSecurityTypes
} from '../../sources/components/source-configuration-list/components/configuration-tab/enums/basic-security-types.enum';
import { PermissionFields } from '../../companies/shared/interfaces/permission-fields';
import { SourcesRelatedDataService } from '../../sources/services/sources-related-data.service';
import { CreateCategoryData } from '../../../shared/interfaces/create-category-data';
import { snackBarConfig } from '../../../core/core.module';
import { CategoriesService } from '../../company/services/categories.service';
import { DATA_SOURCE_TYPES_CREATE_FORM } from '../../../shared/models/data-source-types-create-form';
import { SourceBaseFile } from '../../sources/components/source-form/simple-model-file-loader/source-base-file';
import { IMediaFile } from '../../../core/uploader/media-file.interface';
import { AutofillFieldService } from '../../../services/autofill-field.service';
import { SourceTemplate } from '../../components/source-templates/source-template';

@Component({
  selector: 'mee-add-data-source',
  templateUrl: './add-data-source.component.html',
  styleUrls: ['./add-data-source.component.scss'],
})
export class AddDataSourceComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  source: ISource;
  arcGisSecurityTypes: SecurityType<ArcGisSecurityTypes>[];
  wfsSecurityTypes: SecurityType<BasicSecurityTypes>[];
  types = DATA_SOURCE_TYPES_CREATE_FORM;
  integrationTypes = [];
  companies: Array<ICompany>;
  translationMap: Map<string, string>;
  previewLinkName: string;
  editModeCategoryName: string;
  selectedType: string;
  typesFiltered: Observable<any[]>;
  templatesList: SourceTemplate[] = [];

  simpleModelExistingFiles = [];
  readonly identifierMaxLength = identifierMaxLength;
  private permissionsData: PermissionFields;

  private isCreateCategory: boolean;
  private createCategoryData: CreateCategoryData;

  private readonly destroy$ = new Subject<void>();

  get isIdentifier(): boolean {
    const identifierControl = this.form.get('Slug');

    return identifierControl && Boolean(identifierControl.value.length);
  }

  get type(): AbstractControl {
    return this.form.get('Type');
  }

  get isSuperAdmin() {
    return this.identityService.isSuperAdmin();
  }

  get isCompanyAdmin() {
    return this.identityService.isCompanyAdmin();
  }

  get dataSourceFile(): SourceBaseFile {
    return this.form.get('File').value;
  }

  get mediaType(): string {
    return this.isKmlType() ? 'maps' : 'datasourcefile';
  }

  get isSetTemplateAvailable(): boolean {
    return this.selectedType && this.selectedType !== DataSourceType.Bim360 && this.selectedType !== DataSourceType.Simple3dModel;
  }

  get isShowFileLoader(): boolean {
    return this.isKmlType() || this.isNativeBimType() || this.isLandXmlType() || this.isShapefileType() || this.isNativeGisType();
  }

  constructor(
    private identityService: IdentityService,
    protected formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private dataSourcesService: DataSourcesService,
    private sourcesRelatedDataService: SourcesRelatedDataService,
    private dataSourcesAdapterService: DataSourcesAdapterService,
    private companiesService: CompaniesService,
    private translationHandleService: TranslationHandleService,
    private readonly matDialogRef: MatDialogRef<AddDataSourceComponent>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly autofillFieldService: AutofillFieldService,
    private categoriesService: CategoriesService,
    @Inject(DOCUMENT) private document,
  ) {
    this.translationHandleService.setPhrasesForTranslate([
      {
        sourceWasUpdated: 'sources.update',
      },
      {
        sourceWasCreated: 'sources.create',
      },
      {
        mapFileLink: 'sources.mapFileLink',
      },
      {
        public: 'sources.details.Public',
      },
      {
        password: 'sources.details.Password',
      },
      {
        token: 'sources.details.Token',
      },
      {
        ntlm: 'sources.details.Ntlm',
      },
      {
        fullIntegrationType: 'sources.details.fullIntegrationType',
      },
      {
        infrastructureIntegrationType: 'sources.details.infrastructureIntegrationType',
      },
      {
        dMeshIntegrationType: 'sources.details.3dMeshIntegrationType',
      },
    ]);

    this.createForm();

    this.translationHandleService
      .getTranslationMap()
      .pipe(takeUntil(this.destroy$))
      .subscribe(translationMap => {
        this.translationMap = translationMap;
      });
    this.integrationTypes = [
      {
        value: 0,
        title: this.translationMap.get('fullIntegrationType'),
      },
      {
        value: 1,
        title: this.translationMap.get('infrastructureIntegrationType'),
      },
      {
        value: 2,
        title: this.translationMap.get('dMeshIntegrationType'),
      },
    ];

    this.sortDSTypesAlphabetically();
  }

  ngOnInit() {
    this.initArcGisSecurityTypes();
    this.initWFSData();
    this.previewLinkName = this.translationMap.get('mapFileLink');
    this.filterTypesValues();

    this.types = this.types.filter(data => data.type !== DataSourceType.RealityCapture);
    this.setAutoFillValue();
  }

  filterTypesValues(): void {
    this.typesFiltered = this.type.valueChanges.pipe(
      tap(() => this.type.setErrors({ required: true })),
      startWith(''),
      map((value: string) => this.typesFilter(value)),
      takeUntil(this.destroy$),
    );
  }

  private typesFilter(value: string): { type: DataSourceType; title: string }[] {
    if (typeof value !== 'string') {
      return;
    }

    const filterValue = value.toLowerCase();
    return this.types.filter(type => type.title.toLowerCase().includes(filterValue));
  }

  displayTypeProperty(type: string): string {
    if (!type || !this.types) {
      return '';
    }

    return this.types.find(foundType => foundType.type === type).title;
  }

  setTypeValue(event: MatAutocompleteSelectedEvent): void {
    const type = event.option.value;

    if (type) {
      this.form.get('TemplateId').reset();

      this.getTemplates(type);
      this.type.setErrors(null);
    }
  }

  initWFSData() {
    this.wfsSecurityTypes = this.dataSourcesAdapterService.getBasicSecurityTypes();
  }

  isRequiredError(formControlName: string): boolean {
    const control = this.form.get(formControlName);

    return control.errors && control.errors.required && (control.dirty || control.touched);
  }

  initArcGisSecurityTypes() {
    this.arcGisSecurityTypes = this.dataSourcesAdapterService.getArcgisSecurityTypes();
  }

  createForm(): void {
    this.form = this.formBuilder.group(
      {
        Slug: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(slugPattern)]],
        Type: ['', [Validators.required]],
        Description: [''],
        File: this.formBuilder.group({
          FileUrl: [],
          FileName: [],
          Format: [],
          Title: [],
        }),
        TemplateId: [''],
        ProjectName: [''],
        ModelName: [''],
        FeatureServiceUrl: [''],
        SceneServiceUrl: [''],
        IsArcGisOnline: [false],
        IsRealTime: [false],
        Security: [''],
        AuthenticationUrl: [''],
        Username: [''],
        Password: [''],
        Token: [''],
        PortalUrl: [''],
        PortalItemId: [''],
        BaseUrl: [''],
        CategoryName: [''],
        NewFiles: [[]],
        Files: [[]],
        ClientId: [''],
        ClientSecret: [''],
        MapParsingRules: [''],
        ProjectId: [''],
      },
      {
        validator: group => {
          switch (group.controls.Type.value) {
            case DataSourceType.Simple3dModel:
              return Validators.required(group.controls.NewFiles);
            default:
              return null;
          }
        },
      },
    );

    lockInvalidControl(this.form.controls.Slug);
  }

  getAdaptedWFSData(value) {
    let updatedData = { ...value };

    if (this.isWFSPassword()) {
      updatedData = {
        ...updatedData,
        AuthCredentials: {
          Username: value.Username,
          Password: value.Password,
        },
      };
    }

    updatedData = { ...updatedData, IsRealTime: true };

    return updatedData;
  }

  onSubmit({ value, valid }: UntypedFormGroup): void {
    if (!valid) {
      return;
    }

    let data = this.isWFSType() ? this.getAdaptedWFSData(value) : value

    data = { ...data, ...this.permissionsData };

    if (this.isCreateCategory) {
      this.categoriesService
        .createCategory(this.identityService.companySlug, this.createCategoryData.categoryThatChosen.Name)
        .pipe(
          switchMap(newCategoryData => {
            data = { ...data, Category: newCategoryData };

            return this.dataSourcesService.createSource(data.CompanySlug, data);
          }),
          tap((dataSource: ISource) => this.handleActionsAfterCreateSource(dataSource)),
          catchError(error => {
            this.snackBar.open(error.error.Message, 'OK', snackBarConfig);

            return EMPTY;
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();

      return;
    }

    this.dataSourcesService
      .createSource(data.CompanySlug, data)
      .pipe(
        tap(dataSource => this.handleActionsAfterCreateSource(dataSource)),
        catchError(error => {
          this.snackBar.open(error.error.Message, 'OK', snackBarConfig);

          return EMPTY;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  handleActionsAfterCreateSource(newDataSource: ISource) {
    if (this.isTeamAdmin()) {
      this.sourcesRelatedDataService.setNewlyAddedSourceForTeamAdmin(newDataSource);
    }

    this.snackBar.open(this.translationMap.get('sourceWasCreated'), 'OK', snackBarConfig);

    if (this.isBim360Type() || this.isPix4dType()) {
      this.router.navigate(['/sources', newDataSource.CompanySlug, newDataSource.Slug, 'config'], { queryParams: { tab: 'General' } });

      this.matDialogRef.close(false);

      return;
    }

    this.matDialogRef.close(true);
  }

  onCategoryNameChanged(categoryName: string) {
    this.form.get('CategoryName').setValue(categoryName);
  }

  categoryCreateDataChanged(data: CreateCategoryData) {
    this.isCreateCategory = data.isCreateCategory;
    this.createCategoryData = data;
  }

  onChangePermissionsData(permissionsData: PermissionFields) {
    this.permissionsData = permissionsData;
  }

  uploadFile(file: IMediaFile) {
    this.form.get('File').patchValue(file.sourceFile);
  }

  onRemoveRequiredFile(formControlName: string) {
    this.form.get(formControlName).reset();
    this.form.get(formControlName).setValidators([Validators.required]);
  }

  onSimpleModelNewFilesAdded(files: SourceBaseFile[]) {
    this.form.get('NewFiles').setValue(files);
  }

  cancel() {
    this.matDialogRef.close();
  }

  sortDSTypesAlphabetically() {
    this.types = this.types.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    });
  }

  isKmlType() {
    return this.dataSourcesAdapterService.isKmlType(this.type.value);
  }

  isArcGisType() {
    return this.dataSourcesAdapterService.isArcGisFeatureType(this.type.value);
  }

  isLandXmlType() {
    return this.dataSourcesAdapterService.isLandXmlType(this.form.get('Type').value);
  }

  isIModelType() {
    return this.dataSourcesAdapterService.isIModelType(this.form.get('Type').value);
  }

  isIModelGisType() {
    return this.dataSourcesAdapterService.isIModelGisDataType(this.form.get('Type').value);
  }

  isWFSType() {
    return this.dataSourcesAdapterService.isWFSType(this.form.get('Type').value);
  }

  isArcGisSceneType() {
    return this.dataSourcesAdapterService.isArcGisSceneType(this.form.get('Type').value);
  }

  isBim360Type() {
    return this.dataSourcesAdapterService.isBim360Type(this.form.get('Type').value);
  }

  isPix4dType() {
    return this.dataSourcesAdapterService.isPix4dType(this.form.get('Type').value);
  }

  isNativeBimType() {
    return this.dataSourcesAdapterService.isNativeBimType(this.form.get('Type').value);
  }

  isNativeGisType() {
    return this.dataSourcesAdapterService.isNativeGisType(this.form.get('Type').value);
  }

  isShapefileType() {
    return this.dataSourcesAdapterService.isShapefileType(this.form.get('Type').value);
  }

  clearSelection() {
    if (this.document.window && this.document.window.getSelection && this.document.window.getSelection().empty) {
      this.document.window.getSelection().empty();
    }
  }

  isArcGisPassword() {
    return (
      (this.type.value === DataSourceType.ArcGisFeatureService || this.type.value === DataSourceType.ArcGisSceneService) &&
      this.form.get('Security').value === enums.ArcGisSecurityTypes.Password
    );
  }

  isArcGisToken() {
    return (
      (this.type.value === DataSourceType.ArcGisFeatureService || this.type.value === DataSourceType.ArcGisSceneService) &&
      this.form.get('Security').value === enums.ArcGisSecurityTypes.Token
    );
  }

  isArcGisNtlm() {
    return (
      (this.type.value === DataSourceType.ArcGisFeatureService || this.type.value === DataSourceType.ArcGisSceneService) &&
      this.form.get('Security').value === enums.ArcGisSecurityTypes.Ntlm
    );
  }

  isWFSPassword() {
    return this.type.value === DataSourceType.Wfs && this.form.get('Security').value === enums.ArcGisSecurityTypes.Password;
  }

  isSimpleModelType() {
    return this.dataSourcesAdapterService.isSimpleModelType(this.type.value);
  }

  isTeamAdmin(): boolean {
    return this.identityService.isTeamAdmin();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.translationHandleService.cleanTranslationArray();
  }

  private getTemplates(type: string): void {
    this.selectedType = type;

    this.dataSourcesService.getTemplatesByType(type).pipe(
      tap(templates => this.templatesList = templates),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  private setAutoFillValue(): void {
    const descriptionControl = this.form.get('Description');
    const identifierControl = this.form.get('Slug');

    this.autofillFieldService.setAutoFillValue$(descriptionControl, identifierControl).pipe(takeUntil(this.destroy$)).subscribe();
  }
}
