import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { IntegrationStatus } from '../../../models/integration-status';
import { environment } from '../../../../../../environments/environment';
import { ConnectBim360ProjectComponent } from './connect-bim360-project/connect-bim360-project.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConnectBim360ProjectData } from './connect-bim360-project/connect-bim360-project-data';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DataSourcesService } from '../../../services/data-sources.service';
import { ConfirmationDialogComponent } from '../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from '../../../../../interfaces/confirmation-dialog-data';

@Component({
  selector: 'mee-autodesk-account',
  templateUrl: './autodesk-account.component.html',
  styleUrls: ['./autodesk-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutodeskAccountComponent implements OnDestroy {

  @Input() integrationStatus: IntegrationStatus;

  @Input() companySlug: string;

  @Input() sourceSlug: string;

  @Input() sourceId: string;

  @Output() signOutFromAutodesk = new EventEmitter<void>();

  destroy$ = new Subject<void>();

  constructor(private matDialog: MatDialog,
              private dataSourcesService: DataSourcesService) {
  }

  get autodeskSignInHref() {
    const integrationStatus = this.integrationStatus;

    const mainHref = 'https://developer.api.autodesk.com/authentication/v2/authorize?response_type=code';

    const clientId = `client_id=${integrationStatus.ClientId}`;

    const url = environment.adminApi + integrationStatus.CallbackUrl;

    const encodedUrl = encodeURIComponent(url);

    const redirect_uri = `redirect_uri=${encodedUrl}`;

    const scopes = `scope=${integrationStatus.Scopes.join(' ')}`;

    const state = `state=${this.sourceId.replace(new RegExp('-', 'g'), '')}`

    return mainHref.concat('&', clientId, '&', redirect_uri, '&', scopes, '&', state);
  }

  connectBim360Info() {
    this.matDialog.open<ConnectBim360ProjectComponent, ConnectBim360ProjectData, void>(ConnectBim360ProjectComponent, {
      width: '544px',
      panelClass: 'connect-bim-360-project',
      data: { clientId: this.integrationStatus.ClientId, appName: this.integrationStatus.AppName },
    });
  }

  signOut() {
    this.matDialog.open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>
    (ConfirmationDialogComponent, {
      width: '400px',
      height: '188px',
      data: {subtitleKey: 'sources.autodeskAccount.confirmSignOut'}
    })
      .afterClosed()
      .pipe(
        filter(isConfirmAction => !!isConfirmAction),
        switchMap(() => this.dataSourcesService.signOutBim360(this.companySlug, this.sourceSlug)),
        tap(() => this.signOutFromAutodesk.emit()),
        takeUntil(this.destroy$),
      ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
