import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DataSourcesService } from '../../../services/data-sources.service';
import { TeamProjectService } from '../../../../../services/team-project.service';
import { ConfigurationStructure } from '../components/configuration-tab/interfaces/configuration-structure';

export const SourceConfigurationResolver: ResolveFn<ConfigurationStructure> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  dataSourcesService = inject(DataSourcesService),
  teamProjectService = inject(TeamProjectService)
): Observable<ConfigurationStructure> => {
  const companySlug = route.paramMap.get('companySlug');
  const sourceSlug = route.paramMap.get('sourceSlug');
  teamProjectService.companySlugFromRoute = companySlug;

  return dataSourcesService.configuration(companySlug, sourceSlug);
}
