import { Injectable } from '@angular/core';
import { filter, pairwise } from 'rxjs/operators';
import { Router, RouterEvent, RoutesRecognized } from '@angular/router';

@Injectable()

export class RouterPrevService {

  private previousRoute: string;

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(e => e instanceof RoutesRecognized),
      pairwise()
    ).subscribe((value) => {
        const routerEvent = value[0] as RouterEvent;

        this.previousRoute = routerEvent.url;
      }
    );
  }

  getPreviousUrl() {
    return this.previousRoute;
  }


}
