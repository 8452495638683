import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { fromEvent, merge, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[meeMisclick]',
})
export class MisclickDirective implements AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  @Input() readonly trackSelectors: string[] = [];
  @Output() readonly isInClick = new EventEmitter();

  constructor(private readonly elementRef: ElementRef, @Inject(DOCUMENT) private readonly document: Document) {}

  ngAfterViewInit() {
    const click$ = fromEvent(this.document, 'click');
    const touchStart$ = fromEvent(this.document, 'touchstart');
    const touchEnd$ = fromEvent(this.document, 'touchend');
    const touchMove$ = fromEvent(this.document, 'touchmove');

    const touch$ = touchStart$.pipe(switchMap(() => touchEnd$.pipe(takeUntil(touchMove$))));

    merge(click$, touch$)
      .pipe(
        map(({ target }: any) => {
          const isSomeTrackSelector = this.trackSelectors
            .map(selector => this.document.querySelector(selector))
            .filter(Boolean)
            .some(element => element.contains(target));
          const isInElement = this.elementRef.nativeElement.contains(target);

          return isSomeTrackSelector || isInElement;
        }),
        tap(isInside => this.isInClick.emit(isInside)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
