// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.company-permissions {
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  gap: 32px;
}
.company-permissions .company-assets {
  display: flex;
  gap: 24px;
  align-items: center;
}
.company-permissions h4 {
  font-family: Roboto Condensed, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  color: #000000;
  margin: 0;
}
.company-permissions .permission-crud .company-permission-title {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/company-permissions/company-permissions.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,+BAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AACJ;AAEE;EACE,yCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;EACA,cAAA;EACA,SAAA;AAAJ;AAII;EACE,gBAAA;AAFN","sourcesContent":[".company-permissions {\n  display: flex;\n  flex-direction: column;\n  font-family: Roboto, sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 16px;\n  line-height: 19px;\n  gap: 32px;\n\n  .company-assets {\n    display: flex;\n    gap: 24px;\n    align-items: center;\n  }\n\n  h4 {\n    font-family: Roboto Condensed, sans-serif;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 20px;\n    text-transform: uppercase;\n    color: #000000;\n    margin: 0;\n  }\n\n  .permission-crud {\n    .company-permission-title {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
