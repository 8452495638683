import { Injectable } from '@angular/core';
import { NgxZendeskWebwidgetService } from '@nitsanzo/ngx-zendesk-webwidget';
import { ZendeskCommands } from '../shared/enums/zendesk-commands';
import { User } from '../modules/users/models/user';

@Injectable({
  providedIn: 'root'
})
export class ZendeskService {

  constructor(private readonly ngxZendeskWebWidgetService: NgxZendeskWebwidgetService) {
  }

  initWidget(): Promise<boolean> {
    return this.ngxZendeskWebWidgetService.initZendesk();
  }

  setContactFormData(user: User | null): void {
    if (!this.ngxZendeskWebWidgetService.isInitialized) {
      return;
    }

    if (user === null) {
      this.ngxZendeskWebWidgetService.zE(ZendeskCommands.WidgetType, ZendeskCommands.Reset);

      return;
    }

    const data = {name: {value: `${user.FirstName} ${user.LastName}`}, email: {value: user.Email}};

    this.ngxZendeskWebWidgetService.zE(ZendeskCommands.WidgetType, ZendeskCommands.Prefill, data);
  }

  /*Need for future feat. On some pages the button will be hide*/

  hideWidget(): void {
    if (!this.ngxZendeskWebWidgetService.isInitialized) {
      return;
    }

    this.ngxZendeskWebWidgetService.zE(ZendeskCommands.WidgetType, ZendeskCommands.Hide);
  }

  showWidget(): void {
    const widgetState = this.ngxZendeskWebWidgetService.zE(ZendeskCommands.WidgetGet, ZendeskCommands.Display);

    if (!this.ngxZendeskWebWidgetService.isInitialized || widgetState !== 'hidden') {
      return;
    }

    this.ngxZendeskWebWidgetService.zE(ZendeskCommands.WidgetType, ZendeskCommands.Show);
  }

  /*Need for localization in future*/

  setWidgetLanguage(language: string): void {
    if (!this.ngxZendeskWebWidgetService.isInitialized) {
      return;
    }

    this.ngxZendeskWebWidgetService.zE(ZendeskCommands.WidgetType, ZendeskCommands.SetLocale, language);
  }
}
