import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { IMediaFile } from './media-file.interface';
import { environment } from '../../../environments/environment';
import { ImageCropComponent } from '../infocard-images/image-crop/image-crop.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SourceBaseFile } from '../../modules/sources/components/source-form/simple-model-file-loader/source-base-file';
import { UploaderType } from './uploader-type';
import { UploadOutputType } from './upload-output-type';
import { UploadOutput } from './upload-output';
import { UploadFile } from './upload-file';
import { UploadInput } from './upload-input';
@Directive()
export class MediaUploader {
  /**
   * Type of uploader component
   */
  type: UploaderType;

  @Input() mediaType: string;

  files: UploadFile[] = [];

  error: string;
  /**
   * Upload input
   * @type {EventEmitter<UploadInput>}
   */
  uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();

  /**
   * Is drag over
   */
  dragOver: boolean;

  isReplaceExistingFile: boolean;

  replacedFileId: string;

  /**
   * Multiple files
   * @type {boolean}
   */
  @Input() multiple = false;

  /**
   * Preview image
   */
  @Input() preview: string;

  /**
   * Process uploading
   * @type {boolean}
   */
  process = false;

  /**
   * Event on uploaded
   * @type {EventEmitter<IMediaFile>}
   */
  @Output() uploaded: EventEmitter<IMediaFile> = new EventEmitter<IMediaFile>();
  @Output() uploadingStatus = new EventEmitter<UploadOutputType>();

  /**
   * Media uploader
   */
  constructor(protected dialog: MatDialog) {
  }

  /**
   * Listener of all events
   * @param {UploadOutput} output
   */
  onUploadOutput(output: UploadOutput): void {
    this.error = null;

    this.uploadingStatus.emit(output.type);

    switch (output.type) {
      /**
       * Action done
       */
      case UploadOutputType.Done:
        this.onDone(output);
        break;

      /**
       * Action drag out
       */
      case UploadOutputType.DragOut:
        this.onDragOut();
        break;

      /**
       * Action drop
       */
      case UploadOutputType.Drop:
        this.onDrop();
        break;

      /**
       * Action drag over
       */
      case UploadOutputType.DragOver:
        this.onDragOver();
        break;

      /**
       * Action removed
       */
      case UploadOutputType.Removed:
        this.onRemoved(output);
        break;

      /**
       * Action uploading
       */
      case UploadOutputType.Uploading:
        this.onUploading(output);
        break;

      /**
       * Action added to queue
       */
      case UploadOutputType.AddedToQueue:
        this.onAddedToQueue(output);
        break;

      /**
       * Action all added to queue
       */
      case UploadOutputType.AllAddedToQueue:
        this.onAllAddedToQueue();
        break;
    }
  }

  /**
   * Set preview
   * @param file
   */
  setPreview(file: SourceBaseFile | null): void {
    if (!file) {
      this.preview = null;
      this.uploaded.emit({ sourceFile: { ...file, FileUrl: null } });

      return;
    }

    this.preview = file.FileUrl;
    this.uploaded.emit({ sourceFile: file });
  }

  startUpload(): void {
    if (!this.files.length) {
      return;
    }

    if (this.mediaType === 'TeamsLogo' || this.mediaType === 'CompaniesLogo') {
      this.dialog
        .open(ImageCropComponent, {
          data: this.files[0]['nativeFile'],
        })
        .afterClosed()
        .subscribe((confirm: any) => {
          if (!confirm) {
            this.process = false;

            return;
          }

          const formData = new FormData();
          formData.append('file', confirm, 'index.png');

          this.files[0]['nativeFile'] = formData.get('file');

          this.onUploadInput();
        });
    } else {
      this.onUploadInput();
    }
  }

  onUploadInput(): void {
    this.process = true;
    const event: UploadInput = {
      type: 'uploadAll',
      url: `${environment.api}/media/${this.mediaType}`,
      method: 'POST',
      // set sequential uploaded of files with concurrency 1
      concurrency: 1,
    };

    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });

    this.files = this.files.filter(file => file.id !== id);
  }

  onDone(output: UploadOutput): void {
    if (!output.file.response) {
      this.uploaded.error(output.file);
      this.error = 'system.serverError';
    } else {
      if (this.isReplaceExistingFile) {
        output.file.replacedFileId = this.replacedFileId;
      }

      const file: SourceBaseFile = {
        FileUrl: output.file.response,
        FileName: output.file.name,
        Title: output.file.name,
      }

      this.setPreview(file);
    }
    this.process = false;
  }

  onDragOut(): void {
    this.dragOver = false;
  }

  onDrop(): void {
    this.dragOver = false;
  }

  onDragOver(): void {
    this.dragOver = true;
  }

  /**
   * Listener: on removed
   * @param {UploadOutput} output
   */
  onRemoved(output: UploadOutput): void {
    this.files = this.files.filter((file: UploadFile) => file !== output.file);
  }

  /**
   * Listener: on uploading
   * @param {UploadOutput} output
   */
  onUploading(output: UploadOutput): void {
    const index = this.files.findIndex(file => file.id === output.file.id);
    this.files[index] = output.file;
  }

  /**
   * Listener: on added to queue
   * @param {UploadOutput} output
   */
  onAddedToQueue(output: UploadOutput): void {
    this.files.push(output.file);
  }

  onAllAddedToQueue(): void {
    this.startUpload();
  }
}
