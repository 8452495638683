import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { ConfigurationFieldsAdapterService } from '../configuration-fields-adapter.service';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { MatLegacySelect as MatSelect, MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfigurationInputWithMeasurement } from '../interfaces/configuration-input-with-measurement';
import { MeasurementSystem } from '../interfaces/measurement-system.enum';

@Component({
  selector: 'mee-configuration-compound-input-new',
  templateUrl: './configuration-compound-input.component.html',
  styleUrls: ['./configuration-compound-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConfigurationCompoundInputComponent),
      multi: true,
    },
  ],
})
export class ConfigurationCompoundInputComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @ViewChild('valueInput', { static: true }) valueInput: ElementRef;

  @ViewChild('valueSelect', { static: true }) valueSelect: MatSelect;

  @Input() helpTooltip: string;

  @Input() placeholder: string;

  measurementSystemOptions = this.configurationFieldsAdapterService.getMeasurementSystemOptions();

  isFormTouched = false;

  private initialData = {
    Value: null,
    Type: MeasurementSystem.Meters,
  };

  private data: ConfigurationInputWithMeasurement = this.initialData;

  private destroy$ = new Subject<void>();

  onChange: any = () => {};

  onTouch: any = () => {};

  set value(value: ConfigurationInputWithMeasurement | null) {
    const isValueEmptyObject = Object.keys(value).length === 0;

    const isOnlyUnitsOfMeasurementChanged = this.isFormTouched && isValueEmptyObject;

    if (isOnlyUnitsOfMeasurementChanged) {
      return;
    }

    this.valueInput.nativeElement.value = value.Value;

    this.valueSelect.writeValue(value.Type || MeasurementSystem.Meters);
  }

  get isValueEmpty() {
    return this.data.Value === null;
  }

  constructor(private configurationFieldsAdapterService: ConfigurationFieldsAdapterService) {}

  resetValue() {
    this.setData(null, this.data.Type);
  }

  ngAfterViewInit(): void {
    this.onChangeInputValue();
  }

  selectedUnitOfMeasurementChanged(event: MatSelectChange) {
    this.setData(this.data.Value, event.value);
  }

  private onChangeInputValue() {
    fromEvent(this.valueInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap((event: any) => {
          // for case with minus that is empty string for input type number

          const value = event.target.value;

          if (event.key === '-' || event.key === '.') {
            return;
          }

          if (!value) {
            this.onChange(null);
          }

          this.setData(value, this.data.Type);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.isFormTouched = true;
    this.onTouch = fn;
  }

  writeValue(data: ConfigurationInputWithMeasurement): void {
    if (data === null) {
      this.data = this.initialData;

      this.valueInput.nativeElement.value = null;

      this.valueSelect.writeValue(MeasurementSystem.Meters);

      return;
    }

    this.value = { ...data };

    this.data = { ...data };
  }

  private setData(value: string | null, type: MeasurementSystem) {
    this.data = {
      Value: value || null,
      Type: type || MeasurementSystem.Meters,
    };

    this.valueInput.nativeElement.value = this.data.Value;

    this.valueSelect.writeValue(this.data.Type);

    if (this.isValueEmpty) {
      this.onChange(null);
      return;
    }

    this.onChange(this.data);
  }
}
