// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-content {
  color: #FFFFFF;
  font-family: Roboto, Arial, sans-serif;
}
.text-content .device-name {
  font-weight: bold;
  font-size: 16px;
}
.text-content .date-comment-container {
  font-size: 12px;
}

video {
  width: auto;
  max-width: 80vw;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
}
video.hidden {
  z-index: -100;
}

.spinner-wrapper::ng-deep {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.spinner-wrapper::ng-deep .mat-progress-spinner circle, .spinner-wrapper::ng-deep .mat-spinner circle {
  stroke: white;
}

.overlay-content {
  padding: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/video-dialog/file-preview-overlay/file-preview-overlay.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,cAAA;EACA,sCAAA;AACF;AACE;EACE,iBAAA;EACA,eAAA;AACJ;AAEE;EACE,eAAA;AAAJ;;AAIA;EACE,WAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;AADF;AAGE;EACE,aAAA;AADJ;;AAKA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,WAAA;AAFF;AAIE;EACE,aAAA;AAFJ;;AAMA;EACE,YAAA;AAHF","sourcesContent":[":host {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.text-content {\n  color: #FFFFFF;\n  font-family: Roboto, Arial, sans-serif;\n\n  .device-name {\n    font-weight: bold;\n    font-size: 16px;\n  }\n\n  .date-comment-container {\n    font-size: 12px;\n  }\n}\n\nvideo {\n  width: auto;\n  max-width: 80vw;\n  height: auto;\n  max-height: 80vh;\n  object-fit: contain;\n\n  &.hidden {\n    z-index: -100;\n  }\n}\n\n.spinner-wrapper::ng-deep {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: -1;\n\n  .mat-progress-spinner circle, .mat-spinner circle {\n    stroke: white;\n  }\n}\n\n.overlay-content {\n  padding: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
