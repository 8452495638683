import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MediaUploader } from '../media-uploader';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UploaderType } from '../uploader-type';

@Component({
  selector: 'mee-image-upload-button',
  templateUrl: './image-upload-button.component.html',
  styleUrls: ['./image-upload-button.component.scss']
})
export class ImageUploadButtonComponent extends MediaUploader implements OnInit, OnDestroy {

  type = UploaderType.IMAGE;

  isHoverOnPreviewImage = false;

  @Input() title: string;
  @Input() hoverOnPreviewText: string;

  subs: Subscription[] = [];

  constructor(private translateService: TranslateService,
              dialog: MatDialog) {
    super(dialog);

    this.subs.push(
      this.translateService.get('system.selectImage').subscribe(res => {
        this.title = res;
      })
    );
  }

  ngOnInit() {
  }

  onDisplayUploadImageTitle() {
    this.isHoverOnPreviewImage = true;
  }

  onHideUploadImageTitle() {
    this.isHoverOnPreviewImage = false;
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub && sub.unsubscribe());
  }
}
