import { Pipe, PipeTransform } from '@angular/core';
import { MEASUREMENT_UNITS } from './models/measurement-units';

@Pipe({
  name: 'byteSizeTemplate'
})
export class ByteSizeTemplatePipe implements PipeTransform {

  transform(bytes: number, precision = 0): string {
    if (bytes === 0) {
      return this.getTemplate(0, 'B');
    }

    const baseSize = 1000;
    const measurementUnits = MEASUREMENT_UNITS;

    const unitIndex = Math.floor(Math.log(bytes) / Math.log(baseSize));
    const formattedSize = parseFloat((bytes / Math.pow(baseSize, unitIndex)).toFixed(precision));

    return this.getTemplate(formattedSize, measurementUnits[unitIndex]);
  }

  private getTemplate(formattedSize: number, measurementUnit: string): string {
    return `<span class="unit-size">${formattedSize}</span> <span class="measurement-unit">${measurementUnit}</span>`;
  }
}
