import { Component, OnInit } from '@angular/core';
import { TableBuilder } from '../../../../core/table/table-builder';
import { ITableColumn } from '../../../../core/table/interfaces/table-column.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessService } from '../process-list/process.service';
import { Report, ReportLayer } from '../../models/report.interface';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'mee-process-report',
  templateUrl: './process-report.component.html',
  styleUrls: ['./process-report.component.scss']
})
export class ProcessReportComponent implements OnInit {

  public tableBuilder: TableBuilder<ReportLayer[]>;

  report: Report;

  constructor(
    protected processService: ProcessService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.report = this.route.snapshot.data.reports;

    const columns: ITableColumn[] = [
      {
        key: 'LayerId',
        title: 'LayerId'
      },
      {
        key: 'LayerExternalId',
        title: 'LayerExternalId'
      },
      {
        key: 'LayerName',
        title: 'LayerName'
      },
      {
        key: 'Status',
        title: 'Status'
      },
      {
        key: 'PercentageComplete',
        title: 'PercentageComplete'
      },
      {
        key: 'TotalCount',
        title: 'TotalCount'
      },
      {
        key: 'IndexedCount',
        title: 'IndexedCount'
      },
      {
        key: 'IndexationFailedCount',
        title: 'IndexationFailedCount'
      },
      {
        key: 'ReadAndStoredCount',
        title: 'ReadAndStoredCount'
      },
      {
        key: 'FailedToReadAndStoreCount',
        title: 'FailedToReadAndStoreCount'
      },
    ];


    this.tableBuilder = new TableBuilder<any>({
      list: this.report.Layers,
    }, {
      columns: columns,
    });
  }

  ngOnInit() {
  }

  isProcessFinished(): boolean {
    return this.report.Status === 'Finished' || this.report.Status === 'Canceled' || this.report.Status === 'TimedOut';
  }

  onProcessCancel() {
    this.processService.cancelProcess(this.report.DataSourceId, this.report.ProcessId)
      .subscribe(() => alert('Processing is canceled'));
  }

  updateList() {
    this.processService.getProcessReport(this.report.ProcessId, this.report.CompanySlug, this.report.DataSourceSlug).pipe(
      tap((report: Report) => {
        this.report = report;
      })
    ).subscribe();
  }

}
