import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PromptComponent } from './prompt.component';

@Injectable()
export class PromptService {

  private dialogConfirm: MatDialogRef<PromptComponent>;

  public text: string;

  constructor(private dialog: MatDialog) { }

  prompt(text?: string): Observable<boolean> {
    this.dialogConfirm = this.dialog.open(PromptComponent, {
      width: '350px'
    });
    this.dialogConfirm.componentInstance.text = text;
    return this.dialogConfirm.afterClosed();
  }
}
