export const PRESETS_COLORS = [
  'rgba(255, 241, 0, 1)',
  'rgba(220, 207, 3, 1)',
  'rgba(251, 177, 21, 1)',
  'rgba(194, 133, 4, 1)',
  'rgba(252, 115, 18, 1)',
  'rgba(183, 87, 18, 1)',

  'rgba(255, 0, 0, 1)',
  'rgba(201, 0, 0, 1)',
  'rgba(255, 100, 100, 1)',
  'rgba(175, 68, 68, 1)',
  'rgba(255, 48, 0, 1)',
  'rgba(196, 43, 7, 1)',

  'rgba(0, 121, 213, 1)',
  'rgba(0, 95, 167, 1)',
  'rgba(61, 201, 238, 1)',
  'rgba(33, 136, 163, 1)',
  'rgba(0, 214, 204, 1)',
  'rgba(0, 145, 137, 1)',

  'rgba(1, 176, 22, 1)',
  'rgba(2, 124, 16, 1)',
  'rgba(128, 216, 11, 1)',
  'rgba(93, 157, 8, 1)',
  'rgba(255, 255, 255, 1)',
  'rgba(198, 198, 198, 1)',

  'rgba(243, 39, 160, 1)',
  'rgba(184, 30, 121, 1)',
  'rgba(183, 69, 245, 1)',
  'rgba(135, 42, 187, 1)',
  'rgba(138, 133, 255, 1)',
  'rgba(76, 73, 165, 1)'
];

export const UPDATED_PRESETS_COLORS = [
  'rgba(43,125,225,1)',
  'rgba(1,176,22,1)',
  'rgba(255,241,0,1)',
  'rgba(251,177,21,1)',
  'rgba(255,5,5,1)',
  'rgba(255,255,255,1)',

  'rgba(47,204,255,1)',
  'rgba(128,216,11,1)',
  'rgba(234,255,0,1)',
  'rgba(252,115,18,1)',
  'rgba(255,70,114,1)',
  'rgba(236,240,241,1)',

  'rgba(0,203,194,1)',
  'rgba(11,216,88,1)',
  'rgba(209,216,11,1)',
  'rgba(241,135,241,1)',
  'rgba(239,114,122,1)',
  'rgba(139,143,144,1)',

  'rgba(85,158,183,1)',
  'rgba(152,172,116,1)',
  'rgba(255,237,165,1)',
  'rgba(243,39,160,1)',
  'rgba(167,57,244,1)',
  'rgba(0,83,180,1)',

  'rgba(78,112,189,1)',
  'rgba(21,149,106,1)',
  'rgba(214,167,124,1)',
  'rgba(132,46,121,1)',
  'rgba(138,133,255,1)',
  'rgba(116,93,197,1)'
];
