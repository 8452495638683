import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'mee-table-action',
  templateUrl: './table-action.component.html',
  styleUrls: ['./table-action.component.scss']
})
export class TableActionComponent {

  @Input() icon: string;

  @Input() color: string;

  @Input() disabled = false;

  @Input() visible = true;

  @Input() name = '';

  @Input() actTooltip = '';

  @Input() router: string;

  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  isClickOnRemovingElement: boolean;

  constructor() {
  }

  press(event) {
    this.action.emit(event);

    // for prevent reload page when click on <a> with routerLink directive

    event.preventDefault();
  }

  defineIsRemovingElement() {
    this.isClickOnRemovingElement = this.actTooltip === 'Archive' || this.actTooltip === 'Delete';
  }

}
