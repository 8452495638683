import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { snackBarConfig } from '../../../core/core.module';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import * as audioConfig from './audio-config-enum';

@Component({
  selector: 'mee-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioPlayerComponent implements OnInit, AfterViewInit {
  @Input() src: string;

  isPlaying = false;
  isLoading = false;
  canPlay = false;
  playingProgress = 0;
  audio= '';

  player: any;

  @ViewChild('player', { static: true }) playerEl: ElementRef;

  constructor(private snackBar: MatSnackBar, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.player = this.playerEl.nativeElement;
  }

  ngAfterViewInit() {

    // Loading
    this.player.addEventListener('progress', (event) => {
      if (event.target.networkState === audioConfig.AudioNetworkStates.NETWORK_LOADING && !this.isPlaying) {
        this.isLoading = true;
      }
    });

    this.player.addEventListener('canplay', (event) => {
      this.isLoading = false;
      this.canPlay = true;
    });

    this.player.addEventListener('playing', () => {
      this.isPlaying = true;
    });
    this.player.addEventListener('pause', () => {
      this.isPlaying = false;
      this.cdr.markForCheck();
    });
    this.player.addEventListener('timeupdate', this.updateProgress.bind(this));

    this.player.addEventListener('error', (event) => {
      let msg = 'Audio error';

      if (event.target.networkState === audioConfig.AudioNetworkStates.NETWORK_NO_SOURCE) {
        msg = 'No audio source found';
      }

      this.snackBar.open(msg, null, snackBarConfig);
    });
  }

  get currentTime() {
    return this.canPlay ? this.getTime(this.player.currentTime) : '0:00';
  }

  get totalTime() {
    return this.canPlay ? this.getTime(this.player.duration) : '0:00';
  }

  play(event) {
    event.stopPropagation();
    this.audio = this.src;
    setTimeout(() => {
      this.player.play();
    }, 5);
  }

  pause(event) {
    event.stopPropagation();
    this.player.pause();

    this.cdr.markForCheck();
  }

  updateProgress(event) {
    const player = event.target;
    const percent = (player.currentTime / player.duration) * 10000;

    this.playingProgress = Math.round(percent) / 100;

    this.cdr.markForCheck();
  }

  changeProgress(event) {
    if (!this.canPlay || this.isLoading) {
      this.playingProgress = 0;
      return;
    }

    this.player.currentTime = this.player.duration * (event.value / 100);
  }

  getTime(data) {
    if (!data) {
      return '0:00';
    }

    return moment(data * 1000).format('m:ss');
  }
}
