import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ISchedule, ISource } from '../../models/source.interface';
import { snackBarConfig } from '../../../../core/core.module';
import { IdentityService } from '../../../../services/identity.service';
import { EMPTY, forkJoin, Observable, Subject } from 'rxjs';
import { TranslationHandleService } from '../../../../core/translate/translation-handle.service';
import { TranslateService } from '@ngx-translate/core';
import { CopyConfigFromService } from '../source-configuration-list/components/copy-config-from/copy-config-from.service';
import { CategoriesService, ICategory } from '../../../company/services/categories.service';
import { IStub } from '../../../../shared/components/stub/stub.component';
import { TeamsService } from '../../../teams/services/teams.service';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ServerPaginatedData } from '../../../../interfaces/server-paginated-data';
import { PaginationService } from '../../../../services/pagination.service';
import isNumber from 'lodash-es/isNumber';
import { PermissionType } from '../../../../shared/enums/permission-type.enum';
import { Scope } from '../../../components/models/scope.enum';
import { DataSourcesAdapterService } from '../source-configuration-list/components/configuration-tab/data-sources-adapter.service';
import { SourcesRelatedDataService } from '../../services/sources-related-data.service';
import { DataSourcesService } from '../../services/data-sources.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SchedulingService } from '../source-form/source-form-scheduling/services/scheduling.service';
import { DataSourceType } from '../../data-source-type.enum';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { AccessReason } from '../../../components/models/access-reason.enum';
import { ICategoryBase, UNCATEGORIZED_CATEGORY_NAME } from '../../../../core/categories/categories.component';
import { CategorySetDialogComponent } from '../../../../shared/components/category-set-dialog/category-set-dialog.component';
import { CategorySetActions } from '../../../../shared/enums/category-set-actions.enum';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationDialogComponent } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from '../../../../interfaces/confirmation-dialog-data';
import { CategorySetData } from '../../../../shared/interfaces/category-set-data';
import { ServerPaginationParams } from '../../../../interfaces/server-pagination-params';
import { OffsetLimitParams } from '../../../../interfaces/offset-limit-params';
import { ContextMenuComponent, ContextMenuDirective } from '@perfectmemory/ngx-contextmenu';
import { ExecuteContextMenuItemEvent } from '../../../../shared/interfaces/execute-context-menu-item-event';
import { TeamProjectService } from '../../../../services/team-project.service';
import { AddDataSourceComponent } from '../../../global-add/add-data-source/add-data-source.component';
import { HttpParams } from '@angular/common/http';

const PHRASES_FOR_TRANSLATE = [
  {
    serverError: 'system.serverError',
  },
  {
    sourcesArchived: 'sources.ItemsArchived',
  },
];

@Component({
  selector: 'mee-sources-list',
  templateUrl: './sources-list.component.html',
  styleUrls: ['./sources-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourcesListComponent implements OnInit, AfterViewInit, OnDestroy {
  routeCompanySlug: string;

  sources: ISource[];

  isNoSourcesAfterDelete: boolean;

  stubData: IStub = {
    stubText: 'stubsText.noSources',
    stubIcon: 'settings_ethernet',
  };

  isCreateActionAllowed: boolean;
  isUpdateActionAllowed: boolean;
  isDeleteActionAllowed: boolean;

  isDataLoading = false;

  dataSource = new MatTableDataSource<ISource>();

  displayedColumns = [
    'Select',
    'IsProcessing',
    'Schedule',
    'MapParsingRules',
    'CompanySlug',
    'Description',
    'Slug',
    'Scope',
    'Type',
    'IsRealTime',
    'TotalPlacemarks',
    'ProcessedPlacemarks',
    'ProcessedPercentage',
    'CreatedAtUtc',
  ];

  searchControl = new UntypedFormControl();

  pageIndex: number;

  paginatorOptions = {
    hide: false,
    pageSize: 50,
    pageSizeOptions: [50, 100, 150],
  };

  scope = Scope;

  isShowArchived: boolean;

  totalCount: number;

  sourcesSelection = new SelectionModel<ISource>(true, []);

  sourcesCategories: ICategory[] = [];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('contextMenuRef') contextMenu: ContextMenuComponent<any>;
  @ViewChild('ngxContextMenuRef') ngxContextMenu: ContextMenuDirective<any>;

  private _translationMap: Map<string, string>;

  /*Server side realization*/

  private urlForGetDataForCompanyOrTeamAdmin: string;

  private isDefaultSorting = true;

  private defaultSortingParams = this.paginationService.DEFAULT_SORTING_PARAMS;

  private skip = 0;
  private take = 50;
  private orderBy = 'Slug';
  private desc = false;
  private previousPageSize: number;

  private destroy$ = new Subject<void>();

  get isSearchFieldValue() {
    return this.searchControl.value !== null;
  }

  constructor(
    private snackBar: MatSnackBar,
    private dataSourcesService: DataSourcesService,
    private sourcesRelatedDataService: SourcesRelatedDataService,
    private identityService: IdentityService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private categoriesService: CategoriesService,
    private teamsService: TeamsService,
    private dataSourcesAdapterService: DataSourcesAdapterService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private copyConfigFromService: CopyConfigFromService,
    private paginationService: PaginationService,
    private schedulingService: SchedulingService,
    private translationHandleService: TranslationHandleService,
    private matDialog: MatDialog,
    private teamProjectService: TeamProjectService,
    private urlSerializer: UrlSerializer
  ) {
    this.translationHandleService.setPhrasesForTranslate(PHRASES_FOR_TRANSLATE);

    this.translationHandleService
      .getTranslationMap()
      .pipe(takeUntil(this.destroy$))
      .subscribe(translationMap => {
        this._translationMap = translationMap;
      });

    const routerState = this.router.getCurrentNavigation()?.extras?.state;

    if (this.paginationService.paginationOptions.filter.length > 0) {
      this.searchControl.setValue(this.paginationService.paginationOptions.filter);
    }

    if (routerState !== undefined && routerState.hasOwnProperty('searchParam')) {
      this.searchControl.setValue(routerState.searchParam);
    }
  }

  ngOnInit() {
    this.routeCompanySlug =
      this.route.snapshot.paramMap.get('companySlug') !== this.identityService.companySlug ? this.route.snapshot.paramMap.get('companySlug') : '';

    this.isShowArchived = this.paginationService.paginationOptions.isDeleted;

    const sourcesDataFromResolver: ServerPaginatedData = this.route.snapshot.data.sources;

    this.sources = sourcesDataFromResolver.body;

    this.dataSource.data = sourcesDataFromResolver.body;

    this.pageIndex = sourcesDataFromResolver.pageIndex;

    this.totalCount = Number(sourcesDataFromResolver.totalCount);

    this.searchControl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        map((value: string) => (value ? value.toLowerCase().trim() : '')),
        tap((value: string) => this.setFilterValueParameter(value)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.onTeamProjectChange();

    this.getSourceCategories();

    this.paginationService.onSkipTakeParamsChanged.pipe(takeUntil(this.destroy$)).subscribe(skipTakeParams => {
      this.pageEventChanged(skipTakeParams);
    });

    this.paginationService.onNeedToRefreshData
      .pipe(
        tap(options => {
          if (!options) {
            return;
          }

          this.getRefreshedSources(options);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    if (!this.identityService.isSuperAdmin()) {
      this.urlForGetDataForCompanyOrTeamAdmin = this.dataSourcesService.getBaseUrlForCompanyOrTeamAdmin(this.identityService.companySlug);

      const index = this.displayedColumns.indexOf('CompanySlug');

      if (index !== -1) {
        this.displayedColumns.splice(index, 1);
      }
    }

    if (this.identityService.isTeamAdmin()) {
      const teamPermissions = this.identityService.teamPermissions;

      const createPermission = teamPermissions.find(permission => permission.Type === PermissionType.Create);

      const updatePermission = teamPermissions.find(permission => permission.Type === PermissionType.Update);

      const deletePermission = teamPermissions.find(permission => permission.Type === PermissionType.Delete);

      this.isCreateActionAllowed = createPermission.IsAllowed;

      this.isUpdateActionAllowed = updatePermission.IsAllowed;

      this.isDeleteActionAllowed = deletePermission.IsAllowed;

      // backend not manage to refresh source list for team admin after adding new one

      this.sourcesRelatedDataService
        .getNewlyAddedSourceForTeamAdmin$()
        .pipe(
          filter(newlyCreatedSource => newlyCreatedSource !== null),
          tap(newlyCreatedSource => {
            const isSourceAlreadyExisted = !!this.sources.find(tableSource => tableSource.Id === newlyCreatedSource.Id);

            if (!isSourceAlreadyExisted) {
              this.sources.unshift(newlyCreatedSource);
            }
          }),
        )
        .subscribe();
    }
  }

  ngAfterViewInit() {
    this.sourcesSelection.isSelected = this.isItemChecked.bind(this);
    this.previousPageSize = this.paginator.pageSize;
    this.onChangePage();
  }

  onChangeSourceSelection(event, item) {
    this.ngxContextMenu.close();
    this.sourcesSelection.toggle(item);
  }

  setSelectedItems() {
    const selectedElementsIds = this.sourcesSelection.selected.map(item => item.Id);

    const selectedItemsOnOtherPages = this.sourcesSelection.selected;

    this.sourcesSelection.clear();

    selectedElementsIds.forEach(itemId => {
      const searchElem = this.sources.find(source => itemId === source.Id);

      if (searchElem) {
        this.sourcesSelection.select(searchElem);
      }
    });

    selectedItemsOnOtherPages.forEach(selectedItem => this.sourcesSelection.select(selectedItem));
  }

  isItemChecked(row: ISource): boolean {
    const isFoundSelectedElement = this.sourcesSelection.selected.find(el => el.Id === row.Id);

    return !!isFoundSelectedElement;
  }

  getSourceCategories() {
    if (!this.isCompanyAdmin()) {
      return;
    }

    this.isDataLoading = true;

    this.categoriesService
      .getDataSourcesCategories(this.identityService.companySlug, this.identityService.teamSlug)
      .pipe(
        tap(categories => {
          this.sourcesCategories = categories;

          this.categoriesService.setAmountOfObjectsForCategories(this.sourcesCategories, this.sources, true);

          this.cdr.markForCheck();
        }),
        finalize(() => (this.isDataLoading = false)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  getProcessedClass(percent) {
    if (!isNumber(percent)) {
      return '';
    }

    let cl = 'good';

    if (percent < 100) {
      cl = 'normal';
    }

    if (percent < 25) {
      cl = 'bed';
    }

    return `class="processedPercent ${cl}"`;
  }

  /*TODO replace any if possible*/

  getPlacemarks(placemarks: any) {
    return placemarks < 0 ? 'N/A' : placemarks;
  }

  pageEventChanged(skipTake: OffsetLimitParams) {
    this.skip = skipTake.offset;
    this.take = skipTake.limit;
  }

  onSortData(sortData: Sort) {
    this.setSortParameters(sortData);
  }

  clearFilter() {
    this.dataSource.filter = '';
    this.searchControl.reset();
    this.setFilterValueParameter('');
  }

  onShowArchived({ checked }) {
    if (checked === false) {
      this.resetAllCategories();
    }

    this.isShowArchived = checked;
    this.setIsDeletedParameter(checked);
  }

  navigateOnScheduleTab(event, source: ISource) {
    event.stopPropagation();

    this.router.navigate(['/sources', source.CompanySlug, source.Slug, 'config'], { queryParams: { tab: 'General' } });
  }

  getScheduleTooltipText(processSchedule: ISchedule): string {
    return this.schedulingService.getScheduleTooltipText(processSchedule);
  }

  actionDelete(executeEvent: ExecuteContextMenuItemEvent<ISource>) {
    let item = null;

    if (executeEvent) {
      item = executeEvent.value;
    }

    // multiselect is active

    if (item === null && this.sourcesSelection.selected.length > 1) {
      const sourcesIds = this.sourcesSelection.selected.map(source => source.Id);

      this.matDialog
        .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(ConfirmationDialogComponent, {
          width: '400px',
          height: '188px',
          data: {
            subtitleKey: 'sources.ConfirmArchiveMultiple',
          },
        })
        .afterClosed()
        .pipe(
          filter(isConfirmAction => !!isConfirmAction),
          switchMap(() => this.dataSourcesService.removeMultiple(sourcesIds)),
          tap(() => {
            this.sources = this.sources.map(source => {
              const searchElement = this.sourcesSelection.selected.find(element => element.Id === source.Id);

              if (searchElement !== undefined) {
                return { ...searchElement, IsArchived: true };
              }

              return source;
            });

            this.snackBar.open(this._translationMap.get('sourcesArchived'), 'OK', snackBarConfig);
            this.isNoSourcesAfterDelete = this.sources.length === 1;

            if (this.isCompanyAdmin()) {
              this.getSourceCategories();
              this.categoriesService.isLastItemDeletedFromSortingCategories(this.sources, this.sourcesCategories);
            }

            this.sourcesSelection.clear();

            this.onRefreshListAndCategoriesData();

            this.ngxContextMenu.close();

            this.cdr.markForCheck();
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();

      return;
    }

    if (item === null) {
      item = this.sourcesSelection.selected[0];
    }

    forkJoin([
      this.dataSourcesService.remove(item.CompanySlug, item.Slug),
      this.translateService.get('sources.snackBarMessages.archive', { slug: item.Slug }),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: [ISource, string]) => {
          const source: ISource = res[0];
          if (source) {
            item.IsArchived = source.IsArchived;
            item.Slug = source.Slug;

            if (this.isCompanyAdmin()) {
              this._updateAmountOfObjectsInCategory(item, true);
              this.getSourceCategories();
            }

            this.ngxContextMenu.close();
            this.sourcesSelection.clear();

            this.snackBar.open(res[1], 'OK', snackBarConfig);
            this.isNoSourcesAfterDelete = this.sources.length === 1;

            this.cdr.markForCheck();
          }
        },
        error => {
          this.snackBar.open(error.statusText, 'OK', snackBarConfig);
        },
      );
  }

  actionRestore(executeEvent: ExecuteContextMenuItemEvent<ISource>) {
    const item = executeEvent.value;

    if (this.isRestoreActionDisabled(item)) {
      return;
    }

    forkJoin([
      this.dataSourcesService.restore(item.CompanySlug, item.Slug),
      this.translateService.get('sources.snackBarMessages.restore', { slug: item.Slug }),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: [ISource, string]) => {
          const source: ISource = response[0];
          if (source) {
            item.Slug = source.Slug;
            item.IsArchived = source.IsArchived;

            this.ngxContextMenu.close();

            this._updateAmountOfObjectsInCategory(item, false);
            this.snackBar.open(response[1], 'OK', snackBarConfig);

            this.cdr.markForCheck();
          }
        },
        () => {
          this.snackBar.open(this._translationMap.get('serverError'), 'OK', snackBarConfig);
        },
      );
  }

  private _updateAmountOfObjectsInCategory(item: ISource, isDeleteOperation: boolean) {
    if (!this.isCompanyAdmin()) {
      return;
    }

    // Because of server side pagination if we will request new categories after delete or restore operation
    // we get it with right count and all other info but without chosen category for sorting
    // because of that we will update category count on frontend this way

    let categoryThatLostItem;

    if (item.Category) {
      categoryThatLostItem = this.sourcesCategories.find(cat => cat.Id === item.Category.Id);
    } else {
      /*item from 'Other' category*/

      categoryThatLostItem = this.sourcesCategories.find(cat => cat.Id === null);
    }

    if (!categoryThatLostItem) {
      // we deleted last item from category and delete category itself and now we want to restore this item
      return;
    }

    isDeleteOperation ? (categoryThatLostItem.amountOfObjects -= 1) : (categoryThatLostItem.amountOfObjects += 1);
  }

  actionAdd() {
    this.matDialog.open(AddDataSourceComponent, {
      width: '392px',
      panelClass: 'add-data-source',
    }).afterClosed().pipe(
      filter(Boolean),
      tap(() => this.onRefreshListAndCategoriesData()),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  actionEdit(event, item: ISource) {
    event.stopPropagation();

    this.router.navigate(['/sources', item.CompanySlug, item.Slug, 'config'], { queryParams: { tab: 'General' } });
  }

  addToCategory() {
    const selectedSources = this.sourcesSelection.selected;

    this.matDialog
      .open<CategorySetDialogComponent, { itemsCount: number }, CategorySetData>(CategorySetDialogComponent, {
        data: {
          itemsCount: selectedSources.length,
        },
        autoFocus: false,
      })
      .afterClosed()
      .pipe(
        tap(data => {
          if (data.action === CategorySetActions.Create) {
            this.createCategory(data.categoryName);
          } else {
            this.setCategory(data.category);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  selectAll() {
    if (this.isAllSelected()) {
      this.sourcesSelection.clear();

      return;
    }

    this.sourcesSelection.select(...this.dataSource.data);
  }

  clearAllSelectedSources() {
    this.sourcesSelection.clear();
  }

  selectedItemsByCategoryChanged(categoryBase: ICategoryBase) {
    /*For Other category (category that includes items without category)*/

    let categoryId;

    if (categoryBase.categoryName.length === 0) {
      categoryId = '';
    } else {
      categoryId = categoryBase.categoryId;
    }

    if (categoryBase.categoryName === UNCATEGORIZED_CATEGORY_NAME) {
      categoryId = 'none';
    }

    this.setCategoryIdForSearch(categoryId);
  }

  onRefreshListAndCategoriesData() {
    const paginationOptions = this.paginationService.paginationOptions;

    this.getRefreshedSources(paginationOptions);

    this.getSourceCategories();

    this.sourcesSelection.clear();
  }

  getContextMenu(): ContextMenuComponent<any> | null {
    if (this.isDisplayContextMenu()) {
      return this.contextMenu;
    }

    return null;
  }

  getMappingRouterLink(source: ISource): string {
    return `/sources/${source.CompanySlug}/${source.Slug}/config`;
  }

  getEditRouterLink(source: ISource): string | null {
    if (this.isEditActionDisabled(source)) {
      return null;
    }

    return `/sources/${source.CompanySlug}/${source.Slug}/config`;
  }

  getSourceTitle(sourceType: DataSourceType) {
    return this.dataSourcesAdapterService.getSourceTitle(sourceType);
  }

  actionMapping(event, item: ISource) {
    event.stopPropagation();

    this.copyConfigFromService.onBackToList(false);
    this.router.navigate([`sources/${item.CompanySlug}/${item.Slug}/config`]);
  }

  isMappingActionAvailable = (source: ISource): boolean => {
    return !source.IsCascadeArchived;
  };

  isEditActionAvailable = (source: ISource): boolean => {
    if (this.isSuperAdmin() || this.isCompanyAdmin()) {
      return !source.IsCascadeArchived;
    }

    const isPrivateScope = source.Scope === Scope.TeamPrivateScope;

    const isOwner = source.Scope === Scope.TeamPublicScope && source.AccessReason === AccessReason.Owner;

    return !source.IsCascadeArchived && this.getIsUpdatePermissionsAllowed() && (isPrivateScope || isOwner);
  };

  isEditActionDisabled = (source: ISource): boolean => {
    return source.Type === DataSourceType.Virginia811;
  };

  isArchiveActionAvailable = (source: ISource): boolean => {
    if (this.isSuperAdmin() || this.isCompanyAdmin()) {
      return !source.IsArchived && !source.IsCascadeArchived;
    }

    const isPrivateScope = source.Scope === Scope.TeamPrivateScope;

    const isOwner = source.Scope === Scope.TeamPublicScope && source.AccessReason === AccessReason.Owner;

    return !source.IsArchived && !source.IsCascadeArchived && this.getIsDeletePermissionsAllowed() && (isPrivateScope || isOwner);
  };

  isRestoreActionAvailable = (source: ISource): boolean => {
    return source.IsArchived || source.IsCascadeArchived;
  };

  isRestoreActionDisabled = (source: ISource): boolean => {
    return source.IsCascadeArchived;
  };

  isSuperAdmin(): boolean {
    return this.identityService.isSuperAdmin();
  }

  isCompanyAdmin(): boolean {
    return this.identityService.isCompanyAdmin();
  }

  isTeamAdmin(): boolean {
    return this.identityService.isTeamAdmin();
  }

  isAllSelected(): boolean {
    return this.sourcesSelection.selected.length >= this.dataSource.data.length;
  }

  getIsCreatePermissionsAllowed() {
    if (this.isSuperAdmin() || this.isCompanyAdmin()) {
      return true;
    }

    return this.isCreateActionAllowed;
  }

  getIsUpdatePermissionsAllowed() {
    if (this.isSuperAdmin() || this.isCompanyAdmin()) {
      return true;
    }

    return this.isUpdateActionAllowed;
  }

  getIsDeletePermissionsAllowed() {
    if (this.isSuperAdmin() || this.isCompanyAdmin()) {
      return true;
    }

    return this.isDeleteActionAllowed;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.translationHandleService.cleanTranslationArray();
    this.paginationService.onNeedToRefreshData.next(null);
  }

  private onTeamProjectChange() {
    this.teamProjectService.teamProject$
      .pipe(
        skip(1),
        tap(() => {
          const paginationOptions = this.paginationService.defaultPaginationOptions;

          this.getRefreshedSources(paginationOptions);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private isDisplayContextMenu(): boolean {
    return !this.sourcesSelection.hasValue();
  }

  private createCategory(categoryName: string) {
    this.categoriesService
      .createCategory(this.identityService.companySlug, categoryName)
      .pipe(switchMap(category => this.updateCategoryForMultipleSources$(category.Id)))
      .subscribe();
  }

  private setCategory(category: ICategory) {
    const categoryId = category.Id;

    this.updateCategoryForMultipleSources$(categoryId).subscribe();
  }

  private updateCategoryForMultipleSources$(categoryId: string): Observable<null> {
    const companySlug = this.identityService.companySlug;

    const sourcesIds = this.sourcesSelection.selected.map(source => source.Id);

    return this.categoriesService.updateCategoryForMultipleSources(companySlug, categoryId, sourcesIds).pipe(
      tap(() => {
        this.sourcesSelection.clear();
        this.onRefreshListAndCategoriesData();

        const updateMessage = `${
          sourcesIds.length > 1 ? sourcesIds.length + ' sources were' : sourcesIds.length + ' source was'
        } successfully updated!`;

        this.snackBar.open(updateMessage, 'OK', snackBarConfig);

        this.cdr.markForCheck();
      }),
      catchError(() => {
        // this.snackBar.open(this.translationMap.get('serverError'), 'OK', snackBarConfig);
        return EMPTY;
      }),
      takeUntil(this.destroy$),
    );
  }

  private resetAllCategories(): void {
    this.sourcesCategories.forEach(category => (category.isChosenForSorting = false));
  }

  private onChangePage() {
    this.paginator.page
      .pipe(
        tap(pageEvent => {
          if (this.previousPageSize !== pageEvent.pageSize) {
            this.pageSizeChange();
          }

          this.previousPageSize = pageEvent.pageSize;

          this.setNextPageForPagination(pageEvent);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private setNextPageForPagination(pageEvent) {
    let sortData;

    if (this.isDefaultSorting) {
      sortData = this.defaultSortingParams;
    }

    this.paginationService.setNextPageForPagination(pageEvent, sortData);
  }

  private setCategoryIdForSearch(categoryId: string) {
    this.paginationService.setCategoryIdForSearch(categoryId);
  }

  private setIsDeletedParameter(isArchivedDataDisplay: boolean) {
    this.paginationService.setIsDeletedParameter(isArchivedDataDisplay);

    if (this.paginator?.pageIndex) {
      this.paginator.pageIndex = 0;
    }
  }

  private pageSizeChange(): void {
    this.sourcesSelection.clear();
  }

  private setSortParameters(sortData: Sort) {
    if (!sortData.direction.length) {
      this.isDefaultSorting = true;

      /*Apply default sorting params*/

      // 'desc' instead of this._defaultSortingParams because of types definition of SortDirection

      sortData = { active: this.defaultSortingParams.active, direction: 'asc' };

      if (sortData.active === this.defaultSortingParams.active) {
        sortData = { active: 'CompanySlug', direction: 'asc' };
      }
    }

    this.isDefaultSorting = false;

    this.paginationService.setSortParameters(sortData);
  }

  private setFilterValueParameter(searchString: string) {
    this.paginationService.setFilterValueParameter(searchString);
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    } else {
      this.pageIndex = 0;
    }
  }

  private getRefreshedSources(optionsForServer: ServerPaginationParams) {
    this.isDataLoading = true;

    this.dataSourcesService
      .getServerPaginatedList$(optionsForServer, this.identityService.companySlug)
      .pipe(
        tap(sourcesData => {
          this.sources = sourcesData.body;
          this.dataSource.data = sourcesData.body;
          this.totalCount = Number(sourcesData.totalCount);

          this.setSelectedItems();

          this.cdr.markForCheck();
        }),
        finalize(() => (this.isDataLoading = false)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
