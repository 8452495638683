import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SingleForm } from '../interfaces/single-form';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ILayer } from '../../../../../../components/layers/shared/models/layer.interface';
import { DataSourceType } from '../../../../../data-source-type.enum';
import { PlacemarksFields } from '../../../models/placemarks-fields';
import { SourceConfiguration } from '../interfaces/source-configuration';
import isEqual from 'lodash-es/isEqual';
import { ConfigurationFieldsAdapterService } from '../configuration-fields-adapter.service';
import { ConfigurationFields } from '../enums/configuration-fields.enum';
import { isDisplayForCurrentSourceType } from '../is-display-for-current-source-type.function';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { FeatureLayerType } from '../interfaces/feature-layer-type.enum';
import { SourceConfiguration3dModelMarker } from '../../../models/source-configuration-3d-model-marker.enum';
import { Subject } from 'rxjs';
import { ObjectType } from '../enums/object-types.enum';
import { DataSourcesAdapterService } from '../data-sources-adapter.service';
import { DataSourcesService } from '../../../../../services/data-sources.service';
import { ActivatedRoute } from '@angular/router';
import { Floor } from '../../../../../../components/floors/interfaces/floor.interface';
import {
  MatLegacySlideToggle as MatSlideToggle,
  MatLegacySlideToggleChange as MatSlideToggleChange,
} from '@angular/material/legacy-slide-toggle';
import { FloorConfiguration } from '../floors-configuration/interfaces/floor-configuration';
import { FloorIfcConfiguration } from '../floors-configuration/interfaces/floor-ifc-configuration';
import { FloorElevationSource } from '../floors-configuration/enums/floor-elevation-source.enum';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ProjectionType } from '../enums/projection-type.enum';
import { SourceConfigurationService } from '../../../services/source-configuration.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EpsgCodeTipsComponent } from '../../epsg-code-tips/epsg-code-tips.component';
import { ColorPickerPosition } from '../enums/color-picker-position';
import { CoordinateSystem } from '../../../../../../components/coordinate-system/coordinate-system';
import { IdentityService } from '../../../../../../../services/identity.service';
import { SourceWorkpack } from '../../../../../models/source-workpack';

@Component({
  selector: 'mee-model3d-configuration',
  templateUrl: './model3d-configuration.component.html',
  styleUrls: ['./model3d-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Model3dConfigurationComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, SingleForm {
  form: UntypedFormGroup;

  localNode: SourceConfiguration;

  localLayers: ILayer[];

  configurationFields = ConfigurationFields;

  projectionTypeOptions: Array<string>;

  floorsConfiguration: FloorConfiguration | FloorIfcConfiguration;

  companyFloors: Floor[];

  altitudeModesOptions = this.configurationFieldsAdapterService.getAltitudeModesOptions();

  objectTypesOptions = this.configurationFieldsAdapterService.getObjectTypesOptions();

  verticalDatumOptions = this.configurationFieldsAdapterService.getVerticalDatumOptions();

  measurementSystemOptions = this.configurationFieldsAdapterService.getMeasurementSystemOptions();

  ifcCategorizationStrategyOptions = this.configurationFieldsAdapterService.getIfcCategorizationStrategyOptions();

  isCustomCoordinateSystem = false;

  isCompanyFloorsLoading: boolean;

  isShowIfcMapping: boolean;

  isIfcMappingEnabled = true;

  isShowManualCoordinates: boolean;

  // projection type is client only field, so it needs to be separated from form

  workPacks: SourceWorkpack[] = [];

  projectionTypeControl = new UntypedFormControl();

  private isLocalCoordinatesSystem = false;

  private localSourceType: DataSourceType;

  private destroy$ = new Subject<void>();

  readonly ColorPickerPosition = ColorPickerPosition;

  @ViewChild('floorSplittingToggle', { static: false }) private floorSplittingToggle: MatSlideToggle;

  @ViewChild('ifcMappingToggle', { static: false }) private ifcMappingToggle: MatSlideToggle;

  @Input() companySlug: string;
  @Input() placeMarksFields: PlacemarksFields;
  @Input() customCoordinateSystems: CoordinateSystem[] = [];
  @Input() isModelLevel = false;
  @Output() updateLayerGroup = new EventEmitter();

  @Input() set sourceType(sourceType: DataSourceType) {
    this.localSourceType = sourceType;

    const isLandXmlSource = this.dataSourcesAdapterService.isLandXmlType(this.sourceType);

    if (isLandXmlSource) {
      this.form.get('ObjectTypeConfiguration.IsMeshInteractionEnabled').disable({ emitEvent: false });
      this.form.get('ObjectTypeConfiguration.EnableDoubleSideInteraction').disable({ emitEvent: false });
    }
  }

  get isShowManualCoordinatesFields() {
    return this.isShowManualCoordinates && this.isDisplayForCurrentSourceType(this.configurationFields.ManualCoordinates);
  }

  get sourceType() {
    return this.localSourceType;
  }

  @Input() set layers(layers: ILayer[]) {
    this.localLayers = layers;

    if (layers.length === 0) {
      this.form.get('Layer').disable();
    }
  }

  get layers() {
    return this.localLayers;
  }

  @Input() set node(node: SourceConfiguration) {
    if (node === undefined) {
      return;
    }

    if (isEqual(this.node, node)) {
      return;
    }

    this.localNode = node;

    this.form.patchValue(this.node, { emitEvent: false });

    if (node.Marker === SourceConfiguration3dModelMarker.Category) {
      this.form.get('ObjectType').disable({ emitEvent: false });
    }

    const isMeshInteractionEnabled = this.form.get('ObjectTypeConfiguration.IsMeshInteractionEnabled').value;

    const isLandXmlSource = this.dataSourcesAdapterService.isLandXmlType(this.sourceType);

    if (!isMeshInteractionEnabled || isLandXmlSource) {
      this.form.get('ObjectTypeConfiguration.EnableDoubleSideInteraction').disable({ emitEvent: false });
    }

    this.isIfcMappingEnabled = this.node.IsFloorSplittingEnabled;

    this.floorsConfiguration = this.node.FloorConfiguration;

    // for case when user select one model3d feature layer and then another one same type

    this.setProjectionTypeData();
  }

  get node() {
    return this.localNode;
  }

  get isBim360(): boolean {
    return this.sourceType === DataSourceType.Bim360;
  }

  get isNativeBim(): boolean {
    return this.sourceType === DataSourceType.NativeBim;
  }

  get isLandXml(): boolean {
    return this.sourceType === DataSourceType.LandXml;
  }

  get isTitleNotEmpty() {
    return this.form.get('Title').value !== null;
  }

  get isCheckLocationDisable(): boolean {
    return !this.isRequirementsManualFields && !this.isCoordinateSystem;
  }

  get isCheckLocation(): boolean {
    return (this.isNativeBim || this.isBim360 || this.isLandXml) && !this.isLocalCoordinatesSystem && !this.node.isLeaf;
  }

  get isRequirementsManualFields(): boolean {
    if (!this.isShowManualCoordinates) {
      return false;
    }

    const latitude = this.form.get('ObjectTypeConfiguration.Latitude').value;
    const longitude = this.form.get('ObjectTypeConfiguration.Longitude').value;

    return Boolean(latitude) && Boolean(longitude);
  }

  get isCustomCoordinateSystemDisplay(): boolean {
    return this.isBim360 || this.isNativeBim;
  }

  get isCoordinateSystem(): boolean {
    const coordinateSystem = this.form.get('ObjectTypeConfiguration.CoordinateSystem').value;

    return Boolean(coordinateSystem);
  }

  get isShowCoordinateSystem() {
    return (
      (this.isDisplayForCurrentSourceType(this.configurationFields.CoordinateSystem) ||
        this.isDisplayForCurrentSourceType(this.configurationFields.MeasurementSystem)) &&
      !this.isShowManualCoordinates
    );
  }

  get isDisplayExtendModelToggles() {
    return (
      (this.isDisplayForCurrentSourceType(this.configurationFields.IsMeshInteractionEnabled) ||
        this.isDisplayForCurrentSourceType(this.configurationFields.FullModel)) &&
      this.node.Marker === SourceConfiguration3dModelMarker.ModelFile
    );
  }

  get isShowForCategory() {
    return this.node.Marker === SourceConfiguration3dModelMarker.Category;
  }

  get isShowIfcFloorsConfiguration() {
    const isDisplayOnCurrentSourceType = this.isDisplayForCurrentSourceType(this.configurationFields.FloorElevation);

    const isIfcModelType = this.node.FloorConfiguration !== null && this.node.FloorConfiguration.Type === FloorElevationSource.Ifc;

    return isDisplayOnCurrentSourceType && isIfcModelType;
  }

  constructor(
    private sourceConfigurationService: SourceConfigurationService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private configurationFieldsAdapterService: ConfigurationFieldsAdapterService,
    private dataSourcesAdapterService: DataSourcesAdapterService,
    private dataSourcesService: DataSourcesService,
    private matDialog: MatDialog,
    private identityService: IdentityService,
  ) {
    this.initForm();

    this.onChangeObjectType();
  }

  ngOnInit() {
    this.onChangeModelInteractionsValue();

    this.onChangeIsDefineFloorElevation();
  }

  ngAfterViewInit() {
    this.setFloorsValue();

    this.setProjectionTypeData();
    this.handleWorkPacksList();
  }

  ngOnChanges() {
    // for case when user select one model3d feature layer and then another one same type

    // setTimeout is workaround to not change backend and design, related to refs #54582

    setTimeout(() => this.setFloorsValue());
  }

  checkLocation(): void {
    this.sourceConfigurationService.setCheckLocationState(true);
  }

  updateLayers(): void {
    this.updateLayerGroup.emit();
  }

  compareWorkPacksFn = (compareWorkPack: SourceWorkpack, secondaryCompareWorkPack: SourceWorkpack): boolean => {
    return compareWorkPack && secondaryCompareWorkPack && compareWorkPack.Id === secondaryCompareWorkPack.Id;
  };

  isDisplayForCurrentSourceType(fieldName: ConfigurationFields) {
    return isDisplayForCurrentSourceType(fieldName, FeatureLayerType.Model3d, this.sourceType);
  }

  clearTitle() {
    this.form.get('Title').reset();
  }

  onProjectionTypeChange(event: MatSelectChange) {
    const isManualCoordinates = event.value === ProjectionType.Manual;
    const isLocalCoordinates = event.value === ProjectionType.Local;
    const isGlobalCoordinates = event.value === ProjectionType.Global;

    this.isShowManualCoordinates = isManualCoordinates;
    this.isLocalCoordinatesSystem = isLocalCoordinates;

    this.form.get('ObjectTypeConfiguration.IsLocalCrs').setValue(isLocalCoordinates);

    if (isLocalCoordinates) {
      this.disableLocalCoordinatesRelatedFields();
    } else if (isManualCoordinates) {
      this.form.get('ObjectTypeConfiguration.CoordinateSystem').reset(null, { emitEvent: false });
    } else if (isGlobalCoordinates) {
      this.clearLocalCoordinatesFields();
      this.enableLocalCoordinatesRelatedFields();
    } else {
      this.disableLocalCoordinatesRelatedFields();
      this.clearLocalCoordinatesFields();
      this.isCustomCoordinateSystem = true;

      const coordinateSystem: CoordinateSystem = event.value;
      this.form.get('ObjectTypeConfiguration.CustomCrsId').setValue(coordinateSystem);
    }
  }

  getProjectionTypeLabel(projectionType: string): string | ProjectionType {
    if (projectionType === ProjectionType.Local) {
      return 'Site coordinates (xyz)';
    }

    return ProjectionType[projectionType];
  }

  openEpsgHintDialog(event) {
    event.stopPropagation();

    this.matDialog.open(EpsgCodeTipsComponent, {
      autoFocus: false,
    });
  }

  isShowVerticalDatum() {
    return this.isDisplayForCurrentSourceType(this.configurationFields.VerticalDatum) && !this.isLocalCoordinatesSystem;
  }

  isShowObjectConfigurationTitle(): boolean {
    return (
      this.isDisplayForCurrentSourceType(this.configurationFields.IsLocalCoordinateSystem) ||
      this.isDisplayForCurrentSourceType(this.configurationFields.ManualCoordinates) ||
      this.isDisplayForCurrentSourceType(this.configurationFields.CoordinateSystem)
    );
  }

  private clearLocalCoordinatesFields(): void {
    this.form.get('ObjectTypeConfiguration.Altitude').setValue(0, { emitEvent: false });
    this.form.get('ObjectTypeConfiguration.Latitude').setValue(0, { emitEvent: false });
    this.form.get('ObjectTypeConfiguration.Longitude').setValue(0, { emitEvent: false });
    this.form.get('ObjectTypeConfiguration.Pitch').setValue(0, { emitEvent: false });
    this.form.get('ObjectTypeConfiguration.Roll').setValue(0, { emitEvent: false });
    this.form.get('ObjectTypeConfiguration.Yaw').setValue(0, { emitEvent: false });
  }

  private onChangeObjectType() {
    this.form
      .get('ObjectType')
      .valueChanges.pipe(
      tap((objectType: ObjectType) => this.form.get('ObjectTypeConfiguration').get('ObjectType').setValue(objectType)),
      takeUntil(this.destroy$),
    )
      .subscribe();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      Id: [],
      Layer: [],
      ObjectType: [],
      Title: [],
      MainColor: [],
      VerticalDatum: [],
      VerticalMeasurementSystem: [],
      ObjectTypeConfiguration: this.formBuilder.group({
        CustomCrsId: [],
        CustomCrsName: [],
        ObjectType: [],
        Is12dModel: [],
        IsLocalCrs: [],
        IfcCategorizationStrategy: [],
        IsMeshInteractionEnabled: [],
        EnableDoubleSideInteraction: [],
        FullModel: [],
        CoordinateSystem: [],
        MeasurementSystem: [],
        Altitude: [],
        Latitude: [],
        Longitude: [],
        Pitch: [],
        Roll: [],
        Yaw: [],
        WorkpackAttribute: [],
      }),
      AltitudeMode: [],
      IsExcludedFromProcessing: [],
      IsFloorSplittingEnabled: [],
      FloorConfiguration: [],
    });
  }

  private excludeProjectionTypeOptions() {
    if (!this.isDisplayForCurrentSourceType(this.configurationFields.ManualCoordinates)) {
      this.projectionTypeOptions = this.projectionTypeOptions.filter(option => option !== ProjectionType.Manual);
    }

    if (!this.isDisplayForCurrentSourceType(this.configurationFields.CoordinateSystem)) {
      this.projectionTypeOptions = this.projectionTypeOptions.filter(option => option !== ProjectionType.Global);
    }

    if (!this.isDisplayForCurrentSourceType(this.configurationFields.IsLocalCoordinateSystem)) {
      this.projectionTypeOptions = this.projectionTypeOptions.filter(option => option !== ProjectionType.Local);
    }
  }

  private setProjectionTypeValue() {
    const isLocalCrsEnabled = this.node.ObjectTypeConfiguration.IsLocalCrs;
    const isCoordinatesSystemValue = this.node.ObjectTypeConfiguration.CoordinateSystem !== null;
    const isLatOrLongChanged = this.node.ObjectTypeConfiguration.Longitude !== 0 || this.node.ObjectTypeConfiguration.Latitude !== 0;
    const isCustomCoordinateSystem = this.node.ObjectTypeConfiguration.CustomCrsId !== null;

    const isManualCoordinates = !isLocalCrsEnabled && !isCoordinatesSystemValue && isLatOrLongChanged;

    this.isShowManualCoordinates = isManualCoordinates;

    this.isLocalCoordinatesSystem = isLocalCrsEnabled;

    this.isCustomCoordinateSystem = isCustomCoordinateSystem;

    if (isLocalCrsEnabled) {
      this.projectionTypeControl.setValue(ProjectionType.Local);
      this.disableLocalCoordinatesRelatedFields();

      return;
    }

    if (isManualCoordinates) {
      this.projectionTypeControl.setValue(ProjectionType.Manual);

      return;
    }

    if (isCustomCoordinateSystem) {
      this.projectionTypeControl.setValue(this.node.ObjectTypeConfiguration.CustomCrsId);
      this.disableLocalCoordinatesRelatedFields();

      this.addCustomCoordinateSystemForTeamAdmin();

      return;
    }


    if (this.isDisplayForCurrentSourceType(this.configurationFields.CoordinateSystem)) {
      this.projectionTypeControl.setValue(ProjectionType.Global);
      this.enableLocalCoordinatesRelatedFields();
    } else {
      if (this.projectionTypeOptions.includes(ProjectionType.Manual)) {
        this.isShowManualCoordinates = true;
        this.projectionTypeControl.setValue(ProjectionType.Manual);
      }
    }
  }

  private setProjectionTypeData() {
    if (this.isShowProjectionType()) {
      this.projectionTypeOptions = Object.values(ProjectionType);

      this.excludeProjectionTypeOptions();

      this.setProjectionTypeValue();
    }
  }

  private setFloorsValue() {
    this.isShowIfcMapping = this.node.IsFloorSplittingEnabled;

    if (this.ifcMappingToggle !== undefined) {
      this.ifcMappingToggle.checked = this.node.IsFloorSplittingEnabled;
    }

    if (this.node.IsFloorSplittingEnabled) {
      this.getCompanyFloors();
    }

    if (this.floorSplittingToggle !== undefined) {
      this.floorSplittingToggle.checked = this.node.IsFloorSplittingEnabled || this.node.IsFloorSplittingEnabled === null;
    }

    this.isIfcMappingEnabled = this.node.IsFloorSplittingEnabled || this.node.IsFloorSplittingEnabled === null;
  }

  private enableLocalCoordinatesRelatedFields() {
    this.form.get('ObjectTypeConfiguration.CoordinateSystem').enable({ emitEvent: false });
    this.form.get('AltitudeMode').enable({ emitEvent: false });
  }

  private disableLocalCoordinatesRelatedFields() {
    this.form.get('ObjectTypeConfiguration.CoordinateSystem').disable({ emitEvent: false });
    this.form.get('AltitudeMode').disable({ emitEvent: false });
  }

  private onChangeModelInteractionsValue() {
    this.form
      .get('ObjectTypeConfiguration.IsMeshInteractionEnabled')
      .valueChanges.pipe(
      tap(value => {
        if (!value) {
          this.form.get('ObjectTypeConfiguration.EnableDoubleSideInteraction').setValue(false);
          this.form.get('ObjectTypeConfiguration.EnableDoubleSideInteraction').disable();
        } else {
          this.form.get('ObjectTypeConfiguration.EnableDoubleSideInteraction').enable();
        }
      }),
      takeUntil(this.destroy$),
    )
      .subscribe();
  }

  private onChangeIsDefineFloorElevation() {
    this.form
      .get('IsFloorSplittingEnabled')
      .valueChanges.pipe(
      tap(isFloorSplittingEnabled => {
        if (!this.isShowIfcFloorsConfiguration) {
          return;
        }

        if (isFloorSplittingEnabled) {
          if (this.isIfcMappingEnabled) {
            return;
          }

          this.form.get('IsFloorSplittingEnabled').setValue(null);
        } else {
          this.isShowIfcMapping = false;
          this.ifcMappingToggle.checked = false;
        }

        this.floorSplittingToggle.checked = isFloorSplittingEnabled;
        this.isIfcMappingEnabled = isFloorSplittingEnabled;
      }),
      takeUntil(this.destroy$),
    )
      .subscribe();
  }

  onIfcMappingChange(event: MatSlideToggleChange) {
    this.isShowIfcMapping = event.checked;
    this.isIfcMappingEnabled = event.checked;

    if (event.checked) {
      this.form.get('IsFloorSplittingEnabled').setValue(true);

      this.getCompanyFloors();
    } else {
      this.form.get('IsFloorSplittingEnabled').setValue(null);

      this.floorSplittingToggle.checked = true;
    }
  }

  getCompanyFloors() {
    this.isCompanyFloorsLoading = true;

    this.dataSourcesService
      .getFloors(this.companySlug)
      .pipe(
        tap(floors => (this.companyFloors = floors)),
        finalize(() => {
          this.isCompanyFloorsLoading = false;
          this.cdr.markForCheck();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  isShowProjectionType() {
    return (
      this.isDisplayForCurrentSourceType(this.configurationFields.IsLocalCoordinateSystem) ||
      this.isDisplayForCurrentSourceType(this.configurationFields.CoordinateSystem) ||
      this.isDisplayForCurrentSourceType(this.configurationFields.ManualCoordinates)
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleWorkPacksList(): void {
    if (!this.isDisplayForCurrentSourceType(ConfigurationFields.WorkPacks)) {
      return;
    }

    this.sourceConfigurationService.workPacks$.pipe(
      tap(workPacks => {
        this.workPacks = workPacks;
        this.cdr.markForCheck();
      }),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  private addCustomCoordinateSystemForTeamAdmin(): void {
    if (this.identityService.isTeamAdmin()) {
      const selectedCoordinateSystemId = this.form.get('ObjectTypeConfiguration.CustomCrsId').value;
      const selectedCoordinateSystemName = this.form.get('ObjectTypeConfiguration.CustomCrsName').value;

      if (selectedCoordinateSystemId) {
        const foundCoordinateSystem = this.customCoordinateSystems.find(system => system.Id === selectedCoordinateSystemId);

        if (foundCoordinateSystem === undefined) {
          const selectedCoordinateSystem = { Id: selectedCoordinateSystemId, Name: selectedCoordinateSystemName } as CoordinateSystem;

          this.customCoordinateSystems = [...this.customCoordinateSystems, selectedCoordinateSystem];
        }
      }
    }
  }
}
