import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { ILayer } from '../../components/layers/shared/models/layer.interface';
import { LayersService } from '../../components/layers/shared/services/layers.service';

export const SourcesLayersResolver: ResolveFn<ILayer[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  layersService = inject(LayersService),
) => layersService.getLayersByCompany(route.paramMap.get('companySlug'));
